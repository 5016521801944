import React from "react";
import { ColorBar, ContainedButton, Form, LoginStack, Wrapper } from "../../Styles/GlobalStyles";
import {
  Container,
  Stack,
  Typography,
  FormControl,
  FormControlLabel,
  InputAdornment,
  IconButton,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authenticate } from "./authenticate";
import Checkbox from "@mui/material/Checkbox";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner/LoadingSpinner";
import { UpdateErrorComponent } from "../../components/shared/UpdateErrorComponent/UpdateErrorComponent";
import { userInfo } from "../../Redux/Slices/UserSlice";
import { adminInfo } from "../../Redux/Slices/IsAdminSlice";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ZiplyLogoIcon from "../../assets/images/ziply-logo.svg";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = React.useState<any>("");
  const [password, setPassword] = React.useState<any>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<any>("");
  const [checked, setChecked] = React.useState(false);
  const [userData, setUserData] = React.useState<any>();
  const [openError, setOpenError] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const emailHandleChange = (e: any) => {
    setEmail(e.target.value);
  };
  const passwordHandleChange = (e: any) => {
    setPassword(e.target.value);
  };
  const checkBoxHandler = (e: any) => {
    setChecked(!checked);
  };

  const getErrorMessage = (errorCode: any) => {
    let errorMessage = "";
    if (errorCode === "AP206PRIDNTPR") {
      errorMessage = "Partner ID is not valid.";
    } else {
      errorMessage = "Oops... something went wrong. Please try again later.";
    }
    if (errorCode === "AP206UAF") {
      errorMessage = `Authentication failed. Please check your ID and Password`;
    }
    if (errorCode === "AP206UDNF") {
      errorMessage = `Permission denied...login to self service portal to request access.`;
    }
    setOpenError(true);
    return errorMessage;
  };
  const handleSubmit = async () => {
    if (error) {
      setError("");
    }
    if (!email || !password) {
      setError("Login details cannot be empty");
      return;
    }
    const item = { loginID: email, password: btoa(password) };

    try {
      setLoading(true);
      let result = await authenticate(item);

      if (result?.content?.error) {
        const { code } = result?.content?.error;
        setError(getErrorMessage(code));
      } else {
        setUserData(result);

        var authentication = {
          isAuthenticated: result.content.response.success.code === 0,
        };
        dispatch(adminInfo(authentication));

        const deepLinkingValue = localStorage.getItem("impersonatedEmail") || "";

        if (deepLinkingValue?.length > 1) {
          navigate(`/adminscreen?email=${localStorage.getItem("impersonatedEmail")}`);
        } else {
          navigate("/adminscreen");
        }
        localStorage.setItem("userName", result.content.response.firstName);
        localStorage.setItem("token", result.content.response.token);

        const userDetails = {
          userToken: result.content.response.token,
        };
        dispatch(userInfo(userDetails));

        if (checked) {
          localStorage.setItem("userId", item.loginID);
          localStorage.setItem("userPassword", item.password);
        } else {
          localStorage.removeItem("userId");
          localStorage.removeItem("userPassword");
        }
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (localStorage.getItem("userId")) {
      setEmail(localStorage.getItem("userId"));
      setChecked(true);
    }
    if (localStorage.getItem("userPassword")) {
      setPassword(atob(localStorage.getItem("userPassword") || "{}"));
    }
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <>
      <Wrapper>
        <Container>
          <LoginStack alignItems="center" justifyContent="space-between">
            <img width={240} height={60} src={ZiplyLogoIcon} alt="logo" />

            <Form elevation={3} sx={{ mb: "60px", width: { xs: "90%", sm: "80%" } }}>
              <Stack alignItems="center" spacing={4}>
                <Typography fontSize={{ xs: "medium", sm: "24px" }} align="center">
                  CXP - My Account Admin
                </Typography>

                <Stack width={"80%"} spacing={3}>
                  <FormControl fullWidth required>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      label="Ziply Nwestnetwork ID"
                      value={email}
                      onChange={emailHandleChange}
                    />
                  </FormControl>

                  <FormControl fullWidth required>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      label="Password"
                      value={password}
                      onChange={passwordHandleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end">
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                  <FormControlLabel
                    control={<Checkbox sx={{ p: 0, pr: 1 }} />}
                    label="Remember me"
                    onChange={checkBoxHandler}
                  />

                  <Stack alignItems="center">
                    <ContainedButton
                      sx={{ width: { xs: "50%", sm: "30%" } }}
                      onClick={handleSubmit}>
                      Log In
                    </ContainedButton>
                  </Stack>
                </Stack>
              </Stack>
            </Form>

            <Typography variant="caption" component="p" align="center">
              © 2023 Ziply Fiber. Ziplyᵀᴹ is trademark of Northwest Fiber, LLC All rights reserved.
            </Typography>
          </LoginStack>
        </Container>

        <ColorBar />
      </Wrapper>
      {loading && <LoadingSpinner />}
      {error?.length > 0 && (
        <UpdateErrorComponent
          openError={openError}
          setOpenError={setOpenError}
          message={error}
          setErrorMessage={setError}
        />
      )}
    </>
  );
}
