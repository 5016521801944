import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { ManageAccordion } from "./ManageAccordion";
import { Theme } from "../../../../Styles/GlobalTheme";
import OnHoldGrey from "../../../../assets/icons/OnHoldGrey.svg";
import OrderIconBlue from "../../../../assets/icons/OrderIconBlue.svg";
import CheckmarkIconGray from "../../../../assets/icons/CheckmarkIconGray.svg";
import { Spinner } from "../../../../components/shared/LoadingSpinner/Spinner";
import {
  ColorlibConnector,
  ColorlibStepIconRoot,
  ColorlibStepLabel,
} from "../TrackYourOrder/TrackYourOrder";
import { CardHeading, StepperBox, TrackOrderBox } from "../../../../Styles/GlobalStyles";
import { Box, Grid, Stack, Step, StepIconProps, Stepper, Typography } from "@mui/material";
import { CANCELLED_STATUS } from "../../../../Constants/constants";
import { addSpaceAfterComma, getTroubleTicketActiveStep } from "../../../../Services/helper";

const steps = ["Created", "In Progress", "Complete"];

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <img src={OrderIconBlue} alt="order" width="40" height="150" />,
    2: <img src={OnHoldGrey} alt="checkmark" width="20" height="150" />,
    3: <img src={CheckmarkIconGray} alt="go" width="40" height="150" />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

export interface ImappingData {
  name: string;
  value: any;
  description?: string;
}

export const TroubleTicketStatus = (props: any) => {
  const { troubleTicketStatusLoading } = props;
  const { troubleTicketDetails, createdDate, appointment } = useSelector(
    (state: any) => state.troubleTicketDetails
  );
  const { serviceAddress } = useSelector((state: any) => state.customerPlanDetails);

  const inputData = moment(appointment?.appointmentDate).format("MMM DD");
  const isAppointment: any = troubleTicketDetails?.tickets?.[0]?.appointment !== null;

  const mappingData: ImappingData[] = [
    {
      name: "Ticket number:",
      value: `${troubleTicketDetails?.tickets?.[0]?.ticketId}`,
    },
    {
      name: "Created date :",
      value: createdDate,
    },
    {
      name: isAppointment && "Appointment date :",
      value:
        isAppointment &&
        `${inputData}, ${appointment?.appointmentStartTime} - ${appointment?.appointmentEndTime}`,
    },
    {
      name: isAppointment && "Service address :",
      value: isAppointment && addSpaceAfterComma(serviceAddress),
    },
    {
      name: "Tel :",
      value: troubleTicketDetails?.tickets?.[0]?.appointment?.canBeReachedTelephoneNumber,
    },
  ].filter((item) => item?.value);

  return (
    <>
      <CardHeading>Track your ticket</CardHeading>
      <TrackOrderBox>
        {troubleTicketStatusLoading ? (
          <Box py={10}>
            <Spinner />
          </Box>
        ) : (
          <>
            <StepperBox
              sx={{
                backgroundColor: Theme.palette.secondary.main,
              }}>
              <Grid container justifyContent={"space-between"}>
                <Grid item>
                  <Typography
                    component={"p"}
                    color={"white"}
                    fontSize="large"
                    fontWeight={600}
                    data-testid="tt-message">
                    Ticket number&nbsp;
                    {troubleTicketDetails?.tickets?.[0]?.ticketId}
                  </Typography>
                </Grid>
              </Grid>
              {troubleTicketDetails?.tickets?.[0]?.status !== CANCELLED_STATUS && (
                <Stack py={3}>
                  <Stepper
                    alternativeLabel
                    activeStep={getTroubleTicketActiveStep(
                      troubleTicketDetails?.tickets?.[0]?.status
                    )}
                    connector={<ColorlibConnector />}>
                    {steps?.map((label) => (
                      <Step key={label} data-testid="tt-status">
                        <ColorlibStepLabel StepIconComponent={ColorlibStepIcon}>
                          {label}
                        </ColorlibStepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Stack>
              )}
            </StepperBox>
            <ManageAccordion title="Ticket details" data={mappingData} />
          </>
        )}
      </TrackOrderBox>
    </>
  );
};
