import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cardData: [],
};
const linkedAccountSlice = createSlice({
  name: "linkedAccountDetails",
  initialState,
  reducers: {
    addLinkedAccount: (state: any, action) => {
      state.cardData.push(action.payload);
    },
  },
});

export const { addLinkedAccount } = linkedAccountSlice.actions;
export const linkedAccountDetailsReducer = linkedAccountSlice.reducer;
