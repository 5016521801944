import { Alert, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { SnackbarOrigin } from "@mui/material/Snackbar";

interface ButtonProps {
  margin?: boolean;
  padding?: boolean;
  textcolor?: string;
  backgroundcolor?: string;
  border?: string;
  medium?: boolean;
  small?: boolean;
  xsmall?: boolean;
}

interface BoxProps {
  paddingRight?: boolean;
}

interface TextProps {
  color?: string;
  padding?: boolean;
}

export interface State extends SnackbarOrigin {
  visible: boolean;
}

export const StyledAlert = styled(Alert)(({ theme }) => ({
  padding: "1.5rem 2rem",
  borderRadius: "1.5rem",
}));

export const BoxContent = styled("div")<BoxProps>(({ theme, paddingRight }) => ({
  paddingLeft: 3,
  paddingTop: 3,
  paddingRight: paddingRight ? "4rem" : 0,
}));

export const StyledTypography = styled(Typography)<TextProps>(({ theme, color, padding }) => ({
  color: color === "green" ? theme.palette.primary.main : "black",
  padding: padding ? "0" : "0.5rem 0",
}));

export const CustomButton = styled("button")<ButtonProps>(
  ({ theme, textcolor, backgroundcolor, border, margin, padding, medium, small, xsmall }) => ({
    color:
      textcolor === "red"
        ? theme.palette.error.main
        : textcolor === "grey"
        ? "grey"
        : textcolor === "blue"
        ? theme.palette.secondary.main
        : "white",
    letterSpacing: "1px",
    fontSize: xsmall ? "0.8rem" : "1rem",
    backgroundColor:
      backgroundcolor === "green"
        ? "green"
        : backgroundcolor === "white"
        ? "white"
        : theme.palette.secondary.main,
    border:
      border === "red"
        ? `2px solid ${theme.palette.error.main}`
        : border === "grey"
        ? "grey"
        : border === "blue"
        ? `2px solid ${theme.palette.secondary.main}`
        : "none",
    padding: small
      ? "1.2rem 0.6rem"
      : medium
      ? "1.2rem 3rem"
      : padding
      ? "1.2rem 4rem"
      : "1.5rem 5rem",
    borderRadius: "2.5rem",
    margin: small
      ? "1.5rem 0.1rem"
      : medium
      ? "1.5rem 0.2rem"
      : margin
      ? " 1.5rem 0.5rem"
      : "2rem 2rem",
    cursor: "pointer",
  })
);

export const StyledClose = styled(CloseIcon)(({ theme }) => ({
  position: "absolute",
  right: 0,
  margin: "-0.9rem 1rem -1rem auto",
  cursor: "pointer",
}));

export const BoxStyle1 = {
  position: "absolute" as "absolute",
  display: "flex",
  flexDirection: "column",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "60%",
  bgcolor: "background.paper",
  borderRadius: "1.5rem",
  boxShadow: 24,
  p: 5,
  overflowY: "scroll",
};

export const BoxStyle2 = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "25%",
  bgcolor: "background.paper",
  borderRadius: "1.5rem",
  boxShadow: 24,
  p: 6,
};

export const BoxStyle3 = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "55%",
  bgcolor: "background.paper",
  borderRadius: "1.5rem",
  boxShadow: 24,
  p: 5,
};

export const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "white",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
