import Button from "../../Button/Button";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Theme } from "../../../Styles/GlobalTheme";
import { Grid, Stack, Typography } from "@mui/material";
import { AccountCard } from "../AccountCard/AccountCard";
import { useLocation, useNavigate } from "react-router-dom";
import { NeedHelpCard } from "../NeedHelpCard/NeedHelpCard";
import { getDollarSign, logEvents } from "../../../Services/helper";
import paymentSuccessIcon from "../../../assets/icons/Payment Success Web.svg";
import {
  BlankScreenStack,
  CardHeading,
  CenterStack,
  ContainedButton,
  IconSmall,
  PageGrid,
  StickyBox,
  StyledLink,
} from "../../../Styles/GlobalStyles";

export const SuccessFailure = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { scrollPosition } = useSelector((state: any) => state.pageInfo);
  const { anotherAmount } = useSelector((state: any) => state.paymentDetails);
  const { currentDueBalance } = useSelector((state: any) => state.customerPlanDetails);
  const [transactionId, setTransactionId] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  const paymentAmountInPositive = currentDueBalance && !currentDueBalance?.includes("-");

  const handleNavigate = () => {
    navigate("/billing/makeapayment");
  };

  useEffect(() => {
    setTransactionId(location?.state?.data);
    setError(location?.state?.error);
    !error ? logEvents("PAYMENT_SUCCESS") : logEvents("PAYMENT_FAILED");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  return (
    <>
      <PageGrid container spacing={1}>
        <Grid item xs={12} lg={8} mt={{ md: 4 }}>
          <BlankScreenStack height={"60vh"}>
            {!error ? (
              <>
                <IconSmall src={paymentSuccessIcon} alt="success" />
                <CardHeading my={3} data-testid="success-msg">
                  Payment successful
                </CardHeading>
                {transactionId?.trim()?.length > 0 && (
                  <Typography fontWeight={600} component={"p"} fontSize={"12px"}>
                    Transaction ID:&nbsp; {transactionId}
                  </Typography>
                )}
                <CenterStack textAlign={"center"}>
                  {(anotherAmount || paymentAmountInPositive) && (
                    <Typography>{`Your transaction of ${
                      anotherAmount
                        ? `${getDollarSign(anotherAmount)}`
                        : `${getDollarSign(currentDueBalance)}`
                    } was successful.`}</Typography>
                  )}
                  <Typography>You can track all your payments in Transaction History.</Typography>
                </CenterStack>
                <Typography
                  textAlign={"center"}
                  my={2}
                  color={Theme.palette.customcolor.customText}
                  fontSize={"14px"}>
                  Note : It may take time for the information to reflect in transaction history.
                </Typography>
                <StyledLink to={"/billing"}>
                  <ContainedButton>GO BACK TO BILLING</ContainedButton>
                </StyledLink>
              </>
            ) : (
              <>
                <CardHeading my={3}>Payment failed</CardHeading>
                <Typography component={"p"} px={{ sm: "6rem", md: "10rem" }} textAlign={"center"}>
                  Sorry, we're not able to process your payment at the moment, please try again.
                </Typography>
                <Stack marginTop={6}>
                  <Button type="root" title="BACK" onClick={handleNavigate} />
                </Stack>
              </>
            )}
          </BlankScreenStack>
        </Grid>
        <Grid
          item
          xs={12}
          lg={3.5}
          display={{ xs: "block", lg: scrollPosition > 20 ? "block" : "none" }}>
          <AccountCard />

          <NeedHelpCard />
        </Grid>

        <StickyBox display={{ xs: "none", lg: scrollPosition > 20 ? "none" : "block" }}>
          <AccountCard />
          <NeedHelpCard />
        </StickyBox>
      </PageGrid>
    </>
  );
};
