import { gql } from "@apollo/client";

export const GET_OFFER_DETAILS = gql`
  query getOffers {
    offerDetails {
      offerHeader
      description
      Learnmorelink
      imageURL
    }
  }
`;

export const GET_CUSTOMER_CONTACT = gql`
  query customerContact($email: String!) {
    customerContact(email: $email) {
      error
      APIStatus
      message
      id
      givenAndFamilyName {
        givenName
        familyName
      }
      telephoneContact {
        telephoneNumber
        verifiedDate
      }
      emailContact {
        emailAddress
        verifiedDate
      }
      fireBaseUUId
      linkedAccountNumbers {
        accountNumber
        accountUUID
        pin
        isNDS
        usi
        isActive 
      }
    }
  }
`;

export const GET_ACCOUNT_INFO = gql`
  query getAccountInfo($accountNumber: String!) {
    accountInfo(accountNumber: $accountNumber) {
      error
      APIStatus
      message
      customerName
      accountNumber
      btnNumber
      pin
      hasLinkedAccount
      billingPrefrence {
        error
        APIStatus
        message
        isAutoPayEnabled
        isPaperLessBillingEnabled
        autoPayDiscount
        paperlessBillingDiscount
        billingAddrress {
          addressLine1
          cityName
          stateAbbreviation
          zipCode
        }
      }
    }
  }
`;

export const GET_ORDER_DETAILS = gql`
  query getOrderDetails($accountNumber: String!) {
    orderDetails(accountNumber: $accountNumber) {
      orderCreationDate
      status
      orderNumber
      installationDate
      serviceAddress
      ziplyTelephoneNumber
      message
      confirmationStatus
    }
  }
`;

export const GET_TROUBLE_TICKET_STATUS = gql`
  query troubleTicketDetails($accountNumber: String!) {
    troubleTicketDetails(accountNumber: $accountNumber) {
      APIStatus
      error
      message
      accountNumber
      tickets {
        ticketId
        status
        createdDate
        ticketType {
          code
          description
        }
        isModifiable
        btn
        statusCode
        wtn
        frontierIsNds
        incidentId
        appointment {
          canBeReachedTelephoneNumber
          startDate
          endDate
        }
        completionDate
        statusDescription
      }
    }
  }
`;

export const ORDER_DETAILS = gql`
  query orderStatus($accountNumber: String!) {
    orderStatus(accountNumber: $accountNumber) {
      error
      APIStatus
      message
      orders {
        id
        status
        createdDate
        dueDate
        deactivatedDate
        isModifiable
        orderType
        orderTypeDescription
        stageCode
        stageDescription
        productsAdded {
          action
          quantity
          id
          description
        }
        productsRemoved {
          action
          quantity
          id
          description
        }
        btn
        wtn
        accountUUID
        completionDate
      }
    }
  }
`;

export const GET_SPEED_TEST_DETAILS = gql`
  query speedTestDetails($accountNumber: String!, $isNds: Boolean!) {
    speedTestDetails(accountNumber: $accountNumber, isNds: $isNds) {
      APIStatus
      message
      error
      testDetails {
        uploadSpeed
        downloadSpeed
        downstreamLatency
        upstreamLatency
        message
        testStatus
        speedTestId
        speedMeasurement
        latencyMeasurement
        SpeedInLineWithPlan
      }
    }
  }
`;

export const GET_GLOBYS_URL = gql`
  query globysUrl($email: String!, $globysId: String!) {
    globysUrl(email: $email, globysId: $globysId) {
      url
      message
      APIStatus
      error
    }
  }
`;

export const GET_OUTAGE_DETAILS = gql`
  query outageDetailsByAccountNumber($accountNumber: String!) {
    outageDetailsByAccountNumber(accountNumber: $accountNumber) {
      APIStatus
      error
      message
      affectedByOutage
    }
  }
`;

export const CREATE_SERVICE_TICKET = gql`
  mutation createServiceTicketByAccountNumber(
    $accountNumber: String!
    $isNds: Boolean!
    $serviceTicketInput: CreateServiceTicketByAccountNumber!
  ) {
    createServiceTicketByAccountNumber(
      accountNumber: $accountNumber
      serviceTicketInput: $serviceTicketInput
      isNds: $isNds
    ) {
      error
      message
      APIStatus
    }
  }
`;

export const GET_REASONS_FOR_TT = gql`
  query getReasonCodesForTT($accountNumber: String!) {
    getReasonCodesForTT(accountNumber: $accountNumber) {
      reasonCodes {
        reason
        dispatch
      }
      error
      message
      APIStatus
    }
  }
`;

export const GET_DETAILS_FOR_AVAILABLE_SLOTS = gql`
  query getDetailsForAvailableSlots($accountNumber: String!, $isNds: Boolean!) {
    getDetailsForAvailableSlots(accountNumber: $accountNumber, isNds: $isNds) {
      territoryId
      addressId
      usi
      accountUuid
      btn
      isEligibleForCreateTT
      error
      message
      APIStatus
    }
  }
`;

export const GET_AVAILABLE_SLOTS_FOR_TT = gql`
  query availableSlotsForTT(
    $accountNumber: String!
    $isNds: Boolean!
    $territoryId: String!
    $reasonText: String!
  ) {
    availableSlotsForTT(
      accountNumber: $accountNumber
      isNds: $isNds
      territoryId: $territoryId
      reasonText: $reasonText
    ) {
      appointmentSlots {
        startDate
        endDate
        workUnitsQuantity
      }
      error
      message
      APIStatus
    }
  }
`;
