import React from "react";
import { CenterStack, StyledButton, StyledDialog } from "../../../Styles/GlobalStyles";
import { IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ContactSupport from "../ContactSupport/ContactSupport";
import { useLocation, useNavigate } from "react-router-dom";
import { ACCOUNT } from "../../../Constants/constants";
import { useSelector } from "react-redux";

export const LegacyPaymentErrorPopup = (props: any) => {
  const { openError, setOpenError, message = "We cannot process your payment." } = props;
  const { pathname } = useLocation();
  const { makePaymentRoute } = useSelector((state: any) => state.pageInfo);
  const navigate = useNavigate();
  const handleClose = () => {
    setOpenError(false);
    navigate(makePaymentRoute);
  };
  return (
    <>
      <StyledDialog maxWidth="lg" open={openError}>
        <IconButton
          data-testid="closeButton"
          onClick={handleClose}
          sx={{ alignSelf: "flex-end", padding: "0" }}>
          <CloseIcon />
        </IconButton>

        <CenterStack pb={5} px={5} textAlign={"center"} gap={1}>
          <Typography component={"p"} fontSize={{ xs: "1rem", sm: "1rem" }} mt={2}>
            {message}
          </Typography>
          <Stack direction={"row"} justifyContent={"center"} gap={1} flexWrap={"wrap"}>
            <Typography fontSize={{ xs: "1rem", sm: "1rem" }}>Please contact support</Typography>
            <Stack
              direction={"row"}
              justifyContent={"center"}
              gap={1}
              flexWrap={"wrap"}
              alignItems={"center"}>
              <Typography>via 1-866-699-4759 or</Typography>
              <ContactSupport messageFont={true} text={"CHAT"} />
            </Stack>
          </Stack>
        </CenterStack>
      </StyledDialog>
    </>
  );
};
