import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  selectedPlanDetails: {},
  customerPlandetailsError: false,
  availablePlansError: false,
  nutritionLabelbyPlanError: false,
  isSelected: false,
  planDetailsBySkuError: false,
  ZoomOutFlag: false,
  selectedFiber: {},
  autopaySuccess: false,
  showNextinAutoPay: false,
  autopayFailure: false,
  selectedSlot: false,
  expandNutritionLabel: false,
};

const OrderUpgradeSlice = createSlice({
  name: "OrderUpgradeDetails",
  initialState,
  reducers: {
    setSelectedPlanDetails: (state, action) => {
      state.selectedPlanDetails = action.payload;
    },
    setCustomerPlanDetailsError: (state, action) => {
      state.customerPlandetailsError = action.payload;
    },
    setAvailablePlansError: (state, action) => {
      state.availablePlansError = action.payload;
    },
    setNutritionLabelbyPlanError: (state, action) => {
      state.nutritionLabelbyPlanError = action.payload;
    },
    setIsSelected: (state, action) => {
      state.isSelected = action.payload;
    },
    setPlanDetailsBySkuError: (state, action) => {
      state.planDetailsBySkuError = action.payload;
    },
    setZoomOutFlag: (state, action) => {
      state.ZoomOutFlag = action.payload;
    },
    setExpandNutritionLabel: (state, action) => {
      state.expandNutritionLabel = action.payload;
    },

    setSelectedFuturePlan: (state, action) => {
      state.selectedFiber = action.payload;
    },
    setAutopaySuccess: (state, action) => {
      state.autopaySuccess = action.payload;
    },
    setAutopayFailure: (state, action) => {
      state.autopayFailure = action.payload;
    },
    setShowNextinAutoPay: (state, action) => {
      state.showNextinAutoPay = action.payload;
    },
    setSlotSelected: (state, action) => {
      state.selectedSlot = action.payload;
    },
  },
});

export const {
  setSelectedPlanDetails,
  setCustomerPlanDetailsError,
  setAvailablePlansError,
  setNutritionLabelbyPlanError,
  setIsSelected,
  setPlanDetailsBySkuError,
  setZoomOutFlag,
  setSelectedFuturePlan,
  setAutopaySuccess,
  setShowNextinAutoPay,
  setAutopayFailure,
  setSlotSelected,
  setExpandNutritionLabel,
} = OrderUpgradeSlice.actions;
export const orderUpgradeReducer = OrderUpgradeSlice.reducer;
