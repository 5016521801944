import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  startLoader: false,
  startDownloadSpeed: false,
  startUploadSpeed: false,
  loadingDownload: false,
  loadingUpload: false,
  fadeOut: false,
  speedtestAgain: false,
  speedData: {},
  speedResult: {},
  speedTestComplete: false,
};

const speedAnimationSlice = createSlice({
  name: "speedAnimationSlice",
  initialState,
  reducers: {
    setStartLoader: (state, action) => {
      state.startLoader = action.payload;
    },
    setStartDownloadSpeed: (state, action) => {
      state.startDownloadSpeed = action.payload;
    },
    setStartUploadSpeed: (state, action) => {
      state.startUploadSpeed = action.payload;
    },
    setLoadingDownload: (state, action) => {
      state.loadingDownload = action.payload;
    },
    setLoadingUpload: (state, action) => {
      state.loadingUpload = action.payload;
    },
    setFadeOut: (state, action) => {
      state.fadeOut = action.payload;
    },
    setSpeedTestAgain: (state, action) => {
      state.speedtestAgain = action.payload;
    },

    setSpeedData: (state, action) => {
      state.speedData = action.payload;
    },
    setSpeedTestComplete: (state, action) => {
      state.speedTestComplete = action.payload;
    },
    setSpeedResult: (state, action) => {
      state.speedResult = action.payload;
    },
  },
});

export const {
  setStartLoader,
  setLoadingDownload,
  setLoadingUpload,
  setStartDownloadSpeed,
  setStartUploadSpeed,
  setFadeOut,
  setSpeedTestAgain,
  setSpeedData,
  setSpeedResult,
  setSpeedTestComplete,
} = speedAnimationSlice.actions;
export const speedAnimationReducer = speedAnimationSlice.reducer;
