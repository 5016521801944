import React, { useEffect, useState } from "react";
import ColorBar from "./ColorBar";
import { Box, Stack, Typography } from "@mui/material";
import { Theme } from "../../../../Styles/GlobalTheme";
import { useSelector } from "react-redux";
import { addSpaceAfterComma, getDollarSign } from "../../../../Services/helper";
import { calculateEstimatedTax, isObjectEmpty } from "./OrderHelper";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const MonthlyBillCart = ({
  setOrderTotalPrice,
  orderTotalPrice,
  submitOrderPayload,
  submitOrderArr,
  installation,
  promotion,
  serviceAddress,
  selectedFiber,
}: any) => {
  const { ndsObj } = useSelector((state: any) => state.customerInfoDetails);
  const [showInstallation, setShowInstallation] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [open, setOpen] = React.useState(false);
  const { selectedPlanDetails } = useSelector((state: any) => state.OrderUpgradeDetails);
  const hasInstallationDiscount = JSON.stringify(installation?.discounts)?.includes("FREEINSTALL");

  const tabletView = window.screen.width <= 1440;

  const tooltipTitle =
    "We estimate the tax based on your service address, Estimated monthly tax includes the tax for the product and your add-on";

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#EEEEEE",
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding: "10px",
    },
  }));

  interface cartDataProps {
    name: string;
    price: string;
    priceAfterDiscount?: any;
    isPerMonth?: any;
    productId?: any;
  }

  useEffect(() => {
    let findNewRouter = submitOrderArr?.find(
      (item: any) =>
        (item?.groupType === "Router" && item?.productId !== "BYO_ROUTER") ||
        item?.groupType === "Equipment"
    );
    setShowInstallation(findNewRouter);
    if (submitOrderArr && submitOrderArr?.length > 0) {
      let total = submitOrderArr?.reduce((sum: any, item: any) => {
        // Check if the item's name is not 'installation'
        if (item?.name !== "Installation") {
          if (item?.isPerMonth && ndsObj?.isNDS && item?.productId !== "WHW") {
            return sum + parseFloat(item?.priceAfterDiscount ?? item?.price);
          }
          return sum + parseFloat(item?.price);
        }
        return sum;
      }, 0);
      if (ndsObj?.isNDS) {
        selectedPlanDetails?.discounts?.[0]?.price ? setShowDiscount(true) : setShowDiscount(false);
      } else {
        const promo = promotion?.[0];
        const isValidPromo =
          promo?.startDate && promotion?.[0]?.price != 0 && promo?.expired?.toLowerCase() !== "y";
        setShowDiscount(isValidPromo);
        if (isValidPromo) {
          total += promo.price;
        }
      }
      const getTax: any = calculateEstimatedTax(
        submitOrderArr,
        ndsObj?.isNDS,
        submitOrderPayload?.addressInfo?.billingAddress?.stateOrProvince,
        installation
      );

      setOrderTotalPrice({
        ...orderTotalPrice,
        totalPrice: total,
        extimatedTax: getTax?.estimatedTax,
        installTax: getTax?.installTax,
      });
    }
  }, [submitOrderArr]);

  return (
    <Box my={4}>
      <ColorBar />
      <Stack justifyContent={"center"} alignItems={"center"} my={4} spacing={2}>
        <Typography
          fontSize={{ xs: "20px", md: "24px" }}
          fontWeight={600}
          color={Theme.palette.primary.main}>
          Your New Monthly Bill
        </Typography>
        <Typography textAlign="center">{addSpaceAfterComma(serviceAddress)}</Typography>
      </Stack>
      {submitOrderArr &&
        submitOrderArr?.length > 0 &&
        submitOrderArr?.map((data: cartDataProps, index: number) => {
          const { name, price, isPerMonth, productId } = data;
          return (
            <Box
              py={2.6}
              sx={{ borderTop: index !== 0 ? `1px solid ${Theme.palette.grey[300]}` : "none" }}>
              <Stack direction={"row"} width={"100%"} justifyContent={"space-between"}>
                <Typography fontSize={{ xs: "16px", md: "18px" }}>
                  {productId === selectedFiber.productId && selectedFiber.updateToWifi6
                    ? "Whole Home Wifi"
                    : name}
                </Typography>
                <Typography fontSize={{ xs: "16px", md: "18px" }}>{`$${price}/mo`}</Typography>
              </Stack>
              {showDiscount && isPerMonth && (
                <Stack direction={"row"} width={"100%"} justifyContent={"space-between"}>
                  <Typography
                    fontSize={{ xs: "12px", md: "14px" }}
                    color={Theme.palette.primary.main}
                    sx={{ maxWidth: { xs: "200px", md: "500px" } }}>
                    For the remaining months of your 12-month promotional period
                  </Typography>
                  <Typography fontSize={"14px"} color={Theme.palette.primary.main}>
                    -$
                    {Math.abs(
                      selectedPlanDetails?.discounts?.[0]?.price && ndsObj?.isNDS
                        ? selectedPlanDetails?.discounts?.[0]?.price
                        : promotion?.[0]?.price
                    )}
                  </Typography>
                </Stack>
              )}
            </Box>
          );
        })}
      {installation &&
        !isObjectEmpty(installation) &&
        (showInstallation || selectedPlanDetails?.productId === "FIBER_10G") && (
          <Box py={2}>
            <Stack
              pt={1.5}
              direction={"row"}
              width={"100%"}
              justifyContent={"space-between"}
              sx={{ borderTop: `1px solid ${Theme.palette.grey[300]}` }}>
              <Typography fontSize={{ xs: "16px", md: "18px" }}>
                Installation (One time charge)
              </Typography>
              <Stack direction="column" alignItems="flex-end">
                <Typography fontSize={{ xs: "16px", md: "18px" }}>
                  ${installation?.price}
                </Typography>
              </Stack>
            </Stack>
            {hasInstallationDiscount && (
              <Stack direction={"row"} width={"100%"} justifyContent={"space-between"}>
                <Typography
                  fontSize={{ xs: "12px", md: "14px" }}
                  color={Theme.palette.primary.main}
                  sx={{ maxWidth: { xs: "200px", md: "500px" } }}>
                  Discount
                </Typography>
                <Typography fontSize={"14px"} color={Theme.palette.primary.main}>
                  -$
                  {Math.abs(installation?.price)}
                </Typography>
              </Stack>
            )}
          </Box>
        )}
      <Stack
        py={2.6}
        direction={"row"}
        width={"100%"}
        justifyContent={"space-between"}
        sx={{ borderTop: `1px solid ${Theme.palette.grey[300]}` }}>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Typography fontSize={{ xs: "16px", md: "18px" }}>Estimated Monthly Tax</Typography>

          {tabletView ? (
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <LightTooltip
                  placement="top"
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={tooltipTitle}>
                  <IconButton sx={{ padding: 0 }} onClick={handleTooltipOpen}>
                    <InfoOutlinedIcon
                      sx={{ color: Theme.palette.secondary.main, fontSize: "16px" }}
                    />
                  </IconButton>
                </LightTooltip>
              </div>
            </ClickAwayListener>
          ) : (
            <>
              <LightTooltip title={tooltipTitle} placement="top">
                <IconButton sx={{ padding: 0 }}>
                  <InfoOutlinedIcon
                    sx={{ color: Theme.palette.secondary.main, fontSize: "16px" }}
                  />
                </IconButton>
              </LightTooltip>
            </>
          )}
        </Stack>
        <Typography fontSize={{ xs: "16px", md: "18px" }}>
          ${(Math.round(orderTotalPrice?.extimatedTax * 100) / 100)?.toFixed(2)}/mo
        </Typography>
      </Stack>
      <Stack
        py={2.6}
        direction={"row"}
        width={"100%"}
        justifyContent={"space-between"}
        sx={{ borderTop: `1px solid ${Theme.palette.grey[300]}` }}>
        <Typography fontSize={{ xs: "16px", md: "18px" }}>Due Monthly + Tax</Typography>
        <Typography fontSize={{ xs: "16px", md: "18px" }}>
          $
          {(
            Math.round((orderTotalPrice?.totalPrice + orderTotalPrice?.extimatedTax) * 100) / 100
          )?.toFixed(2)}
          /mo
        </Typography>
      </Stack>

      <Stack py={2} direction={"row"} width={"100%"} justifyContent={"space-between"}>
        <Typography color={Theme.palette.primary.main} fontSize={{ xs: "16px", md: "18px" }}>
          Total Due Today
        </Typography>
        <Typography color={Theme.palette.primary.main} fontSize={{ xs: "16px", md: "18px" }}>
          $0
        </Typography>
      </Stack>

      <ColorBar />
    </Box>
  );
};

export default MonthlyBillCart;
