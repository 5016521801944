import { gql } from "@apollo/client";

export const MASKED_CONTACT_INFO = gql`
  mutation maskedContactInfo($email: String!) {
    maskedContactInfo(email: $email) {
      error
      APIStatus
      message
      maskedEmail
      maskedPhoneNumber
    }
  }
`;

export const ISAM = gql`
  mutation lsam($password: String, $email: String!, $app: String) {
    lsam(email: $email, password: $password, app: $app) {
      error
      APIStatus
      message
    }
  }
`;

export const SEND_OTP_BY_PHONE = gql`
  mutation sendOTPByPhone(
    $email: String
    $phoneNumber: String
    $firstName: String
    $lastName: String
  ) {
    sendOTPByPhone(
      email: $email
      phoneNumber: $phoneNumber
      firstName: $firstName
      lastName: $lastName
    ) {
      error
      APIStatus
      message
    }
  }
`;

export const SEND_OTP_BY_EMAIL = gql`
  mutation sendOTPByEmail($email: String!, $firstName: String, $lastName: String) {
    sendOTPbyEmail(email: $email, firstName: $firstName, lastName: $lastName) {
      error
      APIStatus
      message
    }
  }
`;

export const VERIFY_OTP_PHONE = gql`
  mutation verifyOTPByPhone(
    $otp: String!
    $email: String
    $phoneNumber: String
    $updatingContact: Boolean
  ) {
    verifyOTPByPhone(
      otp: $otp
      email: $email
      phoneNumber: $phoneNumber
      updatingContact: $updatingContact
    ) {
      error
      APIStatus
      message
      uuid
    }
  }
`;

export const VERIFY_OTP_EMAIL = gql`
  mutation verifyOTPByEmail($otp: String!, $email: String!, $updatingContact: Boolean) {
    verifyOTPByEmail(otp: $otp, email: $email, updatingContact: $updatingContact) {
      error
      APIStatus
      message
      uuid
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($encryptedPayload: String!, $email: String!) {
    updatePassword(encryptedPayload: $encryptedPayload, email: $email) {
      error
      APIStatus
      message
    }
  }
`;

export const RESEND_OTP_PHONE = gql`
  mutation resendOTPByPhone(
    $phoneNumber: String
    $email: String
    $firstName: String
    $lastName: String
  ) {
    resendOTPByPhone(
      phoneNumber: $phoneNumber
      email: $email
      firstName: $firstName
      lastName: $lastName
    ) {
      error
      APIStatus
      message
    }
  }
`;

export const RESEND_OTP_EMAIL = gql`
  mutation resendOTPByEmail($email: String!, $firstName: String, $lastName: String) {
    resendOTPByEmail(email: $email, firstName: $firstName, lastName: $lastName) {
      error
      APIStatus
      message
    }
  }
`;

export const UPDATE_CUSTOMER_CONTACT = gql`
  mutation updateCustomerContact(
    $contactId: String!
    $customerContactInput: CustomerContactInput!
  ) {
    updateCustomerContact(contactId: $contactId, customerContactInput: $customerContactInput) {
      error
      APIStatus
      message
    }
  }
`;

export const GET_CUSTOMER_TYPE = gql`
  query getCustomerType($email: String!) {
    getCustomerType(email: $email) {
      residential
      commercial
      message
      APIStatus
      globysId
      error
    }
  }
`;

export const PAYMENT_DELAY_WARNING = gql`
  query homeScreenMessage {
    homeScreenMessage {
      APIStatus
      error
      message
      allScreens
      paymentScreen
      newAccountsMessage
    }
  }
`;

export const UPDATE_CUSTOMER_PASSWORD = gql`
  mutation updateCustomerPassword($encryptedPayload: String!) {
    updateCustomerPassword(encryptedPayload: $encryptedPayload) {
      APIStatus
      error
      message
    }
  }
`;
