import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { ORDER_AND_TROUBLE_TICKET_HISTORY_QUERY } from "../../Pages/Account/query";
import {
  setOrderAndServiceTicketHistory,
  setOrderAndServiceTicketHistoryApiError,
} from "../../Redux/Slices/OrderAndServiceTicketSLice";

export const useOrderAndServiceTicketHistory = () => {
  const dispatch = useDispatch();
  const { accountNumber, ndsObj } = useSelector((state: any) => state.customerInfoDetails);
  const [getOrderAndTroubleTicketHistory, { loading: orderAndServiceTicketHistoryLoading }] =
    useLazyQuery(ORDER_AND_TROUBLE_TICKET_HISTORY_QUERY, {
      variables: {
        accountNumber: accountNumber,
        isNds: ndsObj?.isNDS,
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        dispatch(
          setOrderAndServiceTicketHistory(
            data?.orderAndTroubleTicketHistoryByAccountNumber?.orderAndTroubleTicketHistory
          )
        );
        dispatch(setOrderAndServiceTicketHistoryApiError(false));
      },
      onError: () => dispatch(setOrderAndServiceTicketHistoryApiError(true)),
    });

  return {
    getOrderAndTroubleTicketHistory,
    orderAndServiceTicketHistoryLoading,
  };
};
