import { Card, Chip, Stack, Typography } from "@mui/material";
import { ACCOUNT } from "../../../../Constants/constants";
import { ColumnCenterStack, CustomChip, MiniCard } from "../../../../Styles/GlobalStyles";
import { StyledCard } from "../../../../Styles/GlobalStyles";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "../../../Button/Button";
import { getDollarSign } from "../../../../Services/helper";
import { Theme } from "../../../../Styles/GlobalTheme";

export const AutoPay = (props: any) => {
  const navigate = useNavigate();
  const { type, billingError } = props;
  const { autoPayData } = useSelector((state: any) => state?.autopayPaperlessDetails);
  const { autopayAmount, isNewCustomer } = useSelector((state: any) => state.customerPlanDetails);
  const handleManage = () => {
    if (type === ACCOUNT) {
      navigate("/account/manageautopay");
    } else {
      navigate("/billing/manageautopay");
    }
  };
  const handleSignUp = () => {
    if (type === ACCOUNT) {
      navigate("/account/signupautopay", { state: { ACCOUNT } });
    } else {
      navigate("/billing/signupautopay");
    }
  };

  return (
    <>
      <MiniCard>
        <Stack
          visibility={
            autoPayData &&
            Object.keys(autoPayData)?.length === 0 &&
            !billingError &&
            autopayAmount &&
            !isNewCustomer
              ? "visible"
              : "hidden"
          }
          direction={"row"}
          justifyContent={"flex-end"}>
          <CustomChip label={`SAVE $${autopayAmount}`} color="success" variant="outlined" />
        </Stack>
        <ColumnCenterStack spacing={1} pb={5}>
          {isNewCustomer ? (
            <>
              <Typography textAlign="center" component="p">
                Autopay
              </Typography>
              <Typography
                component="p"
                textAlign="center"
                color={Theme.palette.customcolor.profileTypoGraphyColor}>
                Autopay will be available once your first bill is generated.
              </Typography>
            </>
          ) : autoPayData && Object.keys(autoPayData)?.length > 0 ? (
            <>
              <Typography textAlign="center" component="p">
                Autopay enabled
              </Typography>
              <Button onClick={handleManage} title="Manage autopay" type="pay" />
            </>
          ) : (
            <>
              <Typography textAlign="center" component="p">
                Autopay disabled
              </Typography>
              <Button onClick={handleSignUp} title="SET UP AUTOPAY" type="pay" />
            </>
          )}
        </ColumnCenterStack>
      </MiniCard>
    </>
  );
};
