import React from "react";
import { CenterStack, StyledButton, StyledDialog } from "../../../Styles/GlobalStyles";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const UpdateErrorComponent = (props: any) => {
  const { openError, setOpenError, message, setErrorMessage, refreshButton } = props;

  const handleClose = () => {
    setOpenError(false);
    message?.trim().length > 0 && setErrorMessage("");
  };

  return (
    <>
      <StyledDialog maxWidth="lg" open={openError}>
        <IconButton
          data-testid="closeButton"
          onClick={handleClose}
          sx={{ alignSelf: "flex-end", padding: "0" }}>
          <CloseIcon />
        </IconButton>

        <CenterStack pb={5} px={5} textAlign={"center"}>
          {/* <Typography
            data-testid={"update-error-message"}
            fontWeight={600}
            component={"p"}
            color="error">
            Error
          </Typography> */}
          {message?.trim().length > 0 ? (
            <Typography component={"p"} fontSize={{ xs: "0.8rem", sm: "1rem" }} mt={2}>
              {message}
            </Typography>
          ) : (
            <>
              <Typography component={"p"} fontSize={{ xs: "0.8rem", sm: "1rem" }} mt={2}>
                Something went wrong, please try again later.
              </Typography>
            </>
          )}
          {refreshButton === true && (
            <StyledButton
              onClick={() => window.location.reload()}
              width="small"
              sx={{ marginTop: 2, fontSize: "16px" }}
              background="contained">
              Refresh
            </StyledButton>
          )}
        </CenterStack>
      </StyledDialog>
    </>
  );
};
