import { Grid, FormControl, Stack, Typography, Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  AccountDivider,
  AccountStyledCard,
  StyleAccordian,
  StyleAccordionSummary,
  StyledElement,
  VerifyText,
} from "../../../../Styles/GlobalStyles";
import { useEffect, useState } from "react";
import { ChangePassword } from "../../../../components/ChangePassword/ChangePassword";
import { Theme } from "../../../../Styles/GlobalTheme";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { LoadingSpinner } from "../../../../components/shared/LoadingSpinner/LoadingSpinner";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  RESEND_OTP_EMAIL,
  RESEND_OTP_PHONE,
  SEND_OTP_BY_EMAIL,
  SEND_OTP_BY_PHONE,
  UPDATE_CUSTOMER_CONTACT,
  VERIFY_OTP_EMAIL,
  VERIFY_OTP_PHONE,
} from "../../../Login/query";
import { formatPhoneNumber, logEvents, paymentModifiedDate } from "../../../../Services/helper";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { analytics, auth } from "../../../../Config/firebase";
import {
  updateEmailContact,
  updateTelephoneContact,
} from "../../../../Redux/Slices/CustomerInfoSlice";
import { ErrorComponent } from "../../../../components/ErrorComponent/ErrorComponent";
import { UpdateErrorComponent } from "../../../../components/shared/UpdateErrorComponent/UpdateErrorComponent";
import {
  EMAIL_SECURITY_CODE_SUCCESS_MESSAGE,
  COUNTDOWN_FOR_SEND_AND_RESEND_SMS,
  EXPIRED,
  Email_VERIFY_SUCCESS_MESSAGE,
  MY_ACCOUNT,
  NOT_MATCH,
  OTP_DOES_NOT_MATCH,
  OTP_EXPIRED,
  OTP_INVALID,
  PANEL_1,
  PHONE_SECURITY_CODE_SUCCESS_MESSAGE,
  PHONE_VERIFY_SUCCESS_MESSAGE,
  SEND_OTP_FAILED_MESSAGE,
  UPDATE_EMAIL_SUCCESS_MESSAGE,
  UPDATE_PHONENUMBER_SUCCESS_MESSAGE,
  currentDate,
  INVALID_PHONE_ERROR_MESSAGE,
} from "../../../../Constants/constants";
import { SuccessMessageModel } from "../../../../components/shared/SuccessMessageModel/SuccessMessageModel";
import { RootState } from "../../../../Redux/Store/Store";
import { EditProfile } from "./EditProfile";
import { VerificationDialog } from "../VerificationDialog/VerificationDialog";
import { usePhoneNumberValidation } from "../../../../hooks/Validation/usePhoneNumberValidation";

export interface InputProps {
  margin?: boolean;
  error?: boolean;
}

export interface IconProps {
  iconcolor?: string;
  backgroundcolor?: string;
  padding?: boolean;
  fontsize?: string;
}

export const AccountProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customerDetails = useSelector((state: any) => state.customerInfoDetails);
  const { validPhoneNumber } = useSelector((state: any) => state.billingAddress);
  const { givenNameFamilyName, emailContact, telephoneContact, contactId } = useSelector(
    (state: any) => state.customerInfoDetails
  );
  const [loading, setLoading] = useState(false);
  const [timerSeconds, setTimerSeconds] = useState<boolean>(false);
  const [otpSent, setOtpSent] = useState<string>("");
  const [openError, setOpenError] = useState<boolean>(false);
  const [error, setError] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const [userStatus, setUserStatus] = useState<string>("");
  const [verifyDate, setVerifyDate] = useState({
    phoneVerified: false,
    emailVerified: false,
  });
  const [edit, setEdit] = useState<any>({
    phone: false,
    email: false,
  });

  const [verify, setVerify] = useState<any>({
    phone: false,
    email: false,
  });

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { isAuthenticated } = useSelector((state: RootState) => state.isAuthenticated);

  const [profileForm, setProfileForm] = useState<any>({
    phoneNumber: "",
    confirmPhoneNumber: "",
    email: "",
    confirmEmail: "",
    otpPhone: "",
    otpEmail: "",
    validatePhoneNumberError: "",
  });

  const [profileErrors, setProfileErrors] = useState<any>({
    phoneNumber: "",
    confirmPhoneNumber: "",
    email: "",
    confirmEmail: "",
    otpPhone: "",
    otpEmail: "",
    validatePhoneNumberError: "",
  });

  const contactNumber = formatPhoneNumber(telephoneContact?.telephoneNumber) ?? "";
  const contactEmail = emailContact?.emailAddress ?? "";
  const telePhoneVerifiedDate = telephoneContact?.verifiedDate ?? "";
  const emailVerifiedDate = (emailContact && emailContact?.verifiedDate) ?? "";

  const [sendOTPByPhone, { loading: sendOTPByPhoneLoading }] = useMutation(SEND_OTP_BY_PHONE, {
    onError: () => {
      setOpenError(true);
      setErrorMessage(SEND_OTP_FAILED_MESSAGE);
    },
  });

  const [verifyOTPByPhone, { loading: verifyOTPByPhoneLoading }] = useMutation(VERIFY_OTP_PHONE, {
    onError: (error: any) => {
      setOpenError(true);
      setVerify({ ...verify, phone: false });
      if (error.networkError.result.errors[0]?.message?.toLowerCase()?.includes(NOT_MATCH)) {
        setProfileErrors({ ...profileErrors, otpPhone: OTP_DOES_NOT_MATCH });
      } else if (error.networkError.result.errors[0]?.message?.toLowerCase()?.includes(EXPIRED)) {
        setProfileErrors({ ...profileErrors, otpPhone: OTP_EXPIRED });
      } else {
        setProfileErrors({ ...profileErrors, otpPhone: OTP_INVALID });
      }
    },
  });

  const [resendOTPByPhone, { loading: resendOTPByPhoneLoading }] = useMutation(RESEND_OTP_PHONE, {
    onError: () => {
      setVerify({ ...verify, phone: false });
      setOpenError(true);
      setErrorMessage(SEND_OTP_FAILED_MESSAGE);
    },
  });

  const [sendOTPByEmail, { loading: sendOTPByEmailLoading }] = useMutation(SEND_OTP_BY_EMAIL, {
    onError: (error: any) => {
      if (error.networkError.result.errors[0]?.code === "422") {
        setProfileErrors({ ...profileErrors, email: error.networkError.result.errors[0]?.message });
      } else {
        setOpenError(true);
        setErrorMessage(SEND_OTP_FAILED_MESSAGE);
      }
    },
  });

  const [verifyOTPByEmail, { loading: verifyOTPByEmailLoading }] = useMutation(VERIFY_OTP_EMAIL, {
    onError: (error: any) => {
      setOpenError(true);
      setVerify({ ...verify, email: false });
      if (error.networkError.result.errors[0]?.message?.toLowerCase()?.includes(NOT_MATCH)) {
        setProfileErrors({ ...profileErrors, otpEmail: OTP_DOES_NOT_MATCH });
      } else if (error.networkError.result.errors[0]?.message?.toLowerCase()?.includes(EXPIRED)) {
        setProfileErrors({ ...profileErrors, otpEmail: OTP_EXPIRED });
      } else {
        setProfileErrors({ ...profileErrors, otpEmail: OTP_INVALID });
      }
    },
  });

  const [resendOTPByEmail, { loading: resendOTPByEmailLoading }] = useMutation(RESEND_OTP_EMAIL, {
    onError: () => {
      setOpenError(true);
      setErrorMessage(SEND_OTP_FAILED_MESSAGE);
      setVerify({ ...verify, email: false });
    },
  });

  const [updateCustomerContact, { loading: updateCustomerContactLoading }] = useMutation(
    UPDATE_CUSTOMER_CONTACT,
    {
      onError: () => {
        setOpenError(true);
      },
    }
  );
  const { phoneNumberValidation, message } = usePhoneNumberValidation(
    profileForm?.phoneNumber,
    setProfileErrors,
    profileErrors
  );

  const loader =
    sendOTPByPhoneLoading ||
    resendOTPByPhoneLoading ||
    verifyOTPByPhoneLoading ||
    sendOTPByEmailLoading ||
    verifyOTPByEmailLoading ||
    resendOTPByEmailLoading ||
    updateCustomerContactLoading ||
    loading;

  const handleEditPhone = () => {
    setEdit({ ...edit, phone: !edit.phone });
    setProfileForm({});
    setProfileErrors({});
    setError("");
  };
  const handleOtpSentTimer = () => {
    setTimeout(() => {
      setOtpSent("");
    }, 5000);
  };

  const handleEditEmail = () => {
    setEdit({ ...edit, email: !edit.email });
    setProfileForm({});
    setProfileErrors({});
    setError("");
  };

  const handleClose = () => {
    setVerify({ ...verify, phone: false, email: false });
    setProfileErrors({});
    setProfileForm({ ...profileForm, otpEmail: "", otpPhone: "" });
    setSuccessMessage(false);
  };

  const handleSendOTPPhone = async () => {
    try {
      const response = await sendOTPByPhone({
        variables: {
          firstName: givenNameFamilyName?.givenName,
          lastName: givenNameFamilyName?.familyName,
          phoneNumber: profileForm?.confirmPhoneNumber
            ? profileForm?.confirmPhoneNumber?.replace(/[() -]/g, "")
            : contactNumber,
          email: contactEmail,
        },
      });
      if (response?.data?.sendOTPByPhone?.error) {
        setOpenError(true);
        setTimerSeconds(false);
      } else if (response?.data) {
        setOtpSent(PHONE_SECURITY_CODE_SUCCESS_MESSAGE);
        handleOtpSentTimer();
        setVerify({ ...verify, phone: true });
        setTimerSeconds(true);
        setTimeout(() => {
          setTimerSeconds(false);
        }, COUNTDOWN_FOR_SEND_AND_RESEND_SMS);
        setError("");
        logEvents("ACCOUNT_SEND_OTP_PHONE");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSendOtpEmail = async () => {
    try {
      const response = await sendOTPByEmail({
        variables: {
          firstName: givenNameFamilyName?.givenName,
          lastName: givenNameFamilyName?.familyName,
          email: profileForm?.confirmEmail ? profileForm?.confirmEmail : contactEmail,
        },
      });
      if (response?.data?.sendOTPbyEmail?.error) {
        setOpenError(true);
        setError("");
      } else if (response?.data) {
        setOtpSent(EMAIL_SECURITY_CODE_SUCCESS_MESSAGE);
        handleOtpSentTimer();
        setVerify({ ...verify, email: true });
        setTimerSeconds(true);
        setTimeout(() => {
          setTimerSeconds(false);
        }, COUNTDOWN_FOR_SEND_AND_RESEND_SMS);
        setError("");
        logEvents("ACCOUNT_SEND_OTP_EMAIL");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSendOTP = () => {
    if (profileForm?.phoneNumber !== contactNumber) {
      if (edit.phone) {
        handleSendOTPPhone();
      }
    }
    if (contactEmail !== profileForm?.email?.toLocaleLowerCase()) {
      if (edit.email) {
        handleSendOtpEmail();
      }
    }
  };

  const handleResendPhone = async () => {
    if (!timerSeconds) {
      try {
        const response = await resendOTPByPhone({
          variables: {
            firstName: givenNameFamilyName?.givenName,
            lastName: givenNameFamilyName?.familyName,
            email: contactEmail,
            phoneNumber:
              profileForm?.phoneNumber?.length > 0
                ? profileForm?.phoneNumber?.replace(/[() -]/g, "")
                : contactNumber?.replace(/[() -]/g, ""),
          },
        });
        if (response?.data?.resendOTPByPhone?.error) {
          setVerify({ ...verify, phone: false });
          setOpenError(true);
        } else if (response?.data) {
          setOtpSent(PHONE_SECURITY_CODE_SUCCESS_MESSAGE);
          handleOtpSentTimer();
          setTimerSeconds(true);
          setTimeout(() => {
            setTimerSeconds(false);
          }, COUNTDOWN_FOR_SEND_AND_RESEND_SMS);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleResendEmail = async () => {
    if (!timerSeconds) {
      try {
        const response = await resendOTPByEmail({
          variables: {
            firstName: givenNameFamilyName?.givenName,
            lastName: givenNameFamilyName?.familyName,
            email: profileForm?.confirmEmail?.length > 0 ? profileForm?.confirmEmail : contactEmail,
          },
        });
        if (response?.data?.resendOTPByEmail?.error) {
          setVerify({ ...verify, email: false });
          setOpenError(true);
        } else if (response?.data) {
          setOtpSent(EMAIL_SECURITY_CODE_SUCCESS_MESSAGE);
          handleOtpSentTimer();
          setTimerSeconds(true);

          setTimeout(() => {
            setTimerSeconds(false);
          }, COUNTDOWN_FOR_SEND_AND_RESEND_SMS);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  const handleUpdateCustomerContact = async () => {
    try {
      const response = await updateCustomerContact({
        variables: {
          contactId: contactId,
          customerContactInput: {
            givenAndFamilyName: {
              givenName: givenNameFamilyName?.givenName,
              familyName: givenNameFamilyName?.familyName,
            },
            telephoneContact: {
              telephoneNumber: profileForm?.confirmPhoneNumber
                ? profileForm?.confirmPhoneNumber?.replace(/[() -]/g, "")
                : contactNumber,
              isPrimaryTelephoneContact: true,
              verifiedDate: paymentModifiedDate(currentDate),
            },
            emailContact: {
              isPrimaryEmailContact: true,
              emailAddress: contactEmail,
              newEmailAddress: profileForm?.confirmEmail,
              verifiedDate: paymentModifiedDate(currentDate),
            },
            username: MY_ACCOUNT,
          },
        },
      });
      if (response?.data?.updateCustomerContact?.error) {
        setOpenError(true);
        setEdit(false);
        setEdit({ ...edit, phone: false });
      } else if (response?.data) {
        if (edit.phone) {
          setEdit({ ...edit, phone: false });
          setSuccessMessage(true);
          setUserStatus(UPDATE_PHONENUMBER_SUCCESS_MESSAGE);
          setVerifyDate({ ...verifyDate, phoneVerified: true });
          let telephoneContact = {
            telephoneNumber: profileForm?.confirmPhoneNumber?.replace(/[() -]/g, ""),
            verifiedDate: new Date().toISOString(),
          };
          dispatch(updateTelephoneContact(telephoneContact));
        } else if (edit.email) {
          setEdit(false);
          setSuccessMessage(true);
          setUserStatus(UPDATE_EMAIL_SUCCESS_MESSAGE);
          handleEmailSuccess();
          setVerifyDate({ ...verifyDate, emailVerified: true });
          let emailContact = {
            emailAddress: profileForm?.confirmEmail,
            verifiedDate: new Date().toISOString(),
          };
          dispatch(updateEmailContact(emailContact));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleResendOTP = async () => {
    if (verify.phone) {
      handleResendPhone();
    } else {
      handleResendEmail();
    }
  };

  const handleVerifyPhone = async () => {
    try {
      const response = await verifyOTPByPhone({
        variables: {
          otp: profileForm?.otpPhone,
          phoneNumber: contactNumber?.replace(/[() -]/g, ""),
          email: contactEmail,
        },
      });
      if (response?.data) {
        if (!response?.data?.verifyOTPByPhone?.error) {
          setVerify({ ...verify, phone: false });
          setEdit({ ...edit, phone: false });
          setProfileForm({});
          let telephoneContact = {
            telephoneNumber: contactNumber,
            verifiedDate: new Date().toISOString(),
          };
          dispatch(updateTelephoneContact(telephoneContact));
          setSuccessMessage(true);
          setUserStatus(PHONE_VERIFY_SUCCESS_MESSAGE);
          logEvents("ACCOUNT_PHONE_VERIFY");
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleVerifyAndUpdatePhone = async () => {
    try {
      const response = await verifyOTPByPhone({
        variables: {
          otp: profileForm?.otpPhone,
          phoneNumber: profileForm?.confirmPhoneNumber?.replace(/[() -]/g, ""),
          updatingContact: false,
          email: contactEmail,
        },
      });
      if (response?.data) {
        if (!response?.data?.verifyOTPByPhone?.error) {
          setVerify({ ...verify, phone: false });
          setEdit({ ...edit, phone: false });
          setProfileForm({});
          logEvents("ACCOUNT_PHONE_VERIFY");
          handleUpdateCustomerContact();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleEmailSuccess = () => {
    setTimeout(() => {
      setSuccessMessage(false);
      handleReLogin();
    }, 8000);
  };

  const handleVerifyEmail = async () => {
    try {
      const response = await verifyOTPByEmail({
        variables: {
          otp: profileForm?.otpEmail,
          updatingContact: true,
          email: contactEmail,
        },
      });
      if (response?.data) {
        if (!response?.data?.verifyOTPByEmail?.error) {
          setProfileForm({});
          setVerify({ ...verify, email: false });
          let emailContact = {
            emailAddress: contactEmail,
            verifiedDate: new Date().toISOString(),
          };
          dispatch(updateEmailContact(emailContact));
          setSuccessMessage(true);
          setUserStatus(Email_VERIFY_SUCCESS_MESSAGE);
          logEvents("ACCOUNT_EMAIL_VERIFY");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleVerifyAndUpdateEmail = async () => {
    try {
      const response = await verifyOTPByEmail({
        variables: {
          otp: profileForm?.otpEmail,
          updatingContact: false,
          email: profileForm?.confirmEmail,
        },
      });
      if (response?.data) {
        if (!response?.data?.verifyOTPByEmail?.error) {
          setProfileForm({});
          setVerify({ ...verify, email: false });
          logEvents("ACCOUNT_EMAIL_VERIFY");
          handleUpdateCustomerContact();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const validatePhoneNumber = async () => {
    try {
      await phoneNumberValidation();
    } catch (err) {
      console.log(err);
    }
  };

  const handleVerifyOTP = () => {
    if (verify.phone && profileForm?.phoneNumber?.length > 0) {
      handleVerifyAndUpdatePhone();
    } else if (verify.email && profileForm?.email?.length > 0) {
      handleVerifyAndUpdateEmail();
    } else if (verify.phone && !verifyDate.phoneVerified) {
      handleVerifyPhone();
    } else if (verify.email && !verifyDate.emailVerified) {
      handleVerifyEmail();
    }
  };

  const handleReLogin = async () => {
    await signOut(auth)
      .then(() => {
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        throw new Error(err);
      });
  };

  // useEffect(() => {
  //   let interval = setInterval(() => {
  //     if (timerSeconds > 0) {
  //       setTimerSeconds(timerSeconds - 1);
  //     }

  //     if (timerSeconds === 0) {
  //       clearInterval(interval);
  //     }
  //   }, 1000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [timerSeconds]);

  useEffect(() => {
    setVerifyDate({
      ...verifyDate,
      phoneVerified: telePhoneVerifiedDate?.trim().length > 0,
      emailVerified: emailVerifiedDate?.trim().length > 0,
    });
  }, [telePhoneVerifiedDate, emailVerifiedDate]);

  const VerifyInput = ({ verified, handleVerify }: any) => {
    return (
      <>
        {verified ? (
          <Stack direction={"row"} alignItems={"center"} spacing={0.2} justifyContent={"center"}>
            <CheckCircleIcon fontSize="inherit" color="success" />
            <VerifyText color={Theme.palette.primary.main}>Verified</VerifyText>
          </Stack>
        ) : (
          <StyledElement fontSize={"12px"} onClick={handleVerify}>
            VERIFY
          </StyledElement>
        )}
      </>
    );
  };
  return (
    <>
      <AccountStyledCard sx={{ textDecoration: "none" }}>
        {customerDetails && Object.keys(customerDetails)?.length === 0 ? (
          <ErrorComponent />
        ) : (
          <Box
            padding={"1rem 1.2rem"}
            mb={edit.phone || edit.email ? "1rem" : "0"}
            bgcolor={
              edit.phone || edit.email ? Theme.palette.customcolor.accountbackground : "white"
            }>
            <Typography component={"p"} fontWeight={"600"} mb={2}>
              Contact info
            </Typography>
            {edit.phone || edit.email ? (
              <form>
                <EditProfile
                  edit={edit}
                  profileForm={profileForm}
                  profileErrors={profileErrors}
                  error={error}
                  setError={setError}
                  setProfileForm={setProfileForm}
                  setProfileErrors={setProfileErrors}
                  handleSendOTP={handleSendOTP}
                  handleEditEmail={handleEditEmail}
                  handleEditPhone={handleEditPhone}
                  validatePhoneNumber={validatePhoneNumber}
                />
              </form>
            ) : (
              <Grid container direction="row" justifyContent={"space-between"}>
                <Grid item xs={12} mb={{ xs: "26px", sm: "0px" }} sm={5.8}>
                  <FormControl fullWidth>
                    <Stack
                      gap={2}
                      alignItems={"center"}
                      direction={"row"}
                      justifyContent={{ xs: "space-between", sm: "flex-start" }}
                      mb={1}>
                      <Typography
                        component={"p"}
                        color={Theme.palette.customcolor.profileTypoGraphyColor}
                        fontSize={"0.8rem"}>
                        Mobile number
                      </Typography>
                      <StyledElement
                        fontSize={"0.8rem"}
                        ml={2.5}
                        data-testid={"phoneEdit"}
                        onClick={handleEditPhone}>
                        EDIT
                      </StyledElement>
                    </Stack>
                    <Stack direction={"row"} spacing={2} alignItems={"center"} flexWrap={"wrap"}>
                      <Typography component={"span"} data-testid={"phonetest"}>
                        {contactNumber ?? ""}
                      </Typography>
                      {contactNumber && (
                        <VerifyInput
                          verified={verifyDate?.phoneVerified}
                          handleVerify={handleSendOTPPhone}
                        />
                      )}
                    </Stack>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={5.8}>
                  <FormControl fullWidth>
                    <Stack
                      gap={2}
                      alignItems={"center"}
                      direction={"row"}
                      justifyContent={{ xs: "space-between", sm: "flex-start" }}
                      mb={1}>
                      <Typography
                        component={"p"}
                        color={Theme.palette.customcolor.profileTypoGraphyColor}
                        fontSize={"0.8rem"}>
                        Email address
                      </Typography>
                      <StyledElement
                        data-testid={"editEmail"}
                        fontSize={"0.8rem"}
                        ml={2}
                        onClick={handleEditEmail}>
                        EDIT
                      </StyledElement>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} spacing={2} flexWrap={"wrap"}>
                      <Typography data-testid="emailtest">{contactEmail ?? ""}</Typography>
                      {contactEmail && (
                        <VerifyInput
                          verified={verifyDate?.emailVerified}
                          handleVerify={handleSendOtpEmail}
                        />
                      )}
                    </Stack>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </Box>
        )}
        <AccountDivider />
        {!isAuthenticated && (
          <StyleAccordian
            disableGutters
            elevation={0}
            expanded={expanded === PANEL_1}
            onChange={handleChange(PANEL_1)}>
            <StyleAccordionSummary
              expandIcon={
                <StyledElement>
                  <ExpandMoreIcon />
                </StyledElement>
              }>
              <StyledElement fontSize={{ xs: "0.8rem", sm: "1rem" }}>CHANGE PASSWORD</StyledElement>
            </StyleAccordionSummary>
            <ChangePassword expanded={expanded} handleChange={handleChange} />
          </StyleAccordian>
        )}
      </AccountStyledCard>
      {(verify.phone || verify.email) && (
        <VerificationDialog
          verifyDate={verifyDate}
          verify={verify}
          setProfileForm={setProfileForm}
          setProfileErrors={setProfileErrors}
          profileForm={profileForm}
          profileErrors={profileErrors}
          handleClose={handleClose}
          handleResendOTP={handleResendOTP}
          handleVerifyOTP={handleVerifyOTP}
          otpSent={otpSent}
          timerSeconds={timerSeconds}
        />
      )}
      {successMessage && (
        <SuccessMessageModel
          successMessage={successMessage}
          userStatus={userStatus}
          setSuccessMessage={setSuccessMessage}
        />
      )}
      {openError && (
        <UpdateErrorComponent
          message={validPhoneNumber ? message : errorMessage}
          setErrorMessage={setErrorMessage}
          openError={openError}
          setOpenError={setOpenError}
        />
      )}
      {loader === true && <LoadingSpinner />}
    </>
  );
};
