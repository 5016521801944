import { useEffect, useState } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { StyledCard, ButtonStack, StyledElement, StyledA } from "../../../../Styles/GlobalStyles";
import { getDollarSign } from "../../../../Services/helper";
import { useLocation, useNavigate } from "react-router-dom";
import { RecurringBillDueDate } from "../../../../Pages/Home/Components/RecurringBillStatus/RecurringBillDueDate";
import { ErrorComponent } from "../../../../components/ErrorComponent/ErrorComponent";
import { useDispatch, useSelector } from "react-redux";
import { EmptyDataComponent } from "../../../../components/shared/EmptyDataComponent/EmptyDataComponent";
import Button from "../../../../components/Button/Button";
import {
  DUPLICATE_PAYMENT_HEADING,
  DUPLICATE_PAYMENT_PRIMARY_CONTENT,
  DUPLICATE_PAYMENT_SECONDARY_CONTENT,
  DUPLICATE_PAYMENT_TERTIARY_CONTENT,
  FETCHING_BILLING_DETAILS_MESSAGE,
  MAKE_A_PAYMENT_PRIMARYCONTENT,
  TOTAL_BALANCE_EMPTYDATA_MESSAGE,
} from "../../../../Constants/constants";
import { Theme } from "../../../../Styles/GlobalTheme";
import { Spinner } from "../../../../components/shared/LoadingSpinner/Spinner";
import { ConfirmationModel } from "../../../../components/shared/ConfirmationModel/ConfirmationModel";
import TimerIcon from "../../../../assets/icons/mingcute_time-line.svg";
import { setMakePaymentRoute } from "../../../../Redux/Slices/PageSlice";

export const TotalBalanceCard = (props: any) => {
  const [dialogData, setDialogData] = useState<any>({
    primaryContent: "",
    containedButton: { onClick: () => {}, buttonType: "", buttonTitle: "" },
  });
  const {
    home,
    getbillingDetails,
    billingLoading,
    billingError,
    autopayPaperlessLoading,
    billingDetails,
  } = props;
  const { autoPayData } = useSelector((state: any) => state?.autopayPaperlessDetails);
  const { ziplyPlanName, currentDueBalance, error, paymentConfirmationMessage } = useSelector(
    (state: any) => state.customerPlanDetails
  );
  const { inactivatePaymentStatus } = useSelector((state: any) => state.paymentDetails);
  const { activeAccount } = useSelector((state: any) => state?.customerInfoDetails);
  const { showAutopay, isNewCustomer } = useSelector((state: any) => state.customerPlanDetails);
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const price = currentDueBalance;

  const handleClose = () => {
    setOpen(false);
    navigate({ pathname: "/billing", hash: "#transactions" });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleAutopay = () => {
    if (paymentConfirmationMessage?.length > 0) {
      setOpen(true);
      setDialogData({
        heading: DUPLICATE_PAYMENT_HEADING,
        primaryContent: DUPLICATE_PAYMENT_PRIMARY_CONTENT,
        secondaryContent: DUPLICATE_PAYMENT_SECONDARY_CONTENT,
        tertiaryContent: DUPLICATE_PAYMENT_TERTIARY_CONTENT,
        containedButton: {
          buttonType: "root",
          buttonTitle: "MAKE ANOTHER PAYMENT",
        },
        outlinedButton: {
          buttonType: "root",
          buttonTitle: "CHECK RECENT TRANSACTIONS",
        },
      });
    } else if (autoPayData && Object.keys(autoPayData)?.length > 0) {
      setOpen(true);
      setDialogData({
        primaryContent: MAKE_A_PAYMENT_PRIMARYCONTENT,
        containedButton: {
          buttonType: "root",
          buttonTitle: "YES, PROCEED",
        },
      });
    } else {
      navigate("/billing/makeapayment");
      dispatch(setMakePaymentRoute(location?.pathname));
    }
  };

  const handleNavigate = () => {
    navigate("/billing/makeapayment");
  };

  const handleManageAutoPay = () => {
    navigate({ pathname: "/billing/manageautopay" }, { state: home });
  };

  const handleRefresh = () => {
    getbillingDetails();
  };

  const handleSignupAutopay = () => {
    navigate("/billing/signupautopay", {
      state: {
        NavigateToHome: true,
      },
    });
  };
  const handleNavigateCurrentBill = () => {
    navigate({ pathname: "/billing", hash: "#currentBill" });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const ZERO_BALANCE = error === false && currentDueBalance === null && ziplyPlanName.length > 0;
  return (
    <>
      <StyledCard padding="totalbalance">
        {billingLoading ? (
          <Box my={5}>
            <Spinner loadingText={FETCHING_BILLING_DETAILS_MESSAGE} />
          </Box>
        ) : (
          <>
            {error || billingError ? (
              <ErrorComponent onRefresh={handleRefresh} data-testid="error-message" />
            ) : (
              <Box>
                <Typography
                  component={"p"}
                  fontSize={{ xs: "1rem", sm: "1.3125rem" }}
                  color={activeAccount === false ? "" : "green"}
                  data-test-id="plan-name">
                  {activeAccount === false ? "Disconnected Account" : ziplyPlanName}
                </Typography>

                <Typography
                  component={"span"}
                  fontSize={{ xs: "1rem", sm: "1.6rem" }}
                  fontWeight={"bold"}
                  data-test-id="price">
                  {price ? `${getDollarSign(price)}` : getDollarSign(0)}&nbsp;
                  <Typography
                    component={"span"}
                    color={Theme.palette.customcolor.profileTypoGraphyColor}
                    fontSize={{ xs: "14px", sm: "16px" }}>
                    is your current balance
                  </Typography>
                </Typography>
                {paymentConfirmationMessage && (
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <img src={TimerIcon} alt="timer" />
                    <Typography
                      color={Theme.palette.secondary.main}
                      fontSize={{ xs: "14px", sm: "16px" }}>
                      {paymentConfirmationMessage}
                    </Typography>
                  </Stack>
                )}
                <Box my={1}>
                  <RecurringBillDueDate />
                  <ButtonStack mt={home ? 1.5 : 2} mb={home && 2}>
                    {home && activeAccount && showAutopay && !isNewCustomer ? (
                      <>
                        {!autopayPaperlessLoading &&
                          (autoPayData && Object.keys(autoPayData)?.length > 0 ? (
                            <>
                              <Button
                                type="pay"
                                title="Manage Autopay"
                                onClick={handleManageAutoPay}
                              />
                            </>
                          ) : (
                            <>
                              <Button
                                type="pay"
                                title="Set up Autopay"
                                onClick={handleSignupAutopay}
                              />
                            </>
                          ))}
                        <Button
                          type="payment"
                          title="View Your Bill"
                          onClick={handleNavigateCurrentBill}
                        />
                      </>
                    ) : (
                      <>
                        <Button
                          onClick={handleAutopay}
                          disabled={
                            (inactivatePaymentStatus && !activeAccount) ||
                            (!activeAccount && parseInt(currentDueBalance) <= 0) ||
                            (currentDueBalance === null && !activeAccount) ||
                            !showAutopay
                          }
                          title="Make a payment"
                          type="root"
                        />
                      </>
                    )}
                  </ButtonStack>
                </Box>
                {autoPayData && Object.keys(autoPayData)?.length > 0 && (
                  <ConfirmationModel
                    openDialog={open}
                    setOpenDialog={setOpen}
                    dialogData={dialogData}
                    setDialogData={setDialogData}
                    onClick={handleNavigate}
                  />
                )}
                <ConfirmationModel
                  openDialog={open}
                  setOpenDialog={setOpen}
                  dialogData={dialogData}
                  setDialogData={setDialogData}
                  onClick={handleNavigate}
                  onClickSecondary={handleClose}
                />
              </Box>
            )}
            {home && (
              <Box>
                <Divider />
                <StyledA
                  target="_blank"
                  href="https://ziplyfiber.com/helpcenter/categories/billing">
                  <StyledElement pt={2} fontSize={"0.8rem"}>
                    NEED BILLING HELP?
                  </StyledElement>
                </StyledA>
              </Box>
            )}
          </>
        )}
      </StyledCard>
    </>
  );
};
