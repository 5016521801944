import { Box } from "@mui/material";
import { StyledCard, StyledElement } from "../../../../Styles/GlobalStyles";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export default function OrderAndServiceTicketHistory() {
  const navigate = useNavigate();
  function handleServiceTicketRedirect() {
    navigate("/account/orderandservicetickethistory");
  }

  return (
    <>
      <Box>
        <StyledCard>
          <StyledElement
            fontSize={{ xs: "12px", sm: "16px" }}
            onClick={handleServiceTicketRedirect}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            VIEW ORDERS AND SERVICE TICKETS
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <ArrowForwardIosIcon style={{ height: "15px" }} />
            </Box>
          </StyledElement>
        </StyledCard>
      </Box>
    </>
  );
}


