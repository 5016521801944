import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Theme } from "../../../../Styles/GlobalTheme";
import Button from "../../../../components/Button/Button";
import { ImappingData } from "../TroubleTicketStatus/TroubleTicketStatus";
import {
  CenterStack,
  SpacerSmall,
  StyledA,
  StyledCard,
  StyledDivider,
} from "../../../../Styles/GlobalStyles";
import {
  Box,
  Grid,
  Stack,
  Typography,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";

interface IReschedule {
  data: ImappingData[];
  appointments: any;
  handleSelectAppointment: any;
  selectedDate: string;
  setSelectedAppointment: React.Dispatch<React.SetStateAction<object>>;
  selectedTimeSlot: string;
  setSelectedTimeSlot: React.Dispatch<React.SetStateAction<string>>;
  setConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
  fetchLoading?: boolean;
}

export const Reschedule = ({
  data,
  appointments,
  handleSelectAppointment,
  selectedDate,
  setSelectedAppointment,
  selectedTimeSlot,
  setSelectedTimeSlot,
  setConfirmationModal,
  fetchLoading,
}: IReschedule) => {
  const { isOrder } = useSelector((state: any) => state.orderDetails);
  return (
    <>
      <>
        <StyledCard>
          <b>Original Appointment Details</b>
          <Grid container direction="row" justifyContent={"space-between"} gap={2} my={2}>
            {data?.map((item) => {
              return (
                <>
                  {item?.value?.trim?.()?.length > 0 && (
                    <Grid item xs={12} mb={{ xs: "26px", sm: "0px" }} sm={5.8}>
                      <Stack
                        alignItems={"center"}
                        direction={"row"}
                        justifyContent={{ xs: "space-between", sm: "flex-start" }}>
                        <Typography
                          component={"p"}
                          color={Theme.palette.customcolor.profileTypoGraphyColor}>
                          {item?.name}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} spacing={2} alignItems={"center"} flexWrap={"wrap"}>
                        <Typography component={"span"} variant="subtitle2">
                          {item?.value}
                        </Typography>
                      </Stack>
                    </Grid>
                  )}
                </>
              );
            })}
            <Box display={isOrder ? "block" : "none"}>
              <Typography>
                To reschedule this appointment please select the date and time below.
              </Typography>
              <SpacerSmall />
              <Typography color={Theme.palette.customcolor.customText}>
                If you need to change or modify your order please Chat or Call us and we can assist
                with making those changes.
              </Typography>
              <StyledA href="tel:1-866-699-4759">1-866-699-4759</StyledA>
            </Box>
          </Grid>
          <StyledDivider />

          {appointments && Object.keys(appointments)?.length === 0 && !fetchLoading ? (
            <Box>
              <Stack spacing={2} mt={2}>
                <Typography fontWeight={600}>
                  We are unable to retrieve reschedule time slots at the moment.
                </Typography>
                <Typography>
                  Kindly wait for few moment as we attempt to retrieve the available slots for you.
                </Typography>
                <Typography>
                  If the problem persists please contact our customer care to facilitate your order
                  rescheduling efficiently.
                </Typography>
                <Typography>
                  Live Chat :&nbsp;
                  <b style={{ color: Theme.palette.secondary.main, cursor: "pointer" }}>
                    Contact Us
                  </b>
                </Typography>
                <Typography>
                  Customer Care :&nbsp;
                  <StyledA href="tel:1-866-699-4759">1-866-699-4759</StyledA>
                </Typography>
              </Stack>
            </Box>
          ) : (
            !fetchLoading && (
              <>
                <Typography fontWeight={600} component={"p"} my={2}>
                  Reschedule Appointment
                </Typography>

                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">Select Date</FormLabel>
                </FormControl>

                {Object.keys(appointments)?.length > 0 && (
                  <>
                    <Grid container maxHeight={250} overflow={"auto"}>
                      <>
                        {Object.keys(appointments)?.map((appointment, idx) => {
                          return (
                            <>
                              <Grid item xs={12} mb={{ xs: "26px", sm: "0px" }} sm={5.8}>
                                <Stack>
                                  <StyledCard
                                    onClick={() => handleSelectAppointment(appointment)}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      cursor: "pointer",
                                      m: 2,
                                      border:
                                        appointment === selectedDate
                                          ? `2px solid ${Theme.palette.secondary.main}`
                                          : "none",
                                    }}>
                                    {moment(appointment).format("dddd, MMMM DD")}
                                  </StyledCard>
                                </Stack>
                              </Grid>
                            </>
                          );
                        })}
                      </>
                    </Grid>
                  </>
                )}

                <FormControl sx={{ my: 4 }}>
                  {appointments.hasOwnProperty(selectedDate) && (
                    <>
                      <FormLabel id="demo-radio-buttons-group-label">Select Time</FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group">
                        {appointments?.[selectedDate]?.map((time: any, idx: any) => {
                          const timeRange = `${time?.startTime}-${time?.endTime}`;
                          const [startTime, endTime] = timeRange?.split("-");
                          const formattedStartTime = moment(startTime, "HH:mm:ss").format(
                            "hh:mm A"
                          );
                          const formattedEndTime = moment(endTime, "HH:mm:ss").format("hh:mm A");
                          const formattedTimeRange = `${formattedStartTime} - ${formattedEndTime}`;

                          return (
                            <>
                              <FormControlLabel
                                onChange={(e) => {
                                  setSelectedTimeSlot(formattedTimeRange);
                                  setSelectedAppointment(time);
                                }}
                                value={time?.startTime}
                                checked={selectedTimeSlot === formattedTimeRange}
                                key={idx}
                                control={<Radio />}
                                label={formattedTimeRange}
                              />
                            </>
                          );
                        })}
                      </RadioGroup>
                    </>
                  )}
                </FormControl>

                {appointments.hasOwnProperty(selectedDate) && (
                  <>
                    <CenterStack>
                      <Button
                        disabled={!selectedTimeSlot}
                        onClick={() => setConfirmationModal(true)}
                        title="next"
                        type="payment"
                      />
                    </CenterStack>
                  </>
                )}
              </>
            )
          )}
        </StyledCard>
      </>
    </>
  );
};
