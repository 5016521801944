import { Stack } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
export default function Payment() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [paymentUrl, setPaymentUrl] = React.useState<string>("");

  React.useEffect(() => {
    if (query.get("url") !== null) {
      setPaymentUrl(query.get("url") || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack alignItems={"center"} my={2}>
      <div id="secureFrameWrapper">
        {paymentUrl?.length > 0 && (
          <iframe
            title="payment"
            src={paymentUrl}
            className="secureFrame"
            id="secureFrame"
            style={{ border: 0, minHeight: "100vh" }}
            width={window.screen.width - 20}></iframe>
        )}
      </div>
    </Stack>
  );
}
