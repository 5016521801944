import { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material";
import { KeyAccountInfo } from "./Components/KeyAccountInfo/KeyAccountInfo";
import { AccountPlansServices } from "./Components/PlansServices/PlansServices";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { useLazyQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { GET_BILLING_PREFERENCES, GET_NOTIFICATION_PREFERENCES, GET_PLANS_SERVICES } from "./query";
import {
  CardHeading,
  PageGrid,
  PageNameTypography,
  StickyBox,
  StyledCard,
} from "../../Styles/GlobalStyles";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner/LoadingSpinner";
import { NeedHelpCard } from "../../components/shared/NeedHelpCard/NeedHelpCard";
import { AutoPay } from "../../components/shared/TotalBalance/AutoPay/AutoPay";
import { AccountProfile } from "../../Pages/Account/Components/Profile/Profile";
import { PaperLessBilling } from "../../components/shared/TotalBalance/PaperLessBilling/PaperLessBilling";
import { AccountBillingPreference } from "../../Pages/Account/Components/BillingPref/BillingPref";
import { Settings } from "./Components/AccountSettings/Settings";
import OrderAndServiceTicketHistory from "./Components/OrderAndServiceHistory/OrderAndServiceTicketHistoryRedirect";
import { Spinner } from "../../components/shared/LoadingSpinner/Spinner";
import { ACCOUNT, AUTOPAY_PAPERLESS_LOADING_MESSAGE } from "../../Constants/constants";
import { GET_CUSTOMER_SERVICES_API } from "./Components/OrderUpgrades/query";
import { useLocation } from "react-router-dom";
import InactivateAccountBanner from "../../components/shared/InactiveAccountBanner/InactiveAccountBanner";
import Button from "../../components/Button/Button";
import { Theme } from "../../Styles/GlobalTheme";
import { ConfirmationModel } from "../../components/shared/ConfirmationModel/ConfirmationModel";
import { ErrorPopup } from "./Components/OrderUpgrades/ErrorPopup";
import { getFlags, splitMessageBybraces } from "../../Services/helper";
import ContactSupport from "../../components/shared/ContactSupport/ContactSupport";
import ImportantUpdatesBanner from "../../components/ImportantUpdatesBanner/ImportantUpdatesBanner";

export const Account = ({
  customerInfoLoading,
  autopayPaperlessLoading,
  ordersLoading,
  billingLoading,
  handleCancelAutopay,
  cancelAutopayLoading,
  dialogData,
  setTurnOff,
  turnOff,
  showAutoPayError,
  setShowAutoPayError,
  plansServicesError,
  plansServicesLoading,
  plansServicesInfo,
  getPlansServices,
  billingError,
}: any) => {
  const customerDetails = useSelector((state: any) => state.customerInfoDetails);
  const { paymentWarning } = useSelector((state: any) => state?.userInfo);
  const { showAutopay, paymentsMigrationToNDS, NDSInprogressAccount } = useSelector(
    (state: any) => state.customerPlanDetails
  );
  const { autoPayData } = useSelector((state: any) => state?.autopayPaperlessDetails);
  const { activeAccount } = useSelector((state: any) => state?.customerInfoDetails);
  const envFlags = useSelector((state: any) => state?.userInfo?.envFlags);
  const INACTIVE_ACCOUNTS = getFlags("inactive_accounts", envFlags);
  const hideInactiveFeature = INACTIVE_ACCOUNTS ? activeAccount !== false : true;
  const SHOW_CUSTOMER_SERVICE = getFlags("customer_subscribed_services", envFlags);
  const SHOW_DISABLE_AUTOPAY = getFlags("show_disableAutoPay_banner", envFlags);
  const { scrollPosition } = useSelector((state: any) => state.pageInfo);

  const [
    getBillingPreferences,
    {
      error: billingPreferencesError,
      loading: billingPreferencesLoading,
      data: billingPreferencesInfo,
    },
  ] = useLazyQuery(GET_BILLING_PREFERENCES, {
    variables: {
      accountNumber: customerDetails?.accountNumber,
    },
  });

  const [
    getNotificationPreference,
    {
      error: notificationPreferenceError,
      loading: notificationPreferenceLoading,
      data: notificationPreference,
    },
  ] = useLazyQuery(GET_NOTIFICATION_PREFERENCES, {
    variables: {
      accountNumber: customerDetails?.accountNumber,
      contactId: customerDetails?.contactId,
    },
    fetchPolicy: "network-only",
  });

  const [getCustomerServices, { data: customerServicesData, error: customerServicesError }] =
    useLazyQuery(GET_CUSTOMER_SERVICES_API, {
      variables: {
        accountNumber: customerDetails?.accountNumber,
        isNds: customerDetails?.ndsObj?.isNDS,
      },
      fetchPolicy: "network-only",
    });

  const customerServices = customerServicesData?.customerSubscribedServices;

  const refreshPlansServices = () => {
    getPlansServices();
  };

  const refreshBillingPreferences = () => {
    getBillingPreferences({ fetchPolicy: "network-only" });
  };

  useEffect(() => {
    if (
      customerDetails?.accountNumber &&
      customerDetails?.ndsObj?.isNDS !== undefined &&
      SHOW_CUSTOMER_SERVICE
    ) {
      getCustomerServices();
    }
  }, [customerDetails?.accountNumber, customerDetails?.ndsObj?.isNDS]);

  const billingAddress = billingPreferencesInfo?.billingPreferences?.billingAddrress;
  const planAndServicesInfo = plansServicesInfo?.plansAndServices;
  const notificationPref = notificationPreference?.notificationPreferences;
  const fallOut = plansServicesInfo?.plansAndServices[0]?.fallOut;

  interface AccountComponentsProps {
    name?: string;
    component?: ReactJSXElement;
  }

  const accountComponents: AccountComponentsProps[] = [
    {
      name: "Key account information",
      component: <KeyAccountInfo customerInfoLoading={customerInfoLoading} />,
    },
    {
      component: (
        <>
          {INACTIVE_ACCOUNTS &&
            SHOW_DISABLE_AUTOPAY &&
            activeAccount === false &&
            !customerInfoLoading &&
            autoPayData &&
            Object.keys(autoPayData)?.length > 0 && (
              <StyledCard>
                <Typography variant="h6" fontSize="1.3rem">
                  Disable your Autopay
                </Typography>
                <Typography
                  mb={1.5}
                  color={Theme.palette.customcolor.profileTypoGraphyColor}
                  fontSize={{ xs: "14px", sm: "16px" }}>
                  Autopay is currently active on your account. Since this account is inactive,
                  please turn off Autopay to avoid any issues.
                </Typography>
                <Box display="flex" justifyContent="center">
                  <Button type="payment" title="DISABLE AUTOPAY" onClick={handleCancelAutopay} />
                </Box>
              </StyledCard>
            )}
        </>
      ),
    },
    {
      name: "Profile",
      component: <AccountProfile />,
    },
    {
      name: "Billing information",
      component: (
        <AccountBillingPreference
          billingPreferencesLoading={billingPreferencesLoading}
          billingPreferencesError={billingPreferencesError}
          billingPreferencesInfo={billingPreferencesInfo}
          onRefresh={refreshBillingPreferences}
          billingAddress={billingAddress}
        />
      ),
    },
    {
      component: (
        <>
          {hideInactiveFeature && (
            <>
              {autopayPaperlessLoading ? (
                <StyledCard sx={{ paddingY: 10 }}>
                  <Spinner loadingText={AUTOPAY_PAPERLESS_LOADING_MESSAGE} />
                </StyledCard>
              ) : (
                !(
                  (!paymentsMigrationToNDS && !customerDetails.ndsObj.isNDS) ||
                  (customerDetails.ndsObj.isNDS && NDSInprogressAccount)
                ) && (
                  <Grid mb={2} container justifyContent={"space-between"} gap={{ xs: 3, sm: 0 }}>
                    <Grid item xs={12} md={5.8}>
                      <AutoPay type={ACCOUNT} billingError={billingError} />
                    </Grid>
                    <Grid item xs={12} md={5.8}>
                      <PaperLessBilling billingError={billingError} />
                    </Grid>
                  </Grid>
                )
              )}
            </>
          )}
        </>
      ),
    },
    {
      name: hideInactiveFeature ? "Plans and services" : "",
      component: (
        <>
          {hideInactiveFeature && (
            <AccountPlansServices
              autopayPaperlessLoading={autopayPaperlessLoading}
              plansServicesError={plansServicesError}
              plansServicesLoading={plansServicesLoading}
              onRefresh={refreshPlansServices}
              planAndServicesInfo={planAndServicesInfo}
              Internet={customerServices?.internet}
              Phone={customerServices?.phone}
              customerServicesError={customerServicesError}
              ordersLoading={ordersLoading}
              showCustomerSubscribedServices={SHOW_CUSTOMER_SERVICE}
              fallOut={fallOut}
            />
          )}
        </>
      ),
    },
    {
      name: "Order And Service Ticket History",
      component: <OrderAndServiceTicketHistory />,
    },

    {
      name: "Settings",
      component: (
        <Settings
          getNotificationPreference={getNotificationPreference}
          notificationPreferenceError={notificationPreferenceError}
          notificationPref={notificationPref}
          notificationPreferenceLoading={notificationPreferenceLoading}
        />
      ),
    },
  ];

  const blankAccountComponents: AccountComponentsProps[] = [
    {
      name: "Key account information",
      component: <KeyAccountInfo customerInfoLoading={customerInfoLoading} />,
    },
    {
      name: "Profile",
      component: <AccountProfile />,
    },
    {
      name: "Settings",
      component: (
        <Settings
          getNotificationPreference={getNotificationPreference}
          notificationPreferenceError={notificationPreferenceError}
          notificationPref={notificationPref}
          notificationPreferenceLoading={notificationPreferenceLoading}
        />
      ),
    },
  ];

  useEffect(() => {
    if (customerDetails?.accountNumber?.trim()?.length > 0) {
      getBillingPreferences();
      getNotificationPreference();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDetails?.accountNumber]);

  const autopayMessge = splitMessageBybraces(paymentWarning?.autopayMessage);
  const ShowAutopayContent = {
    showAutopay: true,
    title: autopayMessge[0],
    autopayDescription: autopayMessge[1],
    autoPaySubDiscription: autopayMessge[2],
  };

  return (
    <>
      <PageGrid container>
        <Grid item xs={12} lg={8.3}>
          {INACTIVE_ACCOUNTS &&
            activeAccount === false &&
            !customerInfoLoading &&
            !billingLoading &&
            customerDetails?.linkedAccountNumbers?.length > 0 && <InactivateAccountBanner />}

          {!showAutopay &&
            hideInactiveFeature &&
            !customerInfoLoading &&
            !billingLoading &&
            !billingError &&
            customerDetails?.accountNumber && (
              <ImportantUpdatesBanner content={ShowAutopayContent} />
            )}

          <PageNameTypography>Account</PageNameTypography>
          {customerDetails?.linkedAccountNumbers?.length > 0 ? (
            <>
              {accountComponents?.map(({ name, component }) => {
                return (
                  <Box key={name}>
                    <CardHeading pb={1} variant="h6" fontWeight={600}>
                      {name}
                    </CardHeading>
                    {component}
                  </Box>
                );
              })}
            </>
          ) : (
            <>
              {blankAccountComponents?.map(({ name, component }) => {
                return (
                  <Box key={name}>
                    <CardHeading variant="h6" fontWeight={600}>
                      {name}
                    </CardHeading>
                    {component}
                  </Box>
                );
              })}
            </>
          )}
          <ConfirmationModel
            dialogData={dialogData}
            openDialog={turnOff}
            setOpenDialog={setTurnOff}
            onClick={() => setTurnOff(false)}
          />
          <ErrorPopup openError={showAutoPayError} setOpenError={setShowAutoPayError} />
          {cancelAutopayLoading && <LoadingSpinner />}
        </Grid>
        <Grid item display={{ xs: "block", lg: "none" }} xs={12}>
          <NeedHelpCard />
        </Grid>
      </PageGrid>
      <StickyBox pt={1.5} display={{ xs: "none", lg: scrollPosition > 1200 ? "none" : "block" }}>
        <NeedHelpCard />
      </StickyBox>
    </>
  );
};

export const Container = styled(Box)(({ theme }) => ({
  padding: "1rem",
}));
