import { StyledDialog } from "../../../Styles/GlobalStyles";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const LinkedAccountCard = (props: any) => {
  const { open, setOpen, formData } = props;

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <StyledDialog maxWidth="lg" open={open}>
        <IconButton
          data-testid="close-button"
          sx={{ alignSelf: "flex-end", padding: 0 }}
          onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Box padding={{ xs: "1rem 1rem 2rem 1rem", xm: "1rem 6rem 2rem 6rem" }}>
          <Stack gap={1} alignItems={"center"} justifyContent={"center"}>
            <Typography fontSize={{ xs: "12pxrem", sm: "16px" }} component={"span"}>
              {formData?.email ? "The email" : "The account number "}
            </Typography>
            <Typography fontWeight={600} component={"p"} fontSize={{ xs: "14px", sm: "18px" }}>
              {formData?.email}&nbsp;
              {formData?.billingAccountNumber}&nbsp;
            </Typography>
            <Typography
              component={"span"}
              textAlign={"center"}
              fontSize={{ xs: "12px", sm: "16px" }}>
              {formData?.email
                ? "is already added to authorised users."
                : "is already linked to a profile."}
            </Typography>
          </Stack>
        </Box>
      </StyledDialog>
    </>
  );
};
