import { configureStore } from "@reduxjs/toolkit";
import { customerInfoDetailsReducer } from "../Slices/CustomerInfoSlice";
import { billingAddressReducer } from "../Slices/AccountSlice";
import { linkedAccountDetailsReducer } from "../../components/shared/Account/CustomAccountCard/CustomAccountCardSlice";
import { editAutoPayReducer } from "../Slices/EditAutoPaySlice";
import { customerPlanDetailsReducer } from "../Slices/PlanDetailsSlice";
import { userInfoReducer } from "../Slices/UserSlice";
import { autopayPaperlessReducer } from "../Slices/AutoPayPaperlessSlice";
import { paymentDetailReducer } from "../Slices/PaymentSlice";
import { supportDetailsReducer } from "../Slices/SupportSlice";
import { adminUserName, isAdminFlagReducer } from "../Slices/IsAdminSlice";
import { billingInfoReducer } from "../Slices/BillingInfoSlice";
import { adminAuthorization } from "../Slices/AdminAuthSlice";
import { pageReducer } from "../Slices/PageSlice";
import { appProposalReducer } from "../Slices/AppProposalSlice";
import { orderDetailReducer } from "../Slices/OrderDetailsSlice";
import { speedAnimationReducer } from "../Slices/SpeedTestAnimationSlice";
import { signInReducer } from "../Slices/SignInSlice";
import { troubleTicketReducer } from "../Slices/TroubleTicketSlice";
import { networkStatusReducer } from "../Slices/NetworkStatusSlice";
import { orderAndServiceTicketHistorySliceReducer } from "../Slices/OrderAndServiceTicketSLice";
import { orderUpgradeReducer } from "../Slices/OrderUpgradeSlice";
import { combineReducers } from "@reduxjs/toolkit";
import { resetStateExcept } from "../../Services/helper";

const appReducer = combineReducers({
  customerInfoDetails: customerInfoDetailsReducer,
  billingAddress: billingAddressReducer,
  linkedAccountDetails: linkedAccountDetailsReducer,
  editAutoPay: editAutoPayReducer,
  customerPlanDetails: customerPlanDetailsReducer,
  userInfo: userInfoReducer,
  autopayPaperlessDetails: autopayPaperlessReducer,
  paymentDetails: paymentDetailReducer,
  supportDetails: supportDetailsReducer,
  isAdminFlag: isAdminFlagReducer,
  adminUserName: adminUserName,
  billingInfo: billingInfoReducer,
  isAuthenticated: adminAuthorization,
  pageInfo: pageReducer,
  appProposal: appProposalReducer,
  speedTestAnimation: speedAnimationReducer,
  orderDetails: orderDetailReducer,
  signInDetails: signInReducer,
  troubleTicketDetails: troubleTicketReducer,
  networkStatusDetails: networkStatusReducer,
  orderAndServiceTicketHistory: orderAndServiceTicketHistorySliceReducer,
  OrderUpgradeDetails: orderUpgradeReducer,
}); 

const rootReducer = (state: any, action: any) => {
  switch (action.type) {
    case "EXIT_ACCOUNT_LAC":
      return appReducer(resetStateExcept(state, ["isAuthenticated"]), action);

    case "USER_LOGOUT":
      return appReducer(undefined, action);

    default:
      return appReducer(state, action);
  }
};

export const store = configureStore({
  reducer: rootReducer,
});
export type RootState = ReturnType<typeof store.getState>;
export default rootReducer;
