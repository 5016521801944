import { loginEndpoint } from "../../Constants/constants";

export function authenticate(item: any) {
  return fetch(loginEndpoint || "", {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",

      Accept: "application/json",

      agent_type: "ZIP",
    },
    body: JSON.stringify(item),
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch((err) => err);
}
