import * as React from "react";
import { Stack } from "@mui/material";
import { BorderLinearProgress } from "../../../Styles/GlobalStyles";

export default function LinearDeterminateProgress() {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Stack justifyContent={"center"} sx={{ width: "50%" }}>
      <BorderLinearProgress variant="determinate" value={progress} />
    </Stack>
  );
}
