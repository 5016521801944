import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Theme } from "../../Styles/GlobalTheme";
import CloseIcon from "@mui/icons-material/Close";
import "react-loading-skeleton/dist/skeleton.css";
import { getFlags, readCookie, splitMessageBybraces } from "../../Services/helper";
// import { WELCOME_MESSAGE } from "../../Constants/constants";
import { BlankPage } from "../../components/BlankPage/BlankPage";
import NetworkStatus from "./Components/NetworkStatus/NetworkStatus";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import { AccountCard } from "../../components/shared/AccountCard/AccountCard";
import { ErrorComponent } from "../../components/ErrorComponent/ErrorComponent";
import { NeedHelpCard } from "../../components/shared/NeedHelpCard/NeedHelpCard";
import { TrackOrder } from "../../Pages/Home/Components/TrackYourOrder/TrackYourOrder";
import { TroubleTicketStatus } from "./Components/TroubleTicketStatus/TroubleTicketStatus";
import { TotalBalanceCard } from "../../Pages/Home/Components/TotalBalanceCard/TotalBalanceCard";
import { LinkingWaitMessage } from "../../components/shared/LinkingWaitMessage/LinkingWaitMessage";
import {
  CardHeading,
  CenterStack,
  PageGrid,
  PageNameTypography,
  StickyBox,
  StyledA,
  StyledCard,
} from "../../Styles/GlobalStyles";
import { setCheckRouterTypeError } from "../../Redux/Slices/NetworkStatusSlice";
import OutageBanner from "./Components/NetworkStatus/OutageBanner";
import ImportantUpdatesBanner from "../../components/ImportantUpdatesBanner/ImportantUpdatesBanner";
import TpLinkIcon from "../../assets/icons/TpLinkIcon.svg";
import CtfIcon from "../../assets/icons/CTF.svg";
import routerUpgrade from "../../assets/icons/routerUpgrade.svg";
import ContactSupport from "../../components/shared/ContactSupport/ContactSupport";
import InactiveAccountBanner from "../../components/shared/InactiveAccountBanner/InactiveAccountBanner";
import { useNavigate } from "react-router-dom";
import autoPayIcon from "../../assets/icons/Autopay_Icon.svg";
import {
  autoPay,
  AUTOPAY_BANNER_DISCIPTION,
  AUTOPAY_BANNER_TITLE,
} from "../../Constants/constants";

export interface StyledButtonProps {
  background?: string;
  textcolor?: string;
  height?: string;
  width?: string;
}

export const Home = ({
  customerInfoLoading,
  getbillingDetails,
  graphQLError,
  error,
  getCustomerContact,
  billingLoading,
  billingError,
  troubleTicketStatusLoading,
  outageDetailsLoading,
  billingDetails,
  plansServicesInfo,
  ordersLoading,
  tpLinkData,
  loadingState,
  upgradeBannerData,
  showCTFBanner,
  handleOrderUpgradeRoute,
  autopayPaperlessLoading,
  loader,
}: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [bannerClose, setBannerClose] = useState<boolean>(true);
  const { showOutage } = useSelector((state: any) => state.networkStatusDetails);
  const customerDetails = useSelector((state: any) => state.customerInfoDetails);
  const { autopayAmount, showAutopay, isNewCustomer } = useSelector(
    (state: any) => state.customerPlanDetails
  );
  const { enableTestYourConnection, enableRgStatus, supportsSpeedTest, enableBreakLease } =
    useSelector((state: any) => state.networkStatusDetails);
  const { scrollPosition } = useSelector((state: any) => state.pageInfo);
  const { paymentWarning } = useSelector((state: any) => state?.userInfo);
  const { checkRouterTypeError, TpLinkError } = useSelector(
    (state: any) => state.networkStatusDetails
  );
  const orderDetails = useSelector((state: any) => state.customerInfoDetails.orderDetails);
  const { orderDetailsApiError } = useSelector((state: any) => state.orderDetails);
  const { autoPayData } = useSelector((state: any) => state?.autopayPaperlessDetails);
  const { troubleTicketDetails, troubleTicketApiError } = useSelector(
    (state: any) => state.troubleTicketDetails
  );
  const { activeAccount } = useSelector((state: any) => state?.customerInfoDetails);
  const envFlags = useSelector((state: any) => state?.userInfo?.envFlags);
  const NETWORK_STATUS = getFlags("enable_network_status", envFlags);
  const TP_LINK_BANNER = getFlags("show_tplink_banner", envFlags);
  const INACTIVE_ACCOUNTS = getFlags("inactive_accounts", envFlags);
  const TROUBLE_TICKET_TRACKER = getFlags("show_troubleTicketTracker", envFlags);
  const GLOBAL_BANNER = getFlags("show_global_banner", envFlags);
  const ORDER_TRACKER = getFlags("show_orderTracker", envFlags);
  const WIFI7_BANNER = getFlags("wifi7_banner", envFlags);
  const AUTOPAY_BANNER = getFlags("enable_autopay_banner", envFlags);
  const SHOW_UPGRADE_ORDER = getFlags("show_upgradeorder", envFlags);
  const hasOpenOrder = orderDetails && Object.keys(orderDetails)?.length > 0;
  const isDisconnectedOrder =
    orderDetails &&
    Object.keys(orderDetails)?.length > 0 &&
    orderDetails?.orderType === "disconnect";
  const hideInactiveFeature = INACTIVE_ACCOUNTS ? activeAccount !== false : true;
  const showOrderTracker =
    !orderDetailsApiError &&
    !ordersLoading &&
    hasOpenOrder &&
    isDisconnectedOrder === false &&
    !customerInfoLoading;

  const tickets = troubleTicketDetails && troubleTicketDetails?.tickets;

  const getAppLink = () => {
    const userAgent = navigator.userAgent;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return tpLinkData?.verifyTPLinkRouter?.tpLinkApple;
    } else if (/android/i.test(userAgent)) {
      return tpLinkData?.verifyTPLinkRouter?.tpLinkAndroid;
    } else {
      return tpLinkData?.verifyTPLinkRouter?.tpLinkURL;
    }
  };

  const showNetworkStatus =
    NETWORK_STATUS &&
    !tpLinkData?.verifyTPLinkRouter?.wifi7 &&
    !showOutage &&
    (enableTestYourConnection || enableRgStatus || supportsSpeedTest || enableBreakLease);

  const showTpLinkOnly = tpLinkData?.verifyTPLinkRouter?.wifi7 && getAppLink() && !TpLinkError;
  const tpLinkMessage = splitMessageBybraces(tpLinkData?.verifyTPLinkRouter?.message);
  const cftBannerMessage = splitMessageBybraces(upgradeBannerData?.message);
  const tpLinkBannerContent = {
    title: tpLinkMessage[0],
    description: tpLinkMessage[1],
    icon: <img src={TpLinkIcon} alt="TP-Link Icon" style={{ width: "48px", padding: "4px" }} />,
    linkText: "ACCESS THE APP HERE",
    link: getAppLink(),
  };
  const showWifiBanner = tpLinkData?.verifyTPLinkRouter?.showUpgradeRouter;

  const contactSupportText = SHOW_UPGRADE_ORDER ? (
    <>
      <Stack direction={"row"} gap={0.4} flexWrap={"wrap"}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "0.8rem",
            color: Theme.palette.secondary.main,
            textDecoration: "none",
            cursor: "pointer",
          }}
          onClick={handleOrderUpgradeRoute}>
          CLICK HERE
        </Typography>
        <Typography fontSize={"0.8rem"} color={Theme.palette.customcolor.profileTypoGraphyColor}>
          To upgrade today.
        </Typography>
      </Stack>
    </>
  ) : (
    <>
      <Stack direction={"row"} gap={0.4} flexWrap={"wrap"}>
        <Typography fontSize={"0.8rem"} color={Theme.palette.customcolor.profileTypoGraphyColor}>
          Call
        </Typography>
        <StyledA sx={{ fontWeight: 600, fontSize: "0.8rem" }} href="tel:1-866-699-4759">
          1-866-MY-ZIPLY
        </StyledA>
        <Typography fontSize={"0.8rem"} color={Theme.palette.customcolor.profileTypoGraphyColor}>
          or
        </Typography>
        <ContactSupport fontSize={"0.8rem"} text="CHAT WITH AN AGENT" fontWeight={600} />
        <Typography fontSize={"0.8rem"} color={Theme.palette.customcolor.profileTypoGraphyColor}>
          to upgrade today.
        </Typography>
      </Stack>
    </>
  );

  const renderLinkText = (text: string, onClickHandler: any) => (
    <Typography
      sx={{
        fontWeight: 600,
        fontSize: "0.8rem",
        color: Theme.palette.secondary.main,
        textDecoration: "none",
        cursor: "pointer",
      }}
      onClick={onClickHandler}>
      {text}
    </Typography>
  );

  const handleToAutopay = () => {
    navigate("/billing/signupautopay", {
      state: {
        NavigateToHome: true,
      },
    });
  };

  const upgradeBannerContent = {
    title: cftBannerMessage[0],
    description: cftBannerMessage[1],
    chatSupportText: contactSupportText,
    icon: <img src={CtfIcon} alt="ctfIcon" style={{ width: "48px", padding: "4px" }} />,
  };

  const wifi7BannerContent = {
    title: "Upgrade to WiFi 7 for Faster Speeds!",
    description: "Get the latest WiFi 7 router for better speed and coverage. ",
    chatSupportText: renderLinkText("UPGRADE NOW", handleOrderUpgradeRoute),
    icon: <img src={routerUpgrade} alt="WiFi7Icon" style={{ width: "48px", padding: "4px" }} />,
  };
  const autoPayContent = {
    autoPay: true,
    title: `Save the hassle and $${autopayAmount}/month with autopay`,
    description: AUTOPAY_BANNER_DISCIPTION,
    chatSupportText: renderLinkText("SIGN UP", handleToAutopay),
    icon: <img src={autoPayIcon} alt="autopayIcon" style={{ width: "48px", padding: "4px" }} />,
  };

  const autopayMessge = splitMessageBybraces(paymentWarning?.autopayMessage);
  const ShowAutopayContent = {
    showAutopay: true,
    title: autopayMessge[0],
    autopayDescription: autopayMessge[1],
    autoPaySubDiscription: autopayMessge[2],
  };

  const paymentWarningMessage = {
    paymentMessage: paymentWarning?.message,
  };
  const refreshCustomerContact = () => {
    getCustomerContact();
  };
  const handleBanner = () => {
    setBannerClose(false);
  };

  useEffect(() => {
    dispatch(setCheckRouterTypeError(false));
  }, []);

  useEffect(() => {
    const USIValue = readCookie("customerUSI");
    const tokenVal = readCookie("Token");

    if (USIValue && tokenVal) {
      if (window.loginCompleted !== undefined) {
        window.loginCompleted();
      }
    }
  }, []);

  return (
    <>
      <Box minHeight={"95vh"}>
        <PageGrid container>
          <Grid item lg={8.3} xs={12}>
            <PageNameTypography>Home</PageNameTypography>
            {GLOBAL_BANNER &&
              paymentWarning &&
              Object.keys(paymentWarning).length > 0 &&
              paymentWarning?.error &&
              paymentWarning?.allScreens &&
              paymentWarning?.message && <ImportantUpdatesBanner content={paymentWarningMessage} />}
            {!showAutopay &&
              hideInactiveFeature &&
              !billingError &&
              !loader &&
              !graphQLError &&
              customerDetails?.accountNumber && (
                <ImportantUpdatesBanner content={ShowAutopayContent} />
              )}
            {AUTOPAY_BANNER &&
              !isNewCustomer &&
              customerDetails?.accountNumber &&
              hideInactiveFeature &&
              autoPayData &&
              showAutopay &&
              Object.keys(autoPayData)?.length === 0 &&
              !loader && <ImportantUpdatesBanner content={autoPayContent} />}
            {showCTFBanner && hideInactiveFeature && (
              <ImportantUpdatesBanner content={upgradeBannerContent} />
            )}
            {WIFI7_BANNER && showWifiBanner && (
              <ImportantUpdatesBanner content={wifi7BannerContent} />
            )}
            {/* <StyledCard padding="totalbalance">
              <Typography
                color={Theme.palette.primary.main}
                fontWeight={600}
                fontSize={{ xs: "16px", sm: "20px" }}>
                {WELCOME_MESSAGE}
              </Typography>
            </StyledCard> */}
            {/* We are not getting the data from api so we are using static data for now.   */}
            {INACTIVE_ACCOUNTS &&
              activeAccount === false &&
              !customerInfoLoading &&
              !billingLoading &&
              customerDetails?.linkedAccountNumbers?.length > 0 &&
              !graphQLError && <InactiveAccountBanner />}
            {!bannerClose && (
              <StyledCard
                sx={{
                  paddingY: 2,
                  backgroundColor: Theme.palette.customcolor.bannerBackGroundColor,
                }}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-start"}>
                  <Typography component={"p"}>
                    We're experiencing slow response for bill pay, please wait for 2 mins after
                    submission to avoid duplicate payment.
                  </Typography>
                  <IconButton
                    onClick={handleBanner}
                    disableRipple
                    sx={{ padding: "0", alignSelf: "flex-start" }}>
                    <CloseIcon />
                  </IconButton>
                </Stack>
              </StyledCard>
            )}
            {ORDER_TRACKER && hideInactiveFeature && showOrderTracker && (
              <TrackOrder orderDetails={orderDetails} orderDetailsLoading={false} />
            )}
            {customerDetails?.linkedAccountNumbers?.length > 0 &&
            !customerInfoLoading &&
            hideInactiveFeature ? (
              <>
                {TROUBLE_TICKET_TRACKER && tickets?.length > 0 && !troubleTicketApiError && (
                  <TroubleTicketStatus troubleTicketStatusLoading={troubleTicketStatusLoading} />
                )}

                <CardHeading>Total balance</CardHeading>
                <TotalBalanceCard
                  billingError={billingError}
                  billingLoading={billingLoading}
                  home={true}
                  getbillingDetails={getbillingDetails}
                  billingDetails={billingDetails}
                />
              </>
            ) : customerDetails?.linkedAccountNumbers?.length === 0 &&
              !customerInfoLoading &&
              !error &&
              !graphQLError ? (
              orderDetails && Object.keys(orderDetails)?.length !== 0 ? (
                <>
                  <StyledCard sx={{ py: 10, pb: 15 }}>
                    <LinkingWaitMessage />
                  </StyledCard>
                </>
              ) : (
                <>
                  <BlankPage />
                </>
              )
            ) : error || graphQLError ? (
              <Box my={20}>
                <ErrorComponent onRefresh={refreshCustomerContact} />
              </Box>
            ) : null}
            {TP_LINK_BANNER &&
              hideInactiveFeature &&
              showTpLinkOnly &&
              loadingState &&
              !customerInfoLoading && <ImportantUpdatesBanner content={tpLinkBannerContent} />}
            {customerDetails?.linkedAccountNumbers?.length > 0 &&
              !customerInfoLoading &&
              !checkRouterTypeError &&
              activeAccount !== false && (
                <>
                  {showNetworkStatus ? (
                    <>
                      <NetworkStatus
                        outageDetailsLoading={outageDetailsLoading}
                        showNetworkStatus={showNetworkStatus}
                        showOutage={showOutage}
                      />
                    </>
                  ) : (
                    showOutage && (
                      <OutageBanner showOutage={showOutage} showNetworkStatus={showNetworkStatus} />
                    )
                  )}
                </>
              )}
          </Grid>

          <Grid
            item
            xs={12}
            lg={3.5}
            display={{ xs: "block", lg: scrollPosition > 15 ? "block" : "none" }}>
            <AccountCard />
            <NeedHelpCard />
          </Grid>
        </PageGrid>

        <StickyBox display={{ xs: "none", lg: scrollPosition > 15 ? "none" : "block" }}>
          <AccountCard />
          <NeedHelpCard />
        </StickyBox>
      </Box>
    </>
  );
};
