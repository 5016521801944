import React from "react";
import { CardHeading, StyledCard, StyledLink } from "../../../../Styles/GlobalStyles";
import { Stack, Typography } from "@mui/material";
import { OUTAGE_MESSAGE } from "../../../../Constants/constants";
import { Theme } from "../../../../Styles/GlobalTheme";
import OutageLogo from "../../../../assets/icons/OutageLogo.svg";

const OutageBanner = ({ showNetworkStatus, showOutage }: any) => {
  return (
    <>
      {showOutage && !showNetworkStatus && <CardHeading>Network Status</CardHeading>}
      <StyledCard padding="totalbalance">
        <Stack direction={"row"} justifyContent="center" alignItems={"center"} gap={2}>
          <img src={OutageLogo} alt="wifi icon" />
          <Typography
            color={Theme.palette.customcolor.customText}
            fontWeight={600}
            fontSize={{ xs: "12px", sm: "14px" }}>
            {OUTAGE_MESSAGE}&nbsp;
            <Typography
              component="span"
              fontWeight={600}
              fontSize="14px"
              sx={{ color: Theme.palette.secondary.main }}>
              <StyledLink
                to="https://ziplyfiber.com/helpcenter/categories/categories/internet/internet-outage"
                style={{ color: Theme.palette.secondary.main }}>
                KNOW MORE
              </StyledLink>
            </Typography>
          </Typography>
        </Stack>
      </StyledCard>
    </>
  );
};

export default OutageBanner;
