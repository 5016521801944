import React from "react";
import { Theme } from "../../../../Styles/GlobalTheme";
import { Box, Stack, Typography } from "@mui/material";
import { getDollarSign } from "../../../../Services/helper";
import { Spinner } from "../../../../components/shared/LoadingSpinner/Spinner";
import { StyledElement, StyledGridContainer } from "../../../../Styles/GlobalStyles";
import PlansCard from "./PlansCard";
import { ErrorComponent } from "../../../../components/ErrorComponent/ErrorComponent";
import { planProps } from "./OrderUpgrades";

function SelectNewSpeed(props: any) {
  const {
    activeStep,
    existingPlanDetails,
    availablePlansloading,
    availablePlansError,
    showMore,
    handleAvailablePlans,
    availablePlansForUpgrade,
    handleSeeMore,
    setShowBroadbandFacts,
    highestPlan,
    selectPlan,
    setSelectPlan,
    futureRouterArray,
    setSubmitOrderArr,
    submitOrderArr,
    setInstallation,
  } = props;

  return (
    <>
      <Box display={activeStep === 1 ? "block" : "none"}>
        <Stack my={3} spacing={1}>
          <Typography
            fontSize={{ xs: "16px", md: "18px" }}
            fontWeight={600}
            color={Theme.palette.primary.main}>
            You are on the {existingPlanDetails?.planDescription} plan
          </Typography>
          {availablePlansloading ? (
            <Box my={10}>
              <Spinner />
            </Box>
          ) : (
            !availablePlansloading &&
            !availablePlansError && (
              <>
                <Typography fontSize={{ xs: "16px", md: "18px" }}>
                  Please select from the speed upgrade options at your service address
                </Typography>
              </>
            )
          )}
        </Stack>
        {availablePlansError && !availablePlansloading ? (
          <Box my={10}>
            <ErrorComponent onRefresh={handleAvailablePlans} />
          </Box>
        ) : (
          <>
            <StyledGridContainer container my={2} gap={1}>
              {(showMore ? availablePlansForUpgrade : availablePlansForUpgrade?.slice(0, 2))?.map(
                (plan: planProps, index: number) => (
                  <PlansCard
                    index={index}
                    key={index}
                    selectPlan={selectPlan}
                    setSelectPlan={setSelectPlan}
                    setShowBroadbandFacts={setShowBroadbandFacts}
                    plan={plan}
                    highestPlan={highestPlan}
                    futureRouterArray={futureRouterArray}
                    submitOrderArr={submitOrderArr}
                    setSubmitOrderArr={setSubmitOrderArr}
                    setInstallation={setInstallation}
                  />
                )
              )}
            </StyledGridContainer>

            <StyledElement
              onClick={handleSeeMore}
              display={availablePlansForUpgrade?.length > 2 ? "block" : "none"}
              textAlign={"center"}>
              {showMore ? "" : "See more options"}
            </StyledElement>
          </>
        )}
      </Box>
    </>
  );
}

export default SelectNewSpeed;
