import styled from "styled-components";
import { useEffect, useState } from "react";
import VoipBillTable from "./VoipBillTable";
import { useLazyQuery, useMutation } from "@apollo/client";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { useSelector } from "react-redux";
import { VOIP_DETAILS_BY_SERVICE_PERIOD_ID, VOIP_INVOICE_DETAILS_BY_ACC_NUM } from "../../../query";
import { Box, FormControl, Select, Stack, Typography } from "@mui/material";
import { ColorBar, InformationBox } from "../../../../../Styles/GlobalStyles";
import { Spinner } from "../../../../../components/shared/LoadingSpinner/Spinner";
import {
  FETCHING_VOICE_USAGE_BILLS_MESSAGE,
  INVOICE_ERROR,
} from "../../../../../Constants/constants";
import { ErrorComponent } from "../../../../../components/ErrorComponent/ErrorComponent";
import {
  formatPhoneNumber,
  getDollarSign,
  transformVoipInvoices,
} from "../../../../../Services/helper";
import { setVOIPTotalEvents } from "../../../../../Redux/Slices/PlanDetailsSlice";

export default function VoipBillInfo() {
  const customerDetails = useSelector((state: any) => state.customerInfoDetails);
  const [error, setError] = useState<boolean>(false);
  const [pageDetails, setPageDetails] = useState<any>({
    pageNumber: 1,
    pageSize: "10",
  });
  const [VOIPData, setVOIPData] = useState([]);
  const [totalEvents, setTotalEvents] = useState("");
  const { VOIPTotalEvents } = useSelector((state: any) => state.customerPlanDetails);
  const [invoices, setInvoices] = useState([]);
  const [selectDate, setSelectDate] = useState<any>({});
  const [VOIPTotal, setVOIPTotal] = useState<any>(null);

  const handleChange = (event: any) => {
    const filteredObj = invoices?.find((item: any) => item?.date === event.target.value);
    setSelectDate(filteredObj);

    setPageDetails({ pageNumber: 1, pageSize: "10" });
    setTotalEvents("");
  };

  const [VOIPInvoiceDetails, { loading: VOIPInvoiceloading, data: VOIPInvoiceData }] = useLazyQuery(
    VOIP_INVOICE_DETAILS_BY_ACC_NUM,
    {
      variables: {
        accountNumber: customerDetails?.accountNumber,
      },
      fetchPolicy: "network-only",
      onCompleted: (data: any) => {
        if (data) {
          const invoiceData = transformVoipInvoices(
            data?.VOIPInvoiceDetailsByAccountNumber?.invoices
          );
          setSelectDate(invoiceData[0]);
          setInvoices(invoiceData);
        }
      },
    }
  );

  const totalAmount =
    VOIPInvoiceData?.VOIPInvoiceDetailsByAccountNumber?.invoices?.[0]?.totalAmount;

  const [getVOIPDetailsByServicePeriodId, { loading: voipBillLoading, data: voipBillData }] =
    useMutation(VOIP_DETAILS_BY_SERVICE_PERIOD_ID, {
      onError: () => setError(true),
    });

  const tollCharge = voipBillData?.VOIPDetailsByServicePeriodId?.tollChargedTo;
  const subTotal = voipBillData?.VOIPDetailsByServicePeriodId?.totalEvents;
  const formattedTollCharge = formatPhoneNumber(tollCharge);
  const VoipAmount = getDollarSign(totalAmount);

  const handleChangeRowsPerPage = (event: any) => {
    const newPageSize = Number(event.target.value);
    const currentFirstItem = (pageDetails.pageNumber - 1) * pageDetails.pageSize + 1;
    const newPageNumber = Math.ceil(currentFirstItem / newPageSize);

    setPageDetails({ pageNumber: newPageNumber, pageSize: newPageSize });
    setTotalEvents(VOIPTotalEvents);
  };

  const handlePrev = () => {
    setPageDetails({ ...pageDetails, pageNumber: pageDetails.pageNumber - 1 });
    setTotalEvents(VOIPTotalEvents);
  };

  const handleNext = () => {
    setPageDetails({ ...pageDetails, pageNumber: pageDetails.pageNumber + 1 });
    setTotalEvents(VOIPTotalEvents);
  };

  const handleVOIPByServiceId = async () => {
    try {
      const res = await getVOIPDetailsByServicePeriodId({
        variables: {
          accountNumber: customerDetails?.accountNumber,
          servicePeriodId: selectDate?.servicePeriodId,
          pageNumber: pageDetails.pageNumber.toString(),
          pageSize: pageDetails.pageSize.toString(),
          totalEvents: totalEvents,
        },
      });

      if (!res?.data?.VOIPDetailsByServicePeriodId?.error) {
        if (res?.data?.VOIPDetailsByServicePeriodId?.eventCharges?.length > 0) {
          const info = res?.data?.VOIPDetailsByServicePeriodId?.eventCharges?.map(
            (item: any, i: any) => ({
              ...item,
              id: (
                Number(pageDetails.pageNumber) * Number(pageDetails.pageSize) -
                Number(pageDetails.pageSize) +
                i +
                1
              ).toString(),
            })
          );
          setVOIPTotal(res?.data?.VOIPDetailsByServicePeriodId?.totalEvents);
          setVOIPData(info);
        } else {
          setVOIPData([]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleVOIP = async () => {
    try {
      await VOIPInvoiceDetails();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (selectDate?.servicePeriodId) {
      handleVOIPByServiceId();
    }
  }, [selectDate?.servicePeriodId, pageDetails.pageNumber, pageDetails.pageSize]);

  useEffect(() => {
    handleVOIP();
  }, []);

  return (
    <InformationBox>
      <Box>
        <Box my={1.5}>
          {voipBillLoading || VOIPInvoiceloading ? (
            <Stack justifyContent={"center"} alignItems={"center"} height={"300px"}>
              <Spinner loadingText={FETCHING_VOICE_USAGE_BILLS_MESSAGE} />
            </Stack>
          ) : (
            <>
              <Stack
                direction={"row"}
                gap={"10px"}
                alignItems={"center"}
                justifyContent={"space-between"}>
                <Typography fontSize={{ xs: "14px", md: "16px" }}>Detailed call history</Typography>
                <Box sx={{ width: { xs: 150, md: 200 } }}>
                  <InputLabel id="label">Month</InputLabel>
                  <FormControl fullWidth>
                    <Select
                      labelId="label"
                      id="demo-simple-select"
                      value={selectDate?.date}
                      label="Month"
                      onChange={handleChange}>
                      {invoices?.map((monthName: any, index: number) => {
                        return (
                          <MenuItem key={index} value={monthName?.date}>
                            {monthName?.date}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Stack>
              {(!voipBillLoading &&
                VOIPData?.length === 0 &&
                tollCharge === null &&
                subTotal === null) ||
              invoices?.length === 0 ? (
                <>
                  <Stack justifyContent={"center"} alignItems={"center"} height={"300px"}>
                    <Typography>
                      It appears that there are currently no usage records available for voice
                      services.
                    </Typography>
                  </Stack>
                </>
              ) : (
                !voipBillLoading &&
                VOIPData && (
                  <>
                    <Stack direction={"row"} spacing={1} mt={{ xs: 2, md: 0 }}>
                      <Typography fontWeight={600} fontSize={{ xs: "14px", md: "16px" }}>
                        Account number :
                      </Typography>
                      <Typography fontSize={{ xs: "14px", md: "16px" }}>
                        {customerDetails?.accountNumber}
                      </Typography>
                    </Stack>
                    <VoipBillStack direction={"row"}>
                      {tollCharge && (
                        <>
                          <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
                            <Typography fontWeight={600} fontSize={{ xs: "14px", md: "16px" }}>
                              Toll charged to
                            </Typography>
                            <Typography fontSize={{ xs: "14px", md: "16px" }}>
                              {formattedTollCharge}
                            </Typography>
                          </Stack>
                        </>
                      )}
                      {VoipAmount && (
                        <Stack direction={"row"} spacing={1}>
                          <Typography fontWeight={600} fontSize={{ xs: "14px", md: "16px" }}>
                            Total Amount
                          </Typography>
                          <Typography fontSize={{ xs: "14px", md: "16px" }}>
                            {VoipAmount}
                          </Typography>
                        </Stack>
                      )}
                    </VoipBillStack>

                    <ColorBar />
                    <VoipBillTable
                      tableData={VOIPData}
                      pageDetails={pageDetails}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      handleNext={handleNext}
                      handlePrev={handlePrev}
                      VOIPTotal={VOIPTotal}
                    />
                  </>
                )
              )}
            </>
          )}
          {!voipBillData && error && !voipBillLoading && (
            <ErrorComponent
              onRefresh={getVOIPDetailsByServicePeriodId}
              errorMessage={INVOICE_ERROR}
            />
          )}
        </Box>
      </Box>
    </InformationBox>
  );
}

const VoipBillStack = styled(Stack)(({ theme }) => ({
  padding: "10px 0px",
  justifyContent: "space-between",
  flexWrap: "wrap",
}));
