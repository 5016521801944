import { useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import {
  AccountDivider,
  AccountStyledCard,
  LinkTypography,
  PaymentBox,
  StyleAccordian,
  StyleAccordionSummary,
  StyledA,
  StyledElement,
} from "../../../../Styles/GlobalStyles";
import {
  DELETE_CONTACT_PROFILE,
  GET_NOTIFICATION_PREFERENCES,
  UPDATE_NOTIFICATION_PREFERENCES,
} from "../../query";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ToggleAlerts } from "./ToggleAlerts";
import { UpdateErrorComponent } from "../../../../components/shared/UpdateErrorComponent/UpdateErrorComponent";
import { SuccessMessageModel } from "../../../../components/shared/SuccessMessageModel/SuccessMessageModel";
import { Spinner } from "../../../../components/shared/LoadingSpinner/Spinner";
import {
  PRIVACY_POLICY_URL,
  UPDATE_NOTIFICATION_SUCCESS_MESSAGE,
} from "../../../../Constants/constants";
import { ConfirmationModel } from "../../../../components/shared/ConfirmationModel/ConfirmationModel";
import { LoadingSpinner } from "../../../../components/shared/LoadingSpinner/LoadingSpinner";
import { signOut } from "firebase/auth";
import { auth } from "../../../../Config/firebase";
import { ErrorComponent } from "../../../../components/ErrorComponent/ErrorComponent";
import { getFlags } from "../../../../Services/helper";

export type SettingsComponents = {
  type?: string;
  handleNotifications?: () => void;
  handlePrivacyCenter?: () => void;
};
export type SettingsProps = {
  notificationPref?: any;
  notificationPreferenceLoading?: boolean;
  notificationPreferenceError?: any;
  getNotificationPreference?: any;
};

export const Settings = ({
  notificationPref,
  notificationPreferenceLoading,
  notificationPreferenceError,
  getNotificationPreference,
}: SettingsProps) => {
  const { accountNumber, linkedAccountNumbers, emailContact, contactId, activeAccount } =
    useSelector((state: any) => state.customerInfoDetails);
  const [openError, setOpenError] = useState(false);
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const [userStatus, setUserStatus] = useState<any>();
  const [deleteAcc, setDeleteAcc] = useState(false);
  const [dialogData, setDialogData] = useState<any>({
    heading: "",
    primaryContent: <></>,
    containedButton: {
      buttonType: "",
      buttonTitle: "",
    },
    outlinedButton: {
      buttonType: "",
      buttonTitle: "",
    },
  });
  const [loading, setLoading] = useState(false);
  const envFlags = useSelector((state: any) => state?.userInfo?.envFlags);
  const INACTIVE_ACCOUNTS = getFlags("inactive_accounts", envFlags);
  const hideInactiveFeature = INACTIVE_ACCOUNTS ? activeAccount !== false : true;

  const [deleteContactProfile, { loading: deleteLoading }] = useMutation(DELETE_CONTACT_PROFILE, {
    onError: () => {
      setOpenError(true);
      setDeleteAcc(false);
    },
    onCompleted: (data) => setOpenError(data?.deleteContactProfile?.error),
  });

  const handleConfirmDelete = async () => {
    try {
      const response = await deleteContactProfile({
        variables: {
          email: emailContact?.emailAddress,
        },
      });
      if (response?.data?.deleteContactProfile?.error) {
        setOpenError(true);
        setDeleteAcc(false);
      } else if (response?.data) {
        setLoading(true);
        setTimeout(async () => {
          await signOut(auth)
            .then(() => {
              localStorage.clear();
              window.location.reload();
              setLoading(false);
            })
            .catch((err) => {
              setOpenError(true);
              setDeleteAcc(false);
              setLoading(false);
              throw new Error(err);
            });
        }, 3000);
      }
    } catch (err) {
      setOpenError(true);
    }
  };

  const handleCancel = () => {
    setDeleteAcc(false);
  };

  const alertObj = {
    sendMeEmails: "sendMeEmails",
    sendMeTextMessages: "sendMeTextMessages",
  };

  const compareData = (name: string, data: any) => {
    if (name === alertObj?.sendMeEmails) {
      data = { ...data, sendMeEmails: !data?.sendMeEmails };
    } else if (name === alertObj?.sendMeTextMessages) {
      data = { ...data, sendMeTextMessages: !data?.sendMeTextMessages };
    }
    const newAlertObj = {
      sendMeEmails: data?.sendMeEmails ? "true" : "false",
      sendMeTextMessages: data?.sendMeTextMessages ? "true" : "false",
    };

    return newAlertObj;
  };

  const [updateNotificationPrefrence, { loading: updateNotificationPrefrenceLoading }] =
    useMutation(UPDATE_NOTIFICATION_PREFERENCES, {
      onError: () => setOpenError(true),
      onCompleted: (data) => console.log(data?.updateNotificationPrefrence?.error),
      refetchQueries: [GET_NOTIFICATION_PREFERENCES],
    });

  const handleUpdateNotificationPreference = async (name: string, data: any) => {
    const alertData = compareData(name, data);
    try {
      const response = await updateNotificationPrefrence({
        variables: {
          accountNumber: accountNumber,
          notificationPreferenceInput: alertData,
          contactId: contactId,
        },
      });
      if (response?.data?.updateNotificationPrefrence?.error) {
        setOpenError(true);
      } else if (response?.data) {
        setSuccessMessage(true);
        setUserStatus(UPDATE_NOTIFICATION_SUCCESS_MESSAGE);
      }
    } catch (err) {
      setOpenError(true);
      console.log(err);
    }
  };

  const onRefreshNotifications = () => {
    getNotificationPreference();
  };

  const handleDeleteAcc = () => {
    setDeleteAcc(true);
    setDialogData({
      ...dialogData,
      heading: "Delete profile",
      primaryContent: (
        <Stack spacing={1} justifyContent={"center"} alignItems={"center"} px={{ sm: 3 }}>
          <Typography>Are you sure you want to delete your digital profile?</Typography>
          <Typography>
            This action will not stop service or billing. This action will only discontinue your
            online account access abilities.
          </Typography>
          <Typography>If you do want to cancel service, please give us a call at</Typography>
          <Box>
            <StyledA href="tel:1-866-699-4759">1-866-699-4759.</StyledA>
          </Box>
        </Stack>
      ),
      containedButton: {
        ...dialogData?.containedButton,
        buttonType: "root",
        buttonTitle: "YES, DELETE",
      },
      outlinedButton: {
        buttonType: "pay",
        buttonTitle: "NO, CANCEL",
      },
    });
  };
  return (
    <AccountStyledCard>
      {linkedAccountNumbers?.length > 0 && (
        <>
          <StyleAccordian disableGutters elevation={0}>
            <StyleAccordionSummary
              expandIcon={
                <StyledElement>
                  <ExpandMoreIcon />
                </StyledElement>
              }>
              <LinkTypography>MESSAGE SETTINGS</LinkTypography>
            </StyleAccordionSummary>
            <PaymentBox>
              {updateNotificationPrefrenceLoading || notificationPreferenceLoading ? (
                <Box my={10}>
                  <Spinner />
                </Box>
              ) : notificationPreferenceError || notificationPref?.error ? (
                <>
                  <ErrorComponent onRefresh={onRefreshNotifications} />{" "}
                </>
              ) : (
                <Grid container spacing={{ xs: 2, lg: 4 }}>
                  <Grid item xs={12} sm={6}>
                    <ToggleAlerts
                      type={"Optional Ziply Fiber information"}
                      alertsData={notificationPref}
                      handleUpdateNotificationPreference={handleUpdateNotificationPreference}
                    />
                  </Grid>
                </Grid>
              )}
            </PaymentBox>
          </StyleAccordian>
          <AccountDivider />
        </>
      )}
      <StyledA href={PRIVACY_POLICY_URL} target="_blank">
        <LinkTypography p={2.5}>PRIVACY POLICY</LinkTypography>
      </StyledA>
      <AccountDivider />
      {hideInactiveFeature && (
        <LinkTypography p={2.5} onClick={handleDeleteAcc}>
          DELETE ACCOUNT
        </LinkTypography>
      )}
      {openError && <UpdateErrorComponent setOpenError={setOpenError} openError={openError} />}
      {successMessage && (
        <SuccessMessageModel
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          userStatus={userStatus}
        />
      )}
      {deleteAcc && (
        <ConfirmationModel
          openDialog={deleteAcc}
          setOpenDialog={setDeleteAcc}
          dialogData={dialogData}
          onClick={handleConfirmDelete}
          onClickSecondary={handleCancel}
        />
      )}
      {(deleteLoading || loading) && <LoadingSpinner />}
    </AccountStyledCard>
  );
};
