import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AccountType } from "./AccountType";
import { StyledCard } from "../../Styles/GlobalStyles";
import businessIcon from "../../assets/icons/Business 1.svg";
import consumerIcon from "../../assets/icons/Consumer 1.svg";
import { Stack, Typography, Divider, Box } from "@mui/material";
import { LoginFooter } from "../../components/shared/Login/LoginFooter";

export const SelectAccount = () => {
  const navigate = useNavigate();
  useEffect(() => {
    sessionStorage.setItem("isMultiAccount", "true");
  }, []);

  return (
    <>
      <StyledCard sx={{ mt: 20, mb: 4 }}>
        <Typography textAlign={"center"} fontSize={"22px"} fontWeight={600}>
          Select your account
        </Typography>
        <Stack
          my={{ xs: 4, md: 8 }}
          direction={{ md: "row" }}
          gap={{ xs: 4, md: 10 }}
          justifyContent={"center"}>
          <AccountType
            src={businessIcon}
            heading="Business Accounts"
            subHeading="Manage your business accounts"
            href={
              window.location.pathname.includes("lac") ? "/lac/commercial" : "/myaccount/commercial"
            }
            onClick={() => {}}
          />
          <Box display={{ xs: "none", md: "block" }}>
            <Divider sx={{ height: 180 }} orientation="vertical" textAlign="center">
              or
            </Divider>
          </Box>
          <Box display={{ xs: "block", md: "none" }}>
            <Divider orientation="horizontal" variant="middle" textAlign="center">
              or
            </Divider>
          </Box>
          <AccountType
            src={consumerIcon}
            heading="Consumer Accounts"
            subHeading="Manage your consumer accounts"
            onClick={() => {
              navigate("/home");
            }}
          />
        </Stack>
      </StyledCard>
      <Stack justifyContent={"center"} alignItems={"center"} mb={4}>
        <LoginFooter />
      </Stack>
    </>
  );
};
