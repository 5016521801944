import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
};

const AdminAuthSlice = createSlice({
  name: "isAdminSlice",
  initialState,
  reducers: {
    adminInfo: (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated;
    },
  },
});

export const { adminInfo } = AdminAuthSlice.actions;
export const adminAuthorization = AdminAuthSlice.reducer;
