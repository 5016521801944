import { Box, FormControl, Grid, Stack, Typography } from "@mui/material";
import {
  BlankScreenStack,
  CardHeading,
  ErrorMessage,
  LinkAccountBox,
  ProfileInput,
  ProfileTypography,
} from "../../Styles/GlobalStyles";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validInputSubmit } from "../../Services/helper";
import Button from "../Button/Button";
import { LINK_ACCOUNT } from "../../Pages/Account/query";
import { useMutation } from "@apollo/client";
import { setInactiveAccounts, switchAccountData } from "../../Redux/Slices/CustomerInfoSlice";
import { LoadingSpinner } from "../shared/LoadingSpinner/LoadingSpinner";
import { SuccessMessageModel } from "../shared/SuccessMessageModel/SuccessMessageModel";
import { LinkedAccountCard } from "../shared/LinkedAccountCard/LinkedAccountCard";
import { UpdateErrorComponent } from "../shared/UpdateErrorComponent/UpdateErrorComponent";
import { LINKED_ACCOUNT_SUCCESS_MESSAGE } from "../../Constants/constants";

export const BlankPage = () => {
  const [openError, setOpenError] = useState(false);
  const [open, setOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const [isLinkAccountOpen, setIsLinkAccountOpen] = useState(false);
  const customerDetails = useSelector((state: any) => state.customerInfoDetails);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState<any>({
    billingAccountNumber: "",
    pin: "",
  });

  const [formErrors, setFormErrors] = useState<any>({
    billingAccountNumber: "",
    pin: "",
  });
  const [addLinkAccount, { loading: linkAccountLoading }] = useMutation(LINK_ACCOUNT, {
    onCompleted: (response) => {},
    onError: () => setOpenError(true),
  });

  const handleAccountNumberChange = (e: any) => {
    setFormData({ ...formData, billingAccountNumber: e.target.value.replace(/\D/g, "") });
    setFormErrors({});
  };

  const handlePinChange = (e: any) => {
    setFormData({ ...formData, pin: e.target.value.replace(/\D/g, "") });
    setFormErrors({});
  };

  const openLinkAccount = () => {
    setIsLinkAccountOpen(true);
  };

  const closeLinkAccount = () => {
    setIsLinkAccountOpen(false);
    setFormData({});
    setFormErrors({});
  };

  const checkValidation = () => {
    const errors = validInputSubmit(formData);
    if (Object.keys(errors.billingAccountNumber || errors.pin).length > 0) {
      setFormErrors(errors);
    }
  };
  const billingAccountNumberChange = (e: any) => {
    handleAccountNumberChange(e);
  };
  const pinChange = (e: any) => {
    handlePinChange(e);
  };

  const handleContinue = async () => {
    checkValidation();
    const isNotEmpty = Object.values(formErrors).every((value: any) => value !== "");
    if (isNotEmpty) {
      try {
        const response = await addLinkAccount({
          variables: {
            customerLinkAccountInput: {
              billingAccountNumber: formData?.billingAccountNumber,
              pin: formData?.pin,
              username: "myAccount",
              accountType: "OTHER",
            },
            contactId: customerDetails?.contactId,
          },
        });
        if (response?.data?.linkAccount?.APIStatus === 412 && response?.data?.linkAccount?.error) {
          setOpen(true);
        } else if (response?.data?.linkAccount?.error) {
          setOpenError(true);
        } else {
          const linkedAccount = {
            accountNumber: formData?.billingAccountNumber,
            pin: formData?.pin,
            accountUUID: response?.data?.linkAccount?.accountUUID,
            isNDS: response?.data?.linkAccount?.isNds,
            usi: response?.data?.linkAccount?.usi,
            isActive: response?.data?.linkAccount?.isActive,
          };
          const newAccounts = [...customerDetails?.linkedAccountNumbers, linkedAccount];
          if (linkedAccount?.isActive === false) {
            const newInactiveAccount = [...customerDetails?.inactiveAccounts, linkedAccount];
            dispatch(setInactiveAccounts(newInactiveAccount));
          }
          dispatch(
            switchAccountData({
              linkedAccountNumbers: newAccounts,
              accountNumber: linkedAccount.accountNumber,
            })
          );
          setSuccessMessage(true);
        }
      } catch (error) {
        setOpenError(true);
        console.log(error);
      }
    }
  };

  return (
    <>
      {!isLinkAccountOpen ? (
        <Box pb={"1.5rem"} mt={4} height={{ xs: "25rem", lg: "43rem" }}>
          <BlankScreenStack height={"100%"}>
            <Typography
              fontWeight={600}
              fontSize={{ xs: "0.9rem", sm: "1.3125rem" }}
              component={"p"}
              mb={2}
              textAlign={"center"}>
              Please link an account before proceeding further
            </Typography>
            <Button title="link account" type="root" onClick={openLinkAccount} />
          </BlankScreenStack>
        </Box>
      ) : (
        <>
          <CardHeading>Link account</CardHeading>
          <LinkAccountBox sx={{ padding: "2rem" }}>
            <Typography component={"p"} mb={4}>
              To link a new account to your profile, enter the account number on your Ziply Fiber
              bill.
            </Typography>
            <Grid container justifyContent={"space-between"} mb={4}>
              <Grid item xs={12} sm={5.6} mb={2}>
                <FormControl fullWidth>
                  <ProfileTypography>Account number</ProfileTypography>
                  <ProfileInput
                    placeholder="###-###-####-######-#"
                    inputProps={{ "data-testid": "accountNumber-input" }}
                    value={formData?.billingAccountNumber}
                    onBlur={checkValidation}
                    onChange={billingAccountNumberChange}
                    hasError={formErrors?.billingAccountNumber ? true : false}
                  />
                  {formErrors?.billingAccountNumber && (
                    <ErrorMessage error={formErrors?.billingAccountNumber ? true : false}>
                      {formErrors?.billingAccountNumber}
                    </ErrorMessage>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5.6} mb={2}>
                <FormControl fullWidth>
                  <ProfileTypography>Pin</ProfileTypography>
                  <ProfileInput
                    inputProps={{ "data-testid": "pin-input" }}
                    value={formData?.pin}
                    onBlur={checkValidation}
                    hasError={formErrors?.pin ? true : false}
                    onChange={pinChange}
                  />

                  {formErrors?.pin && (
                    <ErrorMessage error={formErrors?.pin ? true : false}>
                      {formErrors?.pin}
                    </ErrorMessage>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Stack justifyContent={"center"} direction={"row"} gap={2}>
              <Button type="pay" title="Cancel" onClick={closeLinkAccount} />
              <Button type="root" title="Continue" onClick={handleContinue} />
            </Stack>
          </LinkAccountBox>
        </>
      )}
      {linkAccountLoading && <LoadingSpinner />}
      {successMessage && (
        <SuccessMessageModel
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          userStatus={LINKED_ACCOUNT_SUCCESS_MESSAGE}
        />
      )}
      {open && <LinkedAccountCard open={open} setOpen={setOpen} formData={formData} />}
      {openError && <UpdateErrorComponent setOpenError={setOpenError} openError={openError} />}
    </>
  );
};
