import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  contactId: "",
  accountNumber: "",
  givenNameFamilyName: {},
  linkedAccountNumbers: [],
  telephoneContact: {},
  emailContact: {},
  ndsObj: {},
  orderDetails: {},
  orderInstallationDateTime: {},
  businessAccountDetails: "",
  commercialAccApiError: false,
  usi: "",
  pin: "",
  uuid: "",
  activeAccount: true,
  inactiveAccounts: [],
  deeplinkAccNum: "",
};
const customerInfoDetailsSlice = createSlice({
  name: "customerInfoDetails",
  initialState,
  reducers: {
    customerInfoDetails: (state, action) => {
      state.accountNumber = action.payload.accountNumber;
      state.contactId = action.payload.contactId;
      state.linkedAccountNumbers = action.payload.linkedAccountNumbers;
      state.givenNameFamilyName = action.payload.givenNameFamilyName;
      state.telephoneContact = action.payload.telephoneContact;
      state.emailContact = action.payload.emailContact;
    },
    addLinkedAccounts: (state, action) => {
      state.linkedAccountNumbers = action.payload;
    },
    switchAccountData: (state, action) => {
      state.accountNumber = action.payload.accountNumber;
      state.linkedAccountNumbers = action.payload.linkedAccountNumbers;
    },
    setDeeplinkAccNum: (state, action) => {
      state.deeplinkAccNum = action.payload;
    },
    setPin: (state, action) => {
      state.pin = action.payload;
    },
    updateTelephoneContact: (state, action) => {
      state.telephoneContact = action.payload;
    },
    updateEmailContact: (state, action) => {
      state.emailContact = action.payload;
    },
    unlinkAccounts: (state, action) => {
      state.linkedAccountNumbers = action.payload;
    },
    setNDS: (state, action) => {
      state.ndsObj = action.payload;
    },
    setOrderDetails: (state, action) => {
      state.orderDetails = action.payload;
    },
    setOrderInstallationDateTime: (state, action) => {
      state.orderInstallationDateTime = action.payload;
    },
    setBusinessAccountDetails: (state, action) => {
      state.businessAccountDetails = action.payload;
    },
    setCommercialAccApiError: (state, action) => {
      state.commercialAccApiError = action.payload;
    },
    setActiveAccount: (state, action) => {
      state.activeAccount = action.payload;
    },
    setInactiveAccounts: (state, action) => {
      state.inactiveAccounts = action.payload;
    },
  },
});

export const {
  customerInfoDetails,
  addLinkedAccounts,
  switchAccountData,
  updateTelephoneContact,
  updateEmailContact,
  unlinkAccounts,
  setNDS,
  setOrderDetails,
  setOrderInstallationDateTime,
  setBusinessAccountDetails,
  setCommercialAccApiError,
  setActiveAccount,
  setPin,
  setInactiveAccounts,
  setDeeplinkAccNum,
} = customerInfoDetailsSlice.actions;
export const customerInfoDetailsReducer = customerInfoDetailsSlice.reducer;
