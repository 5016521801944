import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  encryptedPayload: "",
};

const SignInSlice = createSlice({
  name: "SignInDetails",
  initialState,
  reducers: {
    setSignInDetails: (state, action) => {
      state.email = action.payload.email;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.phoneNumber = action.payload.phoneNumber;
    },
    setEncryptedPayload: (state, action) => {
      state.encryptedPayload = action.payload;
    },
  },
});

export const { setSignInDetails, setEncryptedPayload } = SignInSlice.actions;
export const signInReducer = SignInSlice.reducer;
