import { useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { TEST_YOUR_CONNECTION_DETAILS } from "../../Pages/Home/Components/NetworkStatus/query";
import {
  setNetworkConnectionDetails,
  setTestConnectionApiError,
  setTestConnectionApiErrorMsg,
  setTestDetailsNull,
} from "../../Redux/Slices/NetworkStatusSlice";
import { NETWORK_CONNECTION_FAILURE_TEXT } from "../../Constants/constants";

export const useTestConnection = (accountNumber: any) => {
  const dispatch = useDispatch();

  const [getTestConnectionDetails, { loading }] = useLazyQuery(TEST_YOUR_CONNECTION_DETAILS, {
    variables: {
      accountNumber: accountNumber,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      dispatch(setNetworkConnectionDetails(data?.testYourConnection?.connectionDetails));
      if (data?.testYourConnection?.connectionDetails === null) {
        dispatch(setTestConnectionApiErrorMsg(NETWORK_CONNECTION_FAILURE_TEXT));
        dispatch(setTestDetailsNull(true));
      } else {
        dispatch(setTestConnectionApiError(false));
        dispatch(setTestDetailsNull(false));
      }
    },
    onError: () => {
      dispatch(setTestConnectionApiError(true));
      dispatch(setTestConnectionApiErrorMsg(NETWORK_CONNECTION_FAILURE_TEXT));
      dispatch(setNetworkConnectionDetails({}));
      dispatch(setTestDetailsNull(false));
    },
  });

  return {
    getTestConnectionDetails,
    loading,
  };
};
