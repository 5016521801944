import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Theme } from "../../../../Styles/GlobalTheme";
import ZiplyLogoIcon from "../../../../assets/images/ziply-logo.svg";
import { Card, CardProps, Divider, Stack, Typography, styled } from "@mui/material";
import { StyledElement, ZiplyLogo, ZoomIcon } from "../../../../Styles/GlobalStyles";
import Zoom from "../../../../assets/icons/Zoom.svg";
import { setExpandNutritionLabel } from "../../../../Redux/Slices/OrderUpgradeSlice";

interface props {
  handleNutritionLabel: any;
}

const BroadbandFactsCard = ({ handleNutritionLabel }: props) => {
  const { selectedPlanDetails, ZoomOutFlag } = useSelector(
    (state: any) => state.OrderUpgradeDetails
  );
  const dispatch = useDispatch();

  const ExpandFullNutritionLabel = () => {
    dispatch(setExpandNutritionLabel(true));
    handleNutritionLabel();
  };
  const ExpandNutritionLabel = () => {
    dispatch(setExpandNutritionLabel(false));
    handleNutritionLabel();
  };
  return (
    <>
      <PlanCardLayout highestPlan={false} sx={{ position: "relative" }}>
        <Stack spacing={0.4}>
          <Typography textAlign={"center"} fontSize={{ xs: "14px", md: "18px" }}>
            Broadband Facts
          </Typography>
          <Divider sx={{ border: "1px solid black" }} />
          <ZiplyLogo height={"45px"} src={ZiplyLogoIcon} alt="ziply-logo" />
          <Typography
            fontSize={"16px"}
            color={Theme.palette.primary.main}
            fontWeight={600}
            pl={"8px"}>
            {selectedPlanDetails?.name}
          </Typography>
          <Typography fontSize={"14px"} pl={"8px"} pb={"4px"}>
            Fixed Broadband Customer Disclosure
          </Typography>
          <Divider sx={{ border: "1px solid black" }} />
          <StyledElement
            textAlign={"center"}
            display={ZoomOutFlag ? "none" : "block"}
            onClick={ExpandNutritionLabel}>
            See more details
          </StyledElement>
          <ZoomIcon
            onClick={ExpandFullNutritionLabel}
            height={"40px"}
            src={Zoom}
            alt="ziply-logo"
          />
        </Stack>
      </PlanCardLayout>
    </>
  );
};

export default BroadbandFactsCard;
interface PlanCardLayoutProps extends CardProps {
  highestPlan: boolean;
}

export const PlanCardLayout = styled(Card, {
  shouldForwardProp: (prop) => prop !== "highestPlan",
})<PlanCardLayoutProps>(({ theme, highestPlan }) => ({
  elevation: 0,
  marginBottom: 10,
  width: "41%",
  minHeight: "195px",
  border: "2px solid black",
  borderRadius: 25,
  padding: "0.6rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  [theme.breakpoints.down(769)]: {
    minHeight: "200px",
  },
  [theme.breakpoints.down(500)]: {
    minHeight: !highestPlan ? "200px" : "160px",
    width: "100%",
  },
  [theme.breakpoints.down(321)]: {
    minHeight: "200px",
    width: "100%",
  },
}));
