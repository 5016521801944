import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAdmin: false,
  adminUserName: "",
};

const isAdminSlice = createSlice({
  name: "isAdminSlice",
  initialState,
  reducers: {
    adminInfo: (state, action) => {
      state.isAdmin = action.payload.isAdmin;
    },
    adminUserName: (state, action) => {
      state.adminUserName = action.payload.adminUserName;
    },
  },
});

export const { adminInfo, adminUserName } = isAdminSlice.actions;
export const isAdminFlagReducer = isAdminSlice.reducer;
