/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { Reschedule } from "./Reshedule";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Theme } from "../../../../Styles/GlobalTheme";
import Button from "../../../../components/Button/Button";
import { useLazyQuery, useMutation } from "@apollo/client";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ImappingData } from "../TroubleTicketStatus/TroubleTicketStatus";
import { GET_APPOINTMENT_DETAILS, RESCHEDULE_APPOINTMENT } from "./query";
import { AccountCard } from "../../../../components/shared/AccountCard/AccountCard";
import { NeedHelpCard } from "../../../../components/shared/NeedHelpCard/NeedHelpCard";
import { LoadingSpinner } from "../../../../components/shared/LoadingSpinner/LoadingSpinner";
import { UpdateErrorComponent } from "../../../../components/shared/UpdateErrorComponent/UpdateErrorComponent";
import {
  Box,
  IconButton,
  Stack,
  Typography,
  DialogContent,
  styled,
  Dialog,
  Grid,
} from "@mui/material";
import {
  CardHeading,
  CenterStack,
  PageGrid,
  StickyBox,
  StyledA,
  StyledCard,
  StyledDialog,
  StyledLink,
} from "../../../../Styles/GlobalStyles";
import {
  setOrderRescheduledFailed,
  setOrderRescheduledSuccess,
} from "../../../../Redux/Slices/OrderDetailsSlice";
import { GET_ORDER_STATUS } from "../../../../Routes/query";
import { GET_TROUBLE_TICKET_STATUS } from "../../query";
import ContactSupport from "../../../../components/shared/ContactSupport/ContactSupport";

export default function RescheduleAppointment({ getorderDetails }: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [availableAppointments, setAvailableAppointments] = React.useState<any>({});
  const [selectedDate, setSelectedDate] = React.useState<any>("");
  const [selectedTimeSlot, setSelectedTimeSlot] = React.useState<any>();
  const [selectedAppointment, setSelectedAppointment] = React.useState<any>("");
  const [openError, setOpenError] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [failureMsg, setFailureMsg] = useState("");
  const [title, setTitle] = useState<string>("");
  const { orderRescheduledSuccess, orderRescheduledFailed, isOrder } = useSelector(
    (state: any) => state.orderDetails
  );
  const { troubleTicketDetails, appointment } = useSelector(
    (state: any) => state.troubleTicketDetails
  );
  const { email } = useSelector((state: any) => state?.userInfo);
  const { orderDetails, orderInstallationDateTime, accountNumber } = useSelector(
    (state: any) => state.customerInfoDetails
  );
  const { scrollPosition } = useSelector((state: any) => state.pageInfo);
  const { billingAddress } = useSelector((state: any) => state.customerPlanDetails);

  const openSuccessModal = () => {
    dispatch(setOrderRescheduledSuccess(true));
  };

  const handleClose = () => {
    dispatch(setOrderRescheduledSuccess(false));
    navigate("/home");
  };

  const handleClosePopup = () => {
    dispatch(setOrderRescheduledFailed(false));
    navigate("/home");
  };

  const openFailureModal = () => dispatch(setOrderRescheduledFailed(true));

  const ticketId = troubleTicketDetails?.tickets && troubleTicketDetails?.tickets?.[0]?.ticketId;

  const ticketDetails = troubleTicketDetails?.tickets && troubleTicketDetails?.tickets?.[0];
  const isAppointment: any = troubleTicketDetails?.tickets?.[0]?.appointment !== null;

  const mappingData: ImappingData[] = [
    {
      name: "Ticket no :",
      value: ticketDetails?.ticketId,
    },
    {
      name: "Appointment date",
      value: appointment?.appointmentDate,
    },
    {
      name: "Appointment time",
      value: `${appointment?.appointmentStartTime} - ${appointment?.appointmentEndTime}`,
    },
    {
      name: "Primary contact number",
      value: ticketDetails?.appointment?.canBeReachedTelephoneNumber,
    },
    {
      name: isAppointment && "Service address :",
      value: isAppointment && billingAddress,
    },
  ];

  const mappingOrderData: ImappingData[] = [
    {
      name: "Order no: ",
      value: orderDetails?.orderId,
    },
    ...((orderDetails?.product || orderDetails?.equipment) && [
      {
        name: "Order items",
        value: (
          <>
            {orderDetails?.product && (
              <>
                Plan: {orderDetails?.product}
                <br />
              </>
            )}
            {orderDetails?.equipment && <>Add ons: {orderDetails?.equipment}</>}
            {orderDetails?.phone && <>,{orderDetails?.phone}</>}
          </>
        ),
      },
    ]),
    {
      name: "Appointment date",
      value: orderInstallationDateTime?.installDate,
    },
    {
      name: "Appointment time",
      value: `${orderInstallationDateTime?.installStart}-${orderInstallationDateTime?.installEnd}`,
    },
    {
      name: "Primary contact number",
      value: orderDetails?.telephoneNumber,
    },
  ];

  const [fetchReschedule, { loading: fetchLoading, data: appointments }] = useLazyQuery(
    GET_APPOINTMENT_DETAILS,
    {
      variables: {
        orderId: isOrder ? orderDetails?.orderId : ticketId,
      },
      fetchPolicy: "network-only",
      onError: () => setApiError(true),
    }
  );

  const [rescheduleOrder, { loading }] = useMutation(RESCHEDULE_APPOINTMENT, {
    onError: (error: any) => {
      if (isOrder) {
        if (error.networkError.result.errors[0]?.code === "422") {
          setFailureMsg(error.networkError.result.errors[0]?.message);
          setTimeout(() => {
            getorderDetails({ fetchPolicy: "network-only" });
          }, 2000);
          orderRescheduledSuccess && dispatch(setOrderRescheduledSuccess(false));
        }
        openFailureModal();
      } else {
        openFailureModal();
      }
    },
    onCompleted: () => {
      isOrder &&
        setTimeout(() => {
          getorderDetails({ fetchPolicy: "network-only" });
        }, 2000);
    },
    refetchQueries: isOrder ? [GET_ORDER_STATUS] : [GET_TROUBLE_TICKET_STATUS],
  });

  async function handleReschedule() {
    setConfirmationModal(false);
    try {
      const response = await rescheduleOrder({
        variables: {
          appointmentSlot: {
            startDate: selectedAppointment?.startDate,
            endDate: selectedAppointment?.endDate,
            resourcePoolId: selectedAppointment?.resourcePoolId,
            timeSlotType: selectedAppointment?.timeSlotType,
            workUnitsQuantity: selectedAppointment?.workUnitsQuantity,
            startDateDisplay: selectedAppointment?.startDate,
            endDateDisplay: selectedAppointment?.endDate,
          },
          orderId: isOrder ? orderDetails?.orderId : ticketId,
          email: email,
          isOrder: isOrder ? true : false,
          accountNumber: accountNumber,
        },
      });
      if (!response?.data?.rescheduleOrderAppointmentSlot?.error) {
        openSuccessModal();
      } else if (response?.data?.rescheduleOrderAppointmentSlot?.error) {
        openFailureModal();
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleSelectAppointment = (appointment: any) => {
    setSelectedDate(appointment);
    if (availableAppointments[appointment].length > 0) {
      let time = availableAppointments[appointment][0];
      const timeRange = `${time?.startTime}-${time?.endTime}`;
      const [startTime, endTime] = timeRange?.split("-");
      const formattedStartTime = moment(startTime, "HH:mm:ss").format("hh:mm A");
      const formattedEndTime = moment(endTime, "HH:mm:ss").format("hh:mm A");
      const formattedTimeRange = `${formattedStartTime} - ${formattedEndTime}`;
      setSelectedTimeSlot(formattedTimeRange);
      setSelectedAppointment(time);
    } else {
      setSelectedTimeSlot("");
      setSelectedAppointment({});
    }
  };

  const handleAppointments = () => {
    const data = appointments?.getOrderRescheduleAppointmentDetails?.appointments;
    const organizedData: any = {};
    data?.forEach((slot: any) => {
      const date = slot.startDateDisplay.split("T")[0];
      if (!organizedData[date]) {
        organizedData[date] = [];
      }
      organizedData[date].push({
        startDate: slot.startDate,
        endDate: slot.endDate,
        startTime: slot.startDateDisplay.split("T")[1].replace("Z", ""),
        endTime: slot.endDateDisplay.split("T")[1].replace("Z", ""),
        timeSlotType: slot.timeSlotType,
        workUnitsQuantity: slot.workUnitsQuantity,
        resourcePoolId: slot.resourcePoolId,
      });
    });
    setAvailableAppointments(organizedData);
  };

  useEffect(() => {
    (orderDetails?.orderId || ticketId) && fetchReschedule();
  }, [orderDetails?.orderId, ticketId]);

  useEffect(() => {
    if (appointments?.getOrderRescheduleAppointmentDetails?.appointments) {
      handleAppointments();
    }
  }, [appointments?.getOrderRescheduleAppointmentDetails?.appointments]);

  useEffect(() => {
    setTitle(isOrder ? "Manage Order" : "Manage Ticket");
  }, [isOrder]);

  return (
    <>
      <Box sx={{ marginTop: "1rem" }} minHeight={"90vh"}>
        <PageGrid container>
          <Grid item lg={8.3} xs={12}>
            <StyledLink to={"/home"}>
              <Stack alignItems={"center"} direction={"row"}>
                <ArrowBackIosIcon color="secondary" />
                <CardHeading>{title}</CardHeading>
              </Stack>
            </StyledLink>
            {apiError ? (
              <StyledCard>
                <Stack my={8} alignItems={"center"} justifyContent={"center"} spacing={2}>
                  <Typography fontWeight={600}>Something went wrong!</Typography>
                  <Typography textAlign={"center"}>
                    Unable to complete your request at this moment. Please try again after sometime
                    or click on "REFRESH"
                  </Typography>
                  <Stack direction={"row"} justifyContent={"center"} gap={1}>
                    <Typography fontSize={{ xs: "14px", md: "16px" }}>Please </Typography>
                    <ContactSupport />
                    <Typography> for further help.</Typography>
                  </Stack>
                  <Box mt={4}>
                    <Button onClick={() => fetchReschedule()} title="REFRESH" type="root" />
                  </Box>
                </Stack>
              </StyledCard>
            ) : (
              <>
                {isOrder ? (
                  <Reschedule
                    data={mappingOrderData}
                    appointments={availableAppointments}
                    handleSelectAppointment={handleSelectAppointment}
                    selectedDate={selectedDate}
                    setSelectedAppointment={setSelectedAppointment}
                    selectedTimeSlot={selectedTimeSlot}
                    setSelectedTimeSlot={setSelectedTimeSlot}
                    setConfirmationModal={setConfirmationModal}
                    fetchLoading={fetchLoading}
                  />
                ) : (
                  ticketId && (
                    <Reschedule
                      data={mappingData}
                      appointments={availableAppointments}
                      handleSelectAppointment={handleSelectAppointment}
                      selectedDate={selectedDate}
                      setSelectedAppointment={setSelectedAppointment}
                      selectedTimeSlot={selectedTimeSlot}
                      setSelectedTimeSlot={setSelectedTimeSlot}
                      setConfirmationModal={setConfirmationModal}
                      fetchLoading={fetchLoading}
                    />
                  )
                )}
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            lg={3.5}
            display={{ xs: "block", lg: scrollPosition > 15 ? "block" : "none" }}>
            <AccountCard />
            <NeedHelpCard />
          </Grid>
        </PageGrid>
        <StickyBox display={{ xs: "none", lg: scrollPosition > 15 ? "none" : "block" }}>
          <AccountCard />
          <NeedHelpCard />
        </StickyBox>
      </Box>
      <>
        <ReviewStyledDialog
          open={confirmationModal}
          onClose={handleClose}
          aria-describedby="modal-modal-description">
          <IconButton
            data-testid="close-button"
            sx={{ alignSelf: "flex-end", padding: 0 }}
            onClick={() => setConfirmationModal(false)}>
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Stack justifyContent={"center"} alignItems={"center"}>
              <Typography
                id="modal-modal-title"
                fontWeight={600}
                component="h2"
                fontSize={{ xs: "16px", md: "18px" }}
                textAlign={"center"}>
                Reschedule Appointment
              </Typography>
              <Typography fontSize={{ xs: "14px", md: "18px" }}>
                Review and confirm new appointment details
              </Typography>

              <br />
              <CenterStack fontSize={{ xs: "14px", md: "18px" }}>
                <section style={{ display: "flex" }}>
                  <p style={{ color: "grey" }}>New Appointment Date : &nbsp; </p>{" "}
                  <p style={{ color: "#026CE3" }}>{moment(selectedDate).format("dddd, MMMM DD")}</p>
                </section>
                <section style={{ display: "flex" }}>
                  <p style={{ color: "grey" }}>New Appointment Time : &nbsp;</p>{" "}
                  <p style={{ color: "#026CE3" }}> {selectedTimeSlot}</p>
                </section>
              </CenterStack>
              <section
                style={{
                  marginTop: "2rem",
                  width: "12rem",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  gap: "1rem",
                }}>
                <Button onClick={() => setConfirmationModal(false)} title="Edit" type="pay" />
                <Button onClick={handleReschedule} title="Confirm" type="payment" />
              </section>
            </Stack>
          </DialogContent>
        </ReviewStyledDialog>

        <StyledDialog
          open={orderRescheduledFailed}
          onClose={handleClosePopup}
          aria-describedby="modal-modal-description">
          <IconButton
            data-testid="close-button"
            sx={{ alignSelf: "flex-end", padding: 0 }}
            onClick={handleClosePopup}>
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Stack justifyContent={"center"} alignItems={"center"} mb={3}>
              <Typography
                id="modal-modal-title"
                fontWeight={600}
                component="h2"
                fontSize={{ xs: "14px", md: "18px" }}
                textAlign={"center"}>
                Rescheduled Unsuccessful
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2 }}
                textAlign={"center"}
                fontSize={{ xs: "14px", md: "16px" }}>
                {failureMsg?.length > 0
                  ? failureMsg
                  : "Sorry ! The system couldn't process your rescheduled request. We've forwarded it to our customer support team for further assistance."}
              </Typography>

              <Typography
                textAlign={"center"}
                component={"div"}
                fontSize={{ xs: "14px", md: "16px" }}>
                For any questions, please contact us at :&nbsp;
                <StyledA href="tel:1-866-699-4759">1.866.699.4759</StyledA>
              </Typography>
            </Stack>
          </DialogContent>
        </StyledDialog>

        <StyledDialog
          open={orderRescheduledSuccess}
          onClose={handleClose}
          aria-describedby="modal-modal-description">
          <IconButton
            data-testid="close-button"
            sx={{ alignSelf: "flex-end", padding: 0 }}
            onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Stack justifyContent={"center"} alignItems={"center"}>
              <Typography
                id="modal-modal-title"
                fontWeight={600}
                component="h2"
                fontSize={{ xs: "13px", md: "20px" }}
                textAlign={"center"}>
                Appointment Rescheduled Successfully
              </Typography>
              <br />
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2 }}
                textAlign={"center"}
                fontSize={{ xs: "14px", md: "16px" }}>
                You have successfully rescheduled your {isOrder ? "order" : "ticket"}&nbsp;
                appointment to &nbsp;
                <b>
                  {moment(selectedDate).format("dddd, MMMM DD")} | {selectedTimeSlot}
                </b>
              </Typography>
              <br />
              <Button onClick={handleClose} title="Done" type="root" />

              <Typography
                mt={1}
                textAlign={"center"}
                component={"div"}
                color={"grey"}
                fontSize={{ xs: "12px", md: "12px" }}>
                Note: Expect a slight delay for the new details to be reflected in your order
              </Typography>
            </Stack>
          </DialogContent>
        </StyledDialog>
      </>
      {(fetchLoading || loading) && <LoadingSpinner />}
      {openError && <UpdateErrorComponent setOpenError={setOpenError} openError={openError} />}
    </>
  );
}

const ReviewStyledDialog = styled(Dialog)(({ theme }) => ({
  zIndex: "2",
  "& .MuiDialog-paper": {
    padding: " 1rem",
    borderRadius: "1rem",
    width: "100%",
  },
}));
