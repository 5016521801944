import React, { useEffect, useState } from "react";
import { Stack, Typography, Grid, FormControl } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import {
  CardHeading,
  ErrorMessage,
  PaymentBox,
  ProfileInput,
  ProfileTypography,
} from "../../Styles/GlobalStyles";
import {
  getAuth,
  signOut,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { validInputSubmit } from "../../Services/helper";
import { auth } from "../../Config/firebase";
import Button from "../Button/Button";
import { UpdateErrorComponent } from "../shared/UpdateErrorComponent/UpdateErrorComponent";
import { INVALID_PASSWORD, PANEL_1 } from "../../Constants/constants";
import { LoadingSpinner } from "../shared/LoadingSpinner/LoadingSpinner";
import { usePasswordCriteria } from "../../hooks/Password/usePasswordCriteria";
import PasswordCriteria from "../PasswordCriteria/PasswordCriteria";

export const ChangePassword = ({ setIsChangePasswordOpen, handleChange, expanded }: any) => {
  const [save, setSave] = useState<boolean>(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const { passwordCriteria, validatePasswordWithCriteria, setPasswordCriteria } =
    usePasswordCriteria();

  const [formData, setFormData] = useState<any>({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [formErrors, setFormErrors] = useState<any>({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const checkCurrentPassword = () => {
    const currentPassword = formData?.currentPassword?.trim();
    const newPassword = formData?.newPassword?.trim();
    const errors = validInputSubmit(formData);
    const confirmPass = formData?.confirmNewPassword?.trim();
    if (newPassword !== confirmPass && confirmPass) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
    }
    if (currentPassword && newPassword && currentPassword === newPassword) {
      setErrorText(true);
      return true;
    }
  };

  const checkValidation = (e: any) => {
    const errors = validInputSubmit(formData);
    if (checkCurrentPassword()) {
      return;
    }
    if (
      errors &&
      Object.keys(errors.currentPassword || errors.newPassword || errors.confirmNewPassword)
        .length > 0
    ) {
      setFormErrors(errors);
    } else {
      const isNotEmpty = Object.values(errors).every((value: any) => value === "");
      if (isNotEmpty) {
        onChangePassword();
      }
    }
  };

  const handleConfirmPasswordonBlur = () => {
    const errors = validInputSubmit(formData);
    const newPass = formData?.newPassword?.trim();
    const confirmPass = formData?.confirmNewPassword?.trim();
    if (newPass !== confirmPass) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
    }
  };

  const handleClickCurrentShowPassword = () => {
    setShowCurrentPassword((show) => !show);
  };

  const handleClickNewShowPassword = () => {
    setShowNewPassword((show) => !show);
  };

  const handleClickConfirmShowPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handlePassword = (data: any, value: any) => {
    setFormData({ ...formData, currentPassword: data });
    setFormErrors((prevErrors: any) => ({
      ...prevErrors,
      currentPassword: "",
    }));
    setErrorText(false);
  };

  const handleNewPassword = (data: any, value: any) => {
    validatePasswordWithCriteria(data);
    setFormData({ ...formData, newPassword: data });
    const confirmPass = formData?.confirmNewPassword?.trim();
    if (data === confirmPass) {
      setFormErrors((prevErrors: any) => ({
        ...prevErrors,
        confirmNewPassword: "",
      }));
    }
    setFormErrors((prevErrors: any) => ({
      ...prevErrors,
      newPassword: "",
    }));
    setErrorText(false);
  };

  const handleConfirmPassword = (data: any, value: any) => {
    setFormData({ ...formData, confirmNewPassword: data });
    setFormErrors((prevErrors: any) => ({
      ...prevErrors,
      confirmNewPassword: "",
    }));
  };

  const onChangePassword = () => {
    setLoading(true);
    const user = getAuth().currentUser;
    if (user) {
      //@ts-ignore
      const credential = EmailAuthProvider.credential(user.email, formData.currentPassword);
      if (formData.currentPassword !== formData.newPassword) {
        reauthenticateWithCredential(user, credential)
          .then(() => {
            return updatePassword(user, formData.newPassword);
          })
          .then(() => {
            setLoading(false);
            setSave(true);
            setFormData({});
            setFormErrors({});
          })
          .catch((error: any) => {
            setLoading(false);
            setSave(false);
            setOpenError(true);
            setFormData({});
            setFormErrors({});
            setMessage(INVALID_PASSWORD);
          });
      }
    }
  };

  const handleRelogin = async () => {
    setLoading(true);
    await signOut(auth)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        throw new Error(err);
      });
    setLoading(false);
  };

  const handleClose = () => {
    setFormData({});
    setFormErrors({});
    setIsChangePasswordOpen(false);
  };

  const handleCancel = () => {
    setFormData({ currentPassword: "", newPassword: "", confirmNewPassword: "" });
    handleChange(PANEL_1)();
  };

  useEffect(() => {
    if (expanded) {
      setFormData({
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      });
      setPasswordCriteria({
        hasEightOrMoreChars: false,
        hasAtleastOneUpperAndLowerCase: false,
        hasAtleastOneNumber: false,
      });
      setFormErrors({});
      setErrorText(false);
    }
  }, [expanded]);

  const isAnyPasswordFieldEmpty = Object.values<string>(formData).some(
    (value) => value?.length === 0
  );

  return (
    <>
      <PaymentBox>
        {save ? (
          <Stack alignItems={"center"} spacing={2} my={5}>
            <Typography
              fontSize={{ xs: "0.8rem", sm: "1.3125rem" }}
              textAlign={"center"}
              fontWeight={"600"}>
              Password has been changed successfully
            </Typography>
            <Button onClick={handleRelogin} title="Re-login" type="root" />
          </Stack>
        ) : (
          <>
            <CardHeading my={2}>Change password</CardHeading>
            <FormControl fullWidth>
              <Grid container justifyContent={"space-between"} mt={2}>
                <Grid item xs={12} sm={5.6} mb={2}>
                  <FormControl fullWidth>
                    <ProfileTypography>Enter your current password</ProfileTypography>
                    <ProfileInput
                      inputProps={{ "data-testid": "current" }}
                      value={formData?.currentPassword}
                      name="currentPassword"
                      hasError={formErrors?.newPassword || errorText ? true : false}
                      onChange={(e) => handlePassword(e.target.value, "currentPassword")}
                      id="outlined-adornment-password"
                      type={showCurrentPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            disableRipple
                            aria-label="toggle password visibility"
                            onClick={handleClickCurrentShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end">
                            {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {formErrors?.currentPassword && (
                      <ErrorMessage error={formErrors?.currentPassword ? true : false}>
                        {formErrors?.currentPassword}
                      </ErrorMessage>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container justifyContent={"space-between"} mt={2}>
                <Grid item xs={12} sm={5.6} mb={2}>
                  <FormControl fullWidth>
                    <ProfileTypography>Enter new password</ProfileTypography>
                    <ProfileInput
                      onBlur={checkCurrentPassword}
                      inputProps={{ "data-testid": "new" }}
                      value={formData?.newPassword}
                      name="newPassword"
                      hasError={formErrors?.newPassword || errorText ? true : false}
                      onChange={(e) => handleNewPassword(e.target.value, "newPassword")}
                      id="outlined-adornment-password"
                      type={showNewPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            disableRipple
                            aria-label="toggle password visibility"
                            onClick={handleClickNewShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end">
                            {showNewPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errorText && (
                      <ErrorMessage error={errorText}>
                        Current password and new password can't be same.
                      </ErrorMessage>
                    )}
                    {formErrors?.newPassword && (
                      <ErrorMessage error={formErrors?.newPassword ? true : false}>
                        {formErrors?.newPassword}
                      </ErrorMessage>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={5.6} mb={2}>
                  <FormControl fullWidth>
                    <ProfileTypography>Confirm new password</ProfileTypography>
                    <ProfileInput
                      onBlur={handleConfirmPasswordonBlur}
                      inputProps={{ "data-testid": "re-enter" }}
                      value={formData?.confirmNewPassword}
                      name="confirmNewPassword"
                      hasError={formErrors?.confirmNewPassword ? true : false}
                      onChange={(e) => handleConfirmPassword(e.target.value, "confirmNewPassword")}
                      id="outlined-adornment-password"
                      type={showConfirmPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            disableRipple
                            aria-label="toggle password visibility"
                            onClick={handleClickConfirmShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end">
                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {formErrors?.confirmNewPassword && (
                      <ErrorMessage error={formErrors?.confirmNewPassword ? true : false}>
                        {formErrors?.confirmNewPassword}
                      </ErrorMessage>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <PasswordCriteria passwordCriteria={passwordCriteria} />
              <Stack justifyContent={"center"} direction={"row"} spacing={2} py={2}>
                <Button onClick={expanded && handleCancel} title="Cancel" type="pay" />
                <Button
                  onClick={checkValidation}
                  eventName="CHANGE_PASSWORD"
                  title="Save"
                  disabled={isAnyPasswordFieldEmpty}
                  type="payment"
                />
              </Stack>
            </FormControl>
          </>
        )}
        {openError && (
          <UpdateErrorComponent
            openError={openError}
            setOpenError={setOpenError}
            message={message}
            setErrorMessage={setErrorMessage}
          />
        )}
      </PaymentBox>
      {loading && <LoadingSpinner />}
    </>
  );
};
