import Step from "@mui/material/Step";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import StepLabel from "@mui/material/StepLabel";
import { Box, Typography } from "@mui/material";
import { StepIconProps } from "@mui/material/StepIcon";
import { Theme } from "../../../../Styles/GlobalTheme";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";

const StepConnectorLine = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 10px)",
    right: "calc(50% + 10px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: Theme.palette.secondary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: Theme.palette.secondary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderTopWidth: 3,
    borderRadius: 1,
    borderColor: Theme.palette.secondary.main,
  },
}));

const StepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: Theme.palette.secondary.main,
    }),

    "& .StepIcon-circle": {
      width: 20,
      height: 20,
      borderRadius: "50%",
      backgroundColor: "white",
      border: "2px solid blue",
    },
    "& .StepIcon-circle-completed": {
      width: 20,
      height: 20,
      borderRadius: "50%",
      backgroundColor: Theme.palette.secondary.main,
      border: "2px solid blue",
    },
  })
);

function StepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <StepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div className="StepIcon-circle-completed" />
      ) : (
        <div className="StepIcon-circle" />
      )}
    </StepIconRoot>
  );
}

const steps = ["", "", "", ""];

interface StepProps {
  activeStep: number;
  heading: string;
}

export default function StepperWithoutLabels({ activeStep, heading }: StepProps) {
  return (
    <Stack justifyContent={"center"} alignItems={"center"} width={"100%"}>
      <Typography fontSize={"18px"} color={Theme.palette.primary.main} fontWeight={600}>
        {heading}
      </Typography>
      <Box
        mt={1}
        sx={{
          width: {
            md: "30%",
          },
        }}>
        <Stepper alternativeLabel activeStep={activeStep} connector={<StepConnectorLine />}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Stack>
  );
}
