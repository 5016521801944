import { store } from "../../Redux/Store/Store";

export const GetToken = () => {
  const userDetails = store.getState().userInfo;
  return (
    <center style={{ marginTop: "4rem" }}>
      <h1>TOKEN</h1>
      <textarea style={{ width: "28rem", height: "24rem", padding: "1rem", resize: "none" }}>
        {userDetails?.userToken}
      </textarea>
    </center>
  );
};
