import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Header } from "../components/Header/Header";
import { Footer } from "../components/shared/Footer/Footer";
import { setScrollPosition } from "../Redux/Slices/PageSlice";
import { useDispatch } from "react-redux";

export default function PrivateRoutes({ children }: any) {
  const isAuthenticated = process.env.REACT_APP_CXP_ADMIN_ACCESS_ENABLED;
  const dispatch = useDispatch();
  const pathname = window.location.pathname;
  const [showFooter, setShowFooter] = useState<boolean>(true);
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    dispatch(setScrollPosition(scrollPosition));
  };

  const handleDisplayFooter = () => {
    window.location.pathname.includes("commercial") ? setShowFooter(false) : setShowFooter(true);
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    handleDisplayFooter();
  }, [pathname]);

  return (
    <Stack justifyContent={"space-between"} height={"100vh"}>
      <Header />
      <Box
        width={{ sm: "100%", md: "80%" }}
        margin={{ sm: isAuthenticated === "TRUE" ? "1.5rem auto" : "0 auto" }}>
        {children}
      </Box>
      {showFooter && <Footer />}
    </Stack>
  );
}
