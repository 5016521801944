import { Container, Box, Stack, Typography, MenuItem, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import {
  CardHeading,
  ColumnCenterStack,
  ErrorMessage,
  RowCenterStack,
  StyledElement,
  ProfileInput,
} from "../../../../Styles/GlobalStyles";
import { Theme } from "../../../../Styles/GlobalTheme";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ADD_AUTHORIZED_USER,
  GET_AUTHORIZED_USERS,
  UNLINK_ACCOUNT,
} from "../../../../Pages/Account/query";
import { LoadingSpinner } from "../../LoadingSpinner/LoadingSpinner";
import { useSelector } from "react-redux";
import { CustomTextField } from "../../../../Styles/GlobalStyles";
import { ErrorComponent } from "../../../ErrorComponent/ErrorComponent";
import { validInputSubmit } from "../../../../Services/helper";
import { UpdateErrorComponent } from "../../UpdateErrorComponent/UpdateErrorComponent";
import { LinkedAccountCard } from "../../LinkedAccountCard/LinkedAccountCard";
import Button from "../../../Button/Button";
import {
  AUTHORIZED_USER_ADDED,
  AUTHORIZED_USER_DELETED,
  DELETE_AUTH_USER_HEADING,
  DELETE_AUTH_USER_PRIMARYCOTENT,
  EVENT_REMOVE_AUTH_USER,
  FETCHING_AUTHORIZED_USERS_MESSAGE,
} from "../../../../Constants/constants";
import { SuccessMessageModel } from "../../SuccessMessageModel/SuccessMessageModel";
import { Spinner } from "../../LoadingSpinner/Spinner";
import { ConfirmationModel } from "../../ConfirmationModel/ConfirmationModel";

export const AuthorizedUserComponent = () => {
  const customerDetails = useSelector((state: any) => state.customerInfoDetails);
  const [isNext, setIsNext] = useState<boolean>(false);
  const [addAuthorisedUser, setAddAuthorisedUser] = useState<boolean>(false);
  const [deleteUser, setDeleteUser] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [emails, setEmails] = useState<any>([]);
  const [formData, setFormData] = useState({ email: "" });
  const [formErrors, setFormErrors] = useState<any>({ email: "" });
  const [openError, setOpenError] = useState<boolean>(false);
  const [userToDeleteEmail, setUserToDeleteEmail] = useState<any>({
    contactId: "",
    email: "",
    contactName: "",
  });
  const [open, setOpen] = useState<boolean>(false);
  const [userStatus, setUserStatus] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<any>({
    heading: "",
    primaryContent: "",
    containedButton: { buttonType: "", buttonTitle: "" },
  });
  const loggedInEmail = customerDetails?.emailContact?.emailAddress;

  const filteredData = customerDetails?.linkedAccountNumbers?.filter(
    (item: any) => item.accountNumber === customerDetails?.accountNumber
  );

  const billingAccountNumber = filteredData?.[0]?.accountNumber;
  const pin = filteredData?.[0]?.pin;

  const [
    getAuthorizedUsers,
    { error: authorizedUsersError, loading: authorizedLoading, data: authorizedUsers },
  ] = useLazyQuery(GET_AUTHORIZED_USERS, {
    variables: {
      accountNumber: customerDetails?.accountNumber,
    },
    onCompleted: () => handleAuthorizedSuccess(),
    fetchPolicy: "network-only",
  });

  const EmptyUsers =
    authorizedUsers?.authorizedUsers?.length === 1 &&
    authorizedUsers?.authorizedUsers[0]?.email === loggedInEmail;

  const handleAuthorizedSuccess = () => {
    setError(authorizedUsers?.authorizedUsers?.[0]?.error);
  };

  const [unlink, { loading: unlinkLoading }] = useMutation(UNLINK_ACCOUNT, {
    refetchQueries: [GET_AUTHORIZED_USERS],
    onCompleted: () => setUserStatus(AUTHORIZED_USER_DELETED),
    onError: () => {
      setDeleteUser(false);
      setOpenError(true);
    },
  });

  const [addAuthorizedUser, { loading: addAuthorizedLoading }] = useMutation(ADD_AUTHORIZED_USER, {
    refetchQueries: () => [GET_AUTHORIZED_USERS],
    onCompleted: () => setUserStatus(AUTHORIZED_USER_ADDED),
    onError: () => {
      setOpenError(true);
    },
    fetchPolicy: "network-only",
  });

  const loader = addAuthorizedLoading || unlinkLoading;
  const handleClose = () => {
    setDeleteUser(false);
    setOpen(false);
  };
  const handleDelete = async () => {
    const response = await unlink({
      variables: {
        accountNumber: billingAccountNumber,
        contactId: userToDeleteEmail?.contactId,
        pin: pin,
        eventName: EVENT_REMOVE_AUTH_USER,
      },
    });
    if (response?.data?.unlinkAccount?.error) {
      setDeleteUser(false);
      setOpenError(true);
      setDeleteUser(false);
    } else {
      response?.data && setSuccessMessage(true);
      getAuthorizedUsers({ fetchPolicy: "network-only" });
      setUserStatus(AUTHORIZED_USER_DELETED);
      setDeleteUser(false);
    }
  };

  const handleCancel = () => {
    setFormErrors({});
    setAddAuthorisedUser(false);
    setDeleteUser(false);
    setFormData({ email: "" });
  };

  const handleUserMessageDissapear = () => {
    setTimeout(() => {
      setSuccessMessage(false);
    }, 5000);
  };

  const handleAddUser = async () => {
    try {
      const response = await addAuthorizedUser({
        variables: {
          email: formData?.email,
          contactId: customerDetails?.contactId,
          authorizedUserInput: {
            billingAccountNumber: billingAccountNumber,
            pin: pin,
            username: "myAccount",
            accountType: "OTHER",
          },
        },
      });
      if (response?.data?.addAuthorizedUser?.APIStatus === 412) {
        setOpen(true);
      } else if (!response?.data?.addAuthorizedUser?.error) {
        setEmails([...emails, formData?.email]);
        response?.data && setSuccessMessage(true);
        setUserStatus(AUTHORIZED_USER_ADDED);
        handleUserMessageDissapear();
        getAuthorizedUsers({ fetchPolicy: "network-only" });
        setAddAuthorisedUser(false);
        setFormData({ email: "" });
      } else if (response?.data?.addAuthorizedUser?.error) {
        setOpenError(true);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleAuthorized = () => {
    setAddAuthorisedUser(true);
    setIsNext(false);
  };

  const handleNext = () => {
    setIsNext(true);
  };
  const handleVerify = () => {
    const errors = validInputSubmit(formData);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    }
  };

  const handleSetNext = () => {
    const errors = validInputSubmit(formData);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    }
    const isNotEmpty = Object.values(errors).every((value: any) => value === "");
    if (isNotEmpty) {
      handleNext();
    }
  };
  const handleDeleteUser = (item: any) => {
    setDeleteUser(!deleteUser);
    setDialogData({
      ...dialogData,
      heading: DELETE_AUTH_USER_HEADING,
      primaryContent: DELETE_AUTH_USER_PRIMARYCOTENT,
      containedButton: {
        ...dialogData?.containedButton,
        buttonType: "payment",
        buttonTitle: "YES, DELETE",
      },
    });
    const deleteEmail = authorizedUsers?.authorizedUsers?.find(
      (card: any) => card?.contactId === item?.contactId
    );

    setUserToDeleteEmail({
      contactId: deleteEmail?.contactId,
      email: deleteEmail?.email,
      contactName: deleteEmail?.contactName,
    });
    const email = deleteEmail?.email;

    return email;
  };

  const handleEmail = (data: any, value: any) => {
    setFormData({ ...formData, email: data });
    setFormErrors({});
  };

  const handleEdit = () => {
    setIsNext(false); 
  };

  const handleRefresh = () => {
    getAuthorizedUsers({ fetchPolicy: "network-only" });
  };

  useEffect(() => {
    customerDetails?.accountNumber?.trim()?.length > 0 && getAuthorizedUsers();
  }, [customerDetails?.accountNumber]);

  return (
    <>
      <Box
        width={"inherit"}
        sx={{ backgroundColor: Theme.palette.customcolor.accountbackground, paddingY: 2 }}>
        <Container maxWidth="lg">
          {addAuthorisedUser ? (
            <Box width={"100%"}>
              <form>
                <CardHeading>Add authorized users</CardHeading>
                <Typography fontSize={{ xs: "12px", sm: "16px" }} my={2} component={"p"}>
                  Enter the new user's email below. We will send them instructions to create a
                  profile and log in. They will have full access to the account.
                </Typography>
                {isNext ? (
                  <>
                    <Stack direction={"row"} alignItems={"center"} gap={5}>
                      <Typography
                        fontSize={{ xs: "12px", sm: "14px" }}
                        data-testid="email"
                        component={"p"}
                        my={1}>
                        Email address
                      </Typography>
                      <StyledElement
                        fontSize={{ xs: "12px", sm: "14px" }}
                        data-testid={"editButton"}
                        onClick={handleEdit}>
                        EDIT
                      </StyledElement>
                    </Stack>
                    <Typography fontSize={{ xs: "14px", sm: "16px" }} component={"p"}>
                      {formData?.email}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography fontSize={"12px"} data-testid="email" component={"p"} my={1}>
                      Email address
                    </Typography>
                    <ProfileInput
                      inputProps={{ "data-testid": "emailInput" }}
                      name="email"
                      value={formData?.email}
                      onBlur={handleVerify}
                      hasError={formErrors?.email ? true : false}
                      onChange={(e: any) => handleEmail(e.target.value, "email")}
                      sx={{ width: { sm: "50%", xs: "100%" } }}
                    />
                    {formErrors?.email && (
                      <ErrorMessage error={formErrors?.email ? true : false}>
                        {formErrors?.email}
                      </ErrorMessage>
                    )}
                  </>
                )}
                <Stack
                  direction={"row"}
                  justifyContent={"center"}
                  spacing={3}
                  alignItems={"center"}
                  my={2}>
                  <Button type="pay" title="Cancel" onClick={handleCancel} />
                  {isNext ? (
                    <Button
                      eventName="ADD_AUTHORIZED_USER"
                      type="payment"
                      title="Add"
                      onClick={handleAddUser}
                    />
                  ) : (
                    <Button
                      type="payment"
                      title="Next"
                      disabled={formData?.email?.length === 0 || formErrors?.email?.length > 0}
                      onClick={handleSetNext}
                    />
                  )}
                </Stack>
              </form>
            </Box>
          ) : (
            <>
              {authorizedUsers?.authorizedUsers?.[0]?.error || authorizedUsersError ? (
                <ErrorComponent onRefresh={handleRefresh} />
              ) : (
                <Box
                  minHeight={authorizedUsers?.authorizedUsers?.length === 1 ? 100 : 200}
                  overflow={"auto"}>
                  <RowCenterStack>
                    <Typography
                      fontSize={{ xs: "14px", sm: "22px" }}
                      fontWeight={"bold"}
                      component={"p"}>
                      Account users
                    </Typography>
                    <Button title="Add Authorized User" type="root" onClick={handleAuthorized} />
                  </RowCenterStack>
                  <Box
                    mt={2}
                    height={authorizedUsers?.authorizedUsers?.length > 5 ? 370 : "auto"}
                    overflow={"auto"}>
                    {authorizedLoading ? (
                      <Box my={16}>
                        <Spinner loadingText={FETCHING_AUTHORIZED_USERS_MESSAGE} />
                      </Box>
                    ) : (
                      <>
                        {authorizedUsers?.authorizedUsers?.map((item: any) => {
                          return (
                            <Box key={item?.contactId}>
                              <ColumnCenterStack direction={"row"}>
                                <Typography fontSize={{ xs: "14px", sm: "16px" }} component={"p"}>
                                  {item?.contactName?.toLowerCase()}
                                </Typography>
                                <StyledElement
                                  display={item?.email === loggedInEmail ? "none" : "block"}
                                  data-testid={"delete-user"}
                                  onClick={() => handleDeleteUser(item)}
                                  fontSize={{ xs: "12px", sm: "14px" }}
                                  paddingRight={"12px"}>
                                  DELETE USER
                                </StyledElement>
                              </ColumnCenterStack>
                              <MenuItem
                                sx={{
                                  cursor: "default",
                                  "&:hover": { backgroundColor: "transparent" },
                                }}
                                disableRipple
                                disableGutters>
                                <Typography fontSize={{ xs: "12px", sm: "14px" }} component={"p"}>
                                  Email Address:&nbsp;
                                  <Typography
                                    component={"span"}
                                    data-testid="authorizedemail"
                                    fontSize={{ xs: "12px", sm: "14px" }}
                                    fontWeight={600}>
                                    {item?.email?.toLowerCase()}
                                  </Typography>
                                </Typography>
                              </MenuItem>
                              <Divider />
                            </Box>
                          );
                        })}
                      </>
                    )}
                  </Box>
                </Box>
              )}
            </>
          )}
        </Container>
      </Box>
      {successMessage && (
        <SuccessMessageModel
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          userStatus={userStatus}
        />
      )}
      {deleteUser && (
        <ConfirmationModel
          openDialog={deleteUser}
          setOpenDialog={setDeleteUser}
          dialogData={dialogData}
          authorizedEmail={userToDeleteEmail?.email}
          setDialogData={setDialogData}
          onClick={handleDelete}
          contactName={userToDeleteEmail?.contactName}
        />
      )}
      {open && <LinkedAccountCard open={open} setOpen={setOpen} formData={formData} />}
      {openError && <UpdateErrorComponent openError={openError} setOpenError={setOpenError} />}
      {loader === true && <LoadingSpinner />}
    </>
  );
};
