import { Stack } from "@mui/material";
import { LoginStyledA, SpanTypography } from "../../../Styles/GlobalStyles";
import HelpIcon from "../../../assets/icons/Help.svg";
import { HELP_CENTER_LINK } from "../../../Constants/constants";

export const LoginFooter = () => {
  return (
    <>
      <Stack alignItems={"center"} spacing={1} justifyContent={"center"}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={2}
          justifyContent={"center"}
          width={{ lg: "38ch", sm: "42ch", md: "38ch", xs: "28ch" }}>
          <SpanTypography>Need help?</SpanTypography>
          <Stack direction={"row"} alignItems={"center"} spacing={0.4}>
            <LoginStyledA target="_blank" href={HELP_CENTER_LINK}>
              HELP CENTER
            </LoginStyledA>
            <img src={HelpIcon} alt="Helpicon" width="12" />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
