import React, { useEffect, useState } from "react";
import { AutoPayDivider, CenterStack, StyledElement } from "../../Styles/GlobalStyles";
import { Divider, styled, Typography } from "@mui/material";
import ErrorLoadingIcon from "../../assets/icons/ErrorLoadIcon.svg";
import ReportIssue from "../shared/ReportIssue/ReportIssue";
import { useSelector } from "react-redux";

export const ErrorComponent = (props: any) => {
  const {
    onRefresh,
    errorMessage,
    ShowReportIssue,
    autopay,
    Managepaymentmethods,
    successMessage,
  } = props;
  const [openReportIssue, setOpenReportIssue] = useState(false);
  const { requestId } = useSelector((state: any) => state.paymentDetails);
  const handleRefresh = () => {
    onRefresh();
  };

  return (
    <>
      <CenterStack marginY={ShowReportIssue ? "0.2rem" : "1.2rem"} data-testid="refresh-page">
        <img
          src={ErrorLoadingIcon}
          alt="error-loading"
          width={ShowReportIssue && autopay ? "50" : "70"}
        />
        {errorMessage ? (
          <Typography
            component={"p"}
            fontSize={{ xs: "0.8rem", sm: "1rem" }}
            mt={2}
            textAlign={"center"}>
            {errorMessage}
          </Typography>
        ) : (
          <>
            <Typography component={"p"} mt={2} textAlign={"center"} data-testid="error-message">
              Sorry, something went wrong.
            </Typography>
            <Typography component={"p"}>Please click to refresh.</Typography>
          </>
        )}
        <StyledElement
          py={ShowReportIssue & autopay ? 0 : 1}
          onClick={handleRefresh}
          fontWeight={600}>
          REFRESH
        </StyledElement>
        {ShowReportIssue && (
          <>
            <ReportIssueDivider />
            <StyledElement fontWeight={600} onClick={() => setOpenReportIssue(true)}>
              SEND THE ERROR REPORT TO US.
            </StyledElement>
            <Typography component={"p"} textAlign={"center"} data-testid="error-message">
              This will help us investigate the issue further.
            </Typography>
            {autopay && <ReportIssueDivider />}
          </>
        )}
      </CenterStack>
      <ReportIssue
        setOpenDialog={setOpenReportIssue}
        openDialog={openReportIssue}
        successMessage={successMessage}
      />
    </>
  );
};

export const ReportIssueDivider = styled(Divider)(() => ({
  width: "100%",
  margin: "0.8rem 0",
}));
