import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import { Box, IconButton, Stack, Typography, styled } from "@mui/material";
import Icon from "@mui/material/Icon";
import {
  StyledBar,
  StyledElement,
  StyledDialog,
  ZiplyLogo,
  RowCenterStack,
  CardHeading,
} from "../../Styles/GlobalStyles";
import { ZIPLYLOGO } from "../../Constants/constants";
import { StyledTypography } from "../shared/CustomModal/CustomModalStlyes";
import { signOut } from "firebase/auth";
import { auth } from "../../Config/firebase";
import { useSelector } from "react-redux";
import { LoadingSpinner } from "../shared/LoadingSpinner/LoadingSpinner";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../Button/Button";
import LogoutIcon from "../../assets/icons/logout.svg";
import { RootState } from "../../Redux/Store/Store";
import FadeMenu from "../shared/FadeMenu/FadeMenu";
import ZiplyLogoIcon from "../../assets/images/ziply-logo.svg";

export interface LinkProps {
  nav?: boolean;
  active?: number;
}
export interface ZiplyLogoProps {
  width?: string;
  marginLeft?: string;
}
export interface HeaderIconProps {
  outline?: number;
}
export const HeaderIcon = styled(Icon)<HeaderIconProps>(({ outline }) => ({
  borderRadius: "50%",
  border: outline ? "none" : "2px solid",
  padding: outline ? "0" : "2px",
  fontSize: outline ? "1.6rem" : "1.2rem",
}));

export const AdminHeader = () => {
  const navigate = useNavigate();
  const customerDetails = localStorage.getItem("userName");
  const [openLoader, setOpenLoader] = React.useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const handleCloseLoader = () => {
    setOpenLoader(false);
  };
  const handleOpenLoader = () => {
    setOpenLoader(true);
  };

  const handleSignOut = () => {
    handleOpenLoader();
    setOpen(false);
    setTimeout(async () => {
      localStorage.clear();
      handleCloseLoader();
      navigate("/admin/login");

      // await signOut(auth)
      //   .then(() => {
      //     handleCloseLoader();
      //     navigate("/login");
      //   })
      //   .catch((err) => {
      //     handleCloseLoader();
      //     throw new Error(err);
      //   });
    }, 3000);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDialog = () => {
    setOpen(true);
  };

  return (
    <>
      <Box>
        <StyledBar
          width={{ sm: "100%", md: "80%" }}
          margin={"auto"}
          display={{ xs: "none", sm: "block" }}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <RowCenterStack>
              <IconButton color="inherit" size="small">
                <PersonIcon
                  fontSize="inherit"
                  sx={{
                    color: "green",
                  }}
                />
              </IconButton>
              <StyledTypography mr={2} color="green" fontSize={"14px"} fontWeight={"600"}>
                Welcome, {customerDetails}
              </StyledTypography>
            </RowCenterStack>
            <Stack onClick={handleDialog} direction="row" alignItems="center">
              <StyledElement textcolor="black" mx={1} fontWeight={"600"} fontSize={"14px"}>
                Sign out Admin
              </StyledElement>
              <img
                src={LogoutIcon}
                style={{ cursor: "pointer", paddingLeft: "0.5rem" }}
                alt="LogoutIcon"
              />
            </Stack>
          </div>
        </StyledBar>

        <Box boxShadow={"0px 3px 4px 0px rgb(193, 193, 193)"} px={{ xs: "0.5rem", md: "0" }}>
          <RowCenterStack
            width={{ sm: "100%", md: "83%", xl: "82%" }}
            py={{ sm: "0.4rem", xs: "0.8rem" }}
            margin={"auto"}>
            <ZiplyLogo src={ZiplyLogoIcon} alt="ziply-logo" />
          </RowCenterStack>
        </Box>
      </Box>

      {open && (
        <StyledDialog fullWidth open={open}>
          <IconButton sx={{ alignSelf: "flex-end", padding: "0rem" }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <>
            <Stack gap={1} textAlign={"center"} alignItems={"center"}>
              <CardHeading fontSize={{ xs: "0.9rem", sm: "1.3125rem" }}>Log out</CardHeading>
              <Typography
                fontSize={{ xs: "0.7rem", sm: "1rem" }}
                component={"p"}
                my={{ xs: "1rem", sm: "0rem" }}
                textAlign={"center"}>
                Are you sure you want to log out of the Ziply Fiber?
              </Typography>
              <Stack my={"1rem"}>
                <Button onClick={handleSignOut} title="YES, LOG OUT" type="root" />
              </Stack>
            </Stack>
          </>
        </StyledDialog>
      )}
      {openLoader && <LoadingSpinner />}
    </>
  );
};
