import React from "react";
import { Button as MuiButton } from "@mui/material";
import { Theme } from "../../Styles/GlobalTheme";
import { logEvents } from "../../Services/helper";
import { apm } from "@elastic/apm-rum";

interface ButtonProps {
  title: string;
  onClick?: any;
  type: string;
  disabled?: boolean;
  eventName?: string;
}
const classes = {
  root: {
    borderRadius: 6,
    backgroundColor: Theme.palette.secondary.main,
    color: Theme.palette.customcolor.whiteColor,
    fontWeight: "600",
    [Theme.breakpoints.down(600)]: {
      fontSize: "12px",
    },
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: Theme.palette.customcolor.emailTextColor,
    },
    "&:disabled": {
      backgroundColor: Theme.palette.customcolor.cancelbtntext,
      color: Theme.palette.customcolor.whiteColor,
    },
  },
  login: {
    borderRadius: 6,
    backgroundColor: Theme.palette.secondary.main,
    width: "60%",
    color: Theme.palette.customcolor.whiteColor,
    fontWeight: "600",
    [Theme.breakpoints.down(600)]: {
      fontSize: "12px",
    },
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: Theme.palette.customcolor.emailTextColor,
    },
    "&:disabled": {
      backgroundColor: Theme.palette.customcolor.cancelbtntext,
      color: Theme.palette.customcolor.whiteColor,
    },
  },
  next: {
    borderRadius: 6,
    width: "30%",
    backgroundColor: Theme.palette.secondary.main,
    color: Theme.palette.customcolor.whiteColor,
    fontWeight: "600",
    [Theme.breakpoints.down(600)]: {
      fontSize: "12px",
    },
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: Theme.palette.customcolor.emailTextColor,
    },
    "&:disabled": {
      backgroundColor: Theme.palette.customcolor.cancelbtntext,
      color: Theme.palette.customcolor.whiteColor,
    },
  },
  pay: {
    borderRadius: 6,
    width: 170,
    border: `2px solid ${Theme.palette.secondary.main}`,
    color: Theme.palette.secondary.main,
    padding: "6px 16px",
    [Theme.breakpoints.down(600)]: {
      fontSize: "12px",
    },
    fontWeight: "600",
    "&:hover": {
      color: Theme.palette.customcolor.emailTextColor,
      border: `2px solid ${Theme.palette.customcolor.emailTextColor}`,
      backgroundColor: Theme.palette.customcolor.whiteColor,
    },
    "&:disabled": {
      color: Theme.palette.customcolor.cancelbtntext,
      border: `2px solid ${Theme.palette.customcolor.cancelbtntext}`,
    },
  },
  pdf: {
    color: Theme.palette.secondary.main,
    padding: "6px 16px",
    [Theme.breakpoints.down(600)]: {
      fontSize: "12px",
    },
    "&:hover": {
      backgroundColor: Theme.palette.customcolor.whiteColor,
    },
    "&:disabled": {
      color: Theme.palette.customcolor.cancelbtntext,
    },
  },
  cancel: {
    borderRadius: 6,
    border: `2px solid ${Theme.palette.customcolor.cancelbtntext} `,
    color: Theme.palette.customcolor.cancelbtntext,
    fontWeight: "600",
    [Theme.breakpoints.down(600)]: {
      fontSize: "12px",
    },
    padding: "6px 16px",
    "&:hover": {
      color: Theme.palette.customcolor.emailTextColor,
      border: `2px solid ${Theme.palette.customcolor.emailTextColor}`,
      backgroundColor: Theme.palette.customcolor.whiteColor,
    },
  },
  payment: {
    borderRadius: 6,
    backgroundColor: Theme.palette.secondary.main,
    width: 170,
    [Theme.breakpoints.up(600)]: { width: 160 },
    color: Theme.palette.customcolor.whiteColor,
    fontWeight: "600",

    [Theme.breakpoints.down(600)]: {
      fontSize: "12px",
    },
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: Theme.palette.customcolor.emailTextColor,
    },
    "&:disabled": {
      backgroundColor: Theme.palette.customcolor.cancelbtntext,
      color: Theme.palette.customcolor.whiteColor,
    },
  },
  delete: {
    borderRadius: 6,
    border: "none",
    color: Theme.palette.secondary.main,
    fontWeight: "600",
    padding: "6px 16px",
    [Theme.breakpoints.down(600)]: {
      fontSize: "12px",
    },
    "&:hover": {
      color: "none",
      border: "none",
      backgroundColor: Theme.palette.customcolor.accordionbackground,
    },
  },
  selected: {
    backgroundColor: Theme.palette.customcolor.emailTextColor,
    color: Theme.palette.customcolor.whiteColor,
    borderRadius: 6,
    width: 170,
    padding: "6px 16px",
    [Theme.breakpoints.down(600)]: {
      fontSize: "12px",
    },
    fontWeight: "600",
    "&:hover": {
      backgroundColor: Theme.palette.customcolor.emailTextColor,
    },
    "&:disabled": {
      color: Theme.palette.customcolor.cancelbtntext,
      border: `2px solid ${Theme.palette.customcolor.cancelbtntext}`,
    },
  },
};

export default function Button({ eventName, title, onClick, disabled, type }: ButtonProps) {
  const handleOnClick = () => {
    let formattedTitle = title?.replace(/ /g, "_");
    logEvents(`${eventName ? eventName : formattedTitle}_clicked`);
    onClick();
    apm.startTransaction(`${eventName ? eventName : formattedTitle}_clicked`);
  };
  // @ts-ignore
  const customClass = (type: any) => {
    switch (type) {
      case "root":
        return classes.root;
      case "login":
        return classes.login;
      case "next":
        return classes.next;
      case "cancel":
        return classes.cancel;
      case "delete":
        return classes.delete;
      case "payment":
        return classes.payment;
      case "pdf":
        return classes.pdf;
      case "selected":
        return classes.selected;
      default:
        return classes.pay;
    }
  };

  return (
    <>
      <MuiButton disabled={disabled} disableRipple sx={customClass(type)} onClick={handleOnClick}>
        {title ? title : eventName}
      </MuiButton>
    </>
  );
}
