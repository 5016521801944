import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import fetch from "cross-fetch";
import { onError } from "@apollo/client/link/error";
import { BACKEND_URI, ENV, PROD_ENV, LOGGER_URL, SALT } from "../Constants/constants";
import { getAuth } from "firebase/auth";
import { store } from "../Redux/Store/Store";
import { v4 as uuidv4 } from "uuid";
import { apiLogEvents } from "./helper";
import { analytics } from "../Config/firebase";
import CryptoJS from "crypto-js";
import { setRequestId } from "../Redux/Slices/PaymentSlice";

const isAdmin = process.env.REACT_APP_CXP_ADMIN_ACCESS_ENABLED;
// Request Interceptors
const authLink = setContext(async (_, operation) => {
  const { requestId } = operation;
  let authToken = await getAuth().currentUser?.getIdToken();
  const { userToken } = store.getState().userInfo;
  //@ts-ignore
  apiLogEvents(analytics, "req", _.operationName);

  return {
    headers: {
      authorization: authToken ? `Bearer ${authToken}` : userToken ? `Bearer ${userToken}` : "",
      apikey: `${process.env.REACT_APP_MYACCOUNT_API_KEY}`,
      requestId: requestId,
    },
  };
});

// Custom Link for request and response handling
const requestResponseLink = new ApolloLink((operation, forward) => {
  const requestId = uuidv4(); // Use uuidv4 to generate a unique ID
  operation.setContext({ requestId });
  store.dispatch(setRequestId(requestId));
  return forward(operation);
});

const responseLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    ENV === PROD_ENV && logger(operation, response, null, false);
    return response;
  });
});

const logger = (operation: any, response: any, graphQLErrors: any, isError: boolean = true) => {
  const { requestId } = operation.getContext();
  const input = { ...operation.variables };
  delete input.password;
  delete input.encryptedPayload;
  let reqObject = {
    requestId,
    response,
    variables: input,
    name: operation.operationName, //@ts-ignore
    code: graphQLErrors?.[0]?.code,
    errors: graphQLErrors?.[0]?.message,
    source: "myAccount",
    responseType: !isError ? "success" : "failure",
  };
  const requestDataString = JSON.stringify(reqObject) + SALT;
  const checksum = CryptoJS.SHA256(requestDataString).toString(CryptoJS.enc.Hex);
  fetch(LOGGER_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Checksum": checksum,
    },
    body: JSON.stringify(reqObject),
  });
};
// Initialize Apollo Client
export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError, response, operation, forward }) => {
      // Handle errors
      if (ENV === PROD_ENV && isAdmin == "FALSE") {
        logger(operation, response, graphQLErrors);
      }
      if (graphQLErrors) {
        const errors = {
          name: operation?.operationName,
          variables: JSON.stringify(operation?.variables),
          errors: JSON.stringify(graphQLErrors),
        };
        for (let err of graphQLErrors) {
          let operationName =
            errors?.name === "updateNotificationPrefrence" ? "updateNotification" : errors.name;

          // @ts-ignore
          apiLogEvents(analytics, "API", `${operationName}_${err?.code}`);
          // @ts-ignore
          switch (err.code) {
            case "401":
              if (err.message.includes("Access denied!")) {
                const oldHeaders = operation.getContext().headers;
                const userDetails = store.getState().userInfo;
                let token = userDetails?.userToken;
                const newHeaders = {
                  ...oldHeaders,
                  authorization: token ? `Bearer ${token}` : "",
                  apikey: `${process.env.REACT_APP_MYACCOUNT_API_KEY}`,
                };
                const newOperation = {
                  ...operation,
                  getContext: () => ({
                    headers: newHeaders,
                  }),
                  setContext: () => ({
                    headers: newHeaders,
                  }),
                };

                return forward(newOperation);
              }
          }
        }
      }

      if (networkError) {
        ENV === PROD_ENV && logger(operation, response, networkError);
        console.log(`[Network error]: ${networkError}`);
      }
    }),
    responseLink.concat(
      requestResponseLink.concat(authLink.concat(createHttpLink({ uri: BACKEND_URI, fetch })))
    ),
  ]),
  cache: new InMemoryCache(),
});
