import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import {
  CardHeading,
  CenterStack,
  CustomTextField,
  ErrorMessage,
  ResetPasswordInput,
  SpanTypography,
  StyledDialog,
  StyledElement,
} from "../../Styles/GlobalStyles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Theme } from "../../Styles/GlobalTheme";
import { LoginHeader } from "../../components/shared/Login/LoginHeader";
import LoginBox from "../../components/shared/Login/LoginBox";
import { LoginFooter } from "../../components/shared/Login/LoginFooter";
import { useNavigate } from "react-router";
import { Footer } from "../../components/shared/Footer/Footer";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  MASKED_CONTACT_INFO,
  RESEND_OTP_EMAIL,
  RESEND_OTP_PHONE,
  SEND_OTP_BY_EMAIL,
  SEND_OTP_BY_PHONE,
  UPDATE_PASSWORD,
  VERIFY_OTP_EMAIL,
  VERIFY_OTP_PHONE,
} from "../Login/query";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner/LoadingSpinner";
import { GET_PUBLIC_KEY } from "../Signin/query";
import {
  RSAOAEPPadding,
  logEvents,
  splitMessageByBracesAndFullStop,
  validInputSubmit,
} from "../../Services/helper";
import { UpdateErrorComponent } from "../../components/shared/UpdateErrorComponent/UpdateErrorComponent";
import Button from "../../components/Button/Button";
import {
  COUNTDOWN_FOR_SEND_AND_RESEND_SMS,
  EXPIRED,
  MY_ACCOUNT,
  NOT_MATCH,
  OTP_DOES_NOT_MATCH,
  OTP_EXPIRED,
  OTP_INVALID,
  SEND_OTP_FAILED_MESSAGE,
  TELEMETRY_EVENT_FORGOT_PASSWORD,
  TELEMETRY_FAILURE,
  TELEMETRY_PLATFORM,
  TELEMETRY_SUCCESS,
} from "../../Constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { setForceReset } from "../../Redux/Slices/UserSlice";
import { CountdownTimer } from "../../components/shared/CountdownTimer/CountdownTimer";
import { usePasswordCriteria } from "../../hooks/Password/usePasswordCriteria";
import PasswordCriteria from "../../components/PasswordCriteria/PasswordCriteria";
import { ISAM } from "../Login/query";
import {
  StyledLoginErrorButtonStack,
  StyledLoginErrorStack,
  StyledLoginErrorTypography,
} from "../Login/Login";
import { ACTION_TELEMETRY } from "../../Routes/query";

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [onContinue, setOnContinue] = useState<boolean>(false);
  const [onVerify, setOnverify] = useState<boolean>(false);
  const [continueReset, setContinueReset] = useState<boolean>(false);
  const [showEmail, setShowEmail] = useState<boolean>(false);
  const [showPhoneNumber, setShowPhoneNumber] = useState<boolean>(false);
  const [uuid, setUuid] = useState<string>("");
  const [timer, setTimer] = useState<boolean>(false);
  const [otpSent, setOtpSent] = useState("");
  const [openError, setOpenError] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { forceReset, email } = useSelector((state: any) => state.userInfo);
  const [open, setOpen] = useState<boolean>(false);
  const [lsamError, setLsamError] = useState<boolean>(false);
  const [lsamForgotpassError, setlsamForgotpassError] = useState<string[]>([]);

  const { passwordCriteria, validatePasswordWithCriteria } = usePasswordCriteria();
  const [actionTelemetry] = useMutation(ACTION_TELEMETRY);

  const [formDataEmail, setFormDataEmail] = useState<any>({
    email: "",
  });
  const [formEmailErrors, setFormEmailErrors] = useState<any>({
    email: "",
  });

  const [formDataForPasswords, setFormDataForPasswords] = useState<any>({
    newPassword: "",
    confirmNewPassword: "",
  });
  const [formPasswordErrors, setFormPasswordErrors] = useState<any>({
    newPassword: "",
    confirmNewPassword: "",
  });
  const [formDataForOtp, setFormDataForOtp] = useState<any>({ otpPhone: "" });
  const [formErrorsForOtp, setFormErrorsForOtp] = useState<any>({ otpPhone: "" });
  const [formDataForEmail, setFormDataForEmail] = useState<any>({ otpEmail: "" });
  const [formEmailOTPErrors, setFormEmailOTPErrors] = useState<any>({ otpEmail: "" });

  const [getPublicKey, { data: publicKey }] = useLazyQuery(GET_PUBLIC_KEY);

  const [getMaskedContactInfo, { loading: maskedInfoLoading, data: maskedInfoData }] = useMutation(
    MASKED_CONTACT_INFO,
    {
      onError: (error: any) => {
        setOpenError(true);
        const errMessage = error.networkError.result.errors[0]?.message;
        handleActionTelemetry(TELEMETRY_FAILURE, errMessage);
      },
    }
  );

  const [sendOTPByPhone, { loading: sendOTPByPhoneLoading }] = useMutation(SEND_OTP_BY_PHONE, {
    onError: (error: any) => {
      setOpenError(true);
      setErrorMessage(SEND_OTP_FAILED_MESSAGE);
      const errMessage = error.networkError.result.errors[0]?.message;
      handleActionTelemetry(TELEMETRY_FAILURE, errMessage);
    },
  });
  const [sendOTPByEmail, { loading: sendOTPByEmailLoading }] = useMutation(SEND_OTP_BY_EMAIL, {
    onError: (error: any) => {
      setOpenError(true);
      setShowPhoneNumber(true);
      setShowEmail(false);
      setErrorMessage(SEND_OTP_FAILED_MESSAGE);
      setTimer(false);
      setOtpSent("");
      const errMessage = error.networkError.result.errors[0]?.message;
      handleActionTelemetry(TELEMETRY_FAILURE, errMessage);
    },
    onCompleted: () => {
      if (forceReset) {
        // setOnverify(true);
        handleContinue();
      }
    },
  });
  const [verifyOTPByPhone, { loading: verifyOTPByPhoneLoading }] = useMutation(VERIFY_OTP_PHONE, {
    onError: (error: any) => {
      if (error.networkError.result.errors[0]?.message?.toLowerCase()?.includes(NOT_MATCH)) {
        setFormErrorsForOtp({ ...formErrorsForOtp, otpPhone: OTP_DOES_NOT_MATCH });
      } else if (error.networkError.result.errors[0]?.message?.toLowerCase()?.includes(EXPIRED)) {
        setFormErrorsForOtp({ ...formErrorsForOtp, otpPhone: OTP_EXPIRED });
      } else {
        setFormErrorsForOtp({ ...formErrorsForOtp, otpPhone: OTP_INVALID });
      }
      const errMessage = error.networkError.result.errors[0]?.message;
      handleActionTelemetry(TELEMETRY_FAILURE, errMessage);
    },
  });
  const [verifyOTPByEmail, { loading: verifyOTPByEmailLoading }] = useMutation(VERIFY_OTP_EMAIL, {
    onError: (error: any) => {
      if (error.networkError.result.errors[0]?.message?.toLowerCase()?.includes(NOT_MATCH)) {
        setFormEmailOTPErrors({ ...formEmailOTPErrors, otpEmail: OTP_DOES_NOT_MATCH });
      } else if (error.networkError.result.errors[0]?.message?.toLowerCase()?.includes(EXPIRED)) {
        setFormEmailOTPErrors({ ...formEmailOTPErrors, otpEmail: OTP_EXPIRED });
      } else {
        setFormEmailOTPErrors({ ...formEmailOTPErrors, otpEmail: OTP_INVALID });
      }
      const errMessage = error.networkError.result.errors[0]?.message;
      handleActionTelemetry(TELEMETRY_FAILURE, errMessage);
    },
  });
  const [resendOTPByEmail, { loading: resendOTPByEmailLoading }] = useMutation(RESEND_OTP_EMAIL, {
    onError: (error: any) => {
      setOpenError(true);
      setErrorMessage(SEND_OTP_FAILED_MESSAGE);
      const errMessage = error.networkError.result.errors[0]?.message;
      handleActionTelemetry(TELEMETRY_FAILURE, errMessage);
    },
  });
  const [resendOTPByPhone, { loading: resendOTPByPhoneLoading }] = useMutation(RESEND_OTP_PHONE, {
    onError: (error: any) => {
      setOpenError(true);
      setErrorMessage(SEND_OTP_FAILED_MESSAGE);
      const errMessage = error.networkError.result.errors[0]?.message;
      handleActionTelemetry(TELEMETRY_FAILURE, errMessage);
    },
  });
  const [updatePassword, { loading: updatePasswordLoading }] = useMutation(UPDATE_PASSWORD, {
    onError: (error: any) => {
      const errMessage = error.networkError.result.errors[0]?.message;
      handleActionTelemetry(TELEMETRY_FAILURE, errMessage);
      setOpenError(true);
    },
  });

  const maskedEmail = maskedInfoData?.maskedContactInfo?.maskedEmail;
  const maskedPhoneNumber = maskedInfoData?.maskedContactInfo?.maskedPhoneNumber;

  const handleClickConfirmShowPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const handleClickNewShowPassword = () => {
    setShowNewPassword((show) => !show);
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleEmailChange = (e: any) => {
    setFormDataEmail({ ...formDataEmail, email: e.target.value });
    setFormEmailErrors({});
  };

  const handleOtpPhone = (e: any) => {
    setFormDataForOtp({ ...formDataForOtp, otpPhone: e.target.value });
    setFormErrorsForOtp({});
  };

  const handleOtpEmail = (e: any) => {
    setFormDataForEmail({ ...formDataForEmail, otpEmail: e.target.value });
    setFormEmailOTPErrors({});
  };

  const handleNewPassword = (e: any) => {
    validatePasswordWithCriteria(e.target.value);

    setFormDataForPasswords({ ...formDataForPasswords, newPassword: e.target.value });
    setFormPasswordErrors({});
  };

  const handleConfirmPassword = (e: any) => {
    setFormDataForPasswords({ ...formDataForPasswords, confirmNewPassword: e.target.value });
    setFormPasswordErrors({});
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handlePrevious = () => {
    setFormDataForPasswords({ newPassword: "", confirmNewPassword: "" });
    setFormDataForOtp({ otpPhone: "" });
    setFormDataForEmail({ otpEmail: "" });
    setShowPhoneNumber(false);
    setShowEmail(false);
    if (onContinue) {
      setOnContinue(false);
    } else if (onVerify) {
      setOnverify(false);
    } else {
      navigate("/login");
    }
  };

  const handleNavigate = () => {
    navigate("/login");
    dispatch(setForceReset(false));
  };
  const sendOtpByPhone = async () => {
    setFormDataForEmail({ otpEmail: "" });
    try {
      const sendResponse = await sendOTPByPhone({
        variables: {
          email: formDataEmail.email,
        },
      });
      if (sendResponse?.data?.sendOTPByPhone?.error) {
        setOpenError(true);
        setErrorMessage(SEND_OTP_FAILED_MESSAGE);
        setShowPhoneNumber(false);
        setShowEmail(true);
        setTimer(false);
      } else if (sendResponse?.data) {
        if (!sendResponse?.data?.sendOTPByPhone?.error) {
          setOtpSent(sendResponse?.data?.sendOTPByPhone?.message);
          setShowPhoneNumber(false);
          setFormEmailOTPErrors({});
          setShowEmail(true);
          setTimer(true);
          setTimeout(() => {
            setTimer(false);
          }, COUNTDOWN_FOR_SEND_AND_RESEND_SMS);
        }
      }
    } catch (error: any) {
      console.log(error);
      const errMessage = error.networkError.result.errors[0]?.message;
      handleActionTelemetry(TELEMETRY_FAILURE, errMessage);
    }
  };

  const sendOtpByEmail = async () => {
    setFormDataForOtp({ otpPhone: "" });
    try {
      const sendResponse = await sendOTPByEmail({
        variables: {
          email: forceReset ? email : formDataEmail?.email,
        },
      });
      if (sendResponse?.data?.sendOTPbyEmail?.error) {
        setOpenError(true);
        setErrorMessage(SEND_OTP_FAILED_MESSAGE);
        setShowPhoneNumber(true);
        setShowEmail(false);
        setTimer(false);
        setOtpSent("");
      } else if (sendResponse?.data) {
        if (!sendResponse?.data?.sendOTPbyEmail?.error) {
          setShowPhoneNumber(true);
          setShowEmail(false);
          setOtpSent(sendResponse?.data?.sendOTPbyEmail?.message);
          setFormErrorsForOtp({});
          setTimer(true);
          setTimeout(() => {
            setTimer(false);
          }, COUNTDOWN_FOR_SEND_AND_RESEND_SMS);
        }
      }
    } catch (error: any) {
      console.log(error);
      const errMessage = error.networkError.result.errors[0]?.message;
      handleActionTelemetry(TELEMETRY_FAILURE, errMessage);
    }
  };

  const handleResendByEmail = async () => {
    try {
      const resendEmailResponse = await resendOTPByEmail({
        variables: {
          email: forceReset ? email : formDataEmail?.email,
        },
      });
      if (resendEmailResponse?.data?.resendOTPByEmail?.error) {
        setOpenError(true);
        setErrorMessage(SEND_OTP_FAILED_MESSAGE);
      } else if (resendEmailResponse?.data) {
        if (!resendEmailResponse?.data?.resendOTPByEmail?.error) {
          setOtpSent(resendEmailResponse?.data?.resendOTPByEmail?.message);
          setTimer(true);
          setTimeout(() => {
            setTimer(false);
          }, COUNTDOWN_FOR_SEND_AND_RESEND_SMS);
        }
      }
    } catch (error: any) {
      console.log(error);
      const errMessage = error.networkError.result.errors[0]?.message;
      handleActionTelemetry(TELEMETRY_FAILURE, errMessage);
    }
  };

  const handleResendByPhone = async () => {
    try {
      const resendPhoneResponse = await resendOTPByPhone({
        variables: {
          email: formDataEmail?.email,
        },
      });
      if (resendPhoneResponse?.data?.resendOTPByPhone?.error) {
        setOpenError(true);
      } else if (resendPhoneResponse?.data) {
        if (!resendPhoneResponse?.data?.resendOTPByPhone?.error) {
          setOtpSent(resendPhoneResponse?.data?.resendOTPByPhone?.message);
          setTimer(true);
          setTimeout(() => {
            setTimer(false);
          }, COUNTDOWN_FOR_SEND_AND_RESEND_SMS);
        }
      }
    } catch (error: any) {
      console.log(error);
      const errMessage = error.networkError.result.errors[0]?.message;
      handleActionTelemetry(TELEMETRY_FAILURE, errMessage);
    }
  };

  const handleVerifyPhone = async () => {
    checkOTPValidation();
    const isNotEmpty = Object.values(formErrorsForOtp).every((value: any) => value !== "");
    if (isNotEmpty) {
      try {
        const verifyPhoneResponse = await verifyOTPByPhone({
          variables: {
            otp: formDataForOtp?.otpPhone,
            email: formDataEmail?.email,
          },
        });
        if (verifyPhoneResponse?.data) {
          if (!verifyPhoneResponse?.data?.verifyOTPByPhone?.error) {
            setUuid(verifyPhoneResponse?.data?.verifyOTPByPhone?.uuid);
            setOnContinue(true);
            setFormDataForOtp({ otpPhone: "" });
            logEvents("FORGET_OTP_VERIFIED");
          }
        }
      } catch (error: any) {
        console.log(error);
        const errMessage = error.networkError.result.errors[0]?.message;
        handleActionTelemetry(TELEMETRY_FAILURE, errMessage);
      }
    }
  };

  const handleVerifyEmail = async () => {
    checkOTPValidation();
    const isNotEmpty = Object.values(formEmailOTPErrors).every((value: any) => value !== "");
    if (isNotEmpty) {
      try {
        const verifyEmailResponse = await verifyOTPByEmail({
          variables: {
            otp: formDataForEmail?.otpEmail,
            email: forceReset ? email : formDataEmail?.email,
            updatingContact: false,
          },
        });
        if (verifyEmailResponse?.data?.verifyOTPByEmail?.error) {
          setOpenError(true);
        } else if (verifyEmailResponse?.data) {
          setUuid(verifyEmailResponse?.data?.verifyOTPByEmail?.uuid);
          setOnContinue(true);
          setFormDataForEmail({ otpEmail: "" });
          logEvents("FORGET_EMAIL_VERIFIED");
        }
      } catch (error: any) {
        console.log(error);
        const errMessage = error.networkError.result.errors[0]?.message;
        handleActionTelemetry(TELEMETRY_FAILURE, errMessage);
      }
    }
  };

  const handleVerifyOTP = () => {
    checkOTPValidation();
    if (showEmail) {
      handleVerifyPhone();
    } else {
      handleVerifyEmail();
    }
  };

  const checkOTPValidation = () => {
    if (showEmail) {
      const errors = validInputSubmit(formDataForOtp);
      if (Object.keys(errors?.otpPhone).length > 0) {
        setFormErrorsForOtp(errors);
      }
    } else {
      const errors = validInputSubmit(formDataForEmail);
      if (Object.keys(errors?.otpEmail).length > 0) {
        setFormEmailOTPErrors(errors);
      }
    }
  };

  const passwordValidation = () => {
    const errors = validInputSubmit(formDataForPasswords);
    if (Object.keys(errors?.newPassword || errors?.confirmNewPassword).length > 0) {
      setFormPasswordErrors(errors);
    }
  };

  const handleUpdatePassword = async () => {
    passwordValidation();
    const isNotEmpty = Object.values(formPasswordErrors).every((value: any) => value !== "");
    if (isNotEmpty) {
      const payload = `${formDataForPasswords?.confirmNewPassword},${uuid}`;
      const encryptedPayload = RSAOAEPPadding(payload, publicKey?.getPublicKey?.publicKey);
      try {
        const updateResponse = await updatePassword({
          variables: {
            encryptedPayload: encryptedPayload,
            email: forceReset ? email : formDataEmail?.email,
          },
        });
        if (updateResponse?.data?.updatePassword?.error) {
          setOpenError(true);
        } else if (updateResponse?.data) {
          setContinueReset(true);
          setFormDataForPasswords({ newPassword: "", confirmNewPassword: "" });
          logEvents("FORGET_UPDATE_PASSWORD");
          handleActionTelemetry(TELEMETRY_SUCCESS);
        }
      } catch (error: any) {
        console.log(error);
        const message = error.networkError.result.errors[0]?.message;
        handleActionTelemetry(TELEMETRY_FAILURE, message);
        setOpenError(true);
      }
    }
  };

  const handleActionTelemetry = async (status: any, message?: any) => {
    const telemetryObj = {
      event_name: TELEMETRY_EVENT_FORGOT_PASSWORD,
      platform: TELEMETRY_PLATFORM,
      customer_email: forceReset ? email : formDataEmail?.email,
      event_final_status: status,
      meta_data: message,
    };
    try {
      const encryptedData = RSAOAEPPadding(
        JSON.stringify(telemetryObj),
        publicKey?.getPublicKey?.publicKey
      );
      await actionTelemetry({
        variables: {
          payload: encryptedData,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const checkEmailValidation = () => {
    const errors = validInputSubmit(formDataEmail);
    if (Object.keys(errors).length > 0) {
      setFormEmailErrors(errors);
    }
  };

  const handleVerify = () => {
    const errors = validInputSubmit(formDataEmail);
    if (Object.keys(errors).length > 0) {
      setFormEmailErrors(errors);
    }
    const isNotEmpty = Object.values(errors).every((value: any) => value === "");
    if (isNotEmpty) {
      try {
        handleIsam();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const [lsam, { loading: popupLoading }] = useMutation(ISAM, {
    onError: (error: any) => {
      if (error.networkError.result.errors[0]?.code === "409") {
        const errorParts = splitMessageByBracesAndFullStop(
          error.networkError.result.errors[0].message
        );

        setLsamError(true);
        setOpen(true);
        setlsamForgotpassError(errorParts);
      } else {
        handleContinue();
      }
    },
  });

  const handleIsam = async () => {
    try {
      const response = await lsam({
        variables: {
          email: formDataEmail.email,
          app: MY_ACCOUNT,
        },
      });
      if (response?.data?.lsam?.error) {
        handleContinue();
      } else if (response?.data) {
        handleContinue();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handlesForgotSignup = () => {
    navigate(
      { pathname: "/register" },
      { state: { data: formDataEmail.email ? formDataEmail.email : "" } }
    );
  };

  const handleContinue = async () => {
    try {
      const response = await getMaskedContactInfo({
        variables: {
          email: forceReset ? email : formDataEmail?.email,
        },
      });
      if (response?.data?.maskedContactInfo?.error) {
        setOpenError(true);
      } else {
        response?.data && setOnverify(true);
      }
    } catch (err: any) {
      const errMessage = err.networkError.result.errors[0]?.message;
      handleActionTelemetry(TELEMETRY_FAILURE, errMessage);
    }
  };

  const loader =
    maskedInfoLoading ||
    sendOTPByPhoneLoading ||
    sendOTPByEmailLoading ||
    resendOTPByPhoneLoading ||
    resendOTPByEmailLoading ||
    verifyOTPByPhoneLoading ||
    verifyOTPByEmailLoading ||
    updatePasswordLoading ||
    popupLoading;

  useEffect(() => {
    getPublicKey();
  }, [getPublicKey]);

  return (
    <Stack justifyContent={"space-between"} height={"100vh"} overflow={"auto"}>
      <LoginHeader />
      <Box>
        <CenterStack direction={"column"} width={{ xs: "100%" }} py={6}>
          <LoginBox>
            <Box width={"95%"}>
              {continueReset ? (
                <CenterStack direction={"column"} spacing={1} py={4}>
                  {forceReset && <CardHeading>Password Updated</CardHeading>}
                  <Typography
                    component={"span"}
                    fontSize={{ xs: "14px", sm: "16px" }}
                    textAlign={"center"}
                    data-testid="success-msg"
                    fontWeight={600}>
                    You have successfully reset your password.
                  </Typography>
                  <Typography
                    component={"span"}
                    fontSize={{ xs: "14px", sm: "16px" }}
                    textAlign={"center"}
                    fontWeight={600}>
                    Please proceed to log in with the new password.
                  </Typography>
                  <Box pt={2}>
                    <Button title="log in" type="payment" onClick={handleNavigate} />
                  </Box>
                </CenterStack>
              ) : (
                <>
                  {onContinue ? (
                    <>
                      <Typography
                        fontSize={"16px"}
                        fontWeight={600}
                        mb={2}
                        component={"p"}
                        textAlign={"center"}>
                        Reset password
                      </Typography>
                      <Stack gap={2}>
                        <FormControl fullWidth>
                          <Typography fontSize={"14px"} component={"p"} my={1}>
                            Enter new password
                          </Typography>
                          <ResetPasswordInput
                            inputProps={{ "data-testid": "password" }}
                            fullWidth
                            name="password"
                            onBlur={passwordValidation}
                            value={formDataForPasswords?.newPassword}
                            hasError={formPasswordErrors?.newPassword ? true : false}
                            onChange={handleNewPassword}
                            id="outlined-adornment-password"
                            type={showNewPassword ? "text" : "password"}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  disableRipple
                                  aria-label="toggle password visibility"
                                  onClick={handleClickNewShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end">
                                  {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          {formPasswordErrors?.newPassword && (
                            <ErrorMessage error={formPasswordErrors?.newPassword ? true : false}>
                              {formPasswordErrors?.newPassword}
                            </ErrorMessage>
                          )}
                        </FormControl>
                        <FormControl fullWidth>
                          <Typography fontSize={"14px"} my={1}>
                            Confirm new password
                          </Typography>
                          <ResetPasswordInput
                            inputProps={{ "data-testid": "confirmPassword" }}
                            name="confirmPassword"
                            value={formDataForPasswords?.confirmNewPassword}
                            onChange={handleConfirmPassword}
                            hasError={formPasswordErrors?.confirmNewPassword ? true : false}
                            fullWidth
                            onBlur={passwordValidation}
                            id="outlined-adornment-confirm-password"
                            type={showConfirmPassword ? "text" : "password"}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  disableRipple
                                  aria-label="toggle password visibility"
                                  onClick={handleClickConfirmShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end">
                                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          {formPasswordErrors?.confirmNewPassword && (
                            <ErrorMessage
                              error={formPasswordErrors?.confirmNewPassword ? true : false}>
                              {formPasswordErrors?.confirmNewPassword}
                            </ErrorMessage>
                          )}
                        </FormControl>
                        <PasswordCriteria passwordCriteria={passwordCriteria} />
                        <CenterStack direction={"row"} spacing={2} my={2}>
                          {!forceReset && (
                            <Button title="Previous" onClick={handlePrevious} type="pay" />
                          )}
                          <Button
                            title="Continue"
                            eventName="RESET_PASSWORD_COMPLETE"
                            onClick={handleUpdatePassword}
                            type="payment"
                          />
                        </CenterStack>
                      </Stack>
                    </>
                  ) : onVerify ? (
                    <>
                      <Typography fontSize={"16px"} component="h1" fontWeight={600} mb={2}>
                        {forceReset
                          ? "Please check you email and enter the security code to proceed."
                          : "Please select an option to receive a security code."}
                      </Typography>
                      {forceReset ? (
                        <Stack justifyContent={"center"} spacing={1}>
                          <SpanTypography py={1}>
                            Security code sent to email: <b>{maskedEmail}</b>
                          </SpanTypography>
                          <SpanTypography>Enter security code</SpanTypography>
                          <CustomTextField
                            inputProps={{ "data-testid": "security-email" }}
                            variant="outlined"
                            required
                            name="security"
                            onBlur={checkOTPValidation}
                            fullWidth
                            hasError={formEmailOTPErrors?.otpEmail ? true : false}
                            value={formDataForEmail?.otpEmail}
                            placeholder="6 digit"
                            onChange={handleOtpEmail}
                          />
                          {formEmailOTPErrors?.otpEmail && (
                            <ErrorMessage error={formEmailOTPErrors?.otpEmail ? true : false}>
                              {formEmailOTPErrors?.otpEmail}
                            </ErrorMessage>
                          )}
                          {timer ? (
                            <Typography
                              component={"p"}
                              alignSelf={"end"}
                              fontSize={{ xs: "10px", sm: "12px" }}
                              color={Theme.palette.customcolor.profileTypoGraphyColor}>
                              RESEND CODE IN &nbsp; <CountdownTimer minutes={5} />
                            </Typography>
                          ) : (
                            <>
                              <StyledElement
                                fontSize={{ xs: "10px", sm: "12px" }}
                                alignSelf={"end"}
                                onClick={handleResendByEmail}>
                                RESEND OTP
                              </StyledElement>
                            </>
                          )}
                          <Typography
                            textAlign={"center"}
                            color={Theme.palette.primary.main}
                            variant="caption">
                            {otpSent}
                          </Typography>
                        </Stack>
                      ) : (
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group">
                          <FormControl fullWidth sx={{ marginY: 2 }}>
                            <FormControlLabel
                              data-testid="radio"
                              value="Receive a security code on mobile number"
                              control={<Radio disableRipple />}
                              label={
                                <Typography fontSize={{ xs: "0.8rem", sm: "0.9rem" }}>
                                  Text a security code to &nbsp;
                                  <Typography
                                    fontSize={{ xs: "0.8rem", sm: "0.9rem" }}
                                    fontWeight={"600"}
                                    component={"span"}>
                                    {maskedPhoneNumber}
                                  </Typography>
                                </Typography>
                              }
                              onChange={sendOtpByPhone}
                            />
                            {showEmail && (
                              <Stack spacing={1} mt={2}>
                                <SpanTypography>Enter security code</SpanTypography>
                                <CustomTextField
                                  inputProps={{ "data-testid": "security-phone" }}
                                  variant="outlined"
                                  required
                                  name="security"
                                  onBlur={checkOTPValidation}
                                  placeholder="6 digit"
                                  fullWidth
                                  hasError={formErrorsForOtp?.otpPhone ? true : false}
                                  value={formDataForOtp?.otpPhone}
                                  onChange={handleOtpPhone}
                                />
                                {formErrorsForOtp?.otpPhone && (
                                  <ErrorMessage error={formErrorsForOtp?.otpPhone ? true : false}>
                                    {formErrorsForOtp?.otpPhone}
                                  </ErrorMessage>
                                )}
                                {timer ? (
                                  <Typography
                                    component={"p"}
                                    alignSelf={"end"}
                                    fontSize={{ xs: "10px", sm: "12px" }}
                                    color={Theme.palette.customcolor.profileTypoGraphyColor}>
                                    RESEND CODE IN &nbsp; <CountdownTimer minutes={5} />
                                  </Typography>
                                ) : (
                                  <StyledElement
                                    data-testid="resend-phone"
                                    fontSize={{ xs: "10px", sm: "12px" }}
                                    onClick={handleResendByPhone}
                                    alignSelf={"end"}>
                                    RESEND OTP
                                  </StyledElement>
                                )}
                                <Typography
                                  textAlign={"center"}
                                  color={Theme.palette.primary.main}
                                  variant="caption">
                                  {otpSent}
                                </Typography>
                              </Stack>
                            )}
                          </FormControl>
                          <FormControl fullWidth sx={{ marginY: 2 }}>
                            <FormControlLabel
                              data-testid="radio"
                              onChange={sendOtpByEmail}
                              value="Receive a security code on email"
                              control={<Radio disableRipple />}
                              label={
                                <Typography fontSize={{ xs: "0.8rem", sm: "0.9rem" }}>
                                  Email a security code to &nbsp;
                                  <Typography
                                    fontSize={{ xs: "0.8rem", sm: "0.9rem" }}
                                    fontWeight={"600"}
                                    component={"span"}>
                                    {maskedEmail}
                                  </Typography>
                                </Typography>
                              }
                            />
                            {showPhoneNumber && (
                              <Stack spacing={1} mt={2}>
                                <SpanTypography>Enter security code</SpanTypography>
                                <CustomTextField
                                  inputProps={{ "data-testid": "security-email" }}
                                  variant="outlined"
                                  required
                                  name="security"
                                  placeholder="6 digit"
                                  onBlur={checkOTPValidation}
                                  fullWidth
                                  hasError={formEmailOTPErrors?.otpEmail ? true : false}
                                  value={formDataForEmail?.otpEmail}
                                  onChange={handleOtpEmail}
                                />
                                {formEmailOTPErrors?.otpEmail && (
                                  <ErrorMessage error={formEmailOTPErrors?.otpEmail ? true : false}>
                                    {formEmailOTPErrors?.otpEmail}
                                  </ErrorMessage>
                                )}
                                {timer ? (
                                  <Typography
                                    component={"p"}
                                    alignSelf={"end"}
                                    fontSize={{ xs: "10px", sm: "12px" }}
                                    color={Theme.palette.customcolor.profileTypoGraphyColor}>
                                    RESEND CODE IN &nbsp; <CountdownTimer minutes={5} />
                                  </Typography>
                                ) : (
                                  <>
                                    <StyledElement
                                      fontSize={{ xs: "10px", sm: "12px" }}
                                      alignSelf={"end"}
                                      onClick={handleResendByEmail}>
                                      RESEND OTP
                                    </StyledElement>
                                  </>
                                )}
                                <Typography
                                  textAlign={"center"}
                                  color={Theme.palette.primary.main}
                                  variant="caption">
                                  {otpSent}
                                </Typography>
                              </Stack>
                            )}
                          </FormControl>
                        </RadioGroup>
                      )}
                      <CenterStack direction={"row"} spacing={2} my={2}>
                        {!forceReset && (
                          <Button title="Previous" onClick={handlePrevious} type="pay" />
                        )}
                        <Button
                          title="Continue"
                          eventName={
                            showPhoneNumber
                              ? "FORGET_PASSWORD_VERIFY_EMAIL"
                              : "FORGET_PASSWORD_VERIFY_PHONE"
                          }
                          onClick={handleVerifyOTP}
                          type="payment"
                        />
                      </CenterStack>
                    </>
                  ) : (
                    <>
                      <Typography fontSize={"16px"} component="h1" fontWeight={600} mb={2}>
                        {forceReset
                          ? "Please verify your email to receive security code"
                          : "Please enter the email address associated with this account and we will provide verification options."}
                      </Typography>
                      <Box>
                        <FormControl fullWidth>
                          <Stack spacing={1} my={1}>
                            <Typography fontSize={"14px"}>
                              {forceReset ? "Email" : "Enter email"}
                            </Typography>
                            <CustomTextField
                              inputProps={{ "data-testid": "email-input" }}
                              variant="outlined"
                              required
                              fullWidth
                              onBlur={checkEmailValidation}
                              name="email"
                              hasError={formEmailErrors?.email ? true : false}
                              value={forceReset ? email : formDataEmail?.email}
                              onChange={handleEmailChange}
                              disabled={forceReset}
                            />
                            {formEmailErrors?.email && (
                              <ErrorMessage error={formEmailErrors?.email ? true : false}>
                                {formEmailErrors?.email}
                              </ErrorMessage>
                            )}
                          </Stack>
                        </FormControl>
                        <CenterStack direction={"row"} spacing={2} my={4}>
                          {!forceReset && (
                            <Button title="Previous" onClick={handlePrevious} type="pay" />
                          )}
                          <Button
                            title="Continue"
                            eventName="RESET_PASSWORD_CONTINUE"
                            onClick={forceReset ? sendOtpByEmail : handleVerify}
                            type="payment"
                          />
                        </CenterStack>
                      </Box>
                    </>
                  )}
                </>
              )}
            </Box>
          </LoginBox>
          <Box mt={6}>
            <LoginFooter />
          </Box>
        </CenterStack>
      </Box>
      <Footer />
      {lsamError && (
        <>
          <StyledDialog data-testid="usermessage" fullWidth open={lsamError}>
            <Stack my={1.5}>
              <StyledLoginErrorStack padding={"20px"} gap={"1rem"}>
                <StyledLoginErrorStack>
                  <StyledLoginErrorTypography>
                    {lsamForgotpassError?.map((data: any) => {
                      return (
                        <>
                          <Typography
                            fontWeight={600}
                            sx={{ fontSize: { xs: "12px", sm: "14px" } }}>
                            {data}
                          </Typography>
                        </>
                      );
                    })}
                  </StyledLoginErrorTypography>
                </StyledLoginErrorStack>
                <StyledLoginErrorButtonStack direction={"row"}>
                  <Button title="Sign up" type="root" onClick={handlesForgotSignup} />
                </StyledLoginErrorButtonStack>
              </StyledLoginErrorStack>
            </Stack>
          </StyledDialog>
        </>
      )}

      {loader === true && <LoadingSpinner />}
      {openError && (
        <UpdateErrorComponent
          setErrorMessage={setErrorMessage}
          openError={openError}
          message={errorMessage}
          setOpenError={setOpenError}
        />
      )}
    </Stack>
  );
};
