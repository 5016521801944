import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openModal: true,
  openErrorModal: false,
  modalContent: false,
  openSwitchModal: false,
};

const AppProposalSlice = createSlice({
  name: "AppProposal",
  initialState,
  reducers: {
    setOpenModal: (state, action) => {
      state.openModal = action.payload;
    },
    setOpenErrorModal: (state, action) => {
      state.openErrorModal = action.payload;
    },
    setModalContent: (state, action) => {
      state.modalContent = action.payload;
    },
    setOpenSwitchModal: (state, action) => {
      state.openSwitchModal = action.payload;
    },
  },
});

export const { setOpenModal, setOpenErrorModal, setModalContent, setOpenSwitchModal } =
  AppProposalSlice.actions;
export const appProposalReducer = AppProposalSlice.reducer;
