import React from "react";
import SuccessDialogBox from "../NetworkStatus/SuccessDialogBox";
import {
  setCreateServiceTicketFail,
  setExistingTicket,
  setOpenOutageBanner,
} from "../../../../Redux/Slices/TroubleTicketSlice";
import { CenterStack } from "../../../../Styles/GlobalStyles";
import { Typography } from "@mui/material";
import { CREATE_SERVICE_TICKET_HEADINGS } from "../../../../Constants/constants";
import { useDispatch, useSelector } from "react-redux";
import ContactSupport from "../../../../components/shared/ContactSupport/ContactSupport";

function CreateServiceError() {
  const dispatch = useDispatch();
  const { openOutageBanner, existingTicket, createServiceTicketFail, createServiceTicketSuccess } =
    useSelector((state: any) => state.troubleTicketDetails);

  return (
    <>
      <SuccessDialogBox
        open={openOutageBanner}
        onClose={() => dispatch(setOpenOutageBanner(false))}
        heading={CREATE_SERVICE_TICKET_HEADINGS.outage}
        message={
          <CenterStack my={2}>
            <Typography textAlign={"center"} fontSize={{ xs: "14px", md: "16px" }}>
              We are aware of service disruption in your area. We apologize for the inconvenience
              and are working to restore services as quickly as possible.
            </Typography>
            <Typography textAlign={"center"} fontSize={{ xs: "14px", md: "16px" }}>
              If you are trying to report a service issue, please note that we are already
              addressing the problem in your area.
            </Typography>
          </CenterStack>
        }
        buttonTitle={"Go back"}
      />
      <SuccessDialogBox
        open={existingTicket}
        onClose={() => dispatch(setExistingTicket(false))}
        heading={CREATE_SERVICE_TICKET_HEADINGS.existing}
        message={
          <CenterStack my={2}>
            <Typography textAlign={"center"} fontSize={{ xs: "14px", md: "16px" }}>
              You already have an open ticket with us. We are working to resolve it and will update
              you soon. Thank you for your patience.
            </Typography>
            <Typography
              textAlign="center"
              mt={1}
              fontSize={{ xs: "14px", md: "16px" }}
              sx={{ display: "inline-flex", whiteSpace: "nowrap" }}>
              If you need further assistance, please
              <span style={{ marginLeft: "4px" }}>
                <ContactSupport fontSize="16px" />
              </span>
            </Typography>
          </CenterStack>
        }
        buttonTitle={"Go back"}
      />
      <SuccessDialogBox
        open={createServiceTicketFail}
        onClose={() => dispatch(setCreateServiceTicketFail(false))}
        heading={CREATE_SERVICE_TICKET_HEADINGS.failure}
        message={
          <CenterStack my={2}>
            <Typography fontSize={{ xs: "14px", md: "16px" }}>
              Something went wrong on our side.
            </Typography>
            <Typography fontSize={{ xs: "14px", md: "16px" }}>
              Please check back again later.
            </Typography>
          </CenterStack>
        }
        buttonTitle={"Go Back"}
      />
    </>
  );
}

export default CreateServiceError;
