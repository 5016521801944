import { Button, Stack, styled } from "@mui/material";
import { StyledA, StyledBar, ZiplyLogo } from "../../../Styles/GlobalStyles";
import ZiplyLogoIcon from "../../../assets/images/ziply-logo.png";

export const LoginHeader = ({ type }: any) => {
  return (
    <StyledBar boxShadow={1}>
      <Stack
        direction={"row"}
        alignItems={"flex-start"}
        py={{ sm: "0.8rem", xs: "1.2rem" }}
        px={{ xs: 2, sm: 0, md: 10 }}>
        <StyledA target="_self" href="https://ziplyfiber.com">
          <ZiplyLogo src={ZiplyLogoIcon} alt="ziply-logo" />
        </StyledA>
      </Stack>
    </StyledBar>
  );
};

export const LoginBtn = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  borderRadius: "1.5rem",
  height: "3rem",
  width: "13rem",
  "&:hover": {
    background: theme.palette.secondary.main,
  },
}));
