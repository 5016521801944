import React from "react";
import { CardHeading, DialogTypography, StyledDialog } from "../../../Styles/GlobalStyles";
import { IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../Button/Button";
import { Theme } from "../../../Styles/GlobalTheme";
import { useSelector } from "react-redux";

export const ConfirmationModel = (props: any) => {
  const {
    openDialog,
    setOpenDialog,
    dialogData,
    onClick,
    accountNumber,
    paymentCardName,
    authorizedEmail,
    contactName,
    onClickSecondary,
  } = props;
  const { paymentConfirmationMessage } = useSelector((state: any) => state.customerPlanDetails);
  const closeConfirmationModel = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <StyledDialog data-testid="usermessage" fullWidth open={openDialog}>
        <IconButton
          data-testid="close-button"
          sx={{ alignSelf: "flex-end", padding: 0 }}
          onClick={closeConfirmationModel}>
          <CloseIcon />
        </IconButton>
        <Stack
          textAlign={"center"}
          alignItems={"center"}
          px={paymentConfirmationMessage ? "26px" : ""}>
          <CardHeading>{dialogData?.heading}</CardHeading>
          <Stack my={1.5} spacing={paymentConfirmationMessage ? 3 : 0} justifyContent={"center"} alignItems={"center"}>
            <DialogTypography>
              {dialogData?.primaryContent ? dialogData?.primaryContent : dialogData?.content}
            </DialogTypography>
            <DialogTypography>
              {dialogData?.secondaryContent ? dialogData?.secondaryContent : dialogData?.subContent}
            </DialogTypography>
            <DialogTypography>{dialogData?.tertiaryContent}</DialogTypography>
          </Stack>
          <DialogTypography fontWeight={600}>
            {accountNumber && `${accountNumber}?`}
          </DialogTypography>
          <DialogTypography fontWeight={600} color={Theme.palette.customcolor.emailTextColor}>
            {contactName && `${contactName}`}
          </DialogTypography>
          <DialogTypography fontWeight={600} color={Theme.palette.customcolor.emailTextColor}>
            {authorizedEmail ? `${authorizedEmail}` : paymentCardName ? `${paymentCardName}` : null}
          </DialogTypography>
          <Stack mt={2} mb={4} direction={"row"} gap={"16px"} justifyContent={"center"}>
            {dialogData?.outlinedButton && (
              <Button
                title={dialogData?.outlinedButton?.buttonTitle}
                type={dialogData?.outlinedButton?.buttonType}
                onClick={onClickSecondary}
              />
            )}
            {dialogData?.containedButton && (
              <Button
                eventName={dialogData?.containedButton?.eventName}
                title={dialogData?.containedButton?.buttonTitle}
                type={dialogData?.containedButton?.buttonType}
                onClick={onClick}
              />
            )}
          </Stack>
        </Stack>
      </StyledDialog>
    </>
  );
};
