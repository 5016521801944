import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { Stack } from "@mui/material";
import { CurrentBillTable } from "./CurrenBillTable/CurrentBillTable";
import { useSelector } from "react-redux";
import { formatDateWithSuffix } from "../../../../../Services/helper";
import {
  StyledElement,
  ColumnCenterStack,
  InformationBox,
  StyledA,
} from "../../../../../Styles/GlobalStyles";
import { GET_CURRENT_BILL_DETAILS } from "../../../query";
import { useLazyQuery } from "@apollo/client";
import { ErrorComponent } from "../../../../../components/ErrorComponent/ErrorComponent";
import { EmptyDataComponent } from "../../../../../components/shared/EmptyDataComponent/EmptyDataComponent";
import { Spinner } from "../../../../../components/shared/LoadingSpinner/Spinner";
import Button from "../../../../../components/Button/Button";
import { Theme } from "../../../../../Styles/GlobalTheme";
import {
  LOADING_CURRENT_BILL_DETAILS_MESSAGE,
  NO_BILL_INFORMATION,
} from "../../../../../Constants/constants";

function CurrentBillInfo() {
  const customerDetails = useSelector((state: any) => state.customerInfoDetails);
  const { userToken } = useSelector((state: any) => state.userInfo);
  const [error, setError] = useState(false);
  const [getCurrentBill, { loading: currentBillLoading, data: currentBillData }] = useLazyQuery(
    GET_CURRENT_BILL_DETAILS,
    {
      variables: {
        accountNumber: customerDetails?.accountNumber,
      },
      onError: () => setError(true),
    }
  );

  const billingDate = currentBillData && currentBillData?.currentBillDetails?.billingDate;
  const url =
    currentBillData && customerDetails?.ndsObj?.isNDS
      ? `${currentBillData?.currentBillDetails?.url}&token=${userToken}`
      : currentBillData?.currentBillDetails?.url;

  const date = new Date(billingDate);
  const dateWithSuffix = formatDateWithSuffix(date);

  const fromDate = new Date(currentBillData?.currentBillDetails?.fromDate);
  const dateWithSuffix2 = formatDateWithSuffix(fromDate);

  const toDate = new Date(currentBillData?.currentBillDetails?.toDate);
  const dateWithSuffix3 = formatDateWithSuffix(toDate);

  const accountNumber = currentBillData?.currentBillDetails?.accountNumber;
  const hyphenSeperatedAccNum = accountNumber?.replace(
    /^(\d{3})(\d{3})(\d{4})(\d{6})(\d)$/,
    "$1-$2-$3-$4-$5"
  );

  const handleRefresh = () => {
    getCurrentBill({ fetchPolicy: "network-only" });
  };

  useEffect(() => {
    if (customerDetails?.accountNumber?.trim()?.length > 0) {
      getCurrentBill();
    }
  }, [customerDetails?.accountNumber, getCurrentBill]);

  const EMPTYCURRENTBILLDATA =
    currentBillData?.currentBillDetails?.error === false &&
    currentBillData?.currentBillDetails?.currentBill === null;

  return (
    <>
      {currentBillLoading ? (
        <Box my={20}>
          <Spinner loadingText={LOADING_CURRENT_BILL_DETAILS_MESSAGE} />
        </Box>
      ) : (
        <>
          {currentBillData?.currentBillDetails?.error || error ? (
            <Box my={10}>
              <ErrorComponent onRefresh={handleRefresh} />
            </Box>
          ) : EMPTYCURRENTBILLDATA ? (
            <Box my={20}>
              <EmptyDataComponent message={NO_BILL_INFORMATION} />
            </Box>
          ) : (
            <>
              <InformationBox>
                <Box>
                  <Typography component={"p"} fontSize={{ xs: "0.8rem", sm: "1rem" }}>
                    Here is a detailed view of your current Ziply Fiber bill.
                  </Typography>
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    justifyContent={"space-between"}
                    my={2}>
                    <ColumnCenterStack spacing={2} direction={"row"}>
                      <Typography
                        component={"span"}
                        fontWeight={600}
                        fontSize={{ xs: "0.8rem", sm: "0.9rem" }}>
                        Account number
                      </Typography>

                      <Typography
                        component={"span"}
                        fontSize={{ xs: "0.8rem", sm: "0.9rem" }}
                        data-testid="accountNumber">
                        {hyphenSeperatedAccNum}
                      </Typography>
                    </ColumnCenterStack>
                    <ColumnCenterStack spacing={2} direction={"row"}>
                      <Typography
                        component={"span"}
                        fontWeight={600}
                        fontSize={{ xs: "0.8rem", sm: "0.9rem" }}>
                        Billing date
                      </Typography>
                      {billingDate && (
                        <Typography
                          fontSize={{ xs: "0.8rem", sm: "0.9rem" }}
                          component={"span"}
                          dangerouslySetInnerHTML={{ __html: dateWithSuffix }}
                          data-testid="billingDate"
                        />
                      )}
                    </ColumnCenterStack>
                  </Stack>
                </Box>
                <ColumnCenterStack direction={"row"}>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={1}
                    fontWeight={600}
                    flexWrap={"wrap"}>
                    <Typography
                      fontSize={{ xs: "0.8rem", sm: "1rem" }}
                      component={"span"}
                      fontWeight={600}>
                      Current bill for
                    </Typography>
                    <Typography
                      variant="body2"
                      component={"span"}
                      fontSize={{ xs: "0.8rem", sm: "1rem" }}
                      fontWeight={600}
                      data-testid="fromDate"
                      dangerouslySetInnerHTML={{ __html: dateWithSuffix2 }}
                    />
                    <Box>-</Box>
                    <Typography
                      data-testid="toDate"
                      fontWeight={600}
                      fontSize={{ xs: "0.8rem", sm: "1rem" }}
                      variant="body2"
                      component={"span"}
                      dangerouslySetInnerHTML={{ __html: dateWithSuffix3 }}
                    />
                  </Stack>
                  <StyledElement fontSize={{ xs: "12px", sm: "14px" }}>
                    {currentBillData?.currentBillDetails?.url != null &&
                      currentBillData?.currentBillDetails?.url !== "" && (
                        <StyledA target="_blank" href={url} data-testid="pdf">
                          <Button onClick={() => {}} title="VIEW PDF" type="pdf" />
                        </StyledA>
                      )}
                  </StyledElement>
                </ColumnCenterStack>
                <CurrentBillTable currentBillData={currentBillData} />
              </InformationBox>
            </>
          )}
        </>
      )}
    </>
  );
}

export default CurrentBillInfo;
