import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { StyledDialog } from "../../../../Styles/GlobalStyles";
import { DialogContent, Stack, Typography, IconButton } from "@mui/material";

const SuccessFailureDialog = ({ open, onClose, message, heading }: any) => {
  return (
    <>
      <StyledDialog open={open} onClose={onClose} aria-describedby="modal-modal-description">
        <IconButton
          data-testid="close-button"
          sx={{ alignSelf: "flex-end", padding: 0 }}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Stack justifyContent={"center"} alignItems={"center"} mb={3}>
            <Typography
              id="modal-modal-title"
              fontWeight={600}
              component="h2"
              fontSize={{ xs: "14px", md: "18px" }}
              textAlign={"center"}>
              {heading}
            </Typography>
            {message}
          </Stack>
        </DialogContent>
      </StyledDialog>
    </>
  );
};

export default SuccessFailureDialog;
