import { Stack, Typography } from "@mui/material";
import {
  getFormattedDate,
  getModifiedAutopaymentDeductionDate,
  getModifiedDate,
  getModifiedDueDate,
} from "../../../../Services/helper";
import { useSelector } from "react-redux";
import { currentDate } from "../../../../Constants/constants";
import { useEffect, useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import { Theme } from "../../../../Styles/GlobalTheme";

export const RecurringBillDueDate = () => {
  const { currentDueBalance, billDueDate } = useSelector((state: any) => state.customerPlanDetails);
  const todaysDate = getFormattedDate(currentDate);
  const [data, setData] = useState<any>({
    color: "",
    status: "",
  });

  const formattedBillingDate = getModifiedAutopaymentDeductionDate(billDueDate);

  const { autoPayData, uuidErrorFlag } = useSelector(
    (state: any) => state?.autopayPaperlessDetails
  );

  const autopaymentDeductionDate = autoPayData?.autopaymentDeductionDate;
  const formattedDate = getModifiedDueDate(billDueDate);
  const formatedAutopaymentDeductionDate =
    getModifiedAutopaymentDeductionDate(autopaymentDeductionDate);
  const autopayEnabled = autoPayData && Object.keys(autoPayData)?.length > 0;

  const getStatus = () => {
    // Split date strings into components (day, month, year)

    const dateComponents1 = billDueDate?.split("-");
    const dateComponents2 = todaysDate?.split("-");

    // Convert date components to integers

    const day1 = parseInt(dateComponents1[0]);
    const month1 = parseInt(dateComponents1[1]) - 1;
    const year1 = parseInt(dateComponents1[2]);
    const day2 = parseInt(dateComponents2[0]);
    const month2 = parseInt(dateComponents2[1]) - 1;
    const year2 = parseInt(dateComponents2[2]);

    // Create Date objects

    const modifiedBillDate = new Date(year1, month1, day1);
    const modifiedCurrentDate = new Date(year2, month2, day2);

    if (modifiedBillDate > modifiedCurrentDate && !autopayEnabled && currentDueBalance > 0) {
      setData({ status: `Payment Due ${formattedDate}`, color: "warning" });
    } else if (
      modifiedBillDate > modifiedCurrentDate &&
      !autopayEnabled &&
      currentDueBalance <= 0
    ) {
      setData({
        status: `Next Bill is on ${formattedDate}`,
        color: Theme.palette.customcolor.navyBlue,
      });
    } else if (modifiedBillDate < modifiedCurrentDate && !autopayEnabled && currentDueBalance > 0) {
      setData({ ...data, status: `Over Due Since ${formattedDate}`, color: "error" });
    } else if (autopayEnabled) {
      if (autopaymentDeductionDate) {
        setData({
          ...data,
          status: `Autopay scheduled for ${formatedAutopaymentDeductionDate} (Bill Due Date is ${formattedBillingDate} )`,
          color: "success",
        });
      } else {
        setData({
          ...data,
          status: `Autopay scheduled for payment due on ${formattedDate}`,
          color: "success",
        });
      }
    } else {
      setData({ ...data, status: `Last billed on ${formattedDate}`, color: "grey" });
    }
  };

  const iconColor = data && data?.color;

  useEffect(() => {
    billDueDate && getStatus();
  }, [billDueDate, autopayEnabled]);

  return (
    <>
      <Stack direction={"row"} gap={0.5} py={uuidErrorFlag && 1} alignItems={"center"}>
        <>
          {(formattedDate || billDueDate) && (
            <>
              <CircleIcon sx={{ fontSize: "16px", color: iconColor }} color={iconColor} />
              <Typography variant="body2" data-test-id="recurring-status">
                {data && data?.status}
              </Typography>
            </>
          )}
        </>
      </Stack>
    </>
  );
};
