import React, { useState, useEffect, useLayoutEffect } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  DialogTitle,
  Typography,
  Divider,
} from "@mui/material";
import {
  CenterStack,
  CustomTextField,
  GridContainer,
  SpacerSmall,
  StyledElement,
  StyledDialog,
  StyledDialogContent,
  ResetPasswordInput,
  ErrorMessage,
  RegisterFormHeading,
  SpanTypography,
  PTypography,
  SignInStack,
  StyledA,
} from "../../../Styles/GlobalStyles";
import { Theme } from "../../../Styles/GlobalTheme";
import { SignUpFooter } from "../../../components/shared/Signup/SignupFooter";
import { useLazyQuery, useMutation } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import CheckMarkGreen from "../../../assets/icons/Checkmark green.svg";
import {
  CONNECT,
  CREATE,
  EMAIL_SECURITY_CODE_SUCCESS_MESSAGE,
  COUNTDOWN_FOR_SEND_AND_RESEND_SMS,
  EXPIRED,
  NOT_MATCH,
  OTP_DOES_NOT_MATCH,
  OTP_EXPIRED,
  OTP_INVALID,
  SEND_OTP_FAILED_MESSAGE,
  TERMS_CONDITIONS_URL,
  VERIFY,
  EMAIL_SECURITY_CODE_SUCCESS_SUBTEXTMESSAGE,
  accountNumberRegex,
  INVALID_PHONE_ERROR_MESSAGE,
  LINK_ACCOUNT_CBST_ACTION,
} from "../../../Constants/constants";
import { StepperComponent } from "../StepperComponent";
import { CREATE_CUSTOMER_CONTACT, GET_PUBLIC_KEY } from "../query";
import { StepperProps, label } from "../SignIn";
import { LoadingSpinner } from "../../../components/shared/LoadingSpinner/LoadingSpinner";
import { RESEND_OTP_EMAIL, SEND_OTP_BY_EMAIL, VERIFY_OTP_EMAIL } from "../../Login/query";
import {
  RSAOAEPPadding,
  formatAccountNumber,
  formatPhoneNumber,
  getFlags,
  logEvents,
  validInputSubmit,
} from "../../../Services/helper";
import { LinkedAccountCard } from "../../../components/shared/LinkedAccountCard/LinkedAccountCard";
import { useLocation } from "react-router-dom";
import { LINK_ACCOUNT } from "../../Account/query";
import { UpdateErrorComponent } from "../../../components/shared/UpdateErrorComponent/UpdateErrorComponent";
import Button from "../../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { setEncryptedPayload, setSignInDetails } from "../../../Redux/Slices/SignInSlice";
import { CountdownTimer } from "../../../components/shared/CountdownTimer/CountdownTimer";
import PasswordCriteria from "../../../components/PasswordCriteria/PasswordCriteria";
import { usePasswordCriteria } from "../../../hooks/Password/usePasswordCriteria";
import { usePhoneNumberValidation } from "../../../hooks/Validation/usePhoneNumberValidation";
import { setApiFailureName, setCbstAction, setError } from "../../../Redux/Slices/PaymentSlice";
import ReportIssue from "../../../components/shared/ReportIssue/ReportIssue";
import { updateEmailContact } from "../../../Redux/Slices/CustomerInfoSlice";

export const CreateAccountForm = ({
  type,
  nextStep,
  prevStep,
  setIdCreated,
  activeStep,
  setActiveStep,
  utTest = false,
}: StepperProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [validateEmail, setValidateEmail] = useState<boolean>(false);
  const [timer, setTimer] = useState<boolean>(false);
  const [otpSent, setOtpSent] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [checkboxes, setCheckboxes] = useState({
    primaryConsent: true,
    checkbox1: false,
    checkbox2: false,
  });
  const [openError, setOpenError] = useState(false);
  const [contactId, setContactId] = useState("");
  const { email, encryptedPayload } = useSelector((state: any) => state.signInDetails);
  const { validPhoneNumber } = useSelector((state: any) => state.billingAddress);

  const emailWithoutContactId = location.state?.data;

  const { passwordCriteria, validatePasswordWithCriteria } = usePasswordCriteria();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: emailWithoutContactId ? emailWithoutContactId : "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
  });

  const [formData2, setFormData2] = useState<any>({
    billingAccountNumber: "",
    pin: "",
  });
  const [formErrors2, setFormErrors2] = useState<any>({
    billingAccountNumber: "",
    pin: "",
  });

  const [formData3, setFormData3] = useState<any>({
    otpEmail: "",
  });
  const [formErrors3, setFormErrors3] = useState<any>({
    otpEmail: "",
  });

  const [formErrors, setFormErrors] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    validatePhoneNumberError: "",
  });
  const envFlags = useSelector((state: any) => state?.userInfo?.envFlags);
  const SHOW_REPORT_ISSUE = getFlags("report_service_issue", envFlags);
  const [openReportIssue, setOpenReportIssue] = useState(false);
  const handleReportIssueError = () => {
    SHOW_REPORT_ISSUE ? setOpenReportIssue(true) : setOpenError(true);
  };

  const [getPublicKey, { loading: publickeyLoading, data: publicKey }] =
    useLazyQuery(GET_PUBLIC_KEY);

  const [createAContact, { loading: contactLoading }] = useMutation(CREATE_CUSTOMER_CONTACT, {
    onError: () => setOpenError(true),
  });
  const [linkAccount, { loading: linkAccountLoading }] = useMutation(LINK_ACCOUNT, {
    onError: (error: any) => {
      handleReportIssueError();
      dispatch(setError(error?.networkError?.result?.errors?.[0]));
      dispatch(setApiFailureName("linkAccount"));
      dispatch(setCbstAction(LINK_ACCOUNT_CBST_ACTION));
    },
    onCompleted: () => setIdCreated(true),
  });

  const [sendOTPByEmail, { loading: sendOTPByEmailLoading }] = useMutation(SEND_OTP_BY_EMAIL, {
    onError: (error: any) => {
      if (error.networkError.result.errors[0]?.code === "422") {
        setFormErrors({ ...formErrors, email: error.networkError.result.errors[0]?.message });
      } else {
        setOpenError(true);
        setMessage(SEND_OTP_FAILED_MESSAGE);
      }
    },
    onCompleted: () => {
      nextStep();
    },
  });

  const [resendOTPByEmail, { loading: resendOTPByEmailLoading }] = useMutation(RESEND_OTP_EMAIL, {
    onError: () => {
      setOpenError(true);
      setMessage(SEND_OTP_FAILED_MESSAGE);
    },
  });
  const [verifyOTPByEmail, { loading: verifyOTPByEmailLoading }] = useMutation(VERIFY_OTP_EMAIL, {
    onError: (error: any) => {
      if (error.networkError.result.errors[0]?.message?.toLowerCase()?.includes(NOT_MATCH)) {
        setFormErrors3({ ...formErrors3, otpEmail: OTP_DOES_NOT_MATCH });
      } else if (error.networkError.result.errors[0]?.message?.toLowerCase()?.includes(EXPIRED)) {
        setFormErrors3({ ...formErrors3, otpEmail: OTP_EXPIRED });
      } else {
        setFormErrors3({ ...formErrors3, otpEmail: OTP_INVALID });
      }
    },
  });

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickNewShowPassword = () => {
    setShowNewPassword((show) => !show);
  };

  const handleClickConfirmShowPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const handleCheckBox = (event: any) => {
    const { name, checked } = event.target;
    setCheckboxes((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleFirstName = (e: any) => {
    setFormData({ ...formData, firstName: e.target.value });
    setFormErrors({ ...formErrors, firstName: "" });
  };

  const handleLastName = (e: any) => {
    setFormData({ ...formData, lastName: e.target.value });
    setFormErrors({ ...formErrors, lastName: "" });
  };

  const handleEmail = (e: any) => {
    setFormData({ ...formData, email: e.target.value });
    setFormErrors({ ...formErrors, email: "" });
  };

  const handlePassword = (e: any) => {
    validatePasswordWithCriteria(e.target.value);

    setFormData({ ...formData, password: e.target.value });
    setFormErrors({ ...formErrors, password: "" });
  };

  const handleConfirmPassword = (e: any) => {
    setFormData({ ...formData, confirmPassword: e.target.value });
    setFormErrors({ ...formErrors, confirmPassword: "" });
  };

  const handlePhoneNumber = (e: any) => {
    setFormData({ ...formData, phoneNumber: formatPhoneNumber(e.target.value.replace(/\D/g, "")) });
    setFormErrors({ ...formErrors, phoneNumber: "" });
  };

  const handleAccountInput = (e: any) => {
    let accountNumber = e.target.value.replace(/[^0-9-]/g, "");

    const isValid = accountNumberRegex.test(accountNumber);

    if (!isValid) {
      accountNumber = formatAccountNumber(accountNumber);
    }

    const hasAlphabets = /[a-z]/i.test(accountNumber);

    if (!hasAlphabets) {
      setFormData2({ ...formData2, billingAccountNumber: accountNumber });
    }
  };

  const handlePinInput = (e: any) => {
    setFormData2({ ...formData2, pin: e.target.value.replace(/\D/g, "") });
    setFormErrors2({ ...formErrors2, pin: "" });
  };

  const handleOtpPhone = (e: any) => {
    setFormData3({ ...formData3, otpEmail: e.target.value.replace(/\D/g, "") });
    setFormErrors3({});
  };
  const handleOtpSentTimer = () => {
    setTimeout(() => {
      setOtpSent("");
    }, 5000);
  };

  const handleSendOTPEmail = async () => {
    let errors = validInputSubmit(formData);
    const hasPasswordCriteriaMet =
      passwordCriteria?.hasEightOrMoreChars &&
      passwordCriteria?.hasAtleastOneUpperAndLowerCase &&
      passwordCriteria?.hasAtleastOneNumber;

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    }
    const IsEmpty = Object.values(errors).every((value) => value === "");
    if (IsEmpty && hasPasswordCriteriaMet) {
      try {
        const sendResponse = await sendOTPByEmail({
          variables: {
            firstName: formData?.firstName?.trim(),
            lastName: formData?.lastName?.trim(),
            email: formData?.email,
          },
        });
        if (sendResponse?.data?.sendOTPbyEmail?.error) {
          setOpenError(true);
          setMessage(SEND_OTP_FAILED_MESSAGE);
          setTimer(false);
        } else if (sendResponse?.data) {
          setActiveStep(1);
          if (!sendResponse?.data?.sendOTPbyEmail?.error) {
            setOtpSent(EMAIL_SECURITY_CODE_SUCCESS_MESSAGE);
            setTimer(true);
            handleOtpSentTimer();
            dispatch(setSignInDetails(formData));
            setTimeout(() => {
              setTimer(false);
            }, COUNTDOWN_FOR_SEND_AND_RESEND_SMS);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleResendByEmail = async () => {
    try {
      const resendEmailResponse = await resendOTPByEmail({
        variables: {
          firstName: formData?.firstName?.trim(),
          lastName: formData?.lastName?.trim(),
          email: formData?.email,
        },
      });
      if (resendEmailResponse?.data?.resendOTPByEmail?.error) {
        setOpenError(true);
        setMessage(SEND_OTP_FAILED_MESSAGE);
        setFormData3({ otpEmail: "" });
      } else if (resendEmailResponse?.data) {
        setTimer(true);
        setOtpSent(EMAIL_SECURITY_CODE_SUCCESS_MESSAGE);
        handleOtpSentTimer();
        setFormData3({ otpEmail: "" });
        setTimeout(() => {
          setTimer(false);
        }, COUNTDOWN_FOR_SEND_AND_RESEND_SMS);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkValidation3 = () => {
    const errors = validInputSubmit(formData3);
    if (Object.keys(errors?.otpEmail).length > 0) {
      setFormErrors3(errors);
    }
  };

  const handleVerifyEmail = async () => {
    checkValidation3();
    const isNotEmpty = Object.values(formErrors3).every((value: any) => value !== "");
    if (isNotEmpty) {
      try {
        const verifyEmailResponse = await verifyOTPByEmail({
          variables: {
            otp: formData3?.otpEmail,
            email: formData?.email,
            updatingContact: false,
          },
        });
        if (verifyEmailResponse?.data) {
          if (!verifyEmailResponse?.data?.verifyOTPByEmail?.error) {
            const encryptedPassword = RSAOAEPPadding(
              formData?.confirmPassword,
              publicKey?.getPublicKey?.publicKey
            );
            try {
              const response = await createAContact({
                variables: {
                  encryptedPayload: decodeURIComponent(encryptedPayload),
                  customerContactInput: {
                    givenAndFamilyName: {
                      givenName: formData?.firstName?.trim(),
                      familyName: formData?.lastName?.trim(),
                    },
                    telephoneContact: {
                      isPrimaryTelephoneContact: true,
                      telephoneNumber: formData?.phoneNumber?.replace(/[() -]/g, ""),
                    },
                    emailContact: { isPrimaryEmailContact: true, newEmailAddress: formData?.email },
                    password: utTest ? formData?.confirmPassword : encryptedPassword,
                    username: "myAccount",
                    uuid: verifyEmailResponse?.data?.verifyOTPByEmail?.uuid,
                  },
                },
              });
              if (response?.data?.createCustomerContact?.error) {
                setOpenError(true);
              } else if (response?.data) {
                logEvents("PROFILE_CREATED");
                setContactId(response?.data?.createCustomerContact?.id);
                if (encryptedPayload?.length > 0) {
                  setIdCreated(true);
                } else {
                  setActiveStep(2);
                }
              }
            } catch (error) {
              console.log(error);
            }
            logEvents("CREATE_ACCOUNT_VERIFIED");
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const checkValidation2 = (name: any) => {
    const errors = validInputSubmit(formData2);
    if (Object.keys(errors).length > 0) {
      setFormErrors2({ ...formErrors2, [name]: errors[name] });
    }
  };

  const handleLinkAccount = async () => {
    let errors = validInputSubmit(formData2);
    if (Object.keys(errors).length > 0) {
      setFormErrors2(errors);
    }
    const IsEmpty = Object.values(errors).every((value) => value === "");
    if (IsEmpty) {
      try {
        const response = await linkAccount({
          variables: {
            customerLinkAccountInput: {
              billingAccountNumber: formData2?.billingAccountNumber.split("-").join(""),
              pin: formData2?.pin,
              username: "myAccount",
            },
            contactId: contactId,
          },
        });
        if (response?.data?.linkAccount?.APIStatus === 412) {
          setOpen(true);
        } else if (response?.data?.linkAccount?.error) {
          handleReportIssueError();
        } else if (response?.data) {
          logEvents("CREATE_ACCOUNT_LINKED");
          setIdCreated(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleCreateContact = async () => {
    let errors = validInputSubmit(formData);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    }
    const IsEmpty = Object.values(errors).every((value) => value === "");
    if (IsEmpty) {
      const encryptedPassword = RSAOAEPPadding(
        formData?.confirmPassword,
        publicKey?.getPublicKey?.publicKey
      );
      try {
        const response = await createAContact({
          variables: {
            encryptedPayload: decodeURIComponent(encryptedPayload),
            customerContactInput: {
              givenAndFamilyName: {
                givenName: formData?.firstName?.trim(),
                familyName: formData?.lastName?.trim(),
              },
              telephoneContact: {
                isPrimaryTelephoneContact: true,
                telephoneNumber: formData?.phoneNumber?.replace(/[() -]/g, ""),
              },
              emailContact: { isPrimaryEmailContact: true, newEmailAddress: formData?.email },
              password: utTest ? formData?.confirmPassword : encryptedPassword,
              username: "myAccount",
            },
          },
        });
        if (response?.data?.createCustomerContact?.error) {
          setOpenError(true);
        } else if (response?.data) {
          if (!response?.data?.createCustomerContact?.error) {
            logEvents("PROFILE_CREATED");
            setContactId(response?.data?.createCustomerContact?.id);
            setIdCreated(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const { phoneNumberValidation } = usePhoneNumberValidation(
    formData?.phoneNumber,
    setFormErrors,
    formErrors
  );

  const validatePhoneNumber = async () => {
    try {
      const response = await phoneNumberValidation();
      if (response?.data) {
        if (response?.data?.phoneNumberValidation?.error) {
          setFormErrors({
            validatePhoneNumberError: response?.data?.phoneNumberValidation?.error,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleNext = () => {
    encryptedPayload?.length > 0 ? handleCreateContact() : handleSendOTPEmail();
  };

  const handleClose = () => {
    setOpenDialog(false);
    setValidateEmail(false);
  };

  const handleValidate = () => {
    setValidateEmail(true);
    setOpenDialog(false);
  };
  const handleStepBack = () => {
    prevStep();
    setFormData2({});
    setFormData3({});
    setFormErrors2({});
    setFormErrors3({});
  };

  const handleValidation = (name: any) => {
    const errors = validInputSubmit(formData);
    if (Object.keys(errors).length > 0) {
      setFormErrors({ ...formErrors, [name]: errors[name] });
    }
  };

  const handlePhoneNumberBlur = () => {
    if (formData.phoneNumber != "") {
      validatePhoneNumber();
    }
    handleValidation("phoneNumber");
  };
  const loader =
    publickeyLoading ||
    contactLoading ||
    linkAccountLoading ||
    sendOTPByEmailLoading ||
    resendOTPByEmailLoading ||
    verifyOTPByEmailLoading;
  useEffect(() => {
    getPublicKey();
    if (query.get("registerToken") !== null) {
      dispatch(setEncryptedPayload(query.get("registerToken") || ""));
    }
  }, []);

  return (
    <>
      {type === CREATE ? (
        <SignInStack mt={2}>
          <RegisterFormHeading>Create your Ziply ID</RegisterFormHeading>
          <PTypography>This is used to log into your account</PTypography>
          <Box mt={"38px"} width={"100%"}>
            <StepperComponent activeStep={activeStep} stepperColor="success" />
          </Box>
          <GridContainer
            container
            mt={"20px"}
            direction={{ xs: "column", md: "row" }}
            width={{ md: "90%", xs: "92%" }}>
            <Grid item md={5.6} xs={11}>
              <SpanTypography>First name*</SpanTypography>
              <CustomTextField
                inputProps={{ "data-testid": "firstname" }}
                required
                fullWidth
                onBlur={() => handleValidation("firstName")}
                name="firstName"
                value={formData?.firstName}
                hasError={formErrors?.firstName ? true : false}
                onChange={handleFirstName}
              />
              {formErrors?.firstName && (
                <ErrorMessage error={formErrors?.firstName ? true : false}>
                  {formErrors?.firstName}
                </ErrorMessage>
              )}
            </Grid>
            <Grid item md={5.6} xs={11}>
              <SpanTypography>Last name*</SpanTypography>
              <CustomTextField
                inputProps={{ "data-testid": "lastname-input" }}
                variant="outlined"
                required
                onBlur={() => handleValidation("lastName")}
                fullWidth
                name="lastName"
                value={formData?.lastName}
                hasError={formErrors?.lastName ? true : false}
                onChange={handleLastName}
              />
              {formErrors?.lastName && (
                <ErrorMessage error={formErrors?.lastName ? true : false}>
                  {formErrors?.lastName}
                </ErrorMessage>
              )}
            </Grid>
          </GridContainer>
          <GridContainer
            direction={{ xs: "column", md: "row" }}
            container
            width={{ md: "90%", xs: "92%" }}>
            <Grid item mt={{ xs: 4, md: 2 }} md={5.6} xs={11}>
              <SpanTypography>Email*</SpanTypography>
              <CustomTextField
                inputProps={{ "data-testid": "email-input" }}
                variant="outlined"
                required
                onBlur={() => handleValidation("email")}
                fullWidth
                name="email"
                hasError={formErrors?.email ? true : false}
                value={formData?.email}
                onChange={handleEmail}
              />
              {formErrors?.email && (
                <ErrorMessage error={formErrors?.email ? true : false}>
                  {formErrors?.email}
                </ErrorMessage>
              )}
            </Grid>
            <Grid item md={5.6} mt={{ xs: 0, md: 2 }} xs={11}>
              <SpanTypography>Mobile number*</SpanTypography>
              <CustomTextField
                inputProps={{ "data-testid": "phoneNumber-input" }}
                variant="outlined"
                required
                type="text"
                onBlur={handlePhoneNumberBlur}
                fullWidth
                name="phoneNumber"
                value={formData?.phoneNumber}
                hasError={
                  formErrors?.phoneNumber || formErrors?.validatePhoneNumberError ? true : false
                }
                onChange={handlePhoneNumber}
              />
              {formErrors?.phoneNumber && (
                <ErrorMessage error={formErrors?.phoneNumber ? true : false}>
                  {formErrors?.phoneNumber}
                </ErrorMessage>
              )}
            </Grid>
            {formErrors?.validatePhoneNumberError && formData?.phoneNumber && (
              <ErrorMessage error={formErrors?.validatePhoneNumberError ? true : false}>
                {formErrors?.validatePhoneNumberError}
              </ErrorMessage>
            )}
          </GridContainer>
          <Stack direction={"row"} ml={3} mt={2}>
            <Checkbox
              data-testid="primaryConsent"
              name="primaryConsent"
              {...label}
              disableRipple
              defaultChecked
              checked={checkboxes.primaryConsent}
              onChange={handleCheckBox}
            />
            <Typography fontSize={"0.8rem"} component={"p"}>
              I agree to receive communication updates from Ziply Fiber regarding status of my
              order, billing and service appointments.
            </Typography>
          </Stack>
          <Grid container width={{ md: "90%" }} display={{ md: "block", xs: "none" }}>
            <Grid mt={5} mb={3} item md={12}>
              <Divider />
            </Grid>
          </Grid>
          <GridContainer
            direction={{ xs: "column", md: "row" }}
            container
            width={{ md: "90%", xs: "92%" }}>
            <Grid item mt={{ xs: 4, md: 0 }} md={5.6} xs={11}>
              <SpanTypography>Create your password*</SpanTypography>
              <ResetPasswordInput
                inputProps={{ "data-testid": "password-input" }}
                value={formData?.password}
                onBlur={() => handleValidation("password")}
                name="password"
                onChange={handlePassword}
                fullWidth
                hasError={formErrors?.password ? true : false}
                id="outlined-adornment-password"
                type={showNewPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      disableRipple
                      aria-label="toggle password visibility"
                      onClick={handleClickNewShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {formErrors?.password && (
                <ErrorMessage
                  error={formErrors?.password ? true : false}
                  data-testid="passwordError">
                  {formErrors?.password}
                </ErrorMessage>
              )}
            </Grid>
            <Grid item md={5.6} xs={11}>
              <SpanTypography>Confirm password*</SpanTypography>
              <ResetPasswordInput
                inputProps={{ "data-testid": "confirmPassword-input" }}
                name="confirmPassword"
                onBlur={() => handleValidation("confirmPassword")}
                value={formData?.confirmPassword}
                onChange={handleConfirmPassword}
                fullWidth
                id="outlined-adornment-confirm-password"
                hasError={formErrors?.confirmPassword ? true : false}
                type={showConfirmPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      disableRipple
                      aria-label="toggle password visibility"
                      onClick={handleClickConfirmShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {formErrors?.confirmPassword && (
                <ErrorMessage
                  error={formErrors?.confirmPassword ? true : false}
                  data-testid="confirmError">
                  {formErrors?.confirmPassword}
                </ErrorMessage>
              )}
            </Grid>
            <PasswordCriteria passwordCriteria={passwordCriteria} />
            <Grid item md={12} xs={12}>
              <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
                <Checkbox
                  data-testid="checkbox1"
                  name="checkbox1"
                  {...label}
                  disableRipple
                  checked={checkboxes?.checkbox1}
                  onChange={handleCheckBox}
                />
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography variant="caption" component={"p"}>
                    I have read and agreed to the &nbsp;
                    <StyledA
                      style={{ fontWeight: 600 }}
                      href={TERMS_CONDITIONS_URL}
                      target="_blank">
                      TERMS AND CONDITIONS
                    </StyledA>
                  </Typography>
                </Stack>
              </Stack>
              <Stack my={{ xs: 6, sm: 2 }} alignItems={"center"} gap={"14px"}>
                <Button
                  eventName="CREATE_ACCOUNT"
                  title="Next"
                  disabled={!checkboxes?.checkbox1 || !checkboxes.primaryConsent}
                  type="next"
                  onClick={handleNext}
                />
                {openDialog && (
                  <StyledDialog maxWidth="lg" open={openDialog}>
                    <DialogTitle>
                      <Stack direction="row" justifyContent="flex-end">
                        <IconButton onClick={handleClose}>
                          <CloseIcon />
                        </IconButton>
                      </Stack>
                    </DialogTitle>
                    <StyledDialogContent>
                      <Stack gap={1} alignItems={"center"}>
                        <Button
                          title="VALIDATE EMAIL ADDRESS"
                          type="root"
                          onClick={handleValidate}
                        />
                        <Button title="NOT NOW,LATER" type="pay" onClick={nextStep} />
                      </Stack>
                    </StyledDialogContent>
                  </StyledDialog>
                )}
                {validateEmail && (
                  <StyledDialog maxWidth="lg" open={validateEmail}>
                    <DialogTitle>
                      <Stack direction="row" justifyContent="flex-end">
                        <IconButton onClick={handleClose}>
                          <CloseIcon />
                        </IconButton>
                      </Stack>
                    </DialogTitle>
                    <StyledDialogContent>
                      <Stack gap={1} alignItems={"center"}>
                        <img src={CheckMarkGreen} alt="sign-out" width="40" />
                        <Typography>Thank You!</Typography>
                        <Typography textAlign={"center"} variant="subtitle2">
                          We've sent a validation link to your email address
                        </Typography>
                        <Typography textAlign={"center"} variant="subtitle2">
                          if you are unable to find it,please check your spam/junk folder.{" "}
                        </Typography>
                        <Stack my={2} alignItems={"center"}>
                          <Typography variant="subtitle2">Didn't receive a email.</Typography>
                          <StyledElement>RESEND EMAIL</StyledElement>
                        </Stack>
                        <Button type={"root"} title={"Proceed"} onClick={nextStep} />
                      </Stack>
                    </StyledDialogContent>
                  </StyledDialog>
                )}
                <SignUpFooter />
              </Stack>
            </Grid>
          </GridContainer>
        </SignInStack>
      ) : type === CONNECT ? (
        <SignInStack my={2}>
          <RegisterFormHeading>Connect to existing account</RegisterFormHeading>
          <Typography
            fontSize={{ xs: "12px", sm: "14px" }}
            mb={2}
            textAlign={"center"}
            component={"span"}>
            You're almost done-just one more step
          </Typography>

          <Box my={"12px"} width={"100%"}>
            <StepperComponent activeStep={activeStep} stepperColor="success" />
          </Box>

          <GridContainer direction={"row"} mt={3} container width={{ md: "90%", xs: "90%" }}>
            <Grid item md={5.6} xs={12}>
              <Stack direction={"column"} spacing={1}>
                <Typography component={"p"} fontSize={{ xs: "0.8rem", sm: "0.9rem" }}>
                  Enter your billing account number found on your bill to link your account
                </Typography>
                <Typography fontSize={{ xs: "0.7rem", sm: "0.8rem" }} component={"span"}>
                  Billing account number
                </Typography>
                <CustomTextField
                  inputProps={{ "data-testid": "accountNumber-input" }}
                  variant="outlined"
                  onBlur={() => checkValidation2("billingAccountNumber")}
                  placeholder="###-###-####-######-#"
                  name="billingAccountNumber"
                  value={formData2?.billingAccountNumber}
                  hasError={formErrors2?.billingAccountNumber ? true : false}
                  onChange={handleAccountInput}
                  type="text"
                />
                {formErrors2?.billingAccountNumber && (
                  <ErrorMessage error={formErrors2?.billingAccountNumber ? true : false}>
                    {formErrors2?.billingAccountNumber}
                  </ErrorMessage>
                )}
              </Stack>
            </Grid>
            <Grid item mt={{ xs: 2, md: 0 }} md={5.6} xs={12}>
              <Stack direction={"column"} spacing={1}>
                <Typography fontSize={{ xs: "0.8rem", sm: "0.9rem" }} component={"span"}>
                  Enter your Ziply Fiber 4-digit PIN found on your account statement
                </Typography>
                <Typography fontSize={{ xs: "0.7rem", sm: "0.8rem" }} component={"span"}>
                  PIN
                </Typography>
                <CustomTextField
                  inputProps={{ "data-testid": "pin-input" }}
                  variant="outlined"
                  type="text"
                  onBlur={() => checkValidation2("pin")}
                  placeholder="####"
                  value={formData2?.pin}
                  name="pin"
                  hasError={formErrors2?.pin ? true : false}
                  onChange={handlePinInput}
                />
                {formErrors2?.pin && (
                  <ErrorMessage error={formErrors2?.pin ? true : false}>
                    {formErrors2?.pin}
                  </ErrorMessage>
                )}
              </Stack>
            </Grid>
          </GridContainer>
          <CenterStack gap={2} direction={"column"}>
            <Stack mt={8} direction={"row"} gap={1}>
              <SpacerSmall />
              <Button
                disabled={!formData2.billingAccountNumber && !formData2.pin}
                onClick={handleLinkAccount}
                eventName="CREATE_ACCOUNT_LINK"
                type="payment"
                title="Confirm"
              />
            </Stack>
            <SignUpFooter />
          </CenterStack>
        </SignInStack>
      ) : (
        type === VERIFY && (
          <SignInStack my={2}>
            <RegisterFormHeading>Verify your account</RegisterFormHeading>
            <Typography
              fontSize={{ xs: "16px", sm: "18px" }}
              component={"span"}
              textAlign={"center"}>
              Now we just need to verify your account.
            </Typography>

            <Box my={3} width={"100%"}>
              <StepperComponent activeStep={activeStep} stepperColor="success" />
            </Box>

            <GridContainer container width={{ md: "50%", xs: "90%" }}>
              <Grid item md={12} xs={12}>
                <Typography component={"p"} fontSize={{ xs: "10px", sm: "14px" }}>
                  Enter the security code we sent to&nbsp;
                  <Typography
                    component={"span"}
                    fontSize={{ xs: "10px", sm: "14px" }}
                    fontWeight={600}>
                    {email}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Stack direction={"column"} my={"16px"} spacing={1}>
                  <Typography fontSize={{ xs: "12px", sm: "16px" }} component={"p"}>
                    Enter the security code
                  </Typography>
                  <CustomTextField
                    inputProps={{ "data-testid": "security" }}
                    variant="outlined"
                    value={formData3?.otpEmail}
                    onBlur={checkValidation3}
                    name="security"
                    placeholder="6 digit"
                    hasError={formErrors3?.otpEmail ? true : false}
                    onChange={handleOtpPhone}
                  />
                  {formErrors3?.otpEmail && (
                    <ErrorMessage error={formErrors3?.otpEmail ? true : false}>
                      {formErrors3?.otpEmail}
                    </ErrorMessage>
                  )}
                  {timer ? (
                    <Typography
                      component={"p"}
                      alignSelf={"end"}
                      fontSize={{ xs: "10px", sm: "12px" }}
                      color={Theme.palette.customcolor.profileTypoGraphyColor}>
                      RESEND CODE IN &nbsp;
                      <CountdownTimer minutes={5} />
                    </Typography>
                  ) : (
                    <StyledElement
                      onClick={handleResendByEmail}
                      fontSize={{ xs: "10px", sm: "12px" }}
                      alignSelf={"end"}>
                      RESEND CODE
                    </StyledElement>
                  )}
                </Stack>
                {otpSent && (
                  <>
                    <Stack direction={"column"} justifyContent={"center"}>
                      <Typography
                        data-testid="otpSent"
                        textAlign={"center"}
                        variant="caption"
                        component={"span"}
                        color={Theme.palette.primary.main}>
                        {otpSent}
                      </Typography>
                      <Typography
                        data-testid="otpSent"
                        textAlign={"center"}
                        variant="caption"
                        component={"span"}
                        fontStyle={"italic"}
                        color={Theme.palette.primary.main}>
                        {EMAIL_SECURITY_CODE_SUCCESS_SUBTEXTMESSAGE}
                      </Typography>
                    </Stack>
                  </>
                )}
              </Grid>
            </GridContainer>

            <CenterStack direction={"column"}>
              <Stack my={3} direction={"row"} gap={1}>
                <Button onClick={handleStepBack} type="pay" title="Previous" />
                <SpacerSmall />
                <Button
                  eventName="CREATE_ACCOUNT_VERIFY"
                  onClick={handleVerifyEmail}
                  type="payment"
                  title="NEXT"
                />
              </Stack>
            </CenterStack>
          </SignInStack>
        )
      )}
      {open && <LinkedAccountCard open={open} setOpen={setOpen} formData={formData2} />}
      {loader && <LoadingSpinner />}

      <ReportIssue setOpenDialog={setOpenReportIssue} openDialog={openReportIssue} />

      <UpdateErrorComponent
        openError={openError}
        setOpenError={setOpenError}
        setErrorMessage={setMessage}
        message={validPhoneNumber ? INVALID_PHONE_ERROR_MESSAGE : message}
      />
    </>
  );
};
