import { gql } from "@apollo/client";

export const GET_CUSTOMER_PLAN_DETAILS = gql`
  query getCustomerPlanDetails(
    $accountNumber: String!
    $isNds: Boolean!
    $customerAddress: CustomerPlanDetailsInput!
    $accountUuid: String!
  ) {
    getCustomerPlanDetails(
      accountNumber: $accountNumber
      isNds: $isNds
      customerAddress: $customerAddress
      accountUuid: $accountUuid
    ) {
      itemCode
      planDescription
      monthlyCharge
      subsidyName
      sku
      LISno
      serviceId
      addons {
        addonItemCode
        price
        description
        LISno
        serviceId
        discounts
      }
      promotions {
        promotionSE
        promotionStartDate
        LISno
      }
      planSpeed
      uuid
      sessionId
      addressId
      APIStatus
      error
      message
      basePrice
    }
  }
`;

export const GET_AVAILABLE_PLANS_FOR_UPGRADE = gql`
  mutation getAvailablePlansForUpgrade(
    $addressId: String!
    $currentPlanSpeed: String!
    $itemCode: String!
    $subsidyName: String
    $uuid: String
    $usi: String!
    $sessionId: String
    $isNds: Boolean!
    $fromBanner: Boolean!
    $copperToFiber: Boolean!
  ) {
    getAvailablePlansForUpgrade(
      addressId: $addressId
      currentPlanSpeed: $currentPlanSpeed
      itemCode: $itemCode
      subsidyName: $subsidyName
      uuid: $uuid
      usi: $usi
      sessionId: $sessionId
      fromBanner: $fromBanner
      isNds: $isNds
      copperToFiber: $copperToFiber
    ) {
      highestPlanName
      currentONTMaxSpeed
      currentTechnology
      currentSku
      APIStatus
      error
      message
      availablePlans {
        name
        description
        marketingDescription
        productId
        downloadSpeed
        uploadSpeed
        se
        sku
        contentSku
        price
        fiberDrop
        selectedPlanMedia
        selectedPlanTechnology
        selectedPlanTerminalType
        discounts {
          discountCode
          duration
          unit
          price
          discountSequence
          gtvProduct
          discountBillDescription
        }
      }
    }
  }
`;

export const NUTRITION_LABEL_BY_PLAN_DETAILS = gql`
  mutation nutritionLabelByPlanDetails(
    $uuid: String!
    $planDetails: nutritionLabelDetailsByPlan!
    $sessionId: String!
  ) {
    nutritionLabelByPlanDetails(uuid: $uuid, planDetails: $planDetails, sessionId: $sessionId) {
      APIStatus
      message
      error
      name
      description
      accountNumber
      ziplyLogo
      headingLabel
      Sections {
        sectionHeading
        sectionValue
        sectionDiv
        sectionHeadingSubText
        addonSection
        govTaxLabel
        govTaxValue
        oneTimeFeeLabel
        optionalLabel
        rowSections {
          secLabel
          secValue
          secArray {
            name
            price
            description
          }
        }
      }
    }
  }
`;

export const GET_UPGRADE_PLAN_DETAILS = gql`
  query getUpgradedPlansDetails($planName: String!) {
    getUpgradedPlansDetails(planName: $planName) {
      sid
      sku
      productName
      description
      short_description
      offer_text
      legal_description
      legal_heading
      legal_sub_heading
      most_popular
      field_plan_order
      status
      price
      image
      APIStatus
      error
      message
    }
  }
`;

export const GET_ADDONS_FOR_FUTURE_PLAN = gql`
  mutation getAddonsForFuturePlan(
    $addonInputForFuturePlan: AddonInputForFuturePlan!
    $isNds: Boolean!
  ) {
    getAddonsForFuturePlan(addonInputForFuturePlan: $addonInputForFuturePlan, isNds: $isNds) {
      legacyAddons {
        name
        description
        sku
        SE
        price
        groupType
        ruleCode
        mandatory
        productId
      }
      newAddons {
        name
        description
        sku
        SE
        price
        groupType
        ruleCode
        mandatory
        productId
        actualPrice
        subsidy_price
        installType
        prerequisite_sku
        discounts
        split_productId
        isNDS
        related_sku
      }
      promotions {
        name
        description
        SE
        price
        ruleCode
        expired
        endDate
        startDate
        productId
      }
      oneTimeFee {
        name
        description
        sku
        SE
        price
        groupType
        ruleCode
        mandatory
        installType
        productId
        discounts
      }
      fiberRouterSKU
      APIStatus
      error
      message
    }
  }
`;

export const GET_PLAN_DETAILS_BY_SKU = gql`
  mutation getPlansDetailsContentByContentSku($contentSkuCode: String!) {
    getPlansDetailsContentByContentSku(contentSkuCode: $contentSkuCode) {
      sid
      sku
      productName
      description
      short_description
      offer_text
      legal_description
      legal_heading
      legal_sub_heading
      most_popular
      field_plan_order
      status
      price
      image
      APIStatus
      error
      message
    }
  }
`;

export const GET_ADDON_DETAILS_BY_SKU = gql`
  mutation getAddonDetailsContentBySkuIds($skuIds: [String!]!, $fiberRouterPrice: String) {
    getAddonDetailsContentBySkuIds(skuIds: $skuIds, fiberRouterPrice: $fiberRouterPrice) {
      addonsContent {
        sid
        sku
        sub_title
        description
        promo_label
        popup_description
        friendly_name
        status
        installation {
          installation_title
          installation_desc
          installation_type
        }
      }
      APIStatus
      error
      message
    }
  }
`;
export const GET_CUSTOMER_SERVICES_API = gql`
  query customerSubscribedServices($accountNumber: String!, $isNds: Boolean!) {
    customerSubscribedServices(accountNumber: $accountNumber, isNds: $isNds) {
      internet
      phone
      APIStatus
      error
      message
    }
  }
`;

export const SUBMIT_ORDER_UPGRADE = gql`
  mutation submitUpgradeOrder(
    $sessionId: String
    $uuid: String
    $subsidyName: String
    $isNds: Boolean!
    $submitOrderInput: SubmitOrderInput!
    $accountNumber: String!
  ) {
    submitUpgradeOrder(
      accountNumber: $accountNumber
      sessionId: $sessionId
      uuid: $uuid
      isNds: $isNds
      subsidyName: $subsidyName
      submitOrderInput: $submitOrderInput
    ) {
      description
      CXPOrderId
      dpiOrderId
      orderStatus
      appointmentsError
      allocatedPhoneNumber
      updateVoiceInfoResult
      orderHash
      appointments {
        startDate
        endDate
        resourcePoolId
        timeSlotType
        workUnitsQuantity
      }
      APIStatus
      error
      message
    }
  }
`;

export const SUBMIT_ORDER_LEGACY = gql`
  mutation submitUpgradeOrder(
    $sessionId: String
    $uuid: String
    $isNds: Boolean!
    $submitOrderInput: SubmitOrderInput!
    $accountNumber: String!
  ) {
    submitUpgradeOrder(
      accountNumber: $accountNumber
      isNds: $isNds
      uuid: $uuid
      sessionId: $sessionId
      submitOrderInput: $submitOrderInput
    ) {
      description
      CXPOrderId
      dpiOrderId
      orderStatus
      appointmentsError
      allocatedPhoneNumber
      updateVoiceInfoResult
      orderHash
      appointments {
        startDate
        endDate
        resourcePoolId
        timeSlotType
        workUnitsQuantity
      }
      APIStatus
      error
      message
    }
  }
`;

export const FALLOUT_ORDER = gql`
  mutation fallOutOrder($fallOutOrderInput: FallOutOrderInput!, $accountNumber: String!) {
    fallOutOrder(accountNumber: $accountNumber, fallOutOrderInput: $fallOutOrderInput) {
      APIStatus
      error
      message
    }
  }
`;

export const COMMIT_ORDER = gql`
  mutation commitOrderUpgrade(
    $sessionId: String
    $uuid: String
    $isNds: Boolean!
    $commitOrderInput: CommitOrderInput!
    $accountNumber: String!
  ) {
    commitOrderUpgrade(
      accountNumber: $accountNumber
      isNds: $isNds
      uuid: $uuid
      sessionId: $sessionId
      commitOrderInput: $commitOrderInput
    ) {
      CXPOrderId
      dpiOrderId
      orderStatus
      orderHash
      orderId
      APIStatus
      error
      message
    }
  }
`;

export const GET_PRIMARY_EMAIL = gql`
  query getPrimaryEmailByAccountUuid(
    $accountUuid: String!
    $email: String!
    $accountNumber: String!
  ) {
    getPrimaryEmailByAccountUuid(
      accountUuid: $accountUuid
      email: $email
      accountNumber: $accountNumber
    ) {
      primaryEmail
      customerSince
      contactId
      error
      message
      APIStatus
    }
  }
`;
