import { createSlice } from "@reduxjs/toolkit";

const editAutoPaySlice = createSlice({
  name: "editautopay",
  initialState: {
    isEditAutoPayEnable: false,
    isSaveAutoPayChanges: false,
  },
  reducers: {
    editAutoPayEnable: (state, action) => {
      state.isEditAutoPayEnable = action?.payload;
    },
    saveAutoPayChanges: (state, action) => {
      state.isSaveAutoPayChanges = action.payload;
    },
  },
});

export const { editAutoPayEnable, saveAutoPayChanges } = editAutoPaySlice?.actions;

export const editAutoPayReducer = editAutoPaySlice?.reducer;
