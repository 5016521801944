import React, { useEffect } from "react";
import { Theme } from "../../../../Styles/GlobalTheme";
import { Box, FormControl, Grid, Typography } from "@mui/material";
import { CustomTextField, ErrorMessage, ProfileTypography } from "../../../../Styles/GlobalStyles";
import { NUMERIC_ONLY_REGEX } from "../../../../Constants/constants";

const DirectoryListingInfo = ({ listingInfo, setListingInfo, errors, setErrors }: any) => {
  const handleChangeFirstName = (e: any) => {
    setListingInfo({ ...listingInfo, firstName: e.target.value });
    setErrors({ ...errors, firstName: "" });
  };

  const handleChangeLastName = (e: any) => {
    setListingInfo({ ...listingInfo, lastName: e.target.value });
    setErrors({ ...errors, lastName: "" });
  };

  const handleChangeAddress = (e: any) => {
    setListingInfo({ ...listingInfo, address: e.target.value });
    setErrors({ ...errors, address: "" });
  };

  const handleChangeVoicemail = (e: any) => {
    const value = e.target.value;
    if (NUMERIC_ONLY_REGEX.test(value)) {
      setListingInfo({ ...listingInfo, voicemail: value });
      setErrors({ ...errors, voicemail: "" });
    }
  };

  const handleChangeCity = (e: any) => {
    setListingInfo({ ...listingInfo, city: e.target.value });
    setErrors({ ...errors, city: "" });
  };

  const handleChangeState = (e: any) => {
    setListingInfo({ ...listingInfo, state: e.target.value });
    setErrors({ ...errors, state: "" });
  };

  const handleChangeZip = (e: any) => {
    const value = e.target.value;
    if (NUMERIC_ONLY_REGEX.test(value)) {
      setListingInfo({ ...listingInfo, zipcode: value });
      setErrors({ ...errors, zipcode: "" });
    }
  };

  return (
    <>
      <Typography fontSize={{ xs: "16px", md: "18px" }} color={Theme.palette.primary.main}>
        Directory Listing Information
      </Typography>
      <Grid container justifyContent={"space-between"} my={2} gap={2}>
        <Grid item xs={12} sm={5.5}>
          <FormControl fullWidth>
            <Box display="flex">
              <ProfileTypography fontSize={{ xs: "14px" }}>First Name</ProfileTypography>
              <Typography component="span" color="error" ml={0.5}>
                *
              </Typography>
            </Box>
            <CustomTextField
              value={listingInfo.firstName}
              onChange={(e: any) => handleChangeFirstName(e)}
              error={errors?.firstName}
            />
            {errors?.firstName && <ErrorMessage error={true}>{errors?.firstName}</ErrorMessage>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5.5}>
          <FormControl fullWidth>
            <Box display="flex">
              <ProfileTypography fontSize={{ xs: "14px" }}>Last Name</ProfileTypography>
              <Typography component="span" color="error" ml={0.5}>
                *
              </Typography>
            </Box>
            <CustomTextField
              value={listingInfo.lastName}
              onChange={(e: any) => handleChangeLastName(e)}
              error={errors?.lastName}
            />
            {errors?.lastName && <ErrorMessage error={true}>{errors?.lastName}</ErrorMessage>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5.5}>
          <FormControl fullWidth>
            <Box display="flex">
              <ProfileTypography fontSize={{ xs: "14px" }}>Street Address</ProfileTypography>
              <Typography component="span" color="error" ml={0.5}>
                *
              </Typography>
            </Box>
            <CustomTextField
              value={listingInfo.address}
              onChange={(e: any) => handleChangeAddress(e)}
              error={errors?.address}
            />
            {errors?.address && <ErrorMessage error={true}>{errors?.address}</ErrorMessage>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5.5}>
          <FormControl fullWidth>
            <Box display="flex">
              <ProfileTypography fontSize={{ xs: "14px" }}>Voicemail</ProfileTypography>
              <Typography component="span" color="error" ml={0.5}>
                *
              </Typography>
            </Box>
            <CustomTextField
              value={listingInfo.voicemail}
              onChange={(e: any) => handleChangeVoicemail(e)}
              error={errors?.voicemail}
            />
            {errors?.voicemail && <ErrorMessage error={true}>{errors?.voicemail}</ErrorMessage>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5.5}>
          <FormControl fullWidth>
            <Box display="flex">
              <ProfileTypography fontSize={{ xs: "14px" }}>City</ProfileTypography>
              <Typography component="span" color="error" ml={0.5}>
                *
              </Typography>
            </Box>
            <CustomTextField
              value={listingInfo.city}
              onChange={(e: any) => handleChangeCity(e)}
              error={errors?.city}
            />
            {errors?.city && <ErrorMessage error={true}>{errors?.city}</ErrorMessage>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5.5}>
          <FormControl fullWidth>
            <Box display="flex">
              <ProfileTypography fontSize={{ xs: "14px" }}>State</ProfileTypography>
              <Typography component="span" color="error" ml={0.5}>
                *
              </Typography>
            </Box>
            <CustomTextField
              value={listingInfo.state}
              onChange={(e: any) => handleChangeState(e)}
              error={errors?.state}
            />
            {errors?.state && <ErrorMessage error={true}>{errors?.state}</ErrorMessage>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5.5}>
          <FormControl fullWidth>
            <Box display="flex">
              <ProfileTypography fontSize={{ xs: "14px" }}>Zip</ProfileTypography>
              <Typography component="span" color="error" ml={0.5}>
                *
              </Typography>
            </Box>
            <CustomTextField
              value={listingInfo.zipcode}
              onChange={(e: any) => handleChangeZip(e)}
              error={errors?.zipcode}
            />
            {errors?.zipcode && <ErrorMessage error={true}>{errors?.zipcode}</ErrorMessage>}
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default DirectoryListingInfo;
