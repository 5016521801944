import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { splitMessageBybraces } from "../../../../Services/helper";
import Button from "../../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { CardHeading, CenteredContainer } from "../../../../Styles/GlobalStyles";
import { Theme } from "../../../../Styles/GlobalTheme";

function AutoPayAndPaperlessError({ Messages }: any) {
  const subMessage = splitMessageBybraces(Messages?.subHeading);
  const navigate = useNavigate();
  const handleContinue = () => {
    navigate("/account");
  };
  return (
    <CenteredContainer>
      <CardHeading>{Messages?.Heading}</CardHeading>

      <Typography
        mt={2}
        fontSize={{ sm: "0.9rem", md: "1.1rem" }}
        color={Theme.palette.customcolor.cardHeading}>
        {subMessage[0]} <br />
        {subMessage[1]}
      </Typography>

      <Box pt={8}>
        <Button type="root" title="Continue" onClick={handleContinue} />
      </Box>
    </CenteredContainer>
  );
}

export default AutoPayAndPaperlessError;
