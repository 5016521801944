import { useMutation } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import { BREAK_LEASE } from "../../Pages/Home/Components/NetworkStatus/query";
import { setBreakLeaseApiError, setBreakLeaseSuccess } from "../../Redux/Slices/NetworkStatusSlice";

export const useBreakLease = () => {
  const { accountNumber } = useSelector((state: any) => state.customerInfoDetails);
  const dispatch = useDispatch();

  const [breakLease, { loading: breakLeaseLoading }] = useMutation(BREAK_LEASE, {
    onError: () => dispatch(setBreakLeaseApiError(true)),
    onCompleted: () => dispatch(setBreakLeaseSuccess(true)),
  });

  const handleBreakLease = async () => {
    try {
      const response = await breakLease({
        variables: {
          accountNumber: accountNumber,
        },
      });
      if (response?.data) {
        if (!response?.data?.breakLease?.error) {
          dispatch(setBreakLeaseSuccess(true));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return {
    handleBreakLease,
    breakLeaseLoading,
  };
};
