import { useState, useEffect, useLayoutEffect } from "react";
import { Box, Grid, Stack, Divider, Typography, styled } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { Theme } from "../../../../Styles/GlobalTheme";
import { NUTRITION_LABEL_API } from "../../query";
import { LoadingSpinner } from "../../../../components/shared/LoadingSpinner/LoadingSpinner";
import { ErrorComponent } from "../../../../components/ErrorComponent/ErrorComponent";
import { NeedHelpCard } from "../../../../components/shared/NeedHelpCard/NeedHelpCard";
import {
  CardHeading,
  PageGrid,
  StickyBox,
  StyledCard,
  StyledElement,
  ZoomIcon,
} from "../../../../Styles/GlobalStyles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useDispatch, useSelector } from "react-redux";
import { MONTHLY_FEES, ONE_TIME_FEES } from "../../../../Constants/constants";
import ZoomOut from "../../../../assets/icons/ZoomOut.svg";
import ZiplyLogoIcon from "../../../../assets/images/ziply-logo.svg";
import {
  setExpandNutritionLabel,
  setZoomOutFlag,
} from "../../../../Redux/Slices/OrderUpgradeSlice";
import ZoomIn from "../../../../assets/icons/Zoom.svg";

interface ApiResponse {
  nutritionLabel?: {
    APIStatus?: number;
    message?: string;
    error?: boolean;
    name?: string;
    description?: string;
    accountNumber?: string;
    ziplyLogo?: string;
    headingLabel?: string;
    Sections?: {
      sectionHeading?: string;
      sectionValue?: string;
      sectionDiv?: string;
      sectionHeadingSubText?: string;
      addonSection?: string;
      govTaxLabel?: string;
      govTaxValue?: string;
      oneTimeFeeLabel?: string;
      optionalLabel?: string;
      rowSections?: {
        secLabel?: string;
        secValue?: string;
        secArray?: {
          name?: string;
          price?: string;
          description?: string;
        }[];
      }[];
    }[];
  };
}

export default function NutritionLabel({
  fromPlanDetails,
  dataFromPlan,
  accountNumberFromPlan,
}: any) {
  const [openError, setOpenError] = useState<boolean>(false);
  const customerDetails = useSelector((state: any) => state.customerInfoDetails);
  const { expandNutritionLabel } = useSelector((state: any) => state.OrderUpgradeDetails);
  const { scrollPosition } = useSelector((state: any) => state.pageInfo);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [getNutritionLabelDetails, { loading, data }] = useLazyQuery<ApiResponse>(
    NUTRITION_LABEL_API,
    {
      variables: {
        accountNumber: customerDetails?.accountNumber,
        isNds: customerDetails?.ndsObj?.isNDS,
      },
      fetchPolicy: "network-only",
      onError: () => {
        setOpenError(true);
      },
    }
  );

  const nutritionLabelData = fromPlanDetails ? dataFromPlan : data?.nutritionLabel;

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (customerDetails?.accountNumber && customerDetails?.linkedAccountNumbers?.length > 0) {
      getNutritionLabelDetails();
    }
  }, [customerDetails?.accountNumber, customerDetails?.ndsObj]);

  function handleAccountRedirect() {
    navigate("/account");
  }
  const [isZoomedIn, setIsZoomedIn] = useState(false);
  const handleZoomIn = () => {
    if (!expandNutritionLabel && !isZoomedIn) {
      setIsZoomedIn(true);
      dispatch(setExpandNutritionLabel(true));
    }
  };
  const handleZoomOut = () => {
    if (expandNutritionLabel || isZoomedIn) {
      setIsZoomedIn(false);
      dispatch(setExpandNutritionLabel(false));
    }
  };
  const renderZoomIcon = () => {
    if (expandNutritionLabel) {
      return <ZoomIcon onClick={handleZoomOut} src={ZoomOut} alt="Zoom-Out" height={"40px"} />;
    } else {
      return <ZoomIcon onClick={handleZoomIn} src={ZoomIn} alt="Zoom-In" height={"40px"} />;
    }
  };

  const closeNutritionLabel = () => {
    dispatch(setZoomOutFlag(false));
    dispatch(setExpandNutritionLabel(false));
  };

  return (
    <>
      <PageGrid
        container
        sx={{
          padding: fromPlanDetails && "0 !important",
          marginTop: fromPlanDetails && "0 !important",
        }}>
        <Stack alignItems={fromPlanDetails ? "center" : "flex-start"} width={"100%"}>
          <Grid
            item
            xs={12}
            lg={fromPlanDetails ? (expandNutritionLabel ? 12 : isZoomedIn ? 12 : 5) : 8}>
            <Box>
              {!fromPlanDetails && (
                <Stack direction={"row"} alignItems={"center"}>
                  <StyledElement marginTop={0.5} onClick={handleAccountRedirect}>
                    <ArrowBackIosIcon />
                  </StyledElement>

                  <CardHeading fontWeight={600}>Nutrition Label</CardHeading>
                </Stack>
              )}

              <StyledCard
                sx={{ padding: { xs: "1rem", md: "1rem 1.5rem" } }}
                style={{ position: "relative" }}>
                {loading ? (
                  <>
                    <Stack gap={"0.5rem"}></Stack>
                  </>
                ) : (
                  <>
                    {openError && !loading ? (
                      <Box margin={5}>
                        <ErrorComponent onRefresh={getNutritionLabelDetails} />
                      </Box>
                    ) : (
                      <Stack gap={"0.5rem"}>
                        <Stack direction={"row"} alignItems={"center"}>
                          <NutritionLabelTypography>Account Number : </NutritionLabelTypography>
                          <Typography marginLeft={"5px"} fontWeight={500} fontSize={"14px"}>
                            {nutritionLabelData?.accountNumber ?? accountNumberFromPlan}
                          </Typography>
                        </Stack>

                        <StyledNutritionLabelDivider />

                        <Typography fontWeight={500} fontSize={"18px"} data-testid="headingLabel">
                          {nutritionLabelData?.headingLabel ?? ""}
                        </Typography>

                        <StyledNutritionLabelDivider style={{ height: "5px" }} />
                        <StyledLogo
                          src={nutritionLabelData?.ziplyLogo ?? ZiplyLogoIcon}
                          alt="ziply-logo"
                          data-testid="ziply-logo"
                        />

                        <Typography fontWeight={800} fontSize={"14px"}>
                          {nutritionLabelData?.name ?? ""}
                        </Typography>

                        <NutritionLabelTypography>
                          {nutritionLabelData?.description ?? ""}
                        </NutritionLabelTypography>

                        <StyledNutritionLabelDivider />
                        {nutritionLabelData?.Sections?.map((section: any, index: any) => {
                          const isLastSection =
                            index === (nutritionLabelData?.Sections?.length || 0) - 1;
                          return (
                            <>
                              <Stack>
                                <NutritionLabelStack width={"100%"} direction={"row"}>
                                  <Typography sx={{ fontWeight: 600 }} data-testid="sectionHeading">
                                    {section?.sectionHeading ?? ""}
                                  </Typography>

                                  <Typography fontSize={"14px"} fontWeight={600}>
                                    {section?.sectionValue}
                                  </Typography>
                                </NutritionLabelStack>
                                {section?.sectionHeading && <Divider sx={{ mb: "10px" }} />}
                                {section?.sectionHeadingSubText && (
                                  <NutritionLabelStack direction={"row"} width={"80%"}>
                                    <NutritionLabelTypography
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          section?.sectionHeadingSubText?.replace(
                                            /<a/g,
                                            '<a target="_blank" rel="noopener noreferrer" style="color: blue ; font-weight: bold;"'
                                          ) || "",
                                      }}
                                    />
                                  </NutritionLabelStack>
                                )}
                                <Stack paddingLeft={1}>
                                  {section?.rowSections?.map((rowSection: any, rowIndex: any) => {
                                    const { secArray, secLabel, secValue } = rowSection;

                                    if (secValue || secArray?.length) {
                                      return (
                                        <>
                                          <>
                                            <NutritionLabelStack
                                              direction={"row"}
                                              sx={{
                                                borderTop:
                                                  rowIndex !== 0 && secArray === null
                                                    ? "1px solid #e9e9e9"
                                                    : "",
                                              }}>
                                              <StyledNutritionLabelText
                                                sx={{
                                                  fontWeight:
                                                    secLabel?.toLowerCase() === ONE_TIME_FEES ||
                                                    secLabel?.toLowerCase() === MONTHLY_FEES
                                                      ? 600
                                                      : 400,
                                                  paddingY:
                                                    secLabel?.toLowerCase() === ONE_TIME_FEES ||
                                                    secLabel?.toLowerCase() === MONTHLY_FEES
                                                      ? "5px"
                                                      : "",
                                                }}>
                                                {secLabel}
                                              </StyledNutritionLabelText>

                                              <StyledNutritionLabelSubText
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    secValue?.replace(
                                                      /<a/g,
                                                      '<a target="_blank" rel="noopener noreferrer" style="color: blue ; font-weight: bold;"'
                                                    ) || "",
                                                }}
                                              />
                                            </NutritionLabelStack>

                                            <Stack>
                                              {secArray?.map((item: any, index: any) => {
                                                const { name, price } = item;
                                                return (
                                                  <Stack
                                                    key={index}
                                                    paddingLeft={"20px"}
                                                    borderTop={"1px solid #e9e9e9"}>
                                                    {price && (
                                                      <>
                                                        <NutritionLabelStack direction={"row"}>
                                                          <StyledNutritionLabelText>
                                                            {name}
                                                          </StyledNutritionLabelText>
                                                          <StyledNutritionLabelSubText>
                                                            {price}
                                                          </StyledNutritionLabelSubText>
                                                        </NutritionLabelStack>
                                                      </>
                                                    )}
                                                  </Stack>
                                                );
                                              })}
                                            </Stack>
                                          </>
                                        </>
                                      );
                                    }
                                  })}
                                </Stack>
                              </Stack>
                              {!isLastSection && <StyledNutritionLabelDivider />}
                              {fromPlanDetails && renderZoomIcon()}
                            </>
                          );
                        })}
                      </Stack>
                    )}
                    {fromPlanDetails && (
                      <StyledElement textAlign={"center"} onClick={closeNutritionLabel}>
                        See less details
                      </StyledElement>
                    )}
                  </>
                )}
              </StyledCard>
            </Box>
          </Grid>
        </Stack>
        {!fromPlanDetails && (
          <Grid item display={{ xs: "block", lg: "none" }} xs={12}>
            <NeedHelpCard />
          </Grid>
        )}
      </PageGrid>

      {!fromPlanDetails && (
        <StickyBox pt={1.5} display={{ xs: "none", lg: scrollPosition > 900 ? "none" : "block" }}>
          <NeedHelpCard />
        </StickyBox>
      )}
      {loading && <LoadingSpinner />}
    </>
  );
}

const StyledNutritionLabelDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: Theme.palette.primary.main,
  height: "2px",
}));
const StyledNutritionLabelText = styled("p")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: { fontSize: "10px" },
  fontSize: "14px",
}));
const StyledNutritionLabelSubText = styled("p")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: { fontSize: "10px" },
  fontSize: "12px",
  fontWeight: "600",
}));

const StyledLogo = styled("img")(({ theme }) => ({
  width: "90px",
  height: "30px",
}));

const NutritionLabelStack = styled(Stack)(({ theme }) => ({
  justifyContent: "space-between",
}));

const NutritionLabelTypography = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
}));
