import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";

export const LoadingSpinner = () => {
  const [lagMessage, setLagMessage] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setLagMessage(true);
    }, 5000);
  }, []);

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CircularProgress color={"success"} />
          {lagMessage && (
            <Typography variant="body2" sx={{ color: "white", textAlign: "center" }}>
              One moment please.
            </Typography>
          )}
        </div>
      </Backdrop>
    </>
  );
};
