import moment from "moment";
import { signOut } from "firebase/auth";
import { auth } from "../Config/firebase";
import { Home } from "../Pages/Home/Home";
import {
  GET_ORDER_STATUS,
  INACTIVE_ACCOUNT_BALANCE_BY_ACCOUNT_NUMBERS,
  UPGRADE_BANNER_COPPER_CUSTOMERS,
  VERIFY_TP_LINK,
} from "./query";
import PrivateRoutes from "./PrivateRoutes";
import { AES, enc } from "crypto-js";
import { Alert } from "../Pages/Alert/Alert";
import { useLazyQuery, useMutation } from "@apollo/client";
import Payment from "../Pages/Payment/Payment";
import React, { useEffect, useState } from "react";
import { Account } from "../Pages/Account/Account";
import { Billing } from "../Pages/Billing/Billing";
import ErrorPage from "../Pages/ErrorPage/ErrorPage";
import { GetToken } from "../components/Token/Token";
import { useDispatch, useSelector } from "react-redux";
import ParkingRoute from "../Pages/Login/ParkingRoute";
import AdminLogin from "../Pages/AdminLogin/AdminLogin";
import AdminScreen from "../Pages/AdminScreen/AdminScreen";
import { setShowOutage, setTpLinkError } from "../Redux/Slices/NetworkStatusSlice";
import { formatResheduleTime, getFlags, removeCommas, setCookie } from "../Services/helper";
import { SelectAccount } from "../Pages/SelectAccount/SelectAccount";
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";
import {
  customerPlanDetails,
  setPaymentConfirmationMessage,
  setShowAutopay,
} from "../Redux/Slices/PlanDetailsSlice";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { SpeedTest } from "../Pages/Home/Components/SpeedtTest/SpeedTest";
import { AccountNotFound } from "../Pages/AccountNotFound/AccountNotFound";
import { setOrderDetailsApiError } from "../Redux/Slices/OrderDetailsSlice";
import { CommercialScreen } from "../Pages/CommercialScreen/CommercialScreen";
import { ManageAutoPay } from "../components/shared/ManageAutoPay/ManageAutoPay";
import { PaymentFailed } from "../components/shared/PaymentFailed/PaymentFailed";
import {
  AUTOPAY_DISABLE_CONTENT,
  AUTOPAY_DISABLE_HEADING,
  CREATE_CUSTOMER_WITH_FINGERPRINT_URL,
  DEV_ENV,
  DEV_HASHKEY,
  ENV,
  NOT_FOUND,
  STG_HASHKEY,
  isAuthenticated,
} from "../Constants/constants";
import { LoadingSpinner } from "../components/shared/LoadingSpinner/LoadingSpinner";
import { MakeAPayment } from "../Pages/Billing/Components/MakeAPayment/MakeAPayment";
import NutritionLabel from "../Pages/Account/Components/NutritionLabel/NutritionLabel";
import {
  AUTOPAY_PAPERLESS_DETAILS,
  CANCEL_AUTOPAY,
  GET_BILLING_DETAILS,
} from "../Pages/Billing/query";
import { SuccessFailure } from "../components/shared/SuccessFailure/SuccessFailureScreen";
import { SignUpAutoPay } from "../components/shared/TotalBalance/SignUpAutoPay/SignUpAutoPay";
import {
  autopayPaperlessDetails,
  setErrorFlag,
  showDisableAutopay,
} from "../Redux/Slices/AutoPayPaperlessSlice";
import { CancelAppointment } from "../Pages/Home/Components/CancelAppointment/CancelAppointment";
import RescheduleAppointment from "../Pages/Home/Components/RescheduleAppointment/RescheduleAppointment";
import OrderAndServiceTicket from "../Pages/Account/Components/OrderAndServiceTicketHistory/OrderAndServiceTicket";
import { CreateServiceTicket } from "../Pages/Home/Components/ServiceTicket/CreateServiceTicket";
import {
  GET_CUSTOMER_CONTACT,
  GET_DETAILS_FOR_AVAILABLE_SLOTS,
  GET_OUTAGE_DETAILS,
  GET_REASONS_FOR_TT,
  GET_TROUBLE_TICKET_STATUS,
} from "../Pages/Home/query";
import {
  customerInfoDetails,
  setActiveAccount,
  setInactiveAccounts,
  setNDS,
  setOrderDetails,
  setOrderInstallationDateTime,
  setPin,
  switchAccountData,
} from "../Redux/Slices/CustomerInfoSlice";
import {
  setAppointment,
  setCreatedDate,
  setdetailsForTTAvailableSlots,
  setReasonsForTT,
  setReasonsForTTError,
  setTroubleTicketApiError,
  setTroubleTicketDetails,
  setTTdetailsError,
} from "../Redux/Slices/TroubleTicketSlice";
import {
  paymentWarningMessage,
  setUserError,
  setUserLoggedInSuccess,
} from "../Redux/Slices/UserSlice";
import OrderUpgrades from "../Pages/Account/Components/OrderUpgrades/OrderUpgrades";
import { useGetCommercialAccount } from "../hooks/Accounts/useGetCommercial";
import useEnvApiCalls from "../hooks/Login/useEnvVariables";
import { isElement } from "react-dom/test-utils";
import { useCheckRouterType } from "../hooks/networkStatus/checkRouterType";
import { PAYMENT_DELAY_WARNING } from "../Pages/Login/query";
import { GET_PLANS_SERVICES } from "../Pages/Account/query";
import useServiceTicketAPI from "../Pages/Home/Components/ServiceTicket/useServiceTicketAPI";
import CreateServiceTicketNew from "../Pages/Home/Components/ServiceTicket/CreateServiceTicketNew";
import CreateServiceError from "../Pages/Home/Components/ServiceTicket/CreateServiceError";

export const EndPoints = ({ userDetails, redirectUrl }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const impersonatedEmail = localStorage.getItem("impersonatedEmail");
  const [email, setEmail] = React.useState(impersonatedEmail ?? "");
  const isMultiAcc = sessionStorage.getItem("isMultiAccount");
  const getError = sessionStorage.getItem("error");
  const userInfoToCreateAccount = localStorage.getItem("userInfo");
  const {
    openServiceTicketDialog,
    openOutageBanner,
    existingTicket,
    createServiceTicketFail,
    createServiceTicketSuccess,
  } = useSelector((state: any) => state.troubleTicketDetails);
  const { userLoggedInSuccess } = useSelector((state: any) => state?.userInfo);
  const customerDetails = useSelector((state: any) => state?.customerInfoDetails);
  const { usi, uuid, activeAccount, deeplinkAccNum } = useSelector(
    (state: any) => state?.customerInfoDetails
  );
  const { encryptedPayload } = useSelector((state: any) => state.signInDetails);
  const [graphQLError, setGraphQLError] = useState(false);
  const [error, setError] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");
  const envFlags = useSelector((state: any) => state?.userInfo?.envFlags);
  const SHOW_CREATE_SERVICE_TICKET = getFlags("enable_create_service_ticket", envFlags);
  const orderInfo = useSelector((state: any) => state.customerInfoDetails.orderDetails);
  const [plansAndServicesData, setPlansAndServicesData] = useState({});
  const INACTIVE_ACCOUNTS = getFlags("inactive_accounts", envFlags);
  const { getCheckRouterType } = useCheckRouterType();
  const [dialogData, setDialogData] = useState<any>({
    heading: "",
    primaryContent: "",
  });
  const [turnOff, setTurnOff] = useState<boolean>(false);
  const [showAutoPayError, setShowAutoPayError] = useState(false);
  const [upgradeBannerData, setUpgradeBannerData] = useState<any>(null);
  const [enableUpgradeError, setEnableUpgradeError] = useState<any>(null);
  const errorPageContent = {
    subHeading: "Login Error",
    content: "You are already logged in.",
    subContent: "Please log out before registering a new user with the provided link.",
    secondaryBtnTitle: "GO TO HOME",
    primaryBtnTitle: "LOG OUT",
  };
  const CTF_BANNER = getFlags("show_ctf_banner", envFlags);
  const hasOpenOrder = orderInfo && Object.keys(orderInfo)?.length > 0;
  const {
    getReasonCodesForTT,
    reasonCodesForTTLoading,
    getDetailsForAvailableSlots,
    getAvailableSlotsDetailsLoading,
  } = useServiceTicketAPI(accountNumber, customerDetails);

  const encrypt = (content: any, password: any) =>
    AES.encrypt(JSON.stringify({ content }), password).toString();

  async function handleBrowserFingerprint() {
    const tokenResponse = localStorage.getItem("tokenResponse");
    const parsedTokenRes = tokenResponse && JSON.parse(tokenResponse);
    const parsedUserInfo = userInfoToCreateAccount && JSON.parse(userInfoToCreateAccount);
    if (parsedUserInfo && Object.values(parsedUserInfo)?.length === 4) {
      try {
        const res = await getCurrentBrowserFingerPrint();
        const encodedData = {
          uuidt: parsedTokenRes?.idToken,
          uuidf: res,
          uuidr: parsedTokenRes?.refreshToken,
          email: parsedUserInfo?.email,
          firstName: parsedUserInfo?.name.split(" ")[0],
          lastName: parsedUserInfo?.name.split(" ")[1],
          phone: parsedUserInfo?.phone,
          usi: parsedUserInfo?.billingUSI,
        };
        const hash_key = process.env.REACT_APP_HASHKEY_URL;
        const responseHash = await fetch(hash_key || "", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        });
        const JSONData = await responseHash.json();
        const encryptedData = encrypt(encodedData, JSONData?.response);
        const response = await fetch(CREATE_CUSTOMER_WITH_FINGERPRINT_URL || "", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({ payload: encryptedData }),
        });
        const responsefromCreateSession = await response.json();
        dispatch(setUserLoggedInSuccess(false));
        return res;
      } catch (error) {
        console.log(error);
        return "";
      }
    }
  }

  const [outageDetailsByAccountNumber, { loading: outageDetailsLoading }] = useLazyQuery(
    GET_OUTAGE_DETAILS,
    {
      variables: {
        accountNumber: accountNumber,
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        dispatch(setShowOutage(data?.outageDetailsByAccountNumber?.affectedByOutage));
      },
    }
  );

  React.useEffect(() => {
    if (customerDetails?.accountNumber) {
      setAccountNumber(
        isAuthenticated === "TRUE" ? customerDetails?.accountNumber : getCurrentAccount()
      );
    }
  }, [customerDetails?.accountNumber]);

  React.useEffect(() => {
    if (userDetails?.email?.length > 0) {
      setEmail((isAuthenticated === "TRUE" ? impersonatedEmail : userDetails?.email) ?? "");
    }
  }, [userDetails?.email]);
  const [getPaymentWarningMessage, { data: PaymentWarningMessageData }] = useLazyQuery(
    PAYMENT_DELAY_WARNING,
    {
      onCompleted: (data) => {
        let showError = {
          error: data?.homeScreenMessage?.error,
          allScreens: data?.homeScreenMessage?.allScreens,
          paymentScreen: data?.homeScreenMessage?.paymentScreen,
          message: data.homeScreenMessage?.message,
          autopayMessage: data.homeScreenMessage?.newAccountsMessage,
        };
        dispatch(paymentWarningMessage(showError));
      },
    }
  );

  // useLazy query automatically triggers the API call , when ever variable changes after first trigger

  const [getCustomerContact, { loading: customerInfoLoading, data: customerInfoDetailsData }] =
    useLazyQuery(GET_CUSTOMER_CONTACT, {
      variables: {
        email: email,
      },
      fetchPolicy: "network-only",
      onCompleted: () => {
        setGraphQLError(false);
      },
      onError: (error: any) => {
        if (
          error.networkError.result.errors[0]?.code === "404" ||
          error.networkError.result.errors[0]?.code === "409"
        ) {
          dispatch(setUserError(error.networkError.result.errors[0]?.message));
          navigate("/acc-not-found");
        } else {
          setGraphQLError(true);
        }
        dispatch(
          customerInfoDetails({
            contactId: "",
            accountNumber: "",
            givenNameFamilyName: {},
            linkedAccountNumbers: [],
            telephoneContact: {},
            emailContact: {},
          })
        );
      },
    });

  React.useEffect(() => {
    if (customerDetails?.accountNumber && customerInfoDetailsData) {
      getPlansServices();
    }
  }, [customerDetails?.accountNumber, customerInfoDetailsData]);

  const getInactiveAccounts = () => {
    const filterInactive = customerDetails?.linkedAccountNumbers?.filter(
      (account: any) => account?.isActive === false
    );
    return filterInactive ? filterInactive?.map((arr: any) => arr?.accountNumber) : [];
  };
  useEffect(() => {
    if (getInactiveAccounts().length > 0 && customerInfoDetailsData)
      getInactiveBalanceByAccountNumbers();
  }, [customerInfoDetailsData, getInactiveAccounts().length]);

  const [getInactiveBalanceByAccountNumbers, { loading: inactiveBalanceLoading }] = useLazyQuery(
    INACTIVE_ACCOUNT_BALANCE_BY_ACCOUNT_NUMBERS,
    {
      variables: {
        accountNumbers: getInactiveAccounts(),
      },
      fetchPolicy: "network-only",
      onCompleted: (data: any) => {
        dispatch(
          setInactiveAccounts(data?.inactiveAccountBalanceByAccountNumbers?.dueBalanceDetails)
        );
      },
      onError: () => dispatch(setInactiveAccounts([])),
    }
  );

  const [getAutopayPaperlessDetails, { loading: autopayPaperlessLoading }] = useLazyQuery(
    AUTOPAY_PAPERLESS_DETAILS,
    {
      variables: {
        accountNumber: customerDetails?.accountNumber,
      },
      onError: (error: any) => {
        if (INACTIVE_ACCOUNTS && !activeAccount) {
          dispatch(showDisableAutopay(true));
        }
        if (error?.networkError?.result.errors[0]?.message?.toLowerCase()?.includes(NOT_FOUND)) {
          dispatch(setErrorFlag(true));
        } else {
          dispatch(setErrorFlag(false));
        }
        dispatch(autopayPaperlessDetails({}));
      },
      onCompleted: (data: any) => {
        const autopayInfo = data?.autoPayAndPaperlessDetails?.autoPayDetails?.autoPayInfo;
        const autoPayPaperlessData = {
          autoPayData: autopayInfo?.length > 0 ? autopayInfo?.[0] : {},
          paperLessData: data?.autoPayAndPaperlessDetails?.paperlessDetails,
        };
        if (autoPayPaperlessData) {
          dispatch(autopayPaperlessDetails(autoPayPaperlessData));
        }
        if (
          INACTIVE_ACCOUNTS &&
          !activeAccount &&
          autoPayPaperlessData?.autoPayData &&
          Object.keys(autoPayPaperlessData?.autoPayData)?.length > 0
        ) {
          dispatch(showDisableAutopay(true));
        } else {
          dispatch(showDisableAutopay(false));
        }
        dispatch(setErrorFlag(false));
      },
      fetchPolicy: "network-only",
    }
  );
  const setAccountActiveStatus = (data: any) => {
    const isActive =
      INACTIVE_ACCOUNTS === false ? true : data?.isActive === null ? true : data.isActive;
    dispatch(setActiveAccount(isActive));
    return isActive;
  };

  const getCurrentAccount = () => {
    if (customerDetails) {
      if (deeplinkAccNum) {
        const customerInfo = {
          linkedAccountNumbers: customerDetails?.linkedAccountNumbers,
          accountNumber: deeplinkAccNum,
        };
        dispatch(switchAccountData(customerInfo));
        const NDS = customerDetails?.linkedAccountNumbers?.find((item: any) => {
          return item?.accountNumber === deeplinkAccNum;
        });
        dispatch(setNDS(NDS));
        const data = customerDetails?.linkedAccountNumbers?.find(
          (item: any) => item.accountNumber === deeplinkAccNum
        );
        if (data) {
          setAccountActiveStatus(data);
          return deeplinkAccNum;
        }
      } else {
        const data = customerDetails?.linkedAccountNumbers?.find(
          (item: any) => item.accountNumber === customerDetails?.accountNumber
        );
        if (data) {
          setAccountActiveStatus(data);
          dispatch(setPin(data?.pin));
          return data.accountNumber;
        }
      }
    }
  };

  useEffect(() => {
    getCurrentAccount();
  }, [accountNumber, customerDetails]);

  const getInitialAccount = () => {
    const linkedAccountNumbers = customerInfoDetailsData?.customerContact?.linkedAccountNumbers;
    if (linkedAccountNumbers) {
      localStorage.setItem(
        "linkedAccountNumbers",
        JSON.stringify(customerInfoDetailsData?.customerContact?.linkedAccountNumbers)
      );
      let newLinkedAccount = localStorage.getItem("linkedAccountNumber");
      let currentAccount = localStorage.getItem("accountNumber");

      if (newLinkedAccount) {
        const data = linkedAccountNumbers?.find(
          (item: any) => item?.accountNumber === newLinkedAccount
        );
        return data ? data?.accountNumber : linkedAccountNumbers?.[0]?.accountNumber;
      } else {
        if (currentAccount) {
          const data = linkedAccountNumbers?.find(
            (item: any) => item?.accountNumber === currentAccount
          );
          return data ? data?.accountNumber : linkedAccountNumbers?.[0]?.accountNumber;
        } else {
          localStorage.setItem("accountNumber", linkedAccountNumbers?.[0]?.accountNumber);
          return linkedAccountNumbers?.[0]?.accountNumber ?? "";
        }
      }
    }
    return "";
  };

  const { getCommercialAccount, commercialAccountLoading } = useGetCommercialAccount();

  const handleLogOut = () => {
    signOut(auth)
      .then(() => {
        localStorage.clear();
        navigate("/register");
        window.location.reload();
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
  const showAutopayBasedOnCustomer = (billingDetails: any) => {
    const paymentMigratedToNds = billingDetails?.billingDetails?.paymentsMigrationToNDS;
    const NDSInprogressAccount = billingDetails?.billingDetails?.NDSInprogressAccount;
    if (
      (!paymentMigratedToNds && !customerDetails?.ndsObj?.isNDS) ||
      (customerDetails?.ndsObj?.isNDS && NDSInprogressAccount)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const [
    getbillingDetails,
    { error: billingError, loading: billingLoading, data: billingDetails },
  ] = useLazyQuery(GET_BILLING_DETAILS, {
    variables: {
      accountNumber: customerDetails?.accountNumber,
    },
    fetchPolicy: "network-only",
    onCompleted: (billingDetails) => {
      dispatch(
        setPaymentConfirmationMessage(billingDetails?.billingDetails?.paymentConfirmationMessage)
      );
      const autopayStatus = billingDetails && showAutopayBasedOnCustomer(billingDetails);
      dispatch(setShowAutopay(autopayStatus));
    },
    onError: () => {
      dispatch(customerPlanDetails({}));
    },
  });

  const [getorderDetails, { error: orderError, loading: ordersLoading, data: orderDetails }] =
    useLazyQuery(GET_ORDER_STATUS, {
      variables: {
        email: email,
        accountNumber: customerDetails?.accountNumber,
        isNds: customerDetails?.ndsObj?.isNDS,
        usi: customerDetails?.ndsObj?.usi,
        uuid: customerDetails?.ndsObj?.accountUUID,
      },
      fetchPolicy: "network-only",
      onCompleted: () => dispatch(setOrderDetailsApiError(false)),
      onError: () => dispatch(setOrderDetailsApiError(true)),
    });

  React.useEffect(() => {
    if (orderDetails && orderDetails?.getOrderDetailsByEmail?.orderDetails) {
      const installStartTime = moment
        .utc(orderDetails?.getOrderDetailsByEmail?.orderDetails?.installationAppointment)
        ?.format("hh:mm A");
      const installEndTime = moment
        .utc(orderDetails?.getOrderDetailsByEmail?.orderDetails?.installationAppointmentEndDate)
        ?.format("hh:mm A");
      const installDate = moment
        .utc(orderDetails?.getOrderDetailsByEmail?.orderDetails?.installationAppointment)
        ?.format("dddd, MMMM DD");

      const installationDateTime = {
        installStart: installStartTime,
        installEnd: installEndTime,
        installDate: installDate,
      };
      dispatch(setOrderDetails(orderDetails?.getOrderDetailsByEmail?.orderDetails));
      Object.values(installationDateTime)?.length > 0 &&
        dispatch(setOrderInstallationDateTime(installationDateTime));
    }
    dispatch(setOrderDetails(orderDetails?.getOrderDetailsByEmail?.orderDetails ?? {}));
  }, [orderError, ordersLoading, orderDetails]);

  const [verifyTPLinkRouter, { loading: verifyTPLinkRouterLoading, data: verifyTPLinkRouterData }] =
    useLazyQuery(VERIFY_TP_LINK, {
      variables: {
        accountNumber: customerDetails?.accountNumber,
        usi: customerDetails?.ndsObj?.usi,
        isNds: customerDetails?.ndsObj?.isNDS,
      },
      fetchPolicy: "network-only",
      onCompleted: () => {
        dispatch(setTpLinkError(false));
      },
      onError: () => {
        dispatch(setTpLinkError(true));
      },
    });

  const [
    getPlansServices,
    { error: plansServicesError, loading: plansServicesLoading, data: plansServicesInfo },
  ] = useLazyQuery(GET_PLANS_SERVICES, {
    variables: {
      accountNumber: customerDetails?.accountNumber,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.plansAndServices) {
        setPlansAndServicesData(data?.plansAndServices[0]);
      }
    },
  });

  const [getUpgradeBannerForCopper, { loading: upgradeBannerLoading, data: upgradeBannerdata }] =
    useMutation(UPGRADE_BANNER_COPPER_CUSTOMERS, {
      variables: {
        accountNumber: customerDetails?.accountNumber,
        isNds: customerDetails?.ndsObj?.isNDS,
      },
      fetchPolicy: "network-only",
      onError: () => {
        setEnableUpgradeError(true);
      },
      onCompleted: () => setEnableUpgradeError(false),
    });

  const loadingState = upgradeBannerLoading === false && verifyTPLinkRouterLoading === false;

  useEffect(() => {
    if (customerDetails?.ndsObj?.isNDS === false && customerDetails?.accountNumber) {
      getUpgradeBannerForCopper();
    }
  }, [customerDetails?.ndsObj?.isNDS, customerDetails?.accountNumber, getUpgradeBannerForCopper]);
  useEffect(() => {
    setUpgradeBannerData(upgradeBannerdata?.upgradeBannerForCopperCustomers);
  }, [upgradeBannerdata]);
  const [
    getTroubleTicketStatus,
    { loading: troubleTicketStatusLoading, data: troubleTicketStatusData },
  ] = useLazyQuery(GET_TROUBLE_TICKET_STATUS, {
    variables: {
      accountNumber: accountNumber,
    },
    fetchPolicy: "network-only",
    onError: () => dispatch(setTroubleTicketApiError(true)),
    onCompleted: () => dispatch(setTroubleTicketApiError(false)),
  });

  useEffect(() => {
    if (accountNumber) {
      getTroubleTicketStatus();
    }
  }, [accountNumber]);

  useEffect(() => {
    if (troubleTicketStatusData) {
      const troubleTicketDetails = troubleTicketStatusData?.troubleTicketDetails;
      const createdDate = moment(troubleTicketDetails?.tickets?.[0]?.createdDate).format("LL");
      const appointmentDate = moment
        .utc(troubleTicketDetails?.tickets?.[0]?.appointment?.startDate)
        ?.format("dddd, MMMM DD");
      const appointmentStartTime = formatResheduleTime(
        troubleTicketDetails?.tickets?.[0]?.appointment?.startDate
      );

      const appointmentEndTime = formatResheduleTime(
        troubleTicketDetails?.tickets?.[0]?.appointment?.endDate
      );

      const appointment = {
        appointmentDate: appointmentDate,
        appointmentStartTime: appointmentStartTime,
        appointmentEndTime: appointmentEndTime,
      };
      dispatch(setCreatedDate(createdDate));
      dispatch(setTroubleTicketDetails(troubleTicketDetails ? troubleTicketDetails : []));
      dispatch(setAppointment(appointment));
    }
  }, [troubleTicketStatusData, troubleTicketStatusLoading]);

  const telephoneContact = customerInfoDetailsData?.customerContact?.telephoneContact?.[0];
  const emailContact = customerInfoDetailsData?.customerContact?.emailContact?.[0];
  const handleOrderUpgradeRoute = () => {
    navigate("/account/upgrade-speed", {
      state: { data: plansServicesInfo?.plansAndServices?.[0], fromBanner: true },
    });
  };

  const { getEnvValues, getEnvValuesLoading } = useEnvApiCalls();
  React.useEffect(() => {
    if (email?.length > 0) getEnvValues();
  }, [email]);

  useEffect(() => {
    if (location.pathname === "/adminscreen") {
      setEmail(impersonatedEmail ?? "");
    }
  }, [location.pathname]);
  React.useEffect(() => {
    getPaymentWarningMessage();
  }, []);

  React.useEffect(() => {
    if (email?.length > 0) {
      getCustomerContact();
    }
  }, [email, getCustomerContact]);

  React.useEffect(() => {
    if (customerDetails?.accountNumber) {
      getbillingDetails();
      getAutopayPaperlessDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDetails?.accountNumber]);

  useEffect(() => {
    if (email) {
      getorderDetails();
    }
  }, [
    email,
    customerDetails?.accountNumber,
    customerDetails?.ndsObj?.isNDS,
    customerDetails?.ndsObj?.accountUUID?.length,
    customerDetails?.ndsObj?.usi?.length,
    getorderDetails,
  ]);

  useEffect(() => {
    if (email || impersonatedEmail) {
      isMultiAcc && customerDetails?.contactId && getCommercialAccount();
    }
  }, [customerDetails?.contactId, email]);

  React.useEffect(() => {
    const customerInfo = {
      accountNumber: deeplinkAccNum ? deeplinkAccNum : getInitialAccount(),
      contactId: customerInfoDetailsData?.customerContact?.id,
      linkedAccountNumbers: customerInfoDetailsData?.customerContact?.linkedAccountNumbers,
      givenNameFamilyName: customerInfoDetailsData?.customerContact?.givenAndFamilyName,
      telephoneContact: telephoneContact,
      emailContact: emailContact,
    };
    if (customerInfoDetailsData?.customerContact?.linkedAccountNumbers) {
      dispatch(customerInfoDetails(customerInfo));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerInfoLoading]);

  const { autoPayData } = useSelector((state: any) => state?.autopayPaperlessDetails);

  const [cancelAutopay, { loading: cancelAutopayLoading }] = useMutation(CANCEL_AUTOPAY, {
    refetchQueries: [AUTOPAY_PAPERLESS_DETAILS],
    onCompleted: () => {
      dispatch(autopayPaperlessDetails({}));
    },
  });

  const handleCancelAutopay = async () => {
    try {
      const response = await cancelAutopay({
        variables: {
          accountNumber: customerDetails?.accountNumber,
          autoPayInput: {
            paymentMethodId: autoPayData?.paymentMethodId,
            paymentMethodType: autoPayData?.paymentMethodType,
            autoPaymentId: autoPayData?.id,
            isNds: customerDetails?.ndsObj?.isNDS,
          },
        },
      });
      if (!response?.data?.cancelAutoPay?.error) {
        setDialogData({
          heading: AUTOPAY_DISABLE_HEADING,
          primaryContent: AUTOPAY_DISABLE_CONTENT,
        });
        setTurnOff(true);
        setShowAutoPayError(false);
      } else {
        setShowAutoPayError(true);
      }
    } catch (err) {
      console.log(err);
      setShowAutoPayError(true);
    }
  };

  useEffect(() => {
    if (accountNumber) {
      outageDetailsByAccountNumber();
      getReasonCodesForTT();
      getDetailsForAvailableSlots();
    }
  }, [accountNumber, customerDetails?.ndsObj?.isNDS]);

  React.useEffect(() => {
    const planDetails = {
      billDueDate: billingDetails?.billingDetails?.billDueDate,
      currentDueBalance: billingDetails?.billingDetails?.currentDueBalance,
      ziplyPlanName: billingDetails?.billingDetails?.ziplyPlanName,
      error: billingDetails?.billingDetails?.error,
      APIStatus: billingDetails?.billingDetails?.APIStatus,
      billingAddress: removeCommas(billingDetails?.billingDetails?.serviceAddress ?? ""),
      paymentsMigrationToNDS: billingDetails?.billingDetails?.paymentsMigrationToNDS,
      VOIPEnabled: billingDetails?.billingDetails?.VOIPEnabled,
      isNewCustomer: billingDetails?.billingDetails?.isNewCustomer,
      serviceAddress: billingDetails?.billingDetails?.serviceAddress ?? "",
      paperlessAmount: billingDetails?.billingDetails?.paperlessAmount,
      autopayAmount: billingDetails?.billingDetails?.autopayAmount,
      NDSInprogressAccount: billingDetails?.billingDetails?.NDSInprogressAccount,
    };

    if (planDetails) {
      dispatch(customerPlanDetails(planDetails));
    }
    let userInfo = {
      name: `${customerInfoDetailsData?.customerContact?.givenAndFamilyName?.givenName} ${customerInfoDetailsData?.customerContact?.givenAndFamilyName?.familyName} `,
      phone: telephoneContact?.telephoneNumber,
      email: emailContact?.emailAddress,
      billingUSI: customerDetails?.ndsObj?.usi,
    };
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
    if (billingDetails?.billingDetails?.usi) {
      setCookie("customerUSI", billingDetails?.billingDetails?.usi);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountNumber, billingLoading]);

  const loader =
    customerInfoLoading ||
    commercialAccountLoading ||
    getEnvValuesLoading ||
    upgradeBannerLoading ||
    inactiveBalanceLoading ||
    plansServicesLoading ||
    reasonCodesForTTLoading ||
    outageDetailsLoading ||
    getAvailableSlotsDetailsLoading;

  useEffect(() => {
    const enableCustomerSession = process.env.REACT_APP_ENABLE_CUSTOMER_SESSION === "TRUE";
    if (enableCustomerSession) {
      if (userInfoToCreateAccount && userLoggedInSuccess) {
        handleBrowserFingerprint();
      }
    }
  }, [userInfoToCreateAccount]);

  useEffect(() => {
    if (customerDetails?.accountNumber) {
      const NDS = customerDetails.linkedAccountNumbers?.find((item: any) => {
        return item?.accountNumber === customerDetails.accountNumber;
      });
      dispatch(setNDS(NDS));
    }
  }, [customerDetails?.accountNumber]);

  useEffect(() => {
    const fetchData = async () => {
      if (
        customerDetails?.accountNumber &&
        customerDetails?.ndsObj?.isNDS !== undefined &&
        customerDetails?.ndsObj?.usi
      ) {
        try {
          await Promise.all([getCheckRouterType(), verifyTPLinkRouter()]);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchData();
  }, [
    customerDetails?.accountNumber,
    customerDetails?.ndsObj?.isNDS,
    customerDetails?.ndsObj?.usi,
    getCheckRouterType,
    verifyTPLinkRouter,
  ]);

  const showCTFBanner =
    CTF_BANNER &&
    customerDetails?.ndsObj?.isNDS === false &&
    upgradeBannerData?.enableUpgradeBanner &&
    enableUpgradeError === false &&
    loadingState &&
    !customerInfoLoading &&
    !ordersLoading &&
    !hasOpenOrder;

  return (
    <>
      <Routes>
        <Route path="/get-token" element={<GetToken />} />
        {getError === null ? (
          <>
            <Route
              path="/home"
              element={
                <PrivateRoutes>
                  <Home
                    billingError={billingError}
                    billingLoading={billingLoading}
                    graphQLError={graphQLError}
                    setGraphQLError={setGraphQLError}
                    customerInfoLoading={customerInfoLoading}
                    getCurrentAccount={getCurrentAccount}
                    getCustomerContact={getCustomerContact}
                    getbillingDetails={getbillingDetails}
                    troubleTicketStatusLoading={troubleTicketStatusLoading}
                    outageDetailsLoading={outageDetailsLoading}
                    billingDetails={billingDetails}
                    ordersLoading={ordersLoading}
                    tpLinkData={verifyTPLinkRouterData}
                    loadingState={loadingState}
                    upgradeBannerData={upgradeBannerData}
                    showCTFBanner={showCTFBanner}
                    plansServicesInfo={plansServicesInfo}
                    handleOrderUpgradeRoute={handleOrderUpgradeRoute}
                    autopayPaperlessLoading={autopayPaperlessLoading}
                    loader={loader}
                  />
                </PrivateRoutes>
              }
            />
            {ENV === DEV_ENV && (
              <Route
                path="/home/speedtest"
                element={
                  <PrivateRoutes>
                    <SpeedTest />
                  </PrivateRoutes>
                }
              />
            )}
            <Route
              path="/home/reschedule-appointment"
              element={
                <PrivateRoutes>
                  <RescheduleAppointment getorderDetails={getorderDetails} />
                </PrivateRoutes>
              }
            />
            <Route
              path="/home/cancel-appointment"
              element={
                <PrivateRoutes>
                  <CancelAppointment />
                </PrivateRoutes>
              }
            />
            <Route
              path="/account"
              element={
                <PrivateRoutes
                  children={
                    <Account
                      customerInfoLoading={customerInfoLoading}
                      autopayPaperlessLoading={autopayPaperlessLoading}
                      ordersLoading={ordersLoading}
                      billingLoading={billingLoading}
                      handleCancelAutopay={handleCancelAutopay}
                      cancelAutopayLoading={cancelAutopayLoading}
                      dialogData={dialogData}
                      setTurnOff={setTurnOff}
                      turnOff={turnOff}
                      showAutoPayError={showAutoPayError}
                      setShowAutoPayError={setShowAutoPayError}
                      plansServicesError={plansServicesError}
                      plansServicesLoading={plansServicesLoading}
                      plansServicesInfo={plansServicesInfo}
                      getPlansServices={getPlansServices}
                      billingError={billingError}
                    />
                  }
                />
              }
            />
            <Route
              path="/billing"
              element={
                <PrivateRoutes>
                  <Billing
                    graphQLError={graphQLError}
                    billingError={billingError}
                    customerInfoLoading={customerInfoLoading}
                    getbillingDetails={getbillingDetails}
                    billingLoading={billingLoading}
                    getCustomerContact={getCustomerContact}
                    autopayPaperlessLoading={autopayPaperlessLoading}
                    handleCancelAutopay={handleCancelAutopay}
                    cancelAutopayLoading={cancelAutopayLoading}
                    dialogData={dialogData}
                    setTurnOff={setTurnOff}
                    turnOff={turnOff}
                    showAutoPayError={showAutoPayError}
                    setShowAutoPayError={setShowAutoPayError}
                    loadingState={loadingState}
                    upgradeBannerData={upgradeBannerData}
                    showCTFBanner={showCTFBanner}
                    handleOrderUpgradeRoute={handleOrderUpgradeRoute}
                  />
                </PrivateRoutes>
              }
            />
            <Route
              path="/messages"
              element={
                <PrivateRoutes>
                  <Alert
                    graphQLError={graphQLError}
                    customerInfoLoading={customerInfoLoading}
                    getCustomerContact={getCustomerContact}
                  />
                </PrivateRoutes>
              }
            />
            <Route
              path="/billing/makeapayment"
              element={
                <PrivateRoutes>
                  <MakeAPayment
                    billingLoading={billingLoading}
                    getbillingDetails={getbillingDetails}
                  />
                </PrivateRoutes>
              }
            />
            <Route
              path="/select-account"
              element={
                <PrivateRoutes>
                  <SelectAccount />
                </PrivateRoutes>
              }
            />
            <Route
              path="/commercial"
              element={
                <PrivateRoutes>
                  <CommercialScreen />
                </PrivateRoutes>
              }
            />
            <Route
              path="/billing/signupautopay"
              element={
                <PrivateRoutes>
                  <SignUpAutoPay />
                </PrivateRoutes>
              }
            />
            <Route
              path="/payment/success"
              element={
                <PrivateRoutes>
                  <SuccessFailure />
                </PrivateRoutes>
              }
            />
            <Route
              path="/payment/failed"
              element={
                <PrivateRoutes>
                  <PaymentFailed />
                </PrivateRoutes>
              }
            />
            <Route
              path="/billing/manageautopay"
              element={
                <PrivateRoutes>
                  <ManageAutoPay />
                </PrivateRoutes>
              }
            />
            <Route
              path="/account/manageautopay"
              element={
                <PrivateRoutes>
                  <ManageAutoPay />
                </PrivateRoutes>
              }
            />
            <Route
              path="/account/signupautopay"
              element={
                <PrivateRoutes>
                  <SignUpAutoPay />
                </PrivateRoutes>
              }
            />

            <Route
              path="/account/upgrade-speed"
              element={
                <PrivateRoutes>
                  <OrderUpgrades
                    getorderDetails={getorderDetails}
                    getPlansServices={getPlansServices}
                    data={plansAndServicesData}
                  />
                </PrivateRoutes>
              }
            />

            <Route
              path="/login"
              element={
                <PrivateRoutes>
                  {isAuthenticated === "TRUE" ? (
                    <Navigate to={`/adminscreen${redirectUrl ? redirectUrl : ""}`} />
                  ) : (
                    <Navigate to={"/checking-account"} />
                  )}
                </PrivateRoutes>
              }
            />
            <>
              <Route
                path="/account/orderandservicetickethistory"
                element={
                  <PrivateRoutes>
                    <OrderAndServiceTicket />
                  </PrivateRoutes>
                }
              />

              <Route
                path="/account/nutritionlabel"
                element={
                  <PrivateRoutes>
                    <NutritionLabel />
                  </PrivateRoutes>
                }
              />
            </>

            {/* <Route
        path="/pdfviewer"
        element={
          <PrivateRoutes>
            <PdfViewer />
          </PrivateRoutes>
        }
      /> */}
            <Route
              path="/payment/success"
              element={
                <PrivateRoutes>
                  <Navigate to="/billing/makeapayment" />
                </PrivateRoutes>
              }
            />

            <Route
              path="/register"
              element={
                <PrivateRoutes>
                  {encryptedPayload?.trim()?.length > 0 ? (
                    <Navigate to="/*" />
                  ) : (
                    <Navigate to="/home" />
                  )}
                </PrivateRoutes>
              }
            />
          </>
        ) : (
          <Route path="/*" element={<ErrorPage />} />
        )}

        <>
          <Route
            path="/account/nutritionlabel/:accountNumber"
            element={
              <PrivateRoutes>
                <NutritionLabel />
              </PrivateRoutes>
            }
          />
        </>
        <Route
          path="/payment/success"
          element={
            <PrivateRoutes>
              <Navigate to="/billing/makeapayment" />
            </PrivateRoutes>
          }
        />

        <Route
          path="/login"
          element={
            <PrivateRoutes>
              {isAuthenticated === "TRUE" ? (
                <Navigate to={`/adminscreen${redirectUrl ? redirectUrl : ""}`} />
              ) : (
                <Navigate to="/home" />
              )}
            </PrivateRoutes>
          }
        />

        <Route
          path="/register"
          element={
            <PrivateRoutes>
              {encryptedPayload?.trim()?.length > 0 ? (
                <Navigate to="/*" />
              ) : (
                <Navigate to="/home" />
              )}
            </PrivateRoutes>
          }
        />
        <Route
          path="/checking-account"
          element={
            <PrivateRoutes>
              <ParkingRoute />
            </PrivateRoutes>
          }
        />
        <Route
          path="/acc-not-found"
          element={
            <PrivateRoutes>
              <AccountNotFound />
            </PrivateRoutes>
          }
        />
        <Route path="/payment" element={<Payment />} />
        {isAuthenticated === "TRUE" ? (
          <>
            <Route path="/adminscreen" element={<AdminScreen />} />
            <Route path="/admin/login" element={<AdminLogin />} />
          </>
        ) : (
          <>
            <Route path="/adminscreen" element={<ErrorPage />} />
            <Route path="/admin/login" element={<ErrorPage />} />
          </>
        )}

        {encryptedPayload?.trim()?.length > 0 ? (
          <Route
            path="/*"
            element={<ErrorPage data={errorPageContent} handleLogOut={handleLogOut} />}
          />
        ) : (
          <Route path="/*" element={<ErrorPage />} />
        )}
      </Routes>

      {SHOW_CREATE_SERVICE_TICKET && (
        <>
          {openServiceTicketDialog && <CreateServiceTicketNew />}
          {(openOutageBanner ||
            existingTicket ||
            createServiceTicketFail ||
            createServiceTicketSuccess) && <CreateServiceError />}
        </>
      )}
      {loader && <LoadingSpinner />}
    </>
  );
};
