import { useLazyQuery, useMutation } from "@apollo/client";
import { CustomManage } from "./CustomManage";
import { useDispatch, useSelector } from "react-redux";
import { AccountCard } from "../AccountCard/AccountCard";
import { NeedHelpCard } from "../NeedHelpCard/NeedHelpCard";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Stack, Grid, Typography } from "@mui/material";
import { useEffect, useState, useLayoutEffect } from "react";
import { getFlags, paymentModifiedDate } from "../../../Services/helper";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import {
  setApiFailureName,
  setCbstAction,
  setError,
  setIframeURL,
} from "../../../Redux/Slices/PaymentSlice";
import { SaveAutoPay, SaveAutopayTypography } from "../TotalBalance/SaveAutoPay/SaveAutoPay";
import { ConfirmationModel } from "../ConfirmationModel/ConfirmationModel";
import {
  autopayPaperlessDetails,
  turnOffAutopay,
} from "../../../Redux/Slices/AutoPayPaperlessSlice";
import { LinkingWaitMessage } from "../LinkingWaitMessage/LinkingWaitMessage";
import { PageGrid, StickyBox, StyledCard } from "../../../Styles/GlobalStyles";
import { UpdateErrorComponent } from "../UpdateErrorComponent/UpdateErrorComponent";
import { PaymentMethod } from "../../../Pages/Billing/Components/PaymentMethod/PaymentMethod";
import { editAutoPayEnable, saveAutoPayChanges } from "../../../Redux/Slices/EditAutoPaySlice";
import {
  AUTOPAY_PAPERLESS_DETAILS,
  CANCEL_AUTOPAY,
  EDIT_AUTOPAY,
} from "../../../Pages/Billing/query";
import {
  CANCEL_AUTOPAY_CBST_ACTION,
  CANCEL_AUTOPAY_SUCCESS_DESCRIPTION,
  CANCEL_AUTOPAY_SUCCESS_NOTE,
  CANCEL_AUTOPAY_SUCCESS_PRIMARY_TEXT,
  CANCEL_AUTOPAY_SUCCESS_SECONDARY_TEXT,
  CANCEL_AUTOPAY_SUCCESS_TITLE,
  EDIT_AUTOPAY_CBST_ACTION,
  EXISTING,
  LANGUAGE,
  MY_ACCOUNT,
  SUCCESS_EDIT_AUTOPAY,
  currentDate,
} from "../../../Constants/constants";
import ReportIssue from "../ReportIssue/ReportIssue";

export const ManageAutoPay = () => {
  const { state } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { email } = useSelector((state: any) => state.userInfo);
  const { scrollPosition } = useSelector((state: any) => state.pageInfo);
  const customerDetails = useSelector((state: any) => state.customerInfoDetails);
  const { paymentsMigrationToNDS } = useSelector((state: any) => state.customerPlanDetails);
  const { isEditAutoPayEnable, isSaveAutoPayChanges } = useSelector(
    (state: any) => state.editAutoPay
  );
  const { autoPayData, uuidErrorFlag } = useSelector(
    (state: any) => state?.autopayPaperlessDetails
  );
  const [unread, setUnread] = useState<boolean>(true);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [manageData, setManageData] = useState<any>({});
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const [failSafeError, setFailSafeError] = useState<boolean>(false);
  const [cancelAutoPaySuccessful, setCancelAutopaySuccessful] = useState<boolean>(false);
  const [typeOfPayment, setTypeOfPayment] = useState<string>("");
  const { givenNameFamilyName } = useSelector((state: any) => state.customerInfoDetails);
  const envFlags = useSelector((state: any) => state?.userInfo?.envFlags);
  const SHOW_REPORT_ISSUE = getFlags("report_service_issue", envFlags);

  const [dialogData, setDialogData] = useState<any>({
    heading: "",
    primaryContent: "",
    secondaryContent: "",
    containedButton: { buttonType: "", buttonTitle: "", eventName: "" },
  });

  const negativeDay = -manageData?.scheduleDayBefore;
  const isNDS = customerDetails?.ndsObj?.isNDS || paymentsMigrationToNDS;
  const [openReportIssue, setOpenReportIssue] = useState(false);
  const handleReportIssueError = () => {
    SHOW_REPORT_ISSUE ? setOpenReportIssue(true) : setOpenError(true);
  };

  const [cancelAutopay, { loading: cancelAutopayLoading }] = useMutation(CANCEL_AUTOPAY, {
    refetchQueries: [AUTOPAY_PAPERLESS_DETAILS],
    onCompleted: () => {
      dispatch(autopayPaperlessDetails({}));
      setOpenReportIssue(false);
    },
    onError: (error: any) => {
      setDialogOpen(false);
      dispatch(setError(error?.networkError?.result?.errors?.[0]));
      handleReportIssueError();
      dispatch(setApiFailureName("cancelAutopay"));
      dispatch(setCbstAction(CANCEL_AUTOPAY_CBST_ACTION));
    },
  });

  const [editAutopay, { loading: editAutopayLoading }] = useMutation(EDIT_AUTOPAY, {
    refetchQueries: [AUTOPAY_PAPERLESS_DETAILS],
    onCompleted: () => {
      setOpenReportIssue(false);
    },
    onError: (error: any) => {
      dispatch(setError(error?.networkError?.result?.errors?.[0]));
      handleReportIssueError();
      dispatch(setApiFailureName("editAutopay"));
      dispatch(setCbstAction(EDIT_AUTOPAY_CBST_ACTION));
    },
  });

  const handleCancelAutopay = async () => {
    if (isNDS !== undefined) {
      try {
        const response = await cancelAutopay({
          variables: {
            autoPayInput: {
              paymentMethodId: autoPayData?.paymentMethodId,
              scheduleDayBefore: negativeDay?.toString(),
              paymentMethodType: autoPayData?.paymentMethodType,
              autoPaymentId: autoPayData?.id,
              isNds: customerDetails?.ndsObj?.isNDS,
            },
            accountNumber: customerDetails?.accountNumber,
          },
        });
        if (response?.data?.cancelAutoPay?.error) {
          setDialogOpen(false);
          handleReportIssueError();
        } else if (response?.data) {
          handleTurnOffAutoPay();
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleTurnOffAutoPay = () => {
    dispatch(turnOffAutopay({}));
    dispatch(editAutoPayEnable(false));
    setCancelAutopaySuccessful(true);
  };

  const editAutopayUsingExisting = {
    paymentMethodId: manageData?.id,
    scheduleDayBefore: manageData?.scheduleDayBefore,
    paymentMethodType: manageData?.paymentMethodType,
    enableAutoPay: true,
    autoPaymentId: autoPayData?.id,
    isNds: customerDetails?.ndsObj?.isNDS,
    uid: null,
    paymentToken: manageData?.token,
  };

  const handleConfirm = async (data: any, uid: any) => {
    console.log(data, "data", uid, "from iframe");

    const editByAddingNewPayment = {
      paymentMethodId: !isNDS ? data?.content?.uid : null,
      scheduleDayBefore: manageData?.scheduleDayBefore,
      paymentMethodType: typeOfPayment,
      enableAutoPay: true,
      autoPaymentId: autoPayData?.id,
      isNds: customerDetails?.ndsObj?.isNDS,
      uid: isNDS ? uid : null,
      paymentToken: isNDS ? manageData?.token : null,
    };
    try {
      if (isNDS !== undefined) {
        const response = await editAutopay({
          variables: {
            accountNumber: customerDetails?.accountNumber,
            autoPayInput:
              typeOfPayment === EXISTING ? editAutopayUsingExisting : editByAddingNewPayment,
          },
        });
        if (response?.data?.editAutoPay?.error) {
          dispatch(setIframeURL(""));
          handleReportIssueError();
        } else if (response?.data) {
          dispatch(saveAutoPayChanges(false));
          dispatch(editAutoPayEnable(false));
          setSuccessMessage(true);
          navigate("/billing/manageautopay");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSaveChanges = (autopayDetails: any, getPaymentMethodURL: any, paymentInput: any) => {
    setManageData(autopayDetails);
    setTypeOfPayment(paymentInput);

    if (paymentInput === EXISTING) {
      dispatch(editAutoPayEnable(false));
      dispatch(saveAutoPayChanges(true));
    } else {
      if (!isNDS) {
        setOpenError(true);
      } else {
        if (isNDS !== null && isNDS !== undefined) {
          getPaymentMethodURL({
            variables: {
              accountNumber: customerDetails?.accountNumber,
              addPaymentMethodInput: {
                paymentType: paymentInput,
                billingName: `${givenNameFamilyName?.givenName ?? ""} ${
                  givenNameFamilyName?.familyName ?? ""
                }`,
                email: email,
                language: LANGUAGE,
                cancelURL: `${window.location.href}?status=failure`,
                successURL: `${window.location.href}?status=success`,
                date: paymentModifiedDate(currentDate),
                completePayment: false,
                isNds: customerDetails?.ndsObj?.isNDS || paymentsMigrationToNDS,
              },
            },
            fetchPolicy: "network-only",
            onCompleted: (URLData: any) => {
              dispatch(setIframeURL(URLData?.getAddPaymentMethodURL?.url));
              setOpenError(URLData?.getAddPaymentMethodURL?.error);
            },
            onError: () => setOpenError(true),
          });
        }
      }
    }
  };

  const handleStepper = () => {
    if (isEditAutoPayEnable) {
      setManageData(autoPayData);
      dispatch(editAutoPayEnable(false));
      dispatch(setIframeURL(""));
    } else if (isSaveAutoPayChanges) {
      dispatch(editAutoPayEnable(true));
      dispatch(saveAutoPayChanges(false));
    } else if (state) {
      navigate("/home");
    } else if (location.pathname === "/billing/manageautopay") {
      navigate("/billing");
    } else {
      navigate("/account");
    }
  };

  useEffect(() => {
    dispatch(editAutoPayEnable(false));
    dispatch(saveAutoPayChanges(false));
  }, []);

  useEffect(() => {
    if (autoPayData) {
      setManageData(autoPayData);
    }
  }, [autoPayData]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (successMessage) {
    return <SaveAutoPay title={SUCCESS_EDIT_AUTOPAY} />;
  }

  if (cancelAutoPaySuccessful) {
    return (
      <SaveAutoPay
        title={CANCEL_AUTOPAY_SUCCESS_TITLE}
        primaryText={
          <>
            <SaveAutopayTypography>{CANCEL_AUTOPAY_SUCCESS_PRIMARY_TEXT}</SaveAutopayTypography>
            {CANCEL_AUTOPAY_SUCCESS_SECONDARY_TEXT}
          </>
        }
        note={CANCEL_AUTOPAY_SUCCESS_NOTE}
      />
    );
  }
  return (
    <Box>
      <PageGrid container mb={{ md: scrollPosition > 30 ? 4 : 16 }}>
        <Grid item xs={12} lg={8.2}>
          <Stack
            sx={{ cursor: "pointer" }}
            mb={2}
            direction={"row"}
            onClick={handleStepper}
            alignItems="center">
            <ArrowBackIosIcon color="secondary" />
            <Typography variant="h6" fontWeight={600}>
              Manage autopay
            </Typography>
          </Stack>
          {uuidErrorFlag ? (
            <StyledCard sx={{ py: 16 }}>
              <LinkingWaitMessage />
            </StyledCard>
          ) : (
            <StyledCard sx={{ p: "1.2rem 1.5rem" }}>
              {isEditAutoPayEnable ? (
                <>
                  <Typography component={"span"} fontWeight={600} variant="h6">
                    Edit autopay
                  </Typography>
                  <PaymentMethod
                    manageData={manageData}
                    setManageData={setManageData}
                    handleConfirm={handleConfirm}
                    handleSaveChanges={handleSaveChanges}
                  />
                </>
              ) : (
                <>
                  <CustomManage
                    manageData={manageData}
                    handleConfirm={handleConfirm}
                    unread={unread}
                    setUnread={setUnread}
                    setDialogOpen={setDialogOpen}
                    setDialogData={setDialogData}
                    dialogData={dialogData}
                  />
                </>
              )}
            </StyledCard>
          )}
        </Grid>
        <Grid
          item
          py={{ xs: 2, lg: 0 }}
          xs={12}
          lg={3.5}
          display={{ xs: "block", lg: scrollPosition > 30 ? "block" : "none" }}>
          <AccountCard />
          <NeedHelpCard />
        </Grid>

        <StickyBox display={{ xs: "none", lg: scrollPosition > 30 ? "none" : "block" }}>
          <AccountCard />
          <NeedHelpCard />
        </StickyBox>
      </PageGrid>
      <ConfirmationModel
        openDialog={dialogOpen}
        setOpenDialog={setDialogOpen}
        dialogData={dialogData}
        setDialogData={setDialogData}
        onClick={handleCancelAutopay}
      />
      {(cancelAutopayLoading || editAutopayLoading) && <LoadingSpinner />}
      {failSafeError && (
        <>
          <UpdateErrorComponent
            refreshButton={true}
            openError={failSafeError}
            setOpenError={setFailSafeError}
          />
        </>
      )}

      <ReportIssue setOpenDialog={setOpenReportIssue} openDialog={openReportIssue} />
      <UpdateErrorComponent openError={openError} setOpenError={setOpenError} />
    </Box>
  );
};
