import React from "react";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import Button from "../../components/Button/Button";
import { REGISTER_USER_WITH_LINK } from "../Signin/query";
import { Typography, Grid, Box, Stack } from "@mui/material";
import { splitMessageBybraces } from "../../Services/helper";
import { LoginFooter } from "../../components/shared/Login/LoginFooter";
import { CenterStack, PageGrid, StyledCard } from "../../Styles/GlobalStyles";
import SuccessDialogBox from "../Home/Components/NetworkStatus/SuccessDialogBox";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner/LoadingSpinner";
import { UpdateErrorComponent } from "../../components/shared/UpdateErrorComponent/UpdateErrorComponent";

export const AccountNotFound = () => {
  const isAdmin = process.env.REACT_APP_CXP_ADMIN_ACCESS_ENABLED;
  const userError = useSelector((state: any) => state?.userInfo?.error);
  const { userNotInFirestore } = useSelector((state: any) => state?.userInfo);
  const errorMessage = splitMessageBybraces(userError);
  const impersonatedEmail = localStorage.getItem("impersonatedEmail");
  const [emailSent, setEmailSent] = React.useState(false);
  const [emailNotSent, setEmailNotSent] = React.useState(false);
  const [registerUserWithLink, { loading: registerLinkLoading }] = useMutation(
    REGISTER_USER_WITH_LINK,
    {
      fetchPolicy: "network-only",
      onCompleted: () => setEmailSent(true),
      onError: () => setEmailNotSent(true),
    }
  );

  const handleSendEmail = async () => {
    try {
      const response = await registerUserWithLink({
        variables: {
          email: impersonatedEmail,
        },
      });
      if (response?.data) {
        if (!response?.data?.registerUserWithLink?.error) {
          setEmailSent(true);
        } else if (response?.data?.registerUserWithLink?.error) {
          setEmailNotSent(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleFontWeight = (message: any) => {
    if (
      message?.toLowerCase()?.includes("profile not") ||
      message?.toLowerCase()?.includes("not found in dynamics")
    ) {
      return 600;
    } else {
      return 400;
    }
  };

  const handleFontSize = (message: any) => {
    if (
      message?.toLowerCase()?.includes("profile not") ||
      message?.toLowerCase()?.includes("not found in dynamics")
    ) {
      return "20px";
    } else {
      return "16px";
    }
  };

  return (
    <Box>
      <PageGrid container sx={{ paddingBottom: "2rem !important" }}>
        <Grid item xs={12}>
          <StyledCard sx={{ py: 12, mt: 2 }}>
            <CenterStack marginY={"1.2rem"} data-testid="refresh-page" gap={2}>
              {errorMessage?.length > 0 && (
                <>
                  {isAdmin === "FALSE" && !userNotInFirestore && (
                    <Typography component={"p"} textAlign={"center"} data-testid="error-message">
                      Something went wrong !
                    </Typography>
                  )}
                  {errorMessage?.map((message: string) => (
                    <Typography
                      fontWeight={() => handleFontWeight(message)}
                      component={"p"}
                      textAlign={"center"}
                      fontSize={() => handleFontSize(message)}
                      data-testid="error-message">
                      {message}
                    </Typography>
                  ))}
                  {isAdmin === "TRUE" && (
                    <Button type="payment" title="SEND EMAIL" onClick={handleSendEmail} />
                  )}
                </>
              )}
            </CenterStack>
          </StyledCard>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <LoginFooter />
          </Stack>
        </Grid>
      </PageGrid>
      {registerLinkLoading && <LoadingSpinner />}
      <>
        <SuccessDialogBox
          open={emailSent}
          onClose={() => setEmailSent(false)}
          heading="An email has been successfully sent to the user."
          message="Please ask the user to check their inbox and register an account."
        />
      </>
      <UpdateErrorComponent openError={emailNotSent} setOpenError={setEmailNotSent} />
    </Box>
  );
};
