import { Dispatch, SetStateAction } from "react";
import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { GET_TROUBLE_TICKET_STATUS } from "../../Pages/Home/query";
import { CANCEL_TT } from "../../Pages/Home/Components/CancelAppointment/query";
import {
  setAppointmentCancelFailed,
  setAppointmentCancelSuccess,
} from "../../Redux/Slices/OrderDetailsSlice";

export const useCancelTroubleTicket = (setOpenDialog: Dispatch<SetStateAction<boolean>>) => {
  const dispatch = useDispatch();
  const { troubleTicketDetails } = useSelector((state: any) => state.troubleTicketDetails);
  const { accountNumber } = useSelector((state: any) => state.customerInfoDetails);
  const { tickets } = troubleTicketDetails;
  const ticketId = tickets && tickets?.[0]?.ticketId;

  const [cancelTroubleTicket, { loading: loadingCancel }] = useMutation(CANCEL_TT, {
    onError: () => {
      dispatch(setAppointmentCancelFailed(true));
      setOpenDialog(false);
    },
    onCompleted: () => {
      dispatch(setAppointmentCancelSuccess(true));
      setOpenDialog(false);
    },
    refetchQueries: [GET_TROUBLE_TICKET_STATUS],
  });

  const handleCancelTroubleTicket = async () => {
    try {
      const res = await cancelTroubleTicket({
        variables: {
          ticketId: ticketId,
          accountNumber: accountNumber,
        },
      });
      if (res?.data) {
        if (!res?.data?.cancelTroubleTicket?.error) {
          dispatch(setAppointmentCancelSuccess(true));
          setOpenDialog(false);
        } else if (res?.data?.cancelTroubleTicket?.error) {
          dispatch(setAppointmentCancelFailed(true));
          setOpenDialog(false);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return {
    handleCancelTroubleTicket,
    loadingCancel,
  };
};
