import { DialogContent, FormControl, IconButton, Stack, Typography } from "@mui/material";
import Button from "../../../../components/Button/Button";
import { Theme } from "../../../../Styles/GlobalTheme";
import CloseIcon from "@mui/icons-material/Close";
import { validInputSubmit, validationForm } from "../../../../Services/helper";
import {
  CardHeading,
  ErrorMessage,
  ProfileInput,
  StyledDialog,
  StyledElement,
} from "../../../../Styles/GlobalStyles";
import { useSelector } from "react-redux";
import { CountdownTimer } from "../../../../components/shared/CountdownTimer/CountdownTimer";
import { EMAIL_SECURITY_CODE_SUCCESS_SUBTEXTMESSAGE } from "../../../../Constants/constants";
import { useEffect } from "react";

export const VerificationDialog = ({
  verify,
  profileErrors,
  profileForm,
  setProfileErrors,
  setProfileForm,
  handleClose,
  handleResendOTP,
  handleVerifyOTP,
  otpSent,
  timerSeconds,
  verifyDate,
}: any) => {
  const { emailContact, telephoneContact } = useSelector((state: any) => state.customerInfoDetails);
  const contactNumber = telephoneContact?.telephoneNumber ?? "";
  const contactEmail = emailContact?.emailAddress ?? "";

  const handleValidateSecurity = () => {
    if (verify.phone) {
      const errors = validInputSubmit(profileForm);
      if (Object.keys(errors).length > 0) {
        setProfileErrors(errors);
      }
    } else if (verify.email) {
      const errors = validInputSubmit(profileForm);
      if (Object.keys(errors).length > 0) {
        setProfileErrors(errors);
      }
    }
  };
  const handleVerifyButton = () => {
    if (verify.phone && !profileErrors.otpPhone) {
      const otpPhoneError = validationForm(profileForm.otpPhone, "otpPhone", profileForm.otpEmail);
      if (otpPhoneError) {
        setProfileErrors({
          ...profileErrors,
          otpPhone: otpPhoneError,
        });
      } else {
        handleVerifyOTP();
      }
    } else if (verify.email && !profileErrors.otpEmail) {
      const otpEmailError = validationForm(profileForm.otpEmail, "otpEmail", profileForm.otpEmail);
      if (otpEmailError) {
        setProfileErrors({
          ...profileErrors,
          otpEmail: otpEmailError,
        });
      } else {
        handleVerifyOTP();
      }
    }
  };

  const handlePhoneOTPInput = (e: any) => {
    setProfileForm({ ...profileForm, otpPhone: e.target.value });
    setProfileErrors({});
  };

  const handleEmailOTPInput = (e: any) => {
    setProfileForm({ ...profileForm, otpEmail: e.target.value });
    setProfileErrors({});
  };
  useEffect(() => {
    setProfileForm({ ...profileForm, otpPhone: "", otpEmail: "" });
    setProfileErrors({});
  }, []);
  return (
    <>
      <StyledDialog data-testid={"dialog"} fullWidth open={verify.phone || verify.email}>
        <IconButton onClick={handleClose} sx={{ alignSelf: "flex-end" }}>
          <CloseIcon />
        </IconButton>
        <CardHeading display={{ xs: "none", sm: "block" }} textAlign={"center"} mb={2}>
          Verification
        </CardHeading>
        <DialogContent sx={{ width: { sm: "80%", xs: "100%" }, margin: "auto", padding: "0" }}>
          {verify.phone ? (
            <Typography
              my={{ xs: 2, sm: 0 }}
              fontSize={{ xs: "0.8rem", sm: "1rem" }}
              component={"p"}
              variant="subtitle2">
              Enter the security code sent to&nbsp;
              {profileForm?.phoneNumber?.length > 0
                ? profileForm?.phoneNumber?.replace(/[() -]/g, "")
                : contactNumber}
            </Typography>
          ) : (
            <Typography
              my={{ xs: 2, sm: 0 }}
              fontSize={{ xs: "0.8rem", sm: "1rem" }}
              component={"p"}
              variant="subtitle2">
              Enter the security code sent to&nbsp;
              {profileForm?.email?.length > 0 ? profileForm?.email : contactEmail}
            </Typography>
          )}

          <FormControl fullWidth>
            <Typography component={"p"} mb={1} fontSize={{ xs: "0.6rem", sm: "0.8rem" }}>
              Security code
            </Typography>
            {verify.phone ? (
              <>
                <ProfileInput
                  onBlur={handleValidateSecurity}
                  name="security"
                  inputProps={{ "data-testid": "phoneOtpInput" }}
                  value={profileForm?.otpPhone}
                  hasError={profileErrors?.otpPhone ? true : false}
                  placeholder="6 digit"
                  onChange={handlePhoneOTPInput}
                  id="outlined-adornment-phone-number"
                />
                {profileErrors?.otpPhone && (
                  <ErrorMessage error={profileErrors?.otpPhone ? true : false}>
                    {profileErrors?.otpPhone}
                  </ErrorMessage>
                )}
              </>
            ) : (
              <>
                <ProfileInput
                  onBlur={handleValidateSecurity}
                  name="security"
                  inputProps={{ "data-testid": "emailOtpInput" }}
                  value={profileForm?.otpEmail}
                  hasError={profileErrors?.otpEmail ? true : false}
                  onChange={handleEmailOTPInput}
                  id="outlined-adornment-phone-number"
                />
                {profileErrors?.otpEmail && (
                  <ErrorMessage error={profileErrors?.otpEmail ? true : false}>
                    {profileErrors?.otpEmail}
                  </ErrorMessage>
                )}
              </>
            )}
          </FormControl>
          <Stack direction={"row"} justifyContent={"flex-end"} gap={1} alignItems={"center"}>
            {timerSeconds ? (
              <>
                <Typography
                  component={"span"}
                  fontSize={{ xs: "10px", sm: "12px" }}
                  color={Theme.palette.customcolor.profileTypoGraphyColor}>
                  RESEND CODE IN &nbsp;
                  <CountdownTimer minutes={5} />
                </Typography>
              </>
            ) : (
              <StyledElement
                fontSize={{ xs: "10px", sm: "12px" }}
                data-testid={"resendOtp"}
                py={"0.5rem"}
                onClick={handleResendOTP}>
                RESEND CODE
              </StyledElement>
            )}
          </Stack>

          {otpSent && (
            <>
              <Stack direction={"column"} justifyContent={"center"}>
                <Typography
                  data-testid="otpSent"
                  textAlign={"center"}
                  variant="caption"
                  component={"span"}
                  color={Theme.palette.primary.main}>
                  {otpSent}
                </Typography>

                {verify.email && (
                  <Typography
                    data-testid="otpSent"
                    textAlign={"center"}
                    variant="caption"
                    component={"span"}
                    fontStyle={"italic"}
                    color={Theme.palette.primary.main}>
                    {EMAIL_SECURITY_CODE_SUCCESS_SUBTEXTMESSAGE}
                  </Typography>
                )}
              </Stack>
            </>
          )}
          <Stack my={2} justifyContent={"center"} direction={"row"}>
            <Button
              type="root"
              eventName={verify?.phone ? "VERIFY_PHONE" : "VERIFY_EMAIL"}
              title="verify"
              onClick={handleVerifyButton}
            />
          </Stack>
        </DialogContent>
      </StyledDialog>
    </>
  );
};
