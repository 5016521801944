import React from "react";
import { Typography } from "@mui/material";
import { ColumnCenterStack, ProfileTypography } from "../../Styles/GlobalStyles";
import Button from "../../components/Button/Button";

export const AccountType = ({ heading, subHeading, onClick, src, href }: any) => {
  return (
    <>
      <ColumnCenterStack gap={1}>
        <img src={src} alt="account-icon" />
        <Typography fontSize={"18px"} fontWeight={600}>
          {heading}
        </Typography>
        <ProfileTypography>{subHeading}</ProfileTypography>
        {window.location.pathname.includes("/lac") ? (
          <a href={href} target="_self" rel="noreferrer">
            <Button title="proceed" type="root" onClick={onClick} />
          </a>
        ) : (
          <a href={href} target="_blank" rel="noreferrer">
            <Button title="proceed" type="root" onClick={onClick} />
          </a>
        )}
      </ColumnCenterStack>
    </>
  );
};
