import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orderAndServiceTicketHistory: [],
  orderAndServiceTicketHistoryApiError: false,
};

const orderAndServiceTicketHistorySlice = createSlice({
  name: "orderAndServiceTicketHistory",
  initialState,
  reducers: {
    setOrderAndServiceTicketHistory: (state, action) => {
      state.orderAndServiceTicketHistory = action.payload;
    },
    setOrderAndServiceTicketHistoryApiError: (state, action) => {
      state.orderAndServiceTicketHistoryApiError = action.payload;
    },
  
  },
});

export const {
  setOrderAndServiceTicketHistory,
  setOrderAndServiceTicketHistoryApiError,
} = orderAndServiceTicketHistorySlice.actions;
export const orderAndServiceTicketHistorySliceReducer = orderAndServiceTicketHistorySlice.reducer;
