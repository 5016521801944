import React from "react";
import { Theme } from "../../Styles/GlobalTheme";
import { Stack, Typography } from "@mui/material";
import { StyledA, StyledCard, StyledSpanElement } from "../../Styles/GlobalStyles";

declare global {
  interface Window {
    triggerOmniChat: any;
    minimizeChat: any;
  }
}

function ImportantUpdatesBanner({ content }: any) {
  const message = content?.paymentMessage ?? "";
  const [firstPart, middlePart, lastPart] = message.split(/("Chat")/);

  const autoPaySubDescription = content?.autoPaySubDiscription ?? "";
  const match = autoPaySubDescription.match(/\[(.*?)\]/);
  const extractedText = match ? match[1] : "";
  const parts = autoPaySubDescription.split(/\[.*?\]/);

  return (
    <StyledCard
      sx={{
        padding: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}>
      <Stack spacing={1} alignItems="center" direction="row" flexGrow={1}>
        {content.icon}
        <Stack gap={{ xs: 1, md: 0 }}>
          <Typography fontWeight={600} fontSize={{ xs: "14px", sm: "18px" }}>
            {content.title}
          </Typography>
          <Stack direction="row" alignItems="center" flexWrap="wrap">
            {content?.message && (
              <Typography fontSize={{ xs: "12px", sm: "14px" }}>{content?.message}</Typography>
            )}
            {content?.paymentMessage && (
              <Typography fontSize={{ xs: "12px", sm: "14px" }}>
                {firstPart}
                <StyledSpanElement
                  style={{ fontWeight: "bold" }}
                  onClick={() => window.triggerOmniChat && window.triggerOmniChat("D582223DCFE61")}>
                  {middlePart}
                </StyledSpanElement>
                {lastPart}
              </Typography>
            )}

            {content?.autoPay && (
              <Typography sx={{ mr: "4px" }}>{content.chatSupportText}</Typography>
            )}

            {content?.description && (
              <Typography
                sx={{ fontSize: { xs: "12px", sm: "14px" } }}
                color={Theme.palette.customcolor.profileTypoGraphyColor}>
                {content?.description}&nbsp;
              </Typography>
            )}

            {content?.autopayDescription && content?.autoPaySubDiscription && (
              <>
                <Typography
                  sx={{ fontSize: { xs: "12px", sm: "14px" } }}
                  color={Theme.palette.customcolor.profileTypoGraphyColor}>
                  {content?.autopayDescription}&nbsp;
                </Typography>
                <Typography
                  sx={{ fontSize: { xs: "12px", sm: "14px" } }}
                  color={Theme.palette.customcolor.profileTypoGraphyColor}>
                  {parts[0]}
                  {extractedText && (
                    <StyledSpanElement
                      style={{ fontWeight: "bold" }}
                      onClick={() =>
                        window.triggerOmniChat && window.triggerOmniChat("D582223DCFE61")
                      }>
                      {extractedText?.toUpperCase()}
                    </StyledSpanElement>
                  )}
                  {parts[1]}
                </Typography>
              </>
            )}

            {content.linkText && content.link && (
              <StyledA
                sx={{ fontWeight: 600, textDecoration: "underline" }}
                target="_blank"
                href={content.link}>
                {content.linkText}
              </StyledA>
            )}

            {!content?.autoPay && <Typography>{content.chatSupportText}</Typography>}
          </Stack>
        </Stack>
      </Stack>
    </StyledCard>
  );
}

export default ImportantUpdatesBanner;
