import { CardHeading, DialogTypography, StyledDialog } from "../../../Styles/GlobalStyles";
import { IconButton, Stack, TextField, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ContactSupport from "../ContactSupport/ContactSupport";
import { useCbst } from "../../../hooks/Cbst/useCbst";
import { Spinner } from "../LoadingSpinner/Spinner";
import { useNavigate } from "react-router-dom";
import { setIsRequestIdStored, setStoreRequestID } from "../../../Redux/Slices/PaymentSlice";

function ReportIssue(props: any) {
  const { openDialog, setOpenDialog, successMessage } = props;
  const { paymentConfirmationMessage } = useSelector((state: any) => state.customerPlanDetails);
  const [reportSent, setReportSent] = useState(false);
  const [feedbackScreen, setFeedbackScreen] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [error, setError] = useState(false);
  const { cbstKeyLoading, fetchCbstKey, cbstLoading } = useCbst(feedback);
  const { ApiFailureName } = useSelector((state: any) => state.paymentDetails);
  const { requestId, isRequestIdStored } = useSelector((state: any) => state.paymentDetails);
  const dispatch = useDispatch();
  const handleSendFeedback = () => {
    setError(false);
    setReportSent(true);
    fetchCbstKey();
    if (ApiFailureName === "getPaymentMethodsForManagePayments") {
      if (!isRequestIdStored) {
        dispatch(setStoreRequestID(requestId));
        dispatch(setIsRequestIdStored(true));
      }
    }
  };
  const navigate = useNavigate();
  const closePopUp = () => {
    setOpenDialog(false);
    setReportSent(false);
    setFeedbackScreen(false);
    setFeedback("");
    if (ApiFailureName === "linkAccount") {
      navigate("/login");
    }
  };

  const handleReportIssue = () => {
    setFeedbackScreen(true);
    setFeedback("");
  };

  const handleFeedbackChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFeedback(e.target.value);
  };
  useEffect(() => {
    setFeedback("");
  }, []);

  return (
    <StyledDialog data-testid="usermessage" fullWidth open={openDialog}>
      {!reportSent && (
        <IconButton
          data-testid="close-button"
          sx={{ alignSelf: "flex-end", padding: 0 }}
          onClick={closePopUp}>
          <CloseIcon />
        </IconButton>
      )}

      {(feedbackScreen || ApiFailureName === "getPaymentMethods") && !reportSent ? (
        <Stack px={2}>
          <CardHeading mb={1}>Feedback or Notes</CardHeading>
          <TextField
            placeholder="Write any feedback or Notes about the Issue"
            multiline
            minRows={4}
            fullWidth
            value={feedback}
            onChange={handleFeedbackChange}
            sx={{
              fontSize: "14px",
              borderRadius: "4px",
            }}
          />
          <Stack mt={2} mb={4} direction={"row"} justifyContent={"center"}>
            <Button title={"SEND FEEDBACK"} type={"root"} onClick={handleSendFeedback} />
          </Stack>
        </Stack>
      ) : !feedbackScreen && !reportSent ? (
        <Stack
          textAlign={"center"}
          alignItems={"center"}
          px={paymentConfirmationMessage ? "26px" : ""}>
          <CardHeading>Something went wrong!</CardHeading>
          <Stack my={1.5} spacing={2} justifyContent={"center"} alignItems={"center"}>
            <DialogTypography>
              Unable to complete your request at this moment.
              <Stack direction={"row"} spacing={1} justifyContent={"center"}>
                <DialogTypography>If the issue persists. Please</DialogTypography>
                <ContactSupport fontSize={"16px"} />
              </Stack>
            </DialogTypography>
            <DialogTypography>
              Would you like to send the error report to us so we can investigate the issue further?
            </DialogTypography>
          </Stack>
          <Stack mt={2} mb={4} direction={"row"} gap={"16px"} justifyContent={"center"}>
            <Button title={"REPORT ISSUE"} type={"root"} onClick={handleReportIssue} />
            <Button title={"CLOSE"} type={"root"} onClick={closePopUp} />
          </Stack>
        </Stack>
      ) : (
        <>
          {cbstKeyLoading || cbstLoading ? (
            <Box my={8}>
              <Spinner />
            </Box>
          ) : (
            <Stack textAlign={"center"} alignItems={"center"}>
              <CardHeading>Issue reported</CardHeading>
              <Stack my={2} spacing={2} justifyContent={"center"} alignItems={"center"}>
                <DialogTypography>
                  Error report has been successfully sent to our team. This will help us analyze the
                  issue and improve the service.
                </DialogTypography>
              </Stack>
              <Stack my={1} justifyContent={"center"}>
                <Button title={"CLOSE"} type={"root"} onClick={closePopUp} />
              </Stack>
            </Stack>
          )}
        </>
      )}
    </StyledDialog>
  );
}

export default ReportIssue;
