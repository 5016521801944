import React from "react";
import {
  CardHeading,
  PageGrid,
  StickyBox,
  StyledCard,
  StyledElement,
} from "../../../../Styles/GlobalStyles";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { NeedHelpCard } from "../../../../components/shared/NeedHelpCard/NeedHelpCard";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ServiceTicketHistoryList from "./components/ServiceTicketHistoryList";
import { useOrderAndServiceTicketHistory } from "../../../../hooks/Accounts/useOrderAndServiceTicketHistory";
import { useSelector } from "react-redux";
import { LoadingSpinner } from "../../../../components/shared/LoadingSpinner/LoadingSpinner";
import { ErrorComponent } from "../../../../components/ErrorComponent/ErrorComponent";

interface OrderAndTroubleTicketHistoryObject {
  __typename?: string;
  id?: string;
  status?: string;
  createdDate?: string;
  type?: string;
  colorCode?: string;
  completionDate?: string | null;
}

export default function OrderAndServiceTicket() {
  const navigate = useNavigate();
  const { accountNumber, ndsObj } = useSelector((state: any) => state.customerInfoDetails);
  const { orderAndServiceTicketHistory, orderAndServiceTicketHistoryApiError } = useSelector(
    (state: any) => state.orderAndServiceTicketHistory
  );
  const { getOrderAndTroubleTicketHistory, orderAndServiceTicketHistoryLoading } =
    useOrderAndServiceTicketHistory();

  const { scrollPosition } = useSelector((state: any) => state.pageInfo);

  React.useEffect(() => {
    accountNumber && getOrderAndTroubleTicketHistory();
  }, [accountNumber, ndsObj]);

  function handleAccountRedirect() {
    navigate("/account");
  }
  return (
    <>
      <PageGrid container>
        <Grid item xs={12} lg={8.3}>
          <Grid>
            <Box>
              <Stack direction={"row"} alignItems={"center"}>
                <StyledElement marginTop={0.5} onClick={handleAccountRedirect}>
                  <ArrowBackIosIcon />
                </StyledElement>

                <CardHeading fontWeight={600}>Order And Service Ticket History</CardHeading>
              </Stack>
              <StyledCard>
                {orderAndServiceTicketHistoryApiError && !orderAndServiceTicketHistoryLoading ? (
                  <ErrorComponent onRefresh={getOrderAndTroubleTicketHistory} />
                ) : orderAndServiceTicketHistory?.length === 0 &&
                  !orderAndServiceTicketHistoryLoading ? (
                  <Stack justifyContent={"center"} alignItems={"center"} my={20}>
                    <Typography fontSize={{ xs: "14px", md: "18px" }}>
                      Looks like you don't have any past orders or service tickets.
                    </Typography>
                    <Typography fontSize={{ xs: "10px", md: "14px" }}>
                      Your previous orders and tickets will appear here, you can track them
                      effortlessly.
                    </Typography>
                  </Stack>
                ) : (
                  !orderAndServiceTicketHistoryLoading &&
                  orderAndServiceTicketHistory?.map(
                    (
                      data: OrderAndTroubleTicketHistoryObject,
                      idx: number,
                      arr: OrderAndTroubleTicketHistoryObject[]
                    ) => {
                      return (
                        <ServiceTicketHistoryList
                          showDivider={arr.length - 1 === idx ? false : true}
                          data={data}
                        />
                      );
                    }
                  )
                )}
              </StyledCard>
            </Box>
          </Grid>
        </Grid>
        <Grid item display={{ xs: "block", lg: "none" }} xs={12}>
          <NeedHelpCard />
        </Grid>
      </PageGrid>
      <StickyBox pt={1.5} display={{ xs: "none", lg: scrollPosition > 50 ? "none" : "block" }}>
        <NeedHelpCard />
      </StickyBox>

      {orderAndServiceTicketHistoryLoading && <LoadingSpinner />}
    </>
  );
}
