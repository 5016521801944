import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { StyledDialog } from "../../../../Styles/GlobalStyles";
import { DialogContent, Stack, Typography, IconButton, useMediaQuery, Box } from "@mui/material";
import { Spinner } from "../../../../components/shared/LoadingSpinner/Spinner";
import { Theme } from "../../../../Styles/GlobalTheme";

const PlanDetailsDialog = ({ open, onClose, planDetails, planDetailsLoading }: any) => {
  const isSmallScreen = useMediaQuery(Theme.breakpoints.down("sm"));
  const planDescription =
    planDetails?.productName?.toLowerCase()?.includes("5 gig") ||
    planDetails?.productName?.toLowerCase()?.includes("10 gig")
      ? planDetails?.short_description
      : planDetails?.legal_description;

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-describedby="modal-modal-description"
      fullScreen={isSmallScreen}>
      <IconButton
        data-testid="close-button"
        sx={{ alignSelf: "flex-end", padding: 0 }}
        onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {planDetails && !planDetailsLoading ? (
          <Stack justifyContent="center" alignItems="center" mb={3}>
            <Typography
              id="modal-modal-title"
              fontWeight={500}
              component="h1"
              color={Theme.palette.primary.main}
              fontSize={{ xs: "20px", md: "22px" }}
              textAlign="center">
              {planDetails?.productName}
            </Typography>
            <Typography
              variant="subtitle1"
              mb={2}
              color={Theme.palette.primary.main}
              fontSize={{ xs: "14px", md: "18px" }}>
              {planDetails?.legal_sub_heading}
            </Typography>
            <Stack spacing={1}>
              <Box>
                <Typography
                  variant="body2"
                  dangerouslySetInnerHTML={{
                    __html: planDescription,
                  }}
                />
              </Box>
            </Stack>
          </Stack>
        ) : (
          <Box my={25} mx={30}>
            <Spinner />
          </Box>
        )}
      </DialogContent>
    </StyledDialog>
  );
};

export default PlanDetailsDialog;
