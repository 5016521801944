import CircularProgress from "@mui/material/CircularProgress";
import { Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";

interface LoadingTextI {
  loadingText?: string;
}

export const Spinner = ({ loadingText = "One moment please." }: LoadingTextI) => {
  const [lagMessage, setLagMessage] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setLagMessage(true);
    }, 3000);
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <CircularProgress />
        {lagMessage && (
          <Typography my={1} variant="body2" sx={{ color: "black", textAlign: "center" }}>
            {loadingText}
          </Typography>
        )}
      </Box>
    </>
  );
};
