import React, { useState, useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { StyledCustomizedButton } from "../../../Styles/GlobalStyles";
import CurrentBillInfo from "../../../Pages/Billing/Components/BillingInformation/CurrentBill/CurrentBill";
import PastBill from "../../../Pages/Billing/Components/BillingInformation/PastBill/PastBill";
import { useDispatch, useSelector } from "react-redux";
import {
  setCompareBill,
  setCompareBillTable,
  setCurrentBill,
  setPastBills,
  setShowTabs,
  setVoipBill,
} from "../../../Redux/Slices/BillingInfoSlice";
import { CompareBillTable } from "../../../Pages/Billing/Components/BillingInformation/CompareBill/CompareBillTable/CompareBillTable";
import { CompareBills } from "../../../Pages/Billing/Components/BillingInformation/CompareBill/CompareBills/CompareBills";
import Button from "../../Button/Button";
import { ErrorComponent } from "../../ErrorComponent/ErrorComponent";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { EmptyDataComponent } from "../EmptyDataComponent/EmptyDataComponent";
import { NO_BILL_INFORMATION } from "../../../Constants/constants";
import VoipBillInfo from "../../../Pages/Billing/Components/BillingInformation/VoipBill/VoipBillInfo";
import VoipBillDPI from "../../../Pages/Billing/Components/BillingInformation/VoipBill/VoipNonNds";
import { getFlags } from "../../../Services/helper";

export const CustomTabs = ({
  names,
  billStatementsApiData,
  getbillStatements,
  billStatementLoading,
  billStatementsError,
  VOIPServiceLoading,
  voipEnabled,
}: any) => {
  const dispatch = useDispatch();
  const [billStatements, setBillStatements] = useState([]);
  const { currentDueBalance } = useSelector((state: any) => state.customerPlanDetails);
  const { showTabs, currentBill, compareBill, pastBills, compareBillTable, voipBill } = useSelector(
    (state: any) => state.billingInfo
  );
  const envFlags = useSelector((state: any) => state?.userInfo?.envFlags);
  const VOIP_FEATURE_FLAG = getFlags("voip", envFlags);

  const { accountNumber, ndsObj, activeAccount } = useSelector(
    (state: any) => state.customerInfoDetails
  );

  const billingStatementData = billStatementsApiData?.billStatements?.billStatements;
  const [selectedDate, setSelectedDate] = useState<any>([]);
  const dueBalance = currentDueBalance === null ? 0 : currentDueBalance;
  const primary = names[0]?.toLocaleUpperCase();
  const secondary = names[1]?.toLocaleUpperCase();
  const voipbill = names[2]?.toLocaleUpperCase();

  const handleCurrentBill = () => {
    dispatch(setCurrentBill(true));
    dispatch(setPastBills(false));
    dispatch(setVoipBill(false));
  };

  const handlePastBill = () => {
    dispatch(setCurrentBill(false));
    dispatch(setPastBills(true));
    dispatch(setVoipBill(false));
  };

  const handleVoipBill = () => {
    // ndsObj?.isNDS && VOIPInvoiceDetails();
    dispatch(setVoipBill(true));
    dispatch(setCurrentBill(false));
    dispatch(setPastBills(false));
  };
  const handleCompreBill = () => {
    getbillStatements({
      variables: {
        billsRequired: null,
        isCurrentBill: false,
        accountNumber: accountNumber,
      },
    });
    dispatch(setShowTabs(true));
    if (!currentBill) {
      dispatch(setCurrentBill(true));
    } else if (pastBills) {
      dispatch(setPastBills(!pastBills));
    }
    dispatch(setCompareBill(false));
    setSelectedDate([]);
  };

  const EMPTYCOMPAREBILLDATA = billingStatementData?.length === 0;

  const handleRefreshCompareBills = () => {
    getbillStatements();
  };

  const handleCompareBillAccoridan = () => {
    dispatch(setCompareBillTable(!compareBillTable));
    dispatch(setCompareBill(!compareBill));
  };

  const hideCurrentBill =
    activeAccount === false && (parseInt(currentDueBalance) <= 0 || currentDueBalance === null);

  useEffect(() => {
    if (billingStatementData) {
      setBillStatements(billingStatementData);
    }
  }, [billingStatementData]);

  useEffect(() => {
    if (hideCurrentBill) {
      dispatch(setCurrentBill(false));
      dispatch(setPastBills(true));
    } else {
      dispatch(setCurrentBill(true));
      dispatch(setPastBills(false));
    }
  }, [hideCurrentBill]);

  return (
    <>
      {showTabs && (
        <Box width={"100%"} display={"flex"}>
          <StyledCustomizedButton isActive={pastBills} onClick={handlePastBill}>
            {primary}
          </StyledCustomizedButton>
          {!hideCurrentBill && (
            <StyledCustomizedButton isActive={currentBill} onClick={handleCurrentBill}>
              {secondary}
            </StyledCustomizedButton>
          )}
          {process.env.REACT_APP_SHOW_VOIP_FEATURE === "TRUE" &&
            VOIP_FEATURE_FLAG &&
            activeAccount !== false && (
              <>
                {voipEnabled && !VOIPServiceLoading && (
                  <StyledCustomizedButton isActive={voipBill} onClick={handleVoipBill}>
                    {voipbill}
                  </StyledCustomizedButton>
                )}
              </>
            )}
        </Box>
      )}
      {!pastBills && !voipBill && currentBill && !hideCurrentBill ? (
        <CurrentBillInfo />
      ) : voipBill ? (
        ndsObj?.isNDS ? (
          <VoipBillInfo />
        ) : (
          <VoipBillDPI />
        )
      ) : (
        pastBills && (
          <PastBill
            billStatementsError={billStatementsError}
            billStatementLoading={billStatementLoading}
            billStatementsApiData={billStatementsApiData}
            getbillStatements={getbillStatements}
            billingStatementData={billStatements}
          />
        )
      )}

      {compareBill && !pastBills && !currentBill && (
        <>
          <Box>
            <Stack direction={"row"} alignItems={"center"} pb={"1rem"} onClick={handleCompreBill}>
              <ArrowBackIosNewOutlinedIcon color="secondary" />
              <Typography component={"span"} fontWeight={"600"} ml={2} variant="h6">
                Compare bill &nbsp;
              </Typography>
            </Stack>
            {billStatementsApiData?.billStatements?.error || billStatementsError ? (
              <ErrorComponent onRefresh={handleRefreshCompareBills} />
            ) : EMPTYCOMPAREBILLDATA ? (
              <Box my={20}>
                <EmptyDataComponent message={NO_BILL_INFORMATION} />
              </Box>
            ) : (
              <>
                <CompareBills
                  billStatementLoading={billStatementLoading}
                  billingStatementData={billingStatementData}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
                {!billStatementLoading && (
                  <Button
                    title="COMPARE SELECTED BILLS"
                    onClick={handleCompareBillAccoridan}
                    type="root"
                    disabled={selectedDate?.length < 2}
                  />
                )}
              </>
            )}
          </Box>
        </>
      )}

      {compareBillTable && !pastBills && !currentBill && !compareBill && (
        <>
          <Stack
            direction={"row"}
            alignItems={"center"}
            onClick={handleCompareBillAccoridan}
            py={1}>
            <ArrowBackIosNewOutlinedIcon color="secondary" />
            <Typography component={"span"} fontWeight={"600"} ml={2} variant="h6">
              Compare bill
            </Typography>
          </Stack>
          <CompareBillTable selectedDate={selectedDate} />
        </>
      )}
    </>
  );
};
