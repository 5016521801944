import { useState } from "react";
import { Stack, Box, Typography } from "@mui/material";
import { CardI } from "../../../../Pages/Account/Components/KeyAccountInfo/KeyAccountInfo";
import { useDispatch, useSelector } from "react-redux";
import {
  setDeeplinkAccNum,
  setInactiveAccounts,
  setNDS,
  switchAccountData,
  unlinkAccounts,
} from "../../../../Redux/Slices/CustomerInfoSlice";
import {
  AccountardText,
  AccountCard,
  AccountDivider,
  AutoPayDivider,
  DividerSmall,
  PTypography,
  RowCenterStack,
  StyledA,
} from "../../../../Styles/GlobalStyles";
import { StyledElement } from "../../../../Styles/GlobalStyles";
import { Theme } from "../../../../Styles/GlobalTheme";
import { SwitchAccountCard } from "../../SwitchAccountCard/SwitchAccountCard";
import { UNLINK_ACCOUNT } from "../../../../Pages/Account/query";
import { useMutation } from "@apollo/client";
import {
  EVENT_UNLINK_ACCOUNT,
  SWITCH_ACCOUNT_PRIMARYCONTENT,
  SWITCH_ACCOUNT_SECONDARYCONTENT,
  UNLINK_ACCOUNT_HEADING,
  UNLINK_ACCOUNT_PRIMARYCONTENT,
  UNLINK_SUCCESS_MESSAGE,
} from "../../../../Constants/constants";
import { ConfirmationModel } from "../../ConfirmationModel/ConfirmationModel";
import { UpdateErrorComponent } from "../../UpdateErrorComponent/UpdateErrorComponent";
import { LoadingSpinner } from "../../LoadingSpinner/LoadingSpinner";
import { SuccessMessageModel } from "../../SuccessMessageModel/SuccessMessageModel";
export interface CardDataI {
  card: CardI;
  setTotalBalance?: any;
  totalBalance?: any;
  isBusiness?: boolean;
  handleGlobysUrl?: any;
  inactiveAccount?: boolean;
}

export const CustomAccountCard = ({
  card,
  setTotalBalance,
  totalBalance,
  isBusiness,
  handleGlobysUrl,
  inactiveAccount,
}: CardDataI) => {
  const customerDetails = useSelector((state: any) => state?.customerInfoDetails);
  const { openSwitchModal } = useSelector((state: any) => state?.appProposal);
  const isMultiAcc = sessionStorage.getItem("isMultiAccount");
  const [open, setOpen] = useState<boolean>(false);
  const [openError, setOpenError] = useState(false);
  const [unlink, setUnlink] = useState(false);
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const [unlinkAccount, setUnlinkAccount] = useState({ accountNumber: "", pin: "" });
  const [dialogData, setDialogData] = useState<any>({
    heading: "",
    primaryContent: "",
    secondaryContent: "",
    containedButton: { onClick: () => {}, buttonType: "", buttonTitle: "" },
  });
  const [unlinkAnAccount, { loading: unlinkLoading }] = useMutation(UNLINK_ACCOUNT, {
    onError: () => setOpenError(true),
  });

  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const selectAccount = (item: any) => {
    const customerInfo = {
      linkedAccountNumbers: customerDetails?.linkedAccountNumbers,
      accountNumber: item?.accountNumber,
    };
    dispatch(setDeeplinkAccNum(""));
    dispatch(switchAccountData(customerInfo));
    item && dispatch(setNDS(item));
    localStorage.setItem("accountNumber", item?.accountNumber);
  };

  const handleDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleSelectCard = () => {
    selectAccount(card);
  };

  const handleUninkDialog = (item: any) => {
    const setPin = () => {
      const data = customerDetails?.linkedAccountNumbers?.find(
        (account: any) => account?.accountNumber === item?.accountNumber
      );
      return data;
    };
    if (customerDetails?.accountNumber !== item?.accountNumber) {
      setUnlinkAccount({
        ...unlinkAccount,
        accountNumber: setPin()?.accountNumber,
        pin: setPin()?.pin,
      });
      setUnlink(true);
      setDialogData({
        ...dialogData,
        heading: UNLINK_ACCOUNT_HEADING,
        primaryContent: UNLINK_ACCOUNT_PRIMARYCONTENT,
        secondaryContent: "",
        containedButton: {
          ...dialogData?.containedButton,
          buttonType: "root",
          buttonTitle: "UNLINK ACCOUNT",
        },
      });
    } else {
      setOpen(true);
      setDialogData({
        ...dialogData,
        heading: UNLINK_ACCOUNT_HEADING,
        primaryContent: SWITCH_ACCOUNT_PRIMARYCONTENT,
        secondaryContent: SWITCH_ACCOUNT_SECONDARYCONTENT,
        containedButton: {
          ...dialogData?.containedButton,
          buttonType: "root",
          buttonTitle: "VIEW ANOTHER ACCOUNT",
        },
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setUnlink(false);
  };

  const handleSwitch = () => {
    window.scrollTo(0, 0);
    setOpen(false);
  };

  const handleUnlinkAccount = async () => {
    setUnlink(false);
    const response = await unlinkAnAccount({
      variables: {
        pin: unlinkAccount?.pin,
        accountNumber: unlinkAccount?.accountNumber,
        contactId: customerDetails?.contactId,
        eventName: EVENT_UNLINK_ACCOUNT,
      },
    });
    if (response?.data?.unlinkAccount?.error) {
      setUnlink(false);
      setOpenError(true);
    } else if (response?.data) {
      const filterData = customerDetails?.linkedAccountNumbers?.filter(
        (item: any) => item?.accountNumber !== unlinkAccount?.accountNumber
      );
      dispatch(unlinkAccounts(filterData));
      const inactiveAccounts = filterData?.filter((account: any) => account?.isActive === false);
      dispatch(setInactiveAccounts(inactiveAccounts));
      setSuccessMessage(!response?.data?.unlinkAccount?.error);
      handleClose();
    }
  };

  const hyphenSeperatedAccNum = card?.accountNumber?.replace(
    /^(\d{3})(\d{3})(\d{4})(\d{6})(\d)$/,
    "$1-$2-$3-$4-$5"
  );
  return (
    <>
      <AccountCard
        sx={{
          border:
            card?.accountNumber === customerDetails?.accountNumber
              ? "2px solid green"
              : "transparent",
        }}>
        <Stack height={card?.isActive === false ? "4rem" : "none"} m={"4px 16px 0px 6px"}>
          <RowCenterStack color={Theme.palette.customcolor.profileTypoGraphyColor}>
            <PTypography>Account number</PTypography>
            {!isBusiness && <PTypography>PIN</PTypography>}
          </RowCenterStack>
          <RowCenterStack>
            <Typography
              fontSize={{ xs: "12px", md: "16px" }}
              component={"span"}
              data-testid="account-number">
              {hyphenSeperatedAccNum}
            </Typography>
            <Typography fontSize={{ xs: "12px", md: "16px" }} component={"span"} data-testid="pin">
              {card?.pin}
            </Typography>
          </RowCenterStack>
          {card?.isActive === false &&
            !!card?.currentDueBalance &&
            parseInt(card?.currentDueBalance) > 0 && (
              <Typography fontSize={{ xs: "12px", md: "14px" }} color={Theme.palette.primary.main}>
                Pending balance on this account
              </Typography>
            )}
        </Stack>
        <AutoPayDivider />
        <RowCenterStack>
          {card?.name && <Typography>{card?.name}</Typography>}
          {card?.accountNumber === customerDetails?.accountNumber ? (
            <AccountardText
              fontSize={{ xs: "14px", sm: "16px" }}
              onClick={handleSelectCard}
              textcolor="green"
              data-testid="view">
              Currently viewing
            </AccountardText>
          ) : isMultiAcc && isBusiness ? (
            <>
              <StyledA
                onClick={handleGlobysUrl}
                target={
                  window.location.pathname.includes("lac") && isMultiAcc && isBusiness
                    ? "_self"
                    : "_blank"
                }
                href={
                  window.location.pathname.includes("lac") && isMultiAcc && isBusiness
                    ? "/lac/commercial"
                    : isMultiAcc && isBusiness
                    ? "/myaccount/commercial"
                    : ""
                }>
                <StyledElement fontSize={{ xs: "12px", sm: "14px" }} data-testid="select">
                  MANAGE BUSINESS ACCOUNT
                </StyledElement>
              </StyledA>
            </>
          ) : (
            <>
              <AccountardText
                fontSize={{ xs: "12px", sm: "14px" }}
                data-testid="select"
                onClick={handleDialog}>
                VIEW ACCOUNT
              </AccountardText>
              {!openSwitchModal && (
                <AccountardText
                  fontSize={{ xs: "12px", sm: "14px" }}
                  onClick={() => handleUninkDialog(card)}
                  data-testid="unlink">
                  UNLINK ACCOUNT
                </AccountardText>
              )}
            </>
          )}
        </RowCenterStack>
      </AccountCard>
      <SwitchAccountCard
        totalBalance={totalBalance}
        setTotalBalance={setTotalBalance}
        card={card}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        selectAccount={selectAccount}
      />
      {open && (
        <ConfirmationModel
          openDialog={open}
          setOpenDialog={setOpen}
          dialogData={dialogData}
          setDialogData={setDialogData}
          onClick={handleSwitch}
        />
      )}
      {unlink && (
        <ConfirmationModel
          openDialog={unlink}
          setOpenDialog={setUnlink}
          dialogData={dialogData}
          accountNumber={unlinkAccount?.accountNumber}
          setDialogData={setDialogData}
          onClick={handleUnlinkAccount}
        />
      )}
      {successMessage && (
        <SuccessMessageModel
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          userStatus={UNLINK_SUCCESS_MESSAGE}
        />
      )}
      {openError && <UpdateErrorComponent openError={openError} setOpenError={setOpenError} />}
      {unlinkLoading && <LoadingSpinner />}
    </>
  );
};
