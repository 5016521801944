// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { PROD_ENV } from "../Constants/constants";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

let firebaseConfig;
if (process.env.REACT_APP_ENV === PROD_ENV) {
  firebaseConfig = {
    apiKey: "AIzaSyCFAOt-SCYuTYaBFUUS7_yg0iPHK034IOs",
    authDomain: "myaccount-myziply.firebaseapp.com",
    projectId: "myaccount-myziply",
    storageBucket: "myaccount-myziply.appspot.com",
    messagingSenderId: "197773136598",
    appId: "1:197773136598:web:f0a8fc5212ccd0f3aa6f77",
    measurementId: "G-Y3W3LP27JK",
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyDW9uD7n2Yj55FCzdu2yb2FF-eVLXVuSBs",
    authDomain: "devmyaccount-myziply.firebaseapp.com",
    projectId: "devmyaccount-myziply",
    storageBucket: "devmyaccount-myziply.appspot.com",
    messagingSenderId: "866099694068",
    appId: "1:866099694068:web:a68e8a7d7891c7781011a1",
    measurementId: "G-TQ97J15CYB",
  };
}
// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const analytics = getAnalytics(app);
