import React from "react";
import { StyledElement } from "../../../Styles/GlobalStyles";

function ContactSupport({
  text = "contact support",
  fontSize = "14px",
  fontSizeMobile = "14px",
  messageFont = false,
  fontWeight = 0,
}) {
  return (
    <div>
      <StyledElement
        fontWeight={fontWeight}
        fontSize={{ xs: fontSizeMobile, sm: messageFont ? "16px" : fontSize }}
        onClick={() => window.triggerOmniChat && window.triggerOmniChat("D582223DCFE61")}>
        {text}
      </StyledElement>
    </div>
  );
}

export default ContactSupport;
