import React, { useEffect, useState } from "react";
import { FormControl, Grid, Stack, Typography, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  PaymentBox,
  ProfileTypography,
  ProfileInput,
  CardHeading,
  ErrorMessage,
  StyledA,
} from "../../../../Styles/GlobalStyles";
import { useMutation } from "@apollo/client";
import { LINK_ACCOUNT } from "../../../../Pages/Account/query";
import {
  setActiveAccount,
  setDeeplinkAccNum,
  setInactiveAccounts,
  setNDS,
  switchAccountData,
} from "../../../../Redux/Slices/CustomerInfoSlice";
import { LoadingSpinner } from "../../LoadingSpinner/LoadingSpinner";
import { formatAccountNumber, logEvents, validInputSubmit } from "../../../../Services/helper";
import { LinkedAccountCard } from "../../LinkedAccountCard/LinkedAccountCard";
import { UpdateErrorComponent } from "../../UpdateErrorComponent/UpdateErrorComponent";
import Button from "../../../Button/Button";
import { SuccessMessageModel } from "../../SuccessMessageModel/SuccessMessageModel";
import {
  ACCOUNT_NUMBER_VERIFICATION_FAILURE,
  INVALID_DETAILS_MESSAGE_WHILE_LINKING_ACCOUNT,
  PANEL_1,
  accountNumberRegex,
} from "../../../../Constants/constants";
import { Theme } from "../../../../Styles/GlobalTheme";
import { setCheckAccNumErrorMessage } from "../../../../Redux/Slices/AccountSlice";

export const LinkedAccount = ({
  handleChange,
  expanded,
  isLinkAnotherAccOpen,
  isAuthorised,
  setIsAuthorised,
  setIsLinkAnotherAccOpen,
}: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const { checkAccNumErrorMessage } = useSelector((state: any) => state.billingAddress);
  const customerDetails = useSelector((state: any) => state.customerInfoDetails);
  const dispatch = useDispatch();

  const handleCancel = () => {
    setIsLinkAnotherAccOpen(false);
    dispatch(setCheckAccNumErrorMessage(""));
    setFormErrors({ billingAccountNumber: "", pin: "" });
    if (isAuthorised) {
      setIsAuthorised(false);
    }
  };

  const [formData, setFormData] = useState<any>({
    billingAccountNumber: "",
    pin: "",
  });

  const [formErrors, setFormErrors] = useState<any>({
    billingAccountNumber: "",
    pin: "",
  });
  const [addLinkAccount, { loading: linkAccountLoading }] = useMutation(LINK_ACCOUNT, {
    onCompleted: () => dispatch(setCheckAccNumErrorMessage("")),
    onError: (error: any) => {
      if (
        error.networkError.result.errors[0]?.code === "400" &&
        error.networkError.result.errors[0]?.message
          ?.toLowerCase()
          ?.includes(ACCOUNT_NUMBER_VERIFICATION_FAILURE)
      ) {
        dispatch(setCheckAccNumErrorMessage(INVALID_DETAILS_MESSAGE_WHILE_LINKING_ACCOUNT));
      } else {
        setOpenError(true);
      }
    },
  });

  const handleAccountNumberChange = (e: any) => {
    dispatch(setCheckAccNumErrorMessage(""));
    let accountNumber = e.target.value.replace(/[^0-9-]/g, "");

    const isValid = accountNumberRegex.test(accountNumber);

    if (!isValid) {
      accountNumber = formatAccountNumber(accountNumber);
    }

    const hasAlphabets = /[a-z]/i.test(accountNumber);

    if (!hasAlphabets) {
      setFormData({ ...formData, billingAccountNumber: accountNumber });
    }
  };

  const handlePinChange = (e: any) => {
    dispatch(setCheckAccNumErrorMessage(""));
    setFormData({ ...formData, pin: e.target.value.replace(/\D/g, "") });
    setFormErrors({});
  };

  const handleValidation = (name: any) => {
    const errors = validInputSubmit(formData);
    if (Object.keys(errors).length > 0) {
      setFormErrors({ ...formErrors, [name]: errors[name] });
    }
  };
  const handleContinue = async () => {
    let errors = validInputSubmit(formData);
    const formattedBillingAccountNumber = formData?.billingAccountNumber.split("-").join("");
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    }
    const IsEmpty = Object.values(errors).every((value) => value === "");
    if (IsEmpty) {
      try {
        const response = await addLinkAccount({
          variables: {
            customerLinkAccountInput: {
              billingAccountNumber: formattedBillingAccountNumber,
              pin: formData?.pin,
              username: "myAccount",
              accountType: "OTHER",
            },
            contactId: customerDetails?.contactId,
          },
        });
        if (response?.data?.linkAccount?.APIStatus === 412) {
          setOpen(true);
        } else if (response?.data?.linkAccount?.error) {
          setOpenError(true);
        } else if (response?.data) {
          const linkedAccount = {
            accountNumber: formattedBillingAccountNumber,
            pin: formData?.pin,
            accountUUID: response?.data?.linkAccount?.accountUUID,
            isNDS: response?.data?.linkAccount?.isNds,
            usi: response?.data?.linkAccount?.usi,
            isActive: response?.data?.linkAccount?.isActive,
          };
          localStorage.setItem("linkedAccountNumber", linkedAccount?.accountNumber);
          const newAccounts = [...customerDetails?.linkedAccountNumbers, linkedAccount];
          if (linkedAccount?.isActive === false) {
            const newInactiveAccount = [...customerDetails?.inactiveAccounts, linkedAccount];
            dispatch(setInactiveAccounts(newInactiveAccount));
          }
          dispatch(
            switchAccountData({
              linkedAccountNumbers: newAccounts,
              accountNumber: linkedAccount?.accountNumber,
            })
          );
          dispatch(setDeeplinkAccNum(""));
          dispatch(setNDS(linkedAccount));
          setSuccessMessage(true);
          logEvents("ACCOUNT_LINKED_SUCCESS");
          handleChange(PANEL_1)(null, false);
        }
      } catch (error) {
        setOpenError(true);
        console.log(error);
      }
    }
  };

  const closeLinkAccount = expanded ? handleChange(PANEL_1) : isLinkAnotherAccOpen && handleCancel;

  useEffect(() => {
    setFormErrors({});
    setFormData({ billingAccountNumber: "", pin: "" });
  }, [expanded]);

  const isAnyLinkAccountFieldEmpty = Object.values<string>(formData).some(
    (value) => value?.length === 0
  );

  return (
    <>
      <PaymentBox my={2}>
        <CardHeading my={1}>Link account</CardHeading>
        <Typography mb={4} fontSize={{ xs: "14px", sm: "16px" }}>
          If you need to link an existing account to your profile, please enter the account number
          and PIN located on the corresponding Ziply Fiber bill.
        </Typography>
        <form>
          <Grid container justifyContent={"space-between"}>
            <Grid item xs={12} sm={5.6} mb={2}>
              <FormControl fullWidth>
                <ProfileTypography>Account number</ProfileTypography>
                <ProfileInput
                  placeholder="###-###-####-######-#"
                  onBlur={() => handleValidation("billingAccountNumber")}
                  inputProps={{ "data-testid": "content-input" }}
                  required
                  hasError={
                    formErrors?.billingAccountNumber || checkAccNumErrorMessage ? true : false
                  }
                  value={formData.billingAccountNumber}
                  name="billingAccountNumber"
                  onChange={handleAccountNumberChange}
                />
                {formErrors?.billingAccountNumber && (
                  <ErrorMessage error={formErrors?.billingAccountNumber ? true : false}>
                    {formErrors?.billingAccountNumber}
                  </ErrorMessage>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5.6} mb={{ xs: 0, sm: 2 }}>
              <FormControl fullWidth>
                <ProfileTypography>PIN</ProfileTypography>
                <ProfileInput
                  placeholder="####"
                  onBlur={() => handleValidation("pin")}
                  inputProps={{ "data-testid": "pin-input" }}
                  required
                  value={formData?.pin}
                  name="pin"
                  hasError={formErrors?.pin || checkAccNumErrorMessage ? true : false}
                  onChange={handlePinChange}
                />
                {formErrors?.pin && (
                  <ErrorMessage error={formErrors?.pin ? true : false}>
                    {formErrors?.pin}
                  </ErrorMessage>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Typography fontSize={{ xs: "14px", sm: "16px" }} my={2} color={Theme.palette.error.main}>
            {checkAccNumErrorMessage}
          </Typography>
          <Stack mb={2}>
            <Typography component={"div"} fontSize={{ xs: "14px", sm: "16px" }}>
              If you need to link a new account for the first time, and it is not linked already,
              please call us at
            </Typography>
            <Typography component={"div"} fontSize={{ xs: "14px", sm: "16px" }}>
              <StyledA href="tel:1-866-699-4759"> 1 (866) 699-4759</StyledA> for support.
            </Typography>
          </Stack>
          <Stack justifyContent={"center"} direction={"row"} gap={2} mb={2}>
            <Box
              sx={{
                display: customerDetails?.linkedAccountNumbers?.length > 0 ? "block" : "none",
              }}>
              <Button onClick={closeLinkAccount} title="Cancel" type="pay" />
            </Box>
            <Button
              type="payment"
              eventName="LINK_ACCOUNT_CONTINUE"
              title="Continue"
              disabled={isAnyLinkAccountFieldEmpty}
              onClick={handleContinue}
            />
          </Stack>
        </form>
        {open && <LinkedAccountCard open={open} setOpen={setOpen} formData={formData} />}
        {openError && <UpdateErrorComponent setOpenError={setOpenError} openError={openError} />}
      </PaymentBox>
      {successMessage && (
        <SuccessMessageModel
          setIsLinkAnotherAccOpen={setIsLinkAnotherAccOpen}
          isLinkAnotherAccOpen={isLinkAnotherAccOpen}
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          userStatus={
            <>
              <Typography fontSize={"18px"} fontWeight={600}>
                Account is linked successfully.
              </Typography>
              <Typography fontSize={"18px"} fontWeight={600}>
                {formData.billingAccountNumber}
              </Typography>
            </>
          }
        />
      )}
      {linkAccountLoading && <LoadingSpinner />}
    </>
  );
};
