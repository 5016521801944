import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addressLine1: "",
  addressLine2: "",
  addressLine3: "",
  cityName: "",
  stateAbbreviation: "",
  zipCode: "",
  globysUrl: "",
  validPhoneNumber: false,
  globysUrlError: false,
  checkAccNumErrorMessage: "",
};
const billingAddressSlice = createSlice({
  name: "billingAddress",
  initialState,
  reducers: {
    billingAddressInfoDetails: (state, action) => {
      state.addressLine1 = action.payload.addressLine1;
      state.addressLine2 = action.payload.addressLine2;
      state.addressLine3 = action.payload.addressLine3;
      state.cityName = action.payload.cityName;
      state.stateAbbreviation = action.payload.stateAbbreviation;
      state.zipCode = action.payload.zipCode;
    },
    setGlobysUrl: (state, action) => {
      state.globysUrl = action.payload;
    },
    setGlobysUrlError: (state, action) => {
      state.globysUrlError = action.payload;
    },
    setValidPhoneNumber: (state, action) => {
      state.validPhoneNumber = action.payload;
    },
    setCheckAccNumErrorMessage: (state, action) => {
      state.checkAccNumErrorMessage = action.payload;
    },
  },
});

export const {
  billingAddressInfoDetails,
  setGlobysUrl,
  setValidPhoneNumber,
  setCheckAccNumErrorMessage,
  setGlobysUrlError,
} = billingAddressSlice?.actions;

export const billingAddressReducer = billingAddressSlice?.reducer;
