import { Step, StepLabel, Stepper } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export interface StepLabelProps {
  label?: string;
  index?: number;
}

export const StepperComponent = ({ activeStep, stepperColor, stepsLabel }: any) => {
  const { encryptedPayload } = useSelector((state: any) => state.signInDetails);

  const [labels, setLabels] = useState(
    stepsLabel || [{ label: "Create" }, { label: "Verify" }, { label: "Connect" }]
  );

  useEffect(() => {
    if (encryptedPayload?.trim()?.length > 0) {
      setLabels([]);
    }
  }, [encryptedPayload]);

  return (
    <Stepper activeStep={activeStep} alternativeLabel color={stepperColor}>
      {labels?.map(({ label, index }: StepLabelProps, idx: number) => {
        const stepLabelProps: any = {};
        if (idx === activeStep) {
          stepLabelProps.StepIconProps = { active: true, color: "secondary" };
        }
        return (
          <Step key={index}>
            <StepLabel
              {...stepLabelProps}
              sx={{
                fontSize: { xs: "14px", sm: "18px" },
              }}>
              {label}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};
