import { useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import { PHONE_NUMBER_VALIDATION } from "../../Pages/Signin/query";
import { setValidPhoneNumber } from "../../Redux/Slices/AccountSlice";

export const usePhoneNumberValidation = (phoneNumber: any, setErrors: any, Errors: any) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [phoneNumberValidation, { loading: validationLoading, data: validationData }] = useMutation(
    PHONE_NUMBER_VALIDATION,
    {
      variables: {
        phoneNumber: phoneNumber?.replace(/[() -]/g, "") ?? "",
      },
      fetchPolicy: "network-only",
      onCompleted: () => {
        dispatch(setValidPhoneNumber(false));
        setErrors({ ...Errors, validatePhoneNumberError: "" });
      },
      onError: (error: any) => {
        setMessage(error.networkError.result.errors[0]?.message);
        dispatch(setValidPhoneNumber(true));
        setErrors({
          ...Errors,
          validatePhoneNumberError: error.networkError.result.errors[0]?.message,
        });
      },
    }
  );
  return {
    phoneNumberValidation,
    validationData,
    validationLoading,
    message,
  };
};
