import { gql } from "@apollo/client";

export const TEST_YOUR_CONNECTION_DETAILS = gql`
  query testYourConnection($accountNumber: String!) {
    testYourConnection(accountNumber: $accountNumber) {
      APIStatus
      message
      error
      connectionDetails {
        serialNumber
        model
        testStatus
        status
        manufacturer
        deviceId
        ipAddress
        rxLight
        txLight
        portNumber
        softwareVersion
      }
    }
  }
`;

export const CHECK_ROUTER_TYPE = gql`
  query CheckRouterType($accountNumber: String!, $isNds: Boolean!) {
    checkRouterType(accountNumber: $accountNumber, isNds: $isNds) {
      isZiplyRouter
      supportsSpeedTest
      enableBreakLeaseAPI
      enableTestYourConnection
      enableRgStatus
      APIStatus
      error
      message
    }
  }
`;
export const RG_STATUS = gql`
  query rgStatus($accountNumber: String!) {
    rgStatus(accountNumber: $accountNumber) {
      APIStatus
      message
      error
      statusDetails {
        model
        unitAddress
        id
        wanIpAddress
        macAddress
        rgStatus
      }
    }
  }
`;

export const REBOOT_RG = gql`
  mutation rebootRG($accountNumber: String!) {
    rebootRG(accountNumber: $accountNumber) {
      rebootStatus
      APIStatus
      error
      message
    }
  }
`;

export const SERVICE_PROVISION_STATUS = gql`
  query serviceAndProvisionStatus($accountNumber: String!) {
    serviceAndProvisionStatus(accountNumber: $accountNumber) {
      videoServiceStatus
      dataServiceStatus
      APIStatus
      message
      error
    }
  }
`;

export const BREAK_LEASE = gql`
  mutation breakLease($accountNumber: String!) {
    breakLease(accountNumber: $accountNumber) {
      APIStatus
      error
      message
    }
  }
`;
