import { gql } from "@apollo/client";

export const GET_ORDER_STATUS = gql`
  query getOrderDetailsByEmail(
    $email: String!
    $accountNumber: String
    $isNds: Boolean
    $usi: String
    $uuid: String
  ) {
    getOrderDetailsByEmail(
      email: $email
      accountNumber: $accountNumber
      isNds: $isNds
      usi: $usi
      uuid: $uuid
    ) {
      error
      APIStatus
      message
      orderDetails {
        orderId
        installationAppointment
        installationAppointmentEndDate
        serviceAddress
        telephoneNumber
        orderStatus
        orderStatusMessage
        orderReschedule
        product
        equipment
        phone
        orderType
        fieldTechnicianName
        fieldTechnicianContact
        estimatedWorkDuration
        estimatedArrivalTime
        headerTitle
        unscheduledAppointment
      }
    }
  }
`;

export const GET_ENV_VALUES = gql`
  query getEnvValuesFromFirestore($platform: String!) {
    getEnvValuesFromFirestore(platform: $platform) {
      envVariables {
        key
        value
      }
      APIStatus
      error
      message
    }
  }
`;

export const ACTION_TELEMETRY = gql`
  mutation late($payload: String!) {
    late(payload: $payload) {
      error
      APIStatus
      message
    }
  }
`;

export const CBST_KEY = gql`
  query cbstKey {
    cbstKey {
      cbstKey
      error
      APIStatus
      message
    }
  }
`;

export const CBST = gql`
  mutation cbst($payload: String!) {
    cbst(payload: $payload) {
      message
      APIStatus
      error
    }
  }
`;

export const VERIFY_TP_LINK = gql`
  query verifyTPLinkRouter($accountNumber: String!, $usi: String!, $isNds: Boolean!) {
    verifyTPLinkRouter(accountNumber: $accountNumber, usi: $usi, isNds: $isNds) {
      APIStatus
      error
      message
      tpLinkURL
      wifi7
      model
      tpLinkAndroid
      tpLinkApple
      tpLinkIntendIos
      tpLinkIntendAndroid
      showUpgradeRouter
    }
  }
`;

export const UPGRADE_BANNER_COPPER_CUSTOMERS = gql`
  mutation upgradeBannerForCopperCustomers($accountNumber: String!, $isNds: Boolean!) {
    upgradeBannerForCopperCustomers(accountNumber: $accountNumber, isNds: $isNds) {
      enableUpgradeBanner
      billingPage
      billingPageMessage
      message
      APIStatus
      error
    }
  }
`;

export const INACTIVE_ACCOUNT_BALANCE_BY_ACCOUNT_NUMBERS = gql`
  query inactiveAccountBalanceByAccountNumbers($accountNumbers: [String!]!) {
    inactiveAccountBalanceByAccountNumbers(accountNumbers: $accountNumbers) {
      dueBalanceDetails {
        accountNumber
        currentDueBalance
      }
      error
      message
      APIStatus
    }
  }
`;
