import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  CANCELLED,
  CANCEL_PAYMENT,
  COMPLETE_SUCCESS,
  SUCCESS,
} from "../../../../Constants/constants";
import { extractUID } from "../../../../Services/helper";

interface IPaymentIframeProps {
  src: string;
  handleSuccess?: any;
  handleFailure?: any;
}

const PaymentIframe = ({ src, handleSuccess, handleFailure }: IPaymentIframeProps) => {
  const { iframeURL } = useSelector((state: any) => state.paymentDetails);
  const [width, setWidth] = useState<number>(0);

  const { ndsObj } = useSelector((state: any) => state.customerInfoDetails);
  const { paymentsMigrationToNDS } = useSelector((state: any) => state.customerPlanDetails);
  const resolutions = {
    mobileResolution: window.screen.width >= 320 && window.screen.width < 500,
    tabletResolution: window.screen.width > 650 && window.screen.width <= 1024,
  };

  useEffect(() => {
    let UID = src && extractUID(src);
    const messageHandler = (event: any) => {
      const data =
        ndsObj?.isNDS || paymentsMigrationToNDS
          ? event.data
          : !ndsObj?.isNDS && event?.data && JSON.parse(event.data);
      let uid;

      try {
        if (ndsObj?.isNDS || paymentsMigrationToNDS) {
          uid = UID;
          console.log(data, "data for nds");
          console.log(uid, "uid for nds");
        }

        if (
          ((ndsObj?.isNDS || paymentsMigrationToNDS) && data?.content?.status === SUCCESS) ||
          (!ndsObj?.isNDS && data?.status === COMPLETE_SUCCESS)
        ) {
          if (!ndsObj?.isNDS) {
            let content = {
              uid: data.paymentmethod_id,
            };
            console.log(data, "data for non nds");
            console.log(content, "content for non nds");

            data["content"] = content;

            data["txn_guid"] = "";
          }

          handleSuccess(data, uid);
        } else if (
          data?.subject === CANCEL_PAYMENT ||
          (!ndsObj?.isNDS && data?.status === CANCELLED)
        ) {
          handleFailure(data);
        }
      } catch (err) {
        console.log(err);
      }
    };

    window.addEventListener("message", messageHandler);
    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, []);

  useEffect(() => {
    if (resolutions?.mobileResolution) {
      setWidth(320);
    } else if (resolutions?.tabletResolution) {
      setWidth(550);
    } else {
      setWidth(700);
    }
  }, [resolutions]);

  return (
    <>
      {iframeURL !== null && (
        <iframe
          scrolling="no"
          style={{
            border: "none",
            minHeight: resolutions?.mobileResolution ? "80vh" : "65vh",
          }}
          src={src}
          data-testid="iframe"
          width={width}
          title="Embedded Content"></iframe>
      )}
    </>
  );
};

export default PaymentIframe;
