import { useEffect, useState } from "react";
import Button from "../../../Button/Button";
import { useMutation } from "@apollo/client";
import { Typography, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { DialogContent, IconButton } from "@mui/material";
import { LoadingSpinner } from "../../LoadingSpinner/LoadingSpinner";
import { ConfirmationModel } from "../../ConfirmationModel/ConfirmationModel";
import { LinkingWaitMessage } from "../../LinkingWaitMessage/LinkingWaitMessage";
import { SuccessMessageModel } from "../../SuccessMessageModel/SuccessMessageModel";
import { UpdateErrorComponent } from "../../UpdateErrorComponent/UpdateErrorComponent";
import { AUTOPAY_PAPERLESS_DETAILS, SET_PAPERLESS } from "../../../../Pages/Billing/query";
import { CenterStack, StyledDialog, CustomChip, MiniCard } from "../../../../Styles/GlobalStyles";
import {
  paperLessTurnedOff,
  paperLessTurnedOn,
} from "../../../../Redux/Slices/AutoPayPaperlessSlice";
import {
  PAPERLESS_CBST_ACTION,
  PAPERLESS_DEACTIVATION_SUCCESS_MESSAGE,
  PAPERLESS_HEADING,
  PAPERLESS_PRIMARYCONTENT,
  TURNON_PAPERLESS_SUCCESS_MESSAGE,
  UUID_ERROR_MSG_PAPERLESS,
} from "../../../../Constants/constants";
import { setApiFailureName, setCbstAction, setError } from "../../../../Redux/Slices/PaymentSlice";
import ReportIssue from "../../ReportIssue/ReportIssue";
import { getFlags } from "../../../../Services/helper";

export const PaperLessBilling = ({ billingError }: any) => {
  const dispatch = useDispatch();
  const customerDetails = useSelector((state: any) => state.customerInfoDetails);
  const { paperlessAmount } = useSelector((state: any) => state.customerPlanDetails);
  const { paperLessData, autoPayData } = useSelector(
    (state: any) => state?.autopayPaperlessDetails
  );
  const [turnOff, setTurnOff] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [userStatus, setUserStatus] = useState<string>("");
  const [uuidError, setUuidError] = useState(false);
  const envFlags = useSelector((state: any) => state?.userInfo?.envFlags);
  const SHOW_REPORT_ISSUE = getFlags("report_service_issue", envFlags);
  const [openReportIssue, setOpenReportIssue] = useState(false);
  const [dialogData, setDialogData] = useState<any>({
    heading: "",
    primaryContent: "",
    secondaryContent: "",
    containedButton: { onClick: () => {}, buttonType: "", buttonTitle: "", eventName: "" },
  });
  const [turnOffSuccessful, setTurnOffSuccessful] = useState(false);

  const paperLessEnabled = paperLessData?.paperlessFlag;
  const [signUp, setSignUp] = useState<boolean>(paperLessEnabled);
  const { uuidErrorFlag } = useSelector((state: any) => state?.autopayPaperlessDetails);

  useEffect(() => {
    setSignUp(paperLessEnabled);
  }, [paperLessData, paperLessEnabled]);
  const handleReportIssueError = () => {
    SHOW_REPORT_ISSUE ? setOpenReportIssue(true) : setOpenError(true);
  };

  const [setPaperless, { loading: setPaperLessLoading }] = useMutation(SET_PAPERLESS, {
    refetchQueries: [AUTOPAY_PAPERLESS_DETAILS],
    onError: (error: any) => {
      handleReportIssueError();
      dispatch(setError(error?.networkError?.result?.errors?.[0]));
      dispatch(setApiFailureName("paperLess"));
      dispatch(setCbstAction(PAPERLESS_CBST_ACTION));
      successMessage && setSuccessMessage(false);
      turnOff && setTurnOff(false);
    },
  });

  const closeDialog = () => {
    setUuidError(false);
    setUserStatus("");
  };

  const openTurnOff = () => {
    setTurnOff(true);
    setDialogData({
      ...dialogData,
      heading: PAPERLESS_HEADING,
      primaryContent: PAPERLESS_PRIMARYCONTENT,
      secondaryContent: paperlessAmount
        ? `You will see a $${paperlessAmount} monthly charge for a paper bill to be mailed.`
        : "",
      containedButton: {
        ...dialogData?.containedButton,
        buttonTitle: "YES, TURN OFF",
        buttonType: "root",
        eventName: "TURN_OFF_PAPERLESS",
      },
    });
  };

  const flagData = paperLessData?.paperlessFlag === true ? false : true;

  const handleTurnOff = async () => {
    try {
      const response = await setPaperless({
        variables: {
          accountNumber: customerDetails?.accountNumber,
          paperlessFlag: flagData,
        },
      });
      if (response?.data?.setPaperless?.error) {
        setTurnOff(false);
        handleReportIssueError();
      } else if (response?.data) {
        setTurnOff(false);
        const paperLessData = { paperlessFlag: flagData };
        dispatch(paperLessTurnedOff(paperLessData));
        setTurnOffSuccessful(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleTurnOn = async () => {
    if (uuidErrorFlag) {
      console.log("uuid errorr");
      setUuidError(true);
    } else {
      try {
        const response = await setPaperless({
          variables: {
            accountNumber: customerDetails?.accountNumber,
            paperlessFlag: flagData,
          },
        });
        if (response?.data?.setPaperless?.error) {
          handleReportIssueError();
          setSuccessMessage(false);
        } else if (response?.data) {
          setSuccessMessage(true);
          setUserStatus(TURNON_PAPERLESS_SUCCESS_MESSAGE);
          const paperLessData = { paperlessFlag: flagData };
          dispatch(paperLessTurnedOn(paperLessData));
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const loader = setPaperLessLoading;
  return (
    <>
      <MiniCard>
        <Stack
          visibility={!signUp && !billingError && paperlessAmount ? "visible" : "hidden"}
          direction={"row"}
          justifyContent={"flex-end"}>
          <CustomChip label={`SAVE $${paperlessAmount}`} color="success" variant="outlined" />
        </Stack>
        <CenterStack spacing={1} pb={4.5}>
          {signUp ? (
            <>
              <Typography component={"p"} variant="subtitle1" textAlign={"center"}>
                Paperless billing is turned on
              </Typography>
              <Button onClick={openTurnOff} title="TURN OFF" type="pay" />
            </>
          ) : (
            <>
              <Typography component={"p"} variant="subtitle1">
                Paperless billing is turned off
              </Typography>
              <Button onClick={handleTurnOn} title="TURN ON" type="pay" />
            </>
          )}
        </CenterStack>

        {successMessage && (
          <>
            <SuccessMessageModel
              successMessage={successMessage}
              setSuccessMessage={setSuccessMessage}
              userStatus={userStatus}
              note={paperlessAmount && `You will save $${paperlessAmount}/mo.`}
            />
          </>
        )}
        {uuidError && (
          <>
            <StyledDialog open={uuidError}>
              <IconButton
                data-testid="close-button"
                sx={{ alignSelf: "flex-end", padding: 0 }}
                onClick={closeDialog}>
                <CloseIcon />
              </IconButton>
              <DialogContent
                sx={{
                  padding: { sm: "1rem 0.5rem 3rem 0.5rem", xs: "1rem 0.5rem 2.5rem 0.5rem" },
                }}>
                <LinkingWaitMessage message={UUID_ERROR_MSG_PAPERLESS} />
              </DialogContent>
            </StyledDialog>
          </>
        )}
        {turnOff && (
          <ConfirmationModel
            dialogData={dialogData}
            setDialogData={setDialogData}
            openDialog={turnOff}
            setOpenDialog={setTurnOff}
            onClick={handleTurnOff}
          />
        )}
        {turnOffSuccessful && (
          <SuccessMessageModel
            successMessage={turnOffSuccessful}
            setSuccessMessage={setTurnOffSuccessful}
            userStatus={PAPERLESS_DEACTIVATION_SUCCESS_MESSAGE}
            note={
              <Typography fontSize={{ xs: "12px", md: "14px" }}>
                It may take a short while for this change to appear in your account.
              </Typography>
            }
          />
        )}
      </MiniCard>
      {loader && <LoadingSpinner />}

      <ReportIssue setOpenDialog={setOpenReportIssue} openDialog={openReportIssue} />
      <UpdateErrorComponent openError={openError} setOpenError={setOpenError} />
    </>
  );
};
