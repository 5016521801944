import { useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { GET_CUSTOMER_TYPE } from "../../Pages/Login/query";
import { useDispatch } from "react-redux";
import { setGlobysId, setUserError, setUserNotInFirestore } from "../../Redux/Slices/UserSlice";
import { getCleanRedirectURL } from "../../Services/helper";
import { setDeeplinkAccNum } from "../../Redux/Slices/CustomerInfoSlice";

export const useCustomerType = (email) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [getCustomerType, { loading: customerTypeLoading }] = useLazyQuery(GET_CUSTOMER_TYPE, {
    variables: { email },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const { residential, commercial, globysId, message } = data?.getCustomerType || {};
      const userDetails = { globysId };
      const redirectURL = localStorage.getItem("redirectURL");
      const cleanedRedirectURL = redirectURL && getCleanRedirectURL(redirectURL);

      dispatch(setGlobysId(userDetails));
      localStorage.setItem("globysId", globysId);

      const isLogin = redirectURL?.includes("/login");
      const isCommercial = redirectURL?.includes("/commercial");
      const isHome = redirectURL?.includes("/home");
      const isSelectAccount = redirectURL?.includes("/select-account");
      const isAdmin = redirectURL?.includes("/adminscreen");
      const emptyRoute = !getCleanRedirectURL(redirectURL);

      const clearSessionAndNavigate = (path) => {
        sessionStorage.clear();
        navigate(path);
      };

      if (residential && !commercial) {
        if ((!isLogin && isHome) || emptyRoute) {
          clearSessionAndNavigate("/home");
        } else if (!isLogin && isCommercial && isSelectAccount) {
          navigate(cleanedRedirectURL);
        } else {
          if (!isLogin && !isCommercial && !isSelectAccount && !isAdmin) {
            navigate(cleanedRedirectURL);
          } else {
            clearSessionAndNavigate("/home");
          }
        }
      } else if (commercial && !residential) {
        console.log("commercial");
        if ((!isLogin && isCommercial) || emptyRoute) {
          clearSessionAndNavigate("/commercial");
        } else if (!isLogin && isSelectAccount && isHome) {
          navigate(cleanedRedirectURL);
        } else if (!isLogin && !isSelectAccount && !isHome) {
          navigate(cleanedRedirectURL);
        } else {
          clearSessionAndNavigate("/commercial");
        }
      } else if (residential && commercial) {
        if ((!isLogin && isSelectAccount) || emptyRoute) {
          navigate("/select-account");
        } else if (!isLogin && isCommercial) {
          navigate(cleanedRedirectURL);
        } else if (!isLogin && !isCommercial) {
          navigate(cleanedRedirectURL);
        } else {
          navigate("/select-account");
        }
      } else if (!residential && !commercial) {
        clearSessionAndNavigate("/home");
        dispatch(setUserError(message));
        sessionStorage.setItem("error", message);
        navigate("/acc-not-found");
      }
    
      localStorage.removeItem("redirectURL");
    },
    onError: (error) => {
      const networkError = error?.networkError?.result?.errors?.[0];
      if (networkError?.code === "404") {
        dispatch(setUserError(networkError.message));
        dispatch(setUserNotInFirestore(true));
      }
      navigate("/acc-not-found");
    },
  });

  return {
    getCustomerType,
    customerTypeLoading,
  };
};
