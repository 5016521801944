import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  networkConnectionDetails: {},
  testConnectionApiError: false,
  testConnectionApiErrorMsg: "",
  testConnectionLoading: false,
  RGStatusDetails: {},
  RGStatusApiError: false,
  RGStatusApiErrorMsg: "",
  rebootRGMessage: "",
  rebootRGApiError: false,
  serviceProvisionDetails: {},
  serviceProvisionApiError: false,
  serviceProvisionApiErrorMsg: "",
  breakLeaseSuccess: false,
  breakLeaseApiError: false,
  speedTestApiError: false,
  isZiplyRouter: false,
  rgStatusRebootSuccess: false,
  supportsSpeedTest: false,
  showOutage: false,
  testDetailsNull: false,
  checkRouterTypeError: false,
  TpLinkError: false,
  enableBreakLease: false,
  checkRouterTypeMessage: "",
  enableRgStatus: false,
  enableTestYourConnection: false,
};

const networkStatusSlice = createSlice({
  name: "networkStatus",
  initialState,
  reducers: {
    setNetworkConnectionDetails: (state, action) => {
      state.networkConnectionDetails = action.payload;
    },
    setTestConnectionApiError: (state, action) => {
      state.testConnectionApiError = action.payload;
    },
    setTestConnectionApiErrorMsg: (state, action) => {
      state.testConnectionApiErrorMsg = action.payload;
    },
    setTestConnectionLoading: (state, action) => {
      state.testConnectionLoading = action.payload;
    },
    setRGStatusDetails: (state, action) => {
      state.RGStatusDetails = action.payload;
    },
    setRGStatusApiError: (state, action) => {
      state.RGStatusApiError = action.payload;
    },
    setRGStatusApiErrorMsg: (state, action) => {
      state.RGStatusApiErrorMsg = action.payload;
    },
    setRebootRGMessage: (state, action) => {
      state.rebootRGMessage = action.payload;
    },
    setRgStatusRebootSuccess: (state, action) => {
      state.rgStatusRebootSuccess = action.payload;
    },
    setRebootRGApiError: (state, action) => {
      state.rebootRGApiError = action.payload;
    },
    setServiceProvisionDetails: (state, action) => {
      state.serviceProvisionDetails = action.payload;
    },
    setServiceProvisionApiError: (state, action) => {
      state.serviceProvisionApiError = action.payload;
    },
    setServiceProvisionApiErrorMsg: (state, action) => {
      state.serviceProvisionApiErrorMsg = action.payload;
    },
    setBreakLeaseSuccess: (state, action) => {
      state.breakLeaseSuccess = action.payload;
    },
    setBreakLeaseApiError: (state, action) => {
      state.breakLeaseApiError = action.payload;
    },
    setSpeedTestApiError: (state, action) => {
      state.speedTestApiError = action.payload;
    },
    setisZiplyRouter: (state, action) => {
      state.isZiplyRouter = action.payload;
    },
    setSupportsSpeedTest: (state, action) => {
      state.supportsSpeedTest = action.payload;
    },
    setShowOutage: (state, action) => {
      state.showOutage = action.payload;
    },
    setTestDetailsNull: (state, action) => {
      state.testDetailsNull = action.payload;
    },
    setCheckRouterTypeError: (state, action) => {
      state.checkRouterTypeError = action.payload;
    },
    setTpLinkError: (state, action) => {
      state.TpLinkError = action.payload;
    },
    setEnableBreakLease: (state, action) => {
      state.enableBreakLease = action.payload;
    },
    setCheckRouterMessage: (state, action) => {
      state.checkRouterTypeMessage = action.payload;
    },
    setEnableRgStatus: (state, action) => {
      state.enableRgStatus = action.payload;
    },
    setEnableTestYourConnection: (state, action) => {
      state.enableTestYourConnection = action.payload;
    },
  },
});

export const {
  setNetworkConnectionDetails,
  setTestConnectionApiError,
  setTestConnectionLoading,
  setRGStatusDetails,
  setRGStatusApiError,
  setRebootRGMessage,
  setRebootRGApiError,
  setServiceProvisionDetails,
  setServiceProvisionApiError,
  setBreakLeaseSuccess,
  setTestConnectionApiErrorMsg,
  setRGStatusApiErrorMsg,
  setServiceProvisionApiErrorMsg,
  setBreakLeaseApiError,
  setSpeedTestApiError,
  setisZiplyRouter,
  setRgStatusRebootSuccess,
  setSupportsSpeedTest,
  setShowOutage,
  setTestDetailsNull,
  setCheckRouterTypeError,
  setTpLinkError,
  setEnableBreakLease,
  setCheckRouterMessage,
  setEnableTestYourConnection,
  setEnableRgStatus,
} = networkStatusSlice.actions;
export const networkStatusReducer = networkStatusSlice.reducer;
