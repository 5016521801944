import { Accordion } from "@mui/material";
import { ManageTicket } from "./ManageTicket";
import { ImappingData } from "./TroubleTicketStatus";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { StyleAccordionSummary, StyledElement } from "../../../../Styles/GlobalStyles";
import { useState } from "react";
import { MANAGE_ORDER } from "../../../../Constants/constants";
import { useSelector } from "react-redux";

interface IManageAccordion {
  title: string;
  data: ImappingData[];
}

export const ManageAccordion = ({ title, data }: IManageAccordion) => {
  const { orderDetails } = useSelector((state: any) => state.customerInfoDetails);
  const [expanded, setExpanded] = useState<boolean>(
    title === orderDetails?.headerTitle ? true : false
  );

  const handleChange = () => {
    setExpanded(!expanded);
  };
  return (
    <Accordion
      elevation={0}
      expanded={expanded}
      onChange={handleChange}
      sx={{ borderRadius: "0 0 6px 6px", margin: "0 !important" }}>
      <StyleAccordionSummary
        expandIcon={
          <StyledElement>
            <ExpandMoreIcon />
          </StyledElement>
        }
        aria-controls="panel1a-content"
        id="panel1a-header">
        <b>{title}</b>
      </StyleAccordionSummary>
      <ManageTicket data={data} title={title} />
    </Accordion>
  );
};
