import React, { useState, useEffect } from "react";

interface ICountdownTimerProps {
  minutes: number;
}

export const CountdownTimer = ({ minutes }: ICountdownTimerProps) => {
  let startTimestamp = performance.now();
  const secondBase = 1000;
  const minuteBase = 60 * secondBase;

  const [timeLeft, setTimeLeft] = useState(minutes * minuteBase);

  const secondTick = () => {
    setTimeLeft((prevTimeLeft) => {
      const timeLeftAfterTick = prevTimeLeft - secondBase;
      if (timeLeftAfterTick < 0) {
        return prevTimeLeft;
      }
      return timeLeftAfterTick;
    });
  };

  const formatTime = (time: number, type: "seconds" | "minutes") => {
    switch (type) {
      case "seconds":
      case "minutes":
        return time.toString().padStart(2, "0");
      default:
        return "";
    }
  };

  const getMinutes = (timeLeft: number) => {
    return formatTime(Math.floor(timeLeft / minuteBase), "minutes");
  };

  const getSeconds = (timeLeft: number) => {
    return formatTime(Math.floor((timeLeft % minuteBase) / secondBase), "seconds");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTimestamp = performance.now();
      const elapsed = currentTimestamp - startTimestamp;

      if (elapsed >= secondBase) {
        startTimestamp = currentTimestamp;
        secondTick();
      }
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <span>{getMinutes(timeLeft)}</span>:<span>{getSeconds(timeLeft)}</span>
    </>
  );
};
