import { useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_DETAILS_FOR_AVAILABLE_SLOTS, GET_REASONS_FOR_TT } from "../../query";
import {
  setdetailsForTTAvailableSlots,
  setReasonsForTT,
  setReasonsForTTError,
  setTTdetailsError,
} from "../../../../Redux/Slices/TroubleTicketSlice";

const useServiceTicketAPI = (accountNumber: string, customerDetails: any) => {
  const dispatch = useDispatch();

  const [getReasonCodesForTT, { loading: reasonCodesForTTLoading }] = useLazyQuery(
    GET_REASONS_FOR_TT,
    {
      variables: { accountNumber },
      onCompleted: (data) => {
        if (data?.getReasonCodesForTT?.reasonCodes) {
          dispatch(setReasonsForTT(data.getReasonCodesForTT.reasonCodes));

          const hasReasons = data.getReasonCodesForTT.reasonCodes.length > 0;
          dispatch(setReasonsForTTError(!hasReasons));
        }
      },
      onError: () => dispatch(setReasonsForTTError(true)),
    }
  );

  const [getDetailsForAvailableSlots, { loading: getAvailableSlotsDetailsLoading }] = useLazyQuery(
    GET_DETAILS_FOR_AVAILABLE_SLOTS,
    {
      variables: {
        accountNumber,
        isNds: customerDetails?.ndsObj?.isNDS,
      },
      onCompleted: (data) => {
        if (data?.getDetailsForAvailableSlots) {
          dispatch(setdetailsForTTAvailableSlots(data.getDetailsForAvailableSlots));

          const isEligible = data.getDetailsForAvailableSlots.isEligibleForCreateTT;
          dispatch(setTTdetailsError(!isEligible));
        }
      },
      onError: () => dispatch(setTTdetailsError(true)),
    }
  );

  return {
    getReasonCodesForTT,
    reasonCodesForTTLoading,
    getDetailsForAvailableSlots,
    getAvailableSlotsDetailsLoading,
  };
};

export default useServiceTicketAPI;
