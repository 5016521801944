import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orderRescheduledSuccess: false,
  appointmentCancelSuccess: false,
  orderRescheduledFailed: false,
  appointmentCancelFailed: false,
  isOrder: false,
  orderDetailsApiError: false,
};

const OrderDetailSlice = createSlice({
  name: "OrderDetails",
  initialState,
  reducers: {
    setOrderRescheduledSuccess: (state, action) => {
      state.orderRescheduledSuccess = action.payload;
    },
    setOrderRescheduledFailed: (state, action) => {
      state.orderRescheduledFailed = action.payload;
    },
    setAppointmentCancelSuccess: (state, action) => {
      state.appointmentCancelSuccess = action.payload;
    },
    setAppointmentCancelFailed: (state, action) => {
      state.appointmentCancelFailed = action.payload;
    },
    setIsOrder: (state, action) => {
      state.isOrder = action.payload;
    },
    setOrderDetailsApiError: (state, action) => {
      state.orderDetailsApiError = action.payload;
    },
  },
});

export const {
  setOrderRescheduledSuccess,
  setOrderRescheduledFailed,
  setAppointmentCancelSuccess,
  setAppointmentCancelFailed,
  setIsOrder,
  setOrderDetailsApiError,
} = OrderDetailSlice.actions;

export const orderDetailReducer = OrderDetailSlice.reducer;
