import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  StyledElement,
  BillingAccountStack,
  BillingAccordionSummary,
  StyleAccordian,
} from "../../../../../../Styles/GlobalStyles";
import { Theme } from "../../../../../../Styles/GlobalTheme";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getDollarSign } from "../../../../../../Services/helper";

export const BilltableAccordion = (props: any) => {
  const { item, dates, parsedData } = props;
  const [metaData, setMetaData] = useState<any>({
    primary: {},
    secondary: {},
  });
  const [billInfo, setBillInfo] = useState<any>({});

  const getRowData: any = () => {
    if (parsedData[dates[0]][item]?.metaData && parsedData[dates[1]][item]?.metaData) {
      const primary = parsedData[dates[0]][item]?.metaData;
      const secondary = parsedData[dates[1]][item]?.metaData;
      const arrayData = Array.from(new Set([...Object.keys(primary), ...Object.keys(secondary)]));
      const newArray = Array.from(arrayData);
      const newData = {
        primaryMetaData: primary,
        secondaryMetaData: secondary,
        keyValues: newArray,
      };
      setBillInfo(newData);
    } else {
      return;
    }
  };

  const reorderedArray = billInfo?.keyValues?.reduce((acc: any, item: any) => {
    if (item.includes("Total")) {
      return [...acc, item];
    } else {
      return [item, ...acc];
    }
  }, []);

  useEffect(() => {
    setMetaData({
      primary: parsedData[dates[0]][item]?.metaData,
      secondary: parsedData[dates[1]][item]?.metaData,
    });
    parsedData && getRowData();
  }, [dates, item, parsedData]);

  return (
    <Box key={item}>
      {billInfo && Object.keys(billInfo)?.length > 0 && (
        <StyleAccordian disableGutters elevation={0}>
          <BillingAccountStack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}>
            <BillingAccordionSummary
              sx={{
                width: { xs: "55%", sm: "60%" },
              }}
              expandIcon={
                ((parsedData && Object.entries(metaData.primary).length > 0) ||
                  (parsedData && Object.entries(metaData.secondary).length > 0)) && (
                  <StyledElement>
                    <ExpandMoreIcon data-testid="arrow-down" />
                  </StyledElement>
                )
              }>
              <Typography
                fontSize={{ xs: "12px", sm: "16px" }}
                component={"span"}
                data-testid="heading">
                {item}
              </Typography>
            </BillingAccordionSummary>
            {dates?.map((date: any, index: number) => (
              <Box key={index}>
                <Typography
                  data-testid="amount"
                  mr={{ xs: 0.5, sm: 5, md: 7 }}
                  fontSize={{ xs: "12px", sm: "16px" }}>
                  {getDollarSign(
                    parsedData[date][item]?.amount ? parsedData[date][item]?.amount : 0
                  )}
                </Typography>
              </Box>
            ))}
          </BillingAccountStack>
          <Box>
            {Object.entries(metaData.primary) && (
              <Box justifyContent={"space-between"} sx={{ display: "flex" }}>
                <Box width={"100%"}>
                  {billInfo &&
                    billInfo?.keyValues?.length > 0 &&
                    reorderedArray?.map((item: any) => (
                      <Box justifyContent={"space-between"} sx={{ display: "flex" }}>
                        <Box width={{ xs: "50%", sm: "57%", md: "58%" }} ml={2}>
                          <Typography
                            borderRight={`1px solid ${Theme.palette.customcolor.accordionborder}`}
                            py={1}
                            component={"p"}
                            variant="caption"
                            alignItems={"center"}
                            data-testid="meta-data">
                            {item}
                          </Typography>
                        </Box>
                        <Stack width={"20%"}>
                          <Typography
                            p={1}
                            textAlign={"center"}
                            component={"p"}
                            variant="caption"
                            borderRight={`1px solid ${Theme.palette.customcolor.accordionborder}`}>
                            {getDollarSign(
                              billInfo?.primaryMetaData[item] ? billInfo?.primaryMetaData[item] : 0
                            )}
                          </Typography>
                        </Stack>
                        <Stack width={"18%"}>
                          <Typography py={1} component={"p"} textAlign={"center"} variant="caption">
                            {getDollarSign(
                              billInfo?.secondaryMetaData[item]
                                ? billInfo?.secondaryMetaData[item]
                                : 0
                            )}
                          </Typography>
                        </Stack>
                      </Box>
                    ))}
                </Box>
              </Box>
            )}
          </Box>
        </StyleAccordian>
      )}
    </Box>
  );
};
