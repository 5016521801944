import  { useState } from "react";

export interface PasswordCriteria {
    hasEightOrMoreChars: boolean;
    hasAtleastOneUpperAndLowerCase: boolean;
    hasAtleastOneNumber: boolean;
}

export  function usePasswordCriteria() {
  const [passwordCriteria, setPasswordCriteria] = useState<PasswordCriteria>({
    hasEightOrMoreChars: false,
    hasAtleastOneUpperAndLowerCase: false,
    hasAtleastOneNumber: false,
  });

  const validatePasswordWithCriteria = (value: string) => {
    const eightOrMoreCharsRegex = /.{8,}/;
    const upperAndLowerCaseRegex = /^(?=.*[a-z])(?=.*[A-Z]).+$/; 
    const atleastOneNumberRegex = /\d/; 

    const hasEightOrMoreChars = eightOrMoreCharsRegex.test(value);
    const hasAtleastOneUpperAndLowerCase = upperAndLowerCaseRegex.test(value);
    const hasAtleastOneNumberRegex = atleastOneNumberRegex.test(value);

    setPasswordCriteria({
      hasEightOrMoreChars: hasEightOrMoreChars,
      hasAtleastOneUpperAndLowerCase: hasAtleastOneUpperAndLowerCase,
      hasAtleastOneNumber: hasAtleastOneNumberRegex,
    });
  };

  return { passwordCriteria, validatePasswordWithCriteria ,setPasswordCriteria};
}
