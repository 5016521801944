export const findMatchingItem = (addonsArray: any[] = [], plansArray: any[] = [], isNDS: any) => {
  for (let addon of addonsArray) {
    // Check if the plansArray is defined and not empty
    if (!Array.isArray(plansArray)) {
      throw new TypeError("Expected plansArray to be an array.");
    }
    const matchingItem = plansArray?.find(
      (plan: any) => (isNDS ? plan.productId : plan.SE) === addon.addonItemCode
    );
    if (matchingItem) {
      let returnItem = { ...matchingItem, isRouter: true };
      return returnItem;
    }
  }

  return false;
};

export const getEquipmentName = (addonSku: string, addonContent: any) => {
  const content = addonContent?.find((item: any) => item?.sku === addonSku);
  return content?.sub_title;
};

export const findMatchingAddon = (obj: any, array: any, isNds: any) => {
  const match = array.find(
    (item: any) => item?.addonItemCode === (isNds ? obj?.productId : obj?.SE)
  );
  return match ? obj : false;
};

export const findMatchingItemOrderSubmit = (addonsArray: any[] = [], plansArray: any[] = []) => {
  // Check if inputs are arrays
  if (!Array.isArray(addonsArray) || !Array.isArray(plansArray)) {
    throw new TypeError("Both addonsArray and plansArray should be arrays.");
  }
  return addonsArray
    .map((addon: any) => {
      const matchingItem = plansArray?.find((plan: any) => {
        return addon.addonItemCode === plan.productId;
      });
      // Return a new object with the additional property if a matching item is found
      if (matchingItem) {
        return {
          ...matchingItem,
          isRouter: !(
            matchingItem.name === "Autopay Convenience Fee" ||
            matchingItem.name === "Paperless Convenience Fee"
          ),
        };
      }
      return null;
    })
    .filter((item: any) => item !== null); // Filter out null values
};
export function transformRemovedAddons(addonsArray: any, isNDS: boolean) {
  return addonsArray?.map((addon: any) => ({
    productId: addon?.addonItemCode || "",
    serviceId: addon?.serviceId || "",
    baseprice:
      addon?.addonItemCode === "WHW"
        ? "5"
        : addon?.price !== undefined && addon?.price !== null
        ? addon?.price?.toString()
        : "",
    ...(addon?.addonItemCode === "WHW" &&
      addon?.discounts?.length > 0 &&
      isNDS && {
        discounts: addon?.discounts,
      }),
  }));
}
export function transformRemovedAddonsLegacy(addonsArray: any) {
  return addonsArray?.map((addon: any) => ({
    id: addon?.addonItemCode,
    LISno: addon?.LISno,
  }));
}
export function transformAddonsLegacy(addonsArray: any) {
  return addonsArray?.map((addon: any) => ({
    id: addon?.SE,
  }));
}

export const transformAddons = (addonsArray: any, isNDS: boolean) => {
  return addonsArray.map((addon: any) => {
    const productId = addon.productId || "";
    const serviceId = addon.serviceId || "";
    let baseprice =
      addon?.productId === "WHW"
        ? addon?.discounts?.length > 0 && isNDS
          ? addon?.actualPrice?.toString()
          : addon?.originalPrice?.toString()
        : addon?.price !== undefined && addon?.price !== null
        ? addon?.price?.toString()
        : "";
    const hasFreeInstall = addon?.discounts?.some((discount: any) =>
      JSON.stringify(discount)?.includes("FREEINSTALL")
    );
    const transformedAddon: { productId: any; serviceId: any; baseprice: any; discounts?: any } = {
      productId,
      serviceId,
      baseprice,
    };
    if ((addon?.productId === "WHW" || hasFreeInstall) && addon?.discounts?.length > 0 && isNDS) {
      transformedAddon.discounts = addon.discounts;
    }

    return transformedAddon;
  });
};

export const checkAddonItemCode = (addonsArray: any[] = [], codeToCheck: any) => {
  // Validate that addonsArray is an array
  if (!Array.isArray(addonsArray)) {
    throw new TypeError("Expected addonsArray to be an array.");
  }
  for (let addon of addonsArray) {
    if (addon.addonItemCode === codeToCheck) {
      let returnItem = { ...addon, isRouter: true };
      return returnItem;
    }
  }
  // Return false if no matching item is found
  return false;
};
export const findRemovedAddons = (addonsArray: any, plansArray: any, isNds: any) => {
  return addonsArray?.filter((addon: any) => {
    return !plansArray?.some((plan: any) => {
      return (isNds ? plan?.productId : plan?.SE) === addon?.addonItemCode;
    });
  });
};

export const removeMatchedAddons = (newAddonsArray: any, plansArray: any, isNds: any) => {
  const filteredAddons = newAddonsArray?.filter((newAddon: any) => {
    if (
      newAddon.name === "Paperless Convenience Fee" ||
      newAddon.name === "Autopay Convenience Fee"
    ) {
      return false;
    }
    const isMatched = !plansArray?.some((plan: any) => {
      return plan?.addonItemCode === (isNds ? newAddon?.productId : newAddon?.SE);
    });

    return isMatched;
  });

  return filteredAddons;
};

export function formatTimeWithAMPM(dateString: any) {
  if (!dateString) {
    return "Invalid date string";
  }
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return "Invalid date";
  }
  // Extract hours and minutes
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  // Determine AM/PM suffix
  const ampm = hours >= 12 ? "PM" : "AM";
  // Convert hours from 24-hour to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  // Format the time
  return `${hours.toString().padStart(2, "0")}:${minutes} ${ampm}`;
}

function formatDateToISO(date: any) {
  const offset = date.getTimezoneOffset();
  const adjustedDate = new Date(date.getTime() - offset * 60000);
  return adjustedDate.toISOString();
}

// ---------------------------------
export function generateAppointmentSlots(numDays: any) {
  const appointments = [];
  const currentDate = new Date();
  const startDate = new Date();
  startDate.setDate(currentDate.getDate() + 1); // Start from tomorrow

  for (let i = 0; i < numDays; i++) {
    const dayStart = new Date(startDate);
    dayStart.setDate(startDate.getDate() + i);

    // First slot: 08:00 to 12:00
    const slot1Start = new Date(dayStart);
    slot1Start.setHours(8, 0, 0, 0);
    const slot1End = new Date(dayStart);
    slot1End.setHours(12, 0, 0, 0);

    appointments.push({
      startDate: formatDateToISO(slot1Start),
      endDate: formatDateToISO(slot1End),
      resourcePoolId: null,
      timeSlotType: null,
      workUnitsQuantity: "30",
      __typename: "Appointments",
    });

    // Second slot: 13:00 to 17:00
    const slot2Start = new Date(dayStart);
    slot2Start.setHours(13, 0, 0, 0);
    const slot2End = new Date(dayStart);
    slot2End.setHours(17, 0, 0, 0);

    appointments.push({
      startDate: formatDateToISO(slot2Start),
      endDate: formatDateToISO(slot2End),
      resourcePoolId: null,
      timeSlotType: null,
      workUnitsQuantity: "30",
      __typename: "Appointments",
    });
  }

  return appointments;
}

export const calculateEstimatedTax = (
  orderArray: any,
  isNDS: any,
  state: any,
  installation: any
) => {
  // //if we select wholewhomwifi
  let wholeHomeWifi = orderArray.find((item: any) => item?.groupType === "Equipment");
  //if we select router
  let wifiRouter = orderArray.find((item: any) => item?.groupType === "Router");

  //get voice Addons
  const voiceAddons = orderArray.find((item: any) => item?.groupType === "Voice Addon");

  let wifiPrice = 0;
  let installPrice = 0;
  let phonePrice = 0;

  if (wholeHomeWifi) {
    wifiPrice =
      wholeHomeWifi.duration < 12 && !isNDS ? wholeHomeWifi.actualprice : wholeHomeWifi.price;
  } else if (wifiRouter) {
    wifiPrice = wifiRouter?.duration < 12 ? wifiRouter.actualprice : wifiRouter.price;
  } else {
    wifiPrice = 0;
  }
  if (installation) {
    let discountAddon: any;
    if (isNDS) {
      discountAddon = installation?.discounts?.find((x: any) => x.unitOfMeasure == "OneTime");
    }

    if (discountAddon) {
      const discountPrice = isNDS ? discountAddon.price.discountAmount : discountAddon.price;
      installPrice = installation?.price + discountPrice;
    } else {
      installPrice = installation?.price;
    }
  } else {
    installPrice = 0;
  }
  if (voiceAddons) {
    phonePrice = voiceAddons.price;
  } else {
    phonePrice = 0;
  }

  //GET STATE
  let wifiPercent = 0 / 100;
  let installPercent = 0 / 100;
  let phonePercent = 0 / 100;
  if (state != "" || state != undefined) {
    if (state === "WA") {
      wifiPercent = 10.5 / 100;
      installPercent = 10.5 / 100;

      phonePercent = 41 / 100;
    }
    if (state === "ID") {
      wifiPercent = 9.0 / 100;
      installPercent = 0 / 100;

      phonePercent = 20 / 100;
    }
    if (state === "OR") {
      wifiPercent = 0 / 100;
      installPercent = 0 / 100;

      phonePercent = 27 / 100;
    }
    if (state === "MT") {
      wifiPercent = 0 / 100;
      installPercent = 0 / 100;

      phonePercent = 24 / 100;
    }
  } else {
    wifiPercent = 0 / 100;
    installPercent = 0 / 100;

    phonePercent = 0 / 100;
  }

  let wifiTax = 0;
  let installTax = 0;
  let tax = {};
  let phoneTax = 0;
  if (wifiRouter || wholeHomeWifi) {
    wifiTax = Number(wifiPrice) * wifiPercent;
  } else {
    wifiTax = 0;
  }
  if (installation) {
    installTax = Number(installPrice) * installPercent;
  } else {
    installTax = 0;
  }
  if (voiceAddons) {
    phoneTax = Number(phonePrice) * phonePercent;
  } else {
    phoneTax = 0;
  }

  tax = {
    estimatedTax: wifiTax + phoneTax,
    installTax: installTax,
  };

  return tax;
};

export const isObjectEmpty = (obj: any) => {
  return Object.values(obj).every((x) => x === null || x === "");
};
export const isMgRouter = (planData: any) => {
  return planData?.addons.find((item: any) =>
    item.addonItemCode === "FIBER_MG_ROUTER" ? true : false
  );
};
export const routerCheck = (items: any) => {
  if (items?.length > 2) {
    return items?.filter((item: any) => item.productId === "FIBER_WIFI_RTR");
  } else {
    return items;
  }
};

export const renderRouterType = (currentaddons: any, isNDS: any) => {
  let currentaddonsIsWifi7 = currentaddons?.addons?.find((item: any) =>
    item?.addonItemCode === "WIFI7_RTR" ? true : false
  );
  let contentData = {
    heading: "",
    description: "",
  };
  let isOnNoRouter = currentaddons?.addons?.find((item: any) =>
    item?.addonItemCode === (isNDS ? "BYO_ROUTER" : "BYOEQ") ? true : false
  );
  if (isOnNoRouter) {
    return {
      ...contentData,
      heading: "You're currently not using a Ziply Equipment",
    };
  } else if (currentaddonsIsWifi7) {
    return {
      ...contentData,
      heading: "You're currently using a Wifi7 Router",
    };
  } else if (isMgRouter(currentaddons)) {
    return {
      ...contentData,
      description:
        "This router cannot transmit the new speed or service you've requested. So we suggest you to exchange it for a new Router",
      heading: "You're currently using a Ziply Router",
    };
  } else {
    return {
      ...contentData,
      heading: "You're currently using a Ziply Router",
    };
  }
};
