import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgIcon from "@mui/material/SvgIcon";
import { AutoPayDivider, RowCenterStack } from "../../../../Styles/GlobalStyles";
import { TOKENIZED_BANK, masterCard, visa } from "../../../../Constants/constants";
import { FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { ReactComponent as masterCardIcon } from "../../../../assets/icons/mc_symbol (1).svg";
import { ReactComponent as bankAccountIcon } from "../../../../assets/icons/online banking.svg";
import { ReactComponent as genericPayment } from "../../../../assets/icons/generic payment.svg";
import { ReactComponent as visaCardIcon } from "../../../../assets/icons/Visa_Brandmark_Blue_RGB_2021-2.svg";
import { ErrorComponent } from "../../../../components/ErrorComponent/ErrorComponent";
import { setIframeURL } from "../../../../Redux/Slices/PaymentSlice";

const PaymentType = ({
  paymentMethods,
  paymentMethodsLoading,
  buttonId,
  setButtonId,
  paymentMethodsError,
}: any) => {
  const { ndsObj } = useSelector((state: any) => state.customerInfoDetails);
  const { paymentsMigrationToNDS } = useSelector((state: any) => state.customerPlanDetails);
  const { autoPayData } = useSelector((state: any) => state?.autopayPaperlessDetails);
  const dispatch = useDispatch();

  const isNDS = ndsObj?.isNDS || paymentsMigrationToNDS;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setButtonId((event.target as HTMLInputElement).value);
    dispatch(setIframeURL(""));
  };

  const filterId = () => {
    if (autoPayData && Object.keys(autoPayData)?.length > 0) {
      if (!isNDS) {
        let filterNonNdsId = paymentMethods?.filter(
          (method: any) => method?.id === autoPayData?.paymentMethodId
        );
        setButtonId(filterNonNdsId?.[0]?.id);
      } else {
        let filterNdsId = paymentMethods?.filter((method: any) => method?.id === autoPayData?.id);
        setButtonId(filterNdsId?.[0]?.id);
      }
    } else {
      setButtonId(paymentMethods?.[0]?.id);
    }
  };

  const handleSetDefaultValue = () => {
    switch (true) {
      case paymentMethods?.length > 0:
        filterId();
        break;
      case isNDS:
        setButtonId("new card");
        break;
      case !isNDS:
        setButtonId("new bank");
        break;
      default:
        setButtonId("");
        break;
    }
  };

  const handleSetIcon = (method: any) => {
    switch (true) {
      case method?.cardType?.toLowerCase() === masterCard:
        return masterCardIcon;
      case method?.cardType?.toLowerCase() === visa:
        return visaCardIcon;
      case method?.paymentMethodType === TOKENIZED_BANK:
        return bankAccountIcon;
      default:
        return genericPayment;
    }
  };

  useEffect(() => {
    !paymentMethodsLoading && handleSetDefaultValue();
  }, [paymentMethodsLoading]);

  return (
    <>
      <Stack>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={buttonId}
            onChange={handleChange}>
            {!paymentMethodsError &&
              paymentMethods?.map((method: any) => {
                return (
                  <div key={method?.id}>
                    <FormControlLabel
                      value={method?.id}
                      control={<Radio />}
                      label={
                        <RowCenterStack gap={0.5}>
                          <SvgIcon
                            fontSize={"small"}
                            component={handleSetIcon(method)}
                            inheritViewBox
                          />
                          <Typography
                            px={method?.cardType?.toLowerCase() === "visa" ? 0 : 1}
                            fontSize={{ xs: "12px", md: "14px" }}>
                            {method?.nameOnTheCard}
                          </Typography>
                        </RowCenterStack>
                      }
                    />
                    <AutoPayDivider />
                  </div>
                );
              })}

            {!isNDS ? (
              <>
                <FormControlLabel value="new bank" control={<Radio />} label="New Payment method" />
              </>
            ) : (
              <>
                <FormControlLabel
                  value="new card"
                  control={<Radio />}
                  label="New Credit/Debit card"
                />
                <AutoPayDivider />
                <FormControlLabel value="new bank" control={<Radio />} label="New bank account" />
              </>
            )}
          </RadioGroup>
        </FormControl>
      </Stack>
    </>
  );
};

export default PaymentType;
