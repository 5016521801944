import React, { useEffect, useState } from "react";
import { Theme } from "../../../../Styles/GlobalTheme";
import { Box, FormControl, Grid, Typography } from "@mui/material";
import { CustomTextField, ErrorMessage, ProfileTypography } from "../../../../Styles/GlobalStyles";
import { accountNumberRegex, NUMERIC_ONLY_REGEX } from "../../../../Constants/constants";
import { formatAccountNumber } from "../../../../Services/helper";

const PortInfo = ({ portInfo, setPortInfo, errors, setErrors }: any) => {
  const handleChangePortInNumber = (e: any) => {
    const value = e.target.value;
    if (NUMERIC_ONLY_REGEX.test(value)) {
      setPortInfo({ ...portInfo, portInNumber: value });
      setErrors({ ...errors, portInNumber: "" });
    }
  };

  const handleChangeAccountNumber = (e: any) => {
    let accountNumber = e.target.value.replace(/[^0-9-]/g, "");

    const isValid = accountNumberRegex.test(accountNumber);

    if (!isValid) {
      accountNumber = formatAccountNumber(accountNumber);
    }

    const hasAlphabets = /[a-z]/i.test(accountNumber);

    if (!hasAlphabets) {
      setPortInfo({ ...portInfo, accountNumber: accountNumber });
    }
    setErrors({ ...errors, accountNumber: "" });
  };

  const handleChangeExistingPin = (e: any) => {
    const value = e.target.value;
    if (NUMERIC_ONLY_REGEX.test(value)) {
      setPortInfo({ ...portInfo, existingPin: value });
      setErrors({ ...errors, existingPin: "" });
    }
  };

  const handleChangeExistingTelecomAddress = (e: any) => {
    setPortInfo({ ...portInfo, existingTelecomAddress: e.target.value });
    setErrors({ ...errors, existingTelecomAddress: "" });
  };

  return (
    <>
      <Typography fontSize={{ xs: "16px", md: "18px" }} color={Theme.palette.primary.main}>
        Port In Information
      </Typography>

      <Grid container justifyContent={"space-between"} my={2} gap={2}>
        <Grid item xs={12} sm={5.5}>
          <FormControl fullWidth>
            <Box display="flex">
              <ProfileTypography fontSize={{ xs: "14px" }}>Port In Number</ProfileTypography>
              <Typography component="span" color="error" ml={0.5}>
                *
              </Typography>
            </Box>
            <CustomTextField
              value={portInfo.portInNumber}
              onChange={(e: any) => handleChangePortInNumber(e)}
              error={errors?.portInNumber}
            />
          </FormControl>
          {errors?.portInNumber && <ErrorMessage error={true}>{errors?.portInNumber}</ErrorMessage>}
        </Grid>
        <Grid item xs={12} sm={5.5}>
          <FormControl fullWidth>
            <Box display="flex">
              <ProfileTypography fontSize={{ xs: "14px" }}>
                Account Number (with current provider)
              </ProfileTypography>
              <Typography component="span" color="error" ml={0.5}>
                *
              </Typography>
            </Box>
            <CustomTextField
              value={portInfo.accountNumber}
              error={errors?.accountNumber}
              onChange={(e: any) => handleChangeAccountNumber(e)}
            />
          </FormControl>
          {errors?.accountNumber && (
            <ErrorMessage error={true}>{errors?.accountNumber}</ErrorMessage>
          )}
        </Grid>
        <Grid item xs={12} sm={5.5}>
          <FormControl fullWidth>
            <Box display="flex">
              <ProfileTypography fontSize={{ xs: "14px" }}>Existing Pin</ProfileTypography>
              <Typography component="span" color="error" ml={0.5}>
                *
              </Typography>
            </Box>
            <CustomTextField
              value={portInfo.existingPin}
              error={errors?.existingPin}
              onChange={(e: any) => handleChangeExistingPin(e)}
            />
          </FormControl>
          {errors?.existingPin && <ErrorMessage error={true}>{errors?.existingPin}</ErrorMessage>}
        </Grid>
        <Grid item xs={12} sm={5.5}>
          <FormControl fullWidth>
            <Box display="flex">
              <ProfileTypography fontSize={{ xs: "14px" }}>
                Existing Telecom address
              </ProfileTypography>
              <Typography component="span" color="error" ml={0.5}>
                *
              </Typography>
            </Box>
            <CustomTextField
              value={portInfo.existingTelecomAddress}
              error={errors?.existingTelecomAddress}
              onChange={(e: any) => handleChangeExistingTelecomAddress(e)}
            />
          </FormControl>
          {errors?.existingTelecomAddress && (
            <ErrorMessage error={true}>{errors?.existingTelecomAddress}</ErrorMessage>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PortInfo;
