import React from "react";
import { CardHeading, StyledDialog } from "../../../Styles/GlobalStyles";
import { DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";

export const SuccessMessageModel = ({
  successMessage,
  setSuccessMessage,
  userStatus,
  note,
  setIsLinkAnotherAccOpen,
  isLinkAnotherAccOpen,
}: any) => {
  const dispatch = useDispatch();
  const closeTurnOnDialog = () => {
    if (isLinkAnotherAccOpen) {
      setIsLinkAnotherAccOpen(false);
    }
    setSuccessMessage(false);
  };
  return (
    <>
      <StyledDialog data-testid="usermessage" fullWidth open={successMessage}>
        <IconButton
          data-testid="close-button"
          sx={{ alignSelf: "flex-end", padding: 0 }}
          onClick={closeTurnOnDialog}>
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{ padding: { sm: "1rem 0.5rem 3rem 0.5rem", xs: "1rem 0.5rem 2.5rem 0.5rem" } }}>
          <CardHeading
            sx={{ fontSize: { xs: "14px", sm: "1.25rem" } }}
            data-testid="success-message"
            textAlign={"center"}>
            {userStatus}
          </CardHeading>
          <CardHeading
            sx={{ fontSize: { xs: "14px", sm: "1.25rem" } }}
            data-testid="success-message"
            textAlign={"center"}>
            {note}
          </CardHeading>
        </DialogContent>
      </StyledDialog>
    </>
  );
};
