import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function LoginBox({ children }: any) {
  const mobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  return (
    <>
      <Box
        sx={{
          width: { xs: 320, sm: 400 },
          padding: "1rem",
          borderRadius: mobile ? "none" : "10px",
          boxShadow: mobile ? "none" : "0px 0px 4px 0px rgb(193, 193, 193)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        {children}
      </Box>
    </>
  );
}
