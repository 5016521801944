import { Analytics, logEvent } from "@firebase/analytics";
import { Theme } from "../Styles/GlobalTheme";
import { analytics } from "../Config/firebase";
import moment from "moment";
import forge from "node-forge";
import {
  COMPLETE,
  INFORMATION_PROVIDED,
  IN_PROGRESS,
  MANAGE_ORDER,
  MERGED,
  ON_HOLD,
  PROBLEM_SOLVED,
  RESEARCHING,
  UID_REGEX,
  WAITING_FOR_DETAILS,
} from "../Constants/constants";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { CustomerAddress } from "../Pages/Account/Components/OrderUpgrades/OrderUpgrades";
import { v4 as uuidv4 } from "uuid";
const isAdmin = process.env.REACT_APP_CXP_ADMIN_ACCESS_ENABLED;

export const resetStateExcept = (state: any, keysToPreserve: string[]) => {
  const newState = Object.keys(state).reduce((acc, key) => {
    if (keysToPreserve.includes(key)) {
      acc[key] = state[key];
    } else {
      acc[key] = undefined;
    }
    return acc;
  }, {} as any);
  return newState;
};

export const getFlags = (key: string, envFlags: any) => {
  const variable = envFlags?.find((variable: any) => variable?.key?.includes(key));
  return variable?.value;
};
export const getFormattedDate = (date: any) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedDate = `${day < 10 ? "0" : ""}${day}-${month < 10 ? "0" : ""}${month}-${year}`;
  return formattedDate;
};
export const getModifiedDate = (date: any) => {
  const modifiedDate = new Date(date);
  const formattedDate = modifiedDate.toLocaleString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
  return formattedDate;
};

export const formatResheduleTime = (timeString: string) => {
  let time = timeString?.slice(timeString.indexOf("T") + 1, timeString.indexOf("Z"));
  return moment(time?.slice(0, -1), "HH:mm:ss").format("h:mm A");
};

const fetchDomainURI = () => {
  let hostname = window.location.hostname ?? "";
  if (window.location.port.length > 0) {
    return hostname;
  }
  const domain = hostname?.match(/\w*\.\w*$/gi)![0];
  return `.${domain}`;
};

export const setCookie = (name: string, value: string, days: number = 30) => {
  let expires = "";
  let domain = fetchDomainURI();
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ""}${expires}; path=/; secure; domain=${domain}`;
};

export const transformVoipInvoices = (voipInvoices: any) => {
  return voipInvoices?.map((item: any) => {
    const date = moment(item.date);
    const monthAbbreviation = date.format("MMM");
    const year = date.format("YYYY");
    const servicePeriodIds = item.servicePeriodId.split(",").map((id: any) => id.trim());
    return {
      date: `${monthAbbreviation} ${year}`,
      servicePeriodId: servicePeriodIds?.join(","),
      totalAmount: item.totalAmount,
    };
  });
};

export const transformVoipDpiInvoices = (voipInvoices: any) => {
  return voipInvoices?.map((item: any, index: any) => {
    const date = moment(item.date);
    const monthAbbreviation = date.format("MMM");
    const year = date.format("YYYY");

    return {
      date: `${monthAbbreviation} ${year}`,
      id: index,
    };
  });
};

export const groupItemsInPairs = (items: any[]) => {
  return items?.reduce((rows: any[], item: any, index: number) => {
    if (index % 2 === 0) {
      rows.push([item]);
    } else {
      rows[rows.length - 1].push(item);
    }
    return rows;
  }, []);
};

export const splitDataBasedOnTitle = (data: any, title: any, heading: any) => {
  if (title === heading) {
    return [data.slice(0, 2), data.slice(2)];
  }
  return [data];
};

export const getMonthAndYear = (dateData: any) => {
  const date = moment(dateData, "MMM YYYY");
  const year = date?.format("YYYY");
  const monthNumber = (date.month() + 1).toString();
  const dateObj = {
    month: monthNumber,
    year: year,
  };
  return dateObj;
};

export const readCookie = (name: string) => {
  const cookieString = document.cookie;
  const cookies = cookieString.split("; ");

  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split("=");
    if (cookieName === name) {
      return cookieValue;
    }
  }

  return null;
};
export function RSAOAEPPadding(pwd: any, publicKeyFromPem: any) {
  const publicKeyPem = `-----BEGIN RSA PUBLIC KEY-----
  ${publicKeyFromPem}
  -----END RSA PUBLIC KEY-----`;
  console.log(publicKeyPem);

  const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
  const encryptedBytes = publicKey.encrypt(pwd, "RSA-OAEP");
  // Convert the encrypted bytes to a Base64-encoded string
  const encryptedBase64 = forge.util.encode64(encryptedBytes);
  console.log(encryptedBase64);
  return encryptedBase64;
}
export async function deleteCookie(name: string) {
  document.cookie = `${name}=; expires=${new Date(0).toUTCString()}`;
}

export const getModifiedDueDate = (dateString: any) => {
  if (dateString) {
    const [day, month, year] = dateString?.split("-");
    const monthIndex = parseInt(month) - 1;
    const monthName = new Date(year, monthIndex).toLocaleString("default", { month: "short" });
    const formattedDate = `${monthName} ${day}, ${year}`;
    return formattedDate;
  } else {
    return "";
  }
};

export const getModifiedAutopaymentDeductionDate = (dateString: string) => {
  if (!dateString) return "";
  let day: string, month: string, year: string;
  const parts = dateString.split("-");
  if (parts.length === 3) {
    if (parts[0].length === 4) {
      [year, month, day] = parts;
    } else {
      [day, month, year] = parts;
    }
    const monthIndex = parseInt(month) - 1;
    const monthName = new Date(parseInt(year), monthIndex).toLocaleString("default", {
      month: "short",
    });
    return `${monthName} ${day}`;
  }

  return "";
};
export const getDueDate = (date: any) => {
  if (!date) {
    return "-/-/-";
  }
  const parts = date?.split("-");
  return `${parts[0]}/${parts[1]}/${parts[2]?.slice(2)}`;
};

export const paymentModifiedDate = (date: any) => {
  const modifiedDate = new Date(date);
  const day = modifiedDate.getDate().toString().padStart(2, "0");
  const month = (modifiedDate.getMonth() + 1).toString().padStart(2, "0");
  const year = modifiedDate.getFullYear().toString();
  return `${year}-${month}-${day}`;
};

export const convertToPositive = (number: number | string) =>
  Number(number) < 0 ? -Number(number) : Number(number);

export const convertToNegative = (number: number | string) =>
  Number(number) > 0 ? -Number(number) : Number(number);

export const extractUID = (url: string) => {
  const match = url?.match(UID_REGEX);
  if (!url) {
    return "";
  }
  if (match && match[1]) {
    const uid = match[1];
    return uid;
  }
};
export const getCleanRedirectURL = (redirectURL: string): string => {
  const cleanedRedirectURL = redirectURL
    ?.replace(/^\/?(lac|myaccount)\//i, "")
    ?.split("?")[0]
    ?.replace(/^"|"$/g, "")
    ?.trim();
  return cleanedRedirectURL && cleanedRedirectURL !== "/" ? `/${cleanedRedirectURL}` : "";
};

export const getDollarSign = (str: any = 0) => {
  if (typeof str === "number") str = str?.toString();
  if (str) {
    if (str !== "") {
      if (str?.includes("+")) {
        return `+$${parseFloat(str?.replace(/-/g, ""))?.toFixed(2)}`;
      } else if (str?.includes("-")) {
        return `-$${parseFloat(str?.replace(/-/g, ""))?.toFixed(2)}`;
      } else {
        return `$${parseFloat(str).toFixed(2)}`;
      }
    } else {
      return "$0.00";
    }
  } else {
    return "$0.00";
  }
};
export const removeCommas = (input: string) => {
  if (!input) {
    return "";
  }
  return input?.replace(/,/g, " ");
};

export const logEvents = (eventName: any) => {
  const data = eventName?.replace(/ /g, "_");
  return isAdmin === "TRUE"
    ? logEvent(analytics, `LAC_${data}`)
    : logEvent(analytics, `WEB_${data}`);
};

export const apiLogEvents = (
  analytics: Analytics,
  eventNamePrefix: string,
  additionalData: string
) => {
  const data = additionalData?.replace(/ /g, "_");
  const eventPrefix = isAdmin === "TRUE" ? `LAC_${eventNamePrefix}` : `WEB_${eventNamePrefix}`;
  return logEvent(analytics, `${eventPrefix}_${data}`);
};

export function queryParamsToObject(queryParams: any) {
  if (queryParams.charAt(0) === "/") {
    queryParams = queryParams.slice(1);
  }
  var paramPairs = queryParams.split("&");
  var params: any = {};
  for (var i = 0; i < paramPairs.length; i++) {
    var param = paramPairs[i].split("=");
    var key = decodeURIComponent(param[0]);
    var value = decodeURIComponent(param[1]);
    params[key] = value;
  }
  return params;
}
export const getColorCode = (status: string) => {
  let transectionStatus = status?.trim()?.toLowerCase();
  switch (transectionStatus) {
    case "successful":
      return Theme.palette.primary.main;
    case "failed":
      return Theme.palette.error.main;
    case "progress":
      return Theme.palette.customcolor.inProgressColor;
    case "pending":
      return Theme.palette.secondary.main;
    case "scheduled":
      return Theme.palette.customcolor.scheduledColor;
    case "success":
      return Theme.palette.primary.main;
    default:
      return Theme.palette.customcolor.blackColor;
  }
};

export const getTransectionStatus = (status: any) => {
  let transectionStatus = status?.trim()?.toLowerCase();
  switch (transectionStatus) {
    case "successful":
      return "Success";
    case "failed":
      return "Failed";
    case "progress":
      return "In Progress";
    case "pending":
      return "Pending";
    case "scheduled":
      return "Scheduled";
    case "success":
      return "Success";
    default:
      return "";
  }
};

export interface DateTimeOptions {
  year?: "numeric" | "2-digit";
  month?: "numeric" | "2-digit" | "long" | "short" | "narrow";
  day?: "numeric" | "2-digit";
  hour?: "numeric" | "2-digit";
  minute?: "numeric" | "2-digit";
  second?: "numeric" | "2-digit";
  timeZoneName?: "short" | "long";
}

export function getMonthYear(date: Date, options: DateTimeOptions): string {
  return new Intl.DateTimeFormat("en-US", options).format(date);
}

export const installationFormatDate = (date: any) => {
  const dateParts = date?.split("/");
  const year = dateParts[0];
  const month = dateParts[1];
  const day = dateParts[2];
  const newDate = new Date(year, month, day);
  const formattedDate = newDate.toLocaleDateString("en-US", {
    weekday: "long",
    month: "short",
    day: "numeric",
  });
  return formattedDate;
};

export const getDaySuffix = (day: any) => {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const formatDateWithSuffix = (date: any) => {
  const options = { month: "short", day: "2-digit", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);
  const day = date.getDate();

  return formattedDate?.replace(day.toString().padStart(2, "0"), `${day}`);
};

export const getNextMonth = (date: any) => {
  const day = date.getDate();
  const suffix = getDaySuffix(day);
  const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, day).toLocaleString("en-US", {
    month: "long",
    day: "2-digit",
    year: "numeric",
  });
  return nextMonth?.replace(
    day.toString().padStart(2, "0"),
    `
  ${day}
  <sup>${suffix}</sup>
  `
  );
};

export const validChangePassword = (validData: any) => {
  let errmessage: any = {
    password: "",
    newPassword: "",
    confirmPassword: "",
  };
  Object.keys(validData).forEach((name) => {
    let error = {};
    error = validationForm(name, validData[name], validData["newPassword"]);

    errmessage[name] = error;
  });
  return errmessage;
};

export function convertNumberToMonthName(number: number) {
  return moment()
    .month(number - 1)
    .format("MMM");
}

export function generateMonthYearDropdownForCurrentYear(): string[] {
  const currentYear: number = new Date().getFullYear();
  const months: string[] = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dropdownOptions: string[] = [];

  months.forEach((month: string) => {
    dropdownOptions.push(`${month} ${currentYear}`);
  });

  return dropdownOptions;
}

export function generateCurrentMonthYear(): string {
  const currentDate: moment.Moment = moment();
  const formattedDate: string = currentDate.format("MMM YYYY");
  return formattedDate;
}

export function addValuesFromString(str: string) {
  if (!str) {
    return "";
  }
  const parts = str.split(",");
  const sum = parts.reduce((acc, curr) => acc + parseFloat(curr), 0);
  return sum;
}

export function convertMonthNameToNumber(monthName: string) {
  return moment().month(monthName).month() + 1;
}

export const formatPhoneNumber = (phoneNumber: any) => {
  const cleaned = ("" + phoneNumber)?.replace(/\D/g, "")?.substring(0, 10);
  const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    let formattedPhoneNumber = "";

    if (match[1]) {
      formattedPhoneNumber = `(${match[1]}`;
    }

    if (match[2]) {
      formattedPhoneNumber += `) ${match[2]}`;
    }

    if (match[3]) {
      formattedPhoneNumber += `-${match[3]}`;
    }

    return formattedPhoneNumber;
  }

  return phoneNumber;
};

export function getSessionID() {
  const sessionIDKey = "sessionID";

  let sessionID = sessionStorage.getItem(sessionIDKey);

  if (!sessionID) {
    sessionID = uuidv4();
    sessionStorage.setItem(sessionIDKey, sessionID);
  }

  return sessionID;
}

export const defaultFormatPhone = (phoneNumber: string) => {
  return phoneNumber?.replace(/[() -]/g, "");
};

export const formatAccountNumber = (accountNumber: string) => {
  const accountNumberArr = accountNumber?.split("");
  const indexesToCheck = [3, 7, 12, 19];

  for (let i = 0; i < indexesToCheck.length; i++) {
    const idxToCheck = indexesToCheck[i];

    if (accountNumberArr[idxToCheck] && accountNumberArr[idxToCheck] !== "-") {
      accountNumberArr.splice(idxToCheck, 0, "-");
    } else {
      continue;
    }
  }
  return accountNumberArr.join("");
};

export const splitErrorMessage = (errorString: string) => {
  const data = errorString?.split(". ");
  return data;
};

export const addSpaceAfterComma = (input: string) => {
  const formatted = input?.replace(/,(?=[^,]*$)/, " ");
  return formatted?.replace(/,/g, ", ");
};

export const checkAccountNumberForAlphabet = (accountNumber: string) => {
  const accountNumberArr = accountNumber?.split("-");
  for (let i = 0; i < accountNumberArr.length; i++) {
    const itemAtIdx = accountNumberArr[i];
    const parsedItem = parseInt(itemAtIdx);
    if (isNaN(parsedItem)) {
      return true;
    }
  }
  return false;
};

export const extractPhoneNumber = (number: any) => {
  return number?.replace(/\D/g, "");
};

export const detectDeviceType = (): string => {
  const userAgent = navigator.userAgent;

  if (/iPad/i.test(userAgent)) {
    return "iPad";
  } else if (/android/i.test(userAgent) && !/mobile/i.test(userAgent)) {
    return "Tablet";
  } else if (/iPhone|iPod/i.test(userAgent)) {
    return "iOS";
  } else if (/Windows/i.test(userAgent)) {
    return "Windows";
  } else if (/Mac/i.test(userAgent)) {
    return "MacOS";
  } else if (/Linux/i.test(userAgent)) {
    return "Linux";
  } else {
    return "Unknown";
  }
};

export const detectDevice = (): string => {
  const userAgent = navigator.userAgent;

  if (/Mobi/i.test(userAgent) && !/iPad/i.test(userAgent)) {
    return "Mobile";
  } else if (/iPad/i.test(userAgent)) {
    return "iPad";
  } else if (/android/i.test(userAgent) && !/mobile/i.test(userAgent)) {
    return "Tablet";
  } else {
    return "Desktop";
  }
};

export const validationForm = (value: string, name: string, dataValue: string) => {
  const strongRegex = /^[\w+!#$%&'*\/=?^`{|}~.-]+@\w+([\.-]?\w+)*(\.\w{2,24})+$/i;
  let zipcodeRegex = /^\d{5}(-\d{4})?$|^\d{9}$/;
  let passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])\S{8,}$/;
  var securityRegex = /^\d+$/;
  var regName = /^[a-zA-Z\s'-]+$/;

  switch (name) {
    case "email":
      if (!value || value.trim() === "") {
        return "Email is required.";
      } else if (!strongRegex.test(value.toLowerCase())) {
        return "Email is invalid.";
      } else {
        return "";
      }

    case "confirmEmail":
      if (!value || value.trim() === "") {
        return "Confirm email is required.";
      } else if (value.toLowerCase() !== dataValue.toLowerCase()) {
        return "Confirm email is not same.";
      } else {
        return "";
      }
    case "firstName":
      if (!value || value.trim() === "") {
        return "First name is required.";
      } else if (!regName.test(value)) {
        return "First name should be valid.";
      } else {
        return "";
      }
    case "lastName":
      if (!value || value.trim() === "") {
        return "Last name is required.";
      } else if (!regName.test(value)) {
        return "Last name should be valid.";
      } else {
        return "";
      }
    case "address":
      if (!value || value.trim() === "") {
        return "address is required.";
      } else {
        return "";
      }
    case "adressLine1":
      if (!value || value.trim() === "") {
        return "Adress line1 is required.";
      } else {
        return "";
      }
    case "adressLine2":
      if (!value || value.trim() === "") {
        return "Adress line2 is required.";
      } else {
        return "";
      }
    case "city":
      if (!value || value.trim() === "") {
        return "City is required.";
      } else {
        return "";
      }
    case "state":
      if (!value || value.trim() === "") {
        return "State is required.";
      } else {
        return "";
      }
    case "phone":
      if (!value || value.trim() === "") {
        return "Mobile number is required.";
      } else if (dataValue.length !== 10) {
        return "Phone is invalid.";
      } else {
        return "";
      }
    case "userName":
      if (!value || value.trim() === "") {
        return "User name is required.";
      } else {
        return "";
      }

    case "newPassword":
      if (!value || value.trim() === "") {
        return "New password is required.";
      } else if (!passwordRegex.test(value)) {
        return "New password is invalid.";
      } else {
        return "";
      }
    case "confirmNewPassword":
      if (!value || value.trim() === "") {
        return "Confirm new password is required.";
      } else if (value !== dataValue) {
        return "Confirm new password is not same.";
      } else {
        return "";
      }
    case "password":
      if (!value || value.trim() === "") {
        return "Password is required.";
      } else if (!passwordRegex.test(value)) {
        return "Password is invalid.";
      } else {
        return "";
      }
    case "currentPassword":
      if (!value || value.trim() === "") {
        return "Current password is required.";
      } else if (!passwordRegex.test(value)) {
        return "Current Password is invalid.";
      } else {
        return "";
      }
    case "confirmPassword":
      if (!value || value.trim() === "") {
        return "Confirm password is required.";
      } else if (value !== dataValue) {
        return "Confirm password is not same";
      } else {
        return "";
      }
    case "billingAccountNumber":
      const formattedBillingAccountNumber = value.split("-").join("");
      if (!formattedBillingAccountNumber || formattedBillingAccountNumber.trim() === "") {
        return "Billing account number is required.";
      } else if (formattedBillingAccountNumber.length !== 17) {
        return "Billing account number is invalid.";
      } else {
        return "";
      }
    case "pin":
      if (!value || value.trim() === "") {
        return "PIN is required.";
      } else if (value.length !== 4) {
        return "PIN should be 4 digit.";
      } else {
        return "";
      }
    case "zipcode":
      if (!value || value.trim() === "") {
        return "Zip code is required.";
      } else if (!zipcodeRegex.test(value)) {
        return "Zip code should be 5 digit.";
      } else {
        return "";
      }
    case "voicemail":
      if (!value || value.trim() === "") {
        return "Voicemail is required.";
      } else if (value.length !== 4) {
        return "Voicemail should be 4 digit.";
      } else {
        return "";
      }
    case "phoneNumber":
      if (!value || value.trim() === "") {
        return "Mobile number is required.";
      } else if (value.replace(/[() -]/g, "").length !== 10) {
        return "Mobile number should be 10 digit.";
      } else {
        return "";
      }
    case "confirmPhoneNumber":
      if (!value || value.trim() === "") {
        return "Confirm Mobile number is required.";
      } else if (value !== dataValue) {
        return "Confirm Mobile number is not same.";
      } else {
        return "";
      }
    case "security":
      if (!value || value?.trim() === "") {
        return "Security code is required.";
      } else if (value.length !== 6) {
        return "Security code should be 6 digit.";
      } else if (!securityRegex.test(value)) {
        return "Enter only numbers.";
      } else {
        return "";
      }
    case "otpPhone":
      if (!value || value?.trim() === "") {
        return "Security code is required.";
      } else if (value.length !== 6) {
        return "Security code should be 6 digit.";
      } else if (!securityRegex.test(value)) {
        return "Enter only numbers.";
      } else {
        return "";
      }
    case "otpEmail":
      if (!value || value?.trim() === "") {
        return "Security code is required.";
      } else if (value.length !== 6) {
        return "Security code should be 6 digit.";
      } else if (!securityRegex.test(value)) {
        return "Enter only numbers.";
      } else {
        return "";
      }
    case "portInNumber":
      if (!value || value.trim() === "") {
        return "Port In Number is required.";
      } else if (value.length !== 10) {
        return "Port In Number should be 10 digit.";
      } else {
        return "";
      }

    case "accountNumber":
      if (!value || value.trim() === "") {
        return "Account Number is required.";
      } else {
        return "";
      }
    case "existingPin":
      if (!value || value.trim() === "") {
        return "Existing Pin is required.";
      } else if (value.length !== 4) {
        return "Existing Pin should be 4 digit.";
      } else {
        return "";
      }

    case "existingTelecomAddress":
      if (!value || value.trim() === "") {
        return "Existing Telecom address is required.";
      } else {
        return "";
      }
    default: {
      return "";
    }
  }
};

export function getTroubleTicketActiveStep(status: string) {
  const statusToLowerCase = status?.toLowerCase();

  switch (true) {
    case [COMPLETE, INFORMATION_PROVIDED, PROBLEM_SOLVED, MERGED].includes(statusToLowerCase):
      return 2;

    case [IN_PROGRESS, WAITING_FOR_DETAILS, RESEARCHING, ON_HOLD].includes(statusToLowerCase):
      return 1;

    default:
      return 1;
  }
}

export function formatDateString(date: string, type: string) {
  const dateObject = new Date(date);

  return moment(dateObject).format(type);
}

export const validInputSubmit = (validData: any) => {
  let errmessage: any = {
    email: "",
    confirmEmail: "",
    billingAccountNumber: "",
    pin: "",
    zipcode: "",
    firstName: "",
    lastName: "",
    userName: "",
    phone: "",
    confirmPassword: "",
    phoneNumber: "",
    confirmPhoneNumber: "",
    security: "",
    currentPassword: "",
    password: "",
    newPassword: "",
    confirmNewPassword: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    voicemail: "",
    otpPhone: "",
    otpEmail: "",
    validatePhoneNumberError: "",
    address: "",
    portInNumber: "",
    accountNumber: "",
    existingPin: "",
    existingTelecomAddress: "",
  };

  Object.keys(validData).forEach((name) => {
    let error = {};

    if (validData.hasOwnProperty("confirmPassword")) {
      error = validationForm(validData[name], name, validData["password"]);
    } else if (validData.hasOwnProperty("confirmPhoneNumber")) {
      error = validationForm(validData[name], name, validData["phoneNumber"]);
    } else if (validData.hasOwnProperty("confirmNewPassword")) {
      error = validationForm(validData[name], name, validData["newPassword"]);
    } else {
      error = validationForm(validData[name], name, validData["email"]);
    }

    if (error) {
      errmessage[name] = error;
    }
  });

  return errmessage;
};

export function parseAddress(address: string): CustomerAddress | null {
  try {
    const addressParts = address?.split(",");

    const addressLine = addressParts[0]?.trim();
    const city = addressParts[1]?.trim();
    const state = addressParts[2]?.trim();
    const zip = addressParts[3]?.trim();

    const customerAddress = {
      addressLine: addressLine,
      city: city,
      state: state,
      zip: zip,
    };

    return customerAddress;
  } catch (err) {
    console.log("error parsing address", err);
    return null;
  }
}

export function formatMessageTime(messageDate: any) {
  var currentDate = new Date();
  var today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

  if (messageDate >= today) {
    var formattedTime = messageDate.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
    });
    return formattedTime;
  } else {
    var formattedDate = messageDate.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
    return formattedDate;
  }
}

export function splitMessageBybraces(message?: string) {
  if (!message) {
    return [];
  }
  return message?.split("{}");
}

export function splitMessageByBracesAndFullStop(message?: string) {
  const messageWithoutBraces = splitMessageBybraces(message).join("");
  const messageWithoutFullStop = messageWithoutBraces.split(".");

  if (messageWithoutFullStop[messageWithoutFullStop.length - 1] === "") {
    messageWithoutFullStop.pop();
  }

  const messageArrayWithFullStop = messageWithoutFullStop.map((line) => {
    return `${line}.`;
  });
  return messageArrayWithFullStop;
}

export const formatDateAndTime = (dateString: string, timeString: string) => {
  const date = new Date(dateString);
  const month = date.toLocaleString("default", { month: "short" });
  const day = date.getDate();
  const [hour, minute] = timeString.split(":");
  const time = new Date();
  time.setHours(parseInt(hour, 10));
  time.setMinutes(parseInt(minute, 10));
  const formattedTime = time.toLocaleString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
  return `${month} ${day}, ${formattedTime}`;
};
