import React from "react";
import { Box } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { PickersCalendarHeaderProps } from "@mui/x-date-pickers/PickersCalendarHeader";
import { Dayjs } from "dayjs";
import { CustomCalendarHeaderRoot, MonthDisplay } from "../../../../Styles/GlobalStyles";
import IconButton from "@mui/material/IconButton";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

type CustomCalendarProps = {
  selectedDate: Dayjs | null;
  onDateChange: (date: Dayjs | null) => void;
  minDate?: Dayjs;
  maxDate?: Dayjs;
};

function CustomCalendarHeader(props: PickersCalendarHeaderProps<Dayjs>) {
  const { currentMonth, onMonthChange } = props;

  const selectNextMonth = () => onMonthChange(currentMonth.add(1, "month"), "left");
  const selectPreviousMonth = () => onMonthChange(currentMonth.subtract(1, "month"), "right");

  return (
    <CustomCalendarHeaderRoot>
      <Stack spacing={1} direction="row">
        <IconButton
          onClick={selectPreviousMonth}
          title="Previous month"
          style={{ color: "secondary" }}>
          <ChevronLeft />
        </IconButton>
      </Stack>
      <MonthDisplay>
        <Typography variant="body2">{currentMonth.format("MMMM")}</Typography>
      </MonthDisplay>
      <Stack spacing={1} direction="row">
        <IconButton onClick={selectNextMonth} title="Next month" style={{ color: "secondary" }}>
          <ChevronRight />
        </IconButton>
      </Stack>
    </CustomCalendarHeaderRoot>
  );
}

const CustomCalendar: React.FC<CustomCalendarProps> = ({
  selectedDate,
  onDateChange,
  minDate,
  maxDate,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
        <DateCalendar
          value={selectedDate}
          onChange={onDateChange}
          slots={{ calendarHeader: CustomCalendarHeader }}
          minDate={minDate}
          maxDate={maxDate}
          sx={{
            "& .MuiPickersCalendarHeader-root": {
              marginBottom: "16px",
            },
            "& .MuiPickersDay-root": {
              margin: { xs: 0, sm: "0 5px" },
            },
            "& .MuiDayCalendar-weekDayLabel": {
              margin: { xs: 0, sm: "0 10px" },
            },
          }}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default CustomCalendar;
