import { useSelector } from "react-redux";
import {
  ROUTER_CONNECTION_INACTIVE_TEXT,
  STATUS_UNABLE_TO_CONNECT,
} from "../../../../Constants/constants";
import { CenterStack } from "../../../../Styles/GlobalStyles";
import { Theme } from "../../../../Styles/GlobalTheme";
import { Box, Grid, Stack, Typography } from "@mui/material";

const ExpandableSectionContent = ({ data, message, submessage, supportingText }: any) => {
  const statusActiveArr = data?.filter(
    (item: any) =>
      item?.value?.toLowerCase() === "active" || item?.value?.toLowerCase() === "tested ok"
  );
  const handleStatusColor = (item: any) => {
    const value = item?.value?.toLowerCase();
    switch (true) {
      case item?.name === "STATUS" && value.includes("ok"):
        return Theme.palette.primary.main;
      case value === "active":
        return Theme.palette.primary.main;
      case value === "inactive":
        return "#DF6B00";
      case value?.includes("unable"):
        return "#DF6B00";
      default:
        return Theme.palette.customcolor.customText;
    }
  };

  const getValue = (item: any) => {
    const itemName = item?.name?.toLowerCase();
    const itemValue = item?.value?.toLowerCase();

    if (itemName?.includes("status")) {
      if (itemValue?.includes("ok")) {
        return "ACTIVE";
      } else if (itemValue?.includes("inactive")) {
        return STATUS_UNABLE_TO_CONNECT.toUpperCase();
      }
    }
    return itemValue?.toUpperCase();
  };
  return (
    <Box my={2}>
      <Grid container direction="row" px={{ xs: 1, sm: 2 }} justifyContent="flex-start">
        <>
          {data?.map((item: any) => {
            const value = getValue(item);
            return (
              item?.value && (
                <Grid item xs={12} sm={6} key={item?.name}>
                  <Stack
                    direction="row"
                    my={1}
                    sx={{
                      minWidth: { xs: "100%", sm: "400px" },
                    }}>
                    <Typography
                      fontSize={{ xs: "12px", sm: "13px" }}
                      color={Theme.palette.customcolor.customText}
                      whiteSpace="nowrap">
                      {item?.name} : &nbsp;
                    </Typography>
                    <Typography
                      fontSize={{ xs: "12px", sm: "13px" }}
                      color={() => handleStatusColor(item)}
                      fontWeight={600}>
                      {value}
                    </Typography>
                  </Stack>
                </Grid>
              )
            );
          })}

          <CenterStack flexWrap="wrap" width="100%" my={2} px={{ xs: 1, sm: 0 }}>
            <Typography
              textAlign="center"
              fontWeight={message === ROUTER_CONNECTION_INACTIVE_TEXT ? 600 : 0}
              color={
                statusActiveArr?.[0]?.value?.toLowerCase() === "active" ||
                statusActiveArr?.[0]?.value?.toLowerCase() === "tested ok"
                  ? Theme.palette.primary.main
                  : "#DF6B00"
              }
              fontSize={{ xs: "12px", sm: "13px" }}>
              {message}
            </Typography>
            <Typography
              textAlign="center"
              fontSize={{ xs: "12px", sm: "13px" }}
              color={Theme.palette.customcolor.customText}>
              {submessage}
            </Typography>
          </CenterStack>
        </>
      </Grid>
    </Box>
  );
};

export default ExpandableSectionContent;
