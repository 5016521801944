import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useCustomerType } from "../../hooks/Login/useCustomerType";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner/LoadingSpinner";

export default function ParkingRoute() {
  const email = useSelector((state: any) => state?.userInfo?.email);
  const impersonatedEmail = localStorage.getItem("impersonatedEmail");

  const { getCustomerType, customerTypeLoading } = useCustomerType(email || impersonatedEmail);
  useEffect(() => {
    if (email || impersonatedEmail) getCustomerType();
  }, []);
  return <div>{customerTypeLoading && <LoadingSpinner />}</div>;
}
