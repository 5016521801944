import React from "react";
import SpeedData from "./SpeedData";
import { useLazyQuery } from "@apollo/client";
import "react-circular-progressbar/dist/styles.css";
import { GET_SPEED_TEST_DETAILS } from "../../query";
import { useSelector, useDispatch } from "react-redux";
import { Theme } from "../../../../Styles/GlobalTheme";
import Button from "../../../../components/Button/Button";
import arrowIcon from "../../../../assets/icons/arrow.svg";
import { Box, Grid, Stack, Typography } from "@mui/material";
import arrowUpload from "../../../../assets/icons/Upload Logo.svg";
import { BouncingDotsLoader } from "./BouncingDots/BouncingDotsLoader";
import { CircularProgressBar } from "./Components/CircularProgressBar";
import {
  CenterStack,
  ColumnCenterStack,
  ItalicTypography,
  StyledA,
  StyledElement,
} from "../../../../Styles/GlobalStyles";
import { ErrorComponent } from "../../../../components/ErrorComponent/ErrorComponent";
import {
  setLoadingDownload,
  setLoadingUpload,
  setSpeedData,
  setSpeedResult,
  setSpeedTestComplete,
} from "../../../../Redux/Slices/SpeedTestAnimationSlice";
import ContactSupport from "../../../../components/shared/ContactSupport/ContactSupport";
import { setSpeedTestApiError } from "../../../../Redux/Slices/NetworkStatusSlice";
import { SPEED_TEST_ERROR_MESSAGE, SPEED_TEST_HERE_LINK } from "../../../../Constants/constants";
import { NetworkStatusErrorComponent } from "../NetworkStatus/NetworkStatusErrorComponent";
import { typography } from "material-ui/styles";
import SpeedoMeter from "./SpeedoMeter";
import { getFlags } from "../../../../Services/helper";
import {
  setExistingTicket,
  setOpenOutageBanner,
  setOpenServiceTicketDialog,
} from "../../../../Redux/Slices/TroubleTicketSlice";

const LatestSpeedTest = () => {
  const dispatch = useDispatch();
  const { accountNumber, ndsObj } = useSelector((state: any) => state?.customerInfoDetails);
  const { showOutage } = useSelector((state: any) => state.networkStatusDetails);
  const { speedData, speedTestComplete, speedResult, loadingDownload } = useSelector(
    (state: any) => state.speedTestAnimation
  );
  const { troubleTicketDetails } = useSelector((state: any) => state.troubleTicketDetails);
  const { speedTestApiError } = useSelector((state: any) => state.networkStatusDetails);
  const [hideButton, setHideButton] = React.useState(false);
  const envFlags = useSelector((state: any) => state?.userInfo?.envFlags);
  const SHOW_CREATE_SERVICE_TICKET = getFlags("enable_create_service_ticket", envFlags);

  const [getSpeedTestDetails, { loading: speedestLoading, data: speedTestData }] = useLazyQuery(
    GET_SPEED_TEST_DETAILS,
    {
      variables: {
        accountNumber: accountNumber,
        isNds: ndsObj?.isNDS,
      },
      fetchPolicy: "network-only",
      onCompleted: (data: any) => {
        handleSpeedTest(data);
      },
      onError: () => handleFailureRunSpeed(),
    }
  );

  const testDetails = speedTestData?.speedTestDetails?.testDetails;

  const handleFailureRunSpeed = () => {
    dispatch(setLoadingUpload(false));
    dispatch(setLoadingDownload(false));
    dispatch(setSpeedTestApiError(true));
  };

  const handleRunSpeedTest = () => {
    getSpeedTestDetails();
    dispatch(setSpeedTestApiError(false));
    dispatch(setSpeedData({}));
    dispatch(setSpeedResult({}));
  };

  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleSpeedTest = async (data: any) => {
    try {
      setHideButton(true);
      dispatch(setSpeedTestApiError(false));
      dispatch(
        setSpeedData({
          downloadSpeed: Math.round(data?.speedTestDetails?.testDetails?.downloadSpeed),
        })
      );
      dispatch(
        setSpeedResult({
          downloadSpeed: Math.round(data?.speedTestDetails?.testDetails?.downloadSpeed),
        })
      );
      dispatch(setLoadingDownload(false));

      await delay(10000);

      dispatch(setLoadingUpload(true));
      dispatch(setSpeedData({ downloadSpeed: "", uploadSpeed: "", ping: "" }));

      await delay(10000);

      dispatch(setLoadingUpload(false));
      dispatch(
        setSpeedData({ uploadSpeed: Math.round(data?.speedTestDetails?.testDetails?.uploadSpeed) })
      );
      dispatch(
        setSpeedResult({
          downloadSpeed: Math.round(data?.speedTestDetails?.testDetails?.downloadSpeed),
          uploadSpeed: Math.round(data?.speedTestDetails?.testDetails?.uploadSpeed),
        })
      );

      await delay(10000);

      dispatch(
        setSpeedData({
          downloadSpeed: Math.round(data?.speedTestDetails?.testDetails?.downloadSpeed),
          uploadSpeed: Math.round(data?.speedTestDetails?.testDetails?.uploadSpeed),
          ping: data?.speedTestDetails?.testDetails?.downstreamLatency,
        })
      );
      dispatch(
        setSpeedResult({
          downloadSpeed: Math.round(data?.speedTestDetails?.testDetails?.downloadSpeed),
          uploadSpeed: Math.round(data?.speedTestDetails?.testDetails?.uploadSpeed),
          ping: data?.speedTestDetails?.testDetails?.downstreamLatency,
        })
      );
      dispatch(setSpeedTestComplete(true));
      setHideButton(false);
    } catch (error) {
      console.error("An error occurred running through speed test", error);
    }
  };

  const handleReportServiceTicket = () => {
    dispatch(setOpenServiceTicketDialog(true));

    if (troubleTicketDetails?.tickets?.length) {
      dispatch(setExistingTicket(true));
      return;
    }

    if (showOutage) {
      dispatch(setOpenOutageBanner(true));
    }
  };

  speedestLoading && dispatch(setLoadingDownload(true)) && dispatch(setSpeedTestComplete(false));

  return (
    <Grid container alignItems={"center"} justifyContent={"center"} py={9}>
      {speedTestApiError ? (
        <Grid item xs={12}>
          <NetworkStatusErrorComponent
            handleRefresh={handleRunSpeedTest}
            errorMessage={SPEED_TEST_ERROR_MESSAGE}
            buttonTitle={"TRY AGAIN"}
          />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} md={6}>
            <Grid container alignItems="center" justifyContent="center">
              {!speedTestComplete ? (
                <Box
                  sx={{
                    width: 240,
                    height: 200,
                    margin: "0 2rem",
                    pt: 1,
                  }}>
                  {speedData.downloadSpeed ? (
                    <SpeedoMeter
                      speed={speedData.downloadSpeed}
                      strokColor={Theme.palette.primary.main}
                      text={"Megabits per second"}
                    />
                  ) : speedData.uploadSpeed ? (
                    <SpeedoMeter
                      speed={speedData.uploadSpeed}
                      strokColor={Theme.palette.secondary.main}
                      text={"Megabits per second"}
                    />
                  ) : (
                    <SpeedoMeter speed={0} strokColor={"#d6d6d6"} text="Speed Test" />
                  )}
                </Box>
              ) : (
                <Box my={{ xs: 6, md: 4 }}>
                  <SpeedData
                    speedUpload={speedResult.uploadSpeed}
                    speedDownload={speedResult.downloadSpeed}
                    ping={speedResult.ping}
                    testDetails={testDetails}
                    hideButton={hideButton}
                    handleRunSpeedTest={handleRunSpeedTest}
                  />
                </Box>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} spacing={{ xs: 2, sm: 3 }}>
            {!speedTestComplete && (
              <Box my={{ xs: 6, md: 4 }}>
                <SpeedData
                  speedUpload={speedResult.uploadSpeed}
                  speedDownload={speedResult.downloadSpeed}
                  ping={speedResult.ping}
                  testDetails={testDetails}
                  hideButton={hideButton}
                  handleRunSpeedTest={handleRunSpeedTest}
                />
              </Box>
            )}
            {speedTestComplete && (
              <Stack
                spacing={{ xs: 1, sm: 2 }}
                mt={{ xs: 4, md: 6 }}
                justifyContent={"center"}
                alignItems={"center"}>
                <Typography
                  textAlign="center"
                  fontWeight={600}
                  color={
                    speedTestData?.speedTestDetails?.testDetails?.SpeedInLineWithPlan
                      ? Theme.palette.primary.main
                      : "#DF6B00"
                  }>
                  {speedTestData?.speedTestDetails?.testDetails?.SpeedInLineWithPlan
                    ? "Your Ziply Fiber speed is performing as expected."
                    : "Your Ziply Fiber connection is slower than it should be."}
                </Typography>

                <Stack alignItems="center" spacing={{ xs: 1, sm: 1.5 }} textAlign="center">
                  {speedTestData?.speedTestDetails?.testDetails?.SpeedInLineWithPlan ? (
                    <>
                      <ItalicTypography>
                        If you are experiencing issues, it could be the WiFi connection or your
                        device.
                      </ItalicTypography>
                      <ItalicTypography>
                        The help center will guide you through troubleshooting steps, click{" "}
                        <StyledA target="blank" href={SPEED_TEST_HERE_LINK}>
                          HERE
                        </StyledA>
                        .
                      </ItalicTypography>
                    </>
                  ) : (
                    <ColumnCenterStack gap={0}>
                      <Typography fontSize={{ xs: "12px", sm: "13px" }}>
                        If you are experiencing issues, it could be a network issue or a poor WiFi
                        connection with your device.
                      </Typography>
                      <CenterStack direction={"row"} flexWrap={"wrap"} mt={2} spacing={1}>
                        <Typography fontSize={{ xs: "12px", sm: "13px" }}>
                          You have some options: Use our{" "}
                          <StyledA target="blank" href={SPEED_TEST_HERE_LINK} sx={{ ml: 0.5 }}>
                            HELP CENTER
                          </StyledA>
                        </Typography>
                        <Typography fontSize={{ xs: "12px", sm: "13px" }} sx={{ ml: 0.5 }}>
                          for guided troubleshooting steps,
                        </Typography>
                        <Stack direction="row" alignItems="center" flexWrap="nowrap">
                          <ContactSupport
                            fontSize="13px"
                            fontSizeMobile={"12px"}
                            text="CHAT WITH AN AGENT"
                          />
                          <Typography
                            fontSize={{ xs: "12px", sm: "13px" }}
                            sx={{ ml: 1, whiteSpace: "nowrap" }}>
                            or call
                          </Typography>
                          <StyledA
                            href="tel:1-866-699-4759"
                            sx={{ mx: 0.5, fontSize: "13px", whiteSpace: "nowrap" }}>
                            1-866-MYZIPLY
                          </StyledA>
                        </Stack>
                        <Typography
                          fontSize={{ xs: "12px", sm: "13px" }}
                          sx={{ ml: 0.5, whiteSpace: "nowrap" }}>
                          for additional support.
                        </Typography>
                      </CenterStack>
                    </ColumnCenterStack>
                  )}
                </Stack>
                {speedTestComplete && (
                  <Box
                    display={loadingDownload || hideButton ? "none" : "flex"}
                    justifyContent="center"
                    alignItems="center">
                    <Button title={"TEST AGAIN"} type="root" onClick={handleRunSpeedTest} />
                  </Box>
                )}
              </Stack>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default LatestSpeedTest;
