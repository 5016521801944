import { FormControl, Grid, Stack, Typography } from "@mui/material";
import { ErrorMessage, ProfileInput, ProfileTypography } from "../../../../Styles/GlobalStyles";
import Button from "../../../../components/Button/Button";
import { formatPhoneNumber, validInputSubmit, validationForm } from "../../../../Services/helper";
import {
  EDIT_EMAIL_ERROR_MESSAGE,
  EDIT_PHONE_ERROR_MESSAGE,
} from "../../../../Constants/constants";
import { useSelector } from "react-redux";

export const EditProfile = ({
  edit,
  profileErrors,
  profileForm,
  error,
  setError,
  setProfileForm,
  setProfileErrors,
  handleSendOTP,
  handleEditEmail,
  handleEditPhone,
  validatePhoneNumber,
}: any) => {
  const { emailContact, telephoneContact } = useSelector((state: any) => state.customerInfoDetails);
  const validPhoneNumber = useSelector((state: any) => state?.billingAddress?.validPhoneNumber);
  const contactNumber = telephoneContact?.telephoneNumber ?? "";
  const contactEmail = emailContact?.emailAddress ?? "";
  const handlePhoneChange = (e: any) => {
    setProfileForm({
      ...profileForm,
      phoneNumber: formatPhoneNumber(e.target.value.replace(/\D/g, "")),
    });
    setProfileErrors({});
    setError("");
  };
  const handleUpdate = () => {
    if (edit.phone) {
      const phoneNumberError = validationForm(
        profileForm.phoneNumber,
        "phoneNumber",
        profileForm.phoneNumber
      );
      const confirmPhoneNumberError = validationForm(
        profileForm.confirmPhoneNumber,
        "confirmPhoneNumber",
        profileForm.phoneNumber
      );
      if (phoneNumberError || confirmPhoneNumberError) {
        setProfileErrors({
          ...profileErrors,
          phoneNumber: phoneNumberError,
          confirmPhoneNumber: confirmPhoneNumberError,
        });
      } else {
        handleSendOTP();
      }
    } else if (edit.email) {
      const emailError = validationForm(profileForm.email, "email", profileForm.email);
      const confirmEmailError = validationForm(
        profileForm.confirmEmail,
        "confirmEmail",
        profileForm.confirmEmail
      );
      if (!profileForm.email || !profileForm.confirmEmail) {
        setProfileErrors({
          ...profileErrors,
          email: emailError,
          confirmEmail: confirmEmailError,
        });
      } else {
        handleSendOTP();
      }
    }
  };

  const handleConfirmPhoneChange = (e: any) => {
    setProfileForm({
      ...profileForm,
      confirmPhoneNumber: formatPhoneNumber(e.target.value.replace(/\D/g, "")),
    });
    setProfileErrors({});
    setError("");
  };
  const handleConfirmEmailChange = (e: any) => {
    setProfileForm({ ...profileForm, confirmEmail: e.target.value });
    setProfileErrors({});
    setError("");
  };

  const handleEmailChange = (e: any) => {
    setProfileForm({ ...profileForm, email: e.target.value });
    setProfileErrors({});
    setError("");
  };
  const handleSameContactError = () => {
    if (edit.phone) {
      if (profileForm?.phoneNumber !== undefined) {
        validatePhoneNumber();
      }
      if (profileForm?.phoneNumber?.replace(/[() -]/g, "") === contactNumber) {
        setError(EDIT_PHONE_ERROR_MESSAGE);
      } else if (profileForm?.phoneNumber !== contactNumber) {
        const errors = validInputSubmit(profileForm);
        if (Object.keys(errors).length > 0) {
          setProfileErrors(errors);
        }
      }
    } else if (edit.email) {
      if (profileForm.email != undefined) {
        const enteremail = profileForm.email.toLowerCase();
        if (contactEmail === enteremail) {
          setError(EDIT_EMAIL_ERROR_MESSAGE);
        } else if (contactEmail !== profileForm?.email?.toLocaleLowerCase()) {
          const errors = validInputSubmit(profileForm);
          if (Object.keys(errors).length > 0) {
            setProfileErrors(errors);
          }
        }
      }
    }
  };
  
  return (
    <>
      <Grid container gap={2} justifyContent={"space-between"}>
        {edit.phone ? (
          <>
            <Grid item xs={12} sm={5.6}>
              <FormControl fullWidth>
                <ProfileTypography>Mobile number</ProfileTypography>
                <ProfileInput
                  error={profileErrors?.phoneNumber ? true : false}
                  onBlur={handleSameContactError}
                  inputProps={{ "data-testid": "phoneNumber" }}
                  type="text"
                  name="phoneNumber"
                  value={profileForm?.phoneNumber}
                  hasError={
                    profileErrors?.phoneNumber ||
                    error.trim().length > 0 ||
                    profileErrors?.validatePhoneNumberError
                  }
                  onChange={handlePhoneChange}
                />
                {profileErrors?.phoneNumber && (
                  <ErrorMessage error={profileErrors?.phoneNumber ? true : false}>
                    {profileErrors?.phoneNumber}
                  </ErrorMessage>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5.6}>
              <FormControl fullWidth>
                <ProfileTypography>Confirm Mobile number</ProfileTypography>
                <ProfileInput
                  onBlur={handleSameContactError}
                  type="text"
                  inputProps={{ "data-testid": "confirmNumber" }}
                  name="confirmPhoneNumber"
                  value={profileForm?.confirmPhoneNumber}
                  hasError={profileErrors?.confirmPhoneNumber || error.trim().length > 0}
                  onChange={handleConfirmPhoneChange}
                />
                {profileErrors?.confirmPhoneNumber && (
                  <ErrorMessage error={profileErrors?.confirmPhoneNumber ? true : false}>
                    {profileErrors?.confirmPhoneNumber}
                  </ErrorMessage>
                )}
              </FormControl>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={5.6}>
              <FormControl fullWidth>
                <ProfileTypography>Email address</ProfileTypography>
                <ProfileInput
                  onBlur={handleSameContactError}
                  name="email"
                  inputProps={{ "data-testid": "email" }}
                  value={profileForm?.email}
                  hasError={profileErrors?.email || error.trim().length > 0}
                  onChange={handleEmailChange}
                />
                {profileErrors?.email && (
                  <ErrorMessage error={profileErrors?.email ? true : false}>
                    {profileErrors?.email}
                  </ErrorMessage>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5.6}>
              <FormControl fullWidth>
                <ProfileTypography>Confirm email address</ProfileTypography>
                <ProfileInput
                  onBlur={handleSameContactError}
                  value={profileForm?.confirmEmail}
                  inputProps={{ "data-testid": "confirmEmail" }}
                  name="confirmEmail"
                  hasError={profileErrors?.confirmEmail || error.trim().length > 0}
                  onChange={handleConfirmEmailChange}
                />
                {profileErrors?.confirmEmail && (
                  <ErrorMessage error={profileErrors?.confirmEmail ? true : false}>
                    {profileErrors?.confirmEmail}
                  </ErrorMessage>
                )}
              </FormControl>
            </Grid>
          </>
        )}
        {profileErrors?.validatePhoneNumberError && (
          <ErrorMessage error={profileErrors?.validatePhoneNumberError ? true : false}>
            {profileErrors?.validatePhoneNumberError}
          </ErrorMessage>
        )}
      </Grid>

      {edit.email && (
        <Typography mt={1} variant="caption" fontWeight={600} component={"p"}>
          Note: For your security, you will be logged out and prompted to sign in again with your
          updated email address.
        </Typography>
      )}

      {error && (
        <ErrorMessage
          sx={{ my: "10px" }}
          data-testid={"error-message"}
          error={error ? true : false}>
          {error}
        </ErrorMessage>
      )}
      <Stack justifyContent={"center"} direction={"row"} gap={3} mt={3} mb={1}>
        {edit.phone ? (
          <Button title="CANCEL" type="pay" onClick={handleEditPhone} />
        ) : (
          <Button title="CANCEL" type="pay" onClick={handleEditEmail} />
        )}
        <Button
          eventName={edit.phone ? "PHONE_UPDATE" : "EMAIL_UPDATE"}
          title="UPDATE"
          type="payment"
          disabled={edit.phone && (validPhoneNumber || profileForm?.phoneNumber == undefined)}
          onClick={handleUpdate}
        />
      </Stack>
    </>
  );
};
