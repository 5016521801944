import React from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";

export const GlobysIframe = () => {
  const { globysUrl } = useSelector((state: any) => state.billingAddress);

  const [width, setWidth] = React.useState<number>(0);

  const resolutions = {
    mobileResolution: window.screen.width >= 320 && window.screen.width < 500,
    tabletResolution: window.screen.width > 650 && window.screen.width <= 1024,
  };

  React.useEffect(() => {
    if (resolutions?.mobileResolution) {
      setWidth(380);
    } else if (resolutions?.tabletResolution) {
      setWidth(740);
    } else {
      setWidth(1200);
    }
  }, [resolutions]);

  return (
    <>
      <Box my={2}>
        <iframe height={800} title="globys" width={width} src={globysUrl} />
      </Box>
    </>
  );
};
