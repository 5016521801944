import React from "react";
import { Grid, Typography } from "@mui/material";
import { Theme } from "../../Styles/GlobalTheme";
import { ListStack, StyledUl } from "../../Styles/GlobalStyles";
import { PasswordCriteria as IPasswordCriteria } from "../../hooks/Password/usePasswordCriteria";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

type Props = {
  passwordCriteria: IPasswordCriteria;
};

const PasswordCriteriaWrapper = styled(Grid)`
  && {
    margin-bottom: 1rem;
  }
`;

const ListItem = styled.li<{ highlight: boolean }>`
  color: ${(props) =>
    props.highlight ? Theme.palette.primary.main : Theme.palette.customcolor.customText};
  font-weight: 600;
  font-size: 12px;
  list-style: none;
  &::before {
    content: ${(props) => (props.highlight ? '"\\2714"' : '"\\2022"')};
    margin-right: 0.5rem;
  }
`;

export default function PasswordCriteria({ passwordCriteria }: Props) {
  const location = useLocation();
  const isForgotPassword = location.pathname === "/login/forgot-password";

  const listStackDirection = isForgotPassword ? "column" : "row";
  const listStackSpacing = isForgotPassword ? "0.2rem" : "1.5rem";

  return (
    <PasswordCriteriaWrapper item xs={11}>
      <Typography fontSize={"14px"} variant="caption" color={Theme.palette.customcolor.customText} component={"span"}>
        Your password must have
      </Typography>
      <StyledUl>
        <ListStack
          color={Theme.palette.customcolor.customText}
          direction={{ xs: "column", sm: listStackDirection }}
          spacing={{ xs: 0, sm: listStackSpacing }}
          flexWrap={"wrap"}>
          <ListItem highlight={passwordCriteria.hasEightOrMoreChars}>8 or more characters</ListItem>
          <ListItem  highlight={passwordCriteria.hasAtleastOneUpperAndLowerCase}>
            Upper & lowercase letters
          </ListItem>
          <ListItem highlight={passwordCriteria.hasAtleastOneNumber}>At least one number</ListItem>
        </ListStack>
      </StyledUl>
    </PasswordCriteriaWrapper>
  );
}
