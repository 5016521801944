import React, { useEffect, useState, Fragment } from "react";
import { Helmet } from "react-helmet-async";
import {
  FormControlLabel,
  Stack,
  Typography,
  IconButton,
  InputAdornment,
  Box,
  styled,
  Snackbar,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoginHeader } from "../../components/shared/Login/LoginHeader";
import LoginBox from "../../components/shared/Login/LoginBox";
import {
  Android12Switch,
  CustomTextField,
  StyledElement,
  StyledLink,
  ResetPasswordInput,
  ErrorMessage,
  ErrorStack,
  LoginTypography,
  ErrorTypography,
  ContainerStack,
  LoginCenterStack,
  BoldStyledElement,
  BoldSpan,
  StyledDialog,
  CardHeading,
  LoginBtn,
} from "../../Styles/GlobalStyles";
import { useNavigate } from "react-router-dom";
import { LoginFooter } from "../../components/shared/Login/LoginFooter";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, analytics } from "../../Config/firebase";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner/LoadingSpinner";
import { Footer } from "../../components/shared/Footer/Footer";
import { logEvent } from "firebase/analytics";
import Button from "../../components/Button/Button";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ISAM, PAYMENT_DELAY_WARNING } from "./query";
import {
  APP_STORE_URL,
  MY_ACCOUNT,
  PLAY_STORE_URL,
  TELEMETRY_EVENT_LOGIN,
  TELEMETRY_FAILURE,
  TELEMETRY_PLATFORM,
  TELEMETRY_SUCCESS,
  USER_NOT_FOUND,
} from "../../Constants/constants";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  RSAOAEPPadding,
  logEvents,
  splitMessageByBracesAndFullStop,
  validInputSubmit,
} from "../../Services/helper";
import AppProposalModal from "../../components/AppProposalModal/AppProposalModal";
import { UpdateErrorComponent } from "../../components/shared/UpdateErrorComponent/UpdateErrorComponent";
import { useDispatch, useSelector } from "react-redux";
import { setModalContent, setOpenErrorModal } from "../../Redux/Slices/AppProposalSlice";
import { setForceReset, setUserLoggedInSuccess, userInfo } from "../../Redux/Slices/UserSlice";
import { Theme } from "../../Styles/GlobalTheme";
import { SEO } from "../../components/shared/SEO/SEO";
import { ACTION_TELEMETRY } from "../../Routes/query";
import { GET_PUBLIC_KEY } from "../Signin/query";
declare global {
  interface Window {
    loginCompleted: any;
  }
}

export default function Login({ setUser }: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [lsamError, setLsamError] = useState<boolean>(false);
  const [lsamErrorMessage, setLsamErrorMessage] = useState<string[]>([]);
  const [openDetailsRequiredPopup, setOpenDetailsRequiredPopup] = useState<boolean>(false);
  const [PaymentWarningMessage, setPaymentWarningMessage] = React.useState<any>({
    open: false,
    message: "",
    vertical: "top",
    horizontal: "center",
  });

  const [getPublicKey, { data: publicKey }] = useLazyQuery(GET_PUBLIC_KEY);
  const [actionTelemetry] = useMutation(ACTION_TELEMETRY);

  const { vertical, horizontal } = PaymentWarningMessage;

  const { openErrorModal } = useSelector((state: any) => state.appProposal);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleEmailInput = (data: any) => {
    setEmail(data);
    setErrorMsg("");
    setEmailError("");
  };

  const handlePasswordInput = (e: any) => {
    setPassword(e.target.value);
    setPasswordError("");
    setErrorMsg("");
  };

  const checkBoxHandler = () => {
    setChecked(!checked);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      let userCredentials: any = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredentials?.user;
      dispatch(setUserLoggedInSuccess(true));
      const tokenResponse = userCredentials?._tokenResponse;
      localStorage.setItem("tokenResponse", JSON.stringify(tokenResponse));
      setUser(user);
      navigate("/checking-account");
      logEvent(analytics, "Web_Login_Success");
      handleActionTelemetry(TELEMETRY_SUCCESS);
      setLoading(false);
      window?.minimizeChat && window?.minimizeChat();
      const robotsMetaTag = document.querySelector('meta[name="robots"]');
      if (robotsMetaTag) {
        robotsMetaTag.remove();
      }

      // Remove canonical link
      const canonicalLink = document.querySelector('link[rel="canonical"]');
      if (canonicalLink) {
        canonicalLink.remove();
      }
      if (checked) {
        localStorage.setItem("lastLogin", new Date().toDateString());
      }
    } catch (error: any) {
      setLoading(false);
      logEvent(analytics, "Web_Login_Failed");
      handleActionTelemetry(TELEMETRY_FAILURE, error);
      setErrorMsg(USER_NOT_FOUND);
    }
    setLoading(false);
  };

  const handleActionTelemetry = async (status: any, message?: any) => {
    const telemetryObj = {
      event_name: TELEMETRY_EVENT_LOGIN,
      platform: TELEMETRY_PLATFORM,
      customer_email: email,
      event_final_status: status,
      meta_data: message,
    };
    try {
      const encryptedData = RSAOAEPPadding(
        JSON.stringify(telemetryObj),
        publicKey?.getPublicKey?.publicKey
      );
      await actionTelemetry({
        variables: {
          payload: encryptedData,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const checkValidation = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!checked) {
      localStorage.setItem("KEEP_ME_LOGGED_IN", "false");
    }
    const errors = validInputSubmit({ email, password });
    if (Object.keys(errors).length > 0) {
      setEmailError(errors.email);
      setPasswordError(errors.password);
    }
    const isNotEmpty = Object.values(errors).every((value: any) => value === "");
    if (isNotEmpty) {
      try {
        handleIsam();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const [getPaymentWarningMessage, { data: PaymentWarningMessageData }] = useLazyQuery(
    PAYMENT_DELAY_WARNING,
    {
      onCompleted: (data) => {
        setPaymentWarningMessage({
          ...PaymentWarningMessage,
          open: data.homeScreenMessage?.error,
          message: data.homeScreenMessage?.message,
          autopayMessage: data.homeScreenMessage?.newAccountsMessage,
        });
      },
    }
  );

  useEffect(() => {
    getPaymentWarningMessage();
    getPublicKey();
  }, []);

  const [lsam, { loading: userLoading }] = useMutation(ISAM, {
    onError: (error: any) => {
      if (error.networkError.result?.errors[0]?.code === "503") {
        setLsamError(true);
      } else if (error.networkError.result?.errors[0]?.code === "403") {
        setOpenPopup(true);
        dispatch(setForceReset(true));
        dispatch(userInfo({ email: email }));
      } else if (error.networkError.result?.errors[0]?.code === "409") {
        const errorMessage = error.networkError.result?.errors[0]?.message;
        const splitMessage = splitMessageByBracesAndFullStop(errorMessage);

        setLsamErrorMessage(splitMessage);
        setOpenDetailsRequiredPopup(true);
      } else {
        handleLogin();
      }
    },
  });

  const handleIsam = async () => {
    try {
      const response = await lsam({
        variables: {
          email: email,
          password: password,
          app: MY_ACCOUNT,
        },
      });
      if (response?.data?.lsam?.error) {
        handleLogin();
      } else if (response?.data) {
        handleLogin();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const loader = userLoading || loading;

  const onEnterSubmit = (e: any) => {
    if (e.keyCode === 13) {
      checkValidation(e);
    }
  };

  const redirectToAppStore = () => {
    const userAgent = navigator.userAgent;
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      window.open(APP_STORE_URL, "_blank");
    } else if (/android/i.test(userAgent)) {
      window.open(PLAY_STORE_URL, "_blank");
    } else {
      dispatch(setModalContent(true));
    }
  };
  const isMobile = () => {
    return /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  };
  const isUserOnMobile = isMobile();

  const handleRegisterRedirect = () => {
    navigate({ pathname: "/register" }, { state: { data: email ? email : "" } });
  };
  return (
    <Fragment>
      <SEO
        title="My Account Login | ZiplyFiber"
        description="Log into your Ziply Fiber account to view or pay your bill, view orders and tickets, manage your account profile, troubleshoot, get help, and more."
      />
      {PaymentWarningMessageData?.homeScreenMessage?.error &&
        PaymentWarningMessageData?.homeScreenMessage?.paymentScreen &&
        PaymentWarningMessage?.message && (
          <Box sx={{ width: 500 }}>
            <Snackbar
              sx={{ textAlign: "center" }}
              anchorOrigin={{ vertical, horizontal }}
              open={true}
              message={PaymentWarningMessage?.message}
              key={vertical + horizontal}
            />
          </Box>
        )}
      <ContainerStack>
        <LoginHeader />
        <LoginCenterStack>
          <LoginBox>
            <Typography component={"h1"} sx={{ marginTop: "2rem" }} textAlign={"center"}>
              Please log in to your Ziply Fiber Account to view or pay your bill, see order status,
              manage appointments, and get help or run troubleshooting tests.
            </Typography>
            <form
              onSubmit={(e: React.SyntheticEvent) => checkValidation(e)}
              style={{ width: "100%" }}>
              <Stack width={"100%"} my={2}>
                <LoginTypography gutterBottom>Email</LoginTypography>
                <CustomTextField
                  onKeyDown={onEnterSubmit}
                  name="email"
                  fullWidth
                  onChange={(e: any) => handleEmailInput(e.target.value)}
                  required
                  hasError={emailError ? true : false}
                  value={email}
                  inputProps={{
                    "data-testid": "email",
                    id: "email",
                  }}
                />
                {emailError && (
                  <ErrorMessage error={emailError ? true : false}>{emailError}</ErrorMessage>
                )}
              </Stack>
              <Stack width={"100%"}>
                <LoginTypography gutterBottom>Password</LoginTypography>
                <ResetPasswordInput
                  onKeyDown={onEnterSubmit}
                  name="password"
                  inputProps={{
                    "data-testid": "password",
                    id: "password",
                  }}
                  fullWidth
                  value={password}
                  onChange={(e: any) => handlePasswordInput(e)}
                  id="outlined-adornment-password"
                  hasError={passwordError ? true : false}
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        id="togglePassword"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <Stack
                  direction={"row"}
                  justifyContent={passwordError ? "space-between" : "flex-end"}>
                  {passwordError && (
                    <ErrorMessage error={passwordError ? true : false}>
                      {passwordError}
                    </ErrorMessage>
                  )}
                  <StyledElement
                    id="forgotpassword"
                    pt={0.5}
                    variant="caption"
                    onClick={() => {
                      navigate("/login/forgot-password");
                    }}>
                    FORGOT PASSWORD?
                  </StyledElement>
                </Stack>
                <FormControlLabel
                  sx={{ paddingX: "4px", my: "8px" }}
                  control={
                    <Android12Switch
                      color="secondary"
                      checked={checked}
                      disableRipple
                      onChange={checkBoxHandler}
                    />
                  }
                  label={
                    <Typography fontSize={"12px"} variant="body2">
                      Keep me logged in
                    </Typography>
                  }
                />
                {errorMsg && (
                  <ErrorStack>
                    <CancelOutlinedIcon color="error" fontSize="small" sx={{ ml: 1 }} />
                    <ErrorTypography>{errorMsg}</ErrorTypography>
                  </ErrorStack>
                )}
                <Stack alignItems={"center"} marginTop={1}>
                  <LoginBtn onClick={() => logEvents("LOGIN_CLICKED")} type="submit">
                    LOG IN
                  </LoginBtn>
                </Stack>
              </Stack>
            </form>
            <Stack marginY={4} direction={"row"} flexWrap={"wrap"} justifyContent={"center"}>
              <BoldSpan>Never logged in before? &nbsp;</BoldSpan>
              <StyledLink to="/register">
                <BoldStyledElement fontSize={{ xs: "12px", sm: "14px" }} id="create_ziply_id">
                  CREATE A LOGIN
                </BoldStyledElement>
              </StyledLink>
            </Stack>
          </LoginBox>
          <Box pt={{ sm: 4 }}>
            <LoginFooter />
          </Box>
        </LoginCenterStack>

        {false && <AppProposalModal redirectToAppStore={redirectToAppStore} />}
        <Footer />
        {loader && <LoadingSpinner />}
        {openErrorModal && (
          <UpdateErrorComponent openError={openErrorModal} setOpenError={setOpenErrorModal} />
        )}
        {lsamError && <UpdateErrorComponent openError={lsamError} setOpenError={setLsamError} />}
        {openPopup && (
          <>
            <StyledDialog data-testid="usermessage" fullWidth open={openPopup}>
              <CardHeading textAlign={"center"} fontSize={"16px !important"}>
                For enhanced security, please reset your password before logging in.
              </CardHeading>

              <Stack my={1.5}>
                <Stack direction={"row"} gap={"16px"} justifyContent={"center"}>
                  <Button
                    title="RESET PASSWORD"
                    type="root"
                    onClick={() => navigate("/login/forgot-password")}
                  />
                </Stack>
              </Stack>
            </StyledDialog>
          </>
        )}

        {openDetailsRequiredPopup && (
          <>
            <StyledDialog data-testid="usermessage" fullWidth open={openDetailsRequiredPopup}>
              <Stack my={1.5}>
                {openDetailsRequiredPopup && (
                  <StyledLoginErrorStack padding={"20px"} gap={"1rem"}>
                    <StyledLoginErrorStack>
                      <StyledLoginErrorTypography>
                        {lsamErrorMessage?.map((data: any) => {
                          return (
                            <>
                              <Typography
                                fontWeight={600}
                                sx={{ fontSize: { xs: "12px", sm: "14px" } }}>
                                {data}
                              </Typography>
                            </>
                          );
                        })}
                      </StyledLoginErrorTypography>
                    </StyledLoginErrorStack>
                    <StyledLoginErrorButtonStack direction={"row"}>
                      <Button title="Sign up" type="root" onClick={handleRegisterRedirect} />
                    </StyledLoginErrorButtonStack>
                  </StyledLoginErrorStack>
                )}
              </Stack>
            </StyledDialog>
          </>
        )}
      </ContainerStack>
    </Fragment>
  );
}
export const StyledLoginErrorStack = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledLoginErrorTypography = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  variant: "body2",
}));

export const StyledLoginErrorButtonStack = styled(Stack)(({ theme }) => ({
  gap: "16px",
  justifyContent: "center",
}));
