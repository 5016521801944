import AES from "crypto-js/aes";
import { useSelector } from "react-redux";
import { CBST, CBST_KEY } from "../../Routes/query";
import { useLazyQuery, useMutation } from "@apollo/client";

export const useCbst = (feedback: any) => {
  const { ApiFailureName, requestId, error, cbstAction, storeRequestID, requestIdForGetUrl } =
    useSelector((state: any) => state.paymentDetails);

  const { accountNumber, emailContact } = useSelector((state: any) => state.customerInfoDetails);

  const encrypt = (content: any, password: any) =>
    AES.encrypt(JSON.stringify({ content }), password).toString();

  const [fetchCbstKey, { loading: cbstKeyLoading }] = useLazyQuery(CBST_KEY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      handleSendReport(data?.cbstKey?.cbstKey);
    },
  });

  const [sendCbst, { loading: cbstLoading }] = useMutation(CBST, {
    fetchPolicy: "network-only",
    onError: (error) => {
      console.log(error);
    },
  });
  const getRequestID = () => {
    switch (ApiFailureName) {
      case "getPaymentMethodURL":
        return requestIdForGetUrl;
      case "getPaymentMethodsForManagePayments":
        return storeRequestID;
      default:
        return requestId;
    }
  };

  const handleSendReport = async (key: any) => {
    try {
      const CbstContent = {
        email: emailContact?.emailAddress,
        accountNumber: accountNumber,
        APIName: ApiFailureName,
        error: error,
        requestId: getRequestID(),
        claritySessionId: sessionStorage?.sessionID,
        customerDescription: feedback,
        action: cbstAction,
      };
      const encryptedData = encrypt(CbstContent, key);
      await sendCbst({
        variables: {
          payload: encryptedData,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  return {
    cbstKeyLoading,
    cbstLoading,
    fetchCbstKey,
    sendCbst,
  };
};
