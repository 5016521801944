import React from "react";
import { useLazyQuery } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import { CHECK_ROUTER_TYPE } from "../../Pages/Home/Components/NetworkStatus/query";
import {
  setCheckRouterMessage,
  setCheckRouterTypeError,
  setEnableBreakLease,
  setEnableRgStatus,
  setEnableTestYourConnection,
  setSupportsSpeedTest,
  setisZiplyRouter,
} from "../../Redux/Slices/NetworkStatusSlice";
import { CHECK_ROUTER_TYPE_ERROR } from "../../Constants/constants";

export const useCheckRouterType = () => {
  const { accountNumber, ndsObj } = useSelector((state: any) => state.customerInfoDetails);
  const dispatch = useDispatch();
  const [getCheckRouterType, { loading: checkRouterTypeLoading, data: routerTypeData }] =
    useLazyQuery(CHECK_ROUTER_TYPE, {
      variables: {
        accountNumber: accountNumber,
        isNds: ndsObj?.isNDS,
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        dispatch(setisZiplyRouter(data?.checkRouterType?.isZiplyRouter));
        dispatch(setSupportsSpeedTest(data?.checkRouterType?.supportsSpeedTest));
        dispatch(setCheckRouterMessage(data?.checkRouterType?.message));
        dispatch(setCheckRouterTypeError(false));
        dispatch(setEnableBreakLease(data?.checkRouterType?.enableBreakLeaseAPI));
        dispatch(setEnableTestYourConnection(data?.checkRouterType?.enableTestYourConnection));
        dispatch(setEnableRgStatus(data?.checkRouterType?.enableRgStatus));
      },
      onError: (error: any) => {
        dispatch(setisZiplyRouter(false));
        const errorMessage =
          error.networkError.result.errors[0]?.message?.includes(CHECK_ROUTER_TYPE_ERROR);
        if (error.networkError.result.errors[0]?.code == "404" && errorMessage) {
          dispatch(setCheckRouterTypeError(true));
        }
      },
    });

  const enableBreakLease = routerTypeData?.checkRouterType?.enableBreakLeaseAPI;

  return {
    getCheckRouterType,
    checkRouterTypeLoading,
  };
};
