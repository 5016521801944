import Button from "../../../Button/Button";
import { useLazyQuery } from "@apollo/client";
import React, { useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "../../LoadingSpinner/Spinner";
import { useLocation, useNavigate } from "react-router-dom";
import { NeedHelpCard } from "../../NeedHelpCard/NeedHelpCard";
import { convertToPositive } from "../../../../Services/helper";
import AutopayIcon from "../../../../assets/icons/AutopayIcon.svg";
import { Box, Stack, Grid, Typography, styled } from "@mui/material";
import { AccountCard } from "../../../shared/AccountCard/AccountCard";
import { AUTOPAY_PAPERLESS_DETAILS } from "../../../../Pages/Billing/query";
import { setErrorFlag } from "../../../../Redux/Slices/AutoPayPaperlessSlice";
import {
  BlankScreenStack,
  PageGrid,
  SpanTypography,
  StickyBox,
  StyledPaper,
} from "../../../../Styles/GlobalStyles";
import {
  AUTOPAY_DISABLE_SUBTEXT,
  CANCEL_AUTOPAY_SUCCESS_TITLE,
  DEFAULT_NOTE_AUTOPAY,
  DEFAULT_PRIMARY_TEXT_AUTOPAY,
  DEFAULT_SECONDARY_TEXT_AUTOPAY,
  NOT_FOUND,
} from "../../../../Constants/constants";
import { setShowNextinAutoPay } from "../../../../Redux/Slices/OrderUpgradeSlice";
import { Account } from "../../../../Pages/Account/Account";

interface SaveAutoPayProps {
  title: string;
  description?: string;
  primaryText?: any;
  secondaryText?: string;
  note?: string;
  orderUpgrade?: any;
}

export const SaveAutoPay: React.FC<SaveAutoPayProps> = ({
  title,
  description,
  primaryText = DEFAULT_PRIMARY_TEXT_AUTOPAY,
  secondaryText = DEFAULT_SECONDARY_TEXT_AUTOPAY,
  note = DEFAULT_NOTE_AUTOPAY,
  orderUpgrade,
}) => {
  const { state, pathname } = useLocation();
  const ACCOUNT = state?.ACCOUNT;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handlePayment = () => {
    navigate({ pathname: "/billing/makeapayment" });
  };
  const { scrollPosition } = useSelector((state: any) => state.pageInfo);
  const { accountNumber } = useSelector((state: any) => state.customerInfoDetails);
  const { autopayAmount } = useSelector((state: any) => state.customerPlanDetails);

  const [
    getAutopayPaperlessDetails,
    { loading: autopayPaperlessLoading, data: autopayPaperlessData },
  ] = useLazyQuery(AUTOPAY_PAPERLESS_DETAILS, {
    variables: {
      accountNumber: accountNumber,
    },
    onError: (error: any) => {
      if (error.networkError.result.errors[0]?.message?.toLowerCase()?.includes(NOT_FOUND)) {
        dispatch(setErrorFlag(true));
      } else {
        dispatch(setErrorFlag(false));
      }
    },
    fetchPolicy: "network-only",
  });

  const autopayDetails =
    autopayPaperlessData?.autoPayAndPaperlessDetails?.autoPayDetails?.autoPayInfo?.[0];

  const handleNavigate = (path: string) => () => {
    navigate(path);
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    getAutopayPaperlessDetails();
    if (orderUpgrade) {
      dispatch(setShowNextinAutoPay(false));
    }
  }, []);

  return (
    <Box mt={orderUpgrade ? 0 : 6}>
      <PageGrid container>
        <Grid item xs={12} lg={orderUpgrade ? 12 : 8.2}>
          <BlankScreenStack textAlign={"center"}>
            {autopayPaperlessLoading ? (
              <Box py={36}>
                <Spinner />
              </Box>
            ) : (
              <Stack mt={orderUpgrade ? "" : "20px"} alignItems={"center"}>
                {title !== CANCEL_AUTOPAY_SUCCESS_TITLE && (
                  <SaveAutopayImage src={AutopayIcon} alt={"autopay-icon"} />
                )}
                <SaveAutopayStack gap={"1rem"}>
                  <Typography fontWeight={600} fontSize={{ xs: "16px", md: "20px" }}>
                    {title}
                  </Typography>
                  {autopayDetails && (
                    <SpanTypography data-testid="account-details">
                      Your&nbsp;
                      {autopayDetails?.paymentMethodName}
                      &nbsp; will be charged&nbsp;
                      {convertToPositive(autopayDetails?.scheduleDayBefore ?? "")} day(s) before
                      your due date.
                    </SpanTypography>
                  )}
                  {description && (
                    <SpanTypography data-testid="account-details">{description}</SpanTypography>
                  )}
                </SaveAutopayStack>

                {!orderUpgrade && (
                  <>
                    <AutopayPaper>
                      <Stack
                        direction={"row"}
                        spacing={1.7}
                        alignItems={"center"}
                        justifyContent={"center"}>
                        <SaveAutopayStack>
                          {title === CANCEL_AUTOPAY_SUCCESS_TITLE && (
                            <SaveAutopayTypography>Alert!</SaveAutopayTypography>
                          )}
                          <Typography sx={{ fontSize: { xs: "12px", md: "14px" } }}>
                            {primaryText}
                          </Typography>
                          {title !== CANCEL_AUTOPAY_SUCCESS_TITLE && (
                            <SaveAutopayTypography>{secondaryText}</SaveAutopayTypography>
                          )}
                        </SaveAutopayStack>
                      </Stack>
                      <Stack direction={"row"} my={"20px"} justifyContent={"center"}>
                        <Button
                          title="Make a one time payment"
                          type="root"
                          onClick={handlePayment}
                        />
                      </Stack>
                      {title === CANCEL_AUTOPAY_SUCCESS_TITLE && autopayAmount && (
                        <Typography sx={{ fontSize: { xs: "12px", md: "14px" } }}>
                          {` De-activating Autopay will result in a $${autopayAmount} monthly manual payment processing fee. This fee will appear in your next bill.`}
                        </Typography>
                      )}
                    </AutopayPaper>
                    <SaveAutopayStack gap={"2rem"} mb={"10px"}>
                      <Typography sx={{ fontSize: { xs: "12px", md: "14px" } }}>
                        Thanks for choosing Ziply Fiber.
                      </Typography>
                      <SaveAutopaySubTypography>{note}</SaveAutopaySubTypography>
                    </SaveAutopayStack>
                    {pathname === "/account/manageautopay" || ACCOUNT ? (
                      <>
                        <Button
                          onClick={handleNavigate("/account")}
                          type="pay"
                          title="GO TO ACCOUNT"
                        />
                        <br />
                      </>
                    ) : (
                      <>
                        <Button
                          onClick={handleNavigate("/billing")}
                          type="pay"
                          title="GO TO BILLING"
                        />
                        <br />
                      </>
                    )}
                  </>
                )}
              </Stack>
            )}
          </BlankScreenStack>
        </Grid>
        {!orderUpgrade && (
          <>
            <Grid
              item
              mt={{ xs: "2rem", lg: "0" }}
              xs={12}
              lg={3.5}
              display={{ xs: "block", lg: scrollPosition > 50 ? "block" : "none" }}>
              <AccountCard />
              <NeedHelpCard />
            </Grid>

            <StickyBox display={{ xs: "none", lg: scrollPosition > 50 ? "none" : "block" }}>
              <AccountCard />
              <NeedHelpCard />
            </StickyBox>
          </>
        )}
      </PageGrid>
    </Box>
  );
};

const SaveAutopayStack = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
}));

export const SaveAutopayTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "600",
  [theme.breakpoints.down("sm")]: { fontSize: "12px" },
}));

const AutopayPaper = styled(StyledPaper)(({ theme }) => ({
  width: "700px",
  backgroundColor: theme.palette.customcolor.saveAutoPayPaperColor,
  boxShadow: "none",
}));

const SaveAutopaySubTypography = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  color: theme.palette.customcolor.profileTypoGraphyColor,
}));

const SaveAutopayImage = styled("img")(({ theme }) => ({
  marginBottom: "20px",
  [theme.breakpoints.down("sm")]: { height: "55px" },
}));
