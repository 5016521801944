import React from "react";
import "../BouncingDots/BouncingDots.css";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

export const BouncingDotsLoader = () => {
  const { loadingDownload, loadingUpload } = useSelector((state: any) => state.speedTestAnimation);
  return (
    <>
      {(loadingDownload || loadingUpload) && (
        <Box
          className={"bouncing-loader-green"}
          sx={{ width: { xs: "100%", sm: "45%", md: "100%" } }}>
          <div></div>
          <div></div>
          <div></div>
        </Box>
      )}
    </>
  );
};
