import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  scrollPosition: 0,
  makePaymentRoute: "/home",
};

const pageSlice = createSlice({
  name: "pageInfo",
  initialState,
  reducers: {
    setScrollPosition: (state, action) => {
      state.scrollPosition = action.payload;
    },
    setMakePaymentRoute: (state, action) => {
      state.makePaymentRoute = action.payload;
    },
  },
});

export const { setScrollPosition, setMakePaymentRoute } = pageSlice.actions;
export const pageReducer = pageSlice.reducer;
