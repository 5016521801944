import { useLayoutEffect } from "react";
import Button from "../../Button/Button";
import { Theme } from "../../../Styles/GlobalTheme";
import { useDispatch, useSelector } from "react-redux";
import { convertToPositive } from "../../../Services/helper";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import { editAutoPayEnable } from "../../../Redux/Slices/EditAutoPaySlice";
import { Android12Switch, ColumnCenterStack, RowCenterStack } from "../../../Styles/GlobalStyles";
import {
  CANCEL_AUTOPAY_HEADING,
  CANCEL_AUTOPAY_PRIMARYCONTENT,
} from "../../../Constants/constants";

export const CustomManage = ({
  handleConfirm,
  setDialogOpen,
  setUnread,
  unread,
  manageData,
  dialogData,
  setDialogData,
}: any) => {
  const dispatch = useDispatch();
  const { isSaveAutoPayChanges } = useSelector((state: any) => state.editAutoPay);
  const { accountNumber } = useSelector((state: any) => state.customerInfoDetails);
  const { autopayAmount } = useSelector((state: any) => state.customerPlanDetails);

  const handleChange = () => {
    setUnread(true);
    setDialogOpen(true);
    setDialogData({
      ...dialogData,
      heading: CANCEL_AUTOPAY_HEADING,
      primaryContent: CANCEL_AUTOPAY_PRIMARYCONTENT,
      secondaryContent: autopayAmount
        ? `You will be charged $${autopayAmount}/billing period to make manual payments.`
        : "",
      containedButton: {
        ...dialogData?.containedButton,
        buttonType: "root",
        buttonTitle: "YES, TURN OFF",
        eventName: "TURN_OFF_AUTOPAY",
      },
    });
  };
  const handleEditAutoPay = () => {
    dispatch(editAutoPayEnable(true));
  };
  const hyphenSeperatedAccNum = accountNumber?.replace(
    /^(\d{3})(\d{3})(\d{4})(\d{6})(\d)$/,
    "$1-$2-$3-$4-$5"
  );

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ColumnCenterStack direction={"row"}>
        {isSaveAutoPayChanges ? (
          <Typography fontSize={{ xs: "16px", sm: "18px" }} fontWeight={600}>
            Review and confirm autopay settings
          </Typography>
        ) : (
          <RowCenterStack width={"100%"}>
            <Typography variant="subtitle1" fontWeight={600}>
              Autopay
            </Typography>
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Typography
                display={{ xs: "none", sm: "block" }}
                fontSize={"12px"}
                color={Theme.palette.customcolor.emailTextColor}>
                {unread ? "TURNED ON" : "TURNED OFF"}
              </Typography>
              <Android12Switch
                color="secondary"
                disableRipple
                inputProps={{ "aria-label": "ant design" }}
                onChange={handleChange}
                checked={unread}
              />
            </Stack>
          </RowCenterStack>
        )}
      </ColumnCenterStack>
      <Grid container justifyContent={"space-between"} spacing={2} my={1}>
        <Grid item xs={12} md={5.6}>
          <Stack gap={2}>
            <Stack>
              <Typography fontSize={"0.8rem"} component={"p"}>
                Account number
              </Typography>
              <Typography variant="subtitle2" component={"span"} mb={1} data-testid="accountNumber">
                {hyphenSeperatedAccNum}
              </Typography>
              <Divider />
            </Stack>
            <Stack>
              <Typography fontSize={"0.8rem"} component={"p"}>
                Payment method
              </Typography>
              <Typography variant="subtitle2" component={"p"} mb={1} data-testid="name-on-card">
                {manageData?.paymentMethodName
                  ? manageData?.paymentMethodName
                  : manageData?.nameOnTheCard}
              </Typography>
              <Divider />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={5.5}>
          <Stack gap={2}>
            <Stack>
              <Typography fontSize={"0.8rem"} component={"p"}>
                Payment to be applied
              </Typography>
              {manageData?.scheduleDayBefore && (
                <Typography variant="subtitle2" component={"p"} mb={1} data-testid="schedule-day">
                  {convertToPositive(manageData?.scheduleDayBefore ?? "")} day(s) before your due
                  date
                </Typography>
              )}
              <Divider />
            </Stack>
            <Stack>
              <Typography fontSize={"0.8rem"} component={"span"}>
                Amount
              </Typography>
              <Typography variant="subtitle2" component={"span"} mb={1} data-testid="amount-type">
                Total amount due for billing cycle
              </Typography>
              <Divider />
            </Stack>
          </Stack>
        </Grid>
        {isSaveAutoPayChanges ? (
          <Stack sx={{ mt: 4, mx: 2 }}>
            <Button
              onClick={handleConfirm}
              eventName="MANAGE_AUTOPAY_CONFIRM"
              type="payment"
              title="confirm"
            />
          </Stack>
        ) : (
          <Stack sx={{ mt: 4, mx: 2 }}>
            <Button
              title="Edit"
              eventName="MANAGE_AUTOPAY_EDIT"
              onClick={handleEditAutoPay}
              type="payment"
            />
          </Stack>
        )}
      </Grid>
    </>
  );
};
