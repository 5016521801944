import { useDispatch, useSelector } from "react-redux";
import { Box, Divider, Typography, Stack } from "@mui/material";
import {
  CardHeading,
  CardsContainer,
  SpacerSmall,
  StyledA,
  StyledElement,
} from "../../../Styles/GlobalStyles";
import {
  setExistingTicket,
  setOpenOutageBanner,
  setOpenSelectReasons,
  setOpenServiceTicketDialog,
} from "../../../Redux/Slices/TroubleTicketSlice";
import { getFlags } from "../../../Services/helper";
export const NeedHelpCard = () => {
  const { showOutage } = useSelector((state: any) => state.networkStatusDetails);


  const dispatch = useDispatch();
  const envFlags = useSelector((state: any) => state?.userInfo?.envFlags);
  const REFER_FRIEND = getFlags("refer_friend", envFlags);

  const needHelpLinks = [
    {
      heading: "Need help? Visit our help center",
      name: "HELP CENTER",
      path: "https://ziplyfiber.com/helpcenter",
    },
    {
      heading: "Customer support",
      name: "CONTACT US",
      path: "https://ziplyfiber.com/contact",
    },
    REFER_FRIEND && {
      heading: "Love Ziply Fiber? Refer a friend! You could both earn $50.",
      name: "SHARE ZIPLY FIBER AND EARN $",
      path: "https://referafriend.ziplyfiber.com/",
    },
  ].filter(Boolean);

  return (
    <>
      <Box>
        <CardHeading>Need help?</CardHeading>
        <CardsContainer>
          {needHelpLinks?.map(({ heading, name, path }: any, index: number) => {
            const isFirstChild = index === 0;
            return (
              <Stack spacing={1} key={name}>
                {!isFirstChild && (
                  <>
                    <SpacerSmall />
                    <Divider />
                  </>
                )}
                <Typography fontSize={"14px"} component={"p"}>
                  {heading}
                </Typography>
                {name === "REPORT SERVICE ISSUE" ? (
                  <StyledElement onClick={path} fontSize={"14px"} style={{ cursor: "pointer" }}>
                    {name}
                  </StyledElement>
                ) : (
                  <StyledA target="_blank" href={path}>
                    <StyledElement fontSize={"14px"}>{name}</StyledElement>
                  </StyledA>
                )}
              </Stack>
            );
          })}
        </CardsContainer>
      </Box>
    </>
  );
};
