import { useMutation } from "@apollo/client";
import { REBOOT_RG, RG_STATUS } from "../../Pages/Home/Components/NetworkStatus/query";
import { useSelector, useDispatch } from "react-redux";
import {
  setRebootRGApiError,
  setRebootRGMessage,
  setRgStatusRebootSuccess,
} from "../../Redux/Slices/NetworkStatusSlice";

export const useRGReboot = () => {
  const { accountNumber } = useSelector((state: any) => state.customerInfoDetails);
  const dispatch = useDispatch();
  const [rebootRG, { loading: rebootLoading }] = useMutation(REBOOT_RG, {
    onCompleted: (data) => {
      dispatch(setRebootRGMessage(data?.rebootRG?.message));
      dispatch(setRgStatusRebootSuccess(true));
    },
    onError: () => dispatch(setRebootRGApiError(true)),
    refetchQueries: [RG_STATUS],
  });

  const resetRebootSuccessMessage = () => {
    setTimeout(() => {
      dispatch(setRebootRGMessage(null));
    }, 5000);
  };

  const handleRebootRG = async () => {
    try {
      const response = await rebootRG({
        variables: {
          accountNumber: accountNumber,
        },
      });
      if (response?.data) {
        if (!response?.data?.rebootRG?.error) {
          resetRebootSuccessMessage();
          dispatch(setRebootRGMessage("Reboot completed successfully"));
        }
      }
    } catch (err) {
      dispatch(setRebootRGApiError(true));
    }
  };

  return {
    handleRebootRG,
    rebootLoading,
  };
};
