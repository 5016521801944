import React from "react";
import { Theme } from "../../../../Styles/GlobalTheme";

const ColorBar = () => {
  return (
    <>
      <div style={{ display: "flex", width: "100%" }}>
        <div
          style={{
            backgroundColor: Theme.palette.primary.main,
            padding: "0.2rem",
            width: "50%",
          }}></div>
        <div
          style={{
            backgroundColor: Theme.palette.secondary.main,
            padding: "0.2rem",
            width: "30%",
          }}></div>
        <div
          style={{
            backgroundColor: Theme.palette.customcolor.emailTextColor,
            padding: "0.2rem",
            width: "20%",
          }}></div>
      </div>
    </>
  );
};

export default ColorBar;
