import { gql } from "@apollo/client";

export const CANCEL_TT = gql`
  mutation cancelTroubleTicket($ticketId: String!, $accountNumber: String) {
    cancelTroubleTicket(ticketId: $ticketId, accountNumber: $accountNumber) {
      message
      APIStatus
      error
    }
  }
`;
