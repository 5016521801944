import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  autoPayData: {},
  paperLessData: {},
  uuidErrorFlag: null,
  disableAutopay: false,
};

const autopayPaperlessSlice = createSlice({
  name: "autopayPaperlessDetails",
  initialState,
  reducers: {
    autopayPaperlessDetails: (state, action) => {
      state.autoPayData = action?.payload?.autoPayData;
      state.paperLessData = action?.payload?.paperLessData;
    },
    paperLessTurnedOn: (state, action) => {
      state.paperLessData = action?.payload;
    },
    paperLessTurnedOff: (state, action) => {
      state.paperLessData = action?.payload;
    },
    turnOffAutopay: (state, action) => {
      state.autoPayData = action.payload;
    },
    signUpAutopay: (state, action) => {
      state.autoPayData = action.payload;
    },
    setErrorFlag: (state, action) => {
      state.uuidErrorFlag = action.payload;
    },
    showDisableAutopay: (state, action) => {
      state.disableAutopay = action.payload;
    },
  },
});

export const {
  autopayPaperlessDetails,
  paperLessTurnedOn,
  paperLessTurnedOff,
  signUpAutopay,
  turnOffAutopay,
  setErrorFlag,
  showDisableAutopay,
} = autopayPaperlessSlice.actions;
export const autopayPaperlessReducer = autopayPaperlessSlice.reducer;
