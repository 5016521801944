import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Divider, IconButton, Typography, Grid, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  CardHeading,
  CardsContainer,
  SwitchDialog,
  StyledElement,
  ColumnCenterStack,
  SpacerSmall,
  PaymentBox,
} from "../../../Styles/GlobalStyles";
import { CustomAccountCard } from "../Account/CustomAccountCard/CustomAccountCard";
import { Theme } from "../../../Styles/GlobalTheme";
import { LinkedAccount } from "../Account/LinkedAccount/LinkedAccount";
import { setOpenSwitchModal } from "../../../Redux/Slices/AppProposalSlice";
import { Spinner } from "../LoadingSpinner/Spinner";
import { ErrorComponent } from "../../ErrorComponent/ErrorComponent";
import { useGetCommercialAccount } from "../../../hooks/Accounts/useGetCommercial";
import { useGlobysURL } from "../../../hooks/Login/useGlobysURL";
import { setCheckAccNumErrorMessage } from "../../../Redux/Slices/AccountSlice";
import { getFlags } from "../../../Services/helper";
export interface AccountLinkProps {
  name?: string;
  path?: any;
  show?: any;
}
export interface AccountCardProps {
  orders?: any;
  maxLinksToShow?: number;
  setTotalBalance?: any;
  totalBalance?: any;
}
export const AccountCard = ({ setTotalBalance, totalBalance }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pathname = window.location.pathname;
  const customerDetails = useSelector((state: any) => state?.customerInfoDetails);
  const { openSwitchModal } = useSelector((state: any) => state?.appProposal);
  const { email } = useSelector((state: any) => state.userInfo);
  const globysId = localStorage.getItem("globysId");
  const { getGlobysURL } = useGlobysURL(email, globysId ?? "");
  const [isLinkAnotherAccOpen, setIsLinkAnotherAccOpen] = useState<boolean>(false);
  const isMultiAcc = sessionStorage.getItem("isMultiAccount");
  const { getCommercialAccount, commercialAccountLoading } = useGetCommercialAccount();
  const envFlags = useSelector((state: any) => state?.userInfo?.envFlags);
  const INACTIVE_ACCOUNTS = getFlags("inactive_accounts", envFlags);

  const accountNumber = customerDetails && customerDetails?.accountNumber;
  const hyphenSeperatedAccNum = accountNumber?.replace(
    /^(\d{3})(\d{3})(\d{4})(\d{6})(\d)$/,
    "$1-$2-$3-$4-$5"
  );
  const activeAccounts = customerDetails?.linkedAccountNumbers?.filter(
    (account: any) =>
      !INACTIVE_ACCOUNTS || account?.isActive !== false || account?.isActive === null
  );

  const inactiveAccounts = INACTIVE_ACCOUNTS
    ? customerDetails?.linkedAccountNumbers?.filter((account: any) => account?.isActive === false)
    : [];

  const mergedArray = customerDetails?.inactiveAccounts?.map((acc: any) => {
    const accountInfo = inactiveAccounts?.find(
      (info: any) => info?.accountNumber === acc?.accountNumber
    );
    return {
      ...acc,
      ...accountInfo,
    };
  });

  const filterPin = customerDetails?.linkedAccountNumbers?.filter(
    (item: any) => item.accountNumber === customerDetails?.accountNumber
  );
  const handleSwitchAccountDialog = () => {
    dispatch(setOpenSwitchModal(!openSwitchModal));
  };
  const handleNavigate = (path: string) => () => {
    navigate(path);
  };
  const handleCancel = () => {
    if (isLinkAnotherAccOpen) {
      setIsLinkAnotherAccOpen(false);
    } else {
      dispatch(setOpenSwitchModal(false));
    }
    dispatch(setCheckAccNumErrorMessage(""));
  };
  const handleOpen = () => {
    setIsLinkAnotherAccOpen(true);
  };
  return (
    <>
      <Box sx={{ mt: 1 }}>
        <CardHeading>Account</CardHeading>
        <CardsContainer>
          {customerDetails?.linkedAccountNumbers?.length > 0 ? (
            <>
              <ColumnCenterStack
                direction={"row"}
                color={Theme.palette.customcolor.profileTypoGraphyColor}>
                <Typography component={"p"}>Account number</Typography>
                <Typography component={"span"}>PIN</Typography>
              </ColumnCenterStack>
              <ColumnCenterStack direction={"row"}>
                <Typography
                  fontSize={{ xs: "14px", sm: "16px" }}
                  component={"p"}
                  data-testid="accountNumber"
                  id="account-number">
                  {hyphenSeperatedAccNum}
                </Typography>

                <Typography
                  fontSize={{ xs: "14px", sm: "16px" }}
                  component={"span"}
                  data-testid="pin">
                  {filterPin?.[0]?.pin}
                </Typography>
              </ColumnCenterStack>
              <SpacerSmall />
              <Divider />
              <SpacerSmall />
              <Stack pt={1} spacing={2}>
                <StyledElement onClick={handleSwitchAccountDialog} variant="body2">
                  VIEW ANOTHER ACCOUNT
                </StyledElement>
                <Divider />
                <StyledElement onClick={handleNavigate("/account")} variant="body2">
                  ACCOUNT & USER PROFILE
                </StyledElement>
                <Divider
                  sx={{
                    display: pathname === "/myaccount/billing" ? "none" : "block",
                  }}
                />
                <StyledElement
                  sx={{
                    display: pathname === "/myaccount/billing" ? "none" : "block",
                  }}
                  onClick={handleNavigate("/billing")}
                  variant="body2">
                  BILLING INFORMATION
                </StyledElement>
              </Stack>
            </>
          ) : (
            <>
              <StyledElement py={"2px"} onClick={handleNavigate("/account")} variant="body2">
                ACCOUNT & USER PROFILE
              </StyledElement>
            </>
          )}
        </CardsContainer>
      </Box>
      <SwitchDialog sx={{ zIndex: 0, width: "100%" }} open={openSwitchModal}>
        <IconButton onClick={handleCancel} sx={{ alignSelf: "end" }}>
          <CloseIcon />
        </IconButton>
        <Box px={2}>
          <CardHeading sx={{ fontSize: { xs: "20px", sm: "22px" } }} textAlign={"center"}>
            View another account
          </CardHeading>
          {isLinkAnotherAccOpen ? (
            <Box pb={2}>
              <LinkedAccount
                isLinkAnotherAccOpen={isLinkAnotherAccOpen}
                setIsLinkAnotherAccOpen={setIsLinkAnotherAccOpen}
              />
            </Box>
          ) : (
            <>
              <Box
                height={
                  customerDetails?.linkedAccountNumbers?.length > 2 ? { xs: 210, sm: 320 } : "auto"
                }
                width={{ sm: 500, xs: 250 }}
                overflow={"auto"}>
                {customerDetails && (
                  <>
                    {activeAccounts?.length > 0 && (
                      <>
                        {activeAccounts?.map((card: any) => (
                          <Grid item xs={12} key={card?.accountNumber} px={1}>
                            <CustomAccountCard
                              totalBalance={totalBalance}
                              setTotalBalance={setTotalBalance}
                              card={card}
                            />
                          </Grid>
                        ))}
                      </>
                    )}

                    {INACTIVE_ACCOUNTS && inactiveAccounts?.length > 0 && (
                      <>
                        <Box my={2}>
                          <Divider />
                        </Box>
                        <Stack mb={2} px={1}>
                          <CardHeading>Disconnected or Inactive Accounts</CardHeading>
                          <Typography
                            color={Theme.palette.customcolor.profileTypoGraphyColor}
                            fontSize={{ xs: "14px", sm: "16px" }}>
                            Inactive or disconnected accounts, these account don't have a active
                            plan and have limited services.
                          </Typography>
                        </Stack>
                        {mergedArray?.map((card: any) => (
                          <Grid item xs={12} key={card?.accountNumber} px={1}>
                            <CustomAccountCard
                              totalBalance={totalBalance}
                              setTotalBalance={setTotalBalance}
                              card={card}
                              inactiveAccount={inactiveAccounts?.length > 0 ? true : false}
                            />
                          </Grid>
                        ))}
                      </>
                    )}
                  </>
                )}

                <Divider />
                {isMultiAcc && (
                  <Box py={2}>
                    <Stack
                      justifyContent={"space-between"}
                      gap={1}
                      alignItems={"flex-start"}
                      direction={{ sm: "row", xs: "column" }}>
                      <CardHeading>Business accounts</CardHeading>
                    </Stack>
                    <Grid container justifyContent={"space-between"} flexWrap={"wrap"}>
                      {commercialAccountLoading ? (
                        <Grid item xs={12}>
                          <Box my={14}>
                            <Spinner />
                          </Box>
                        </Grid>
                      ) : customerDetails?.commercialAccApiError ? (
                        <>
                          <Grid item xs={12}>
                            <ErrorComponent onRefresh={getCommercialAccount} />
                          </Grid>
                        </>
                      ) : (
                        customerDetails?.businessAccountDetails?.accountNumber && (
                          <Grid item xs={12}>
                            <CustomAccountCard
                              isBusiness={true}
                              key={customerDetails?.businessAccountDetails?.accountNumber}
                              card={customerDetails?.businessAccountDetails}
                              handleGlobysUrl={getGlobysURL}
                            />
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Box>
                )}
              </Box>
              <Divider />
              <StyledElement
                fontSize={{ xs: "12px", sm: "16px" }}
                pt={2}
                pb={4}
                onClick={handleOpen}>
                LINK ANOTHER ACCOUNT
              </StyledElement>
            </>
          )}
        </Box>
      </SwitchDialog>
    </>
  );
};
