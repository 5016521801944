import { Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getDollarSign } from "../../../../../../Services/helper";
import {
  BillingAccordionSummary,
  BillingAccountStack,
  ColumnCenterStack,
  StyleAccordian,
  StyledElement,
} from "../../../../../../Styles/GlobalStyles";
import { Theme } from "../../../../../../Styles/GlobalTheme";

export const RowDataComponent = (props: any) => {
  const { item } = props;
  const sublist = item && JSON?.parse(item?.content);
  const details = sublist?.metaData;
  const price = sublist?.amount ? getDollarSign(sublist?.amount) : "$0.00";
  const data =
    details &&
    Object.entries(details)?.map(([key, value]) => ({
      key,
      title: key,
      content: value,
    }));

  return (
    <>
      <StyleAccordian data-testid={"accordion"} disableGutters elevation={0}>
        <BillingAccountStack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}>
          <BillingAccordionSummary
            expandIcon={
              details &&
              Object.entries(details).length > 0 && (
                <StyledElement>
                  <ExpandMoreIcon />
                </StyledElement>
              )
            }>
            <Typography fontSize={{ xs: "12px", sm: "16px" }} component={"p"} data-testid="title">
              {item?.title}
            </Typography>
          </BillingAccordionSummary>
          <Typography
            data-testid="price"
            fontSize={{ xs: "12px", sm: "16px" }}
            component={"span"}
            color={details ? "inherit" : Theme.palette.primary.main}
            width={{ xs: "30%", sm: "25%" }}
            textAlign={"center"}>
            {price}
          </Typography>
        </BillingAccountStack>
        {data &&
          data?.map((item: any) => (
            <ColumnCenterStack direction={"row"} key={item?.key}>
              <Typography
                data-testid="content"
                fontSize={{ xs: "10px", sm: "12px" }}
                component={"p"}
                pl={"18px"}
                textAlign={"start"}
                width={{ xs: "70%", sm: "80%" }}
                borderRight={`1px solid ${Theme.palette.customcolor.accordionborder}`}
                py={"0.5rem"}>
                {item?.title}
              </Typography>
              <Typography
                data-testid="contentPrice"
                fontSize={{ xs: "10px", sm: "12px" }}
                textAlign={"center"}
                component={"p"}
                width={{ xs: "30%", sm: "25%" }}>
                {item?.content ? getDollarSign(item?.content) : "$0.00"}
              </Typography>
            </ColumnCenterStack>
          ))}
      </StyleAccordian>
    </>
  );
};
