export const loginEndpoint = process.env.REACT_APP_CXP_LOGIN;
export const DEV_HASHKEY = "https://ziplyfiber.dev.nwestnetwork.com/care24x7/csapi/handle/hashKey";
export const STG_HASHKEY = " https://dev-nwfbr.com/care24x7/csapi/handle/hashKey ";
export const CREATE_CUSTOMER_WITH_FINGERPRINT_URL = `${process.env.REACT_APP_CREATE_CUSTOMER_SESSION_BY_FINGERPRINT}`;
export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname;
export const ZIPLYLOGO =
  "https://ziplyfiber.com/-/media/Residential/modules/images/logo.png?h=77&iar=0&w=300&hash=15ACAD8E98767B4FB553E7F5896BE6B7";
export const completed = "completed"?.toLocaleUpperCase();
export const currentBill = "Current Bill"?.toLocaleUpperCase();
export const pastBill = "past bills"?.toLocaleUpperCase();
export const voipBill = "voip bills"?.toLocaleLowerCase();
export const scheduled = "pending"?.toLocaleUpperCase();
export const creditDebit = "credit/Debit"?.toLocaleUpperCase();
export const bankAccount = "Bank Account"?.toLocaleUpperCase();
export const today = "today";
export const newPayment = "newpayment";
export const HYPHEN = "-";
export const newCreditCard = "newcreditcard";
export const newBankAccount = "newbankaccount";
export const older = "older";
export const thisWeek = "this week";
export const notVerified = "not verified";
export const successful = "successful";
export const due = "due";
export const update = "update";
export const autoPay = "Autopay"?.toLocaleLowerCase();
export const paperLessBilling = "Paperless Billing"?.toLocaleLowerCase();
export const CREATE = "create";
export const CONNECT = "connect";
export const VERIFY = "verify";
export const SIGN_IN = "sign-in";
export const notifications = "NOTIFICATIONS";
export const CREDIT = "CREDIT";
export const BACKEND_URI = `${process.env.REACT_APP_BACKEND_API_HOST}${process.env.REACT_APP_GRAPHQL_API_URL}`;
export const LOGGER_URL = `${process.env.REACT_APP_LOGGER_URL}`;
export const SALT = process.env.REACT_APP_LOGGER_SALT;
export const ENV = `${process.env.REACT_APP_ENV}`;
export const PROD_ENV = "production";
export const DEV_ENV = "Devlopment";
export const STG_ENV = "Staging";
export const ACCOUNT = "account";
export const COMPLETED = "COMPLETED";
export const INVALID_EMAIL = "invalid-email";
export const WRONG_PASSWORD = "wrong-password";
export const EMPTY_LOGIN_ERROR = "Login details cannot be empty";
export const TOP_SOLUTION = "top-solutions";
export const ARTICLES = "articles";
export const ALERTS = "alerts";
export const INVALID_ACC_NUMBER = "Invalid account number";
export const INVALID_PIN = "Invalid pin";
export const INVALID_PHONE = "Invalid mobile number";
export const PUSH_NOTIFICATIONS = "pushNotifications";
export const SEND_EMAILS = "sendEmails";
export const SEND_SMS = "sendSMS";
export const MY_ACCOUNT = "myaccount";
export const PAYMENT_DESCRIPTION = ENV === PROD_ENV ? MY_ACCOUNT : "test payment";
export const PAYMENT_TYPE = "payment";
export const COMPLETE = "Completed";
export const INVOICED = "Invoiced";
export const IN_PROGRESS = "In Progress";
export const ACTIVE = "Active";
export const CANCELLED_STATUS = "Cancelled";
export const SUBMITTED = "Submitted";
export const FULLFILLED = "Fullfiled";
export const ON_HOLD = "On Hold";
export const WAITING_FOR_DETAILS = "Waiting for details";
export const RESEARCHING = "Researching";
export const PROBLEM_SOLVED = "Problem Solved";
export const INFORMATION_PROVIDED = "Information Provided";
export const MERGED = "Merged";
export const LANGUAGE = "en";
export const SUCCESS_URL = "myaccount/payment/success";
export const CANCEL_URL = "myaccount/payment/failed";
export const CREDIT_CARD = "credit";
export const BANK = "bank";
export const TOKENIZED_CREDIT = "tokenized-credit-card";
export const TOKENIZED_BANK = "tokenized-bank";
export const EXISTING = "existing";
export const currentDate = new Date();
export const TOTAL = "Total";
export const UID_REGEX = /[?&]uid=([^&]+)/;
export const TRANSACTION_ID_REGEX = /[?&]txn_guid=([^&]+)/;
export const SUCCESS = "Success";
export const LOG_IN = "/login";
export const AUTHORIZED_USER_DELETED = "User has been deleted.";
export const AUTHORIZED_USER_ADDED =
  "Email sent with steps for authorised users to access current account.";
export const ERROR_MESSAGE = "Unable to delete payment method card.";
export const SUCCESS_PAYMENT_MESSAGE = "Successfully added payment method.";
export const DELETE_PAYMENT_MESSAGE = "Successfully removed payment method.";
export const TURNON_PAPERLESS_SUCCESS_MESSAGE = "Thank you for enrolling in paperless billing.";
export const EDIT_PHONE_ERROR_MESSAGE =
  "Entered mobile number is the current mobile number, please add other mobile number.";
export const EDIT_EMAIL_ERROR_MESSAGE =
  "Entered email address is the current email address, please add other email address.";
export const PAST_BILLS_MESSAGE = "No past bills found.";
export const TOTAL_BALANCE_EMPTYDATA_MESSAGE = "No total balance found.";
export const AUTHORIZED_USERS_ERROR_MSG =
  "Unable to fetch the authorized users, please refresh the page";
export const PAYMENT_METHODS_ERROR_MSG = "Unable to fetch payment methods, please try again later";
export const FAILURE_MSG = "Something went wrong, please try again later";
export const COMPLETE_SUCCESS = "complete-success";
export const CANCEL_PAYMENT = "cancelPayment";
export const CANCELLED = "cancelled";
export const TRANSACTIONS_EMPTYDATA_MESSAGE = "No recent transactions found.";
export const AMOUNT_WARNING_MSG = "Amount should be greater than 0";
export const SELECT_PAYMENT_METHOD = "Please select a payment method";
export const UPDATE_PHONENUMBER_SUCCESS_MESSAGE =
  "Your mobile number has been updated successfully and verified.";
export const UPDATE_EMAIL_SUCCESS_MESSAGE =
  "Your email address has been successfully updated. To ensure security, the application will log you out. Please proceed to log in using your updated email address.";
export const EMPTY_ARTICLES_MESSAGE = "No articles found.";
export const AUTOPAY_BANNER_DISCIPTION = "for automatic payments with just a few clicks!";
export const AUTOPAY_BANNER_TITLE = "Save the hassle and $10/month with autopay";
export const masterCard = "mastercard";
export const MASTERCARD = "MASTERCARD";
export const visa = "visa";
export const VISA = "VISA";
export const CHECKING = "Checking";
export const EMPTY_ALERTS_MESSAGE = "No messages found.";
export const SEND_OTP_FAILED_MESSAGE =
  "Didn't receive a code?  Please click 'RESEND CODE' or try again later.";
export const PHONE_VERIFY_SUCCESS_MESSAGE = "Mobile number verified successfully.";
export const Email_VERIFY_SUCCESS_MESSAGE = "Email verified succesfully.";
export const AUTH_WRONG_PASSWORD = "auth/wrong-password";
export const AUTH_INVALID_EMAIL = "auth/invalid-email";
export const AUTH_USER_NOT_FOUND = "auth/user-not-found";
export const AUTH_TOO_MANY_REQUESTS = "auth/too-many-requests";
export const UNLINK_SUCCESS_MESSAGE = "Successfully unlinked account.";
export const WRONG_PASSWORD_MESSAGE = "Wrong password.";
export const USER_NOT_FOUND = "Email/password is incorrect.";
export const USER_DISABLED_MESSAGE =
  "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.";
export const INVALID_PASSWORD = "Your current password is incorrect.";
export const INVOICE_ERROR =
  "We're having trouble fetching your voice details right now. Please try again later. ";
export const BILLING_ERROR =
  "We're having trouble retrieving billing invoice right now. Please try again later. ";
export const MAKE_PAYMENT_CBST_ACTION = "customer got error while making payment";
export const GET_PAYMENT_METHODS_CBST_ACTION = "customer got error while fetching payment methods ";
export const DELETE_PAYMENT_CBST_ACTION = "customer got error while deleting payment method";
export const CANCEL_AUTOPAY_CBST_ACTION = "customer got error while cancel autopay  ";
export const EDIT_AUTOPAY_CBST_ACTION = "customer got error while editing autopay";
export const PAPERLESS_CBST_ACTION = "customer got error while turning paperless on or off";
export const LINK_ACCOUNT_CBST_ACTION = "customer got error while Linking an account";
export const SET_AUTOPAY_CBST_ACTION = "customer got error while setting an autopay";

export const PAPERLESS_HEADING = "Turn off paperless billing";
export const PAPERLESS_PRIMARYCONTENT = "Are you sure you want to turn off paperless billing?";
export const PAPERLESS_SECONDARYCONTENT =
  "You will see a $5 monthly charge for a paper bill to be mailed.";
export const LOGOUT_HEADING = "Log out";
export const LOGOUT_PRIMARYCONTENT = "Are you sure you want to log out of Ziply Fiber?";
export const CANCEL_AUTOPAY_HEADING = "Turn off autopay";
export const CANCEL_AUTOPAY_PRIMARYCONTENT = "Are you sure you want to turn off autopay?";
export const CANCEL_AUTOPAY_SECONDARYCONTENT =
  "You will be charged $5/billing period to make manual payments.";
export const SUCCESS_EDIT_AUTOPAY = "Autopay edited successfully.";
export const MAKE_A_PAYMENT_PRIMARYCONTENT = "Autopay is enabled. Are you sure want to proceed?";
export const SAVE_PAYMENT_HEADING = "Save payment method";
export const TEN_GIG_INSTALLATION =
  "Please note that our 10Gbps service currently requires a $300 installation fee.";
export const UPGRADE_ORDER_AUTOPAY =
  "Please enter or select a payment method for Autopay signup. You will not be billed until your service is activated";
export const SAVE_PAYMENT_PRIMARYCONTENT =
  "Do you want to save the new card/bank account for future use?";
export const DELETE_AUTH_USER_HEADING = "Delete user";
export const DELETE_AUTH_USER_PRIMARYCOTENT = "Are you sure you want to delete user";
export const UNLINK_ACCOUNT_HEADING = "Unlink Account";
export const UNLINK_ACCOUNT_PRIMARYCONTENT = "Are you sure you want to unlink account";
export const SWITCH_ACCOUNT_PRIMARYCONTENT =
  "It is not possible to unlink the account while you are currently viewing account.";
export const SWITCH_ACCOUNT_SECONDARYCONTENT =
  "To unlink the current account, please switch to view another account.";
export const REMOVE_PAYMENT_METHOD_PRIMARYCONTENT = "Are you sure you want to remove";
export const REMOVE_PAYMENT_METHOD_HEADING = "Remove payment method";
export const REMOVE_AUTOPAY_DATA_PRIMARY_CONTENT = "This payment method is scheduled for autopay.";
export const REMOVE_AUTOPAY_DATA_SECONDARY_CONTENT = "To remove this payment method please add";
export const REMOVE_AUTOPAY_DATA_TERTIARYCONTENT = "another payment method.";
export const MESSAGE_SETTINGS_PRIMARYCONTENT = "Please note that it will take up to 3 weeks to";
export const MESSAGE_SETTINGS_SECONDARYCONTENT = "reflect the changes on your account.";
export const MESSAGE_SETTINGS_TERTIARYCONTENT = "Please confirm to proceed.";
export const REPORT_SERVICE_ISSUE_ERROR =
  "Sorry, we are currently unable to fetch your old payment methods.";
export const PHONE_SECURITY_CODE_SUCCESS_MESSAGE =
  "Security code sent to mobile number successfully.";
export const EMAIL_SECURITY_CODE_SUCCESS_MESSAGE =
  "Security code has been sent to your email successfully.";
export const EMAIL_SECURITY_CODE_SUCCESS_SUBTEXTMESSAGE =
  "(Please also check your Junk folder for the mail)";
export const PANEL_1 = "panel1";
export const PAYMENT_METHODS_ERROR_MESSAGE =
  "Due to technical error, payments are not displayed in the payment methods. Please try again later.";
export const PRIVACY_POLICY_URL = "https://ziplyfiber.com/corporate/privacy-policy";
export const PAYMENT_TERMS_URL = "https://ziplyfiber.com/corporate/terms-conditions/payment-terms";
export const TERMS_CONDITIONS_URL = "https://ziplyfiber.com/corporate/terms";
export const EMPTY_PAYMENT_METHODS = "No payment methods added.";
export const UPDATE_NOTIFICATION_SUCCESS_MESSAGE =
  "Message settings have been updated successfully.";
export const NO_BILL_INFORMATION = "No bill information found.";
export const NEW_CARD = "new credit/debit card";
export const NEW_BANK = "new bank account";
export const LINKED_ACCOUNT_SUCCESS_MESSAGE = "Account is linked succesfully";
export const COMPARE_BILL_ERROR_MESSAGE =
  "Unable to compare bills as one of the selected bill is missing data. Please select other bill to compare.";
export const SUCCESSFUL = "successful";
export const FAILED = "failed";
export const PENDING = "pending";
export const INPROGRESS = "inprogress";
export const SCHEDULED = "scheduled";
export const APP_STORE_URL = "https://apps.apple.com/us/app/ziply-fiber/id6451298186";
export const PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.ziplyfiber.myziply2";
export const GOOGLE_PLAY_STORE =
  "https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png";
export const APPLE_STORE =
  "https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&releaseDate=1699401600";

export const PLAYSTORE_URL_GOOGLE =
  "https://play.google.com/store/apps/details?id=com.ziplyfiber.myziply2&utm_campaign=Website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1";

export const APPSTORE_URL_APPLE =
  "https://apps.apple.com/us/app/ziply-fiber/id6451298186?itsct=apps_box_badge&itscg=30200";
export const NOT_FOUND = "not found";
export const ORDER = "order";
export const CONFIRM = "confirm";
export const INSTALL = "install";
export const UUID_ERROR_MSG_AUTOPAY =
  "If you've recently completed an order, please wait a few days after your order is processed before turning Autopay On/Off.";
export const UUID_ERROR_MSG_PAPERLESS =
  "If you've recently completed an order, please wait a few days after your order is processed before turning Paperless On/Off.";
export const UUID_ERROR_MSG_EXISTING_CUSTOMER_PAYMENTS =
  "If you are an existing customer and registered within the last 30 minutes, we may still be getting your account ready to go. Please log out, wait 30 minutes and log back in.";
export const UUID_ERROR_MSG_NEW_CUSTOMER_PAYMENTS =
  "If you are a new customer and your installation was completed within the last couple of days,we may have not finished setting up your account yet. Please check back in a couple days before attempting to make any payments.";
export const UUID_ERROR_MSG_ADD_PAYMENT =
  "If you've recently completed an order, please wait a few days after your order is processed before adding new payment methods.";
export const COMMIT_FAILED = "commite_failed";
export const MANAGE_ORDER = "Manage order";
export const ERROR_PAGE_HEADING = "404";
export const ERROR_PAGE_SUBHEADING = "Page not found";
export const ERROR_PAGE_CONTENT = "Oops! We couldn't find the page that you were looking for.";
export const ERROR_PAGE_SUBCONTENT = "Please check the address and try again.";
export const GO_TO_LOGIN = "Go to login";
export const GO_TO_HOME = "Go to home";
export const OTP_DOES_NOT_MATCH = "Security code does not match";
export const OTP_EXPIRED = "Security code expired";
export const OTP_INVALID = "Security code invalid";
export const NOT_MATCH = "not match";
export const EXPIRED = "expired";
export const COUNTDOWN_FOR_SEND_AND_RESEND_SMS = 5 * 60000;
export const INTERNET = "Internet";
export const INTERNET_SPEED_TEST = "Speed test";
export const ROUTER_CONNECTION_MESSAGE = "Your router is working properly.";
export const ROUTER_CONNECTION_SUB_MESSAGE =
  "If network issue continues, click here to REPORT A SERVICE ISSUE.";
export const CONNECTION_QUALITY_TEST_SUB_MESSAGE =
  "If you think there is an issue, check out our help center.";
export const CONNECTION_QUALITY_TEST_MESSAGE =
  "Success! Your home is connected to the Ziply Fiber network.";
export const CONNECTION_QUALITY_TEST_INFO =
  "The connection at your home is the Optical Network Terminal (ONT) often located in a garage, closet or outside the home.";
export const SPEED_TEST_INFO_MESSAGE =
  "Our test measures the speed delivered from the Ziply Fiber network to your router. This does not test the Wi-Fi speeds from the router to your wireless devices. Speeds will always vary based on your devices.";
export const SPEED_TEST_SUBTEXT =
  "Let's test your connection speed and ensure it's performing as expected.";
export const CHECK_INTERNET_SPEED = "CHECK INTERNET SPEED";
export const CONNECTION_QUALITY_TEST = "Connection quality test";
export const SPEED_TEST_HERE_LINK =
  "https://ziplyfiber.com/helpcenter/categories/internet/troubleshooting/connectivity-problems-multi-devices";
export const CONNECTION_QUALITY_SUBTEXT =
  "Let's confirm there is a great connection between the Ziply Fiber network and your home.";
export const RUN_TESTS = "RUN TESTS";
export const ROUTER_CONNECTION_TEST = "Router connection test";
export const ROUTER_CONNECTION_SUBTEXT =
  "Let's confirm that your Ziply Fiber WiFi router is working properly.";
export const ANALYZE_NETWORK = "ANALYSE NETWORK";
export const REBOOT_ROUTER = "Reboot Router";
export const STATE = "STATE";
export const CONFIRM_CANCELLATION_TEXT = "Confirm Cancellation";
export const TT_CANCELLATION_CONFIRMATION_HEADING = "Are you sure you want to cancel the ticket?";
export const TICKET_DETAILS = "Ticket details";
export const SERVICES = "Services";
export const BREAK_LEASE_SUCCESSFUL = "IP Release Successful";
export const BREAK_LEASE_FAILED = "IP Release Failed";
export const REBOOT_ERROR = "Reboot Error";
export const TEST_CONNECTION_FAILURE_MESSAGE =
  "Couldn't establish a connection to the network currently. Please try again.";
export const RG_STATUS_FAILURE_MESSAGE =
  "Couldn't communicate with your home router currently. Please try again.";
export const SERVICE_PROVISION_FAILURE_MESSAGE =
  "Unable to retrieve information from our services. Please try again later.";
export const PARTICIPATES_IN_ACP = "Participates in the ACP";
export const CHARGES_FOR_ADDITIONAL_DATA_USAGE = "Charges for Additional Data Usage";
export const NETWORK_MANAGEMENT = "Network Management";
export const PRIVACY_POLICY = "Privacy Policy";
export const CUSTOMER_SUPPORT = "Customer Support";
export const DATA_INCLUDED_WITH_MONTHLY_PRICE = "Data Included with Monthly Price";
export const AUTOPAY_SIGNUP_SUCCESS = "Autopay Sign-Up Successful";
export const ONE_TIME_FEES = "one-time fees";
export const MONTHLY_FEES = "monthly fees";
export const AUTOPAY_PAPERLESS_LOADING_MESSAGE =
  "Please wait while we load AutoPay and Paperless options";
export const PAPERLESS_DEACTIVATION_SUCCESS_MESSAGE = "Paperless billing deactivated";
export const FETCHING_BILLING_DETAILS_MESSAGE = "Please wait while we fetch your billing details.";
export const LOADING_CURRENT_BILL_DETAILS_MESSAGE = "Loading current bill details…";
export const RETRIEVING_PASTBILLS_MESSAGE = "Retrieving past bills…";
export const ANALYZING_BILLS_FOR_COMPARISION_MESSAGE = "Analysing data for bill comparison…";
export const FETCHING_TRANSACTION_HISTORY_MESSAGE = "Fetching recent transaction history…";
export const FETCHING_AUTHORIZED_USERS_MESSAGE =
  "Please wait while we fetch all the authorized users";
export const RETRIEVING_PLANS_SERVICES_MESSAGE = "Retrieving your current plans and services…";
export const LOADING_MESSAGES_TEXT = "Please wait while we load your messages";
export const FETCHING_PAYMENT_METHODS_MESSAGE = "Fetching available payment methods…";
export const LOADING_BILLING_ADDRESSINFO_MESSAGE = "Retrieving your address information...";
export const DEFAULT_PRIMARY_TEXT_AUTOPAY =
  "Note : These settings will apply to your next full billing cycle.";
export const DEFAULT_SECONDARY_TEXT_AUTOPAY =
  "Please be sure to make a one time payment for any current bills due this cycle.";
export const DEFAULT_NOTE_AUTOPAY = "If all your current bills are settled, feel free to proceed.";
export const CANCEL_AUTOPAY_SUCCESS_TITLE = "Autopay Deactivated";
export const CANCEL_AUTOPAY_SUCCESS_DESCRIPTION =
  "It may take a short while to this change to appear in your account.";
export const CANCEL_AUTOPAY_SUCCESS_PRIMARY_TEXT =
  "If your current bill has not been paid in full:";
export const CANCEL_AUTOPAY_SUCCESS_SECONDARY_TEXT =
  "please make a manual payment before the due date to avoid late fees.";
export const CANCEL_AUTOPAY_SUCCESS_NOTE =
  "Account updates - including removal of autopay may take some time to reflect in your Autopay";
export const PAYMENT_METHODS_SECURITY_NOTICE =
  "There are existing payment methods in this account, however, for security reasons, we are unable to display them at this time. Safeguarding user information is our top priority.";
export const AUTOPAY_DISABLE_SUBTEXT =
  " De-activating Autopay will result in a $5 monthly manual payment processing fee. This fee will appear in your next bill.";
export const STATUS_ACTIVE = "active";
export const STATUS_OK = "tested ok";
export const STATUS_UNABLE_TO_FETCH = "unable to fetch";
export const STATUS_UNABLE_TO_CONNECT = "unable to connect";
export const WELCOME_MESSAGE = "Thank you for choosing Ziply Fiber. Welcome to your account.";
export const OUTAGE_MESSAGE =
  "Service alert : We have detected an outage in your area. We are working to resolve it as quickly as possible, and apologize for the inconvenience.";
export const NETWORK_CONNECTION_FAILURE_TEXT =
  "We're unable to run the connection test right now. Please try again later.";
export const NETWORK_CONNECTION_INACTIVE_TEXT =
  "Uh oh! Looks like we're unable to confirm a connection with our network.";
export const ROUTER_CONNECTION_FAILURE_TEXT =
  "We're unable to run the connection test right now. Please try again later.";
export const ROUTER_CONNECTION_INACTIVE_TEXT = "We are unable to confirm the router is connected. ";
export const FETCHING_VOICE_USAGE_BILLS_MESSAGE = "Loading voice usage bill details…";
export const LATENCY_INFO = "Latency-how quickly data is transmitting in milliseconds.";
export const ROUTER_CONECTION_NOTE =
  "Note: If you use your own router, you will not be able to properly conduct this WiFi router connection test, Upgrade to a Ziply Fiber WiFi router for a more comprehensive network experience.";
export const ROUTER_CONNECTION_HERE_LINK =
  "https://ziplyfiber.com/helpcenter/categories/internet/troubleshooting/router-reboot-reset";
export const CREATE_SERVICE_TICKET_HEADINGS = {
  primary: "What's wrong?",
  secondary: "Contact and ticket information",
  outage: "There's an outage in your area",
  existing: "You already have an open ticket",
  success: "Your service issue has been succesfully raised.",
  failure: "Create Service Ticket Failed.",
  other: "Please specify what's wrong",
};

export const HELP_CENTER_LINK =
  ENV === PROD_ENV
    ? "https://ziplyfiber.com/helpcenter/categories/account/manage-your-account/set-up-online-account-access"
    : "https://ziplyfiber.com/helpcenter/categories/account/manage-your-account/set-up-online-account-access";

export const NETWORK_STATUS_HELP_LINK =
  "https://ziplyfiber.com/helpcenter/categories/internet/troubleshooting";

export const PAPERLESS_UPGRADE_ORDER_ERROR = {
  Heading: "Paperless Sign-up unsuccessful",
  subHeading:
    "We were Unable to register Paperless at this time.{} You can sign up for these service anytime in the account section.",
};
export const AUTOPAY_AND_PAPERLESS_UPGRADE_ORDER_ERROR = {
  Heading: "Autopay and Paperless Sign-up unsuccessful",
  subHeading:
    "We were Unable to register you for Autopay and Paperless Billing at this time.{} You can sign up for these service anytime in the account section.",
};

export const AUTOPAY_UPGRADE_ORDER_ERROR = {
  Heading: "Autopay Sign-up unsuccessful",
  subHeading:
    "We were Unable to register you for Autopay at this time.{} You can sign up for these service anytime in the account section.",
};
export const SPEED_TEST_ERROR_MESSAGE =
  "We're unable to run the speed test right now. Please try again later.";
export const INVALID_PHONE_ERROR_MESSAGE = "Mobile number is invalid.";
export const CHECK_ROUTER_TYPE_ERROR = "Customer doesn't have data plan";
export const DUPLICATE_PAYMENT_PRIMARY_CONTENT =
  "It looks like you are trying to make a second payment within an hour.";
export const DUPLICATE_PAYMENT_HEADING = "Warning";
export const DUPLICATE_PAYMENT_SECONDARY_CONTENT =
  "Unsure if the original payment processed? Please continue to check Recent Transactions for an hour after submission to see it reflected. This will prevent duplicate payments.";
export const DUPLICATE_PAYMENT_TERTIARY_CONTENT =
  "Still want to pay again? Proceed to make another payment.";
export const isCommercial =
  window.location.pathname.includes("/myaccount/commercial") ||
  window.location.pathname.includes("/lac/commercial");
export const TELEMETRY_PLATFORM = "web";
export const TELEMETRY_EVENT_LOGIN = "LOGIN";
export const TELEMETRY_EVENT_FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const TELEMETRY_FAILURE = "FAILURE";
export const TELEMETRY_SUCCESS = "SUCCESS";
export const EVENT_UNLINK_ACCOUNT = "UNLINK_ACCOUNT";
export const EVENT_REMOVE_AUTH_USER = "REMOVE_AUTHORISED_USER";
export const isAuthenticated = process.env.REACT_APP_CXP_ADMIN_ACCESS_ENABLED;
export const AUTOPAY_DISABLE_HEADING = "Autopay Disabled";
export const AUTOPAY_DISABLE_CONTENT =
  "Your autopay has been successfully disabled for this account.";
export const INACTIVE_ACCOUNT_BANNER_PRIMARY_TEXT =
  "Your account is currently inactive or disconnected. Please settle your pending balance. You can still view invoices and transactions anytime.";
export const INACTIVE_ACCOUNT_BANNER_SECONDARY_TEXT =
  "Your account is currently disconnected or inactive. You will be able to pay your pending bill amount. You can also view your invoices and transactions at any time.";

export const pageTitles = {
  "/home": "Home",
  autopay: "Autopay",
  "/billing": "Billing",
  "/account": "Account",
  "/messages": "Messages",
};

export const INVALID_DETAILS_MESSAGE_WHILE_LINKING_ACCOUNT =
  "Could not verify the account number and pin. Please double-check your credentials.";
export const ACCOUNT_NUMBER_VERIFICATION_FAILURE = "failed to verify the account number";

export const accountLinks = [
  {
    name: "ACCOUNT & USER PROFILE",
    path: "/account",
  },
  {
    name: "BILLING INFORMATION",
    path: "/billing",
  },
];

export const transactions = [
  {
    type: "Payment",
    amount: "$60.00",
    date: "January 18",
    card: "Amex ***7890",
  },
  {
    type: "Payment",
    amount: "$60.00",
    date: "January 18",
    card: "Amex ***7890",
  },
  {
    type: "Payment",
    amount: "$60.00",
    date: "January 18",
    card: "Amex ***7890",
  },
  {
    type: "Payment",
    amount: "$60.00",
    date: "January 18",
    card: "Amex ***7890",
  },
  {
    type: "payment",
    amount: "$60.00",
    date: "December 18",
    card: "Amex ***7890",
  },
  {
    type: "Credit",
    amount: "$60.00",
    date: "December 18",
    card: "Amex ***7890",
  },
  {
    type: "payment",
    amount: "$75.00",
    date: "November 18",
    card: "Amex ***7890",
  },
  {
    type: "Payment",
    amount: "$60.00",
    date: "January 18",
    card: "Amex ***7890",
  },
  {
    type: "payment",
    amount: "$60.00",
    date: "December 18",
    card: "Amex ***7890",
  },
  {
    type: "Credit",
    amount: "$60.00",
    date: "December 18",
    card: "Amex ***7890",
  },
  {
    type: "payment",
    amount: "$75.00",
    date: "November 18",
    card: "Amex ***7890",
  },
];

export const monthsHistory = [
  {
    date: "February 2023",
    icon: "VIEW PDF",
  },
  {
    date: "January 2023",
    icon: "VIEW PDF",
  },
  {
    date: "December 2022",
    icon: "VIEW PDF",
  },
  {
    date: "November 2022",
    icon: "VIEW PDF",
  },
  {
    date: "Octomber 2022",
    icon: "VIEW PDF",
  },
  {
    date: "September 2022",
    icon: "VIEW PDF",
  },
  {
    date: "August 2022",
    icon: "VIEW PDF",
  },
  {
    date: "July 2022",
    icon: "VIEW PDF",
  },
  {
    date: "Jun 2022",
    icon: "VIEW PDF",
  },
  {
    date: "May 2022",
    icon: "VIEW PDF",
  },
  {
    date: "April 2022",
    icon: "VIEW PDF",
  },
  {
    date: "March 2022",
    icon: "VIEW PDF",
  },
  {
    date: "February 2022",
    icon: "VIEW PDF",
  },
  {
    date: "January 2022",
    icon: "VIEW PDF",
  },
];

export const accordionCompareList = [
  {
    service: "Ziply Monthly Service Charges",
    previousMonthBill: "$70.00",
    presentMonthBill: "$70.00",
    sublist: [
      {
        service: "Fiber Internet",
        previousMonthBill: "$60.00",
        presentMonthBill: "$60.00",
      },
      {
        service: "$20.00 Discount Thru 12/22/23",
        previousMonthBill: "$0.00",
        presentMonthBill: "$0.00",
      },
      {
        service: "Internet: Gig",
        previousMonthBill: "$0.00",
        presentMonthBill: "$0.00",
      },
      {
        service: "Home Networking Service",
        previousMonthBill: "$0.00",
        presentMonthBill: "$0.00",
      },
      {
        service: "TOTAL MONTH SERVICE CHARGES",
        previousMonthBill: "$70.00",
        presentMonthBill: "$70.00",
      },
    ],
  },
  {
    service: "Other Service Charges & credits",
    previousMonthBill: "$2.33",
    presentMonthBill: "$2.33",
    sublist: [
      {
        service: "High Speed Internet Installation Credit",
        previousMonthBill: "-$90.00",
        presentMonthBill: "-$90.00",
      },
      {
        service: "Installation fee",
        previousMonthBill: "$90.00",
        presentMonthBill: "$90.00",
      },
      {
        service: "PARTIAL MONTH CHARGES AND CREDITS",
        previousMonthBill: "$60.00",
        presentMonthBill: "$60.00",
      },
      {
        service: "Home Networking Service 12/23-12/23",
        previousMonthBill: "$0.33",
        presentMonthBill: "$0.00",
      },
      {
        service: "TOTAL MONTH SERVICE CHARGES",
        previousMonthBill: "$70.00",
        presentMonthBill: "$70.00",
      },
    ],
  },
  {
    service: "Tax & Other charges",
    previousMonthBill: "$1.02",
    presentMonthBill: "$1.02",
    sublist: [
      {
        service: "State Taxes and Other Charges",
        previousMonthBill: "$1.02",
        presentMonthBill: "$1.02",
      },
      {
        service: "TOTAL TAXES AND OTHER CHARGES",
        previousMonthBill: "$1.02",
        presentMonthBill: "$1.02",
      },
    ],
  },
  {
    service: "Balance forward",
    previousMonthBill: "$0.00",
    presentMonthBill: "$0.00",
  },
  {
    service: "New Charges",
    previousMonthBill: "$73.35",
    presentMonthBill: "$73.35",
  },
  {
    service: "Total Bill Amount",
    previousMonthBill: "$73.35",
    presentMonthBill: "$73.35",
  },
];

export const monthsList = [
  {
    date: "Jan 24, 2023",
    billAmount: "$73.35",
    currentBill: true,
  },
  {
    date: "Dec 24, 2022",
    billAmount: "$73.35",
  },
  {
    date: "Nov 24, 2022",
    billAmount: "$73.35",
  },
  {
    date: "Oct 24, 2022",
    billAmount: "$73.35",
  },
  {
    date: "Sep 24, 2022",
    billAmount: "$73.35",
  },
];

export const Articles = [
  {
    name: "Internet",
  },
  {
    name: "Accounts and Passwords",
    state: "account-and-password",
  },
  {
    name: "Billing",
  },
  {
    name: "Troubleshooting",
  },
  {
    name: "Phone",
  },
  {
    name: "TV",
  },
  {
    name: "Order Status",
  },
  {
    name: "Outages",
  },
  {
    name: "Ticket Status",
  },
];

export const HelpResources = [
  {
    heading: "Is there an outage in my area?",
    content: "content",
    link: "Read More >",
  },
  {
    heading: "How do I setup AutoPay?",
    content: "content",
    link: "Read More >",
  },
  {
    heading: "Why did my bill increase this month?",
    content: "content",
    link: "Read More >",
  },
  {
    heading: "I'm moving... can I take Ziply with me?",
    content: "content",
    link: "Read More >",
  },
  {
    heading: "How do I change the password on my WiFi/Router?",
    content: "content",
    link: "Read More >",
  },
];

export const FAQ = [
  {
    heading: "Do I have to cancel and signup again?",
    summary: "",
  },
  {
    heading: "What should I do with my current equipment?",
    summary:
      "In many cases, your installation can becompleted in a single visit. However, if fiber is brand new to your property, a second visit may be required resulting in your instalation taking more time.",
  },
  {
    heading: "Do I have to pay another installation fee?",
    summary: "",
  },
  {
    heading: "Will the amount charged on my bill change when I move?",
    summary:
      "Whether you're moving, upgrading devices or cancelling a service, we can help! If you have Ziply Fiber equipment that needs to be returned,please follow the steps below.Return your equipment within 45 days to avoid incurring fees for unreturned equipment. Note - if you have purchased your equipment, it is yours to keep.",
  },
  {
    heading: "What should I expect during the installation at my new home",
    summary: "",
  },
];

export const topSolutions = [
  {
    heading: "Connection Issues",
    content: "content",
    link: "Check for outages, and get help here >",
  },
  {
    heading: "Need help in understanding your bill?",
    content: "content",
    link: "We break it down >",
  },
  {
    heading: "Newly installed orprepping for an install?",
    content: "content",
    link: "Lets Get you setup >",
  },
  {
    heading: "Connection Issues",
    content: "content",
    link: "Check for outages, and get help here >",
  },
  {
    heading: "Need help in understanding your bill?",
    content: "content",
    link: "We break it down >",
  },
];

export const solutions = [
  { heading: "Speed test", content: "content", link: "Run a Speed test >" },
  { heading: "Speed test", content: "content", link: "Run a Speed test >" },
  { heading: "Speed test", content: "content", link: "Run a Speed test >" },
  { heading: "Speed test", content: "content", link: "Run a Speed test >" },
  { heading: "Speed test", content: "content", link: "Run a Speed test >" },
  { heading: "Speed test", content: "content", link: "Run a Speed test >" },
  { heading: "Speed test", content: "content", link: "Run a Speed test >" },
  { heading: "Speed test", content: "content", link: "Run a Speed test >" },
  { heading: "Speed test", content: "content", link: "Run a Speed test >" },
  { heading: "Speed test", content: "content", link: "Run a Speed test >" },
];

export const accountPassword = [
  {
    heading: "Make Account Changes",
    content: "Need to Upgrade or change your Ziply Fiber services?",
    link: "Read More >",
  },
  {
    heading: "Make Account Changes",
    content: "Need to Upgrade or change your Ziply Fiber services?",
    link: "Read More >",
  },
  {
    heading: "How to Change Password",
    content: "You have different passwords to keep your Ziply Fib...",
    link: "Read More >",
  },
  {
    heading: "How to Change Password",
    content: "You have different passwords to keep your Ziply Fib...",
    link: "Read More >",
  },
  {
    heading: "How to return equipment",
    content: "Whether you're moving, upgrading devices or a...",
    link: "Read More >",
  },
  {
    heading: "How to return equipment",
    content: "Whether you're moving, upgrading devices or a...",
    link: "Read More >",
  },
  {
    heading: "Create Ziply ID",
    content: "Watch a quick video for instructions about setting...",
    link: "Read More >",
  },
  {
    heading: "Create Ziply ID",
    content: "Watch a quick video for instructions about setting...",
    link: "Read More >",
  },
  {
    heading: "Help with Account Issues",
    content: "Check this page for solutions to issues with online...",
    link: "Read More >",
  },
  {
    heading: "Help with Account Issues",
    content: "Check this page for solutions to issues with online...",
    link: "Read More >",
  },
  {
    heading: "Check Trouble Ticket Status",
    content: "Once you've opened a trouble ticket you can track it...",
    link: "Read More >",
  },
  {
    heading: "Check Trouble Ticket Status",
    content: "Once you've opened a trouble ticket you can track it...",
    link: "Read More >",
  },
  {
    heading: "Help with Account Issues",
    content: "Check this page for solutions to issues with online...",
    link: "Read More >",
  },
  {
    heading: "Help with Account Issues",
    content: "Check this page for solutions to issues with online...",
    link: "Read More >",
  },
];

export const dueDays = [
  { id: -1, value: "1 day(s) before your due date" },
  { id: -2, value: "2 day(s) before your due date" },
  { id: -3, value: "3 day(s) before your due date" },
  { id: -4, value: "4 day(s) before your due date" },
  { id: -5, value: "5 day(s) before your due date" },
  { id: -6, value: "6 day(s) before your due date" },
  { id: -7, value: "7 day(s) before your due date" },
  { id: -8, value: "8 day(s) before your due date" },
  { id: -9, value: "9 day(s) before your due date" },
  { id: -10, value: "10 day(s) before your due date" },
];

export const FooterLinks = [
  { value: "Policies", path: "https://ziplyfiber.com/corporate/policies" },
  { value: "Privacy", path: "https://ziplyfiber.com/corporate/privacy-policy" },
  {
    value: "Public Inspection",
    path: "https://ziplyfiber.com/corporate/responsibility/inspection-files/home",
  },
  { value: "Tariffs", path: "https://ziplyfiber.com/corporate/tariffs" },
  { value: "Terms and Conditions", path: "https://ziplyfiber.com/corporate/terms" },
];

export const footerIcons = [
  { name: "Facebook", href: "https://www.facebook.com/ZiplyFiber" },
  { name: "Twitter", href: "https://twitter.com/ZiplyFiber" },
  { name: "LinkedIn", href: "https://www.linkedin.com/company/ziplyfiber/" },
  { name: "Youtube", href: "https://www.youtube.com/channel/UCuptILu22lVJd7uXve6solg" },
  {
    name: "Reddit",
    href: "https://www.reddit.com/r/ZiplyFiber/?rdt=53012",
  },
  { name: "Instagram", href: "https://www.instagram.com/ziplyfiber/" },
];

//Regex
export const accountNumberRegex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}-[0-9]{6}-[0-9]{1}$/;
export const NUMERIC_ONLY_REGEX = /^[0-9]*$/;

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const reasonsToRaiseServiceTicket = [
  "My phone and internet are not working",
  "My internet is down",
  "Phone service not working",
  "Slow internet/frequent disconnects",
  "Wi-Fi not working",
  "Other",
];

export const selectAppointmentDate = ["monday", "tuesday", "wed", "thu", "friday", "sat", "sunday"];
