import { gql } from "@apollo/client";

export const GET_TRANSACTIONS = gql`
  query transactions($transactionsRequired: Float, $accountNumber: String!) {
    transactions(transactionsRequired: $transactionsRequired, accountNumber: $accountNumber) {
      error
      APIStatus
      message
      transactions {
        adjustmentAmount
        adjustmentDescription
        adjustmentDate
        adjustmentColorCode
        adjustmentType
        colorCode
        transactionDescription
        referenceNumber
      }
      count
    }
  }
`;

export const GET_BILL_STATEMENTS = gql`
  query billStatements($isCurrentBill: Boolean, $accountNumber: String!, $billsRequired: Float) {
    billStatements(
      isCurrentBill: $isCurrentBill
      accountNumber: $accountNumber
      billsRequired: $billsRequired
    ) {
      error
      APIStatus
      message
      billStatements {
        status
        date
        amount_due
        id
        url
      }
    }
  }
`;

export const GET_RECURRING_PAYMENTS_BY_BILLING_ACCOUNT_ID = gql`
  query getRecurringPaymentsByBillingAccountId($accountNumber: String!) {
    recurringPaymentsByBillingAccountId(accountNumber: $accountNumber) {
      error
      APIStatus
      message
      id
      valid_from
      auto_payment
      payment_method {
        payment_method_type
        id
        nickname
        token
        identifier_number
        multi_factor_auth
      }
      billing_account {
        id
        account_num
        external_account_num
      }
    }
  }
`;

export const GET_BILLING_DETAILS = gql`
  query billingDetails($accountNumber: String!) {
    billingDetails(accountNumber: $accountNumber) {
      error
      usi
      APIStatus
      message
      isAutoPayEnabled
      isPaperLessBillingEnabled
      currentDueBalance
      billDueDate
      VOIPEnabled
      paymentConfirmationMessage
      ziplyPlanName
      billingAddress
      isNewCustomer
      serviceAddress
      paymentsMigrationToNDS
      paperlessAmount
      autopayAmount
      NDSInprogressAccount
      __typename
    }
  }
`;

export const MAKE_A_PAYMENT = gql`
  mutation makeAPayment($paymentInput: MakeAPaymentInput!) {
    makeAPayment(paymentInput: $paymentInput) {
      error
      APIStatus
      message
      payment_type
      id
      amount
      description
      authorization
      reference
      status
      billing_account {
        id
        account_num
        external_account_num
      }
      payment_method {
        payment_method_type
        id
        nickname
        token
        multi_factor_auth
        identifier_number
      }
      occurred_on
      use_recurring_payment
      unapplied_amount
      currency_code
      payment_processor {
        id
        type
      }
      transaction_id
      refunded_amount
      canceled_amount
      apply_automatically
      mark_for_settlement
    }
  }
`;

export const GET_CURRENT_BILL_DETAILS = gql`
  query currentBillDetails($accountNumber: String!) {
    currentBillDetails(accountNumber: $accountNumber) {
      error
      APIStatus
      message
      url
      fromDate
      toDate
      accountNumber
      billingDate
      currentBill
    }
  }
`;

export const GET_COMPARE_BILLS = gql`
  query compareBills($secondMonth: String!, $firstMonth: String!, $accountNumber: String!) {
    compareBillDetails(
      secondMonth: $secondMonth
      firstMonth: $firstMonth
      accountNumber: $accountNumber
    ) {
      error
      message
      result
    }
  }
`;

export const AUTOPAY_PAPERLESS_DETAILS = gql`
  query autopayPaperlessDetails($accountNumber: String!) {
    autoPayAndPaperlessDetails(accountNumber: $accountNumber) {
      error
      APIStatus
      message
      paperlessDetails {
        error
        APIStatus
        message
        paperlessFlag
      }
      autoPayDetails {
        error
        APIStatus
        accountNumber
        message
        autoPayInfo {
          id
          amountType
          amountValue
          paymentMethodId
          paymentMethodName
          paymentMethodType
          paymentMethodlast4
          scheduleDayBefore
          startDate
          endDate
          systemOfOrigin
          autopaymentDeductionDate
          currentBillDueDate
        }
      }
    }
  }
`;

export const CANCEL_AUTOPAY = gql`
  mutation cancelAutoPay($autoPayInput: AutoPayInput!, $accountNumber: String!) {
    cancelAutoPay(autoPayInput: $autoPayInput, accountNumber: $accountNumber) {
      error
      APIStatus
      message
    }
  }
`;

export const SET_AUTOPAY = gql`
  mutation setAutoPay($autoPayInput: AutoPayInput!, $accountNumber: String!) {
    setAutoPay(autoPayInput: $autoPayInput, accountNumber: $accountNumber) {
      error
      APIStatus
      message
    }
  }
`;

export const EDIT_AUTOPAY = gql`
  mutation editAutoPay($autoPayInput: AutoPayInput!, $accountNumber: String!) {
    editAutoPay(autoPayInput: $autoPayInput, accountNumber: $accountNumber) {
      error
      APIStatus
      message
    }
  }
`;

export const GET_PAYMENT_METHODS = gql`
  query paymentMethods($accountNumber: String!) {
    paymentMethods(accountNumber: $accountNumber) {
      accountNumber
      paymentDetails {
        id
        billingAccountId
        nameOnTheCard
        paymentMethodType
        type
        cardType
        cardNumber
        token
        expirationDate
        autoPayment
        securityCode
        cardHolderName
        billingAccountId
      }
      APIStatus
      error
      message
    }
  }
`;

export const SET_PAPERLESS = gql`
  mutation setPaperless($paperlessFlag: Boolean!, $accountNumber: String!) {
    setPaperless(accountNumber: $accountNumber, paperlessFlag: $paperlessFlag) {
      error
      APIStatus
      message
    }
  }
`;

export const VOIP_DETAILS_BY_SERVICE_PERIOD_ID = gql`
  mutation VOIPDetailsByServicePeriodId(
    $servicePeriodId: String!
    $pageNumber: String!
    $pageSize: String!
    $accountNumber: String!
    $totalEvents: String
  ) {
    VOIPDetailsByServicePeriodId(
      servicePeriodId: $servicePeriodId
      pageNumber: $pageNumber
      pageSize: $pageSize
      accountNumber: $accountNumber
      totalEvents: $totalEvents
    ) {
      error
      message
      APIStatus
      tollChargedTo
      totalEvents
      eventCharges {
        dateTime
        min
        type
        place
        numberCalled
        charge
      }
    }
  }
`;

export const VOIP_INVOICE_DETAILS_BY_ACC_NUM = gql`
  query VOIPInvoiceDetailsByAccountNumber($accountNumber: String!) {
    VOIPInvoiceDetailsByAccountNumber(accountNumber: $accountNumber) {
      error
      message
      APIStatus
      invoices {
        date
        servicePeriodId
        totalAmount
      }
    }
  }
`;

export const VOIP_SERVICE_VERIFICATION_BY_ACC_NUM = gql`
  query VOIPServiceVerificationByAccountNumber($accountNumber: String!, $isNds: Boolean!) {
    VOIPServiceVerificationByAccountNumber(accountNumber: $accountNumber, isNds: $isNds) {
      APIStatus
      error
      message
      VOIPEnabled
    }
  }
`;

export const VOIP_INVOICES_DETAILS_DPI_CUSTOMER = gql`
  mutation VOIPInvoiceDetailsForDPICustomer(
    $accountNumber: String!
    $month: String!
    $year: String!
    $pageNumber: Float!
    $pageSize: Float!
  ) {
    VOIPInvoiceDetailsForDPICustomer(
      accountNumber: $accountNumber
      month: $month
      year: $year
      pageNumber: $pageNumber
      pageSize: $pageSize
    ) {
      error
      message
      APIStatus
      tollChargedTo
      totalEvents
      totalAmount
      eventCharges {
        dateTime
        min
        place
        numberCalled
        charge
      }
    }
  }
`;
