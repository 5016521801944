import { gql } from "@apollo/client";

export const GET_ACCOUNT_INFO = gql`
  query getAccountInfo($accountNumber: String!) {
    accountInfo(accountNumber: $accountNumber) {
      error
      APIStatus
      message
      customerName
      accountNumber
      btnNumber
      pin
      hasLinkedAccount
      billingPrefrence {
        error
        APIStatus
        message
        isAutoPayEnabled
        isPaperLessBillingEnabled
        autoPayDiscount
        paperlessBillingDiscount
        billingAddrress {
          addressLine1
          addressLine2
          addressLine3
          cityName
          stateAbbreviation
          zipCode
        }
      }
      profileInfo {
        error
        APIStatus
        message
        contactNumber
        contactNumberVerified
        contactEmail
        contactEmailVerified
        username
      }
      planAndServicesInfo {
        error
        APIStatus
        message
        ziplyPlanName
        amount
        accountNumber
      }
    }
  }
`;

export const GET_CONTACT_INFO = gql`
  query contactInfo($accountNumber: String!) {
    contactInfo(accountNumber: $accountNumber) {
      error
      APIStatus
      message
      contactNumber
      contactNumberVerified
      contactEmail
      contactEmailVerified
      username
    }
  }
`;

export const GET_NOTIFICATION_PREFERENCES = gql`
  query getNotificationPreferences($accountNumber: String!, $contactId: String!) {
    notificationPreferences(accountNumber: $accountNumber, contactId: $contactId) {
      error
      APIStatus
      message
      sendMeEmails
      sendMeTextMessages
    }
  }
`;

export const GET_PLANS_SERVICES = gql`
  query plansAndServices($accountNumber: String!) {
    plansAndServices(accountNumber: $accountNumber) {
      error
      APIStatus
      message
      firstName
      lastName
      ziplyPlanName
      amount
      accountNumber
      serviceAddress
      copperToFiber
      fiberToFiber
      accountUuid
      disableNutritionLabel
      usi
      billingAddress
      btn
      paymentsMigrationToNDS
      CTFOfficeCode
      CTFTerminalType
      CTFTechnology
      CTFMedia
      CTFOfferId
      env
      samControlNumber
      rateCenter
      timeZone
      fallOut
    }
  }
`;

export const GET_BILLING_PREFERENCES = gql`
  query billingPreferences($accountNumber: String!) {
    billingPreferences(accountNumber: $accountNumber) {
      error
      APIStatus
      message
      autoPayDiscount
      paperlessBillingDiscount
      billingAddrress {
        error
        APIStatus
        message
        addressLine1
        addressLine2
        addressLine3
        cityName
        stateAbbreviation
        zipCode
      }
    }
  }
`;

export const UPDATE_BILLING_ADDRESS = gql`
  mutation updateBillingAddress(
    $accountNumber: String!
    $billingAddressDetails: updateBillingAddressInput!
  ) {
    updateBillingAddress(
      accountNumber: $accountNumber
      billingAddressDetails: $billingAddressDetails
    ) {
      error
      APIStatus
      message
    }
  }
`;

export const ADD_AUTHORIZED_USER = gql`
  mutation addAuthorizedUser(
    $email: String!
    $contactId: String!
    $authorizedUserInput: CustomerLinkAccountInput!
  ) {
    addAuthorizedUser(
      email: $email
      contactId: $contactId
      authorizedUserInput: $authorizedUserInput
    ) {
      error
      APIStatus
      message
    }
  }
`;

export const GET_AUTHORIZED_USERS = gql`
  query authorizedUsers($accountNumber: String!) {
    authorizedUsers(accountNumber: $accountNumber) {
      error
      APIStatus
      message
      contactId
      email
      contactName
    }
  }
`;

export const LINK_ACCOUNT = gql`
  mutation linkAccount($contactId: String!, $customerLinkAccountInput: CustomerLinkAccountInput!) {
    linkAccount(contactId: $contactId, customerLinkAccountInput: $customerLinkAccountInput) {
      error
      APIStatus
      message
      accountUUID
      isNds
      usi
      isActive
    }
  }
`;

export const IN_APP_NOTIFICATIONS = gql`
  query inAppNotifications($isRead: Boolean, $accountNumber: String!, $contactId: String!) {
    inAppNotifications(isRead: $isRead, accountNumber: $accountNumber, contactId: $contactId) {
      error
      APIStatus
      message
      count
      inAppNotifications {
        error
        APIStatus
        message
        createdAt
        accountNumber
        title
        body
        clickAction
        isRead
      }
    }
  }
`;

export const UPDATE_NOTIFICATION_PREFERENCES = gql`
  mutation updateNotificationPrefrence(
    $accountNumber: String!
    $notificationPreferenceInput: NotificationPreferencesInput!
    $contactId: String!
  ) {
    updateNotificationPrefrence(
      accountNumber: $accountNumber
      notificationPreferenceInput: $notificationPreferenceInput
      contactId: $contactId
    ) {
      error
      APIStatus
      message
    }
  }
`;

export const DELETE_AUTHORIZED_USER = gql`
  mutation deleteAuthorizedUser($accountNumber: String!, $contactId: String!) {
    deleteAuthorizedUser(accountNumber: $accountNumber, contactId: $contactId) {
      error
      APIStatus
      message
    }
  }
`;
export const DELETE_PAYMENT_METHOD = gql`
  mutation deletePaymentMethod($accountNumber: String!, $paymentMethodId: String!) {
    deletePaymentMethod(paymentMethodId: $paymentMethodId, accountNumber: $accountNumber) {
      error
      APIStatus
      message
    }
  }
`;

export const UNLINK_ACCOUNT = gql`
  mutation unlinkAccount(
    $pin: String!
    $accountNumber: String!
    $contactId: String!
    $eventName: String
  ) {
    unlinkAccount(
      pin: $pin
      accountNumber: $accountNumber
      contactId: $contactId
      eventName: $eventName
    ) {
      error
      APIStatus
      message
    }
  }
`;

export const DELETE_CONTACT_PROFILE = gql`
  mutation deleteContactProfile($email: String!) {
    deleteContactProfile(email: $email) {
      message
      APIStatus
      error
    }
  }
`;

export const GET_COMMERCIAL_ACCOUNT = gql`
  query getCommercialAccountNumberByContactId($email: String!, $contactId: String!) {
    getCommercialAccountNumberByContactId(email: $email, contactId: $contactId) {
      accountNumber
      message
      APIStatus
      error
    }
  }
`;

export const ORDER_AND_TROUBLE_TICKET_HISTORY_QUERY = gql`
  query orderAndTroubleTicketHistoryByAccountNumber($accountNumber: String!, $isNds: Boolean!) {
    orderAndTroubleTicketHistoryByAccountNumber(accountNumber: $accountNumber, isNds: $isNds) {
      APIStatus
      error
      message
      orderAndTroubleTicketHistory {
        id
        status
        createdDate
        type
        colorCode
        completionDate
      }
    }
  }
`;

export const NUTRITION_LABEL_API = gql`
  query nutritionLabel($accountNumber: String!, $isNds: Boolean!) {
    nutritionLabel(accountNumber: $accountNumber, isNds: $isNds) {
      APIStatus
      message
      error
      name
      description
      accountNumber
      ziplyLogo
      headingLabel
      Sections {
        sectionHeading
        sectionValue
        sectionDiv
        sectionHeadingSubText
        addonSection
        govTaxLabel
        govTaxValue
        oneTimeFeeLabel
        optionalLabel
        rowSections {
          secLabel
          secValue
          secArray {
            name
            price
            description
          }
        }
      }
    }
  }
`;
