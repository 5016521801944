import { Stack, Typography, Box } from "@mui/material";
import { formatDateWithSuffix, getDollarSign } from "../../../../../../Services/helper";
import {
  CompareBillStack,
  MonthlyBillCard,
  StyledCheckbox,
} from "../../../../../../Styles/GlobalStyles";
import { Spinner } from "../../../../../../components/shared/LoadingSpinner/Spinner";

export const CompareBills = (props: any) => {
  const { billingStatementData, selectedDate, setSelectedDate, billStatementLoading } = props;
  const sortedItems = billingStatementData?.slice()?.sort((a: any, b: any) => {
    const dateA: any = new Date(a.date);
    const dateB: any = new Date(b.date);
    return dateB - dateA;
  });

  const handleCheckboxChange = (event: any) => {
    if (selectedDate.includes(event.target.value)) {
      const filteredData = selectedDate?.filter((item: any) => item !== event.target.value);
      setSelectedDate(filteredData);
    } else {
      if (selectedDate.length < 2) {
        setSelectedDate([...selectedDate, event.target.value]);
      }
    }
  };

  return (
    <>
      <CompareBillStack>
        {billStatementLoading ? (
          <Box my={12}>
            <Spinner />
          </Box>
        ) : (
          <>
            {sortedItems?.map((item: any, index: any) => {
              const dateString = item?.date;
              const date = new Date(dateString);
              const dateWithSuffix = formatDateWithSuffix(date);
              return (
                <MonthlyBillCard direction={"row"} key={index} justifyContent={"space-between"}>
                  <Stack direction={"row"} width={"80%"}>
                    <StyledCheckbox
                      type="checkbox"
                      value={item?.date}
                      checked={selectedDate?.includes(item?.date) ? true : false}
                      disabled={
                        selectedDate?.includes(item?.date)
                          ? false
                          : selectedDate?.length === 2
                          ? true
                          : false
                      }
                      onChange={handleCheckboxChange}
                    />
                    {item && (
                      <Typography
                        data-testid="months"
                        fontSize={{ xs: "0.8rem", sm: "1rem" }}
                        component={"span"}
                        dangerouslySetInnerHTML={{ __html: dateWithSuffix }}
                      />
                    )}
                  </Stack>
                  <Typography component={"span"} data-testid="amount">
                    {item && getDollarSign(item?.amount_due)}
                  </Typography>
                </MonthlyBillCard>
              );
            })}
          </>
        )}
      </CompareBillStack>
    </>
  );
};
