import React from "react";
import { StyledA, StyledCard } from "../../../Styles/GlobalStyles";
import { Typography } from "@mui/material";
import Button from "../../Button/Button";
import { Theme } from "../../../Styles/GlobalTheme";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { INACTIVE_ACCOUNT_BANNER_PRIMARY_TEXT } from "../../../Constants/constants";
import { setMakePaymentRoute } from "../../../Redux/Slices/PageSlice";

function InactiveAccountBanner() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { currentDueBalance } = useSelector((state: any) => state.customerPlanDetails);
  const { inactivatePaymentStatus } = useSelector((state: any) => state.paymentDetails);
  const handlePayNow = () => {
    navigate("/billing/makeapayment");
    dispatch(setMakePaymentRoute(location?.pathname));
  };
  const INACTIVE_ACCOUNT_BANNER_SECONDARY_TEXT = (
    <>
      <Typography>
        Your account is currently disconnected or inactive. You may be able to check your bills and
        transactions. To reactivate your account please place a new order for this address.{" "}
        <StyledA href="https://ziplyfiber.com/sales/" target="_blank">
          Place a New Order
        </StyledA>
      </Typography>
    </>
  );

  const description =
    inactivatePaymentStatus || parseInt(currentDueBalance) <= 0 || currentDueBalance === null
      ? INACTIVE_ACCOUNT_BANNER_SECONDARY_TEXT
      : INACTIVE_ACCOUNT_BANNER_PRIMARY_TEXT;
  return (
    <StyledCard>
      <Typography variant="h6" fontSize="1.3rem">
        Disconnected Account {parseInt(currentDueBalance) > 0 && "- Settle Your Balance"}
      </Typography>
      <Typography
        mb={1.5}
        color={Theme.palette.customcolor.profileTypoGraphyColor}
        fontSize={{ xs: "14px", sm: "16px" }}>
        {description}
      </Typography>
      {parseInt(currentDueBalance) > 0 && (
        <Button type="root" title="MAKE A PAYMENT" onClick={handlePayNow} />
      )}
    </StyledCard>
  );
}

export default InactiveAccountBanner;
