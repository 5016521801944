import { Box, Divider, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { DateTimeOptions, getMonthYear } from "../../../../../Services/helper";
import {
  BoxStyle,
  ColumnCenterStack,
  InformationBox,
  PTypography,
  StyledA,
  StyledElement,
} from "../../../../../Styles/GlobalStyles";
import { EmptyDataComponent } from "../../../../../components/shared/EmptyDataComponent/EmptyDataComponent";
import {
  PAST_BILLS_MESSAGE,
  RETRIEVING_PASTBILLS_MESSAGE,
} from "../../../../../Constants/constants";
import { ErrorComponent } from "../../../../../components/ErrorComponent/ErrorComponent";
import { Spinner } from "../../../../../components/shared/LoadingSpinner/Spinner";
import Button from "../../../../../components/Button/Button";
import { Theme } from "../../../../../Styles/GlobalTheme";

const PastBill = (props: any) => {
  const {
    billingStatementData,
    billStatementsError,
    billStatementsApiData,
    getbillStatements,
    billStatementLoading,
  } = props;
  const customerDetails = useSelector((state: any) => state.customerInfoDetails);
  const { userToken } = useSelector((state: any) => state.userInfo);
  const [showAll, setShowAll] = useState<boolean>(false);
  const accountNumber = customerDetails?.accountNumber;
  const hyphenSeperatedAccNum = accountNumber?.replace(
    /^(\d{3})(\d{3})(\d{4})(\d{6})(\d)$/,
    "$1-$2-$3-$4-$5"
  );

  const handleMonths = () => {
    getbillStatements({
      variables: {
        billsRequired: null,
        isCurrentBill: false,
        accountNumber: customerDetails?.accountNumber,
      },
    });
    setShowAll(true);
  };

  const handleShowLess = () => {
    getbillStatements({
      variables: {
        billsRequired: 5,
        isCurrentBill: false,
        accountNumber: customerDetails?.accountNumber,
      },
    });
    setShowAll(false);
  };

  const handleRefresh = () => {
    getbillStatements({
      variables: {
        billsRequired: 5,
        isCurrentBill: false,
        accountNumber: customerDetails?.accountNumber,
      },
      fetchPolicy: "network-only",
    });
  };

  return (
    <>
      <InformationBox>
        {billStatementLoading ? (
          <Box my={20}>
            <Spinner loadingText={RETRIEVING_PASTBILLS_MESSAGE} />
          </Box>
        ) : (
          <>
            <Stack
              direction={"row"}
              my={2}
              spacing={2}
              alignItems="center"
              justifyContent={{ xs: "space-between", md: "flex-start" }}>
              <Typography fontWeight={600} fontSize={{ xs: "14px", md: "16px" }} flexShrink={1}>
                Account number
              </Typography>
              <Typography
                flexShrink={2}
                fontSize={{ xs: "12px", md: "16px" }}
                data-testid="accountNumber">
                {hyphenSeperatedAccNum}
              </Typography>
            </Stack>
            {billStatementsApiData?.billStatements?.error || billStatementsError ? (
              <Box my={5}>
                <ErrorComponent onRefresh={handleRefresh} />
              </Box>
            ) : billingStatementData?.length === 0 ? (
              <Stack py={8}>
                <EmptyDataComponent message={PAST_BILLS_MESSAGE} />
              </Stack>
            ) : (
              <>
                <BoxStyle
                  height={billingStatementData?.length > 5 ? 255 : "auto"}
                  overflow={showAll ? "auto" : "hidden"}>
                  {billingStatementData?.map(({ date, url }: any, index: number) => {
                    const urlData = customerDetails?.ndsObj?.isNDS
                      ? `${url}&token=${userToken}`
                      : url;
                    const isLastChild = index === billingStatementData.length - 1;
                    const postedDate = new Date(date);
                    const options: DateTimeOptions = { year: "numeric", month: "long" };
                    const monthYear = getMonthYear(postedDate, options);
                    return (
                      <Box key={date}>
                        <ColumnCenterStack direction={"row"} py={2}>
                          <PTypography data-testid="month">{monthYear}</PTypography>
                          {url === null ? (
                            <Typography
                              sx={{
                                color: Theme.palette.customcolor.cancelbtntext,
                                cursor: "default",
                              }}>
                              VIEW PDF
                            </Typography>
                          ) : (
                            <StyledA target="_blank" href={urlData} data-testid="pdf">
                              <Button
                                disabled={url === null}
                                onClick={() => {}}
                                title="VIEW PDF"
                                type="pdf"
                              />
                            </StyledA>
                          )}
                        </ColumnCenterStack>
                        {!(showAll || billingStatementData?.length > 4) ? (
                          !isLastChild && <Divider />
                        ) : (
                          <Divider />
                        )}
                      </Box>
                    );
                  })}
                </BoxStyle>
                {!showAll ? (
                  <StyledElement
                    display={billingStatementData?.length > 4 ? "block" : "none"}
                    marginTop={"14px"}
                    onClick={handleMonths}
                    fontSize={{ xs: "0.8rem", sm: "1rem" }}>
                    SHOW ALL BILLS
                  </StyledElement>
                ) : (
                  <StyledElement
                    display={billingStatementData?.length > 4 ? "block" : "none"}
                    marginTop={"14px"}
                    onClick={handleShowLess}
                    fontSize={{ xs: "0.8rem", sm: "1rem" }}>
                    SHOW LESS
                  </StyledElement>
                )}
              </>
            )}
          </>
        )}
      </InformationBox>
    </>
  );
};

export default PastBill;
