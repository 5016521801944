import React from "react";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import { PageGrid } from "../../Styles/GlobalStyles";
import { useGlobysURL } from "../../hooks/Login/useGlobysURL";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner/LoadingSpinner";
import { GlobysIframe } from "./GlobysIframe";
import { ErrorComponent } from "../../components/ErrorComponent/ErrorComponent";

export const CommercialScreen = () => {
  const { email } = useSelector((state: any) => state.userInfo);
  const { globysUrlError } = useSelector((state: any) => state.billingAddress);
  const impersonatedEmail = localStorage.getItem("impersonatedEmail");
  const GlobysID = localStorage.getItem("globysId");
  const { globysURLLoading, getGlobysURL } = useGlobysURL(
    email || impersonatedEmail,
    GlobysID ?? ""
  );

  React.useEffect(() => {
    if ((email || impersonatedEmail) && GlobysID) {
      getGlobysURL();
    }
  }, [email, GlobysID]);

  return (
    <>
      <Box minHeight={"90vh"}>
        <PageGrid container>
          {!globysUrlError ? (
            <Grid item>
              <GlobysIframe />
            </Grid>
          ) : (
            <Grid container justifyContent="center" alignItems="center" style={{ height: "60vh" }}>
              <ErrorComponent onRefresh={getGlobysURL} />
            </Grid>
          )}
        </PageGrid>
      </Box>

      {globysURLLoading && <LoadingSpinner />}
    </>
  );
};
