import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  staticData: {},
  topsolutions: {},
};
const supportDetailsSlice = createSlice({
  name: "supportDetailsSlice",
  initialState,
  reducers: {
    topSolutionsData: (state, action) => {
      state.topsolutions = action?.payload;
    },
    staticDetails: (state, action) => {
      state.staticData = action.payload;
    },
  },
});

export const { topSolutionsData, staticDetails } = supportDetailsSlice.actions;
export const supportDetailsReducer = supportDetailsSlice.reducer;
