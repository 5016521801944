import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import { Box, IconButton, Badge, Stack, Typography, styled } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import Icon from "@mui/material/Icon";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {
  StyledBar,
  StyledElement,
  StyledDivBar,
  StyledLink,
  StyledDrawer,
  NavListStack,
  MenuBar,
  ZiplyLogo,
  PageNameTypography,
  RowCenterStack,
  StyledA,
} from "../../Styles/GlobalStyles";
import { LOGOUT_HEADING, LOGOUT_PRIMARYCONTENT } from "../../Constants/constants";
import { NavList } from "../Navlist/NavList";
import { NavProps } from "../Navlist/NavList";
import { StyledTypography } from "../shared/CustomModal/CustomModalStlyes";
import { signOut } from "firebase/auth";
import { auth } from "../../Config/firebase";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { LoadingSpinner } from "../shared/LoadingSpinner/LoadingSpinner";
import CloseIcon from "@mui/icons-material/Close";
import { RootState } from "../../Redux/Store/Store";
import { UpdateErrorComponent } from "../shared/UpdateErrorComponent/UpdateErrorComponent";
import { ConfirmationModel } from "../shared/ConfirmationModel/ConfirmationModel";
import ZiplyLogoIcon from "../../assets/images/ziply-logo.svg";
import { customerInfoDetails, setOrderDetails } from "../../Redux/Slices/CustomerInfoSlice";
import { deleteCookie, getFlags } from "../../Services/helper";
import { setUserError, setUserNotInFirestore } from "../../Redux/Slices/UserSlice";
import { adminInfo } from "../../Redux/Slices/IsAdminSlice";
import { Theme } from "../../Styles/GlobalTheme";

export interface LinkProps {
  nav?: boolean;
  active?: number;
}

export interface HeaderIconProps {
  outline?: number;
}
export const HeaderIcon = styled(Icon)<HeaderIconProps>(({ outline }) => ({
  borderRadius: "50%",
  border: outline ? "none" : "2px solid",
  padding: outline ? "0" : "2px",
  fontSize: outline ? "1.6rem" : "1.2rem",
}));

export const navLinks: NavProps[] = [
  {
    name: " Home",
    link: "/home",
    icon: (
      <HeaderIcon outline={+true}>
        <HomeOutlinedIcon fontSize="inherit" />
      </HeaderIcon>
    ),
  },
  {
    name: "Billing",
    link: "/billing",
    icon: (
      <HeaderIcon>
        <AttachMoneyIcon fontSize="inherit" />
      </HeaderIcon>
    ),
  },
  {
    name: "Account",
    link: "/account",
    icon: (
      <HeaderIcon>
        <PersonOutlinedIcon fontSize="inherit" />
      </HeaderIcon>
    ),
  },
  {
    name: "Messages",
    link: "/messages",
    icon: (
      <Badge variant="dot" color="error" overlap="circular">
        <HeaderIcon>
          <NotificationsOutlinedIcon fontSize="inherit" />
        </HeaderIcon>
      </Badge>
    ),
  },
];

export const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const customerDetails = useSelector(
    (state: any) => state?.customerInfoDetails?.givenNameFamilyName
  );
  const { activeAccount } = useSelector((state: any) => state?.customerInfoDetails);
  const { openSwitchModal } = useSelector((state: any) => state.appProposal);
  const [openLoader, setOpenLoader] = React.useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<any>({
    heading: "",
    primaryContent: "",
    containedButton: { onClick: () => {}, buttonType: "", buttonTitle: "" },
  });

  const commercial: boolean =
    window.location.pathname.includes("/myaccount/commercial") ||
    window.location.pathname.includes("/myaccount/select-account") ||
    window.location.pathname.includes("/lac/select-account") ||
    window.location.pathname.includes("/lac/commercial") ||
    window.location.pathname.includes("/myaccount/acc-not-found") ||
    window.location.pathname.includes("/lac/acc-not-found");

  const displaySelectAccount = sessionStorage.getItem("isMultiAccount");

  const handleCloseLoader = () => {
    setOpenLoader(false);
  };
  const handleOpenLoader = () => {
    setOpenLoader(true);
  };
  const adminName = localStorage.getItem("userName");
  const { isAuthenticated } = useSelector((state: RootState) => state.isAuthenticated);
  const handleSignOut = () => {
    handleOpenLoader();
    setOpen(false);
    setTimeout(async () => {
      await signOut(auth)
        .then(() => {
          // localStorage.removeItem("linkedAccountNumber");
          dispatch({ type: "USER_LOGOUT" });
          localStorage.clear();
          deleteCookie("Token");
          deleteCookie("customerUSI");
          handleCloseLoader();
        })
        .catch((err) => {
          setOpenError(true);
          setOpen(false);
          handleCloseLoader();
          throw new Error(err);
        });
    }, 3000);
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const handleDialog = () => {
    setIsDrawerOpen(false);
    setOpen(true);
    setDialogData({
      ...dialogData,
      heading: LOGOUT_HEADING,
      primaryContent: LOGOUT_PRIMARYCONTENT,
      containedButton: {
        ...dialogData?.containedButton,
        buttonType: "root",
        buttonTitle: "YES, LOG OUT",
      },
    });
  };

  const handleAdminSignOut = () => {
    window.location.replace("lac/admin/login");
    localStorage.clear();
    dispatch({ type: "USER_LOGOUT" });
    deleteCookie("Token");
    deleteCookie("customerUSI");
  };
  const dispatch = useDispatch();
  const displayName = customerDetails?.givenName
    ? customerDetails.givenName[0]?.toUpperCase() + customerDetails.givenName.substring(1)
    : "";

  function handleExitAccount() {
    navigate("/adminscreen");
    dispatch({ type: "EXIT_ACCOUNT_LAC" });
    sessionStorage.removeItem("error");
    localStorage.removeItem("impersonatedEmail");
    window.location.reload();
  }
  const handleRoutingToHome = () => {
    navigate("/home");
  };
  const envFlags = useSelector((state: any) => state?.userInfo?.envFlags);
  const INACTIVE_ACCOUNTS = getFlags("inactive_accounts", envFlags);

  return (
    <>
      <Box position="fixed" width="100%" top="0" zIndex={openSwitchModal ? 0 : 1} bgcolor="white">
        <StyledBar
          width={{ sm: "97%", md: "80%" }}
          margin={"auto"}
          display={{ xs: "none", sm: "block" }}>
          <RowCenterStack mt={1} fontSize={"0.8rem"}>
            {isAuthenticated ? (
              <RowCenterStack>
                <IconButton color="inherit" size="small">
                  <PersonIcon
                    fontSize="inherit"
                    sx={{
                      color: "green",
                    }}
                  />
                </IconButton>
                <StyledTypography mr={2} color="green" fontSize={"14px"} fontWeight={"600"}>
                  Welcome, {adminName}
                </StyledTypography>
                {window.location.pathname !== "/lac/select-account" && (
                  <StyledElement
                    display={displaySelectAccount ? "block" : "none"}
                    onClick={() => navigate("/select-account")}
                    fontSize={"14px"}
                    fontWeight={600}>
                    Select account
                  </StyledElement>
                )}
              </RowCenterStack>
            ) : (
              <StyledTypography color="green" fontSize={"14px"} fontWeight={"600"}>
                My Account
              </StyledTypography>
            )}
            <Stack direction="row" spacing={2} alignItems="center">
              <Box>
                {isAuthenticated && displayName ? (
                  <StyledTypography mr={2} color="green" fontSize={"14px"} fontWeight={"600"}>
                    You are logged in as {displayName}
                  </StyledTypography>
                ) : (
                  displayName && (
                    <StyledTypography mr={2} color="green" fontSize={"14px"} fontWeight={"600"}>
                      Welcome, {displayName}
                    </StyledTypography>
                  )
                )}
              </Box>
              <Box>
                {isAuthenticated ? (
                  <StyledElement
                    textcolor="black"
                    fontWeight={"600"}
                    fontSize={"14px"}
                    onClick={handleExitAccount}>
                    Exit Account
                  </StyledElement>
                ) : (
                  <StyledElement
                    textcolor="black"
                    fontWeight={"600"}
                    fontSize={"14px"}
                    onClick={handleDialog}>
                    Log out
                  </StyledElement>
                )}
              </Box>
            </Stack>
          </RowCenterStack>
          <Stack alignItems="flex-end">
            {isAuthenticated && (
              <StyledElement
                display={"flex"}
                justifyContent={"flex-end"}
                textcolor="black"
                fontWeight={"600"}
                fontSize={"14px"}
                onClick={handleAdminSignOut}>
                Sign out Admin
              </StyledElement>
            )}
          </Stack>
        </StyledBar>
        {open && (
          <ConfirmationModel
            dialogData={dialogData}
            setDialogData={setDialogData}
            openDialog={open}
            setOpenDialog={setOpen}
            onClick={handleSignOut}
          />
        )}
        <Box boxShadow={"0px 3px 4px 0px rgb(193, 193, 193)"} px={{ xs: "0.6rem", sm: "0" }}>
          <RowCenterStack width={{ sm: "100%", md: "82%" }} margin={"auto"}>
            <StyledA target="_self" onClick={handleRoutingToHome}>
              <ZiplyLogo height={"60px"} src={ZiplyLogoIcon} alt="ziply-logo" />
            </StyledA>
            <PageNameTypography fontSize={"14px"}>My Account</PageNameTypography>
            {!commercial && (
              <NavListStack direction={"row"}>
                <NavList navLinks={navLinks} />
              </NavListStack>
            )}
            <MenuBar ml={4} onClick={toggleDrawer(true)}>
              <MenuIcon />
            </MenuBar>
            <StyledDrawer anchor={"right"} open={isDrawerOpen} onClose={toggleDrawer(false)}>
              <RowCenterStack>
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography
                    component={"p"}
                    flexShrink={1}
                    color="primary"
                    fontWeight="600"
                    fontSize={"0.8rem"}>
                    Welcome, {customerDetails?.givenName}
                  </Typography>
                </Stack>
                <IconButton onClick={toggleDrawer(false)}>
                  <CloseIcon />
                </IconButton>
              </RowCenterStack>
              <StyledElement
                textcolor="black"
                fontWeight={600}
                fontSize={"small"}
                onClick={handleDialog}
                ml={4}>
                Sign Out
              </StyledElement>
              <Stack ml={2.5} pt={6}>
                {navLinks?.map(({ name, link }) => {
                  const isDisabled =
                    INACTIVE_ACCOUNTS &&
                    name.toLowerCase() === "messages" &&
                    activeAccount === false;

                  const content = (
                    <>
                      <StyledLink
                        active={pathname === link || pathname?.includes(link) ? +true : undefined}
                        to={link}
                        style={{
                          pointerEvents: isDisabled ? "none" : "auto",
                          opacity: isDisabled ? 0.5 : 1,
                        }}>
                        {name}
                      </StyledLink>
                      {pathname === link || pathname?.includes(link) ? <StyledDivBar /> : null}
                    </>
                  );

                  return (
                    <Box width={"min-content"} key={name}>
                      <Typography component={"p"} py={3} fontSize={"0.6rem"} fontWeight="600">
                        {content}
                      </Typography>
                    </Box>
                  );
                })}
              </Stack>
            </StyledDrawer>
          </RowCenterStack>
        </Box>
      </Box>
      {openLoader && <LoadingSpinner />}
      {openError && <UpdateErrorComponent openError={openError} setOpenError={setOpenError} />}
    </>
  );
};
