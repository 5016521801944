import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid, Box, Typography, Stack } from "@mui/material";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AccountCard } from "../../components/shared/AccountCard/AccountCard";
import { useDispatch, useSelector } from "react-redux";
import { CustomTabs } from "../../components/shared/CustomTabs/CustomTabs";
import {
  AUTOPAY_PAPERLESS_DETAILS,
  CANCEL_AUTOPAY,
  GET_BILL_STATEMENTS,
  GET_TRANSACTIONS,
  VOIP_SERVICE_VERIFICATION_BY_ACC_NUM,
} from "../../Pages/Billing/query";
import {
  CardHeading,
  PageGrid,
  PageNameTypography,
  StickyBox,
  StyledA,
  StyledCard,
} from "../../Styles/GlobalStyles";
import { PaperLessBilling } from "../../components/shared/TotalBalance/PaperLessBilling/PaperLessBilling";
import { AutoPay } from "../../components/shared/TotalBalance/AutoPay/AutoPay";
import { NeedHelpCard } from "../../components/shared/NeedHelpCard/NeedHelpCard";
import { TotalBalanceCard } from "../../Pages/Home/Components/TotalBalanceCard/TotalBalanceCard";
import { BlankPage } from "../../components/BlankPage/BlankPage";
import { CompletedTransaction } from "./Components/Transactions/CompletedTransaction/CompletedTransaction";
import {
  setBillingInfo,
  setCompareBill,
  setCurrentBill,
  setVoipBill,
  setPastBills,
  setShowTabs,
  setVoipNonNdsDate,
} from "../../Redux/Slices/BillingInfoSlice";
import { LinkingWaitMessage } from "../../components/shared/LinkingWaitMessage/LinkingWaitMessage";
import { ErrorComponent } from "../../components/ErrorComponent/ErrorComponent";
import { Spinner } from "../../components/shared/LoadingSpinner/Spinner";
import { AUTOPAY_PAPERLESS_LOADING_MESSAGE } from "../../Constants/constants";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner/LoadingSpinner";
import { setVOIPEnable } from "../../Redux/Slices/PlanDetailsSlice";
import InactivateAccountBanner from "../../components/shared/InactiveAccountBanner/InactiveAccountBanner";
import { Theme } from "../../Styles/GlobalTheme";
import Button from "../../components/Button/Button";
import { autopayPaperlessDetails } from "../../Redux/Slices/AutoPayPaperlessSlice";
import { ConfirmationModel } from "../../components/shared/ConfirmationModel/ConfirmationModel";
import { ErrorPopup } from "../Account/Components/OrderUpgrades/ErrorPopup";
import { getFlags, splitMessageBybraces } from "../../Services/helper";
import ImportantUpdatesBanner from "../../components/ImportantUpdatesBanner/ImportantUpdatesBanner";
import ContactSupport from "../../components/shared/ContactSupport/ContactSupport";
import CtfIcon from "../../assets/icons/CTF.svg";

export interface CustomizedButtonProps {
  isActive: boolean;
  onClick: () => void;
}

export const Billing = ({
  getbillingDetails,
  billingLoading,
  customerInfoLoading,
  billingError,
  graphQLError,
  getCustomerContact,
  autopayPaperlessLoading,
  handleCancelAutopay,
  cancelAutopayLoading,
  dialogData,
  setTurnOff,
  turnOff,
  showAutoPayError,
  setShowAutoPayError,
  upgradeBannerData,
  showCTFBanner,
  handleOrderUpgradeRoute,
}: any) => {
  const dispatch = useDispatch();
  let location = useLocation();
  const { activeAccount } = useSelector((state: any) => state?.customerInfoDetails);
  const { paymentWarning } = useSelector((state: any) => state?.userInfo);
  const { autoPayData } = useSelector((state: any) => state?.autopayPaperlessDetails);
  const customerDetails = useSelector((state: any) => state?.customerInfoDetails);
  const envFlags = useSelector((state: any) => state?.userInfo?.envFlags);
  const INACTIVE_ACCOUNTS = getFlags("inactive_accounts", envFlags);
  const SHOW_UPGRADE_ORDER = getFlags("show_upgradeorder", envFlags);
  const SHOW_DISABLE_AUTOPAY_BANNER = getFlags("show_disableAutoPay_banner", envFlags);
  const GLOBAL_BANNER = getFlags("show_global_banner", envFlags);
  const hideInactiveFeature = INACTIVE_ACCOUNTS ? activeAccount !== false : true;
  const { orderDetails } = useSelector((state: any) => state.customerInfoDetails);
  const { currentDueBalance, showAutopay, paymentsMigrationToNDS, NDSInprogressAccount } =
    useSelector((state: any) => state.customerPlanDetails);
  const { currentBill, showTabs, pastBills, voipBill } = useSelector(
    (state: any) => state.billingInfo
  );

  const { scrollPosition } = useSelector((state: any) => state.pageInfo);
  const customTabNames = [
    "past bills",
    "current bill",
    ...(process.env.REACT_APP_SHOW_VOIP_FEATURE === "TRUE" ? ["voice usage"] : []),
  ];

  const [
    getTransactions,
    { error: transactionsError, loading: transactionsLoading, data: transactionDetails },
  ] = useLazyQuery(GET_TRANSACTIONS, {
    variables: {
      transactionsRequired: 5,
      accountNumber: customerDetails?.accountNumber,
    },
    fetchPolicy: "network-only",
  });
  const [
    getbillStatements,
    { error: billStatementsError, loading: billStatementLoading, data: billStatementsApiData },
  ] = useLazyQuery(GET_BILL_STATEMENTS, {
    variables: {
      billsRequired: 5,
      isCurrentBill: false,
      accountNumber: customerDetails?.accountNumber,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      dispatch(setVoipNonNdsDate(data?.billStatements?.billStatements[0]?.date));
    },
  });
  const [VOIPServiceVerification, { loading: VOIPServiceLoading, data: VOIPServiceData }] =
    useLazyQuery(VOIP_SERVICE_VERIFICATION_BY_ACC_NUM, {
      fetchPolicy: "network-only",
      onCompleted: (data: any) => {
        dispatch(setVOIPEnable(data?.VOIPServiceVerificationByAccountNumber?.VOIPEnabled));
      },
      onError: () => {
        dispatch(setVOIPEnable(false));
      },
    });

  const voipEnabled = VOIPServiceData?.VOIPServiceVerificationByAccountNumber?.VOIPEnabled;

  const transactions = transactionDetails?.transactions;

  const onRefreshBillingDetails = () => {
    getbillingDetails({
      fetchPolicy: "network-only",
    });
  };

  const handleCompareBill = () => {
    getbillStatements({
      variables: {
        billsRequired: 0,
        isCurrentBill: false,
        accountNumber: customerDetails?.accountNumber,
      },
    });
    dispatch(setShowTabs(!showTabs));
    if (currentBill) {
      dispatch(setCurrentBill(!currentBill));
    } else if (pastBills) {
      dispatch(setPastBills(!pastBills));
    } else {
      dispatch(setVoipBill(!voipBill));
    }
    dispatch(setCompareBill(true));
  };

  useEffect(() => {
    if (customerDetails?.accountNumber?.trim()?.length > 0) {
      getTransactions();
      getbillStatements();
    }
    const hideCurrentBill =
      activeAccount === false && (parseInt(currentDueBalance) <= 0 || currentDueBalance === null);

    const billingStates = {
      compareBill: false,
      currentBill: hideCurrentBill ? false : true,
      showTabs: true,
      pastBills: hideCurrentBill ? true : false,
      voipBill: false,
      compareBillTable: false,
    };
    if (billingStates) {
      dispatch(setBillingInfo(billingStates));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDetails?.accountNumber]);
  useEffect(() => {
    if (customerDetails?.accountNumber && customerDetails?.ndsObj?.isNDS !== undefined) {
      VOIPServiceVerification({
        variables: {
          accountNumber: customerDetails.accountNumber,
          isNds: customerDetails.ndsObj.isNDS,
        },
      });
    }
  }, [customerDetails?.accountNumber, customerDetails?.ndsObj?.isNDS]);

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  const refreshCustomerContact = () => {
    getCustomerContact();
  };

  const handleCloseAutopayModel = () => {
    setTurnOff(false);
  };

  const contactSupportText = SHOW_UPGRADE_ORDER ? (
    <>
      <Stack direction={"row"} alignItems={"center"} flexWrap={"wrap"} gap={0.4}>
        <Typography fontSize={"0.8rem"} color={Theme.palette.customcolor.profileTypoGraphyColor}>
          To upgrade to fiber and avoid this fee
        </Typography>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "0.8rem",
            color: Theme.palette.secondary.main,
            textDecoration: "none",
            cursor: "pointer",
          }}
          onClick={handleOrderUpgradeRoute}>
          CLICK HERE.
        </Typography>
      </Stack>
    </>
  ) : (
    <>
      <Stack direction={"row"} gap={0.4} flexWrap={"wrap"}>
        <Typography fontSize={"0.8rem"} color={Theme.palette.customcolor.profileTypoGraphyColor}>
          Call
        </Typography>
        <StyledA sx={{ fontWeight: 600, fontSize: "0.8rem" }} href="tel:1-866-699-4759">
          1-866-MY-ZIPLY
        </StyledA>
        <Typography fontSize={"0.8rem"} color={Theme.palette.customcolor.profileTypoGraphyColor}>
          or
        </Typography>
        <ContactSupport fontSize={"0.8rem"} text="CHAT WITH AN AGENT" fontWeight={600} />
        <Typography fontSize={"0.8rem"} color={Theme.palette.customcolor.profileTypoGraphyColor}>
          to upgrade today.
        </Typography>
      </Stack>
    </>
  );
  const cftBannerMessage = splitMessageBybraces(upgradeBannerData?.billingPageMessage);
  const paymentWarningMessage = {
    paymentMessage: paymentWarning?.message,
  };
  const upgradeBannerContent = {
    title: cftBannerMessage[0],
    description: cftBannerMessage[1],
    chatSupportText: contactSupportText,
    icon: <img src={CtfIcon} alt="ctf Icon" style={{ width: "48px", padding: "4px" }} />,
  };

  const autopayMessge = splitMessageBybraces(paymentWarning?.autopayMessage);
  const ShowAutopayContent = {
    showAutopay: true,
    title: autopayMessge[0],
    autopayDescription: autopayMessge[1],
    autoPaySubDiscription: autopayMessge[2],
  };

  return (
    <>
      <Box minHeight={"90vh"}>
        <PageGrid container>
          <Grid item xs={12} lg={8.3}>
            {GLOBAL_BANNER &&
              paymentWarning &&
              Object.keys(paymentWarning).length > 0 &&
              paymentWarning?.error &&
              paymentWarning?.allScreens &&
              paymentWarning?.message && <ImportantUpdatesBanner content={paymentWarningMessage} />}
            {!showAutopay &&
              hideInactiveFeature &&
              !customerInfoLoading &&
              !billingLoading &&
              !billingError &&
              customerDetails?.accountNumber && (
                <ImportantUpdatesBanner content={ShowAutopayContent} />
              )}
            {INACTIVE_ACCOUNTS &&
              activeAccount === false &&
              !customerInfoLoading &&
              !billingLoading &&
              !billingError &&
              customerDetails?.linkedAccountNumbers?.length > 0 && <InactivateAccountBanner />}
            {showCTFBanner && hideInactiveFeature && upgradeBannerData?.billingPage && (
              <ImportantUpdatesBanner content={upgradeBannerContent} />
            )}
            {customerDetails && customerDetails?.linkedAccountNumbers?.length > 0 ? (
              <>
                <PageNameTypography pb={"1rem"}>Billing</PageNameTypography>
                <CardHeading>Total balance</CardHeading>
                <TotalBalanceCard
                  getbillingDetails={getbillingDetails}
                  billingError={billingError}
                  billingLoading={billingLoading}
                  onRefresh={onRefreshBillingDetails}
                  home={false}
                  autopayPaperlessLoading={autopayPaperlessLoading}
                />
                {INACTIVE_ACCOUNTS &&
                  SHOW_DISABLE_AUTOPAY_BANNER &&
                  activeAccount === false &&
                  !customerInfoLoading &&
                  autoPayData &&
                  Object.keys(autoPayData)?.length > 0 && (
                    <StyledCard>
                      <Typography variant="h6" fontSize="1.3rem">
                        Disable your Autopay
                      </Typography>
                      <Typography
                        mb={1.5}
                        color={Theme.palette.customcolor.profileTypoGraphyColor}
                        fontSize={{ xs: "14px", sm: "16px" }}>
                        Autopay is currently active on your account. Since this account is inactive,
                        please turn off Autopay to avoid any issues.
                      </Typography>
                      <Box display="flex" justifyContent="center">
                        <Button
                          type="payment"
                          title="DISABLE AUTOPAY"
                          onClick={handleCancelAutopay}
                        />
                      </Box>
                    </StyledCard>
                  )}

                {autopayPaperlessLoading ? (
                  <StyledCard sx={{ paddingY: 10 }}>
                    <Spinner loadingText={AUTOPAY_PAPERLESS_LOADING_MESSAGE} />
                  </StyledCard>
                ) : (
                  hideInactiveFeature &&
                  !(
                    (!paymentsMigrationToNDS && !customerDetails.ndsObj.isNDS) ||
                    (customerDetails.ndsObj.isNDS && NDSInprogressAccount)
                  ) && (
                    <Grid mb={2} container justifyContent={"space-between"} gap={{ xs: 3, sm: 0 }}>
                      <Grid item xs={12} md={5.8}>
                        <AutoPay billingError={billingError} />
                      </Grid>
                      <Grid item xs={12} md={5.8}>
                        <PaperLessBilling billingError={billingError} />
                      </Grid>
                    </Grid>
                  )
                )}

                <CardHeading id="currentBill">Billing information</CardHeading>
                <StyledCard>
                  <CustomTabs
                    billStatementsError={billStatementsError}
                    billStatementLoading={billStatementLoading}
                    getbillStatements={getbillStatements}
                    billStatementsApiData={billStatementsApiData}
                    names={customTabNames}
                    voipEnabled={voipEnabled}
                    VOIPServiceLoading={VOIPServiceLoading}
                  />
                </StyledCard>
                {process.env.REACT_APP_SHOW_VOIP_FEATURE === "TRUE" && hideInactiveFeature && (
                  <>
                    {!voipEnabled && !VOIPServiceLoading && (
                      <StyledCard>
                        <Typography mb={1} fontWeight={600}>
                          Unlock Voice Calls Today!
                        </Typography>
                        <Box fontSize={{ xs: "14px", sm: "16px" }}>
                          Upgrade for seamless voice communication with Ziply's enhanced plan&nbsp;
                          <StyledA href="https://ziplyfiber.com/services/phone" target="_blank">
                            HERE.
                          </StyledA>
                        </Box>
                      </StyledCard>
                    )}
                  </>
                )}

                {showTabs && (
                  <StyledCard>
                    <Typography mb={1} fontWeight={600}>
                      Compare bills
                    </Typography>
                    <Box fontSize={{ xs: "14px", sm: "16px" }}>
                      Did your bill change? Compare past bills&nbsp;
                      <StyledA onClick={handleCompareBill}>HERE.</StyledA>
                    </Box>
                  </StyledCard>
                )}
                <CardHeading>Recent transactions</CardHeading>
                <StyledCard>
                  <CompletedTransaction
                    getTransactions={getTransactions}
                    transactionDetails={transactionDetails}
                    transactions={transactions}
                    transactionsError={transactionsError}
                    transactionsLoading={transactionsLoading}
                  />
                </StyledCard>
              </>
            ) : !customerInfoLoading &&
              customerDetails &&
              customerDetails?.linkedAccountNumbers?.length === 0 &&
              orderDetails &&
              Object.keys(orderDetails).length !== 0 ? (
              <>
                <StyledCard sx={{ py: 20, pb: 22 }}>
                  <LinkingWaitMessage />
                </StyledCard>
              </>
            ) : graphQLError ? (
              <Box my={20}>
                <ErrorComponent onRefresh={refreshCustomerContact} />
              </Box>
            ) : (
              <>
                {!customerInfoLoading &&
                  customerDetails?.linkedAccountNumbers?.length === 0 &&
                  customerDetails?.contactId?.length > 0 && <BlankPage />}
              </>
            )}
          </Grid>
          {customerInfoLoading ? (
            <>
              <Grid
                item
                xs={12}
                lg={3.5}
                display={{ xs: "block", lg: scrollPosition > 20 ? "block" : "none" }}>
                <AccountCard />
                <NeedHelpCard />
              </Grid>
              <StickyBox display={{ xs: "none", lg: scrollPosition > 20 ? "none" : "block" }}>
                <AccountCard />
                <NeedHelpCard />
              </StickyBox>
            </>
          ) : (
            <>
              <Grid
                item
                xs={12}
                lg={3.5}
                display={{ xs: "block", lg: scrollPosition > 800 ? "block" : "none" }}>
                <AccountCard />
                <NeedHelpCard />
              </Grid>
              <StickyBox display={{ xs: "none", lg: scrollPosition > 800 ? "none" : "block" }}>
                <AccountCard />
                <NeedHelpCard />
              </StickyBox>
            </>
          )}
        </PageGrid>
        {(cancelAutopayLoading || VOIPServiceLoading) && <LoadingSpinner />}
      </Box>
      <ErrorPopup
        openError={showAutoPayError}
        setOpenError={setShowAutoPayError}
        component={"billing"}
      />
      <ConfirmationModel
        dialogData={dialogData}
        openDialog={turnOff}
        setOpenDialog={setTurnOff}
        onClick={handleCloseAutopayModel}
      />
      {showAutoPayError && <ErrorComponent />}
    </>
  );
};
