import { Grid, FormControl, Typography, Stack, Box } from "@mui/material";
import {
  AccountDivider,
  AccountStyledCard,
  ErrorMessage,
  ProfileInput,
  ProfileTypography,
  StyleAccordian,
  StyleAccordionSummary,
  StyledElement,
} from "../../../../Styles/GlobalStyles";
import { useEffect, useState } from "react";
import { CustomModal } from "../../../../components/shared/CustomModal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { billingAddressInfoDetails } from "../../../../Redux/Slices/AccountSlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ErrorComponent } from "../../../../components/ErrorComponent/ErrorComponent";
import { useMutation } from "@apollo/client";
import { UPDATE_BILLING_ADDRESS } from "../../query";
import { LoadingSpinner } from "../../../../components/shared/LoadingSpinner/LoadingSpinner";
import { UpdateErrorComponent } from "../../../../components/shared/UpdateErrorComponent/UpdateErrorComponent";
import Button from "../../../../components/Button/Button";
import { LOADING_BILLING_ADDRESSINFO_MESSAGE, PANEL_1 } from "../../../../Constants/constants";
import {
  setIframeURL,
  setIsRequestIdStored,
  setRequestIdForGetUrl,
  setStoreRequestID,
} from "../../../../Redux/Slices/PaymentSlice";
import { Spinner } from "../../../../components/shared/LoadingSpinner/Spinner";

interface BillingPrefInputFieldsProps {
  label: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export type CancelButton = {
  handleEdit?: () => void;
};
export const AccountBillingPreference = ({
  onRefresh,
  billingAddress,
  billingPreferencesInfo,
  billingPreferencesError,
  billingPreferencesLoading,
  inactive_accounts,
}: any) => {
  const customerDetails = useSelector((state: any) => state.customerInfoDetails);

  const dispatch = useDispatch();
  const [addPayment, setAddPayment] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [expanded, setExpanded] = useState<string | false>(false);
  const { activeAccount } = useSelector((state: any) => state?.customerInfoDetails);
  const { showAutopay } = useSelector((state: any) => state.customerPlanDetails);
  const hideInactiveFeature = inactive_accounts ? activeAccount !== false : true;

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
    addPayment && setAddPayment(false);
    dispatch(setIframeURL(""));
  };

  const [updateBillingAddress, { loading: updateAddressLoading }] =
    useMutation(UPDATE_BILLING_ADDRESS);

  const [formData, setFormData] = useState<any>({});
  const [formErrors, setFormErrors] = useState<any>({});

  const handleEdit = () => {
    setEdit(!edit);
  };

  const handleAddressLine1 = (value: any, name: any) => {
    setFormData({ ...formData, addressLine1: value });
  };

  const handleAddressLine2 = (value: any) => {
    setFormData({ ...formData, addressLine2: value });
  };

  const handleCity = (value: any) => {
    setFormData({ ...formData, cityName: value });
  };

  const handleState = (value: any) => {
    setFormData({ ...formData, stateAbbreviation: value });
  };

  const handleZip = (value: any) => {
    setFormData({ ...formData, zipCode: value });
  };

  const billingPrefInputFields: BillingPrefInputFieldsProps[] = [
    {
      label: "Address line 1",
      value: formData?.addressLine1,
      onChange: (e: any) => handleAddressLine1(e.target.value, "adressLine1"),
    },
    {
      label: "Address line 2",
      value: formData?.addressLine2,
      onChange: (e: any) => handleAddressLine2(e.target.value),
    },

    {
      label: "City",
      value: formData?.cityName,
      onChange: (e: any) => handleCity(e.target.value),
    },
    {
      label: "State",
      value: formData?.stateAbbreviation,
      onChange: (e: any) => handleState(e.target.value),
    },
    {
      label: "Zip code",
      value: formData?.zipCode,
      onChange: (e: any) => handleZip(e.target.value),
    },
  ];

  const handleUpdate = async () => {
    const response = await updateBillingAddress({
      variables: {
        accountNumber: customerDetails?.accountNumber,
        billingAddressDetails: {
          addressLine1: formData?.addressLine1,
          addressLine2: formData?.addressLine2,
          addressLine3: formData?.addressLine3,
          cityName: formData?.cityName,
          stateAbbreviation: formData?.stateAbbreviation,
          zipCode: formData?.zipCode,
          agentDetails: { agentId: "193999", loginId: "s_cxpapi" },
        },
      },
    });
    const jsonData = JSON.parse(response?.data?.updateBillingAddress?.message);
    const message = jsonData.content?.response?.messgae?.description?.operationStatusDescription;
    if (response?.data?.updateBillingAddress?.error === true) {
      setOpenError(true);
      setErrorMessage(message);
    } else {
      dispatch(billingAddressInfoDetails(formData));
      setEdit(false);
    }
  };

  useEffect(() => {
    setFormData(billingAddress);
  }, [billingAddress]);
  useEffect(() => {
    dispatch(setIsRequestIdStored(false));
    dispatch(setStoreRequestID(""));
    dispatch(setRequestIdForGetUrl(""));
  }, []);

  return (
    <>
      <AccountStyledCard sx={{ textDecoration: "none" }}>
        {billingPreferencesInfo?.billingPreferences?.error || billingPreferencesError ? (
          <ErrorComponent onRefresh={onRefresh} />
        ) : (
          <Stack padding={"1rem 1.2rem"}>
            {billingPreferencesLoading ? (
              <Box py={12}>
                <Spinner loadingText={LOADING_BILLING_ADDRESSINFO_MESSAGE} />
              </Box>
            ) : (
              <>
                <Stack direction="row" justifyContent={"space-between"}>
                  <Typography component={"span"} fontWeight={"600"}>
                    Billing address
                  </Typography>
                  {/* will uncomment this codeonce edit billing address api works */}
                  {/* {!edit && (
                <StyledElement fontSize={"0.8rem"} onClick={handleEdit}>
                  EDIT
                </StyledElement>
              )} */}
                </Stack>
                <Grid
                  container
                  direction="row"
                  justifyContent={edit ? "space-between" : "flex-start"}>
                  {billingPrefInputFields?.map(({ label, value, onChange }) => {
                    return (
                      <Grid item xs={12} sm={edit ? 5.6 : 3.8} my={1} key={label}>
                        <FormControl fullWidth>
                          <ProfileTypography fontSize={{ xs: "0.8rem", sm: "1rem" }}>
                            {label}
                          </ProfileTypography>
                          {edit ? (
                            <>
                              <ProfileInput value={value} onChange={onChange} />
                              <ErrorMessage error={value ? true : false}>
                                {formErrors?.email}
                              </ErrorMessage>
                            </>
                          ) : (
                            <Typography fontSize={{ xs: "0.8rem", sm: "1rem" }} component={"span"}>
                              {value === null ? "-" : value}
                            </Typography>
                          )}
                        </FormControl>
                      </Grid>
                    );
                  })}
                </Grid>
                {edit && (
                  <Stack direction={"row"} width={{ xs: "100%", sm: "50%" }} columnGap={4} my={2}>
                    <Button onClick={handleUpdate} type="root" title="save" />
                    <Button onClick={handleEdit} type="cancel" title="cancel" />
                  </Stack>
                )}
              </>
            )}
          </Stack>
        )}
        {hideInactiveFeature && showAutopay && (
          <>
            <AccountDivider />
            <StyleAccordian
              disableGutters
              elevation={0}
              expanded={expanded === PANEL_1}
              onChange={handleChange(PANEL_1)}>
              <StyleAccordionSummary
                expandIcon={
                  <StyledElement>
                    <ExpandMoreIcon />
                  </StyledElement>
                }>
                <StyledElement fontSize={{ xs: "0.8rem", sm: "1rem" }}>
                  MANAGE PAYMENT METHODS
                </StyledElement>
              </StyleAccordionSummary>
              <CustomModal addPayment={addPayment} setAddPayment={setAddPayment} />
            </StyleAccordian>
          </>
        )}
      </AccountStyledCard>
      {openError && (
        <UpdateErrorComponent
          openError={openError}
          message={errorMessage}
          setOpenError={setOpenError}
        />
      )}
      {updateAddressLoading && <LoadingSpinner />}
    </>
  );
};

export interface ElementProps {
  textcolor?: string;
}
