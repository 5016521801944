import React from "react";
import { Stack, Divider } from "@mui/material";
import {
  StyledTroubleTicketOrder,
  StyledTroubleTicketStatus,
  StyledTroubleTicketStatusContainer,
  StyledTroubleTicketTypography,
  TicketHistoryCardContainer,
} from "../../../../../Styles/GlobalStyles";
import { formatDateString } from "../../../../../Services/helper";

export default function ServiceTicketHistoryList({
  data,
  showDivider = true,
}: {
  data: any;
  showDivider?: boolean;
}) {
  const createdDate = formatDateString(data?.createdDate, "MM/DD/yyyy");
  const completedDate = formatDateString(data?.completionDate, "MM/DD/yyyy");

  const capitalizeFirstLetter = (str: string) => {
    if (str.length > 0) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return "";
  };

  const formattedType = capitalizeFirstLetter(data?.type);

  return (
    <TicketHistoryCardContainer key={data?.id}>
      <Stack width={"100%"}>
        <StyledTroubleTicketStatusContainer direction={"row"}>
          <Stack sx={{ width: { xs: "50%" } }}>
            <StyledTroubleTicketOrder>
              {formattedType}&nbsp;no:&nbsp;{data?.id}
            </StyledTroubleTicketOrder>
            {data?.createdDate && (
              <StyledTroubleTicketTypography>
                Created Date: {createdDate}
              </StyledTroubleTicketTypography>
            )}
          </Stack>
          <Stack>
            <StyledTroubleTicketStatus color={data?.colorCode?.toLowerCase()} fontWeight="bold">
              {data?.status}
            </StyledTroubleTicketStatus>
            {data?.completionDate && (
              <StyledTroubleTicketTypography>
                Completed Date: {completedDate}
              </StyledTroubleTicketTypography>
            )}
          </Stack>
        </StyledTroubleTicketStatusContainer>
        {showDivider && <Divider />}
      </Stack>
    </TicketHistoryCardContainer>
  );
}
