import { Box, Stack, Typography, Grid, FormControlLabel } from "@mui/material";
import { useState, useEffect } from "react";
import { NotificationBox } from "./Components/NotificationBox";
import { AccountCard } from "../../components/shared/AccountCard/AccountCard";
import {
  AccountStyledCard,
  Android12Switch,
  PageGrid,
  PageNameTypography,
  StickyBox,
  StyledCard,
} from "../../Styles/GlobalStyles";
import { NeedHelpCard } from "../../components/shared/NeedHelpCard/NeedHelpCard";
import { BlankPage } from "../../components/BlankPage/BlankPage";
import { useSelector } from "react-redux";
import { ErrorComponent } from "../../components/ErrorComponent/ErrorComponent";
import { IN_APP_NOTIFICATIONS } from "../Account/query";
import { useLazyQuery } from "@apollo/client";
import { EmptyDataComponent } from "../../components/shared/EmptyDataComponent/EmptyDataComponent";
import { EMPTY_ALERTS_MESSAGE, LOADING_MESSAGES_TEXT } from "../../Constants/constants";
import { Spinner } from "../../components/shared/LoadingSpinner/Spinner";
import { Theme } from "../../Styles/GlobalTheme";
import { LinkingWaitMessage } from "../../components/shared/LinkingWaitMessage/LinkingWaitMessage";

export const Alert = ({ customerInfoLoading, graphQLError, getCustomerContact }: any) => {
  const customerDetails = useSelector((state: any) => state.customerInfoDetails);
  const { scrollPosition } = useSelector((state: any) => state.pageInfo);
  const [unread, setUnread] = useState<boolean>(false);
  const [notificationsError, setNotificationsError] = useState<boolean>(false);

  const notificationsFailure = (error: any) => {
    error && setNotificationsError(true);
  };
  const orderDetails = useSelector((state: any) => state.customerInfoDetails.orderDetails);

  const [inAppNotifications, { loading: notificationsLoading, data: notificationsData }] =
    useLazyQuery(IN_APP_NOTIFICATIONS, {
      variables: {
        accountNumber: customerDetails?.accountNumber,
        contactId: customerDetails?.contactId,
        isRead: !unread,
      },
      fetchPolicy: "network-only",
      onCompleted: (notificationsData) =>
        setNotificationsError(notificationsData?.inAppNotifications?.error),
      onError: (error: any) => notificationsFailure(error),
    });

  const handleSwitch = () => {
    setUnread(!unread);
  };

  const InAppNotifications = notificationsData?.inAppNotifications?.inAppNotifications;

  const EMPTY = notificationsData?.inAppNotifications?.inAppNotifications?.length === 0;

  const ERROR = notificationsData?.inAppNotifications?.error;

  const onRefresh = () => {
    inAppNotifications({ fetchPolicy: "network-only" });
  };

  const refreshCustomerContact = () => {
    getCustomerContact();
  };

  useEffect(() => {
    customerDetails?.accountNumber?.trim().length > 0 && inAppNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDetails?.accountNumber]);
  return (
    <>
      <Box>
        <PageGrid container>
          <Grid item xs={12} lg={8.3}>
            <PageNameTypography variant="subtitle1">Notifications</PageNameTypography>
            <Box
              sx={{
                display: customerDetails?.linkedAccountNumbers?.length > 0 ? "block" : "none",
              }}>
              <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
                <FormControlLabel
                  control={
                    <Android12Switch
                      color="secondary"
                      disableRipple
                      checked={unread}
                      onChange={handleSwitch}
                    />
                  }
                  label={
                    <Typography
                      fontSize={"12px"}
                      variant="body2"
                      color={
                        unread ? "secondary" : Theme.palette.customcolor.profileTypoGraphyColor
                      }>
                      UNREAD ONLY
                    </Typography>
                  }
                />
              </Stack>
            </Box>
            {notificationsLoading ? (
              <Box my={24} height={"50vh"}>
                <Spinner loadingText={LOADING_MESSAGES_TEXT} />
              </Box>
            ) : notificationsError || ERROR ? (
              <AccountStyledCard
                sx={{
                  height: { lg: "70vh", xs: "40vh" },
                  display: "flex",
                  justifyContent: "center",
                }}>
                <ErrorComponent onRefresh={onRefresh} />
              </AccountStyledCard>
            ) : customerDetails?.linkedAccountNumbers?.length > 0 && EMPTY ? (
              <AccountStyledCard
                sx={{
                  height: { lg: "70vh", xs: "20vh" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}>
                <EmptyDataComponent message={EMPTY_ALERTS_MESSAGE} />
              </AccountStyledCard>
            ) : customerDetails?.linkedAccountNumbers?.length > 0 && !notificationsLoading ? (
              <Box minHeight={{ lg: "100vh" }} overflow={"auto"}>
                {InAppNotifications?.map((item: any) => {
                  return <NotificationBox unread={unread} item={item} />;
                })}
              </Box>
            ) : customerDetails?.linkedAccountNumbers?.length === 0 &&
              !notificationsLoading &&
              !customerInfoLoading ? (
              orderDetails && Object.keys(orderDetails).length !== 0 ? (
                <>
                  <StyledCard sx={{ py: 20, pb: 22 }}>
                    <LinkingWaitMessage />
                  </StyledCard>
                </>
              ) : graphQLError ? (
                <Box my={20}>
                  <ErrorComponent onRefresh={refreshCustomerContact} />
                </Box>
              ) : (
                <>
                  <BlankPage />
                </>
              )
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            lg={3.5}
            display={{ xs: "block", lg: scrollPosition > 100 ? "block" : "none" }}>
            <AccountCard />
            <NeedHelpCard />
          </Grid>
        </PageGrid>
        {customerInfoLoading ? (
          <StickyBox display={{ xs: "none", lg: scrollPosition === 0 ? "none" : "block" }}>
            <AccountCard />
            <NeedHelpCard />
          </StickyBox>
        ) : (
          <StickyBox display={{ xs: "none", lg: scrollPosition > 100 ? "none" : "block" }}>
            <AccountCard />
            <NeedHelpCard />
          </StickyBox>
        )}
      </Box>
    </>
  );
};
