import { useEffect, useState } from "react";
import PaymentType from "../PaymentType/PaymentType";
import { useDispatch, useSelector } from "react-redux";
import { Theme } from "../../../../Styles/GlobalTheme";
import PaymentIframe from "../MakeAPayment/PaymentIframe";
import Button from "../../../../components/Button/Button";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_PAYMENT_METHOD_URL } from "../../../Payment/query";
import {
  setApiFailureName,
  setCbstAction,
  setError,
  setIframeURL,
  setPaymentMethodsError,
  setRequestIdForGetUrl,
} from "../../../../Redux/Slices/PaymentSlice";
import { Box, MenuItem, Select, Stack, Typography } from "@mui/material";
import { editAutoPayEnable } from "../../../../Redux/Slices/EditAutoPaySlice";
import { convertToNegative, getFlags, paymentModifiedDate } from "../../../../Services/helper";
import { AUTOPAY_PAPERLESS_DETAILS, GET_PAYMENT_METHODS, SET_AUTOPAY } from "../../query";
import { LoadingSpinner } from "../../../../components/shared/LoadingSpinner/LoadingSpinner";
import { UpdateErrorComponent } from "../../../../components/shared/UpdateErrorComponent/UpdateErrorComponent";
import {
  CANCEL_PAYMENT,
  EXISTING,
  GET_PAYMENT_METHODS_CBST_ACTION,
  LANGUAGE,
  MY_ACCOUNT,
  NOT_FOUND,
  PAYMENT_METHODS_SECURITY_NOTICE,
  REPORT_SERVICE_ISSUE_ERROR,
  SET_AUTOPAY_CBST_ACTION,
  TOKENIZED_BANK,
  TOKENIZED_CREDIT,
  UPGRADE_ORDER_AUTOPAY,
  UUID_ERROR_MSG_ADD_PAYMENT,
  currentDate,
  dueDays,
  isAuthenticated,
} from "../../../../Constants/constants";
import {
  AutoPayDivider,
  SecurityNoticeCard,
  StyledFormControl,
} from "../../../../Styles/GlobalStyles";
import {
  setAutopayFailure,
  setShowNextinAutoPay,
} from "../../../../Redux/Slices/OrderUpgradeSlice";
import ReportIssue from "../../../../components/shared/ReportIssue/ReportIssue";
import { ErrorComponent } from "../../../../components/ErrorComponent/ErrorComponent";
import { LinkingWaitMessage } from "../../../../components/shared/LinkingWaitMessage/LinkingWaitMessage";
import { LegacyPaymentErrorPopup } from "../../../../components/shared/UpdateErrorComponent/LegacyPaymentErrorPopup";

export const PaymentMethod = (props: any) => {
  const dispatch = useDispatch();
  const {
    setSave,
    handleSaveChanges,
    handleConfirm,
    setManageData,
    manageData,
    orderUpgrade,
    handleSubmitOrder,
  } = props;
  const { email } = useSelector((state: any) => state.userInfo);
  const { iframeURL, requestId, requestIdForGetUrl, paymentMethodsError } = useSelector(
    (state: any) => state.paymentDetails
  );
  const customerDetails = useSelector((state: any) => state.customerInfoDetails);
  const { autoPayData } = useSelector((state: any) => state?.autopayPaperlessDetails);
  const { paymentsMigrationToNDS } = useSelector((state: any) => state.customerPlanDetails);
  const [openError, setOpenError] = useState<boolean>(false);
  const [legacyError, setLegacyError] = useState<boolean>(false);
  const [paymentInput, setPaymentInput] = useState<string>("");
  const [failSafeError, setFailSafeError] = useState<boolean>(false);
  const [buttonId, setButtonId] = useState<string>("");
  const envFlags = useSelector((state: any) => state?.userInfo?.envFlags);
  const SHOW_REPORT_ISSUE = getFlags("report_service_issue", envFlags);
  const [openReportIssue, setOpenReportIssue] = useState(false);
  const [paymentMethodsErrorFlag, setPaymentMethodsErrorFlag] = useState<boolean>(false);
  const { givenNameFamilyName } = useSelector((state: any) => state.customerInfoDetails);
  const isNDS = customerDetails?.ndsObj?.isNDS || paymentsMigrationToNDS;
  const handleReportIssueError = () => {
    SHOW_REPORT_ISSUE ? setOpenReportIssue(true) : setOpenError(true);
  };

  const handleOnCompleted = (data: any) => {
    setOpenError(data?.paymentMethods?.error);
    setPaymentMethodsErrorFlag(false);
    dispatch(setRequestIdForGetUrl(requestId));
    !isNDS && setLegacyError(true);
  };
  const handleOnError = (error: any) => {
    if (error.networkError.result.errors[0]?.message?.toLowerCase()?.includes(NOT_FOUND)) {
      dispatch(setPaymentMethodsError(true));
    } else {
      dispatch(setPaymentMethodsError(false));
      setPaymentMethodsErrorFlag(true);
      dispatch(setError(error?.networkError?.result?.errors?.[0]));
      dispatch(setApiFailureName("getPaymentMethods"));
      dispatch(setCbstAction(GET_PAYMENT_METHODS_CBST_ACTION));
      dispatch(setRequestIdForGetUrl(requestId));
    }
  };

  const [getPaymentMethods, { loading: paymentMethodsLoading, data: PaymentMethodsData }] =
    useLazyQuery(GET_PAYMENT_METHODS, {
      variables: {
        accountNumber: customerDetails?.accountNumber,
      },
      fetchPolicy: "network-only",
      onCompleted: handleOnCompleted,
      onError: handleOnError,
    });

  const paymentMethods = PaymentMethodsData?.paymentMethods?.paymentDetails;
  const [setAutoPay, { loading: setAutopayLoading }] = useMutation(SET_AUTOPAY, {
    refetchQueries: [AUTOPAY_PAPERLESS_DETAILS],
    onError: (error: any) => {
      if (orderUpgrade) {
        handleSubmitOrder();
        dispatch(setAutopayFailure(true));
      } else {
        dispatch(setError(error.networkError.result.errors[0]));
        dispatch(setApiFailureName("setAutoPay"));
        dispatch(setCbstAction(SET_AUTOPAY_CBST_ACTION));
        handleReportIssueError();
      }
    },
    onCompleted: () => {
      orderUpgrade && dispatch(setAutopayFailure(false));
    },
  });
  const [getPaymentMethodURL, { loading: urlLoading }] = useMutation(GET_PAYMENT_METHOD_URL, {
    variables: {
      accountNumber: customerDetails?.accountNumber,
      addPaymentMethodInput: {
        paymentType: paymentInput,
        billingName: `${givenNameFamilyName?.givenName ?? ""} ${
          givenNameFamilyName?.familyName ?? ""
        }`,
        email: email,
        language: LANGUAGE,
        cancelURL: `${window.location.href}?status=failure`,
        successURL: `${window.location.href}?status=success`,
        date: paymentModifiedDate(currentDate),
        completePayment: false,
        isNds: isNDS,
      },
    },
    fetchPolicy: "network-only",
    onCompleted: (URLData) => {
      dispatch(setIframeURL(URLData?.getAddPaymentMethodURL?.url));
      setOpenError(URLData?.getAddPaymentMethodURL?.error);
    },
    onError: (error: any) => {
      dispatch(setError(error?.networkError?.result?.errors?.[0]));
      dispatch(setApiFailureName("getPaymentMethodURL"));
      handleReportIssueError();
    },
  });

  const handleReset = () => {
    setManageData(autoPayData);
    dispatch(setIframeURL(""));
    dispatch(editAutoPayEnable(false));
  };

  const handleFilterPaymentMethod =
    paymentMethods && paymentMethods?.filter((method: any) => method?.id === buttonId);

  const filteredPaymentMethod = handleFilterPaymentMethod?.[0];

  const handleChange = (event: any) => {
    setManageData({
      ...filteredPaymentMethod,
      scheduleDayBefore: convertToNegative(event.target.value).toString(),
    });
  };

  const setAutopayObjExisting = {
    paymentMethodId: manageData?.id,
    scheduleDayBefore: manageData?.scheduleDayBefore ?? "-1",
    paymentMethodType: manageData?.paymentMethodType,
    enableAutoPay: true,
    paymentToken: manageData?.token,
    isNds: customerDetails?.ndsObj?.isNDS,
    uid: null,
  };

  const handleSetAutopay = async (data?: any, uid?: any) => {
    const setAutopayObjForNew = {
      paymentMethodId: !customerDetails?.ndsObj?.isNDS ? data?.content?.uid : null,
      scheduleDayBefore: manageData?.scheduleDayBefore ?? "-1",
      paymentMethodType: paymentInput,
      enableAutoPay: true,
      paymentToken: null,
      isNds: customerDetails?.ndsObj?.isNDS,
      uid: customerDetails?.ndsObj?.isNDS || paymentsMigrationToNDS ? uid : null,
    };
    try {
      const response = await setAutoPay({
        variables: {
          autoPayInput: paymentInput === EXISTING ? setAutopayObjExisting : setAutopayObjForNew,
          accountNumber: customerDetails?.accountNumber,
        },
      });
      if (response?.data?.setAutoPay?.error) {
        handleReportIssueError();
        dispatch(setIframeURL(""));
      } else if (response?.data) {
        if (!response?.data?.setAutoPay?.error) {
          setSave(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleFailure = (data: any) => {
    dispatch(setIframeURL(""));
  };

  const handleSave = async () => {
    if (buttonId === "new card" || buttonId === "new bank") {
      if (!isNDS) {
        setLegacyError(true);
      } else {
        if (isNDS !== undefined) getPaymentMethodURL();
      }
    } else {
      handleSetAutopay();
    }
  };

  const handleSuccess = (data: any, uid: any) => {
    dispatch(setIframeURL(""));
    if (autoPayData && Object.keys(autoPayData)?.length > 0) {
      handleConfirm(data, uid);
    } else {
      handleSetAutopay(data, uid);
    }
  };

  const accountNumber = customerDetails && customerDetails?.accountNumber;
  const hyphenSeperatedAccNum = accountNumber.replace(
    /^(\d{3})(\d{3})(\d{4})(\d{6})(\d)$/,
    "$1-$2-$3-$4-$5"
  );

  useEffect(() => {
    if (buttonId === "new card") {
      setPaymentInput(TOKENIZED_CREDIT);
    } else if (buttonId === "new bank") {
      setPaymentInput(TOKENIZED_BANK);
    } else {
      setPaymentInput(EXISTING);
      if (autoPayData && Object.keys(autoPayData)?.length > 0) {
        setManageData({
          ...filteredPaymentMethod,
          scheduleDayBefore: convertToNegative(autoPayData?.scheduleDayBefore)?.toString(),
        });
      } else {
        setManageData({ ...filteredPaymentMethod, scheduleDayBefore: "-1" });
      }
    }
  }, [autoPayData, buttonId, filteredPaymentMethod]);

  useEffect(() => {
    isAuthenticated === "FALSE" && customerDetails?.accountNumber && getPaymentMethods();
    dispatch(setIframeURL(""));
  }, [customerDetails.accountNumber, getPaymentMethods]);

  useEffect(() => {
    dispatch(setRequestIdForGetUrl(""));
    if (orderUpgrade) {
      dispatch(setShowNextinAutoPay(true));
    }
  }, []);
  const handleRefresh = () => {
    getPaymentMethods({ fetchPolicy: "network-only" });
  };

  return (
    <>
      {paymentMethodsError ? (
        <Box py={10}>
          <LinkingWaitMessage message={UUID_ERROR_MSG_ADD_PAYMENT} />
        </Box>
      ) : (
        <>
          {orderUpgrade && (
            <Stack mb={3}>
              <Typography
                my={1}
                component={"span"}
                fontSize={"18px"}
                color={Theme.palette.primary.main}
                fontWeight={600}>
                Set-Up Autopay
              </Typography>
              <Typography variant="body1" component={"span"} data-testid="account-number">
                {UPGRADE_ORDER_AUTOPAY}
              </Typography>
            </Stack>
          )}
          <Stack mt={1.6}>
            <Typography component={"span"} variant="subtitle2">
              Account number
            </Typography>

            <Typography variant="body1" component={"span"} data-testid="account-number">
              {hyphenSeperatedAccNum}
            </Typography>
          </Stack>
          <AutoPayDivider />
          <Stack mt={4} justifyContent={"center"}>
            <Typography
              color={isAuthenticated === "TRUE" ? Theme.palette.secondary.main : "inherit"}
              fontWeight={isAuthenticated === "TRUE" ? 600 : 0}
              variant="subtitle2">
              Payment method
            </Typography>
            {paymentMethodsErrorFlag && !(iframeURL?.length > 0) && (
              <ErrorComponent
                onRefresh={handleRefresh}
                ShowReportIssue={SHOW_REPORT_ISSUE}
                errorMessage={REPORT_SERVICE_ISSUE_ERROR}
                autopay={true}
              />
            )}
            {isAuthenticated === "TRUE" && (
              <SecurityNoticeCard>{PAYMENT_METHODS_SECURITY_NOTICE}</SecurityNoticeCard>
            )}
            <Stack>
              <PaymentType
                buttonId={buttonId}
                setButtonId={setButtonId}
                paymentMethods={paymentMethods}
                paymentMethodsLoading={paymentMethodsLoading}
                paymentMethodsError={paymentMethodsErrorFlag}
              />
              {iframeURL?.length > 0 && (
                <PaymentIframe
                  handleFailure={handleFailure}
                  handleSuccess={handleSuccess}
                  src={iframeURL}
                />
              )}
            </Stack>
            {iframeURL?.length == 0 && (
              <Stack justifyContent={"center"} my={4} spacing={2}>
                <Typography component={"span"} variant="subtitle2">
                  How many days before the due date should the payment be applied?
                </Typography>
                <StyledFormControl>
                  <Select
                    data-testid="select"
                    defaultValue={
                      manageData?.scheduleDayBefore
                        ? convertToNegative(manageData?.scheduleDayBefore)
                        : -1
                    }
                    onChange={handleChange}>
                    {dueDays?.map((item: any) => (
                      <MenuItem data-testid="dropdown-value" value={item?.id} key={item?.id}>
                        {item?.value}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
                <Stack>
                  <Typography component={"span"} variant="body2">
                    Amount
                  </Typography>
                  <Typography component={"p"} variant="body2">
                    Total amount due for billing cycle
                  </Typography>
                </Stack>
              </Stack>
            )}

            {!iframeURL && autoPayData && Object.keys(autoPayData)?.length > 0 ? (
              <Stack direction={"row"} spacing={3} pb={4}>
                <Button
                  eventName="MANAGE_AUTOPAY_SAVE_CHANGES"
                  onClick={() => handleSaveChanges(manageData, getPaymentMethodURL, paymentInput)}
                  title={paymentInput !== EXISTING ? "Continue" : "save changes"}
                  type="root"
                />
                <Button onClick={handleReset} title="cancel" type="root" />
              </Stack>
            ) : (
              !iframeURL && (
                <Box>
                  <Button
                    eventName="SETUP_AUTOPAY_CONTINUE"
                    onClick={handleSave}
                    title="Continue"
                    type="root"
                  />
                </Box>
              )
            )}
          </Stack>
        </>
      )}
      {(paymentMethodsLoading || setAutopayLoading || urlLoading) && <LoadingSpinner />}
      {failSafeError && (
        <>
          <UpdateErrorComponent
            refreshButton={true}
            openError={failSafeError}
            setOpenError={setFailSafeError}
          />
        </>
      )}
      <ReportIssue setOpenDialog={setOpenReportIssue} openDialog={openReportIssue} />
      <UpdateErrorComponent openError={openError} setOpenError={setOpenError} />
      <LegacyPaymentErrorPopup
        openError={legacyError}
        setOpenError={setLegacyError}
        message={"We are unable to register you for AutoPay."}
      />
    </>
  );
};
