import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import i18nnext from "./i18n";
import { I18nextProvider } from "react-i18next";
import { store } from "./Redux/Store/Store";
import { Provider } from "react-redux";
import AdminApp from "./AdminApp";
import { Helmet, HelmetProvider } from "react-helmet-async";
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const isAdmin = process.env.REACT_APP_CXP_ADMIN_ACCESS_ENABLED;

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <title>My Account ZiplyFiber</title>
      </Helmet>
      <BrowserRouter basename={isAdmin === "TRUE" ? "/lac" : "/myaccount"}>
        <Provider store={store}>
          <I18nextProvider i18n={i18nnext}>
            {isAdmin === "TRUE" ? <AdminApp /> : <App />}
          </I18nextProvider>
        </Provider>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);

var adobeScript = document.createElement("script");
adobeScript.src = process.env.REACT_APP_ADOBE_SCRIPT || "";
adobeScript.type = "text/javascript";
adobeScript.async = true;

var chasePaymentScript = document.createElement("script");
chasePaymentScript.src = process.env.REACT_APP_CHASE_SCRIPT || "";
chasePaymentScript.type = "text/javascript";
chasePaymentScript.async = true;

document.body.appendChild(adobeScript);
document.body.appendChild(chasePaymentScript);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (process.env.REACT_APP_ENV === "production") {
  console.log = () => {};
  console.debug = () => {};
}
