import { useLazyQuery } from "@apollo/client";
import { GET_ENV_VALUES } from "../../Routes/query";
import { setEnvFlags } from "../../Redux/Slices/UserSlice";
import { useDispatch } from "react-redux";

const useEnvApiCalls = () => {
  const dispatch = useDispatch();

  const [getEnvValues, { loading: getEnvValuesLoading }] = useLazyQuery(GET_ENV_VALUES, {
    variables: {
      platform: process.env.REACT_APP_ENV_PLATFORM,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const variables = data?.getEnvValuesFromFirestore?.envVariables;
      dispatch(setEnvFlags(variables));
    },
  });

  return { getEnvValues, getEnvValuesLoading };
};

export default useEnvApiCalls;
