import { Stack, Typography, IconButton, Box } from "@mui/material";
import { CardHeading, SwitchDialog } from "../../../Styles/GlobalStyles";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../Button/Button";
import { useNavigate } from "react-router-dom";
import { editAutoPayEnable, saveAutoPayChanges } from "../../../Redux/Slices/EditAutoPaySlice";
import { useDispatch, useSelector } from "react-redux";
import {
  setCompareBill,
  setCurrentBill,
  setShowTabs,
} from "../../../Redux/Slices/BillingInfoSlice";
import {
  setAnotherAmount,
  setIframeURL,
  setPaymentMethodsError,
  setSelectedMethod,
} from "../../../Redux/Slices/PaymentSlice";
import { setErrorFlag } from "../../../Redux/Slices/AutoPayPaperlessSlice";
import { setOpenSwitchModal } from "../../../Redux/Slices/AppProposalSlice";
import { setExistingTicket, setOpenOutageBanner } from "../../../Redux/Slices/TroubleTicketSlice";
import {
  setCheckRouterTypeError,
  setNetworkConnectionDetails,
  setRGStatusApiError,
  setRGStatusDetails,
  setTestConnectionApiError,
  setTestDetailsNull,
} from "../../../Redux/Slices/NetworkStatusSlice";

export const SwitchAccountCard = (props: any) => {
  const navigate = useNavigate();
  const { card, setOpenDialog, openDialog, selectAccount, setTotalBalance, totalBalance } = props;
  const handleClose = () => {
    setOpenDialog(false);
  };
  const dispatch = useDispatch();
  const { openSwitchModal } = useSelector((state: any) => state?.appProposal);

  const hyphenSeperatedAccNum = card?.accountNumber?.replace(
    /^(\d{3})(\d{3})(\d{4})(\d{6})(\d)$/,
    "$1-$2-$3-$4-$5"
  );

  const handleSwitch = () => {
    if (
      window.location.pathname === "/myaccount/billing/signupautopay" ||
      window.location.pathname === "/myaccount/billing/manageautopay" ||
      window.location.pathname === "/myaccount/payment/failed" ||
      window.location.pathname === "/myaccount/payment/success"
    ) {
      navigate("/billing");
    } else if (
      window.location.pathname === "/myaccount/account/signupautopay" ||
      window.location.pathname === "/myaccount/account/manageautopay" ||
      window.location.pathname === "/myaccount/account/upgrade-speed"
    ) {
      navigate("/account");
    }
    selectAccount(card);
    setOpenDialog(false);
    if (openSwitchModal) {
      dispatch(setOpenSwitchModal(false));
    }
    if (totalBalance === "newpayment") {
      setTotalBalance("payment");
    }
    localStorage.removeItem("linkedAccountNumber");
    dispatch(editAutoPayEnable(false));
    dispatch(saveAutoPayChanges(false));
    dispatch(setShowTabs(false));
    dispatch(setCurrentBill(false));
    dispatch(setCompareBill(false));
    dispatch(setIframeURL(""));
    dispatch(setSelectedMethod(""));
    dispatch(setAnotherAmount(0));
    dispatch(setErrorFlag(false));
    dispatch(setPaymentMethodsError(false));
    dispatch(setOpenOutageBanner(false));
    dispatch(setExistingTicket(false));
    dispatch(setNetworkConnectionDetails({}));
    dispatch(setRGStatusDetails({}));
    dispatch(setTestDetailsNull(false));
    dispatch(setRGStatusApiError(false));
    dispatch(setTestConnectionApiError(false));
    dispatch(setCheckRouterTypeError(false));
  };
  return (
    <SwitchDialog open={openDialog}>
      <IconButton onClick={handleClose} sx={{ alignSelf: "end" }}>
        <CloseIcon />
      </IconButton>
      <Box pb={4} px={2}>
        <Stack gap={1} textAlign={"center"} alignItems={"center"}>
          <CardHeading>View account</CardHeading>
          <Typography component={"p"} fontSize={{ xs: "0.8rem", sm: "1rem" }}>
            Are you sure you want to view this account?
          </Typography>
        </Stack>
        <Typography
          fontSize={{ xs: "0.8rem", sm: "1.2rem" }}
          textAlign={"center"}
          component={"p"}
          fontWeight={600}>
          {hyphenSeperatedAccNum} ?
        </Typography>
        <Stack
          my={2}
          gap={{ sm: 3, xs: 1 }}
          justifyContent={"center"}
          alignItems={"center"}
          direction={"row"}>
          <Button onClick={handleSwitch} type="payment" title="YES" />
        </Stack>
      </Box>
    </SwitchDialog>
  );
};
