import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import {
  Android12Switch,
  AutoPayDivider,
  RowCenterStack,
  SettingsBox,
} from "../../../../Styles/GlobalStyles";
import { ConfirmationModel } from "../../../../components/shared/ConfirmationModel/ConfirmationModel";

export const ToggleAlerts = (props: any) => {
  const { type, alertsData, handleUpdateNotificationPreference } = props;
  const [emailDialog, setEmailDialog] = useState(false);
  const [messagesDialog, setMessagesDialog] = useState(false);
  const [dialogData, setDialogData] = useState({
    primaryContent: <></>,
    containedButton: { buttonType: "", buttonTitle: "", eventName: "" },
  });

  const dialogContent = (
    <Typography>
      Please note that it will take up to 3 weeks to <br /> reflect the changes on your account.
      <br /> Please confirm to proceed.
    </Typography>
  );

  const handleEmails = () => {
    setEmailDialog(true);
    setDialogData({
      ...dialogData,
      primaryContent: dialogContent,
      containedButton: {
        ...dialogData?.containedButton,
        buttonType: "payment",
        buttonTitle: "CONFIRM",
        eventName: "UPDATE_NOTIFICATION",
      },
    });
  };

  const handleTextMessages = () => {
    setMessagesDialog(true);
    setDialogData({
      ...dialogData,
      primaryContent: dialogContent,
      containedButton: {
        ...dialogData?.containedButton,
        buttonType: "payment",
        buttonTitle: "CONFIRM",
        eventName: "UPDATE_NOTIFICATION",
      },
    });
  };

  return (
    <>
      <Stack>
        <Typography component={"span"} fontWeight={600}>
          {type}
        </Typography>
        <Typography component={"span"} variant="caption">
          News, upgrades and specials
        </Typography>
      </Stack>
      <SettingsBox my={2}>
        <RowCenterStack my={2}>
          <Stack>
            <Typography component={"p"} fontSize={"14px"}>
              Send me emails
            </Typography>
            <Typography data-testid="email-status" fontSize={"12px"}>
              {alertsData?.sendMeEmails ? "TURNED ON" : "TURNED OFF"}
            </Typography>
          </Stack>
          <Android12Switch
            data-testid="emails"
            color="secondary"
            disableRipple
            checked={alertsData?.sendMeEmails}
            onClick={handleEmails}
          />
        </RowCenterStack>
        <AutoPayDivider />
        <RowCenterStack my={2}>
          <Stack>
            <Typography component={"p"} fontSize={"14px"}>
              Send me text messages
            </Typography>
            <Typography component={"span"} fontSize={"12px"} data-testid="sms-status">
              {alertsData?.sendMeTextMessages ? "TURNED ON" : "TURNED OFF"}
            </Typography>
          </Stack>
          <Android12Switch
            data-testid="sms"
            color="secondary"
            disableRipple
            checked={alertsData?.sendMeTextMessages}
            onClick={handleTextMessages}
          />
        </RowCenterStack>
      </SettingsBox>
      {emailDialog && (
        <ConfirmationModel
          openDialog={emailDialog}
          setOpenDialog={setEmailDialog}
          dialogData={dialogData}
          setDialogData={setDialogData}
          onClick={() => handleUpdateNotificationPreference("sendMeEmails", alertsData)}
        />
      )}
      {messagesDialog && (
        <ConfirmationModel
          openDialog={messagesDialog}
          setOpenDialog={setMessagesDialog}
          dialogData={dialogData}
          setDialogData={setDialogData}
          onClick={() => handleUpdateNotificationPreference("sendMeTextMessages", alertsData)}
        />
      )}
    </>
  );
};
