import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { GET_COMMERCIAL_ACCOUNT } from "../../Pages/Account/query";
import {
  setBusinessAccountDetails,
  setCommercialAccApiError,
} from "../../Redux/Slices/CustomerInfoSlice";
import { isAuthenticated } from "../../Constants/constants";

export const useGetCommercialAccount = () => {
  const impersonatedEmail = localStorage.getItem("impersonatedEmail");
  const { email } = useSelector((state: any) => state.userInfo);
  const { contactId } = useSelector((state: any) => state.customerInfoDetails);
  const dispatch = useDispatch();

  const [getCommercialAccount, { loading: commercialAccountLoading }] = useLazyQuery(
    GET_COMMERCIAL_ACCOUNT,
    {
      variables: {
        email: (isAuthenticated === "TRUE" ? impersonatedEmail : email) ?? "",
        contactId: contactId,
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        dispatch(setBusinessAccountDetails(data?.getCommercialAccountNumberByContactId));
        dispatch(setCommercialAccApiError(false));
      },
      onError: () => dispatch(setCommercialAccApiError(true)),
    }
  );

  return {
    getCommercialAccount,
    commercialAccountLoading,
  };
};
