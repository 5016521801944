import { Typography } from "@mui/material";
import { NotificationDate, StyledNotification } from "../../../Styles/GlobalStyles";
import { Theme } from "../../../Styles/GlobalTheme";
import moment from "moment";

export const NotificationBox = ({ item }: any) => {
  return (
    <>
      <>
        <StyledNotification
          sx={{
            background: !item?.isRead
              ? Theme.palette.customcolor.notificationunread
              : Theme.palette.customcolor.notificationread,
          }}>
          <Typography variant="subtitle2" data-testid="body">
            {item?.body}
          </Typography>
          <NotificationDate data-testid="date">
            {moment().startOf(item?.createdAt).fromNow()}
          </NotificationDate>
        </StyledNotification>
      </>
    </>
  );
};
