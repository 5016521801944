import React from "react";
import { ColumnCenterStack, SpacerSmall } from "../../../Styles/GlobalStyles";
import { Box, Typography } from "@mui/material";
import LinkAccountIcon from "../../../assets/icons/Icon Link account Web.svg";

const DefaultMessage =
  "After signing up for a service, it may take a few days to link your billing account to your profile.";

export const LinkingWaitMessage = ({ message = DefaultMessage }: any) => {
  return (
    <>
      <ColumnCenterStack>
        <Box>
          <img src={LinkAccountIcon} alt="linkacc" />
        </Box>
        <Typography sx={{ textAlign: "center" }} fontWeight={600} variant="h6">
          {message}
        </Typography>
        <SpacerSmall />
        <Typography variant="h6">Thank you for your patience!</Typography>
      </ColumnCenterStack>
    </>
  );
};
