import { Stack, Typography, Grid, Divider } from "@mui/material";
import { Theme } from "../../../Styles/GlobalTheme";
import { FooterLinks, footerIcons } from "../../../Constants/constants";
import {
  FooterLink,
  FooterTypography,
  ImgSmall,
  StyledA,
  ZiplyLogo,
} from "../../../Styles/GlobalStyles";
import ZiplyLogoIcon from "../../../assets/images/ziply-logo.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { logEvents } from "../../../Services/helper";

export const Footer = () => {
  const userInfo = localStorage.getItem("userInfo");
  const navigate = useNavigate();

  const isLoggedIn = userInfo && JSON.parse(userInfo);
  const handleRoutingToHome = () => {
    if (window.location.pathname !== "/myaccount/login") {
      navigate("/home");
    } else {
      window.location.href = "https://ziplyfiber.com";
    }
  };

  const handleLogger = (name: string) => () => {
    if (name) {
      logEvents(`${name}_clicked`);
    }
  };

  const myAccountFooterPublicLinks = !isLoggedIn?.email
    ? [
        {
          name: isLoggedIn?.email ? "" : "Login",
          path: "https://ziplyfiber.com/myaccount/login",
        },
        {
          name: isLoggedIn?.email ? "" : "Register",
          path: "https://ziplyfiber.com/myaccount/register",
        },
      ]
    : [];
  const footerHyperlinks = [
    {
      heading: "My Account",
      list: [
        ...myAccountFooterPublicLinks,
        { name: "Ziply Fiber App", path: "https://ziplyfiber.com/my-ziply-app" },
      ],
    },
    {
      heading: "Help Center",
      list: [
        {
          name: "Support",
          path: "https://ziplyfiber.com/helpcenter",
        },
        {
          name: "Moving",
          path: "https://ziplyfiber.com/moving-with-ziply",
        },
      ],
    },
    {
      heading: "Company",
      list: [
        {
          name: "Contact Us",
          path: "https://ziplyfiber.com/contact",
        },
        {
          name: "News",
          path: "https://ziplyfiber.com/news",
        },
        {
          name: "Careers",
          path: "https://ziplyfiber.com/careers",
        },
        {
          name: "About Us",
          path: "https://ziplyfiber.com/about-us",
        },
        {
          name: "Blog",
          path: "https://ziplyfiber.com/blogs",
        },
      ],
    },
    {
      heading: "Segments",
      list: [
        {
          name: "Residential",
          path: "https://ziplyfiber.com/",
        },
        {
          name: "Small Business",
          path: "https://ziplyfiber.com/smallbusiness",
        },
        {
          name: "Enterprise",
          path: "https://enterprise.ziplyfiber.com/",
        },
        {
          name: "Wholesale",
          path: "https://ziplyfiber.com/wholesale",
        },
        { name: "Commercial Agents", path: "https://enterprise.ziplyfiber.com/commercial-agents" },
      ],
    },
  ];

  const desktop = useMediaQuery("(min-width:1200px)");
  let images = footerIcons.map((item) => (
    <StyledA href={item.href} target="_blank" onClick={handleLogger(item.name)}>
      <ImgSmall src={require(`../../../assets/icons/${item.name}.svg`)} alt="icons" />
    </StyledA>
  ));
  return (
    <>
      <Stack
        px={{ xs: "0.5rem", md: 0 }}
        bottom={0}
        width={"100%"}
        justifyContent={"center"}
        py={{ sm: "0.4rem", xs: "0.8rem", lg: "1.5rem" }}
        bgcolor={Theme.palette.customcolor.accordionborder}>
        <Stack
          px={{ xs: 2, md: 12 }}
          pt={{ md: 2, lg: 0 }}
          direction={{ xs: "column", lg: "row" }}
          justifyContent={"center"}
          gap={desktop ? 6 : 0}
          flexWrap={"wrap"}>
          <Stack alignItems={"flex-start"} width={{ xs: "100%", md: "30%" }}>
            <StyledA target="_self" onClick={handleRoutingToHome}>
              <ZiplyLogo height={"60px"} src={ZiplyLogoIcon} alt="ziply-logo" />
            </StyledA>
            <Typography display={{ xs: "none", lg: "block" }}>Lets Stay Connected</Typography>
            <Stack direction={"row"} py={2} spacing={2} display={{ xs: "none", lg: "block" }}>
              {images}
            </Stack>
          </Stack>
          <>
            {footerHyperlinks?.map((item, index) => {
              return (
                <>
                  <Stack display={{ xs: "block", lg: "none" }}>
                    <Accordion elevation={0}>
                      <AccordionSummary
                        sx={{
                          backgroundColor: Theme.palette.customcolor.accordionborder,
                          borderBottom: "1px solid black",
                        }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography variant="subtitle1" fontWeight={600}>
                          {item.heading}
                        </Typography>
                      </AccordionSummary>
                      <>
                        {item.list.map((list) => {
                          return (
                            <Stack
                              pl={2}
                              sx={{ backgroundColor: Theme.palette.customcolor.accordionborder }}>
                              <StyledA
                                href={list.path}
                                target="_blank"
                                onClick={handleLogger(list.name)}
                                style={{ color: "black", padding: "0.2rem 0" }}>
                                {list.name}
                              </StyledA>
                            </Stack>
                          );
                        })}
                      </>
                    </Accordion>
                  </Stack>
                  <Stack display={{ xs: "none", lg: "block" }}>
                    <Stack direction={"row"} justifyContent={"flex-start"} my={2}>
                      <Typography variant="subtitle1" fontWeight={600}>
                        {item.heading}
                      </Typography>
                    </Stack>
                    {item.list.map((list) => {
                      return (
                        <Stack justifyContent={"center"} alignItems={"flex-start"}>
                          <StyledA
                            href={list.path}
                            target="_blank"
                            onClick={handleLogger(list.name)}
                            style={{ color: "black", padding: "0.2rem 0" }}>
                            {list.name}
                          </StyledA>
                        </Stack>
                      );
                    })}
                  </Stack>
                  {index === footerHyperlinks.length - 2 && (
                    <Divider
                      sx={{
                        border: `1px solid ${Theme.palette.primary.main}`,
                        display: { xs: "none", lg: "block" },
                      }}
                      orientation="vertical"
                    />
                  )}
                </>
              );
            })}
          </>
          <Stack
            direction={"row"}
            p={2}
            my={2}
            spacing={{ xs: 1.2, md: 4 }}
            display={{ xs: "block", lg: "none" }}>
            {images}
          </Stack>
        </Stack>
        <Divider
          sx={{
            border: `1px solid ${Theme.palette.primary.main}`,
            my: { md: 2 },
          }}
        />

        <Grid container alignItems={"flex-start"} width={{ md: "83%", xl: "82%" }} margin={"auto"}>
          <Grid item sm={3} lg={2} alignSelf={"center"}></Grid>
          <Grid item mt={2} sm={12} lg={8}>
            <Stack spacing={4} alignItems={"center"}>
              <Stack direction={{ sm: "row", xs: "column" }} alignItems="center" gap={4}>
                {FooterLinks?.map((data) => (
                  <FooterTypography key={data?.value}>
                    <FooterLink
                      href={data?.path}
                      target="_blank"
                      onClick={handleLogger(data.value)}>
                      {data?.value}
                    </FooterLink>
                  </FooterTypography>
                ))}
              </Stack>
              <FooterTypography pt={1}>
                &copy;{new Date().getFullYear()} Ziply Fiber. Ziply&reg; is trademark of northwest
                fiber, LLC. All rights reserved
              </FooterTypography>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};
