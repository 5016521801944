import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { Box, Card, useMediaQuery } from "@mui/material";
import { LoginHeader } from "../../components/shared/Login/LoginHeader";
import { LOG_IN, SIGN_IN, CONNECT, CREATE, VERIFY } from "../../Constants/constants";
import { LoginFooter } from "../../components/shared/Login/LoginFooter";
import { CardHeading, CenterStack, ColumnCenterStack } from "../../Styles/GlobalStyles";
import { CreateAccountForm } from "./CreateAccountForm/CreateAccountForm";
import { Footer } from "../../components/shared/Footer/Footer";
import Button from "../../components/Button/Button";
import { SEO } from "../../components/shared/SEO/SEO";

export const label = { inputProps: { "aria-label": "Checkbox demo" } };
export const SignIn = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [idCreated, setIdCreated] = useState<boolean>(false);
  const mobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const nextStep = () => {
    setActiveStep((currentStep) => currentStep + 1);
    window.scrollTo(0, 0);
  };
  const prevStep = () => {
    if (activeStep === 0) {
      setActiveStep(0);
    } else setActiveStep((currentStep) => currentStep - 1);
  };

  const handleSignIn = () => {
    navigate(LOG_IN);
  };
  return (
    <Fragment>
      <SEO
        title="Create your Online Login for Ziply Fiber Account Management | Ziply Fiber"
        description="Please create an online login for your Ziply Fiber service & billing account. This allows you to access your online account to view and pay bills, troubleshoot, and manage your profile."
      />
      <LoginHeader type={SIGN_IN} />
      <Box position="relative" minHeight={"80vh"}>
        <CenterStack direction={"row"} width={{ xs: "100%" }} py={6}>
          <Box>
            <Stack direction={"column"} justifyContent={"center"} alignItems={"center"}>
              {idCreated ? (
                <Card
                  sx={{ marginY: 20, width: { md: "40%", sm: "60%" } }}
                  elevation={mobile ? 0 : 1}>
                  <ColumnCenterStack direction={"column"} p={4} spacing={2}>
                    <CardHeading>Ziply Profile Created</CardHeading>
                    <Typography
                      component={"span"}
                      fontWeight={600}
                      fontSize={{ xs: "12px", sm: "16px" }}
                      textAlign={"center"}
                      data-testid="id-created">
                      Please login with your new credentials to access all of your accounts and
                      account services or edit your information.
                    </Typography>
                    <Button type="payment" title="Log in" onClick={handleSignIn} />
                  </ColumnCenterStack>
                </Card>
              ) : (
                <Card sx={{ width: { xs: 280, sm: 400, md: 750 } }} elevation={mobile ? 0 : 1}>
                  <Box overflow={"hidden"} alignSelf={"center"}>
                    <CreateAccountForm
                      type={activeStep === 1 ? VERIFY : activeStep === 2 ? CONNECT : CREATE}
                      nextStep={nextStep}
                      prevStep={prevStep}
                      setIdCreated={setIdCreated}
                      activeStep={activeStep}
                      setActiveStep={setActiveStep}
                    />
                  </Box>
                </Card>
              )}
              <Box pt={{ sm: 4 }}>
                <LoginFooter />
              </Box>
            </Stack>
          </Box>
        </CenterStack>
      </Box>

      <Footer />
    </Fragment>
  );
};

export interface StepperProps {
  type?: string;
  activeStep?: number;
  nextStep?: any;
  prevStep?: any;
  setIdCreated?: any;
  utTest?: boolean;
  setActiveStep?: any;
}
