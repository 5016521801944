import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  troubleTicketDetails: {},
  createdDate: "",
  appointment: {},
  troubleTicketApiError: false,
  openServiceTicketDialog: false,
  openSelectReasons: false,
  openOutageBanner: false,
  existingTicket: false,
  openRunTest: false,
  openPopup: false,
  openSelectdates: false,
  reasonsForTT: {},
  detailsForTTAvailableSlots: {},
  createServiceTicketFail: false,
  createServiceTicketSuccess: false,
  reasonsForTTError: false,
  getTTDetailsError: false,
};

const troubleTicketSlice = createSlice({
  name: "troubleTicketSlice",
  initialState,
  reducers: {
    setTroubleTicketDetails: (state, action) => {
      state.troubleTicketDetails = action.payload;
    },
    setCreatedDate: (state, action) => {
      state.createdDate = action.payload;
    },
    setAppointment: (state, action) => {
      state.appointment = action.payload;
    },
    setTroubleTicketApiError: (state, action) => {
      state.troubleTicketApiError = action.payload;
    },
    setOpenServiceTicketDialog: (state, action) => {
      state.openServiceTicketDialog = action.payload;
    },
    setOpenSelectReasons: (state, action) => {
      state.openSelectReasons = action.payload;
    },
    setOpenOutageBanner: (state, action) => {
      state.openOutageBanner = action.payload;
    },
    setExistingTicket: (state, action) => {
      state.existingTicket = action.payload;
    },
    setOpenRunTest: (state, action) => {
      state.openRunTest = action.payload;
    },
    setOpenPopup: (state, action) => {
      state.openPopup = action.payload;
    },
    setOpenSelectdates: (state, action) => {
      state.openSelectdates = action.payload;
    },
    setReasonsForTT: (state, action) => {
      state.reasonsForTT = action.payload;
    },
    setdetailsForTTAvailableSlots: (state, action) => {
      state.detailsForTTAvailableSlots = action.payload;
    },
    setCreateServiceTicketFail: (state, action) => {
      state.createServiceTicketFail = action.payload;
    },
    setCreateServiceTicketSuccess: (state, action) => {
      state.createServiceTicketSuccess = action.payload;
    },
    setReasonsForTTError: (state, action) => {
      state.reasonsForTTError = action.payload;
    },
    setTTdetailsError: (state, action) => {
      state.getTTDetailsError = action.payload;
    },
  },
});

export const {
  setTroubleTicketDetails,
  setCreatedDate,
  setAppointment,
  setTroubleTicketApiError,
  setOpenSelectReasons,
  setOpenServiceTicketDialog,
  setOpenOutageBanner,
  setExistingTicket,
  setOpenRunTest,
  setOpenPopup,
  setOpenSelectdates,
  setdetailsForTTAvailableSlots,
  setReasonsForTT,
  setCreateServiceTicketFail,
  setCreateServiceTicketSuccess,
  setReasonsForTTError,
  setTTdetailsError,
} = troubleTicketSlice.actions;
export const troubleTicketReducer = troubleTicketSlice.reducer;
