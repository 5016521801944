import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  billDueDate: "",
  currentDueBalance: "",
  ziplyPlanName: "",
  error: false,
  APIStatus: 0,
  billingAddress: "",
  paymentsMigrationToNDS: false,
  VOIPEnabled: false,
  VOIPServicePeriodId: "",
  VOIPDetails: [],
  month: "",
  VOIPTotalAmount: "",
  VOIPTotalEvents: "",
  isNewCustomer: false,
  paymentConfirmationMessage: "",
  VOIPEnable: false,
  serviceAddress: "",
  autopayAmount: "",
  paperlessAmount: "",
  showAutopay: false,
  NDSInprogressAccount: false,
};

const customerPlanDetailsSlice = createSlice({
  name: "customerPlanDetails",
  initialState,
  reducers: {
    customerPlanDetails: (state, action) => {
      state.billDueDate = action.payload.billDueDate;
      state.currentDueBalance = action.payload.currentDueBalance;
      state.ziplyPlanName = action.payload.ziplyPlanName;
      state.error = action.payload.error;
      state.APIStatus = action.payload.APIStatus;
      state.billingAddress = action.payload.billingAddress;
      state.paymentsMigrationToNDS = action.payload.paymentsMigrationToNDS;
      state.VOIPEnabled = action.payload.VOIPEnabled;
      state.isNewCustomer = action.payload.isNewCustomer;
      state.serviceAddress = action.payload.serviceAddress;
      state.autopayAmount = action.payload.autopayAmount;
      state.paperlessAmount = action.payload.paperlessAmount;
      state.NDSInprogressAccount = action.payload.NDSInprogressAccount;
    },
    setVOIPServicePeriodId: (state, action) => {
      state.VOIPServicePeriodId = action.payload;
    },
    setVOIPDetails: (state, action) => {
      state.VOIPDetails = action.payload;
    },
    setMonth: (state, action) => {
      state.month = action.payload;
    },
    setVoipTotalAmount: (state, action) => {
      state.VOIPTotalAmount = action.payload;
    },
    setVOIPTotalEvents: (state, action) => {
      state.VOIPTotalEvents = action.payload;
    },
    setPaymentConfirmationMessage: (state, action) => {
      state.paymentConfirmationMessage = action.payload;
    },
    setVOIPEnable: (state, action) => {
      state.VOIPEnable = action.payload;
    },
    setShowAutopay: (state, action) => {
      state.showAutopay = action.payload;
    },
  },
});

export const {
  customerPlanDetails,
  setVOIPServicePeriodId,
  setVOIPDetails,
  setMonth,
  setVoipTotalAmount,
  setVOIPTotalEvents,
  setPaymentConfirmationMessage,
  setVOIPEnable,
  setShowAutopay,
} = customerPlanDetailsSlice.actions;
export const customerPlanDetailsReducer = customerPlanDetailsSlice.reducer;
