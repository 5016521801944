import { useEffect, useState } from "react";
import { Typography, Box, Stack, styled, useTheme, useMediaQuery } from "@mui/material";
import { getDollarSign } from "../../../../../Services/helper";
import {
  ColumnCenterStack,
  LinkTypography,
  PTypography,
  SpacerSmall,
  StyledDivider,
} from "../../../../../Styles/GlobalStyles";
import {
  FETCHING_TRANSACTION_HISTORY_MESSAGE,
  TRANSACTIONS_EMPTYDATA_MESSAGE,
} from "../../../../../Constants/constants";
import { useSelector } from "react-redux";
import { ErrorComponent } from "../../../../../components/ErrorComponent/ErrorComponent";
import { EmptyDataComponent } from "../../../../../components/shared/EmptyDataComponent/EmptyDataComponent";
import { Spinner } from "../../../../../components/shared/LoadingSpinner/Spinner";
import moment from "moment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Theme } from "../../../../../Styles/GlobalTheme";
import Tooltip from "@mui/material/Tooltip";

export interface TransactionProps {
  adjustmentType?: string;
  adjustmentAmount?: any;
  adjustmentDate?: string;
  cardNumber?: string;
  index?: any;
  transactionsError?: any;
  adjustmentDescription?: string;
  colorCode?: string;
  adjustmentColorCode?: string;
  transactionDescription?: string;
  referenceNumber?: string;
}

export const CompletedTransaction = (props: any) => {
  const {
    transactions,
    transactionDetails,
    getTransactions,
    transactionsError,
    transactionsLoading,
  } = props;

  const customerDetails = useSelector((state: any) => state.customerInfoDetails);
  const [showAll, setShowAll] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [copiedIndex, setCopiedIndex] = useState<number | null>(null);
  const handleCopy = (referenceNumber?: string, index?: number) => {
    if (!referenceNumber || index === undefined) return;
    navigator.clipboard.writeText(referenceNumber).then(() => {
      setCopiedIndex(index);
      setTimeout(() => setCopiedIndex(null), 1000);
    });
  };
  const handleRefresh = () => {
    getTransactions();
  };

  const handleShowLess = () => {
    getTransactions({
      variables: {
        transactionsRequired: 5,
        accountNumber: customerDetails?.accountNumber,
      },
    });
    setShowAll(false);
  };

  const handleTransactions = () => {
    getTransactions({
      variables: {
        transactionsRequired: null,
        accountNumber: customerDetails?.accountNumber,
      },
    });
    setShowAll(true);
  };

  const EMPTY =
    transactionDetails?.transactions?.transactions === null ||
    transactionDetails?.transactions?.transactions?.length === 0;

  useEffect(() => {
    setShowAll(false);
  }, [customerDetails?.accountNumber]);
  return (
    <>
      {transactionsLoading ? (
        <Box my={20}>
          <Spinner loadingText={FETCHING_TRANSACTION_HISTORY_MESSAGE} />
        </Box>
      ) : transactions?.error || transactionsError ? (
        <ErrorComponent onRefresh={handleRefresh} />
      ) : EMPTY ? (
        <Box
          my={10}
          height={transactions?.transactions?.length > 5 ? 285 : "auto"}
          overflow={showAll ? "auto" : "hidden"}>
          <EmptyDataComponent message={TRANSACTIONS_EMPTYDATA_MESSAGE} />
        </Box>
      ) : (
        <>
          <Box>
            {transactions?.transactions?.map(
              (
                {
                  adjustmentType,
                  adjustmentAmount,
                  adjustmentDescription,
                  adjustmentDate,
                  index,
                  colorCode,
                  adjustmentColorCode,
                  transactionDescription,
                  referenceNumber,
                }: TransactionProps,
                lastChild: number
              ) => {
                const isLastChild = lastChild === transactions?.transactions.length - 1;

                return (
                  <Box key={index}>
                    <SpacerSmall />
                    <Stack justifyContent={"space-between"} direction={"row"}>
                      <Stack sx={{ width: { xs: "auto", sm: "100px" } }}>
                        <PTypography data-testid="adjustmentType" color={adjustmentColorCode}>
                          {adjustmentType}
                        </PTypography>
                        {transactionDescription && (
                          <Typography fontSize={10} data-testid="date">
                            {transactionDescription}
                          </Typography>
                        )}
                        <Typography component={"span"} fontSize={10} data-testid="date">
                          {moment(adjustmentDate).format("MMMM D, YYYY")}
                        </Typography>
                        {referenceNumber && (
                          <Box sx={{ display: { xs: "flex", sm: "none" } }}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={0.5}
                              onClick={() => handleCopy(referenceNumber, lastChild)}>
                              <Typography component={"span"} fontSize={10} data-testid="date">
                                Reference Number:
                              </Typography>
                              <Typography fontSize={10}>{referenceNumber}</Typography>
                              {isMobile && (
                                <Tooltip
                                  title={copiedIndex === lastChild && "Copied"}
                                  open={copiedIndex === lastChild}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  onClose={() => setCopiedIndex(null)}>
                                  <ContentCopyIcon
                                    sx={{
                                      fontSize: 12,
                                      color: "#007BFF",
                                      cursor: "pointer",
                                      transition: "transform 0.3s",
                                      "&:hover": {
                                        transform: "scale(1.2)",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </Stack>
                          </Box>
                        )}
                      </Stack>
                      {referenceNumber && (
                        <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                          <Stack alignItems="flex-start">
                            <Typography fontSize="12px" color="textSecondary">
                              Reference Number
                            </Typography>
                            <Stack direction="row" alignItems="center" spacing={0.5}>
                              <Typography fontSize={{ xs: "0.8rem", sm: "1rem" }}>
                                {referenceNumber}
                              </Typography>
                              {!isMobile && (
                                <Tooltip
                                  title={copiedIndex === lastChild && "Copied"}
                                  open={copiedIndex === lastChild}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  onClose={() => setCopiedIndex(null)}>
                                  <ContentCopyIcon
                                    sx={{
                                      fontSize: 15,
                                      color: "#007BFF",
                                      cursor: "pointer",
                                      transition: "transform 0.3s",
                                      "&:hover": {
                                        transform: "scale(1.2)",
                                      },
                                    }}
                                    onClick={() => handleCopy(referenceNumber, lastChild)}
                                  />
                                </Tooltip>
                              )}
                            </Stack>
                          </Stack>
                        </Box>
                      )}

                      <Stack alignItems="flex-end" sx={{ minWidth: "100px" }}>
                        <Typography component={"span"} variant="inherit" data-testid="amount">
                          {getDollarSign(adjustmentAmount)}
                        </Typography>
                        <Typography color={colorCode ?? "inherit"} component={"span"} fontSize={10}>
                          {adjustmentDescription}
                        </Typography>
                      </Stack>
                    </Stack>
                    <SpacerSmall />
                    {(showAll || transactions?.transactions.length > 4) === false ? (
                      !isLastChild && <StyledDivider />
                    ) : (
                      <StyledDivider />
                    )}
                  </Box>
                );
              }
            )}
          </Box>

          {!showAll ? (
            <LinkTypography
              mt={2}
              display={
                !transactionsLoading && transactions?.transactions.length > 4 ? "block" : "none"
              }
              onClick={handleTransactions}>
              ALL TRANSACTIONS
            </LinkTypography>
          ) : showAll ? (
            <LinkTypography
              mt={2}
              display={
                !transactionsLoading && transactions?.transactions.length > 4 ? "block" : "none"
              }
              onClick={handleShowLess}>
              SHOW LESS
            </LinkTypography>
          ) : null}
        </>
      )}
    </>
  );
};
const StyledContentCopyIcon = styled(ContentCopyIcon)(({ theme }) => ({
  fontSize: 16,
  color: Theme.palette.customcolor.cardHeading,
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.primary.dark,
  },
}));
