import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Theme } from "../../../Styles/GlobalTheme";

export const EmptyDataComponent = (props: any) => {
  const { message } = props;
  const firstPartLength = 68;

  if (message.length >= firstPartLength) {
    const firstPart = message.substring(0, firstPartLength);
    const secondPart = message.substring(firstPartLength);

    return (
      <Box
        textAlign={"center"}
        data-testid="empty-data-message"
        color={Theme.palette.customcolor.customText}>
        <Typography component={"p"} fontWeight={600} fontSize={{ md: "20px", xs: "16px" }}>
          {firstPart}
        </Typography>
        <Typography component={"p"} fontWeight={600} fontSize={{ md: "20px", xs: "16px" }}>
          {secondPart}
        </Typography>
      </Box>
    );
  } else {
  }
  return (
    <>
      <Stack my={5} direction={"row"} justifyContent={"center"}>
        <Typography
          component={"p"}
          textAlign={"center"}
          data-testid="empty-data-message"
          fontSize={{ sm: "20px", xs: "16px" }}
          fontWeight={600}
          color={Theme.palette.customcolor.customText}>
          {message}
        </Typography>
      </Stack>
    </>
  );
};
