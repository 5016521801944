import { Box } from "@mui/material";
import { RowDataComponent } from "../RowDataComponent/RowDataComponent";
import { ColorBar } from "../../../../../../Styles/GlobalStyles";

export const CurrentBillTable = (props: any) => {
  const { currentBillData } = props;

  const parsedData =
    currentBillData?.currentBillDetails &&
    JSON.parse(currentBillData?.currentBillDetails?.currentBill);

  const rowData =
    parsedData &&
    Object.entries(parsedData).map(([key, value]) => ({
      key,
      title: key,
      content: JSON.stringify(value, null, 2),
    }));

  return (
    <Box my={1.5}>
      <ColorBar />
      {rowData &&
        rowData?.map((item: any, index: number) => {
          return (
            <Box key={index}>
              <RowDataComponent item={item} />
            </Box>
          );
        })}
    </Box>
  );
};
