import "./RunSpeedTest.css";
import backgroundImg from "../../../../assets/images/Clouds.svg";
import routerImg from "../../../../assets/images/router.svg";
import globeImg from "../../../../assets/images/globe.svg";
import pathImg from "../../../../assets/images/path.svg";
import { Box, Stack, Typography } from "@mui/material";
import { Theme } from "../../../../Styles/GlobalTheme";
import { useSelector } from "react-redux";
import cloudsDownImg from "../../../../assets/images/Clouds Down.svg";
import cloudsUpImg from "../../../../assets/images/Clouds Up.svg";
import verticalPath from "../../../../assets/images/Vector 10.svg";

export const RunSpeedTestComponent = ({ speedestLoading }: any) => {
  const { startDownloadSpeed, fadeOut, loadingUpload, startUploadSpeed, loadingDownload } =
    useSelector((state: any) => state.speedTestAnimation);
  const { scrollPosition } = useSelector((state: any) => state.pageInfo);
  const { openSwitchModal } = useSelector((state: any) => state?.appProposal);

  return (
    <>
      <Box display={{ xs: "none", sm: "block" }} position={"relative"}>
        <Box position={"absolute"} width={"100%"}>
          <img
            src={backgroundImg}
            alt="background"
            width={"100%"}
            style={{ objectFit: "cover", height: "auto" }}
          />
        </Box>

        <Box id={fadeOut && "animated-example"} className={fadeOut && "animated fadeOut"}>
          <Stack
            gap={2}
            direction={"row"}
            justifyContent={{ sm: "center", xl: "center" }}
            alignItems={"center"}
            width={"100%"}>
            <img
              height={120}
              src={routerImg}
              alt="router"
              style={{ zIndex: scrollPosition > 250 || openSwitchModal ? 0 : 1 }}
            />
            <img src={pathImg} alt="path" style={{ flex: 1, width: "80%", maxHeight: "16px" }} />
            <img
              src={globeImg}
              alt="globe"
              style={{ zIndex: scrollPosition > 250 || openSwitchModal ? 0 : 1 }}
            />
          </Stack>
          {speedestLoading && startDownloadSpeed && (
            <div
              className={startDownloadSpeed && "dot"}
              style={{
                backgroundColor: Theme.palette.primary.light,
                boxShadow: `0px 0px 10px ${Theme.palette.primary.light}`,
              }}></div>
          )}
          {loadingUpload && startUploadSpeed && (
            <div
              className={startUploadSpeed && "dot-forward"}
              style={{
                backgroundColor: Theme.palette.secondary.light,
                boxShadow: `0px 0px 10px ${Theme.palette.secondary.light}`,
              }}></div>
          )}
        </Box>
      </Box>
      <Stack
        id={fadeOut && "animated-example"}
        className={fadeOut && "animated fadeOut"}
        display={{ xs: "none", sm: "flex" }}
        direction={"row"}
        gap={55}
        mr={{ sm: 4 }}
        justifyContent={"center"}>
        <Typography component={"span"} color={"primary"}>
          Router
        </Typography>
        <Typography component={"span"} color={"secondary"}>
          Internet
        </Typography>
      </Stack>

      <Stack display={{ xs: "block", sm: "none" }} mb={12} position={"relative"}>
        <Stack spacing={38}>
          <Box position={"absolute"} width={"100%"}>
            <img
              src={cloudsUpImg}
              alt="cloudsUp"
              width={"100%"}
              style={{ objectFit: "cover", height: "auto" }}
            />
          </Box>
          <Box position={"relative"} width={"100%"}>
            <img
              src={cloudsDownImg}
              alt="cloudsDown"
              width={"100%"}
              style={{ objectFit: "cover", height: "auto" }}
            />
          </Box>
        </Stack>
        <Stack
          id={fadeOut && "animated-example"}
          className={fadeOut && "animated fadeOut"}
          position={"absolute"}
          top={0}
          width={"100%"}
          alignItems={"center"}
          justifyContent={"space-between"}>
          <img src={globeImg} alt="globe" />
          <Typography textAlign={"center"} color={"secondary"}>
            Internet
          </Typography>
          {loadingDownload && startDownloadSpeed && (
            <div
              className={startDownloadSpeed && "dot-mobile"}
              style={{
                backgroundColor: Theme.palette.primary.light,
                boxShadow: `0px 0px 10px ${Theme.palette.primary.light}`,
              }}></div>
          )}
          {loadingUpload && startUploadSpeed && (
            <div
              className={startUploadSpeed && "dot-mobile-upward"}
              style={{
                backgroundColor: Theme.palette.secondary.light,
                boxShadow: `0px 0px 10px ${Theme.palette.secondary.light}`,
              }}></div>
          )}
          <img src={verticalPath} alt="path" />
          <img src={routerImg} alt="router" />
          <Typography color={"primary"}>Router</Typography>
        </Stack>
      </Stack>
    </>
  );
};
