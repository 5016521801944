import { useState, useEffect } from "react";
import { BlankScreenStack, CardHeading, PageGrid, StickyBox } from "../../../Styles/GlobalStyles";
import { Grid, Stack, Typography, Box } from "@mui/material";
import { AccountCard } from "../AccountCard/AccountCard";
import { NeedHelpCard } from "../NeedHelpCard/NeedHelpCard";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "../../Button/Button";
import { useSelector, useDispatch } from "react-redux";
import { getDollarSign, logEvents, splitMessageBybraces } from "../../../Services/helper";
import { setAnotherAmount } from "../../../Redux/Slices/PaymentSlice";
import ContactSupport from "../ContactSupport/ContactSupport";
import { Theme } from "../../../Styles/GlobalTheme";

export const PaymentFailed = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [paymentStatus, setPaymentStatus] = useState<string>("");
  const { scrollPosition } = useSelector((state: any) => state.pageInfo);
  const { paymentError } = useSelector((state: any) => state.paymentDetails);
  const PaymentErrormessage = splitMessageBybraces(paymentError);
  const { anotherAmount } = useSelector((state: any) => state.paymentDetails);
  const { currentDueBalance } = useSelector((state: any) => state.customerPlanDetails);
  const paymentAmountInPositive = currentDueBalance && !currentDueBalance?.includes("-");

  const handleNavigate = () => {
    navigate("/billing/makeapayment");
  };

  const handleNavigateToBilling = () => {
    navigate("/billing");
    dispatch(setAnotherAmount(0));
  };

  useEffect(() => {
    if (state?.data?.subject) {
      setPaymentStatus(state?.data?.subject);
    } else if (state?.data?.status) {
      setPaymentStatus(state?.data?.status);
    } else {
      setPaymentStatus("");
    }
  }, [state?.data?.subject, state?.data?.status]);

  useEffect(() => {
    paymentStatus?.trim()?.length > 0
      ? logEvents("PAYMENT_CANCELLED")
      : logEvents("PAYMENT_FAILED");
  }, [paymentStatus]);

  return (
    <>
      <PageGrid container spacing={1}>
        <Grid item xs={12} lg={8} mt={{ md: 4 }}>
          <BlankScreenStack justifyContent={"space-between"} height={"65vh"} sx={{ p: 0 }}>
            <Stack mb={4} justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
              <CardHeading data-testid="failure-msg">
                {paymentStatus?.trim()?.length > 0 ? "Payment cancelled" : PaymentErrormessage?.[0]}
              </CardHeading>
              <Typography
                fontSize={{ sm: "0.9rem", md: "1.1rem" }}
                fontWeight={600}
                color={Theme.palette.customcolor.cardHeading}>
                {PaymentErrormessage?.[1]}
              </Typography>
            </Stack>
            <Typography component={"p"} px={{ sm: "6rem", md: "10rem" }} textAlign={"center"}>
              {paymentStatus?.trim()?.length > 0 ? (
                <>
                  <Typography>
                    Your payment has been successfully cancelled. If you have any questions or need
                    further assistance,
                  </Typography>
                  <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} gap={1}>
                    <Typography>Please</Typography>
                    <ContactSupport />
                  </Stack>
                </>
              ) : (
                <>
                  {PaymentErrormessage && (anotherAmount || paymentAmountInPositive) ? (
                    <Typography>{`Your transaction of ${
                      anotherAmount
                        ? `${getDollarSign(anotherAmount)}`
                        : `${getDollarSign(currentDueBalance)}`
                    } was unsuccessful.`}</Typography>
                  ) : (
                    <Typography>
                      Sorry, we're not able to process your payment at the moment, please try again.
                    </Typography>
                  )}
                  {PaymentErrormessage?.[1]?.toLowerCase()?.includes("expired") ? (
                    <Typography>Do you want to add a new payment method?</Typography>
                  ) : PaymentErrormessage?.[1]?.toLowerCase()?.includes("declined") ? (
                    <Typography>
                      If problem persists please contact your financial institution
                    </Typography>
                  ) : PaymentErrormessage?.[2] ? (
                    <Typography>{PaymentErrormessage?.[2]}</Typography>
                  ) : (
                    <Stack
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={1}>
                      <Typography>If the problem persists,</Typography>
                      <Typography>Please</Typography>
                      <Box mt={0.3}>
                        <ContactSupport text="CONTACT US" />
                      </Box>
                    </Stack>
                  )}
                </>
              )}
            </Typography>
            {paymentStatus?.trim()?.length === 0 ? (
              <>
                <Stack direction={"row"} mt={6} gap={2}>
                  <Stack>
                    <Button
                      type="root"
                      title={
                        PaymentErrormessage?.[1]?.toLowerCase()?.includes("expired") ||
                        PaymentErrormessage?.length > 2
                          ? "UPDATE PAYMENT METHOD"
                          : "TRY AGAIN"
                      }
                      onClick={handleNavigate}
                    />
                  </Stack>
                  <Box>
                    <Button
                      onClick={handleNavigateToBilling}
                      title="GO BACK TO BILLING"
                      type="root"
                    />
                  </Box>
                </Stack>
              </>
            ) : (
              <Box mt={3}>
                <Button onClick={handleNavigateToBilling} title="GO BACK TO BILLING" type="root" />
              </Box>
            )}
          </BlankScreenStack>
        </Grid>
        <Grid
          item
          xs={12}
          lg={3.5}
          display={{ xs: "block", lg: scrollPosition > 50 ? "block" : "none" }}>
          <AccountCard />
          <NeedHelpCard />
        </Grid>
        <StickyBox display={{ xs: "none", lg: scrollPosition > 50 ? "none" : "block" }}>
          <AccountCard />
          <NeedHelpCard />
        </StickyBox>
      </PageGrid>
    </>
  );
};
