import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Button from "../../components/Button/Button";
import { Box, Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { setUserError, userInfo } from "../../Redux/Slices/UserSlice";
import { getCleanRedirectURL, validInputSubmit } from "../../Services/helper";
import LoginBox from "../../components/shared/Login/LoginBox";
import { AdminHeader } from "../../components/Header/AdminHeader";
import { CardHeading, CenterStack, CustomTextField, ErrorMessage } from "../../Styles/GlobalStyles";
import { UpdateErrorComponent } from "../../components/shared/UpdateErrorComponent/UpdateErrorComponent";
import { ISAM } from "../Login/query";
import { useMutation } from "@apollo/client";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner/LoadingSpinner";
import { isAuthenticated } from "../../Constants/constants";

export default function AdminScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const [error, setError] = React.useState<Boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [formData, setFormData] = React.useState({
    email: "",
  });
  const [formErrors, setFormErrors] = React.useState<any>({
    email: "",
  });

  const handleEmailInput = (e: any) => {
    setFormData({ ...formData, email: e.target.value });
    setFormErrors({});
  };

  const handleValidation = () => {
    const errors = validInputSubmit(formData);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    }
  };

  const handleProceed = () => {
    const isNotEmpty = Object.values(formErrors).every((value: any) => value === "");
    if (isNotEmpty) {
      handleIsam();
    }
    window.clarity("set", "userEmail", formData.email);
  };

  const [lsam, { loading: lsamError }] = useMutation(ISAM, {
    onError: (error: any) => {
      if (error.networkError.result.errors[0]?.code === "409") {
        dispatch(setUserError(error.networkError.result.errors[0]?.message));
        navigate("/acc-not-found");
      } else {
        const userDetails = {
          email: formData.email,
        };
        localStorage.setItem("impersonatedEmail", userDetails.email);
        if (localStorage.getItem("token")) {
          dispatch(userInfo(userDetails));
          navigate("/checking-account");
        } else {
          navigate("/admin/login");
        }
      }
    },
    onCompleted: () => {
      const userDetails = {
        email: formData.email,
      };
      localStorage.setItem("impersonatedEmail", userDetails.email);
      dispatch(userInfo(userDetails));
      const redirectURL = localStorage.getItem("redirectURL");
      const cleanedRedirectURL = redirectURL && getCleanRedirectURL(redirectURL);

      if (
        cleanedRedirectURL &&
        !cleanedRedirectURL.includes("/adminscreen") &&
        cleanedRedirectURL !== "/null"
      ) {
        navigate(cleanedRedirectURL);
      } else {
        navigate("/checking-account");
      }
    },
  });

  const handleIsam = async () => {
    try {
      await lsam({
        variables: {
          email: formData.email,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeeplinking = () => {
    setFormData({ ...formData, email: query.get("email") || "" });
    navigate("/checking-account");
    localStorage.setItem("impersonatedEmail", query.get("email") || "");
    if (typeof window.clarity === "function") {
      window.clarity("set", "userEmail", query.get("email") ?? "");
    }
  };

  React.useEffect(() => {
    if (query.get("email") !== null) {
      if (isAuthenticated === "TRUE") {
        if (localStorage.getItem("token")) {
          handleDeeplinking();
        } else {
          navigate("/admin/login");
        }
      } else {
        localStorage.setItem("impersonatedEmail", query.get("email") || "");
        navigate("/login");
      }
    }
  }, []);

  useEffect(() => {
    dispatch(userInfo({ email: "" }));
    query.get("email") === null && localStorage.removeItem("impersonatedEmail");
  }, []);

  const loader = lsamError;

  return (
    <>
      <AdminHeader />
      <Box py={9}>
        <CenterStack direction={"column"} width={{ xs: "100%" }}>
          <LoginBox padding={"2rem"}>
            <CardHeading style={{ marginTop: "2rem" }}>Impersonate member</CardHeading>
            <Typography component={"p"} textAlign={"center"} mt={2}>
              Enter email address of the member that you want to impersonate
            </Typography>
            <Stack marginTop={"2rem"} alignContent={"center"} width={"100%"} spacing={0} my={2}>
              <Typography variant="caption" display="block" gutterBottom>
                Enter email address
              </Typography>
              <CustomTextField
                name="email"
                fullWidth
                variant="outlined"
                onChange={handleEmailInput}
                required
                value={formData?.email}
                hasError={formErrors?.email ? true : false}
                onBlur={handleValidation}
                inputProps={{
                  "data-testid": "email",
                }}
              />
              {formErrors?.email && (
                <ErrorMessage error={formErrors?.email ? true : false}>
                  {formErrors?.email}
                </ErrorMessage>
              )}
            </Stack>

            <Stack width={"100%"} justifyContent={"center"} marginY={5} direction={"row"}>
              <Button title="PROCEED" onClick={handleProceed} type={"login"} />
            </Stack>
          </LoginBox>
        </CenterStack>
      </Box>
      {error && (
        <UpdateErrorComponent
          openError={error}
          setOpenError={() => {
            setError(false);
            window.location.reload();
          }}
          message={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}
      {loader === true && <LoadingSpinner />}
    </>
  );
}
