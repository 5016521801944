import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  savePaymentData: { save: false, amount: "" },
  iframeURL: "",
  selectedMethod: "",
  anotherAmount: 0,
  paymentMethodsError: false,
  paymentError: "",
  inactivatePaymentStatus: false,
  ApiFailureName: "",
  requestId: "",
  feedBack: "",
  error: "",
  cbstAction: "",
  storeRequestID: "",
  isRequestIdStored: false,
  requestIdForGetUrl: "",
};

const paymentDetailSlice = createSlice({
  name: "paymentDetails",
  initialState,
  reducers: {
    savePaymentInfo: (state, action) => {
      state.savePaymentData = action.payload;
    },
    setIframeURL: (state, action) => {
      state.iframeURL = action.payload;
    },
    setSelectedMethod: (state, action) => {
      state.selectedMethod = action.payload;
    },
    setAnotherAmount: (state, action) => {
      state.anotherAmount = action.payload;
    },
    setPaymentMethodsError: (state, action) => {
      state.paymentMethodsError = action.payload;
    },
    setPaymentError: (state, action) => {
      state.paymentError = action.payload;
    },
    setInactivatePaymentStatus: (state, action) => {
      state.inactivatePaymentStatus = action.payload;
    },
    setApiFailureName: (state, action) => {
      state.ApiFailureName = action.payload;
    },
    setRequestId: (state, action) => {
      state.requestId = action.payload;
    },
    setErrorFeedback: (state, action) => {
      state.feedBack = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setCbstAction: (state, action) => {
      state.cbstAction = action.payload;
    },
    setStoreRequestID: (state, action) => {
      state.storeRequestID = action.payload;
    },
    setIsRequestIdStored: (state, action) => {
      state.isRequestIdStored = action.payload;
    },
    setRequestIdForGetUrl: (state, action) => {
      state.requestIdForGetUrl = action.payload;
    },
  },
});

export const {
  savePaymentInfo,
  setPaymentMethodsError,
  setIframeURL,
  setSelectedMethod,
  setAnotherAmount,
  setPaymentError,
  setInactivatePaymentStatus,
  setApiFailureName,
  setRequestId,
  setErrorFeedback,
  setError,
  setStoreRequestID,
  setCbstAction,
  setIsRequestIdStored,
  setRequestIdForGetUrl,
} = paymentDetailSlice.actions;
export const paymentDetailReducer = paymentDetailSlice.reducer;
