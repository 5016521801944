import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  compareBill: false,
  currentBill: true,
  showTabs: false,
  pastBills: false,
  voipBill: false,
  compareBillTable: false,
  VoipNonNdsDate: "",
};

const billingInfoSlice = createSlice({
  name: "billingInformation",
  initialState,
  reducers: {
    setBillingInfo: (state, action) => {
      state.compareBill = action.payload.compareBill;
      state.currentBill = action.payload.currentBill;
      state.voipBill = action.payload.voipBill;
      state.pastBills = action.payload.pastBills;
      state.showTabs = action.payload.showTabs;
      state.compareBillTable = action.payload.compareBillTable;
    },
    setCompareBill: (state, action) => {
      state.compareBill = action.payload;
    },
    setCurrentBill: (state, action) => {
      state.currentBill = action.payload;
    },
    setShowTabs: (state, action) => {
      state.showTabs = action.payload;
    },
    setPastBills: (state, action) => {
      state.pastBills = action.payload;
    },
    setCompareBillTable: (state, action) => {
      state.compareBillTable = action.payload;
    },
    setVoipBill: (state, action) => {
      state.voipBill = action.payload;
    },
    setVoipNonNdsDate: (state, action) => {
      state.VoipNonNdsDate = action.payload;
    },
  },
});

export const {
  setCompareBill,
  setCurrentBill,
  setShowTabs,
  setPastBills,
  setCompareBillTable,
  setBillingInfo,
  setVoipBill,
  setVoipNonNdsDate,
} = billingInfoSlice.actions;
export const billingInfoReducer = billingInfoSlice.reducer;
