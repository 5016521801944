import React from "react";
import { ServiceTicketDialogBox } from "../../../../Styles/GlobalStyles";
import { DialogContent, Stack, Typography, Box, Grid, Divider } from "@mui/material";
import Button from "../../../../components/Button/Button";

const SuccessDialogBox = ({
  open,
  onClose,
  message,
  heading,
  buttonTitle = "Done",
  defaultDetails,
}: any) => {
  const finalDetails = defaultDetails;

  return (
    <ServiceTicketDialogBox
      open={open}
      onClose={onClose}
      aria-describedby="modal-modal-description"
      maxWidth="md">
      <DialogContent>
        <Stack justifyContent="center" alignItems="center">
          <Typography
            id="modal-modal-title"
            fontWeight={600}
            fontSize={{ xs: "16px", md: "20px" }}
            textAlign="center"
            mb={2}>
            {heading}
          </Typography>
          {defaultDetails && (
            <Box
              sx={{
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: 2,
                width: "100%",
                maxWidth: "800px",
                backgroundColor: "#fff",
              }}>
              <Typography fontWeight={600} fontSize="14px" mb={1}>
                Service Issue Details:
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Grid container rowSpacing={2}>
                {finalDetails?.map((detail: { label: string; value: string }, index: number) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12} sm={6}>
                      <Typography fontWeight={500} fontSize="14px">
                        {detail?.label}
                      </Typography>
                      <Typography fontSize="14px">{detail?.value}</Typography>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
          )}
          {message && (
            <Typography fontSize="14px" textAlign="center" mt={2}>
              {message}
            </Typography>
          )}
          <Box mt={3}>
            <Button title={buttonTitle} type="root" onClick={onClose} />
          </Box>
        </Stack>
      </DialogContent>
    </ServiceTicketDialogBox>
  );
};

export default SuccessDialogBox;
