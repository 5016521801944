import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Theme } from "../../../../Styles/GlobalTheme";
import { formatPhoneNumber } from "../../../../Services/helper";
import { ErrorMessage, ProfileEmailInput, ProfileInput } from "../../../../Styles/GlobalStyles";
import { SignUpAutoPay } from "../../../../components/shared/TotalBalance/SignUpAutoPay/SignUpAutoPay";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  setAutopaySuccess,
  setShowNextinAutoPay,
} from "../../../../Redux/Slices/OrderUpgradeSlice";

interface InfoProps {
  email: string;
  phoneNumber: string;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  checkPaperless: boolean;
  checkAutopay: boolean;
  setCheckPaperless: React.Dispatch<React.SetStateAction<boolean>>;
  setCheckAutopay: React.Dispatch<React.SetStateAction<boolean>>;
  handlePhoneNumberBlur: any;
  verifyInfoErrors: any;
  setVerifyInfoErrors: any;
  openAutopay: any;
  existingAddons: any;
  submitOrderArr: any;
  setSubmitOrderArr: any;
  handleSubmitOrder: any;
  futureRouterArray: any;
  setFutureRouterArray: any;
}

export const VerifyInfo = (props: InfoProps) => {
  const {
    email,
    phoneNumber,
    setPhoneNumber,
    setCheckAutopay,
    setCheckPaperless,
    checkAutopay,
    checkPaperless,
    handlePhoneNumberBlur,
    verifyInfoErrors,
    setVerifyInfoErrors,
    openAutopay,
    setSubmitOrderArr,
    submitOrderArr,
    handleSubmitOrder,
    existingAddons,
  } = props;
  const { telephoneContact } = useSelector((state: any) => state.customerInfoDetails);
  const { paymentsMigrationToNDS } = useSelector((state: any) => state.customerPlanDetails);
  const { autopayAmount, paperlessAmount } = useSelector((state: any) => state.customerPlanDetails);
  const { paperLessData, autoPayData, uuidErrorFlag } = useSelector(
    (state: any) => state?.autopayPaperlessDetails
  );
  const customerDetails = useSelector((state: any) => state.customerInfoDetails);
  const isNDS = customerDetails?.ndsObj?.isNDS;

  const paperlessEnabled = paperLessData?.paperlessFlag;
  const dispatch = useDispatch();

  const handleChangePaperless = (event: React.ChangeEvent<HTMLInputElement>) => {
    const paperItemCode = "PAPBR";
    let updatedAddons: any = [];
    if (!checkPaperless) {
      updatedAddons = submitOrderArr?.filter((item: any) => item.addonItemCode !== paperItemCode);
      setSubmitOrderArr(updatedAddons);
    } else {
      setSubmitOrderArr((prevState: any) => [
        ...prevState,
        {
          name: "Paperless Convenience Fee",
          price: paperlessAmount,
          addonItemCode: paperItemCode,
        },
      ]);
    }
    setCheckPaperless(event.target.checked);
  };

  const handleChangeAutopay = (event: React.ChangeEvent<HTMLInputElement>) => {
    const autopayItemCode = "PAYR1";
    let updatedAddons: any = [];
    if (!checkAutopay) {
      updatedAddons = submitOrderArr.filter((item: any) => item.addonItemCode !== autopayItemCode);
      setSubmitOrderArr(updatedAddons);
    } else {
      setSubmitOrderArr((prevState: any) => [
        ...prevState,
        {
          name: "Autopay Convenience Fee",
          price: autopayAmount,
          addonItemCode: autopayItemCode,
        },
      ]);
    }
    setCheckAutopay(event.target.checked);
  };

  useEffect(() => {
    setPhoneNumber(telephoneContact?.telephoneNumber);
    setVerifyInfoErrors({});
    dispatch(setShowNextinAutoPay(false));
    dispatch(setAutopaySuccess(false));
  }, []);

  const showAutopay =
    ((autoPayData && Object.keys(autoPayData)?.length === 0) || uuidErrorFlag === null) &&
    (isNDS || paymentsMigrationToNDS);
  const showPaperless = paperlessEnabled === false || uuidErrorFlag === null;

  return (
    <>
      {openAutopay ? (
        <>
          <SignUpAutoPay orderUpgrade={true} handleSubmitOrder={handleSubmitOrder} />
        </>
      ) : (
        <>
          <Stack gap={1.5} my={2}>
            <Typography>
              Please verify that this is the best phone number for this upgrade order.
            </Typography>
            <Typography fontSize={{ xs: "16px", md: "18px" }} color={Theme.palette.primary.main}>
              Customer Information
            </Typography>
          </Stack>
          <Grid container justifyContent={"space-between"} gap={2} flexWrap={"wrap"} my={2}>
            <Grid item xs={12} sm={5.5}>
              <FormControl fullWidth>
                <Box display="flex">
                  <Typography fontSize={"12px"} mb={0.7}>
                    Email
                  </Typography>
                </Box>
                <ProfileEmailInput name="email" value={email} disabled />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5.5}>
              <FormControl fullWidth>
                <Box display="flex">
                  <Typography fontSize={"12px"}>Phone Number</Typography>
                  <Typography component="span" color="error" ml={0.5}>
                    *
                  </Typography>
                </Box>

                <ProfileInput
                  hasError={
                    verifyInfoErrors?.validatePhoneNumberError || verifyInfoErrors.phoneNumber
                  }
                  onBlur={handlePhoneNumberBlur}
                  name="phoneNumber"
                  value={formatPhoneNumber(phoneNumber)}
                  onChange={(e: React.BaseSyntheticEvent) =>
                    setPhoneNumber(formatPhoneNumber(e.target.value.replace(/\D/g, "")))
                  }
                />
                {(verifyInfoErrors?.validatePhoneNumberError || verifyInfoErrors.phoneNumber) && (
                  <ErrorMessage
                    error={
                      verifyInfoErrors?.validatePhoneNumberError || verifyInfoErrors.phoneNumber
                        ? true
                        : false
                    }>
                    {verifyInfoErrors?.validatePhoneNumberError || verifyInfoErrors.phoneNumber}
                  </ErrorMessage>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <FormGroup>
            {showPaperless && (
              <FormControlLabel
                componentsProps={{ typography: { fontSize: "12px" } }}
                control={
                  <Checkbox
                    inputProps={{ "aria-label": "controlled" }}
                    size="small"
                    checked={checkPaperless}
                    onChange={handleChangePaperless}
                  />
                }
                label={`Enroll me in paperless billing to save $${paperlessAmount}/mo.`}
              />
            )}
            {showAutopay && (
              <FormControlLabel
                componentsProps={{ typography: { fontSize: "12px" } }}
                control={
                  <Checkbox
                    inputProps={{ "aria-label": "controlled" }}
                    size="small"
                    checked={checkAutopay}
                    onChange={handleChangeAutopay}
                  />
                }
                label={`Enroll me in autopay to save $${autopayAmount}/mo.`}
              />
            )}
          </FormGroup>
        </>
      )}
    </>
  );
};
