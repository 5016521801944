import React, { useEffect, useState } from "react";
import { Card, Divider, Grid, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Dayjs } from "dayjs";
import Button from "../../../../components/Button/Button";
import CustomCalendar from "./Calendar";
import dayjs from "dayjs";
import moment from "moment";
import { formatTimeWithAMPM, generateAppointmentSlots } from "./OrderHelper";
import { useDispatch } from "react-redux";
import { setSlotSelected } from "../../../../Redux/Slices/OrderUpgradeSlice";

const SlotSelection = ({ submitOrderResponse, selectedSlotObj, setSelectedSlotObj }: any) => {
  const [timeSlots, setTimeSlots] = useState<any>([]);
  const [isFirstSelection, setIsFirstSelection] = useState(true);
  const dispatch = useDispatch();

  const randomAppointments = generateAppointmentSlots(90);
  const appointmentSlots =
    submitOrderResponse?.appointments && submitOrderResponse?.appointments?.length > 0
      ? submitOrderResponse?.appointments
      : randomAppointments;

  const formattedAppointments = appointmentSlots.map((appointment: any) => {
    const startDate: any = moment(appointment.startDate);
    const endDate: any = moment(appointment.endDate);

    return {
      ...appointment,
      formattedStartDate: startDate.format("YYYY-MM-DD"),
      formattedEndDate: endDate.format("YYYY-MM-DD"),
      startTime: formatTimeWithAMPM(appointment.startDate),
      endTime: formatTimeWithAMPM(appointment.endDate),
    };
  });

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    dayjs(formattedAppointments?.[0]?.formattedStartDate)
  );

  const handleClick = (slot: any) => {
    setSelectedSlotObj(slot);
    dispatch(setSlotSelected(true));
  };

  useEffect(() => {
    if (isFirstSelection && selectedDate) {
      const formatted = moment(dayjs(selectedDate).format()).format("YYYY-MM-DD");
      const initialDateTimeSlots = formattedAppointments.filter((itemTime: any) => {
        return itemTime.formattedStartDate === formatted;
      });
      setTimeSlots(initialDateTimeSlots);
      setIsFirstSelection(false);
    }
  }, [dispatch, selectedDate, formattedAppointments, setSelectedSlotObj]);

  const handleSelectedDate = (value: any) => {
    setSelectedDate(value);
    const formatted = moment(dayjs(value).format()).format("YYYY-MM-DD");
    const dateTimeSlots = formattedAppointments.filter((itemTime: any) => {
      return itemTime.formattedStartDate === formatted;
    });
    setTimeSlots(dateTimeSlots);
  };

  return (
    <>
      <Typography my={2}>
        Please select an installation date before confirming your upgrade.
      </Typography>
      <Card elevation={0} sx={{ border: "1px solid grey", borderRadius: 3, padding: "1rem" }}>
        <Typography fontSize={{ xs: "12px", md: "14px" }} color="primary">
          Schedule Installation
        </Typography>

        <CustomCalendar
          selectedDate={selectedDate}
          onDateChange={handleSelectedDate}
          minDate={dayjs(formattedAppointments?.[0]?.formattedStartDate)}
          maxDate={dayjs(
            formattedAppointments?.[formattedAppointments?.length - 1]?.formattedEndDate
          )}
        />

        {timeSlots && timeSlots?.length > 0 ? (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Typography fontSize={{ xs: "12px", md: "14px" }} color="primary" my={1}>
              Available Times
            </Typography>
            <Stack spacing={2}>
              {timeSlots.map((slot: any, index: number) => (
                <Stack key={index} spacing={1}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Typography>
                      {slot.startTime} - {slot.endTime}
                    </Typography>
                    <Button
                      type={selectedSlotObj === slot ? "selected" : "pay"}
                      title={selectedSlotObj === slot ? "SELECTED" : "SELECT"}
                      onClick={() => handleClick(slot)}
                    />
                  </Grid>
                  {index < timeSlots.length - 1 && <Divider />}
                </Stack>
              ))}
            </Stack>
          </>
        ) : (
          !isFirstSelection && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Typography
                fontSize={{ xs: "14px", md: "18px" }}
                textAlign={"center"}
                fontWeight={600}
                mt={1.5}>
                No slots available
              </Typography>
            </>
          )
        )}
      </Card>
    </>
  );
};

export default SlotSelection;
