import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { PlanCardLayout } from "./BroadbandFactsCard";
import { Theme } from "../../../../Styles/GlobalTheme";
import Button from "../../../../components/Button/Button";
import { StyledElement } from "../../../../Styles/GlobalStyles";
import { getDollarSign } from "../../../../Services/helper";
import { planProps } from "./OrderUpgrades";
import { useDispatch } from "react-redux";
import {
  setIsSelected,
  setPlanDetailsBySkuError,
  setSelectedPlanDetails,
  setZoomOutFlag,
} from "../../../../Redux/Slices/OrderUpgradeSlice";
import { useMutation } from "@apollo/client";
import { GET_PLAN_DETAILS_BY_SKU } from "./query";
import PlanDetailsDialog from "../../../Home/Components/NetworkStatus/PlanDetailsDialog";
import ContactSupport from "../../../../components/shared/ContactSupport/ContactSupport";

interface PlanCardProps {
  selectPlan: number | null;
  setSelectPlan: React.Dispatch<React.SetStateAction<number | null>>;
  plan: planProps;
  index: number;
  highestPlan: string;
  setShowBroadbandFacts: React.Dispatch<React.SetStateAction<boolean>>;
  futureRouterArray: any;
  setSubmitOrderArr: any;
  submitOrderArr: any;
  setInstallation: any;
}

const PlansCard = (props: PlanCardProps) => {
  const dispatch = useDispatch();
  const [Details, setDetails] = useState(false);
  const {
    selectPlan,
    setSelectPlan,
    plan,
    index,
    setShowBroadbandFacts,
    highestPlan,
    setSubmitOrderArr,
    submitOrderArr,
    setInstallation,
  } = props;

  const handleSelectPlan = (plan: planProps, index: number) => {
    if (selectPlan === index) return;

    setInstallation(null);
    setSubmitOrderArr([]);
    setSelectPlan(index);
    setShowBroadbandFacts(true);
    dispatch(setSelectedPlanDetails(plan));
    let newSubmitArray: any = submitOrderArr?.filter(
      (item: any) => item?.productId === plan?.productId
    );

    let newRowItem: any = plan;
    const updatedItem = { ...newRowItem, isPerMonth: true };
    newSubmitArray.push(updatedItem);
    setSubmitOrderArr(newSubmitArray);

    dispatch(setIsSelected(true));
    dispatch(setZoomOutFlag(false));
  };

  const [getPlandetailsBySku, { data: planDetailsData, loading: planDetailsLoading }] = useMutation(
    GET_PLAN_DETAILS_BY_SKU,
    {
      variables: {
        contentSkuCode: plan?.contentSku ?? "",
      },
      fetchPolicy: "network-only",
      onError: () => dispatch(setPlanDetailsBySkuError(true)),
      onCompleted: () => dispatch(setPlanDetailsBySkuError(false)),
    }
  );

  const planDetails = planDetailsData?.getPlansDetailsContentByContentSku;

  const handlePlanDetails = () => {
    getPlandetailsBySku();
    setDetails(true);
  };

  const handleCloseDialog = () => {
    setDetails(false);
  };

  return (
    <>
      <PlanCardLayout highestPlan={highestPlan === plan?.name}>
        <Stack spacing={highestPlan === plan?.name ? 2 : 1}>
          <Typography fontWeight={600} color={Theme.palette.primary.main}>
            {plan?.name}
          </Typography>
          <Stack
            width={"100%"}
            direction={"row"}
            spacing={{ xs: 0.5, sm: 1 }}
            alignItems={"center"}
            p={1.2}
            sx={{ backgroundColor: Theme.palette.customcolor.skyBlue }}>
            <Typography fontSize={{ xs: "18px", md: "20px" }} fontWeight={600}>
              {getDollarSign(plan?.priceAfterDiscount ?? plan?.price)}/mo
            </Typography>
            {plan?.discounts?.length > 0 && (
              <>
                <Typography fontSize={"10px"}>
                  (For the remaining months of your 12-month promotional period)
                </Typography>
              </>
            )}
          </Stack>
          {highestPlan === plan?.name ? (
            <>
              <Stack direction={"row"} spacing={0.2} my={1} flexWrap={"wrap"}>
                <Typography fontSize={{ xs: "14px", md: "14px" }}>Please</Typography>
                <Typography>
                  <ContactSupport text="Contact our Support Team to upgrade" />
                </Typography>
              </Stack>
            </>
          ) : (
            <>
              <Button
                type={selectPlan === index ? "selected" : "pay"}
                title={selectPlan === index ? "SELECTED" : "SELECT"}
                onClick={() => handleSelectPlan(plan, index)}
              />
              <StyledElement onClick={handlePlanDetails}>Plan Details</StyledElement>
            </>
          )}
        </Stack>
      </PlanCardLayout>

      <PlanDetailsDialog
        open={Details}
        onClose={handleCloseDialog}
        planDetails={planDetails}
        planDetailsLoading={planDetailsLoading}
      />
    </>
  );
};

export default PlansCard;
