import { gql } from "@apollo/client";

export const GET_PUBLIC_KEY = gql`
  query getPublicKey {
    getPublicKey {
      error
      APIStatus
      message
      publicKey
    }
  }
`;

export const CREATE_CUSTOMER_CONTACT = gql`
  mutation createCustomerContact(
    $encryptedPayload: String
    $customerContactInput: CustomerContactInput!
  ) {
    createCustomerContact(
      encryptedPayload: $encryptedPayload
      customerContactInput: $customerContactInput
    ) {
      id
      userId
      telephoneContact {
        telephoneNumber
        type
        userId
        id
        verifiedDate
        isPrimaryTelephoneContact
      }
      emailContact {
        id
        userId
        emailAddress
        isPrimaryEmailContact
        verifiedDate
      }
      hasProvidedEmailContact
      hasProvidedTelephoneContact
      refusedToProvideTelephoneContact
      givenAndFamilyName {
        familyName
        givenName
      }
      APIStatus
      error
      message
    }
  }
`;

export const VERIFY_ACCOUNT_WITH_PIN = gql`
  query verifyAccountWithPin($zipCode: String, $pin: String!, $billingAccountNumber: String!) {
    verifyAccountWithPin(
      zipCode: $zipCode
      pin: $pin
      billingAccountNumber: $billingAccountNumber
    ) {
      error
      APIStatus
      message
    }
  }
`;

export const REGISTER_USER_WITH_LINK = gql`
  mutation registerUserWithLink($email: String!) {
    registerUserWithLink(email: $email) {
      error
      message
      APIStatus
    }
  }
`;

export const PHONE_NUMBER_VALIDATION = gql`
  mutation phoneNumberValidation($phoneNumber: String!) {
    phoneNumberValidation(phoneNumber: $phoneNumber) {
      validationResult
      phoneNumberType
      APIStatus
      error
      message
    }
  }
`;
