import { useDispatch } from "react-redux";
import { setIframeURL } from "../../../../Redux/Slices/PaymentSlice";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";

export const NewCardOrBank = ({ value, setValue }: any) => {
  const dispatch = useDispatch();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    dispatch(setIframeURL(""));
  };

  return (
    <>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}>
          {value === "new payment" ? (
            <FormControlLabel value="new payment" control={<Radio />} label="New payment method" />
          ) : (
            <>
              <FormControlLabel
                value="new credit/debit"
                control={<Radio />}
                label="New credit/debit card"
              />
              <FormControlLabel value="new bank" control={<Radio />} label="New bank account" />
            </>
          )}
        </RadioGroup>
      </FormControl>
    </>
  );
};
