import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { GET_PUBLIC_KEY } from "../Signin/query";
import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import Visibility from "@mui/icons-material/Visibility";
import { UPDATE_CUSTOMER_PASSWORD } from "../Login/query";
import { useLazyQuery, useMutation } from "@apollo/client";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoginBox from "../../components/shared/Login/LoginBox";
import { Footer } from "../../components/shared/Footer/Footer";
import { LoginHeader } from "../../components/shared/Login/LoginHeader";
import { RSAOAEPPadding, validInputSubmit } from "../../Services/helper";
import { usePasswordCriteria } from "../../hooks/Password/usePasswordCriteria";
import PasswordCriteria from "../../components/PasswordCriteria/PasswordCriteria";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner/LoadingSpinner";
import { CenterStack, ErrorMessage, ResetPasswordInput } from "../../Styles/GlobalStyles";
import { Box, FormControl, IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import { UpdateErrorComponent } from "../../components/shared/UpdateErrorComponent/UpdateErrorComponent";

export const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [openError, setOpenError] = useState(false);
  const [continueReset, setContinueReset] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const { passwordCriteria, validatePasswordWithCriteria } = usePasswordCriteria();

  const [getPublicKey, { data: publicKey }] = useLazyQuery(GET_PUBLIC_KEY);

  const [updateCustomerPassword, { loading: updateLoading }] = useMutation(
    UPDATE_CUSTOMER_PASSWORD,
    {
      onError: () => setOpenError(true),
    }
  );

  const [formDataForPasswords, setFormDataForPasswords] = useState<any>({
    newPassword: "",
    confirmNewPassword: "",
  });
  const [formPasswordErrors, setFormPasswordErrors] = useState<any>({
    newPassword: "",
    confirmNewPassword: "",
  });

  const handleClickConfirmShowPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const handleClickNewShowPassword = () => {
    setShowNewPassword((show) => !show);
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleNewPassword = (e: any) => {
    validatePasswordWithCriteria(e.target.value);
    setFormDataForPasswords({ ...formDataForPasswords, newPassword: e.target.value });
    setFormPasswordErrors({});
  };

  const handleConfirmPassword = (e: any) => {
    setFormDataForPasswords({ ...formDataForPasswords, confirmNewPassword: e.target.value });
    setFormPasswordErrors({});
  };

  const handleNavigate = () => {
    navigate("/login");
  };

  const passwordValidation = (name: any) => {
    const errors = validInputSubmit(formDataForPasswords);
    if (Object.keys(errors).length > 0) {
      setFormPasswordErrors({ ...formPasswordErrors, [name]: errors[name] });
    }
  };

  const handleUpdatePassword = async () => {
    try {
      const hasPasswordCriteriaMet =
        passwordCriteria?.hasEightOrMoreChars &&
        passwordCriteria?.hasAtleastOneUpperAndLowerCase &&
        passwordCriteria?.hasAtleastOneNumber;

      let errors = validInputSubmit(formDataForPasswords);
      if (Object.keys(errors).length > 0) {
        setFormPasswordErrors(errors);
      }
      const IsEmpty = Object.values(errors).every((value) => value === "");
      const payload = `${formDataForPasswords?.confirmNewPassword},${query.get("oobCode")}`;

      const encryptedPayload = RSAOAEPPadding(payload, publicKey?.getPublicKey?.publicKey);
      if (IsEmpty && hasPasswordCriteriaMet) {
        const response = await updateCustomerPassword({
          variables: {
            encryptedPayload: encryptedPayload,
          },
        });
        if (response?.data) {
          if (!response.data?.updateCustomerPassword?.error) {
            setContinueReset(true);
          } else {
            setOpenError(true);
          }
        }
      }
    } catch (err) {
      setOpenError(true);
    }
  };

  useEffect(() => {
    getPublicKey();
    setOpenError(false);
  }, []);

  return (
    <Stack justifyContent={"space-between"} height={"100vh"} overflow={"auto"}>
      <LoginHeader />
      <Box>
        <CenterStack direction={"column"} width={{ xs: "100%" }} py={6}>
          <LoginBox>
            <Box width={"95%"}>
              {continueReset ? (
                <CenterStack direction={"column"} spacing={1} py={4}>
                  <Typography
                    component={"span"}
                    fontSize={{ xs: "14px", sm: "16px" }}
                    textAlign={"center"}
                    data-testid="success-msg"
                    fontWeight={600}>
                    You have successfully reset your password.
                  </Typography>
                  <Typography
                    component={"span"}
                    fontSize={{ xs: "14px", sm: "16px" }}
                    textAlign={"center"}
                    fontWeight={600}>
                    Please proceed to log in with the new password.
                  </Typography>
                  <Box pt={2}>
                    <Button title="log in" type="root" onClick={handleNavigate} />
                  </Box>
                </CenterStack>
              ) : (
                <>
                  <>
                    <Typography
                      fontSize={"16px"}
                      fontWeight={600}
                      mb={2}
                      component={"p"}
                      textAlign={"center"}>
                      Reset password
                    </Typography>
                    <Stack gap={2}>
                      <FormControl fullWidth>
                        <Typography fontSize={"14px"} component={"p"} my={1}>
                          Enter new password
                        </Typography>
                        <ResetPasswordInput
                          inputProps={{ "data-testid": "password" }}
                          fullWidth
                          name="password"
                          onBlur={() => passwordValidation("newPassword")}
                          value={formDataForPasswords?.newPassword}
                          hasError={formPasswordErrors?.newPassword ? true : false}
                          onChange={handleNewPassword}
                          id="outlined-adornment-password"
                          type={showNewPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                disableRipple
                                aria-label="toggle password visibility"
                                onClick={handleClickNewShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end">
                                {showNewPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {formPasswordErrors?.newPassword && (
                          <ErrorMessage error={formPasswordErrors?.newPassword ? true : false}>
                            {formPasswordErrors?.newPassword}
                          </ErrorMessage>
                        )}
                      </FormControl>
                      <FormControl fullWidth>
                        <Typography fontSize={"14px"} my={1}>
                          Confirm new password
                        </Typography>
                        <ResetPasswordInput
                          inputProps={{ "data-testid": "confirmPassword" }}
                          name="confirmPassword"
                          value={formDataForPasswords?.confirmNewPassword}
                          onChange={handleConfirmPassword}
                          hasError={formPasswordErrors?.confirmNewPassword ? true : false}
                          fullWidth
                          onBlur={() => passwordValidation("confirmNewPassword")}
                          id="outlined-adornment-confirm-password"
                          type={showConfirmPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                disableRipple
                                aria-label="toggle password visibility"
                                onClick={handleClickConfirmShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end">
                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {formPasswordErrors?.confirmNewPassword && (
                          <ErrorMessage
                            error={formPasswordErrors?.confirmNewPassword ? true : false}>
                            {formPasswordErrors?.confirmNewPassword}
                          </ErrorMessage>
                        )}
                      </FormControl>
                      <PasswordCriteria passwordCriteria={passwordCriteria} />
                      <CenterStack direction={"row"} spacing={2} my={2}>
                        <Button
                          title="Continue"
                          disabled={!query.get("oobCode")}
                          onClick={handleUpdatePassword}
                          type="root"
                        />
                      </CenterStack>
                    </Stack>
                  </>
                </>
              )}
            </Box>
          </LoginBox>
          <Box mt={6}></Box>
          {openError && <UpdateErrorComponent setOpenError={setOpenError} openError={openError} />}
          {updateLoading && <LoadingSpinner />}
        </CenterStack>
      </Box>
      <Footer />
    </Stack>
  );
};
