import { useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_GLOBYS_URL } from "../../Pages/Home/query";
import { setGlobysUrl, setGlobysUrlError } from "../../Redux/Slices/AccountSlice";

export const useGlobysURL = (email: string, globysId: string) => {
  const dispatch = useDispatch();
  const [getGlobysURL, { loading: globysURLLoading }] = useLazyQuery(GET_GLOBYS_URL, {
    variables: {
      email: email,
      globysId: globysId,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      dispatch(setGlobysUrl(data?.globysUrl?.url));
      dispatch(setGlobysUrlError(false));
    },
    onError: () => {
      dispatch(setGlobysUrlError(true));
    },
  });

  return {
    getGlobysURL,
    globysURLLoading,
  };
};
