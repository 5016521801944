import { gql } from "@apollo/client";

export const GET_APPOINTMENT_DETAILS = gql`
  query getOrderRescheduleAppointmentDetails($orderId: String!) {
    getOrderRescheduleAppointmentDetails(orderId: $orderId) {
      APIStatus
      error
      message
      appointments {
        startDate
        endDate
        resourcePoolId
        timeSlotType
        workUnitsQuantity
        startDateDisplay
        endDateDisplay
      }
    }
  }
`;

export const RESCHEDULE_APPOINTMENT = gql`
  mutation rescheduleOrderAppointmentSlot(
    $appointmentSlot: RescheduleOrderAppointmentSlot!
    $orderId: String!
    $email: String!
    $isOrder: Boolean!
    $accountNumber: String
  ) {
    rescheduleOrderAppointmentSlot(
      orderId: $orderId
      email: $email
      appointmentSlot: $appointmentSlot
      isOrder: $isOrder
      accountNumber: $accountNumber
    ) {
      APIStatus
      error
      message
    }
  }
`;
