import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userToken: "",
  email: "",
  forceReset: false,
  globysId: "",
  error: "",
  userNotInFirestore: false,
  userLoggedInSuccess: false,
  envFlags: [],
  paymentWarning: {},
};

const UserInfoSlice = createSlice({
  name: "UserInfo",
  initialState,
  reducers: {
    userInfo: (state, action) => {
      state.userToken = action.payload.userToken;
      state.email = action.payload.email;
    },
    setForceReset: (state, action) => {
      state.forceReset = action.payload;
    },
    setGlobysId: (state, action) => {
      state.globysId = action.payload;
    },
    setUserError: (state, action) => {
      state.error = action.payload;
    },
    setUserNotInFirestore: (state, action) => {
      state.userNotInFirestore = action.payload;
    },
    setUserLoggedInSuccess: (state, action) => {
      state.userLoggedInSuccess = action.payload;
    },
    setEnvFlags: (state, action) => {
      state.envFlags = action.payload;
    },
    paymentWarningMessage: (state, action) => {
      state.paymentWarning = action.payload;
    },
  },
});

export const {
  userInfo,
  setForceReset,
  setGlobysId,
  setUserError,
  setUserNotInFirestore,
  setEnvFlags,
  setUserLoggedInSuccess,
  paymentWarningMessage,
} = UserInfoSlice.actions;
export const userInfoReducer = UserInfoSlice.reducer;
