import PaymentIframe from "./PaymentIframe";
import { useNavigate } from "react-router-dom";
import { GET_PAYMENT_METHODS } from "../../query";
import React, { useState, useEffect } from "react";
import PaymentType from "../PaymentType/PaymentType";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/Button/Button";
import { useMutation, useLazyQuery } from "@apollo/client";
import { PAYMENT_DELAY_WARNING } from "../../../Login/query";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Spinner } from "../../../../components/shared/LoadingSpinner/Spinner";
import { AccountCard } from "../../../../components/shared/AccountCard/AccountCard";
import { NeedHelpCard } from "../../../../components/shared/NeedHelpCard/NeedHelpCard";
import { LoadingSpinner } from "../../../../components/shared/LoadingSpinner/LoadingSpinner";
import { ConfirmationModel } from "../../../../components/shared/ConfirmationModel/ConfirmationModel";
import { LinkingWaitMessage } from "../../../../components/shared/LinkingWaitMessage/LinkingWaitMessage";
import { UpdateErrorComponent } from "../../../../components/shared/UpdateErrorComponent/UpdateErrorComponent";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  Grid,
  RadioGroup,
  Stack,
  Box,
  FormControl,
  Snackbar,
  IconButton,
  Card,
  styled,
  FormControlLabel,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import {
  GET_PAYMENT_METHOD_URL,
  MAKE_A_PAYMENT,
  SCHEDULE_ONE_TIME_PAYMENT,
} from "../../../Payment/query";
import {
  convertToPositive,
  getDollarSign,
  getFlags,
  getModifiedDueDate,
  paymentModifiedDate,
} from "../../../../Services/helper";
import {
  savePaymentInfo,
  setAnotherAmount,
  setApiFailureName,
  setCbstAction,
  setError,
  setIframeURL,
  setInactivatePaymentStatus,
  setPaymentError,
  setPaymentMethodsError,
  setSelectedMethod,
} from "../../../../Redux/Slices/PaymentSlice";
import {
  newPayment,
  MY_ACCOUNT,
  LANGUAGE,
  TOKENIZED_CREDIT,
  TOKENIZED_BANK,
  PAYMENT_TYPE,
  PAYMENT_DESCRIPTION,
  EXISTING,
  currentDate,
  SUCCESS,
  CANCEL_PAYMENT,
  CANCELLED,
  // SELECT_PAYMENT_METHOD,
  // AMOUNT_WARNING_MSG,
  // VISA,
  // visa,
  // masterCard,
  COMPLETE_SUCCESS,
  SAVE_PAYMENT_HEADING,
  PAYMENT_TERMS_URL,
  NOT_FOUND,
  UUID_ERROR_MSG_EXISTING_CUSTOMER_PAYMENTS,
  UUID_ERROR_MSG_NEW_CUSTOMER_PAYMENTS,
  HYPHEN,
  // CREDIT_CARD,
  // BANK,
  // NEW_CARD,
  // NEW_BANK,
  FETCHING_BILLING_DETAILS_MESSAGE,
  PAYMENT_METHODS_SECURITY_NOTICE,
  isAuthenticated,
  GET_PAYMENT_METHODS_CBST_ACTION,
  REPORT_SERVICE_ISSUE_ERROR,
} from "../../../../Constants/constants";
import {
  StyledCard,
  MakeaPaymentDivider,
  StyledElement,
  StyledLink,
  CardHeading,
  StyledRadio,
  StyledFormControlLabel,
  // AutoPayDivider,
  PaymentTextField,
  // RowCenterStack,
  StyledA,
  PageGrid,
  StickyBox,
  PTypography,
  ErrorMessage,
  SecurityNoticeCard,
} from "../../../../Styles/GlobalStyles";
import dayjs from "dayjs";
import ReportIssue from "../../../../components/shared/ReportIssue/ReportIssue";
import { ErrorComponent } from "../../../../components/ErrorComponent/ErrorComponent";
import { LegacyPaymentErrorPopup } from "../../../../components/shared/UpdateErrorComponent/LegacyPaymentErrorPopup";

export const MakeAPayment = ({ billingLoading, getbillingDetails }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state: any) => state.userInfo);
  const { scrollPosition, makePaymentRoute } = useSelector((state: any) => state.pageInfo);
  const { ndsObj } = useSelector((state: any) => state.customerInfoDetails);
  const customerDetails = useSelector((state: any) => state.customerInfoDetails);
  const { iframeURL, anotherAmount, paymentMethodsError, savePaymentData, selectedMethod, error } =
    useSelector((state: any) => state.paymentDetails);
  const { currentDueBalance, billDueDate, paymentsMigrationToNDS, isNewCustomer } = useSelector(
    (state: any) => state.customerPlanDetails
  );
  const [isInputLessThanZero, setIsInputLessThanZero] = useState<boolean>(false);
  const [totalBalance, setTotalBalance] = useState<string>("payment");
  const [savePayment, setSavePayment] = useState<boolean>(false);
  const [paymentInput, setPaymentInput] = useState<string>("");
  const [openError, setOpenError] = useState<boolean>(false);
  const [value, setValue] = useState<any>("dueBalance");
  const isNDS = ndsObj?.isNDS || paymentsMigrationToNDS;
  const [buttonId, setButtonId] = useState(isNDS ? "new card" : "new bank");
  const [selectedOption, setSelectedOption] = useState<string>("payNow");
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null);
  const [saveDate, setSaveDate] = useState<string>("");
  const envFlags = useSelector((state: any) => state?.userInfo?.envFlags);
  const SHOW_REPORT_ISSUE = getFlags("report_service_issue", envFlags);
  const GLOBAL_BANNER = getFlags("show_global_banner", envFlags);
  const [openReportIssue, setOpenReportIssue] = useState(false);
  const [legacyError, setLegacyError] = useState<boolean>(false);
  const [amountExceed, setAmountExceed] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<any>({
    heading: "",
    primaryContent: "",
    containedButton: {
      buttonType: "",
      buttonTitle: "",
    },
    outlinedButton: { buttonType: "", buttonTitle: "" },
  });
  const [PaymentWarningMessage, setPaymentWarningMessage] = useState<any>({
    open: false,
    message: "",
    vertical: "top",
    horizontal: "center",
  });

  const amountwithoutleadingzeros = anotherAmount !== "." && anotherAmount.toString();
  const formattedDate = getModifiedDueDate(billDueDate);
  const price = currentDueBalance ?? 0;
  const { vertical, horizontal } = PaymentWarningMessage;
  const [openCalendar, setOpenCalendar] = useState(false);
  const { activeAccount } = useSelector((state: any) => state?.customerInfoDetails);
  const { givenNameFamilyName } = useSelector((state: any) => state.customerInfoDetails);

  const handleOpenCalendar = () => {
    setOpenCalendar(true);
  };

  const [getPaymentWarningMessage, { data: PaymentWarningMessageData }] = useLazyQuery(
    PAYMENT_DELAY_WARNING,
    {
      onCompleted: (data) => {
        setPaymentWarningMessage({
          ...PaymentWarningMessage,
          open: data.homeScreenMessage?.error,
          message: data.homeScreenMessage?.message,
        });
      },
    }
  );

  const handleCloseSnackBar = () => {
    setPaymentWarningMessage({ ...PaymentWarningMessage, message: "" });
  };

  const handleSuccessPaymentMethods = () => {
    setPaymentMethodsErrorScreen(false);
    !isNDS && setLegacyError(true);
  };
  const [paymentMethodsErrorScreen, setPaymentMethodsErrorScreen] = useState<boolean>(false);

  const handleFailurePaymentMethods = (error: any) => {
    if (error.networkError.result.errors[0]?.message?.toLowerCase()?.includes(NOT_FOUND)) {
      dispatch(setPaymentMethodsError(true));
    } else {
      setPaymentMethodsErrorScreen(true);
      dispatch(setError(error.networkError.result.errors[0]));
      dispatch(setApiFailureName("getPaymentMethods"));
      dispatch(setCbstAction(GET_PAYMENT_METHODS_CBST_ACTION));
      dispatch(setPaymentMethodsError(false));
    }
  };

  const [getPaymentMethods, { loading: paymentMethodsLoading, data: PaymentMethodsData }] =
    useLazyQuery(GET_PAYMENT_METHODS, {
      variables: {
        accountNumber: customerDetails?.accountNumber,
      },
      onCompleted: handleSuccessPaymentMethods,
      onError: (error) => handleFailurePaymentMethods(error),
      fetchPolicy: "network-only",
    });
  const handlePaymentFailure = (error: any) => {
    navigate("/payment/failed");
    if (error) {
      if (error?.networkError?.result?.errors?.[0]?.message?.includes("Payment Unsuccessful")) {
        dispatch(setPaymentError(error?.networkError?.result?.errors?.[0]?.message));
      }
      dispatch(setError(error?.networkError?.result?.errors?.[0]));
    } else {
      dispatch(setPaymentError(""));
    }
  };

  const [scheduleOnetimePayment, { loading: oneTimePaymentLoading }] = useMutation(
    SCHEDULE_ONE_TIME_PAYMENT,
    {
      onCompleted: (data) => {
        setOpenError(data?.scheduleOneTimePayment?.error);
        getbillingDetails();
      },
      onError: (error) => {
        handlePaymentFailure(error);
      },
    }
  );

  const handleFailurePaymentURL = (URLError: any) => {
    URLError && setOpenError(true);
  };

  const handleSuccessPaymentURL = (URLData: any) => {
    dispatch(setIframeURL(URLData?.getAddPaymentMethodURL?.url));
    setOpenError(URLData?.getAddPaymentMethodURL?.error);
  };
  const checkAmount =
    totalBalance === newPayment
      ? anotherAmount > 0 && parseFloat(anotherAmount.toString()).toFixed(2)
      : parseFloat(convertToPositive(price).toString()).toFixed(2);

  const getAddPaymentMethodURLInput = {
    paymentType: paymentInput,
    billingName: `${givenNameFamilyName?.givenName ?? ""} ${givenNameFamilyName?.familyName ?? ""}`,
    email: userDetails?.email,
    language: LANGUAGE,
    cancelURL: `${window.location.href}?status=failure`,
    successURL: `${window.location.href}?status=success`,
    date: paymentModifiedDate(currentDate),
    completePayment: true,
    amount:
      totalBalance === newPayment
        ? anotherAmount > 0 && parseFloat(parseFloat(anotherAmount).toFixed(2))
        : convertToPositive(parseFloat(price)?.toFixed(2)),
    isNds: ndsObj?.isNDS || paymentsMigrationToNDS,
  };

  const [getPaymentMethodURL, { loading: urlLoading }] = useMutation(GET_PAYMENT_METHOD_URL, {
    variables: {
      accountNumber: customerDetails?.accountNumber,
      addPaymentMethodInput: getAddPaymentMethodURLInput,
    },
    fetchPolicy: "network-only",
    onCompleted: (URLData) => handleSuccessPaymentURL(URLData),
    onError: (URLError) => handleFailurePaymentURL(URLError),
  });

  const [makeAPayment, { loading: paymentLoading }] = useMutation(MAKE_A_PAYMENT, {
    onCompleted: () => {
      navigate("/payment/success");
      getbillingDetails();
    },
    onError: (error: any) => {
      dispatch(setError(error?.networkError?.result?.errors?.[0]));
      handlePaymentFailure(error);
    },
  });

  const handleChange = (event: any) => {
    const selectedValue = (event.target as HTMLInputElement).value;
    setValue(selectedValue);
    setTotalBalance(selectedValue);
    dispatch(setAnotherAmount(0));
    setIsInputLessThanZero(false);
    setAmountExceed(false);
  };
  const paymentMethods: any = PaymentMethodsData?.paymentMethods?.paymentDetails;

  const handleFilterPaymentMethod =
    paymentMethods && paymentMethods?.filter((method: any) => method?.id === buttonId);

  const filteredPaymentMethod = handleFilterPaymentMethod?.[0];

  const scheduleOneTimePaymentInput = {
    paymentMethodId: filteredPaymentMethod?.id,
    paymentMethodType: filteredPaymentMethod?.paymentMethodType,
    amount: checkAmount,
    description: PAYMENT_DESCRIPTION,
    nickName: filteredPaymentMethod?.nameOnTheCard,
    date: paymentModifiedDate(currentDate),
    token: filteredPaymentMethod?.token,
    isNds: ndsObj?.isNDS,
    billingAccountId: filteredPaymentMethod?.billingAccountId,
    paymentChannel: "myaccount",
  };

  const handlePayment = async () => {
    if (paymentInput === EXISTING) {
      handleOnetimePayment();
    } else {
      if (isNDS) {
        setSavePayment(true);
        setDialogData({
          ...dialogData,
          heading: SAVE_PAYMENT_HEADING,
          primaryContent: `Do you want to save the payment method for future use?`,
          containedButton: {
            ...dialogData?.containedButton,
            buttonType: "root",
            buttonTitle: "YES, SAVE",
          },
          outlinedButton: {
            ...dialogData?.outlinedButton,
            buttonType: "root",
            buttonTitle: "NO, DON'T SAVE",
          },
        });
      } else {
        setLegacyError(true);
      }
    }
  };

  const handleSavePayment = () => {
    const saveInfo = {
      save: true,
      amount: anotherAmount,
    };
    dispatch(savePaymentInfo(saveInfo));
    if (isNDS !== undefined) getPaymentMethodURL();
    handleClose();
  };

  const handleSuccess = (data: any, uid: any) => {
    if (
      (ndsObj?.isNDS || paymentsMigrationToNDS) &&
      (data?.content?.status === SUCCESS || data?.subject === "completePayment")
    ) {
      handleMakePayment(data?.content?.uid ? data?.content?.uid : uid, data?.txn_guid);
    } else if (!ndsObj?.isNDS && data.status === COMPLETE_SUCCESS) {
      navigate({ pathname: "/payment/success" }, { state: { data: "", error: false } });
    }
    dispatch(setIframeURL(""));
  };

  const handleFailure = (data: any) => {
    if (data?.subject === CANCEL_PAYMENT || data?.status === CANCELLED) {
      navigate({ pathname: "/payment/failed" }, { state: { data: data } });
      dispatch(setPaymentError(""));
    }
    dispatch(setIframeURL(""));
  };

  const handleOnetimePayment = async () => {
    try {
      const response = await scheduleOnetimePayment({
        variables: {
          accountNumber: customerDetails?.accountNumber,
          scheduleOneTimePaymentInput: scheduleOneTimePaymentInput,
        },
      });
      if (!response?.data?.scheduleOneTimePayment?.error) {
        response?.data && navigate("/payment/success");
        if (!activeAccount) {
          dispatch(setInactivatePaymentStatus(true));
        } else {
          dispatch(setInactivatePaymentStatus(false));
        }
        dispatch(setSelectedMethod(""));
      } else {
        navigate("/payment/failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAnotherAmount = (e: React.BaseSyntheticEvent) => {
    let input = e.target.value;
    input = input.replace(/[^0-9.]+/g, "");

    const parts = input.split(".");
    if (parts.length > 1) {
      input = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    dispatch(setAnotherAmount(input));
    parseFloat(input) < 1 ? setIsInputLessThanZero(true) : setIsInputLessThanZero(false);
    if (activeAccount === false) {
      parseFloat(input) > parseFloat(price) ? setAmountExceed(true) : setAmountExceed(false);
    }
  };

  const handleClose = () => {
    setSavePayment(false);
  };

  const handleDontSavePayment = () => {
    const dontSaveInfo = {
      save: false,
      amount: anotherAmount,
    };
    dispatch(savePaymentInfo(dontSaveInfo));
    if (isNDS !== undefined) {
      getPaymentMethodURL();
    }
    handleClose();
  };

  const handleMakePayment = async (uid: any, transactionId: any) => {
    try {
      const response = await makeAPayment({
        variables: {
          accountNumber: customerDetails?.accountNumber,
          makeAPaymentInput: {
            isNds: ndsObj?.isNDS,
            id: uid,
            paymentType: PAYMENT_TYPE,
            paymentMethodType: paymentInput,
            amount: checkAmount,
            date: paymentModifiedDate(currentDate),
            description: PAYMENT_DESCRIPTION,
            savePaymentInfo: savePaymentData?.save,
            completePayment: true,
            paymentChannel: "myaccount",
          },
        },
      });
      if (response?.data?.makeAPayment?.error) {
        navigate("/payment/failed");
      } else if (response?.data) {
        navigate(
          { pathname: "/payment/success" },
          { state: { data: transactionId, error: false } }
        );
        dispatch(setSelectedMethod(""));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const isAnotherAmountValid = anotherAmount <= 0 || anotherAmount === ".";

  const payButtonEventName =
    paymentInput === TOKENIZED_BANK || paymentInput === TOKENIZED_CREDIT ? "continue" : "payment";

  const payButtonTitle =
    paymentInput === TOKENIZED_BANK || paymentInput === TOKENIZED_CREDIT
      ? "CONTINUE"
      : anotherAmount
      ? `Pay ${getDollarSign(amountwithoutleadingzeros)}`
      : (price && price?.includes("-")) || totalBalance === newPayment
      ? "Pay"
      : `Pay ${getDollarSign(price)}`;

  const payButtonDisable =
    isInputLessThanZero ||
    amountExceed ||
    (price && (price?.includes("-") || price == 0) && isAnotherAmountValid) ||
    (totalBalance === newPayment && isAnotherAmountValid) ||
    billingLoading;

  useEffect(() => {
    getPaymentWarningMessage();
    dispatch(setIframeURL(""));
    dispatch(setAnotherAmount(0));
  }, []);

  useEffect(() => {
    setValue("dueBalance");
    customerDetails?.accountNumber?.trim()?.length > 0 &&
      isAuthenticated === "FALSE" &&
      getPaymentMethods();
  }, [customerDetails?.accountNumber]);

  useEffect(() => {
    customerDetails?.accountNumber?.trim()?.length > 0 &&
      isAuthenticated === "FALSE" &&
      getPaymentMethods();
  }, [customerDetails?.accountNumber]);
  useEffect(() => {
    if (buttonId === "new card") {
      setPaymentInput(TOKENIZED_CREDIT);
    } else if (buttonId === "new bank") {
      setPaymentInput(TOKENIZED_BANK);
    } else {
      setPaymentInput(EXISTING);
    }
  }, [buttonId]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption((event.target as HTMLInputElement).value);
  };

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    setSelectedDate(date);
  };

  const handleSaveDate = () => {
    if (selectedDate) {
      setSaveDate(selectedDate.format("MM/DD/YYYY"));
    }
    setOpenCalendar(false);
  };
  const handleCloseCalendar = () => {
    setOpenCalendar(false);
    setSelectedDate(null);
  };
  const handleRefresh = () => {
    getPaymentMethods({ fetchPolicy: "network-only" });
  };

  return (
    <Box position="relative">
      {GLOBAL_BANNER &&
        PaymentWarningMessage?.message &&
        PaymentWarningMessageData?.homeScreenMessage?.error &&
        PaymentWarningMessageData?.homeScreenMessage?.paymentScreen && (
          <Box sx={{ width: 500 }}>
            <Snackbar
              action={
                <>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleCloseSnackBar}>
                    <DeleteForeverIcon fontSize="small" />
                  </IconButton>
                </>
              }
              sx={{
                textAlign: "center",
              }}
              anchorOrigin={{ vertical, horizontal }}
              open={PaymentWarningMessage?.message?.length > 0}
              message={PaymentWarningMessage?.message}
              key={vertical + horizontal}
              autoHideDuration={100000}
              onClose={handleCloseSnackBar}
            />
          </Box>
        )}
      <PageGrid container>
        <Grid item xs={12} lg={8.2}>
          <StyledLink to={makePaymentRoute}>
            <Stack direction={"row"} alignItems="center">
              <ArrowBackIosIcon color="secondary" />
              <CardHeading>Make a payment</CardHeading>
            </Stack>
          </StyledLink>
          {!paymentMethodsError ? (
            <>
              {!(iframeURL?.length > 0) && (
                <>
                  <CardHeading mt={2}>Total balance</CardHeading>
                  <StyledCard sx={{ paddingX: "20px" }}>
                    {billingLoading ? (
                      <Box py={10}>
                        <Spinner loadingText={FETCHING_BILLING_DETAILS_MESSAGE} />
                      </Box>
                    ) : (
                      <FormControl fullWidth>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={value}
                          onChange={handleChange}>
                          <Stack
                            alignItems={{ xs: "flex-start", sm: "center" }}
                            justifyContent={"space-between"}
                            direction={{ xs: "column", sm: "row" }}>
                            <StyledFormControlLabel
                              disabled={iframeURL?.length > 0}
                              value={"dueBalance"}
                              control={<StyledRadio />}
                              label={`${getDollarSign(price) ?? getDollarSign(0)}`}
                            />
                            <PTypography marginLeft={"30px"}>
                              {billDueDate &&
                                `Current balance due ${billDueDate ? formattedDate : "-"}`}
                            </PTypography>
                          </Stack>
                          <MakeaPaymentDivider />
                          <StyledFormControlLabel
                            disabled={iframeURL?.length > 0}
                            value={newPayment}
                            control={<StyledRadio />}
                            label="Pay another amount"
                          />

                          {(totalBalance === newPayment ||
                            (price?.toString()?.includes(HYPHEN) && value == newPayment)) &&
                            value == newPayment && (
                              <PaymentTextField
                                error={isInputLessThanZero || amountExceed}
                                disabled={iframeURL?.length > 0}
                                name="numberformat"
                                inputProps={{ "data-testid": "another-amount-textfield" }}
                                value={anotherAmount !== 0 ? anotherAmount : null}
                                onChange={handleAnotherAmount}
                              />
                            )}

                          {isInputLessThanZero &&
                            anotherAmount.length !== 0 &&
                            value === newPayment && (
                              <ErrorMessage
                                error={isInputLessThanZero}
                                style={{ marginLeft: "13px" }}>
                                Amount must be more than $1
                              </ErrorMessage>
                            )}
                          {amountExceed && value === newPayment && anotherAmount.length !== 0 && (
                            <ErrorMessage
                              error={isInputLessThanZero}
                              style={{ marginLeft: "13px" }}>
                              Amount must be less than or equal to {currentDueBalance}
                            </ErrorMessage>
                          )}
                        </RadioGroup>
                      </FormControl>
                    )}
                  </StyledCard>
                </>
              )}
              {/* TODO: Enable Scehedule Payments*/}
              {/* <CardHeading sx={{ fontSize: iframeURL?.length > 0 ? "18px" : "20px" }}>
                Payment date
              </CardHeading>
              <StyledCard sx={{ paddingX: "20px" }}>
                <FormControl fullWidth>
                  <RadioGroup
                    aria-labelledby="payment-radio-buttons-group"
                    name="payment-radio-buttons-group"
                    value={selectedOption}
                    onChange={handleRadioChange}>
                    <StyledFormControlLabel
                      value="payNow"
                      control={<StyledRadio />}
                      label="Pay right now"
                    />
                    <MakeaPaymentDivider />
                    <StyledFormControlLabel
                      control={<StyledRadio />}
                      label={selectedOption === "payNow" ? "Pay later" : "Schedule a Payment"}
                      value="pay Later"
                    />
                  </RadioGroup>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DemoItem>
                        <DatePicker
                          disabled={selectedOption === "payNow"}
                          value={selectedDate}
                          onChange={handleDateChange}
                          disablePast
                          views={["year", "month", "day"]}
                          slots={{
                            textField: (params: any) => (
                              <CalendarText
                                {...params}
                                value={selectedDate ? saveDate : ""}
                                InputProps={{
                                  ...params.InputProps,
                                  readOnly: true,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <CalendarMonthIcon />
                                    </InputAdornment>
                                  ),
                                  endAdornment: null,
                                }}
                                onClick={handleOpenCalendar}
                              />
                            ),
                          }}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                  <StyledDialog
                    open={openCalendar}
                    aria-describedby="modal-modal-description"
                    width="100%">
                    <Box mt={3}>
                      <CustomCalendar
                        selectedDate={selectedDate}
                        onDateChange={handleDateChange}
                        minDate={dayjs()}
                        maxDate={dayjs().add(1, "year")}
                      />
                    </Box>
                    <Stack direction={"row"} gap={2} mb={6} justifyContent={"center"}>
                      <Button title={"Save"} onClick={handleSaveDate} type="root" />
                      <Button title={"Cancel"} onClick={handleCloseCalendar} type="cancel" />
                    </Stack>
                  </StyledDialog>
                </FormControl>
              </StyledCard> */}
              <CardHeading sx={{ fontSize: iframeURL?.length > 0 ? "18px" : "20px" }}>
                Payment method
              </CardHeading>
              {isAuthenticated === "TRUE" && (
                <SecurityNoticeCard>{PAYMENT_METHODS_SECURITY_NOTICE}</SecurityNoticeCard>
              )}
              <PaymentMethodsCard
                sx={{
                  padding: iframeURL?.length > 0 ? "0.2rem" : "1rem 1.5rem",
                }}>
                {!(iframeURL?.length > 0) ? (
                  <PaymentType
                    buttonId={buttonId}
                    setButtonId={setButtonId}
                    paymentMethods={paymentMethods}
                    paymentMethodsLoading={paymentMethodsLoading}
                    paymentMethodsErrorScreen={paymentMethodsErrorScreen}
                  />
                ) : (
                  <PaymentIframe
                    handleSuccess={handleSuccess}
                    src={iframeURL}
                    handleFailure={handleFailure}
                  />
                )}
              </PaymentMethodsCard>
              {paymentMethodsErrorScreen && !(iframeURL?.length > 0) && (
                <StyledCard>
                  <ErrorComponent
                    onRefresh={handleRefresh}
                    ShowReportIssue={SHOW_REPORT_ISSUE}
                    errorMessage={REPORT_SERVICE_ISSUE_ERROR}
                  />
                </StyledCard>
              )}
              <Box my={4}>
                <StyledA href={PAYMENT_TERMS_URL} target="_blank">
                  <StyledElement fontSize={{ xs: "14px", sm: "16px" }}>
                    PAYMENT TERMS & CONDITIONS
                  </StyledElement>
                </StyledA>
              </Box>
              {iframeURL?.length === 0 && (
                <Button
                  eventName={payButtonEventName}
                  title={payButtonTitle}
                  disabled={payButtonDisable}
                  onClick={handlePayment}
                  type="payment"
                />
              )}
            </>
          ) : (
            <StyledCard sx={{ py: 20, pb: 22 }}>
              <LinkingWaitMessage
                message={
                  isNewCustomer
                    ? UUID_ERROR_MSG_NEW_CUSTOMER_PAYMENTS
                    : UUID_ERROR_MSG_EXISTING_CUSTOMER_PAYMENTS
                }
              />
            </StyledCard>
          )}
        </Grid>

        <Grid
          mt={{ xs: "32px", lg: "0" }}
          item
          xs={12}
          lg={3.5}
          display={{ xs: "block", lg: scrollPosition > 200 ? "block" : "none" }}>
          <AccountCard totalBalance={totalBalance} setTotalBalance={setTotalBalance} />
          <NeedHelpCard />
        </Grid>
        <StickyBox display={{ xs: "none", lg: scrollPosition > 200 ? "none" : "block" }}>
          <AccountCard totalBalance={totalBalance} setTotalBalance={setTotalBalance} />
          <NeedHelpCard />
        </StickyBox>
      </PageGrid>
      {savePayment && (
        <ConfirmationModel
          openDialog={savePayment}
          setOpenDialog={setSavePayment}
          dialogData={dialogData}
          setDialogData={setDialogData}
          onClickSecondary={handleDontSavePayment}
          onClick={handleSavePayment}
        />
      )}
      {(paymentMethodsLoading || urlLoading || paymentLoading || oneTimePaymentLoading) && (
        <LoadingSpinner />
      )}

      <ReportIssue setOpenDialog={setOpenReportIssue} openDialog={openReportIssue} />
      <UpdateErrorComponent openError={openError} setOpenError={setOpenError} />
      <LegacyPaymentErrorPopup openError={legacyError} setOpenError={setLegacyError} />
    </Box>
  );
};

export const PaymentMethodsCard = styled(Card)(({ theme }) => ({
  boxShadow: "0px 0px 4px 0px rgb(193, 193, 193)",
  elevation: 0,
  borderRadius: 8,
  margin: isAuthenticated === "TRUE" ? "0" : "0.8rem 0 1.5rem 0",
}));
