import * as React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, IconButton, MenuItem, Select, Stack, Typography, styled } from "@mui/material";
import { useSelector } from "react-redux";
import { addValuesFromString } from "../../../../../Services/helper";

const columns: GridColDef[] = [
  { field: "id", headerName: "Ref #", width: 120, sortable: false, flex: 1 },
  {
    field: "numberCalled",
    headerName: "Number called",
    width: 120,
    sortable: false,
    flex: 1,
  },
  {
    field: "dateTime",
    headerName: "Date and Time",
    width: 160,
    sortable: false,
    flex: 1,
  },
  { field: "place", headerName: "Place", width: 120, sortable: false, flex: 1 },
  { field: "min", headerName: "Min", width: 60, sortable: false, flex: 1 },
  { field: "charge", headerName: "Charge", width: 70, sortable: false, flex: 1 },
];

interface TableData {
  id: string;
  numberCalled: string;
  dateTime: string;
  place: string;
  min: string;
  charge: string;
}

interface PageDetails {
  pageSize: string;
  pageNumber: string;
}

interface DataTableProps {
  tableData: TableData[];
  pageDetails: PageDetails;
  handleChangeRowsPerPage: any;
  handleNext: any;
  handlePrev: any;
  VOIPTotal: any;
}

export default function DataTable({
  tableData,
  pageDetails,
  handleChangeRowsPerPage,
  handleNext,
  handlePrev,
  VOIPTotal,
}: DataTableProps) {
  const rowData = tableData;
  const { VOIPTotalEvents } = useSelector((state: any) => state.customerPlanDetails);
  const { ndsObj } = useSelector((state: any) => state.customerInfoDetails);

  const isNDS = ndsObj?.isNDS;
  const [firstElement] = tableData || [];
  const [lastElement] = tableData ? tableData?.slice(-1) : [];

  const firstElementId = firstElement ? firstElement?.id : null;
  const lastElementId = lastElement ? lastElement?.id : null;
  const addedValues = addValuesFromString(VOIPTotal);

  const CustomPagination = () => {
    return (
      <>
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          width="100%"
          px={{ md: 2 }}
          alignItems={"center"}>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Typography fontSize={{ xs: "12px", md: "16px" }}>Rows per page</Typography>
            <Select
              sx={{
                boxShadow: "none",
                fontSize: { xs: "12px", md: "16px" },
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
              }}
              id="rows-per-page"
              onChange={handleChangeRowsPerPage}
              value={pageDetails.pageSize}>
              <MenuItem value="10">10</MenuItem>
              <MenuItem value="20">20</MenuItem>
              <MenuItem value="30">30</MenuItem>
            </Select>
          </Stack>
          {isNDS ? (
            <Typography fontSize={{ xs: "12px", md: "16px" }}>
              {firstElementId} - {lastElementId} of {addedValues}
            </Typography>
          ) : (
            <Typography fontSize={{ xs: "12px", md: "16px" }}>
              {firstElementId} - {lastElementId}
            </Typography>
          )}
          <Stack direction={"row"} spacing={{ xs: 1, md: 2 }}>
            <IconButton
              id="prev-page"
              onClick={handlePrev}
              disabled={Number(pageDetails.pageNumber) === 1}>
              <KeyboardArrowLeftIcon />
            </IconButton>
            <IconButton
              id="next-page"
              onClick={handleNext}
              disabled={tableData?.length < Number(pageDetails.pageSize)}>
              <KeyboardArrowRightIcon />
            </IconButton>
          </Stack>
        </Stack>
        <div id="data-grid"></div>
      </>
    );
  };

  return (
    <Box
      sx={{
        height: 515,
      }}>
      <VOIPDataGrid
        components={{ Pagination: CustomPagination }}
        rowHeight={40}
        disableColumnMenu
        disableSelectionOnClick
        hideFooterSelectedRowCount
        rows={rowData}
        columns={columns}
      />
    </Box>
  );
}

const VOIPDataGrid = styled(DataGrid)(({ theme }) => ({
  borderRadius: "0px",
  paddingLeft: "5px",
  fontSize: "13px",
  "& .MuiDataGrid-cell": {
    textAlign: "center",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 600,
  },
  "& .MuiDataGrid-row:hover": {
    backgroundColor: "inherit",
  },
}));
