import i18nnext from "i18next";
import english from "./english.json";
import french from "./french.json";
import japanese from "./japanese.json";
import arabic from "./arabic.json";
import { initReactI18next } from "react-i18next";
i18nnext.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  resources: {
    en: english,
    fr: french,
    jp: japanese,
    ar: arabic,
  },
  debug: true,
  ns: ["translation"],
  defaultNS: "translation",

  keySeparator: false,

  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
  },

  detection: {
    lookupQuerystring: "lang",
  },
});

export default i18nnext;
