import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { CenterStack, RowCenterStack, SpacerMedium, SpacerSmall } from "../../Styles/GlobalStyles";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import {
  ERROR_PAGE_CONTENT,
  ERROR_PAGE_HEADING,
  ERROR_PAGE_SUBCONTENT,
  ERROR_PAGE_SUBHEADING,
  GO_TO_HOME,
  GO_TO_LOGIN,
} from "../../Constants/constants";

export default function ErrorPage({ user, data, handleLogOut }: any) {
  const navigate = useNavigate();
  const [displayContent, setDisplayContent] = useState<any>({
    heading: ERROR_PAGE_HEADING,
    subHeading: ERROR_PAGE_SUBHEADING,
    content: ERROR_PAGE_CONTENT,
    subContent: ERROR_PAGE_SUBCONTENT,
    primaryBtnTitle: user === null ? GO_TO_LOGIN : GO_TO_HOME,
  });

  const handleNavigate = () => {
    user !== null && displayContent?.primaryBtnTitle !== "LOG OUT"
      ? navigate("/home")
      : user !== null && displayContent?.primaryBtnTitle === "LOG OUT"
      ? handleLogOut()
      : navigate("/login");
  };

  useEffect(() => {
    data && setDisplayContent(data);
  }, [data]);

  return (
    <Box py={26} px={{ xs: 2, md: 0 }}>
      <CenterStack direction={"column"} width={{ xs: "100%" }}>
        <Typography color={"rgb(0,0,80)"} fontWeight={"bold"} variant="h2">
          {displayContent?.heading}
        </Typography>
        <Typography color={"rgb(0,0,80)"} fontSize={{ xs: "30px", md: "50px" }}>
          {displayContent?.subHeading}
        </Typography>
        <SpacerMedium />
        <Typography>{displayContent?.content}</Typography>
        <Typography>{displayContent?.subContent}</Typography>
        <SpacerSmall />
        <SpacerMedium />
        <RowCenterStack gap={4}>
          {displayContent?.secondaryBtnTitle && (
            <Button
              onClick={() => navigate("/home")}
              title={displayContent?.secondaryBtnTitle}
              type="pay"
            />
          )}
          {displayContent?.primaryBtnTitle && (
            <Button
              onClick={() => handleNavigate()}
              title={displayContent?.primaryBtnTitle}
              type="root"
            />
          )}
        </RowCenterStack>
      </CenterStack>
    </Box>
  );
}
