import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Theme } from "../../../../Styles/GlobalTheme";
import { getDollarSign, getFlags } from "../../../../Services/helper";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Grid, Stack, Typography, Box, Divider } from "@mui/material";
import { Spinner } from "../../../../components/shared/LoadingSpinner/Spinner";
import { RETRIEVING_PLANS_SERVICES_MESSAGE } from "../../../../Constants/constants";
import { ErrorComponent } from "../../../../components/ErrorComponent/ErrorComponent";
import {
  StyledElement,
  AccountStyledCard,
  AccountDivider,
  LinkTypography,
} from "../../../../Styles/GlobalStyles";

export const AccountPlansServices = (props: any) => {
  const {
    planAndServicesInfo,
    onRefresh,
    plansServicesLoading,
    plansServicesError,
    customerServicesError,
    Internet,
    Phone,
    ordersLoading,
    showCustomerSubscribedServices,
    fallOut,
  } = props;
  const { envFlags } = useSelector((state: any) => state.userInfo);
  const { accountNumber, orderDetails } = useSelector((state: any) => state.customerInfoDetails);
  const navigate = useNavigate();
  const { showAutopay } = useSelector((state: any) => state.customerPlanDetails);

  const showOrderUpgrades = getFlags("show_upgradeorder", envFlags);
  const hasOpenOrder = orderDetails && Object.keys(orderDetails)?.length > 0;
  const isDisconnectedOrder =
    orderDetails &&
    Object.keys(orderDetails)?.length > 0 &&
    orderDetails?.orderType === "disconnect";

  const showUpgradeButton =
    showOrderUpgrades && !fallOut && !hasOpenOrder && isDisconnectedOrder === false && showAutopay;

  const handleNavigateToUpgrade = () => {
    navigate("/account/upgrade-speed");
  };

  function handleNutritionLabelRedirect() {
    navigate("/account/nutritionlabel");
  }

  const hyphenSeperatedAccNum = (item: any) => {
    if (item === null) {
      return "-";
    } else {
      return item?.replace(/^(\d{3})(\d{3})(\d{4})(\d{6})(\d)$/, "$1-$2-$3-$4-$5");
    }
  };

  return (
    <>
      <AccountStyledCard sx={{ textDecoration: "none" }}>
        <Box>
          {plansServicesLoading || ordersLoading ? (
            <Box my={10}>
              <Spinner loadingText={RETRIEVING_PLANS_SERVICES_MESSAGE} />
            </Box>
          ) : (
            <>
              {planAndServicesInfo &&
                planAndServicesInfo
                  ?.filter((item: any) => item.accountNumber === accountNumber)
                  ?.map((item: any, index: any) => {
                    return (
                      <Grid
                        container
                        key={index}
                        direction="row"
                        padding={"1rem 1.2rem 0.2rem 1.2rem"}
                        rowGap={1}
                        justifyContent={"space-between"}
                        alignItems={"center"}>
                        <Typography
                          fontSize={{ xs: "1rem", md: "1.2rem" }}
                          component={"span"}
                          color={"primary"}
                          fontWeight={600}
                          data-testid="planName">
                          {item?.ziplyPlanName === null ? "-" : item?.ziplyPlanName}
                        </Typography>
                        <Stack gap={1}>
                          {item?.amount === null ? (
                            <Typography variant="h5" component={"span"}>
                              -
                            </Typography>
                          ) : (
                            <>
                              <Typography
                                variant="h5"
                                fontSize={{ xs: "1rem", md: "1.2rem" }}
                                fontWeight={"600"}
                                component={"span"}
                                data-testid="amount">
                                {getDollarSign(item?.amount)}/mo
                              </Typography>
                            </>
                          )}
                        </Stack>
                        <Stack>
                          <Typography
                            component={"span"}
                            fontSize={{ xs: "14px", md: "16px" }}
                            color={Theme.palette.customcolor.profileTypoGraphyColor}>
                            Account number
                          </Typography>
                          <Stack
                            direction="row"
                            spacing={3}
                            alignItems={"center"}
                            flexWrap={"wrap"}
                            justifyContent={"space-between"}
                            pt={1}>
                            <Typography component={"span"} fontSize={{ xs: "12px", md: "14px" }}>
                              {hyphenSeperatedAccNum(item?.accountNumber)}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack>
                          {!item?.disableNutritionLabel && (
                            <StyledElement
                              onClick={() => handleNutritionLabelRedirect()}
                              fontSize={{ xs: "12px", md: "14px" }}
                              color={Theme.palette.secondary.main}>
                              VIEW NUTRITION LABEL
                            </StyledElement>
                          )}
                        </Stack>

                        {showCustomerSubscribedServices &&
                          (Internet || Phone) &&
                          !customerServicesError && (
                            <>
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                              <Stack
                                my={1}
                                mb={2}
                                direction={"row"}
                                alignItems={"center"}
                                spacing={1}>
                                <Typography fontSize={{ xs: "14px", md: "16px" }}>
                                  You are subscribed to the following services :
                                </Typography>
                                <Stack direction={"row"} alignItems={"center"}>
                                  {Internet && (
                                    <Typography
                                      fontWeight={600}
                                      fontSize={{ xs: "14px", md: "16px" }}
                                      color={Theme.palette.primary.main}>
                                      {Internet}
                                      {Phone && ", "}
                                    </Typography>
                                  )}
                                  {Phone && (
                                    <Typography
                                      fontWeight={600}
                                      fontSize={{ xs: "14px", md: "16px" }}
                                      color={Theme.palette.primary.main}>
                                      {Phone}
                                    </Typography>
                                  )}
                                </Stack>
                              </Stack>
                            </>
                          )}
                        {showUpgradeButton && (
                          <>
                            {(item?.fiberToFiber || item?.copperToFiber) && (
                              <>
                                <Grid item xs={12}>
                                  <Divider />
                                </Grid>
                                <Grid item xs={12} my={2}>
                                  <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    width={"100%"}
                                    justifyContent={"space-between"}
                                    onClick={() => handleNavigateToUpgrade()}
                                    sx={{ cursor: "pointer" }}>
                                    <LinkTypography>UPGRADE MY SPEED</LinkTypography>
                                    <StyledElement>
                                      <ArrowForwardIosIcon style={{ height: "16px" }} />
                                    </StyledElement>
                                  </Stack>
                                </Grid>
                              </>
                            )}
                          </>
                        )}
                      </Grid>
                    );
                  })}
            </>
          )}
          {plansServicesError && !plansServicesLoading && <ErrorComponent onRefresh={onRefresh} />}
        </Box>
        {plansServicesLoading && <AccountDivider />}
      </AccountStyledCard>
    </>
  );
};
