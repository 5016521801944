import { useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { RG_STATUS } from "../../Pages/Home/Components/NetworkStatus/query";
import {
  setRGStatusApiError,
  setRGStatusApiErrorMsg,
  setRGStatusDetails,
} from "../../Redux/Slices/NetworkStatusSlice";
import { ROUTER_CONNECTION_FAILURE_TEXT } from "../../Constants/constants";

export const useRGStatus = (accountNumber: any) => {
  const dispatch = useDispatch();

  const [getRGStatus, { loading: rgStatusLoading }] = useLazyQuery(RG_STATUS, {
    variables: {
      accountNumber: accountNumber,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      dispatch(setRGStatusDetails(data?.rgStatus?.statusDetails));
      dispatch(setRGStatusApiError(false));
    },
    onError: () => {
      dispatch(setRGStatusApiError(true));
      dispatch(setRGStatusApiErrorMsg(ROUTER_CONNECTION_FAILURE_TEXT));
      dispatch(setRGStatusDetails({}));
    },
  });

  return {
    getRGStatus,
    rgStatusLoading,
  };
};
