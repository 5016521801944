import { Box, Grid, Stack, Typography } from "@mui/material";
import { CustomAccountCard } from "../../../../components/shared/Account/CustomAccountCard/CustomAccountCard";
import { useEffect, useState } from "react";
import { AuthorizedUserComponent } from "../../../../components/shared/Account/AuthorizedUserComponent/AuthorizedUserComponent";
import { useSelector } from "react-redux";
import {
  AccountDivider,
  AccountStyledCard,
  LinkAccountBox,
  PaymentBox,
  RowStack,
  StyledElement,
  StyleAccordian,
  StyleAccordionSummary,
  CardHeading,
  PTypography,
} from "../../../../Styles/GlobalStyles";
import { LinkedAccount } from "../../../../components/shared/Account/LinkedAccount/LinkedAccount";
import { Theme } from "../../../../Styles/GlobalTheme";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ErrorComponent } from "../../../../components/ErrorComponent/ErrorComponent";
import Button from "../../../../components/Button/Button";
import { useGetCommercialAccount } from "../../../../hooks/Accounts/useGetCommercial";
import { Spinner } from "../../../../components/shared/LoadingSpinner/Spinner";
import { useGlobysURL } from "../../../../hooks/Login/useGlobysURL";
import { getFlags } from "../../../../Services/helper";

export interface CardI {
  name?: string;
  accountNumber?: string;
  pin?: string;
  selected?: boolean;
  isActive?: boolean;
  currentDueBalance?: any;
}

export interface AccountBoxGridProps {
  color?: string;
  textDeco?: string;
}

export const KeyAccountInfo = ({ customerInfoLoading }: any) => {
  const customerDetails = useSelector((state: any) => state.customerInfoDetails);
  const [isAuthorised, setIsAuthorised] = useState<boolean>(false);
  const [isLinkAnotherAccOpen, setIsLinkAnotherAccOpen] = useState<boolean>(false);
  const { email } = useSelector((state: any) => state.userInfo);
  const globysId = localStorage.getItem("globysId");
  const isMultiAcc = sessionStorage.getItem("isMultiAccount");
  const [isAccordionExpanded, setIsAccordionExpanded] = useState<boolean>(false);

  const envFlags = useSelector((state: any) => state?.userInfo?.envFlags);
  const INACTIVE_ACCOUNTS = getFlags("inactive_accounts", envFlags);

  const activeAccounts = customerDetails?.linkedAccountNumbers?.filter(
    (account: any) =>
      !INACTIVE_ACCOUNTS || account?.isActive !== false || account?.isActive === null
  );

  const inactiveAccounts = INACTIVE_ACCOUNTS
    ? customerDetails?.linkedAccountNumbers?.filter((account: any) => account?.isActive === false)
    : [];

  const mergedArray = customerDetails?.inactiveAccounts?.map((acc: any) => {
    const accountInfo = inactiveAccounts?.find(
      (info: any) => info?.accountNumber === acc?.accountNumber
    );
    return {
      ...acc,
      ...accountInfo,
    };
  });

  const { getCommercialAccount, commercialAccountLoading } = useGetCommercialAccount();
  const { getGlobysURL } = useGlobysURL(email, globysId ?? "");

  const handleGlobysUrl = () => {
    getGlobysURL();
  };

  const accountNumber = customerDetails && customerDetails?.accountNumber;
  const hyphenSeperatedAccNum = accountNumber?.replace(
    /^(\d{3})(\d{3})(\d{4})(\d{6})(\d)$/,
    "$1-$2-$3-$4-$5"
  );

  const filterPin = customerDetails?.linkedAccountNumbers?.filter(
    (item: any) => item?.accountNumber === customerDetails?.accountNumber
  );

  const handleAuthorised = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsAuthorised(!isAuthorised);
  };

  const handleLinkAnotherAccount = () => {
    setIsLinkAnotherAccOpen(true);
  };

  const handleAccordion = () => {
    setIsAccordionExpanded(!isAccordionExpanded);
    if (isLinkAnotherAccOpen) {
      setIsLinkAnotherAccOpen(!isLinkAnotherAccOpen);
    }
  };

  return (
    <>
      {Object.keys(customerDetails).length === 0 ? (
        <AccountStyledCard>
          <ErrorComponent />
          <AccountDivider />
          <StyleAccordian disableGutters elevation={0}>
            <StyleAccordionSummary
              expandIcon={
                <StyledElement>
                  <ExpandMoreIcon />
                </StyledElement>
              }>
              <StyledElement fontSize={{ xs: "0.8rem", sm: "1rem" }}>
                MANAGE AUTHORISED USER
              </StyledElement>
            </StyleAccordionSummary>
            <AuthorizedUserComponent />
          </StyleAccordian>
        </AccountStyledCard>
      ) : customerDetails?.linkedAccountNumbers?.length > 0 && !customerInfoLoading ? (
        <AccountStyledCard>
          <RowStack>
            <Box>
              <PTypography color={Theme.palette.customcolor.profileTypoGraphyColor}>
                Account number
              </PTypography>
              <PTypography data-testid="current-acc">{hyphenSeperatedAccNum}</PTypography>
            </Box>
            <Box>
              <PTypography color={Theme.palette.customcolor.profileTypoGraphyColor}>
                PIN
              </PTypography>
              <PTypography data-testid="current-pin">{filterPin?.[0]?.pin}</PTypography>
            </Box>
          </RowStack>
          <AccountDivider />

          <StyleAccordian disableGutters elevation={0}>
            <StyleAccordionSummary
              onClick={handleAccordion}
              expandIcon={
                <StyledElement>
                  <ExpandMoreIcon />
                </StyledElement>
              }>
              <StyledElement fontSize={{ xs: "0.8rem", sm: "1rem" }}>LINKED ACCOUNTS</StyledElement>
            </StyleAccordionSummary>

            {!isLinkAnotherAccOpen && isAccordionExpanded && (
              <>
                <PaymentBox py={6} margin={!isMultiAcc ? "1rem 0 1.5rem 0" : "1rem 0 0 0"}>
                  <Grid container justifyContent={"space-between"} flexWrap={"wrap"}>
                    {activeAccounts &&
                      activeAccounts?.map((card: any) => {
                        return (
                          <Grid item xs={12} sm={5.8} key={card?.accountNumber}>
                            <CustomAccountCard key={card?.accountNumber} card={card} />
                          </Grid>
                        );
                      })}
                  </Grid>
                  <Stack
                    justifyContent={"space-between"}
                    my={2}
                    gap={1}
                    alignItems={"flex-start"}
                    direction={{ sm: "row", xs: "column" }}>
                    <Button
                      onClick={handleLinkAnotherAccount}
                      title="LINK A NEW ACCOUNT"
                      type="root"
                    />
                  </Stack>
                  {INACTIVE_ACCOUNTS && inactiveAccounts?.length > 0 && (
                    <>
                      <AccountDivider />
                      <Stack my={2}>
                        <Typography variant="h6" fontSize="1.3rem">
                          Disconnected or Inactive Accounts
                        </Typography>
                        <Typography
                          color={Theme.palette.customcolor.profileTypoGraphyColor}
                          fontSize={{ xs: "14px", sm: "16px" }}>
                          Inactive or disconnected accounts, these account don't have a active plan
                          and have limited services.
                        </Typography>
                      </Stack>
                      <Grid container justifyContent={"space-between"} flexWrap={"wrap"}>
                        {mergedArray &&
                          mergedArray?.map((card: any) => {
                            return (
                              <Grid item xs={12} sm={5.8} key={card?.accountNumber}>
                                <CustomAccountCard
                                  key={card?.accountNumber}
                                  card={card}
                                  inactiveAccount={inactiveAccounts?.length > 0 ? true : false}
                                />
                              </Grid>
                            );
                          })}
                      </Grid>
                    </>
                  )}
                </PaymentBox>
                <AccountDivider />
                {isMultiAcc && (
                  <PaymentBox py={6} mb={"1.5rem"}>
                    <Stack
                      justifyContent={"space-between"}
                      mb={0.8}
                      gap={1}
                      alignItems={"flex-start"}
                      direction={{ sm: "row", xs: "column" }}>
                      <CardHeading>Business accounts</CardHeading>
                    </Stack>
                    <Grid container justifyContent={"space-between"} flexWrap={"wrap"}>
                      {commercialAccountLoading ? (
                        <Grid item xs={12}>
                          <Box my={14}>
                            <Spinner />
                          </Box>
                        </Grid>
                      ) : customerDetails?.commercialAccApiError ? (
                        <>
                          <Grid item xs={12}>
                            <ErrorComponent onRefresh={getCommercialAccount} />
                          </Grid>
                        </>
                      ) : (
                        customerDetails?.businessAccountDetails?.accountNumber && (
                          <Grid item xs={12} sm={5.8}>
                            <CustomAccountCard
                              isBusiness={true}
                              key={customerDetails?.businessAccountDetails?.accountNumber}
                              card={customerDetails?.businessAccountDetails}
                              handleGlobysUrl={handleGlobysUrl}
                            />
                          </Grid>
                        )
                      )}
                    </Grid>
                  </PaymentBox>
                )}
              </>
            )}
          </StyleAccordian>

          {isLinkAnotherAccOpen ? (
            <>
              <LinkedAccount
                isLinkAnotherAccOpen={isLinkAnotherAccOpen}
                setIsAuthorised={setIsAuthorised}
                isAuthorised={isAuthorised}
                setIsLinkAnotherAccOpen={setIsLinkAnotherAccOpen}
              />
            </>
          ) : (
            <>
              <AccountDivider />
              <StyleAccordian disableGutters elevation={0}>
                <StyleAccordionSummary
                  onClick={handleAuthorised}
                  expandIcon={
                    <StyledElement>
                      <ExpandMoreIcon />
                    </StyledElement>
                  }>
                  <StyledElement fontSize={{ xs: "0.8rem", sm: "1rem" }}>
                    MANAGE AUTHORIZED USERS
                  </StyledElement>
                </StyleAccordionSummary>
                {isAuthorised && <AuthorizedUserComponent />}
              </StyleAccordian>
            </>
          )}
        </AccountStyledCard>
      ) : (
        !customerInfoLoading && (
          <LinkAccountBox bgcolor={Theme.palette.customcolor.accordionbackground}>
            <LinkedAccount setIsLinkAnotherAccOpen={setIsLinkAnotherAccOpen} />
          </LinkAccountBox>
        )
      )}
    </>
  );
};
