import { useLocation } from "react-router-dom";
import { NavigateLink, StyledDivBar, StyledNavLinks } from "../../Styles/GlobalStyles";
import { Theme } from "../../Styles/GlobalTheme";
import { useSelector } from "react-redux";
import { getFlags } from "../../Services/helper";

export type NavProps = {
  name: string;
  link: string;
  icon?: any;
};

export interface NavBarProps {
  navLinks: NavProps[];
}

export interface ActiveTabProp {
  active?: number;
}

export const NavList = ({ navLinks }: NavBarProps) => {
  const { pathname } = useLocation();
  const { activeAccount } = useSelector((state: any) => state?.customerInfoDetails);
  const envFlags = useSelector((state: any) => state?.userInfo?.envFlags);
  const INACTIVE_ACCOUNTS = getFlags("inactive_accounts", envFlags);

  return (
    <>
      {navLinks?.map(({ name, link, icon }) => {
        const isDisabled =
          INACTIVE_ACCOUNTS && name.toLowerCase() === "messages" && activeAccount === false;

        const content = (
          <>
            <StyledNavLinks>{name}</StyledNavLinks>
            {pathname === link || pathname?.includes(link) ? <StyledDivBar></StyledDivBar> : null}
          </>
        );

        if (isDisabled) {
          return (
            <StyledNavLinks style={{ opacity: 0.5, cursor: "not-allowed" }} key={name}>
              {content}
            </StyledNavLinks>
          );
        }

        return (
          <NavigateLink
            to={link}
            key={name}
            active={pathname === link || pathname?.includes(link) ? +true : undefined}>
            {content}
          </NavigateLink>
        );
      })}
    </>
  );
};
