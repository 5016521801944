import Button from "../../Button/Button";
import { useEffect, useState } from "react";
import { Theme } from "../../../Styles/GlobalTheme";
import { Spinner } from "../LoadingSpinner/Spinner";
import { useSelector, useDispatch } from "react-redux";
import { useLazyQuery, useMutation } from "@apollo/client";
import { getFlags, paymentModifiedDate } from "../../../Services/helper";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import { GET_PAYMENT_METHODS } from "../../../Pages/Billing/query";
import { ErrorComponent } from "../../ErrorComponent/ErrorComponent";
import { DELETE_PAYMENT_METHOD } from "../../../Pages/Account/query";
import { ConfirmationModel } from "../ConfirmationModel/ConfirmationModel";
import { EmptyDataComponent } from "../EmptyDataComponent/EmptyDataComponent";
import { LinkingWaitMessage } from "../LinkingWaitMessage/LinkingWaitMessage";
import { SuccessMessageModel } from "../SuccessMessageModel/SuccessMessageModel";
import { UpdateErrorComponent } from "../UpdateErrorComponent/UpdateErrorComponent";
import { GET_PAYMENT_METHOD_URL, MAKE_A_PAYMENT } from "../../../Pages/Payment/query";
import PaymentIframe from "../../../Pages/Billing/Components/MakeAPayment/PaymentIframe";
import { Stack, Box, Typography, FormControl, RadioGroup, SvgIcon } from "@mui/material";
import { AutoPayDivider, PaymentBox, RowCenterStack } from "../../../Styles/GlobalStyles";
import {
  setApiFailureName,
  setCbstAction,
  setError,
  setIframeURL,
  setPaymentMethodsError,
  setRequestIdForGetUrl,
} from "../../../Redux/Slices/PaymentSlice";
import { ReactComponent as masterCardIcon } from "../../../assets/icons/mc_symbol (1).svg";
import { ReactComponent as bankAccountIcon } from "../../../assets/icons/online banking.svg";
import { ReactComponent as genericPayment } from "../../../assets/icons/generic payment.svg";
import { NewCardOrBank } from "../../../Pages/Billing/Components/NewCardOrBank/NewCardOrBank";
import { ReactComponent as visaCardIcon } from "../../../assets/icons/Visa_Brandmark_Blue_RGB_2021-2.svg";
import {
  CANCELLED,
  CANCEL_PAYMENT,
  DELETE_PAYMENT_CBST_ACTION,
  DELETE_PAYMENT_MESSAGE,
  EMPTY_PAYMENT_METHODS,
  FETCHING_PAYMENT_METHODS_MESSAGE,
  GET_PAYMENT_METHODS_CBST_ACTION,
  LANGUAGE,
  MAKE_PAYMENT_CBST_ACTION,
  MY_ACCOUNT,
  NOT_FOUND,
  PAYMENT_DESCRIPTION,
  PAYMENT_METHODS_SECURITY_NOTICE,
  PAYMENT_TYPE,
  REMOVE_AUTOPAY_DATA_PRIMARY_CONTENT,
  REMOVE_AUTOPAY_DATA_SECONDARY_CONTENT,
  REMOVE_AUTOPAY_DATA_TERTIARYCONTENT,
  REMOVE_PAYMENT_METHOD_HEADING,
  REMOVE_PAYMENT_METHOD_PRIMARYCONTENT,
  REPORT_SERVICE_ISSUE_ERROR,
  SUCCESS_PAYMENT_MESSAGE,
  TOKENIZED_BANK,
  TOKENIZED_CREDIT,
  UUID_ERROR_MSG_ADD_PAYMENT,
  currentDate,
  isAuthenticated,
  masterCard,
  visa,
} from "../../../Constants/constants";
import ReportIssue from "../ReportIssue/ReportIssue";
import { LegacyPaymentErrorPopup } from "../UpdateErrorComponent/LegacyPaymentErrorPopup";

export const CustomModal = ({ addPayment, setAddPayment }: any) => {
  const dispatch = useDispatch();

  const userDetails = useSelector((state: any) => state.userInfo);
  const customerDetails = useSelector((state: any) => state?.customerInfoDetails);
  const { autoPayData } = useSelector((state: any) => state?.autopayPaperlessDetails);
  const { paymentsMigrationToNDS } = useSelector((state: any) => state.customerPlanDetails);
  const { iframeURL, paymentMethodsError, requestId } = useSelector(
    (state: any) => state.paymentDetails
  );

  const [openReportIssue, setOpenReportIssue] = useState(false);
  const [remove, setRemove] = useState<boolean>(false);
  const [userStatus, setUserStatus] = useState<string>("");
  const [openError, setOpenError] = useState<boolean>(false);
  const [paymentInput, setPaymentInput] = useState<string>("");
  const [paymentsAPIError, setPaymentsAPIError] = useState(false);
  const [autoPayEnabled, setAutoPayEnabled] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>({});
  const [legacyError, setLegacyError] = useState<boolean>(false);
  const { givenNameFamilyName } = useSelector((state: any) => state.customerInfoDetails);
  const envFlags = useSelector((state: any) => state?.userInfo?.envFlags);
  const SHOW_REPORT_ISSUE = getFlags("report_service_issue", envFlags);

  const [paymentCardData, setPaymentCardData] = useState({
    cardName: "",
    id: "",
  });
  const [dialogData, setDialogData] = useState<any>({
    heading: "",
    primaryContent: "",
    secondaryContent: "",
    tertiaryContent: "",
    containedButton: { onClick: () => {}, buttonType: "", buttonTitle: "", eventName: "" },
  });
  const [getPaymentMethods, { loading: paymentMethodsLoading, data: PaymentMethodsData }] =
    useLazyQuery(GET_PAYMENT_METHODS, {
      variables: {
        accountNumber: customerDetails?.accountNumber,
      },
      onError: (error: any) => {
        if (error.networkError.result.errors[0]?.message?.toLowerCase()?.includes(NOT_FOUND)) {
          dispatch(setPaymentMethodsError(true));
        } else {
          dispatch(setPaymentMethodsError(false));
          dispatch(setError(error.networkError.result.errors[0]));
          dispatch(setApiFailureName("getPaymentMethodsForManagePayments"));
          dispatch(setCbstAction(GET_PAYMENT_METHODS_CBST_ACTION));
          setPaymentsAPIError(true);
          dispatch(setRequestIdForGetUrl(requestId));
        }
      },
      onCompleted: () => {
        dispatch(setPaymentMethodsError(false));
        setOpenReportIssue(false);
        setPaymentsAPIError(false);
        dispatch(setRequestIdForGetUrl(requestId));
      },
      fetchPolicy: "network-only",
    });

  const [getPaymentMethodURL, { loading: URLLoading }] = useMutation(GET_PAYMENT_METHOD_URL, {
    variables: {
      accountNumber: customerDetails?.accountNumber,
      addPaymentMethodInput: {
        isNds: customerDetails?.ndsObj?.isNDS || paymentsMigrationToNDS,
        paymentType: paymentInput,
        billingName: `${givenNameFamilyName?.givenName ?? ""} ${
          givenNameFamilyName?.familyName ?? ""
        }`,
        email: userDetails?.email,
        language: LANGUAGE,
        completePayment: false,
        cancelURL: `${window.location.href}?status=failure`,
        successURL: `${window.location.href}?status=success`,
      },
    },
    fetchPolicy: "network-only",
    onCompleted: (URLdata) => {
      dispatch(setIframeURL(URLdata?.getAddPaymentMethodURL?.url));
      setOpenError(URLdata?.getAddPaymentMethodURL?.error);
    },
    onError: (error: any) => {
      dispatch(setError(error?.networkError?.result?.errors?.[0]));
      dispatch(setApiFailureName("getPaymentMethodURL"));
      handleReportIssueError();
    },
  });
  const handleReportIssueError = () => {
    SHOW_REPORT_ISSUE ? setOpenReportIssue(true) : setOpenError(true);
  };

  const [makeAPayment, { loading: paymentLoading }] = useMutation(MAKE_A_PAYMENT, {
    onError: (error: any) => {
      handleReportIssueError();
      dispatch(setError(error.networkError.result.errors[0]));
      dispatch(setApiFailureName("makeAPayment"));
      dispatch(setCbstAction(MAKE_PAYMENT_CBST_ACTION));
    },
  });
  const isNDS = customerDetails?.ndsObj?.isNDS || paymentsMigrationToNDS;
  const handleSavePayment = () => {
    !isNDS ? setLegacyError(true) : isNDS !== undefined && getPaymentMethodURL();
  };
  const [deletePaymentMethod, { loading: deletePaymentLoading }] = useMutation(
    DELETE_PAYMENT_METHOD,
    {
      onError: (error: any) => {
        dispatch(setError(error.networkError.result.errors[0]));
        dispatch(setApiFailureName("deletePaymentMethod"));
        dispatch(setCbstAction(DELETE_PAYMENT_CBST_ACTION));
        handleReportIssueError();
      },
    }
  );

  const paymentMethods = PaymentMethodsData?.paymentMethods?.paymentDetails;

  const handleClose = () => {
    setRemove(false);
    setAddPayment(false);
  };

  const handleDeleteCard = async () => {
    setRemove(false);
    try {
      const response = await deletePaymentMethod({
        variables: {
          paymentMethodId: paymentCardData?.id,
          accountNumber: customerDetails?.accountNumber,
        },
      });
      if (response?.data?.deletePaymentMethod?.error) {
        handleReportIssueError();
      } else if (response?.data) {
        getPaymentMethods();
        handleClose();
        setSuccessMessage(true);
        setUserStatus(DELETE_PAYMENT_MESSAGE);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const loader = URLLoading || paymentLoading || deletePaymentLoading;

  const handleDelete = (item: any) => {
    const paymentcard = PaymentMethodsData?.paymentMethods?.paymentDetails?.find(
      (card: any) => card?.id === item?.id
    );
    setPaymentCardData({
      cardName: paymentcard.nameOnTheCard,
      id: paymentcard.id,
    });
    if (autoPayData?.paymentMethodName === paymentcard.nameOnTheCard) {
      setDialogData({
        ...dialogData,
        heading: "",
        primaryContent: REMOVE_AUTOPAY_DATA_PRIMARY_CONTENT,
        secondaryContent: REMOVE_AUTOPAY_DATA_SECONDARY_CONTENT,
        tertiaryContent: REMOVE_AUTOPAY_DATA_TERTIARYCONTENT,
        containedButton: null,
      });
      setAutoPayEnabled(true);
    } else {
      setDialogData({
        ...dialogData,
        heading: REMOVE_PAYMENT_METHOD_HEADING,
        primaryContent: REMOVE_PAYMENT_METHOD_PRIMARYCONTENT,
        secondaryContent: "",
        tertiaryContent: "",
        containedButton: {
          ...dialogData?.containedButton,
          buttonType: "payment",
          buttonTitle: "YES, REMOVE",
          eventName: "REMOVE_PAYMENT_METHOD",
        },
      });
      setRemove(true);
    }
  };

  const handleRefresh = () => {
    getPaymentMethods();
  };

  useEffect(() => {
    isAuthenticated === "FALSE" && customerDetails?.accountNumber && getPaymentMethods();
  }, [customerDetails?.accountNumber]);

  const handleSuccess = (data: any, uid: any) => {
    if (customerDetails?.ndsObj?.isNDS || paymentsMigrationToNDS) {
      handleComplete(data?.content?.uid ? data?.content?.uid : uid);
    } else {
      setAddPayment(false);
      setSuccessMessage(true);
      setUserStatus(SUCCESS_PAYMENT_MESSAGE);
      getPaymentMethods();
      dispatch(setIframeURL(""));
    }
  };

  const handleComplete = async (uid: any) => {
    try {
      const response = await makeAPayment({
        variables: {
          accountNumber: customerDetails?.accountNumber,
          makeAPaymentInput: {
            isNds: customerDetails?.ndsObj?.isNDS,
            id: uid,
            paymentType: PAYMENT_TYPE,
            paymentMethodType: paymentInput,
            date: paymentModifiedDate(currentDate),
            description: PAYMENT_DESCRIPTION,
            savePaymentInfo: true,
            completePayment: false,
          },
        },
      });
      if (response?.data?.makeAPayment?.error) {
        handleReportIssueError();
      } else if (response?.data) {
        setAddPayment(false);
        setSuccessMessage(true);
        setUserStatus(SUCCESS_PAYMENT_MESSAGE);
        getPaymentMethods();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddPaymentMethod = () => {
    setAddPayment(true);
    dispatch(setIframeURL(""));
  };

  const handleFailure = (data: any) => {
    if (data?.subject === CANCEL_PAYMENT || data?.status === CANCELLED) {
      dispatch(setIframeURL(""));
    }
  };

  const handleSvgIcon = (item: any) => {
    switch (true) {
      case item?.cardType?.toLowerCase() === masterCard:
        return masterCardIcon;
      case item?.cardType?.toLowerCase() === visa:
        return visaCardIcon;
      case item?.paymentMethodType === TOKENIZED_BANK:
        return bankAccountIcon;
      default:
        return genericPayment;
    }
  };

  useEffect(() => {
    if (!customerDetails?.ndsObj?.isNDS && !paymentsMigrationToNDS) {
      setSelectedPaymentMethod("new payment");
    } else if (customerDetails?.ndsObj?.isNDS || paymentsMigrationToNDS) {
      setSelectedPaymentMethod("new credit/debit");
    }
  }, [customerDetails?.ndsObj?.isNDS, paymentsMigrationToNDS]);

  useEffect(() => {
    if (selectedPaymentMethod === "new payment" || selectedPaymentMethod === "new bank") {
      setPaymentInput(TOKENIZED_BANK);
    } else if (selectedPaymentMethod === "new credit/debit") {
      setPaymentInput(TOKENIZED_CREDIT);
    }
  }, [selectedPaymentMethod]);

  const ERROR = PaymentMethodsData?.paymentMethods?.error === true;

  useEffect(() => {
    setPaymentsAPIError(false);
  }, []);

  return (
    <>
      <PaymentBox>
        {paymentMethodsError ? (
          <Box py={10}>
            <LinkingWaitMessage message={UUID_ERROR_MSG_ADD_PAYMENT} />
          </Box>
        ) : (
          <>
            {addPayment ? (
              <Box sx={{ py: 2 }}>
                <Typography
                  py={1}
                  data-testid={"addmethod"}
                  fontSize={{ xs: "1rem", sm: "1.3125rem" }}>
                  Add payment method
                </Typography>
                <Typography fontSize={"14px"} component={"p"} fontWeight={600}>
                  Select a payment method
                </Typography>
                <FormControl sx={{ my: 2 }} fullWidth>
                  <RadioGroup>
                    <NewCardOrBank
                      value={selectedPaymentMethod}
                      setValue={setSelectedPaymentMethod}
                      setPaymentInput={setPaymentInput}
                    />
                  </RadioGroup>
                  {iframeURL?.length > 0 ? (
                    <PaymentIframe
                      src={iframeURL}
                      handleSuccess={handleSuccess}
                      handleFailure={handleFailure}
                    />
                  ) : (
                    <>
                      <Stack mt={4} justifyContent={"center"} direction={"row"} gap={3}>
                        <Button
                          disabled={iframeURL?.length > 0}
                          onClick={handleClose}
                          type="pay"
                          title="CANCEL"
                        />
                        <Button
                          eventName="ADD_PAYMENT_METHOD"
                          disabled={iframeURL?.length > 0}
                          onClick={handleSavePayment}
                          title="NEXT"
                          type="payment"
                        />
                      </Stack>
                    </>
                  )}
                </FormControl>
              </Box>
            ) : (
              <>
                <RowCenterStack my={2} gap={1}>
                  <Typography
                    color={Theme.palette.customcolor.cardHeading}
                    fontWeight={600}
                    component={"p"}
                    fontSize={{ xs: "1rem", sm: "1.2rem" }}>
                    {!paymentsAPIError && " Your saved cards and bank accounts"}
                  </Typography>

                  <Button onClick={handleAddPaymentMethod} type="root" title="Add payment method" />
                </RowCenterStack>
                {paymentMethodsLoading ? (
                  <Box py={10}>
                    <Spinner loadingText={FETCHING_PAYMENT_METHODS_MESSAGE} />
                  </Box>
                ) : ERROR || paymentsAPIError ? (
                  <ErrorComponent
                    onRefresh={handleRefresh}
                    ShowReportIssue={SHOW_REPORT_ISSUE}
                    errorMessage={REPORT_SERVICE_ISSUE_ERROR}
                    successMessage={successMessage}
                    Managepaymentmethods={true}
                  />
                ) : (
                  <>
                    {isAuthenticated === "TRUE" ? (
                      <Box my={18}>
                        <EmptyDataComponent message={PAYMENT_METHODS_SECURITY_NOTICE} />
                      </Box>
                    ) : paymentMethods?.length === 0 ? (
                      <Box my={18}>
                        <EmptyDataComponent message={EMPTY_PAYMENT_METHODS} />
                      </Box>
                    ) : (
                      <>
                        {paymentMethods?.map((item: any, index: number) => {
                          const isLastChild = index === paymentMethods?.length - 1;
                          return (
                            <>
                              <Stack
                                direction={"row"}
                                alignItems={"center"}
                                my={2}
                                justifyContent={"space-between"}>
                                <RowCenterStack>
                                  <SvgIcon
                                    fontSize={
                                      item?.cardType?.toLowerCase() === visa ? "large" : "small"
                                    }
                                    component={handleSvgIcon(item)}
                                    inheritViewBox
                                  />
                                  <Typography
                                    flexShrink={1}
                                    px={item?.cardType?.toLowerCase() === visa ? 0 : 1}
                                    component={"span"}
                                    fontSize={{ xs: "10px", sm: "14px" }}>
                                    {item?.nameOnTheCard}
                                  </Typography>
                                </RowCenterStack>
                                <Button
                                  type="delete"
                                  onClick={() => handleDelete(item)}
                                  title="REMOVE"
                                />
                              </Stack>
                              {!isLastChild && <AutoPayDivider />}
                            </>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {remove && (
              <ConfirmationModel
                openDialog={remove}
                setOpenDialog={setRemove}
                dialogData={dialogData}
                paymentCardName={paymentCardData?.cardName}
                setDialogData={setDialogData}
                onClick={handleDeleteCard}
              />
            )}
            {autoPayEnabled && (
              <ConfirmationModel
                openDialog={autoPayEnabled}
                setOpenDialog={setAutoPayEnabled}
                dialogData={dialogData}
                setDialogData={setDialogData}
              />
            )}
          </>
        )}
      </PaymentBox>
      {successMessage && (
        <SuccessMessageModel
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          userStatus={userStatus}
        />
      )}
      {loader && <LoadingSpinner />}

      <ReportIssue setOpenDialog={setOpenReportIssue} openDialog={openReportIssue} />
      <UpdateErrorComponent openError={openError} setOpenError={setOpenError} />
      <LegacyPaymentErrorPopup
        openError={legacyError}
        setOpenError={setLegacyError}
        message={"We cannot process payment methods."}
      />
    </>
  );
};
