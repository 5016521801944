import styled from "styled-components";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import VoipBillTable from "./VoipBillTable";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, FormControl, Select, Stack, Typography } from "@mui/material";
import { ColorBar, InformationBox } from "../../../../../Styles/GlobalStyles";
import { Spinner } from "../../../../../components/shared/LoadingSpinner/Spinner";
import {
  BILLING_ERROR,
  FETCHING_VOICE_USAGE_BILLS_MESSAGE,
  INVOICE_ERROR,
} from "../../../../../Constants/constants";
import { ErrorComponent } from "../../../../../components/ErrorComponent/ErrorComponent";
import { GET_BILL_STATEMENTS, VOIP_INVOICES_DETAILS_DPI_CUSTOMER } from "../../../query";
import {
  formatPhoneNumber,
  getDollarSign,
  getMonthAndYear,
  transformVoipDpiInvoices,
} from "../../../../../Services/helper";

interface VOIPDataItem {
  charge: string;
  dateTime: string;
  id: string;
  min: string;
  numberCalled: string;
  place: string;
  __typename: string;
}

export default function VoipBillDPI() {
  const customerDetails = useSelector((state: any) => state.customerInfoDetails);
  const [VOIPData, setVOIPData] = useState<VOIPDataItem[]>([]);
  const [invoices, setInvoices] = useState([]);
  const [selectDate, setSelectDate] = useState<any>({});
  const [VoipDPIError, setVoipDPIError] = useState<boolean>(false);
  const [billStatementError, setBillStatementsError] = useState(false);
  const [pageDetails, setPageDetails] = useState<any>({
    pageNumber: 1,
    pageSize: 10,
  });

  const handleChange = (event: any) => {
    const filteredObj = invoices?.find((item: any) => item?.date === event.target.value);
    setSelectDate(filteredObj);
    setPageDetails({ pageNumber: 1, pageSize: 10 });
  };

  const [getBillStatements, { loading: billStatementsLoading, data: billStatementsData }] =
    useLazyQuery(GET_BILL_STATEMENTS, {
      variables: {
        accountNumber: customerDetails?.accountNumber,
        billsRequired: null,
        isCurrentBill: false,
      },
      fetchPolicy: "network-only",
      onCompleted: (data: any) => {
        if (data) {
          const invoiceData = transformVoipDpiInvoices(data?.billStatements?.billStatements);
          setSelectDate(invoiceData[0]);
          setInvoices(invoiceData);
        }
        setBillStatementsError(false);
      },
      onError: () => setBillStatementsError(true),
    });

  const [getVOIPForDPI, { loading: voipDPILoading, data: voipDPIData }] = useMutation(
    VOIP_INVOICES_DETAILS_DPI_CUSTOMER,
    {
      onError: () => setVoipDPIError(true),
      onCompleted: () => setVoipDPIError(false),
    }
  );

  const tollCharge = voipDPIData?.VOIPInvoiceDetailsForDPICustomer?.tollChargedTo;
  const subTotal = voipDPIData?.VOIPInvoiceDetailsForDPICustomer?.totalEvents;
  const formattedTollCharge = formatPhoneNumber(tollCharge);
  const VoipAmount = getDollarSign(voipDPIData?.VOIPInvoiceDetailsForDPICustomer?.totalAmount);

  const handleChangeRowsPerPage = (event: any) => {
    const newPageSize = Number(event.target.value);
    const currentFirstItem = (pageDetails.pageNumber - 1) * pageDetails.pageSize + 1;
    const newPageNumber = Math.ceil(currentFirstItem / newPageSize);

    setPageDetails({ pageNumber: newPageNumber, pageSize: newPageSize });
  };

  const handlePrev = () => {
    setPageDetails({ ...pageDetails, pageNumber: pageDetails.pageNumber - 1 });
  };

  const handleNext = () => {
    setPageDetails({ ...pageDetails, pageNumber: pageDetails.pageNumber + 1 });
  };

  const detailsNull = VOIPData?.length === 0 && tollCharge === null && subTotal === null;

  const handleVOIPForDPI = async () => {
    try {
      const res = await getVOIPForDPI({
        variables: {
          accountNumber: customerDetails?.accountNumber,
          month: getMonthAndYear(selectDate.date).month,
          year: getMonthAndYear(selectDate.date).year,
          pageNumber: pageDetails.pageNumber,
          pageSize: pageDetails.pageSize,
        },
      });
      if (!res?.data?.VOIPInvoiceDetailsForDPICustomer?.error) {
        if (res?.data?.VOIPInvoiceDetailsForDPICustomer?.eventCharges?.length > 0) {
          const info = res?.data?.VOIPInvoiceDetailsForDPICustomer?.eventCharges?.map(
            (item: any, i: any) => ({
              ...item,
              id: (
                Number(pageDetails.pageNumber) * Number(pageDetails.pageSize) -
                Number(pageDetails.pageSize) +
                i +
                1
              ).toString(),
            })
          );
          setVOIPData(info);
        }
      } else {
        setVoipDPIError(true);
      }
    } catch (err) {
      setVoipDPIError(true);
      console.log(err);
    }
  };

  const handleGetBillStatements = async () => {
    try {
      await getBillStatements();
    } catch (err) {
      setBillStatementsError(true);
      console.log(err);
    }
  };

  useEffect(() => {
    if (selectDate?.date) {
      handleVOIPForDPI();
    }
  }, [selectDate?.date, pageDetails.pageNumber, pageDetails.pageSize]);

  useEffect(() => {
    handleGetBillStatements();
  }, [customerDetails?.accountNumber]);

  return (
    <InformationBox>
      <Box>
        <Box my={1.5}>
          {voipDPILoading || billStatementsLoading ? (
            <Stack justifyContent={"center"} alignItems={"center"} height={"300px"}>
              <Spinner
                loadingText={billStatementsLoading ? FETCHING_VOICE_USAGE_BILLS_MESSAGE : ""}
              />
            </Stack>
          ) : (
            <>
              {invoices?.length > 0 && (
                <Stack
                  direction={"row"}
                  gap={"10px"}
                  alignItems={"center"}
                  justifyContent={"space-between"}>
                  <Typography fontSize={{ xs: "14px", md: "16px" }}>
                    Detailed call history
                  </Typography> 
                  <Box sx={{ width: { xs: 150, md: 200 } }}>
                    <InputLabel id="label">Month</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        labelId="label"
                        id="demo-simple-select"
                        value={selectDate?.date}
                        label="Month"
                        onChange={handleChange}>
                        {invoices?.map((monthName: any, index: number) => {
                          return (
                            <MenuItem key={index} value={monthName?.date}>
                              {monthName?.date}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>
              )}
              {billStatementError && !billStatementsLoading ? (
                <Box my={10}>
                  <ErrorComponent
                    onRefresh={handleGetBillStatements}
                    errorMessage={BILLING_ERROR}
                  />
                </Box>
              ) : VoipDPIError && !voipDPILoading ? (
                <Box my={10}>
                  <ErrorComponent onRefresh={handleVOIPForDPI} errorMessage={INVOICE_ERROR} />
                </Box>
              ) : (!voipDPILoading && detailsNull) || invoices?.length === 0 ? (
                <>
                  <Stack justifyContent={"center"} alignItems={"center"} height={"300px"}>
                    <Typography>
                      It appears that there are currently no usage records available for voice
                      services.
                    </Typography>
                  </Stack>
                </>
              ) : (
                !voipDPILoading &&
                VOIPData && (
                  <>
                    <Stack direction={"row"} spacing={1} mt={{ xs: 2, md: 0 }}>
                      <Typography fontWeight={600} fontSize={{ xs: "14px", md: "16px" }}>
                        Account number :
                      </Typography>
                      <Typography fontSize={{ xs: "14px", md: "16px" }}>
                        {customerDetails?.accountNumber}
                      </Typography>
                    </Stack>
                    <VoipBillStack direction={"row"}>
                      {tollCharge && (
                        <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
                          <Typography fontWeight={600} fontSize={{ xs: "14px", md: "16px" }}>
                            Toll charged to
                          </Typography>
                          <Typography fontSize={{ xs: "14px", md: "16px" }}>
                            {formattedTollCharge}
                          </Typography>
                        </Stack>
                      )}
                      {VoipAmount && (
                        <Stack direction={"row"} spacing={1}>
                          <Typography fontWeight={600} fontSize={{ xs: "14px", md: "16px" }}>
                            Total Amount
                          </Typography>
                          <Typography fontSize={{ xs: "14px", md: "16px" }}>
                            {VoipAmount}
                          </Typography>
                        </Stack>
                      )}
                    </VoipBillStack>

                    <ColorBar />
                    <VoipBillTable
                      tableData={VOIPData}
                      pageDetails={pageDetails}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      handleNext={handleNext}
                      handlePrev={handlePrev}
                      VOIPTotal={""}
                    />
                  </>
                )
              )}
            </>
          )}
        </Box>
      </Box>
    </InformationBox>
  );
}

const VoipBillStack = styled(Stack)(({ theme }) => ({
  padding: "10px 0px",
  justifyContent: "space-between",
  flexWrap: "wrap",
}));
