import React from "react";
import { Divider, Stack, Typography, Box, Grid, useMediaQuery, Theme } from "@mui/material";
import speedDownloadIcon from "../../../../assets/icons/SpeedtestDownloadIcon.svg";
import speedUploadIcon from "../../../../assets/icons/SpeedtestUploadIcon.svg";
import pingIcon from "../../../../assets/icons/PingIcon.svg";
import { useSelector } from "react-redux";
import Button from "../../../../components/Button/Button";
import InfoIcon from "../../../../assets/icons/Information Web.svg";
import { InfoTooltip } from "../../../../Styles/GlobalStyles";
import { LATENCY_INFO } from "../../../../Constants/constants";

const SpeedData = ({
  speedDownload = "--",
  speedUpload = "--",
  ping = "--",
  testDetails,
  hideButton,
  handleRunSpeedTest,
}: any) => {
  const { loadingDownload, speedTestComplete, loadingUpload } = useSelector(
    (state: any) => state.speedTestAnimation
  );

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={4} textAlign="center">
          <Stack spacing={1} alignItems="center">
            <Typography fontWeight={600} fontSize={{ xs: "18px", sm: "20px" }}>
              {speedUpload !== "--" ? speedDownload : "--"}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <img src={speedDownloadIcon} alt="Download Speed" />
              <Typography sx={{ display: "inline-flex", whiteSpace: "nowrap" }}>
                {testDetails?.speedMeasurement ?? "Mbps"} Download
              </Typography>
            </Stack>
          </Stack>
        </Grid>

        {isMobile ? (
          <Divider sx={{ my: 2, width: "100%", ml: 2 }} />
        ) : (
          <Grid
            item
            xs={12}
            sm={1}
            display="flex"
            ml={1.5}
            justifyContent="center"
            sx={{ minHeight: "80px" }}>
            <Divider orientation="vertical" flexItem />
          </Grid>
        )}

        <Grid item xs={12} sm={4} textAlign="center">
          <Stack spacing={1} alignItems="center">
            <Typography fontWeight={600} fontSize={{ xs: "18px", sm: "20px" }}>
              {!ping.includes("--") ? speedUpload : "--"}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <img src={speedUploadIcon} alt="Upload Speed" />
              <Typography sx={{ display: "inline-flex", whiteSpace: "nowrap" }}>
                {testDetails?.speedMeasurement ?? "Mbps"} Upload
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
        <img src={pingIcon} alt="Ping" />
        <Typography>
          Latency: {ping} {testDetails?.latencyMeasurement ?? "ms"}
        </Typography>
        {!(loadingUpload || loadingDownload) && (
          <InfoTooltip title={<span>{LATENCY_INFO}</span>}>
            <img src={InfoIcon} alt="information icon" />
          </InfoTooltip>
        )}
      </Stack>
      {(loadingUpload || loadingDownload) && (
        <Typography
          textAlign={"center"}
          fontSize={{ xs: "10px", sm: "12px" }}
          color={"rgb(137,137,137)"}>
          Latency-How quickly data is transmitting in milliseconds.
        </Typography>
      )}
      {!speedTestComplete && (
        <Box
          mt={3}
          display={loadingDownload || hideButton ? "none" : "flex"}
          justifyContent="center"
          alignItems="center">
          <Button title={"RUN A SPEED TEST"} type="root" onClick={handleRunSpeedTest} />
        </Box>
      )}
    </>
  );
};

export default SpeedData;
