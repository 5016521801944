import { createTheme } from "@mui/material";
declare module "@mui/material/styles" {
  interface Palette {
    customcolor: {
      skyblue: string;
      menuitembackground: string;
      accordionbackground: string;
      notificationunread: string;
      notificationread: string;
      autopaybackground: string;
      accountbackground: string;
      selectbackground: string;
      customText?: string;
      loginCardBackground?: string;
      accordionborder?: string;
      accordionserviceborder?: string;
      cancelbtn?: string;
      cancelbtntext?: string;
      footerTextColor?: string;
      cardHeading?: string;
      disableSwitch?: string;
      emailTextColor?: string;
      profileTypoGraphyColor?: string;
      selectBorder?: string;
      whiteColor?: string;
      bannerBackGroundColor?: string;
      errorBackGroundColor?: string;
      navyBlue?: string;
      inProgressColor?: string;
      scheduledColor?: string;
      blackColor?: string;
      parrotgreen?: string;
      lightGreen?: string;
      saveAutoPayPaperColor?: string;
      skyBlue?: string;
    };
  }
  interface PaletteOptions {
    customcolor?: {
      skyblue?: string;
      menuitembackground?: string;
      accordionbackground?: string;
      notificationunread?: string;
      notificationread?: string;
      autopaybackground?: string;
      accountbackground?: string;
      selectbackground?: string;
      customText?: string;
      loginCardBackground?: string;
      accordionborder?: string;
      accordionserviceborder?: string;
      cancelbtn?: string;
      cancelbtntext?: string;
      footerTextColor?: string;
      cardHeading?: string;
      disableSwitch?: string;
      emailTextColor?: string;
      profileTypoGraphyColor?: string;
      selectBorder?: string;
      whiteColor?: string;
      bannerBackGroundColor?: string;
      errorBackGroundColor?: string;
      navyBlue?: string;
      inProgressColor?: string;
      scheduledColor?: string;
      blackColor?: string;
      parrotgreen?: string;
      lightGreen?: string;
      saveAutoPayPaperColor?: string;
      skyBlue?: string;
    };
  }
}

export const Theme = createTheme({
  typography: {
    fontFamily: "DM Sans",
  },
  palette: {
    primary: {
      light: "#009300",
      main: "#008600",
    },
    secondary: {
      light: "#3589e9",
      main: "#026ce3",
      dark: "#9747ff",
    },
    warning: {
      light: "#d2d615",
      main: "#ffc600",
      dark: "#d47d09",
    },
    error: {
      main: "#ba0000",
      light: "#E46F6C",
    },
    customcolor: {
      saveAutoPayPaperColor: "#E5F2FF",
      skyblue: "#67dfff",
      menuitembackground: "#f7f7f7",
      accordionbackground: "#fbfbfb",
      notificationunread: "#e6f1fd",
      notificationread: "#f8f8f8",
      autopaybackground: "#f3f3f3",
      accountbackground: "#fbfbfb",
      selectbackground: "#ececec",
      customText: "rgb(137,137,137)",
      loginCardBackground: "#ecfbff",
      accordionborder: "#d9d9d9",
      accordionserviceborder: "#8080805c",
      cancelbtn: "#e8e8e8",
      cancelbtntext: "#999999",
      footerTextColor: "#494949",
      cardHeading: "#474747",
      disableSwitch: "#c0c0c0",
      emailTextColor: "#000050",
      profileTypoGraphyColor: "#595959",
      selectBorder: "#b4b4b5",
      whiteColor: "#ffffff",
      bannerBackGroundColor: "#F8E192",
      errorBackGroundColor: "#ffcece",
      navyBlue: "#40407c",
      inProgressColor: "#FD8B21",
      scheduledColor: "#00008A",
      blackColor: "#000000",
      parrotgreen: "rgba(201, 255, 222, 0.75)",
      lightGreen: "#a2dd89",
      skyBlue: "#d9e9fb",
    },
  },
});
