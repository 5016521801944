import React from "react";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CenterStack, StyledDialog } from "../../../../Styles/GlobalStyles";
import { useNavigate } from "react-router-dom";

export const ErrorPopup = (props: any) => {
  const {
    openError,
    setOpenError,
    component,
    message = "Something went wrong, please try again later.",
  } = props;
  const navigate = useNavigate();

  const handleClose = () => {
    setOpenError(false);
    if (component != "billing") {
      navigate("/account");
    }
  };

  return (
    <>
      <StyledDialog maxWidth="lg" open={openError}>
        <IconButton
          data-testid="closeButton"
          onClick={handleClose}
          sx={{ alignSelf: "flex-end", padding: "0" }}>
          <CloseIcon />
        </IconButton>

        <CenterStack pb={5} px={5} textAlign={"center"}>
          <Typography component={"p"} fontSize={{ xs: "0.8rem", sm: "1rem" }} mt={2}>
            {message}
          </Typography>
        </CenterStack>
      </StyledDialog>
    </>
  );
};
