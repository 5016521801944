import { gql } from "@apollo/client";

export const getUid = gql`
  query chaseUID {
    chasePaymentUID(
      chasePaymentUIDInput: {
        orderId: "123"
        totalAmount: "0"
        billingName: "kiran"
        formName: "ChaseCreditCardPaymentForm"
        tokenSize: "1"
        transactionType: "accountVerify"
      }
    ) {
      APIStatus
      error
      uid
    }
  }
`;

export const GET_PAYMENT_METHOD_URL = gql`
  mutation getAddpaymentMethodURL(
    $accountNumber: String!
    $addPaymentMethodInput: AddPaymentMethodInput!
  ) {
    getAddPaymentMethodURL(
      accountNumber: $accountNumber
      addPaymentMethodInput: $addPaymentMethodInput
    ) {
      error
      APIStatus
      message
      isNds
      url
    }
  }
`;

export const SCHEDULE_ONE_TIME_PAYMENT = gql`
  mutation scheduleOnetimePayment(
    $accountNumber: String!
    $scheduleOneTimePaymentInput: ScheduleOneTimePaymentInput!
  ) {
    scheduleOneTimePayment(
      accountNumber: $accountNumber
      scheduleOneTimePaymentInput: $scheduleOneTimePaymentInput
    ) {
      error
      APIStatus
      message
    }
  }
`;

export const MAKE_A_PAYMENT = gql`
  mutation makeAPayment($accountNumber: String!, $makeAPaymentInput: PaymentInput!) {
    makeAPayment(accountNumber: $accountNumber, makeAPaymentInput: $makeAPaymentInput) {
      error
      APIStatus
      message
      isNds
      url
    }
  }
`;
