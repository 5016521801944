import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ColorBar, PTypography } from "../../../../../../Styles/GlobalStyles";
import { ColumnCenterStack } from "../../../../../../Styles/GlobalStyles";
import { useLazyQuery } from "@apollo/client";
import { GET_COMPARE_BILLS } from "../../../../query";
import { useSelector } from "react-redux";
import { formatDateWithSuffix } from "../../../../../../Services/helper";
import { ErrorComponent } from "../../../../../../components/ErrorComponent/ErrorComponent";
import { BilltableAccordion } from "../BillTableAccordion/BillTableAccordion";
import { EmptyDataComponent } from "../../../../../../components/shared/EmptyDataComponent/EmptyDataComponent";
import {
  ANALYZING_BILLS_FOR_COMPARISION_MESSAGE,
  COMPARE_BILL_ERROR_MESSAGE,
  NO_BILL_INFORMATION,
} from "../../../../../../Constants/constants";
import { Spinner } from "../../../../../../components/shared/LoadingSpinner/Spinner";

export const CompareBillTable = (props: any) => {
  const { selectedDate } = props;
  const customerDetails = useSelector((state: any) => state.customerInfoDetails);
  const [error, setError] = useState<boolean>(false);

  const [getCompareBill, { loading: compareBillLoading, data: compareBillData }] = useLazyQuery(
    GET_COMPARE_BILLS,
    {
      variables: {
        secondMonth: selectedDate?.[1],
        firstMonth: selectedDate?.[0],
        accountNumber: customerDetails?.accountNumber,
      },
      onError: (compareBillLoading: any) => setError(compareBillLoading),
    }
  );
  const data = compareBillData?.compareBillDetails?.result;
  const parsedData = data && JSON.parse(data);
  const chargeNames =
    parsedData &&
    Object.values(parsedData).reduce((names: any, charges: any) => {
      Object.keys(charges).forEach((chargeName: any) => {
        if (!names.includes(chargeName)) {
          names.push(chargeName);
        }
      });
      return names;
    }, []);

  const hasKeys = (obj: any) => {
    return Object.keys(obj).length > 0;
  };

  const checkValues = () => {
    const result: any = {};

    for (const key in parsedData) {
      if (parsedData.hasOwnProperty(key)) {
        result[key] = hasKeys(parsedData[key]);
      }
    }

    return result;
  };

  const valuesWithKeys = checkValues();

  const selectedDate1 = new Date(selectedDate?.[0]);
  const currentDate = formatDateWithSuffix(selectedDate1);

  const selectedDate2 = new Date(selectedDate?.[1]);
  const previousDate = formatDateWithSuffix(selectedDate2);

  const dates: any = parsedData && Object.keys(parsedData);
  const accountNumber = customerDetails?.accountNumber;
  const hyphenSeperatedAccNum = accountNumber?.replace(
    /^(\d{3})(\d{3})(\d{4})(\d{6})(\d)$/,
    "$1-$2-$3-$4-$5"
  );

  const handleRefresh = () => {
    getCompareBill({ fetchPolicy: "network-only" });
  };
  const EMPTYDATA =
    compareBillData?.compareBillDetails?.result === null &&
    compareBillData?.compareBillDetails?.error === false;

  useEffect(() => {
    if (customerDetails?.accountNumber?.trim()?.length > 0 && selectedDate.length > 0) {
      getCompareBill();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);
  return (
    <>
      {compareBillData?.compareBillDetails?.error || error ? (
        <ErrorComponent onRefresh={handleRefresh} />
      ) : EMPTYDATA ? (
        <Box my={20}>
          <EmptyDataComponent message={NO_BILL_INFORMATION} />
        </Box>
      ) : (
        <>
          {valuesWithKeys && Object.values(valuesWithKeys).every((error: any) => error === true) ? (
            <Box>
              {compareBillLoading ? (
                <Box py={14}>
                  <Spinner loadingText={ANALYZING_BILLS_FOR_COMPARISION_MESSAGE} />
                </Box>
              ) : (
                <>
                  <ColumnCenterStack
                    sx={{ justifyContent: "space-between" }}
                    direction={"row"}
                    spacing={{ xs: 3, sm: 6, md: 10 }}
                    flexWrap={"wrap"}
                    p={{ sm: 1 }}>
                    <Stack
                      direction={"row"}
                      my={2}
                      spacing={2}
                      alignItems="center"
                      justifyContent={{ xs: "space-between", md: "flex-start" }}>
                      <PTypography fontWeight={600} flexShrink={1}>
                        Account number
                      </PTypography>
                      <PTypography flexShrink={2} data-testid="accountNumber">
                        {hyphenSeperatedAccNum}
                      </PTypography>
                    </Stack>
                    <ColumnCenterStack
                      direction={"row"}
                      spacing={{ xs: 3, sm: 6, md: 10 }}
                      flexWrap={"wrap"}>
                      <Typography
                        fontSize={{ xs: "12px", sm: "14px" }}
                        component={"span"}
                        data-testid="currrentDate"
                        dangerouslySetInnerHTML={{ __html: currentDate }}
                      />
                      <Typography
                        fontSize={{ xs: "12px", sm: "14px" }}
                        component={"span"}
                        data-testid="previousDate"
                        dangerouslySetInnerHTML={{ __html: previousDate }}
                      />
                    </ColumnCenterStack>
                  </ColumnCenterStack>
                  <ColorBar />
                  {chargeNames?.map((item: any) => {
                    return (
                      <BilltableAccordion
                        key={item}
                        parsedData={parsedData}
                        dates={dates}
                        item={item}
                      />
                    );
                  })}
                </>
              )}
            </Box>
          ) : (
            <Box py={8}>
              <EmptyDataComponent message={COMPARE_BILL_ERROR_MESSAGE} />
            </Box>
          )}
        </>
      )}
    </>
  );
};
