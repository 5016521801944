import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Box, Grid, Stack } from "@mui/material";
import { SaveAutoPay } from "../SaveAutoPay/SaveAutoPay";
import { StyledCard } from "../../../../Styles/GlobalStyles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { NeedHelpCard } from "../../NeedHelpCard/NeedHelpCard";
import { AccountCard } from "../../../shared/AccountCard/AccountCard";
import { LinkingWaitMessage } from "../../LinkingWaitMessage/LinkingWaitMessage";
import { CardHeading, PageGrid, StickyBox, StyledLink } from "../../../../Styles/GlobalStyles";
import { PaymentMethod } from "../../../../Pages/Billing/Components/PaymentMethod/PaymentMethod";
import { AUTOPAY_SIGNUP_SUCCESS, UUID_ERROR_MSG_AUTOPAY } from "../../../../Constants/constants";
import { setAutopaySuccess } from "../../../../Redux/Slices/OrderUpgradeSlice";
import { UpdateErrorComponent } from "../../UpdateErrorComponent/UpdateErrorComponent";
import { getFlags } from "../../../../Services/helper";

export const SignUpAutoPay = ({ orderUpgrade, handleSubmitOrder }: any) => {
  const location = useLocation();
  const { NavigateToHome } = location.state || {};
  const [save, setSave] = useState<boolean>(false);
  const { scrollPosition } = useSelector((state: any) => state.pageInfo);
  const { uuidErrorFlag } = useSelector((state: any) => state?.autopayPaperlessDetails);
  const [manageData, setManageData] = useState<any>({});
  const [openError, setOpenError] = useState<boolean>(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (orderUpgrade && save) {
      dispatch(setAutopaySuccess(true));
    }
  }, [save]);

  const routeBack = () => {
    return NavigateToHome
      ? "/home"
      : location.pathname === "/billing/signupautopay"
      ? "/billing"
      : "/account";
  };

  return (
    <>
      {save ? (
        <SaveAutoPay title={AUTOPAY_SIGNUP_SUCCESS} orderUpgrade={orderUpgrade} />
      ) : (
        <Box position="relative" mt={1}>
          <PageGrid container sx={{ padding: orderUpgrade ? "0 !important" : "" }}>
            <Grid item xs={12} lg={orderUpgrade ? 12 : 8.2}>
              <>
                {!orderUpgrade && (
                  <StyledLink to={routeBack()}>
                    <Stack alignItems={"center"} direction={"row"}>
                      <ArrowBackIosIcon color="secondary" />
                      <CardHeading>Sign up for autopay</CardHeading>
                    </Stack>
                  </StyledLink>
                )}
                {uuidErrorFlag ? (
                  <StyledCard sx={{ py: 16 }}>
                    <LinkingWaitMessage message={UUID_ERROR_MSG_AUTOPAY} />
                  </StyledCard>
                ) : (
                  <StyledCard>
                    <Box mb={{ sm: "4rem", xs: "2rem" }}>
                      <PaymentMethod
                        setSave={setSave}
                        orderUpgrade={orderUpgrade}
                        manageData={manageData}
                        setManageData={setManageData}
                        handleSubmitOrder={handleSubmitOrder}
                      />
                    </Box>
                  </StyledCard>
                )}
              </>
            </Grid>

            {!orderUpgrade && (
              <>
                <Grid
                  item
                  mt={{ xs: "1rem", lg: "0" }}
                  xs={12}
                  lg={3.5}
                  display={{ xs: "block", lg: scrollPosition > 280 ? "block" : "none" }}>
                  <AccountCard />
                  <NeedHelpCard />
                </Grid>
                <StickyBox display={{ xs: "none", lg: scrollPosition > 100 ? "none" : "block" }}>
                  <AccountCard />
                  <NeedHelpCard />
                </StickyBox>
              </>
            )}
          </PageGrid>
          <UpdateErrorComponent openError={openError} setOpenError={setOpenError} />
        </Box>
      )}
    </>
  );
};
