import CloseIcon from "@mui/icons-material/Close";
import Button from "../../../../components/Button/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useEffect, useState } from "react";
import {
  Box,
  DialogContent,
  IconButton,
  Stack,
  useTheme,
  Grid,
  Card,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import {
  CardHeading,
  ServiceTicketDialogBox,
  StyledCard,
  StyledDialog,
} from "../../../../Styles/GlobalStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { setOpenSelectdates } from "../../../../Redux/Slices/TroubleTicketSlice";
import { useDispatch } from "react-redux";
import { Theme } from "../../../../Styles/GlobalTheme";
import moment from "moment";

const dates = [
  "Wednesday, June 7",
  "Thursday, June 8",
  "Friday, June 9",
  "Monday, June 12",
  "Tuesday, June 13",
  "Wednesday, June 14",
  "Wednesday, June 14",
  "Wednesday, June 14",
  "Wednesday, June 14",
];

const RepairAppointmentDialog = ({
  open,
  availableSlotsForTT,
  setSelectedTimeSlot,
  selectedTimeSlot,
  setSelectedAppointment,
  setSelectedDate,
  selectedDate,
}: any) => {
  const theme = useTheme();
  const [availableAppointments, setAvailableAppointments] = useState<any>({});

 

  const handleSelectAppointment = (appointment: any) => {
    setSelectedDate(appointment);
    if (availableAppointments[appointment].length > 0) {
      let time = availableAppointments[appointment][0];
      const timeRange = `${time?.startTime}-${time?.endTime}`;
      const [startTime, endTime] = timeRange?.split("-");
      const formattedStartTime = moment(startTime, "HH:mm:ss").format("hh:mm A");
      const formattedEndTime = moment(endTime, "HH:mm:ss").format("hh:mm A");
      const formattedTimeRange = `${formattedStartTime} - ${formattedEndTime}`;
      setSelectedTimeSlot(formattedTimeRange);
      setSelectedAppointment(time);
    } else {
      setSelectedTimeSlot("");
      setSelectedAppointment({});
    }
  };

  const handleAppointments = () => {
    const data = availableSlotsForTT?.appointmentSlots;
    const organizedData: any = {};
    data?.forEach((slot: any) => {
      const date = slot?.startDate?.split("T")[0];
      if (!organizedData[date]) {
        organizedData[date] = [];
      }
      organizedData[date].push({
        startDate: slot?.startDate,
        endDate: slot?.endDate,
        startTime: slot.startDate?.split("T")[1]?.replace("Z", ""),
        endTime: slot?.endDate?.split("T")[1]?.replace("Z", ""),
        workUnitsQuantity: slot?.workUnitsQuantity,
      });
    });
    setAvailableAppointments(organizedData);
  };

  useEffect(() => {
    handleAppointments();
  }, [open]);

  return (
    <>
      <Box sx={{ px: 3, mt: 2 }}>
        <Box sx={{ fontSize: "16px", mb: 2 }}>Select date</Box>
        {Object.keys(availableAppointments)?.length > 0 && (
          <>
            <Grid container maxHeight={250} overflow={"auto"}>
              <>
                {Object.keys(availableAppointments)?.map((appointment) => {
                  return (
                    <>
                      <Grid item xs={12} mb={{ xs: "26px", sm: "0px" }} sm={5.8}>
                        <Stack>
                          <StyledCard
                            onClick={() => handleSelectAppointment(appointment)}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              cursor: "pointer",
                              m: 2,
                              border:
                                appointment === selectedDate
                                  ? `2px solid ${Theme.palette.secondary.main}`
                                  : "none",
                            }}>
                            {moment(appointment).format("dddd, MMMM DD")}
                          </StyledCard>
                        </Stack>
                      </Grid>
                    </>
                  );
                })}
              </>
            </Grid>
          </>
        )}

        <FormControl sx={{ my: 4 }}>
          {availableAppointments?.hasOwnProperty(selectedDate) && (
            <>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group">
                {availableAppointments?.[selectedDate]?.map((time: any, idx: any) => {
                  const timeRange = `${time?.startTime}-${time?.endTime}`;
                  const [startTime, endTime] = timeRange?.split("-");
                  const formattedStartTime = moment(startTime, "HH:mm:ss").format("hh:mm A");
                  const formattedEndTime = moment(endTime, "HH:mm:ss").format("hh:mm A");
                  const formattedTimeRange = `${formattedStartTime} - ${formattedEndTime}`;

                  return (
                    <>
                      <FormControlLabel
                        onChange={(e) => {
                          setSelectedTimeSlot(formattedTimeRange);
                          setSelectedAppointment(time);
                        }}
                        value={time?.startTime}
                        checked={selectedTimeSlot === formattedTimeRange}
                        key={idx}
                        control={<Radio />}
                        label={formattedTimeRange}
                      />
                    </>
                  );
                })}
              </RadioGroup>
            </>
          )}
        </FormControl>
      </Box>
    </>
  );
};

export default RepairAppointmentDialog;
